import firebase from 'firebase/app';
import 'firebase/functions';

export default class EmailHelper {

    static async sendEmail(mailTo, msg, subject) {
        return firebase.functions().httpsCallable('sendMail')({
            mailTo,
            msg,
            subject,
        })
    }
}