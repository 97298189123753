import React, {Component} from 'react';

import "./ListAreaBody.css";
class ListAreaBody extends Component{

    render() {
        return (
            <div className="listAreaBody-container" onClick={this.props.onClick}>
                {this.props.children}
            </div>
        );
    }
}

export default ListAreaBody