import React, { Component } from 'react';
import { connect } from 'react-redux';

import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/functions";

import {
    Paper,
    TextField,
    MenuItem,
    Button,
    Tabs,
    Tab, Typography, Dialog, CircularProgress, DialogTitle, DialogContent,
} from '@material-ui/core';

import WorkshiftComponent from './WorkshiftComponent';
import SendApproval from '../TimeReports/SendApproval';
import { getOccurrences } from "../../CalendarComponents/OccurrencesComponent";

import PDFWorkshifts from "./PDFWorkshifts";

import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";

import moment from 'moment';
import "moment/locale/fi";
import PropTypes from "prop-types";

import { resetSend } from "../../actions/sendReportActions";
import SearchListComponent from './SearchListComponent';

function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

/*
 *
 * pohja otettu Timerposteista ja laajennetaan laskuraportteihin

 */

class Workshifts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            employee: "",
            customers: [],
            //selectedCustomer: "",
            selectedEmployee: "",
            selectedWorksite: "",
            jobs: [],
            company: {},
            jobModels: [],
            customerModel: {},
            selectedPeriod: "select",
            reportType: "tvl", //"select",
            dateRange: {
                selection: {
                    startDate: moment().add(1, "week").startOf("week").toDate(),
                    endDate: moment().add(1, "week").endOf("week").toDate(),
                    key: 'selection'
                }
            },
            dateChanged: false,
            tabValue: 0,
            resetPeriod: "gotPeriod",
            resetApproval: "gotApproval",
            resetArray: "gotArray",
            resetHour: "gotHour",
            searchArray: [],
            searchObj: {},
            errors: {
                period: "",
                date: "",
            },
            email: {
                pdfName: `Workshifts_${moment().locale("fi").format("L")}`,
                message: "ohessa tyovuorolista",
                subject: "Työvuorolista"
            },
            emailLoading: false,
            emailSent: false,
            descriptionField: "task",
            loading: true,
            searchingJobs: false,
            reportsLoading: false,
        };
    }

    componentDidMount() {
        const { user } = this.props.user;

        this.unsubscribeCompanyListener =
            firebase.firestore().collection('company').doc(user.companyID).onSnapshot(this.onCompanySnapshot);

        this.unsubscribeCustomerListener =
            firebase.firestore().collection('customers')
                .where("companyID", "array-contains", user.companyID)
                .onSnapshot(this.onCustomerSnapshot);

        this.unsubscribeEmployeeListener =
            firebase.firestore().collection('employees')
                .where("companyID", "==", user.companyID)
                //.where("isActive", "==", "true")
                .onSnapshot(this.onEmployeeSnapshot);

        this.unsubscribeWorksiteListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('worksites').onSnapshot(this.onWorksiteSnapshot);

    }

    onCompanySnapshot = docSnapshot => {
        const company = docSnapshot.data();
        this.setState({
            company: company
        });
    };

    onEmployeeSnapshot = querySnapshot => {
        const employees = [];

        querySnapshot.forEach(doc => {
            let employee = doc.data();
            employee.id = doc.id;
            employee.name = `${employee.firstName} ${employee.familyName}`;
            if(!employee.isDeleted) {
                employees.push(employee)
            }
        });

        this.setState({
            employees: employees
        });
    };

    onCustomerSnapshot = querySnapshot => {
        const customers = [];

        querySnapshot.forEach(doc => {
            const customer = doc.data();
            customer.id = doc.id;
            customers.push(customer);
        });

        this.setState({
            customers: customers,
            loading: false,
        });
    };

    onWorksiteSnapshot = querySnapshot => {
        const worksites = [];

        querySnapshot.forEach(doc => {
            let worksite = doc.data();
            worksite.id = doc.id;
            worksites.push(worksite);
        });

        this.setState({
            worksites: worksites
        });
    };



    componentWillUnmount() {
        this.unsubscribeCompanyListener && this.unsubscribeCompanyListener();
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
        this.unsubscribeCustomerListener && this.unsubscribeCustomerListener();
        this.unsubscribeWorksiteListener && this.unsubscribeWorksiteListener();
    }

    setMailToAccordingToEmployee = (callback) => {
        const { email, employees } = this.state;

        const employee = employees.find(empl => empl.name === employee);

        if (employee) {
            email.sendTo = employee.email ? employee.email : "employee has no email";

            this.setState({ email: email }, () => callback ? callback() : null);
        }
    }

    setEmailPresets = () => {
        const { email, dateRange, employee, company } = this.state;

        console.log("setEmailPresets employee ", employee);
        //email.mailFrom = "noreply@mobilityworkcloud.com";
        email.pdfName = `Työvuorolista_${company.name}_${employee}_${moment(dateRange.selection.startDate).locale("fi").format("L")}-${moment(dateRange.selection.endDate).locale("fi").format("L")}`;
        email.subject = `${company.name}: Raportti kotitalousvähennystä varten`;
        email.message = `Hei ${employee},<br><br>Ohessa raportti kotitalousvähennykseen oikeuttavista maksuistanne ajalta ${moment(dateRange.selection.startDate).locale("fi").format("L")}-${moment(dateRange.selection.endDate).locale("fi").format("L")} <br><br> Terveisin, <br><br>${company.name}`;

        this.setState({ email: email })

    }

    changeDateRangeToState = (startMoment, endMoment, callBack) => {
        console.log("changeDateRangeToState ", startMoment, endMoment)

        const dateRange = {
            selection: {
                startDate: startMoment,
                endDate: endMoment,
                key: 'selection'
            }
        }
        this.setState({ dateRange: dateRange }, callBack ? callBack() : null)

    }

    fieldChangeHandler = (event, field) => {
        const { errors, reportType } = this.state;

        console.log("fieldChangeHandler ", field, event)

        if (field === "employee") {
            this.setState({ selectedEmployee: event, searchArray: []})
        }

        if (field === "tvl") {
            this.setState({ selectedCustomer: event }, () => this.setMailToAccordingToEmployee(reportType === "tvl" ? () => {
                this.setEmailPresets(); // set email presets after customer has been set
            } : null
            ))
        }
        if (field === "selectedPeriod") {
            errors.period = "";
            this.setState({ selectedPeriod: event.target.value, errors: errors, searchArray: [] })
        }
        if (field === "tabValue") {
            this.setState({ tabValue: event })
        }
        if (field === "reportType") {
            this.setState({ reportType: event.target.value, searchArray: []})
            //change also the default range for different report types
            if (event.target.value === "tvl") {
                this.changeDateRangeToState(moment().add(1, "week").startOf('week'), moment().add(1, "week").endOf('week'),
                    () => {
                        this.setEmailPresets(); // set presets after date has been set
                    });
            }

        }

        if (field === "date") {
            errors.date = "";
            const dateRange = event;
            dateRange.selection.endDate = moment(dateRange.selection.endDate).endOf("day").toDate();
            this.setState({
                dateRange,
                errors: errors,
                dateChanged: true,
                searchArray: []
            });
        }
    }

    getEmployee = id => {
        const { employees } = this.state;

        const employee = employees.find(emp => emp.id === id);

        return employee;
    };

    getEmployeeName = id => {
        //const { employees } = this.state;

        const employee = this.getEmployee(id);  //employees.find(emp => emp.id === id);

        if (employee) {
            return employee.name;
        }
        else {
            return "Couldn't find name";
        }
    };

    getCustomerName = id => {
        const { customers } = this.state;
        
        const customer = customers.find(customer => customer.id === id);
        
        if (customer) {
            return customer.name;
        }
        else {
            return "Couldn't find name"
        }

    };

    getWorksiteName = id => {
        const { worksites } = this.state;
        
        const worksite = worksites.find(worksite => worksite.id === id);
        
        if (worksite) {
            return worksite.name;
        }
        else {
            return this.props.t("No worksite");
        }
    };

    getEmployeeNameString = employees => {
        const { selectedEmployee } = this.state;

        //console.log("getEmployeeNameString ", selectedEmployee);
        //console.log("employeess ", employees);

        let employeeString = "";

        if (selectedEmployee) {
            //const emp = employees.find(empl => empl.name === selectedEmployee);
            employeeString = selectedEmployee;
            if(employees.length > 1) {
                employeeString += " with ";
                employees && employees.map((employee, index) => {
                    const emplName = this.getEmployeeName(employee.id)
                    if(emplName != selectedEmployee) employeeString += emplName;
                })
            }

        } else {
            employees && employees.map((employee, index) => {
                employeeString += this.getEmployeeName(employee.id)
                if(index >= 0 && index < employees.length-1) employeeString += ", ";
            });
        }
        return employeeString;
    }


    searchDB = () => {
        const { employees, dateRange, selectedEmployee } = this.state;

        const { user } = this.props.user;

        this.setState({searchingJobs: true})


        //console.log("search jobs with employee id ", selectedEmployee, dateRange.selection.startDate, dateRange.selection.endDate);

        const employee = employees.find(empl => empl.name === selectedEmployee);

        firebase.firestore().collection('company').doc(user.companyID).collection('jobs')
            .get()
            .then((docSnap) => {
                const jobArray = [];
                const searchArray = [];
                let jobOccurrences = [];

                if (!docSnap.empty) {
                    docSnap.forEach(doc => {
                        const job = doc.data();
                        jobArray.push(job);
                    })
                }
                //console.log(" dateRange.selection ", dateRange.selection);

                const startMoment = moment(dateRange.selection.startDate).startOf("day");
                const endMoment = moment(dateRange.selection.endDate).endOf("day");

                jobArray && jobArray.forEach(job => {
                    let occArr = getOccurrences(job, startMoment, endMoment);
                    if (occArr && occArr.length > 0) {
                        //add missing shit
                        const occArr2 = [] 
                        occArr.forEach(occ => {
                            occ.worksite = job.worksite;
                            occ.customer = job.customer;
                            jobOccurrences.push(occ);
                            //occArr2.push(occ);
                        })       
                        //jobOccurrences = jobOccurrences.concat(occArr2);      
                    }
                });
                
                //console.log("DONE ALL: jobOccurrences ", jobOccurrences)

                jobOccurrences && jobOccurrences.forEach(jobOccu => {

                    //CHECK IF FILTERED BY EMPLOYEE
                    if (selectedEmployee && employee) {
                        //console.log("filter by employee ", employee.firstName, employee.id, jobOccu.employees, (jobOccu.employees.some(empl => empl.id === employee.id)))
                        if (jobOccu.employees && jobOccu.employees.some(empl => empl.id === employee.id)) {
                            //console.log("filtered by employee, job: ", jobOccu)
                            //TODO - CHECK INCOMINGHANGES
                            searchArray.push(jobOccu);
                        } //else dont add
                    } else { //ad all if no selection
                        //console.log("not filtered ", jobOccu);
                        searchArray.push(jobOccu);
                    }
                });

                //console.log("searchArray  len ", searchArray.length)

                searchArray.sort((a, b) => moment(a.start) - moment(b.start));

                this.setState({
                    searchArray: [...searchArray],
                    resetArray: this.state.resetArray === "gotArray" ? "noArray" : "gotArray",
                    //employee: employee,
                    searchingJobs: false,
                });
            })
            .catch((error => {
                //TODO: pop up this
                console.log("error in searchDB  ", error)
                this.setState({searchingJobs: false })
            }));

    }

    changeEmail = (event, field) => {
        const { email } = this.state;

        email[field] = event.target.value;

        this.setState({
            email: email
        });
    };

    sendEmail = () => {
        const { pdf } = this.props.report;
        const { email } = this.state;
        this.setState({
            emailLoading: true
        });

        console.log("sending email: ", email);
        firebase.functions().httpsCallable('sendMail')({
            mailTo: email.sendTo,
            pdf: pdf,
            msg: email.message,
            pdfName: email.pdfName,
            subject: email.subject
        })
            .then(res => {
                this.props.resetSend();
                this.setState({
                    emailSent: true,
                    tabValue: 0,
                    email: {}
                });
            })
            .catch(err => {
                console.log(err)

            });
    };

    resetParameters = () => {
        this.setState({
            employee: "",
            reportType: "select",
            selectedPeriod: "select",
            selectedEmployee: "",
            selectedWorksite: {},
            dateRange: {
                selection: {
                    startDate: moment(),
                    endDate: moment(),
                    key: 'selection'
                }
            },
            dateChanged: false,
            tabValue: 0,
            resetPeriod: this.state.resetPeriod === "gotPeriod" ? "noPeriod" : "gotPeriod",
            resetApproval: this.state.resetApproval === "gotApproval" ? "noApproval" : "gotApproval",
            searchArray: [],
            searchObj: [],
            resetHour: this.state.resetHour === "gotHour" ? "noHour" : "gotHour",
            resetArray: this.state.resetArray === "gotArray" ? "noArray" : "gotArray",
            errors: {
                period: "",
                date: ""
            }
        });
    };

    renderEmailDialog = () => {
        if (this.state.emailLoading) {
            return (
                <Dialog
                    open={this.state.emailLoading}
                    onClose={() => this.setState({ emailLoading: false })}
                    maxWidth="xs"
                >
                    {!this.state.emailSent && <CircularProgress />}
                    {this.state.emailSent && <DialogTitle>Email sent!</DialogTitle>}
                    {this.state.emailSent && <DialogContent>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.setState({ emailLoading: false })}
                            style={{
                                marginLeft: 10
                            }}
                        >
                            Close
                        </Button>
                    </DialogContent>}
                </Dialog>
            );
        }
    };

    renderReportsCreateDialog = () => {
        if (this.state.reportsLoading) {
            return (
                <Dialog
                    open={this.state.reportsLoading}
                    onClose={() => this.setState({ reportsLoading: false })}
                    maxWidth="xs"
                >
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
            );
        }
    };

    render() {
        const { t } = this.props;
        const { searchingJobs, selectedEmployee, reportsLoading } = this.state;

        //console.log("state ", this.state)
        //console.log("props ", this.props)

        //console.log("this.state.dateRange.selection ", this.state.dateRange.selection)

        return (
            <div style={{ width: '100%', display: 'flex', marginLeft: 20, marginTop: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: 350 }}>

                    <Paper style={{ width: '100%', padding: 10, height: 'auto', overflowY: 'auto' }}>
                        {
                            /* Työlista
                             */
                        }

                        {this.state.reportType === "select" &&
                            <TextField
                                variant="outlined"
                                label={t("Report type")}
                                style={{
                                    width: '100%'
                                }}
                                value={this.state.reportType}
                                //onChange={event => this.setState({reportType: event.target.value})}
                                onChange={event => this.fieldChangeHandler(event, 'reportType')}
                                select
                            >
                                <MenuItem key="select" value="select">{t("Select type")}</MenuItem>
                                {/* <MenuItem key="period" value="period">{t("Period bill report")}</MenuItem> */}
                                <MenuItem key="tvl" value="tvl">{t("Workshift list")}</MenuItem>

                            </TextField>}


                        {this.state.reportType === "tvl" &&
                            <div>
                                
                                {
                                    <TabContainer>
                                        <WorkshiftComponent
                                            employees={this.state.employees}
                                            onChange={this.fieldChangeHandler}
                                            reportType={this.state.reportType}
                                            onReset={this.resetParameters}
                                            dateRange={this.state.dateRange}
                                            onSearch={() => this.searchDB()}
                                            key={this.state.resetHour}
                                            searchingJobs = {searchingJobs}
                                        />
                                        {this.state.searchArray.length > 0 && 
                                            <PDFWorkshifts
                                                start={this.state.dateRange.selection.startDate}
                                                end={this.state.dateRange.selection.endDate}
                                                //selectedCustomer={this.state.selectedCustomer}
                                                //customer={this.state.customer}
                                                employeeLabel={this.state.selectedEmployee.length > 0 ? this.state.selectedEmployee : "All"}
                                                selectedEmployee={this.state.selectedEmployee}
                                                employees={this.state.employees}
                                                customers={this.state.customers}
                                                selectedJobs={this.state.searchArray}
                                                company={this.state.company}
                                                //descriptionField={this.state.descriptionField}
                                                dateRange={this.state.dateRange}
                                                reportsCreating={reportsLoading}
                                                onReportCreate={() => this.setState({ reportsLoading: true })}
                                                onReportsCreated={() => this.setState({ reportsLoading: false })}
                                                openInNewTab={this.state.openInNewTab}
                                                onChangeOpenInNewTab={(checked) => this.setState({ openInNewTab: checked })}
                                                getEmployeeNameString={this.getEmployeeNameString}
                                            />
                                        }
                                    </TabContainer>
                                }

                                {/*this.state.tabValue === 1 &&
                                    <TabContainer>
                                        <SendApproval
                                            gotEmail={event => this.setState({ sendTo: event })}
                                            onChange={this.changeEmail}
                                            initialEmail={this.props.report.customer}
                                            email={this.state.email}
                                            sendEmail={this.sendEmail}
                                        />
                                    </TabContainer>
                                */}

                            </div>
                        }

                        {/* info texts */}

                        {this.state.reportType === "select" &&
                            <div>
                                <div
                                    style={{ width: '95%', height: '85vh', overflow: 'auto', padding: 20, fontSize: 12 }}>
                                    <div style={{ padding: 5, marginBottom: 20, fontSize: 13 }}>
                                        Valitse laskuraportin tyyppi:
                                </div>
                                    <div style={{ padding: 5 }}>
                                        Kotitalousvähennysraportti sisältää aikavälin (yleensä verovuoden) kotitalousvähennykseen oikeuttavat maksut eli työsuoritukset. Laskutusmaksut, sekä materiaalit on vähennetty ja eritelty raportilla.
                                </div>
                                    <div style={{ padding: 5 }}>

                                    </div>
                                    <div style={{ padding: 5 }}>
                                    </div>
                                    <div style={{ padding: 5 }}>
                                    </div>
                                </div>
                            </div>
                        }

                    </Paper>
                </div>

                <div style={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 20, marginRight: 20 }}>

                        <Paper style={{ width: '100%', height: '85vh', overflow: 'auto' }}>
                            {
                                searchingJobs && <CircularProgress/>
                            }

                            {!searchingJobs &&
                                <SearchListComponent
                                    searchArray={this.state.searchArray}
                                    findEmployee={this.getEmployeeName}
                                    findCustomer={this.getCustomerName}
                                    getWorksite={this.getWorksiteName}
                                    getEmployeeNameString={this.getEmployeeNameString}
                                    key={this.state.resetArray}
                                    searchingJobs = {searchingJobs}
                                    selectedEmployee={selectedEmployee}
                                />
                            }

                            {/*this.state.reportType === "hour" &&
                            <HourListComponent
                                searchObj={this.state.searchObj}
                                employees={this.state.employee}
                                getEmployee={this.getEmployeeName}
                                getCustomer={this.getCustomerName}
                                getWorksite={this.getWorksiteName}
                            />*/
                            }
                        </Paper>
                    </div>
                </div>


                {this.renderEmailDialog()}
                {this.renderReportsCreateDialog()}

            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,

    //report: state.report,
});

export default withRouter(connect(mapStateToProps, { resetSend })(withTranslation()(Workshifts)));