import React from 'react';

import {Checkbox, FormControlLabel} from "@material-ui/core";


export const StatusSelection = props => {
    const {t, filterValues} = props;
    return (
       <div style={{marginLeft: 5, marginTop: 0}}>
           <FormControlLabel
               control={
                    <Checkbox
                        checked={!!filterValues.find(value => value.type === "Created" && value.field === "status")}
                        onChange={() => props.filterBills("Created", "status")}
                        style={{color: "#1770B2"}}
                    />
               }
               label={t("Created")}
           />

           <br/>

           <FormControlLabel
               control={
                   <Checkbox
                       checked={!!filterValues.find(value => value.type === "Sent" && value.field === "status")}
                       onChange={() => props.filterBills("Sent", "status")}
                       style={{color: "#1770B2"}}
                   />
               }
               label={t("Sent")}
           />
        
            <br/>

            <FormControlLabel
               control={
                   <Checkbox
                       checked={!!filterValues.find(value => value.type === "InQue" && value.field === "status")}
                       onChange={() => props.filterBills("InQue", "status")}
                       style={{color: "#1770B2"}}
                   />
               }
               label={t("InQue")}
           />

            <br/>

           <FormControlLabel
               control={
                   <Checkbox
                       checked={!!filterValues.find(value => value.type === "Paid" && value.field === "status")}
                       onChange={() => props.filterBills("Paid", "status")}
                       style={{color: "#1770B2"}}
                   />
               }
               label={t("Paid")}
           />

           <br/>

           <FormControlLabel
               control={
                   <Checkbox
                       checked={!!filterValues.find(value => value.type === "Archived" && value.field === "status")}
                       onChange={() => props.filterBills("Archived", "status")}
                       style={{color: "#1770B2"}}
                   />
               }
               label={t("Archived")}
           />
            <br/>
       </div>
    );
};

export const SendMethodSelection = props => {
    const {t, filterValues} = props;
    return (
        <div style={{marginLeft: 5, marginTop: 0}}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!filterValues.find(value => value.type === "email" && value.field === "deliveryMethod")}
                        onChange={() => props.filterBills("email", "deliveryMethod")}
                        style={{color: "#1770B2"}}
                    />
                }
                label={t("Email")}
            />

            <br/>

            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!filterValues.find(value => value.type === "apix" && value.field === "deliveryMethod")}
                        onChange={() => props.filterBills("apix", "deliveryMethod")}
                        style={{color: "#1770B2"}}
                    />
                }
                label={t("apix")}
            />

            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!filterValues.find(value => value.type === "email / apix" && value.field === "deliveryMethod")}
                        onChange={() => props.filterBills("email / apix", "deliveryMethod")}
                        style={{color: "#1770B2"}}
                    />
                }
                label={t("Email / APIX")}
            />


        </div>
    );
};
