import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import {
    Paper
} from '@material-ui/core';

import moment from 'moment-timezone';

import DateComponent from './DateComponent';
import FullCalendarComponent from './FullCalendar';
import EmployeeListComponent from './EmployeeListComponent';

import {connect} from 'react-redux';
import {getOccurrences} from "../CalendarComponents/OccurrencesComponent";
import {DataSearch} from "../util/DataSearch";

const INITIAL_DB_QUERY_DAYS_BACK = 1;

class CalendarWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            employees: [],
            selectedEmployees: [],
            customers: [],
            models: [],
            worksites: [],
            jobs: [],
            worklogs: [],
            events: [],
            eventsCopy: [],
            company: {},
            'dateRange': {
                selection: {
                    key: 'selection',
                    startDate: moment().startOf("day").toDate(),
                    endDate: moment().endOf("day").toDate()
                }
            },
            viewRange: null,
            activeDates: {
                view: {
                    activeStart: moment().startOf("week"),
                    activeEnd: moment().add(1, "week").startOf("week")
                    //activeEnd: moment().startOf("week").add(5, "days")

                }
            },
            fullCalendar: {
                defaultDate: moment().startOf("week"),
                defaultView: "agendaWeek",
                //defaultView: "agenda",
                activeDays: 0,
                /*visibleRange: {
                    start: moment().subtract(1, 'days'),
                    end:  moment().add(3, 'days')
                },*/

                /* voisi olla kiva jos saisi vaikka +2 päivää, mutta ei tariva toimia tällä 4.0.alphalla vielä */
                aspectRatio: (window.innerWidth * 0.7) / (window.innerHeight * 0.8)
            },
            defaultDate: "",
            searchTerm: "",

            wlQueryTimerStart: moment(),
            wl2QueryTimerStart: moment(),
            jobQueryTimerStart: moment(),
            wlQueryFromValue: moment().subtract(INITIAL_DB_QUERY_DAYS_BACK, "day").format("YYYYMMDD"),
        }
    }

    componentDidMount() {
        const {user} = this.props.user;

        //const wlQueryFromValue = moment().subtract(INITIAL_DB_QUERY_DAYS_BACK, "day").format("YYYYMMDD");

        this.unsubscribeEmployees = firebase.firestore().collection('employees')
            .where("companyID", "==", user.companyID)
            .where("isActive", "==", "true")
            .onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "employees"));


        this.unsubscribeCustomers = firebase.firestore().collection('customers')
            .where("companyID", "array-contains", user.companyID)
            .onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "customers"));

        this.unsubscribeJobModels = firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "models"));


        this.getCancellations()

        this.unsubscribeWorksites = firebase.firestore().collection('company').doc(user.companyID).collection('worksites').onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "worksites"));

        this.setState({jobQueryTimerStart: moment()})
        this.unsubscribeJob = firebase.firestore().collection('company').doc(user.companyID).collection('jobs').onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "jobs"));

        this.unsubscribeCompany = firebase.firestore().collection('company').doc(user.companyID).onSnapshot(docSnap => {
            const company = docSnap.data();
            company.id = docSnap.id;

            this.setState({company})
        });

    }

    getCancellations = () => {
        const {user} = this.props.user;
        const {wlQueryFromValue} = this.state;

        this.unsubscribeWorklogs && this.unsubscribeWorklogs();
        this.unsubscribeWorklogs2 && this.unsubscribeWorklogs2();

        console.log("get CANCELLED from ", wlQueryFromValue);

        this.setState({wlQueryTimerStart: moment()})

        this.unsubscribeWorklogs = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs")
            .where("status", "==", "Cancelled")
            .where("startDT", ">=", wlQueryFromValue)
            .onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "cancelled1"));

        this.setState({wl2QueryTimerStart: moment()})

        //this is really silly and consuming way to find workslogs that vere cancelled, but then status changed to billed or other
        this.unsubscribeWorklogs2 = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs")
            .where("previousStatus", "==", "Cancelled")
            .where("startDT", ">=", wlQueryFromValue)
            .onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "cancelled2"));


    }

    componentWillUnmount() {
        this.unsubscribeEmployees && this.unsubscribeEmployees();
        this.unsubscribeCustomers && this.unsubscribeCustomers();
        this.unsubscribeJobModels && this.unsubscribeJobModels();
        this.unsubscribeWorksites && this.unsubscribeWorksites();
        this.unsubscribeJob && this.unsubscribeJob();
        this.unsubscribeWorklogs && this.unsubscribeWorklogs();
        this.unsubscribeWorklogs2 && this.unsubscribeWorklogs2();
        this.unsubscribeCompany && this.unsubscribeCompany();
    }


    onQuerysnapshot = (snap, field) => {
        const {wlQueryTimerStart, wl2QueryTimerStart, jobQueryTimerStart} = this.state;

        const dataArr = [];
        const selectedEmployees = [];

        if (field === "cancelled1") {
            console.log("DEBUG: cancelled 1 query took: ", (moment() - wlQueryTimerStart) / 1000)
        }
        if (field === "cancelled2") {
            console.log("DEBUG: cancelled 2 query took: ", (moment() - wl2QueryTimerStart) / 1000)
        }
        if (field === "jobs") {
            console.log("DEBUG: JOBS query took: ", (moment() - jobQueryTimerStart) / 1000)
        }

        snap.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;

            if (field === "employees") {
                data.name = `${data.firstName} ${data.familyName}`;
                selectedEmployees.push(data.id);
            }

            dataArr.push(data)
        });

        this.setState({
            [field]: dataArr
        });

        if (field === "employees") {
            this.setState({selectedEmployees});
            if (!this.state.loading) this.eventHandler(this.state.activeDates, true);
        }
        if (field === "jobs") this.eventHandler(this.state.activeDates, true);
        if ((field === "cancelled1" || field === "cancelled2") && !this.state.loading) this.eventHandler(this.state.activeDates, true);

    };

    getEmployee = (id, field) => {
        const {employees} = this.state;

        const employee = employees.find(employee => employee.id === id);

        if (employee) {
            return employee[field];
        }
    };

    checkRangeForCancelledWorklogs = (dateRange) => {
        const {wlQueryFromValue} = this.state;

        if (moment(dateRange.selection.startDate) < moment(wlQueryFromValue)) {
            console.log("checkRangeForCancelledWorklogs get earlier cancellations from ", dateRange.selection.startDate, wlQueryFromValue);
            this.setState({wlQueryFromValue: moment(dateRange.selection.startDate).format("YYYYMMDD")}, () => this.getCancellations())
        } else if (moment(dateRange.startDate) >= moment(wlQueryFromValue)) {
            console.log("checkRangeForCancelledWorklogs get later cancellations from ", dateRange.selection.startDate, wlQueryFromValue);
            this.setState({wlQueryFromValue: moment(dateRange.selection.startDate).format("YYYYMMDD")}, () => this.getCancellations())
        } else {
            console.log("checkRangeForCancelledWorklogs NOTHING FOR ", dateRange.defaultDate, dateRange.selection.startDate, wlQueryFromValue);
        }

    }


    handleFullCalendarFields = (event, field) => {
        const {fullCalendar, dateRange} = this.state;
        let {defaultDate} = this.state;

        if (field === "dateRange") {
            dateRange.selection = event.selection;
            console.log("handleFullCalendarFields dateRange ", dateRange);
            const days = moment(event.selection.endDate).diff(event.selection.startDate, "days");
            fullCalendar.defaultDate = event.selection.startDate;
            fullCalendar.activeDays = days + 1;
            fullCalendar.defaultView = "selectedView";
            defaultDate = event.selection.startDate;

        } else {
            console.log("handleFullCalendarFields ELSE selection: ", field, " dateRange ", dateRange);
        }

        this.checkRangeForCancelledWorklogs(dateRange);

        this.setState({
            dateRange,
            fullCalendar,
            defaultDate,
        });
    };

    handleEmployeeSelects = (id, reverse) => {
        let {selectedEmployees, eventsCopy} = this.state;

        // Filter array
        if (reverse) {
            selectedEmployees = [id];
        }
        else {
            if (!selectedEmployees.includes(id)) {
                selectedEmployees.push(id);
            }
            else {
                const removeIndex = selectedEmployees.indexOf(id);
                selectedEmployees.splice(removeIndex, 1);
            }
        }


        const filteredEventArray = eventsCopy.filter(event => selectedEmployees.includes(event.employee));

        this.setState({
            events: filteredEventArray,
            selectedEmployees
        });
    };

    eventHandler = (event, override) => {
        const startMoment = moment(event.view.activeStart);
        const endMoment = moment(event.view.activeEnd);

        let events = [];

        if (this.state.viewRange === null) {
            this.setState({viewRange: event.view.activeStart});
        }

        if ((!moment(this.state.viewRange).isSame(event.view.activeStart) && this.state.jobs) || override) {

            const time1 = moment();
            this.state.jobs.forEach(job => {

                const eventArr = getOccurrences(job, startMoment, endMoment);
                events = events.concat(eventArr)
            });
            const time2 = moment();
            console.log("DEBUG: job getOccurrences loop took ", (time2 - time1) / 1000)

            let id = 1;
            let finalEvents = [];

            const time11 = moment();
            events.forEach(event => {
                if (event.employees) {
                    if (event.employees.length === 0) {
                        let employeeEvent = {...event};
                        employeeEvent.id = id;
                        finalEvents.push(employeeEvent);
                        id++;
                    }
                    event.employees.forEach(employee => {
                        const employeeEvent = {...event};
                        employeeEvent.color = this.getEmployee(employee.id, "color");
                        employeeEvent.id = id;
                        employeeEvent.employee = employee.id;
                        finalEvents.push(employeeEvent);
                        id++;
                    });
                }
            });
            const time12 = moment();
            console.log("DEBUG: job events.forEach loop took ", (time12 - time11) / 1000)

            const time21 = moment();
            if (this.state.employees.length !== this.state.selectedEmployees.length) {
                const filteredByEmployee = finalEvents.filter(event => this.state.selectedEmployees.includes(event.employee));
                finalEvents = filteredByEmployee
            }
            const time22 = moment();
            console.log("DEBUG: job events.forEach per employee loop took ", (time22 - time21) / 1000)


            const time31 = moment();
            // Go trough final events array to find cancelled jobs
            finalEvents.forEach(event => {
                this.state.cancelled1 && this.state.cancelled1.forEach(log => {
                    if (moment(event.start).isSame(log.startDT) && event.jobID === log.jobID) {
                        event.color = "#333333"
                    }
                });
                this.state.cancelled2 && this.state.cancelled2.forEach(log => {
                    if (moment(event.start).isSame(log.startDT) && event.jobID === log.jobID) {
                        event.color = "#333333"
                    }
                });
            });
            const time32 = moment();
            console.log("DEBUG: job  final events array to find cancelled jobs loop took ", (time32 - time31) / 1000)

            if (finalEvents && finalEvents.length > 0) {
                const {fullCalendar, activeDates} = this.state;
                fullCalendar.defaultDate = event.view.activeStart;

                activeDates.view.activeStart = event.view.activeStart;
                activeDates.view.activeEnd = event.view.activeEnd;


                this.setState({
                    loading: false,
                    events: finalEvents,
                    eventsCopy: [...finalEvents],
                    viewRange: event.view.activeStart,
                    activeDates,
                    fullCalendar
                }, () => {
                    if (!this.state.loading && this.state.searchTerm.length > 0) this.onSearch({target: {value: this.state.searchTerm}})
                });
            }

        }
    };


    onSearch = event => {
        const {eventsCopy} = this.state;
        const searchTerm = event.target.value;

        const events = JSON.parse(JSON.stringify(eventsCopy));

        const eventSearch = new DataSearch();
        eventSearch.setSearchData(events);

        eventSearch.appendField("title", searchTerm, "search");
        const results = eventSearch.executeSearch();


        this.setState({
            searchTerm,
            events: results
        });
    };

    render() {
        return (
            <div style={{width: '100%', display: 'flex', padding: 20}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Paper style={{flexShrink: 1, flexBasis: 360}}>
                        <DateComponent
                            dateRange={this.state.dateRange}
                            defaultDate={this.state.defaultDate}
                            onSelect={this.handleFullCalendarFields}
                        />
                    </Paper>

                    <Paper style={{marginTop: 20, flexGrow: 2, flexShrink: 2, overflowY: 'auto'}}>
                        <EmployeeListComponent
                            employees={this.state.employees}
                            selectedEmployees={this.state.selectedEmployees}
                            onSelect={this.handleEmployeeSelects}
                        />
                    </Paper>
                </div>

                <div style={{flexGrow: 1, marginLeft: 20}}>
                    <Paper style={{height: '100%', overflowY: 'auto'}} id="calendar-container">
                        <FullCalendarComponent
                            showSearchBar
                            jobs={this.state.jobs}
                            events={this.state.events}
                            getEvents={this.eventHandler}
                            fullCalendar={this.state.fullCalendar}
                            worksites={this.state.worksites}
                            customers={this.state.customers}
                            employees={this.state.employees}
                            models={this.state.models}
                            company={this.state.company}
                            defaultDate={this.state.defaultDate}
                            searchTerm={this.state.searchTerm}
                            onSearch={this.onSearch}
                        />
                    </Paper>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(CalendarWrapper);
