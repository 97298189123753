import React from 'react';

import {
    Menu,
    MenuItem,
} from '@material-ui/core';

import {withTranslation} from "react-i18next";

const SelectMenu = props => {
    const {anchorEs, t} = props;
    const open = Boolean(anchorEs);

    return (
        <Menu
            anchorEl={anchorEs}
            getContentAnchorEl={null}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
            }}
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top'
            }}
            open={open}
            onClose={() => props.closeMenu()}
        >
            {props.loopArr && props.loopArr.map(item => (
                <MenuItem
                    key={item}
                    value={item}
                    onClick={() => props.onSelect(item, props.selectTarget)}
                    style={{
                        width: 165
                    }}
                >
                    {t(item)}
                </MenuItem>
            ))}
        </Menu>
    )
};

export default withTranslation()(SelectMenu);