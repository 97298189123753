import React, {Component} from 'react';

import {Button, FormControlLabel, Checkbox} from '@material-ui/core';

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";

import BillPriceItem from './BillPriceItem';

// Import React FilePond
import { FilePond, registerPlugin }from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";


import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

import {billSummaryPrices} from "./InvoiceObjectForming";

import "./BillDetailsSummary.css";

registerPlugin(FilePondPluginFileEncode);

class BillDetailsSummary extends  Component{
    constructor(props) {
        super(props);
        this.state = {
            showAttachment: false,
            fileCounter: 0,
            files: [],
            attachmentUploaded: false
        };

    }



    roundNumers = (d, field) => {
        const {invoice, billingFees} = this.props.bill.billObj;

        return parseFloat(d).toFixed(2);
    };


    handleInit = () => {
        console.log("FilePond initiated")
    };

    onFiles = items => {
        const {fileCounter} = this.state;

        if (fileCounter > 0 && fileCounter % 2 === 0) {
            items.forEach(item => {
                const pdfString = item.getFileEncodeDataURL();
                console.log(item)
                console.log(item.file);
                const nameString = item.file.name.split(".");

                this.props.onUpload(nameString[0], pdfString);

                this.setState({
                    attachmentUploaded: true,
                    files: [item.file]
                });
            });
        }

        if (items.length === 0) {
            this.setState({
                fileCounter: 0,
                files: []
            });
        }
    };

    renderSummaryPrices = () => {
        const {rows, invoice, billingFees} = this.props.bill.billObj;
        const {user} = this.props.user;
        const {t} = this.props;


        const {withoutVat, totalVat, fullPrice} = billSummaryPrices(rows, billingFees, invoice, user);

        return (
            <div style={{marginTop: 10}}>
                <div className="summary-prices">
                    <p>{t("Total_we_vat")}:</p>
                    <p>{withoutVat}</p>
                </div>

                <div className="summary-prices">
                    <p>{t("Vat")}:</p>
                    <p>{totalVat}</p>
                </div>

                <div className="summary-prices">
                    <p>{t("Total")}:</p>
                    <p>{fullPrice}</p>
                </div>

                <div className="summary-prices">
                    <p className="toPay">{t("Payable")}:</p>
                    <p className="toPay">{fullPrice}</p>
                </div>
            </div>
        )
    };

    render() {
        const {t, billingFees, attachment, uploadedAttachment} = this.props;
        const {invoice} = this.props.bill.billObj;

        let discount = "";
        if (invoice.discount) discount = (parseFloat(invoice.discount) * 100).toFixed(0);

        return (
            <div className="bill-summary-container">
                <div className="bill-summary-header">
                    <p className="header-txt">{t("Summary_attachments")}</p>
                </div>

                <div className="bill-delivery-price">

                    <BillPriceItem
                        label={t("Freight_charge")}
                        field="freight"
                        value={billingFees.freight}
                        onChange={this.props.onChange}
                        key={"freight"}
                    />

                    <BillPriceItem
                        label={t("Billing_charge")}
                        field="billing"
                        value={billingFees.billing}
                        onChange={this.props.onChange}
                        key={"billing"}
                    />

                    <BillPriceItem
                        label={t("Bill_discount")}
                        field="discount"
                        value={discount}
                        onChange={this.props.onChange}
                        key={"discount"}
                    />
                </div>

                <div className="row-container">
                    <div className="attachments">
                        <div style={{ width: 200, marginLeft: 10 }}>
                            <FilePond
                                files={this.state.files}
                                allowMultiple={false}
                                maxFiles={1}
                                server="https://icf.heinolasoftwares.fi/upload"
                                onInit={() => this.handleInit()}
                                onupdatefiles={files => this.setState(prevState => ({
                                    fileCounter: prevState.fileCounter + 1,
                                    showAttachment: false
                                }), () => this.onFiles(files))}
                                allowFileEncode={true}
                                onerror={ error => console.log("FilePond OnError: ", error) }
                                onaddfile={(error, file) => { console.log("onaddfile; ", error, file) }}
                                onpreparefile={(file, output) => { console.log("onprepare; ", file, output) }}
                                onprocessfileprogress={(file, progress) => { console.log("onprocessfileprogress; ", file, progress) }}
                                ondata={ (formData) => {
                                    console.log("formData=" ,formData)
                                    /*authenticateSender().then( (token) => {
                                        if (token) authToken = `Bearer ${token}`;
                                    });*/
                                }}
                                //TODO: headers={
                                //             "Content-Type": "application/json",
                                //             'Access-Control-Allow-Origin': '*',
                                //             "x-access-token": authToken
                                //         }

                            />
                        </div>


                        <br/>

                        <div style={{width: 400, display: "flex"}}>    
                            <FormControlLabel
                                control={<Checkbox
                                    checked={this.props.sendAttachment}
                                    onChange={() => this.props.setSendAttachment(!this.props.sendAttachment)}
                                    style={{color: "#1770B2"}}
                                />}
                                label={t("SendAttachment")}
                                style={{ marginLeft: 10 }}
                            />
                            <FormControlLabel
                                control={<Checkbox
                                    checked={this.props.sendAttachmentAsEmail}
                                    onChange={() => this.props.setSendAttachmentAsEmail(!this.props.sendAttachmentAsEmail)}
                                    style={{color: "#1770B2"}}
                                />}
                                label={t("SendAttachmentSeparateEmail")}
                                style={{ marginLeft: 10 }}
                            />
                        </div>

                        <div style={{width: 400, display: "flex"}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginLeft: 10, width: 200}}
                                onClick={() => this.setState(prevState => ({showAttachment: !prevState.showAttachment}))}
                                disabled={!attachment}
                            >
                                {t("Show_attachment")}
                            </Button>

                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginLeft: 10, width: 200}}
                                onClick={() => this.props.onSendBillAttachmentAsEmail()}
                                disabled={!attachment}
                            >
                                {t("Send_attachment_email_button")}
                            </Button>

                        </div>
                        <br/>

                    </div>

                    {this.renderSummaryPrices()}

                </div>

                {this.state.showAttachment && !this.state.attachmentUploaded &&
                    <object data={attachment} width="100%" height={600} style={{marginTop:20}}/>
                }

                {this.state.showAttachment && this.state.attachmentUploaded &&
                    <object data={uploadedAttachment} width="100%" height={600} style={{marginTop:20}}/>
                }

            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps,{})(withTranslation()(BillDetailsSummary));
