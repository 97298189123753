import icalendar from "icalendar";
import RecurrenceRule from "icalendar/src/RecurrenceRule";
import moment from 'moment-timezone';

const getRecurrenceString = job => {

    let ruleString = "";
    let rrule = job.rrule;

    if (rrule.BYDAY && rrule.COUNT) {
        ruleString = `FREQ=${rrule.FREQ};INTERVAL=${rrule.INTERVAL};BYDAY=${rrule.BYDAY};COUNT=${rrule.COUNT}`;

    }
    else if (rrule.BYDAY && rrule.UNTIL) {
        ruleString = `FREQ=${rrule.FREQ};INTERVAL=${rrule.INTERVAL};BYDAY=${rrule.BYDAY};UNTIL=${rrule.UNTIL}`;

    }
    else if (rrule.BYDAY && job.disableDT) {
        ruleString = `FREQ=${rrule.FREQ};INTERVAL=${rrule.INTERVAL};UNTIL=${job.disableDT};BYDAY=${rrule.BYDAY}`;
    }
    else if (rrule.COUNT) {
        ruleString = `FREQ=${rrule.FREQ};INTERVAL=${rrule.INTERVAL};COUNT=${rrule.COUNT}`;

    }
    else if (rrule.UNTIL) {
        ruleString = `FREQ=${rrule.FREQ};INTERVAL=${rrule.INTERVAL};UNTIL=${rrule.UNTIL}`;

    }
    else if (rrule.BYDAY) {
        ruleString = `FREQ=${rrule.FREQ};INTERVAL=${rrule.INTERVAL};BYDAY=${rrule.BYDAY}`;

    }
    else if (job.disableDT) {
        ruleString = `FREQ=${rrule.FREQ};INTERVAL=${rrule.INTERVAL};UNTIL=${job.disableDT}`;

    }
    else {
        ruleString = `FREQ=${rrule.FREQ};INTERVAL=${rrule.INTERVAL}`;
    }

    if (rrule.uuid === "fb5d5d6e-f362-43b1-82f7-ea54d1c95215") {
        console.log(ruleString);
    }

    return ruleString;
};

export const getOccurrences = (job, startMoment, endMoment) => {

    let duration = moment(job.endDT) - moment(job.startDT);
    
    //no point getting occurrences if disabled before (also no incomingchanges)
    if (job?.disableDT && (moment(job.disableDT) < startMoment || moment(job.disableDT) <  moment(job.startDT))) {
        //console.log("job disabled ", job);
        return [];
    }

    try {
        const event = new icalendar.CalendarEvent()
            .setStartTime(new icalendar.DateTime(job.startDT, { TZID: job.TZ }))
            .setRecurrenceRule(new RecurrenceRule(getRecurrenceString(job)));

        const occurrences = event.occurrencesBetweenMoments(startMoment, endMoment);
        
        let createdEvents = [];

        occurrences.forEach(obj => {

            const event = {
                start: obj.toISOString(true),
                end: moment(obj + duration).toISOString(true),
                employees: job.employees ? job.employees : [],
                jobID: job.id,
                title: `${job.title + ' -'} ${job.worksiteName ? (`${job.customerLabel + ' (' + job.worksiteName})`) : job.customerLabel} `,
                extendedProps: {
                    status: obj.status,
                }
            };
            createdEvents.push(event);
        });


        job.inComingChanges && job.inComingChanges.forEach(change => {
            //limit also changes between desired time
            // TODO: be aware of side effects in calandar?

            //if (change.replaceDT.moment.isBetween(startMoment, endMoment, null, '[]')) {
            if (change.impact === "once") createdEvents = checkOnce(createdEvents, change, job, startMoment, endMoment); // shoot once checks
            if (change.impact === "range") createdEvents = checkRange(createdEvents, change, job, startMoment, endMoment); // shoot range checks
            //}
        });
        
        return createdEvents;

    } catch (err) {
        console.log("ERROR : getOccurrences failed with error: " + err + " for the job ", job);
        //TODO: SEND EMAIL TO SUPPORT

        return [];
    }
};

const checkOnce = (events, change, job, startMoment, endMoment) => {
    const eventCopy = [...events];

    events && events.forEach(event => {
        if (moment(event.start).isSame(change.replaceDT)) {
            const removeIndex = eventCopy.indexOf(event);
            eventCopy.splice(removeIndex, 1)
        }
    });

    if (change.type && change.type !== "delete") {

        if (moment(change.startDT).isBetween(startMoment, endMoment, null, '[]')) {
            const newEvent = {
                start: moment(change.startDT).toISOString(true),
                end: moment(change.endDT).toISOString(true),
                employees: change.employees ? change.employees : job.employees,
                //title: `${job.title + ' -' } ${job.worksiteName ? job.worksiteName + '/' : ''} ${job.customerLabel} `,
                title: change.title ? `${change.title + ' -'} ${job.worksiteName ? job.worksiteName + '/' : ''} ${job.customerLabel} ` :
                    `${job.title + ' -'} ${job.worksiteName ? job.worksiteName + '/' : ''} ${job.customerLabel} `,
                jobID: job.id,
                isOntime: change.isOntime ? change.isOntime : (job.isOntime ? job.isOntime : null),
                endOntime: change.endOntime ? change.endOntime : (job.endOntime ? job.endOntime : null),
                alternateTitle: change.title ? change.title : null,
                alternateMaxEmp: change.maxEmp ? change.maxEmp : null,

                changeImpact: change.impact,
            };
            eventCopy.push(newEvent);
        }
    }

    return eventCopy;
};

const checkRange = (events, change, job, startMoment, endMoment) => {
    const eventCopy = [...events];
    const increasedEnd = moment(change.rangeEnd).add(1, "day");

    events && events.forEach(event => {
        if (moment(event.start).isBetween(change.rangeStart, increasedEnd)) {
            const removeIndex = eventCopy.indexOf(event);
            eventCopy.splice(removeIndex, 1);
        }
    });

    const duration = moment(change.endDuringRange) - moment(change.startDuringRange);

    if (change.type && change.type !== "delete") {

        if (moment(change.startDT).isBetween(startMoment, endMoment, null, '[]')) {

            const event = new icalendar.CalendarEvent()
                .setStartTime(new icalendar.DateTime(change.startDuringRange, { TZID: job.TZ }))
                .setRecurrenceRule(new RecurrenceRule(getRecurrenceString(job)));

            const occurrences = event.occurrencesBetweenMoments(moment(change.rangeStart), moment(increasedEnd));

            occurrences.forEach(event => {
                const newEvent = {
                    start: event.toISOString(true),
                    end: moment(event + duration).toISOString(true),
                    employees: job.employees,
                    jobID: job.id,
                    //title: `${job.title + ' -' } ${job.worksiteName ? job.worksiteName + '/' : ''} ${job.customerLabel} `,
                    title: change.title ? change.title : `${job.title + ' -'} ${job.worksiteName ? job.worksiteName + '/' : ''} ${job.customerLabel} `,
                    isOntime: change.isOntime ? change.isOntime : (job.isOntime ? job.isOntime : null),
                    endOntime: change.endOntime ? change.endOntime : (job.endOntime ? job.endOntime : null),
                    alternateTitle: change.title ? change.title : null,
                    alternateMaxEmp: change.maxEmp ? change.maxEmp : null,
                    changeImpact: change.impact,
                };
                eventCopy.push(newEvent);
            });
        }
    }

    return eventCopy;
};

