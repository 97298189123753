export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0);


export const checkNested = (obj, level, ...rest) => {
    if (obj === undefined) return false;
    if (rest.length === 0 && obj.hasOwnProperty(level)) return true;
    return checkNested(obj[level], ...rest);
};

export const findValueInObject = (arr, target) => {
    if (arr === undefined) return false;

    const newArr = [];
    let foundTarget = "";

    arr.forEach(obj => {
        if (obj.hasOwnProperty(target)) return foundTarget = obj[target];
        Object.keys(obj).forEach(key => {
            if (obj[key].hasOwnProperty(target)) return foundTarget = obj[key][target];
            if (typeof obj[key] === "object" && !Array.isArray(obj[key])) newArr.push(obj[key])
        });
    });

    if (foundTarget) return foundTarget;
    if (newArr.length === 0) return false;
    return findValueInObject(newArr, target);
};
