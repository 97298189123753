import React from 'react';

import { withTranslation } from "react-i18next";
import { Checkbox, Collapse, FormControlLabel } from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import "./ModuleUserPermissions.css";

const ModuleUserPermissions = props => {
    const { itemOpen, selectedUser, t } = props;

    console.log("selected user " , selectedUser)
    return (
        <div className="module-permissions">
            <div className="module-permission-title" onClick={() => props.openItem("management")}>
                <h3>{t("Administration")}</h3>
                {itemOpen.includes("management") && <ExpandLess />}
                {!itemOpen.includes("management") && <ExpandMore />}
            </div>
            <Collapse in={itemOpen.includes("management")}>
                <div className="module-list-container">
                    <div className="module-list-left">

                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.mainUser.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.mainUser : false}
                                    onChange={event => props.onChange(event, selectedUser, "mainUser")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("MainUser_CB")}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.management.admin.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.management.admin : false}
                                    onChange={event => props.onChange(event, selectedUser, "management", "admin")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Management_admin")}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.admin.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.admin : false}
                                    onChange={event => props.onChange(event, selectedUser, "time", "admin")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Time_admin")}
                        />

                        <br />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.reports.admin.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.reports.admin : false}
                                    onChange={event => props.onChange(event, selectedUser, "reports", "admin")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Reports_admin")}
                        />
                    </div>

                    <div>


                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.billing.admin.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.billing.admin : false}
                                    onChange={event => props.onChange(event, selectedUser, "billing", "admin")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Billing_admin")}
                        />

                        <br />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.crm?.admin?.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.crm?.admin : false}
                                    onChange={event => props.onChange(event, selectedUser, "crm", "admin")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Crm_admin")}
                        />

                        <br />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.admin.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.admin : false}
                                    onChange={event => props.onChange(event, selectedUser, "keys", "admin")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Keys_admin")}
                        />

                        <br />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.registry.admin.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.registry.admin : false}
                                    onChange={event => props.onChange(event, selectedUser, "registry", "admin")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Registry_admin")}
                        />

                        <br />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.spotOn?.admin?.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.spotOn?.admin : false}
                                    onChange={event => props.onChange(event, selectedUser, "spotOn", "admin")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("SpotOn_admin")}
                        />

                    </div>

                </div>
            </Collapse>

            <div className="module-permission-title" onClick={() => props.openItem("Management_settings")}>
                <h3>{t("Management_settings")}</h3>
                {itemOpen.includes("Management_settings") && <ExpandLess />}
                {!itemOpen.includes("Management_settings") && <ExpandMore />}
            </div>
            <Collapse in={itemOpen.includes("Management_settings")}>
                <div className="module-list-column">
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.management.jobList.toString() : "false"}
                                checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.management.jobList : false}
                                onChange={event => props.onChange(event, selectedUser, "management", "jobList")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("View jobs")}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.management.createAndEdit.toString() : "false"}
                                checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.management.createAndEdit : false}
                                onChange={event => props.onChange(event, selectedUser, "management", "createAndEdit")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("Create_edit")}
                    />
                </div>

            </Collapse>

            <div className="module-permission-title" onClick={() => props.openItem("time")}>
                <h3>{t("Time")}</h3>
                {itemOpen.includes("time") && <ExpandLess />}
                {!itemOpen.includes("time") && <ExpandMore />}
            </div>
            <Collapse in={itemOpen.includes("time")}>
                <div className="module-list-container">
                    <div className="module-list-left">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.mobileApplication.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.mobileApplication : false}
                                    onChange={() => props.onCategoryChange(selectedUser, "time", "mobile")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Mobile application")}
                        />
                        <ul>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.autoJob.toString() : "false"}
                                            checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.autoJob : false}
                                            onChange={event => props.onChange(event, selectedUser, "time", "autoJob")}
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    }
                                    label={t("Automatic_tracking")}
                                />
                            </li>


                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.reportPartner.toString() : "false"}
                                            checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.reportPartner : false}
                                            onChange={event => props.onChange(event, selectedUser, "time", "reportPartner")}
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    }
                                    label={t("Log for partner")}
                                />
                            </li>


                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.addJob.toString() : "false"}
                                            checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.addJob : false}
                                            onChange={event => props.onChange(event, selectedUser, "time", "addJob")}
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    }
                                    label={t("Add new job")}
                                />
                            </li>


                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.addMaterials.toString() : "false"}
                                            checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.addMaterials : false}
                                            onChange={event => props.onChange(event, selectedUser, "time", "addMaterials")}
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    }
                                    label={t("Add_materials")}
                                />
                            </li>


                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.addCustomer.toString() : "false"}
                                            checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.addCustomer : false}
                                            onChange={event => props.onChange(event, selectedUser, "time", "addCustomer")}
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    }
                                    label={t("Add_customer")}
                                />
                            </li>


                        </ul>
                    </div>

                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.webApplication.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.webApplication : false}
                                    onChange={() => props.onCategoryChange(selectedUser, "time", "web")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Web application")}
                        />
                        <ul>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.ownReports.toString() : "false"}
                                            checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.ownReports : false}
                                            onChange={event => props.onChange(event, selectedUser, "time", "ownReports")}
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    }
                                    label={t("Check own reports")}
                                />
                            </li>

                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.modifyWorklogs.toString() : "false"}
                                            checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.time.modifyWorklogs : false}
                                            onChange={event => props.onChange(event, selectedUser, "time", "modifyWorklogs")}
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    }
                                    label={t("Edit_worklogs")}
                                />
                            </li>

                        </ul>
                    </div>
                </div>
            </Collapse>


            <div className="module-permission-title" onClick={() => props.openItem("reports")}>
                <h3>{t("Reports")}</h3>
                {itemOpen.includes("reports") && <ExpandLess />}
                {!itemOpen.includes("reports") && <ExpandMore />}
            </div>
            <Collapse in={itemOpen.includes("reports")}>
                <div className="module-list-container">
                    <ul>
                        <li>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.reports.timeReports.toString() : "false"}
                                        checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.reports.timeReports : false}
                                        onChange={event => props.onChange(event, selectedUser, "reports", "timeReports")}
                                        style={{
                                            color: '#1770B2'
                                        }}
                                    />
                                }
                                label={t("Time reports")}
                            />
                        </li>
                        <li>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={selectedUser && selectedUser.name ? selectedUser.settings.permissions?.reports?.billReports?.toString() : "false"}
                                        checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions?.reports?.billReports : false}
                                        onChange={event => props.onChange(event, selectedUser, "reports", "billReports")}
                                        style={{
                                            color: '#1770B2'
                                        }}
                                    />
                                }
                                label={t("Bill reports")}
                            />
                        </li>
                        <li>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={selectedUser && selectedUser.name ? selectedUser.settings.permissions?.reports?.workShifts?.toString() : "false"}
                                        checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.reports.workShifts : false}
                                        onChange={event => props.onChange(event, selectedUser, "reports", "workShifts")}
                                        style={{
                                            color: '#1770B2'
                                        }}
                                    />
                                }
                                label={t("Workshifts")}
                            />
                        </li>
                    </ul>
                </div>

            </Collapse>


            <div className="module-permission-title" onClick={() => props.openItem("billing")}>
                <h3>{t("Billing")}</h3>
                {itemOpen.includes("billing") && <ExpandLess />}
                {!itemOpen.includes("billing") && <ExpandMore />}
            </div>
            <Collapse in={itemOpen.includes("billing")}>
                <div className="module-list-container">
                    <FormControlLabel
                        control={
                            <Checkbox
                                value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.billing.manageBills.toString() : "false"}
                                checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.billing.manageBills : false}
                                onChange={event => props.onChange(event, selectedUser, "billing", "manageBills")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("Manage_bills")}
                    />
                </div>

            </Collapse>


            <div className="module-permission-title" onClick={() => props.openItem("crm")}>
                <h3>{t("CRM")}</h3>
                {itemOpen.includes("crm") && <ExpandLess />}
                {!itemOpen.includes("crm") && <ExpandMore />}
            </div>
            <Collapse in={itemOpen.includes("crm")}>
                <div className="module-list-column">

                    <FormControlLabel
                        control={
                            <Checkbox
                                value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.crm?.email?.toString() : "false"}
                                checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.crm?.email : false}
                                onChange={event => props.onChange(event, selectedUser, "crm", "email")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("CRM email")}
                    />



                </div>

            </Collapse>

            <div className="module-permission-title" onClick={() => props.openItem("keyModule")}>
                <h3>{t("Key module")}</h3>
                {itemOpen.includes("keyModule") && <ExpandLess />}
                {!itemOpen.includes("keyModule") && <ExpandMore />}
            </div>
            <Collapse in={itemOpen.includes("keyModule")}>
                <div className="module-list-container">
                    <div className="module-list-left">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.mobileApplication.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.mobileApplication : false}
                                    onChange={() => props.onCategoryChange(selectedUser, "keys", "mobile")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Mobile application")}
                        />
                        <ul>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.registerKey.toString() : "false"}
                                            checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.registerKey : false}
                                            onChange={event => props.onChange(event, selectedUser, "keys", "registerKey")}
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    }
                                    label={t("Add_key_system")}
                                />
                            </li>


                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.returnCustomer.toString() : "false"}
                                            checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.returnCustomer : false}
                                            onChange={event => props.onChange(event, selectedUser, "keys", "returnCustomer")}
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    }
                                    label={t("Return_key_customer")}
                                />
                            </li>

                        </ul>

                    </div>

                    <div>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.webApplication.toString() : "false"}
                                    checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.webApplication : false}
                                    onChange={() => props.onCategoryChange(selectedUser, "keys", "web")}
                                    style={{
                                        color: '#1770B2'
                                    }}
                                />
                            }
                            label={t("Web application")}
                        />
                        <ul>
                            <li>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.manageKeys.toString() : "false"}
                                            checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.keys.manageKeys : false}
                                            onChange={event => props.onChange(event, selectedUser, "keys", "manageKeys")}
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    }
                                    label={t("Manage_keys")}
                                />
                            </li>

                        </ul>
                    </div>
                </div>
            </Collapse>

            <div className="module-permission-title" onClick={() => props.openItem("spotOn")}>
                <h3>{t("SpotOn")}</h3>
                {itemOpen.includes("spotOn") && <ExpandLess />}
                {!itemOpen.includes("spotOn") && <ExpandMore />}
            </div>
            <Collapse in={itemOpen.includes("spotOn")}>
                <div className="module-list-column">

                    <FormControlLabel
                        control={
                            <Checkbox
                                value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.spotOn?.vehicles?.toString() : "false"}
                                checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.spotOn?.vehicles : false}
                                onChange={event => props.onChange(event, selectedUser, "spotOn", "vehicles")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("Vehicles")}
                    />



                </div>

            </Collapse>

            <div className="module-permission-title" onClick={() => props.openItem("registry")}>
                <h3>{t("Registry")}</h3>
                {itemOpen.includes("registry") && <ExpandLess />}
                {!itemOpen.includes("registry") && <ExpandMore />}
            </div>
            <Collapse in={itemOpen.includes("registry")}>
                <div className="module-list-column">

                    <FormControlLabel
                        control={
                            <Checkbox
                                value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.registry.jobModels.toString() : "false"}
                                checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.registry.jobModels : false}
                                onChange={event => props.onChange(event, selectedUser, "registry", "jobModels")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("Job models")}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.registry.jobTemplates.toString() : "false"}
                                checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.registry.jobTemplates : false}
                                onChange={event => props.onChange(event, selectedUser, "registry", "jobTemplates")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("Job templates")}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.registry.products.toString() : "false"}
                                checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.registry.products : false}
                                onChange={event => props.onChange(event, selectedUser, "registry", "products")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("Products")}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                value={selectedUser && selectedUser.name ? selectedUser.settings.permissions.registry.customers.toString() : "false"}
                                checked={selectedUser && selectedUser.name ? selectedUser.settings.permissions.registry.customers : false}
                                onChange={event => props.onChange(event, selectedUser, "registry", "customers")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("Customers")}
                    />

                </div>

            </Collapse>

            


        </div>
    );
};

export default withTranslation()(ModuleUserPermissions)