import React, {Component, Fragment} from 'react';

import CircularLoader from "../util/CircularLoader";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core';

import {withTranslation} from 'react-i18next';

class ConfirmationDialog extends Component {
    render() {
        const {t, worklogsSaving, worklogsSaved, selectedWorklogs} = this.props;
        //console.log("ConfirmationDialog selectedWorklogs ", selectedWorklogs);

        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.props.onClose()}
                maxWidth="sm"
            >
                {!worklogsSaving && !worklogsSaved && <DialogTitle>{`${t("Change status")}?`}</DialogTitle>}
                {worklogsSaving && worklogsSaved && <DialogTitle>{`${t("Saved_succesfully")}`}</DialogTitle>}
                <DialogContent>
                    {!worklogsSaving && !worklogsSaved &&
                    <Fragment>
                        {selectedWorklogs &&
                        <p>{`${t("Number_of_selected")}: ` + selectedWorklogs.length}</p>
                        }
                        <br/>
                        <p>{`${t("Change_message")}:`}</p>
                        <p style={{
                            marginLeft: 100,
                            marginTop: 20,
                            fontSize: 18,
                            fontWeight: 'bold'
                        }}>{this.props.confirmationText}</p>
                    </Fragment>
                    }
                    <CircularLoader loading={worklogsSaving && !worklogsSaved}/>
                </DialogContent>
                <DialogActions>

                    {!worklogsSaving && !worklogsSaved &&
                    <Fragment>
                        <Button
                            variant="outlined"
                            onClick={() => this.props.onClose()}
                        >
                            {t("Cancel")}
                        </Button>

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.props.onConfirm()}
                        >
                            {t("Confirm")}
                        </Button>
                    </Fragment>
                    }
                    {worklogsSaving && worklogsSaved &&
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={() => this.props.onClose()}
                    >
                        {t("Close")}
                    </Button>
                    }
                </DialogActions>

            </Dialog>
        );
    }
}


export default withTranslation()(ConfirmationDialog);
