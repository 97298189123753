import React, {useState, useEffect} from 'react';

import {
    TextField,
    MenuItem, Button,
    ClickAwayListener,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core';

import AutocompleteReports from '../AutocompleteReports';
import {withTranslation} from 'react-i18next';
import {DateRange} from 'react-date-range';
import { fi, enGB} from 'date-fns/locale'

import moment from 'moment';
import {connect} from 'react-redux';
import {isEmpty} from "../../validations/isEmpty";

const CustomerComponent = props => {
    const {t, i18n} = props;
    const {user} = props.user;

    const [menu, setMenu] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [searchDisabled, setSearchDisabled] = useState(false)

    const handleMenu = event => {
        setMenu(true);
        setAnchor(event.getBoundingClientRect());
    };

    const closeMenu = () => {
        setMenu(false);
        setAnchor(null)
    };

    const handleDate = event => {
        props.onChange(event, 'date');

        closeMenu();
    };

    useEffect (() => {
        console.log("useEffect search allowed? err ", props.errors)
        if (isEmpty(props.selectedCustomer)) {
            console.log(" search 1")
            setSearchDisabled(true);
        } else {
            console.log(" search 2")
            setSearchDisabled(false);
        }
    }, [props.selectedCustomer]);


    return (
        <div>
            <TextField
                variant="outlined"
                label={t("Report type")}
                style={{
                    width: '100%'
                }}
                value={props.reportType}
                onChange={event => props.onChange(event, 'reportType')}
                select
            >
                <MenuItem key="select" value="select">{t("Select type")}</MenuItem>
                <MenuItem key="customer" value="customer">{t("Customer report")}</MenuItem>
                <MenuItem key="period" value="period">{t("Period")}</MenuItem>
                <MenuItem key="hour" value="hour">{t("Hour")}</MenuItem>
                <MenuItem key="approval" value="approval">{t("Approval")}</MenuItem>
            </TextField>

            <AutocompleteReports
                searchArray={props.customers}
                field="Customer"
                label={t("Search customers")}
                onSelect={event => props.onChange(event, 'customer')}

            />
            {props.errors.customer && props.errors.customer.length > 0 && <p style={{color: '#B00', marginLeft: 60}}>{props.errors.customer}</p>}

            {props.selectedCustomer &&
            <Button
                variant="outlined"
                color="primary"
                style={{ width: '100%', marginTop: 10}}
                onClick={() => props.onChange(true, "worksite")}
            >
                {t("Select worksite")} (optional)
            </Button>
            }

            {/*
            <AutocompleteReports
                searchArray={props.employees}
                field="Employee"
                label={t("Search employees")}
                onSelect={event => props.onChange(event, 'employee')}
            />
            */}

            <TextField
                variant="outlined"
                label={t("Select range")}
                style={{
                    width: '100%',
                    marginTop: 10
                }}
                value={`${moment(props.dateRange.selection.startDate).locale(user.lang ? user.lang : "fi").format('L')} - ${moment(props.dateRange.selection.endDate).locale(user.lang ? user.lang : "fi").format('L')}`}
                onClick={event => handleMenu(event.currentTarget)}
            />
            {props.errors.date.length > 0 && <p style={{color: '#B00', marginLeft: 60}}>{props.errors.date}</p>}


            {menu &&
            <ClickAwayListener onClickAway={closeMenu}>
                <div
                    style={{
                        position: 'absolute',
                        top: anchor.y + anchor.height,
                        left: anchor.x,
                        zIndex: 10,
                        backgroundColor: '#FFF',
                        width: anchor.width - 2,
                        borderStyle: 'solid',
                        borderColor: '#ccc',
                        borderWidth: 1,
                        borderRadius: 4,
                    }}
                >
                    <div style={{marginLeft: 30, marginTop: 10}}>
                        <DateRange
                            moveRangeOnFirstSelection={false}
                            ranges={[props.dateRange.selection]}
                            style={{
                                width: '100%',
                            }}
                            locale={i18n.language === "fi" ? fi : enGB}
                            onChange={event => handleDate(event)}
                        />
                    </div>


                </div>
            </ClickAwayListener>
            }

            <br/>

            {/*
            <h4 style={{marginLeft: 5}}>{t("ReportDescription")}</h4>

            <RadioGroup
                value={props.descriptionField}
                onChange={event => props.onChange(event, "descriptionField")}
                style={{
                    marginLeft: 5,
                }}
            >
                <FormControlLabel value={"task"} control={<Radio color="primary"/>} label={t("FromTasks")} />
                <FormControlLabel value={"description"} control={<Radio color="primary"/>} label={t("FromDescription")} />
            </RadioGroup>

            <br/>
            */}

            <Button
                variant="outlined"
                style={{
                    marginTop: 20,
                    marginLeft: 50
                }}
                onClick={() => props.onReset()}
            >
                {t("Reset")}
            </Button>

            <Button
                disabled={searchDisabled}
                variant="outlined"
                color={searchDisabled ? "secondary" : "primary" }
                style={{
                    marginLeft: 50,
                    marginTop: 20,
                    //color: searchDisabled ? 'grey' : 'red',
                }}
                onClick={() => props.onSearch()}
            >
                {t("Search")}
            </Button>
        </div>
    );
};

const mapsStateToProps = state => ({
    user: state.user
});

export default connect(mapsStateToProps, {})(withTranslation()(CustomerComponent));
