import React from 'react';

const ToolTip = props => {
    const {anchor, position, message} = props;

    let tooltipPosition = {};

    if (position === "right") {
        tooltipPosition = {
            top: anchor.top - 19,
            left: anchor.left + anchor.width + 12
        }
    }

    return (
        <div
            style={{
                position: "absolute",
                ...tooltipPosition,
                zIndex: 10,
                width: 150,
                borderStyle: "solid",
                borderColor: "#ccc",
                borderWidth: 0.5,
                borderRadius: 4,
                backgroundColor: "#FFF",
                padding: 5
            }}
        >
            {message}
        </div>
    );
};

export default ToolTip;