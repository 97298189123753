import React, {useState} from 'react';

import {ClickAwayListener} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSortDown} from '@fortawesome/free-solid-svg-icons'

import PropTypes from 'prop-types';
import "./WorklogSelect.css";
export const WorklogSelect = props => {
    const {value, placeHolder} = props;
    const [menuOpen, setMenuOpen] = useState(null);

    const onMenuOpen = event => {
        const currentTarget = event.currentTarget.getBoundingClientRect();
        setMenuOpen(currentTarget)
    };

    const onMenuItemSelect = value => {
        props.onChange(value);
        setTimeout(() => {
            setMenuOpen(null);
        }, 20)
    };

    return (
        <div className="wl-select" onClick={onMenuOpen}>
            <div className="wl-input">
                {!value && <p className="wl-placeholder">{placeHolder}</p>}
                {value && <p>{value}</p>}
                <FontAwesomeIcon icon={faSortDown} className="wl-input-icon"/>
            </div>

            {menuOpen !== null &&
                <ClickAwayListener onClickAway={() => setMenuOpen(null)}>
                    <div className="wl-menu" style={{top: menuOpen.top + 45, left: menuOpen.left, width: menuOpen.width}}>
                        {props.children.map(child => (
                            <div onClick={() => onMenuItemSelect(child.props.value)} key={child.props.value}>
                                {child}
                            </div>
                        ))}
                    </div>
                </ClickAwayListener>

            }
        </div>
    )
};

WorklogSelect.propTypes = {
    value: PropTypes.any,
    placeHolder: PropTypes.string,
    onChange: PropTypes.func,
};

export const SelectItem = props => {
    return (
        <div className="wl-select-item">{props.children}</div>
    )
};
