import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';

import {
    TextField,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    FormControlLabel
} from '@material-ui/core';

import {withTranslation} from "react-i18next";

class CustomerDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            customers: [],
            searchArr: [],
            selectedCustomers: [],
            searchTerm: "",
        };
    }

    componentWillMount() {
        const {user} = this.props.user;
        this.unsubscribeCustomerListener =
            firebase.firestore().collection('customers')
                .where('companyID', 'array-contains', user.companyID)
                .onSnapshot(this.onCustomerSnapshot);

        if (this.props.selectedCustomers) {
            const selectedCustomers = [];
            this.props.selectedCustomers.forEach(customer => {
                selectedCustomers.push(customer.id)
            });
            this.setState({
                selectedCustomers: selectedCustomers
            });
        }
    }

    onCustomerSnapshot = querySnapshot => {
        const {user} = this.props.user;
        const customers = [];

        querySnapshot.forEach(doc => {
            const customer = doc.data();
            customer.id = doc.id;

            if (customer.companyID.includes(user.companyID)) {
                customers.push(customer)
            }
        });
        this.setState({
            customers: customers,
            dialogOpen: true
        });
    };

    componentWillUnmount() {
        this.unsubscribeCustomerListener && this.unsubscribeCustomerListener();
    }

    customerSearch = event => {
        const {customers} = this.state;

        const term = event.target.value;
        const searchArr = [];

        customers && customers.forEach(customer => {
            const nameSearch = customer.name.toLowerCase().search(term.toLowerCase());
            if (nameSearch > -1) {
                searchArr.push(customer)
            }
        });
        this.setState({
            searchArr: searchArr,
            searchTerm: term
        });
    };

    customerSelect = id => {
        let {selectedCustomers} = this.state;

        if (!selectedCustomers.includes(id)) {
            selectedCustomers.push(id);
        }
        else {
            const newArr = selectedCustomers.filter(customer => customer !== id);
            selectedCustomers = newArr;
        }

        this.setState({
            selectedCustomers: selectedCustomers
        });
    };

    onSave = () => {

        const customers = [];

        this.state.customers.forEach(customer => {
            this.state.selectedCustomers.forEach(id => {
                if (customer.id === id) {
                    const newCustomer = {
                        name: customer.name,
                        id: id
                    };
                    customers.push(newCustomer)
                }
            }) ;
        });

        this.props.onAccept(customers);
    };

    render() {
        const {t} = this.props;
        return (
            <Dialog
                open={this.state.dialogOpen}
                onClose={() => this.props.onClose()}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle>{t("Select customers")}</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        label={t("Search customers")}
                        style={{
                            marginTop: 10,
                            width: '100%'
                        }}
                        value={this.state.searchTerm}
                        onChange={event => this.customerSearch(event)}
                    />
                    <div style={{width: '100%',height: 300, overflowY: 'auto'}}>
                        {this.state.searchTerm.length === 0 && this.state.customers.map(customer => (
                            <div key={customer.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.selectedCustomers.includes(customer.id)}
                                            onChange={() => this.customerSelect(customer.id)}
                                            color="primary"
                                        />
                                    }
                                    label={customer.name}
                                />
                                <br/>
                            </div>
                        ))}
                        {this.state.searchTerm.length > 0 && this.state.searchArr.map(customer => (
                            <div key={customer.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.selectedCustomers.includes(customer.id)}
                                            onChange={() => this.customerSelect(customer.id)}
                                            color="primary"
                                        />
                                    }
                                    label={customer.name}
                                />
                                <br/>
                            </div>
                        ))}

                    </div>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.props.onClose()}
                    >
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.onSave()}
                        style={{
                            marginRight: 10
                        }}
                    >
                        {t("Save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(CustomerDialog));