import {combineReducers} from 'redux';
import userReducer from './userReducer';
import sendapprovalReducer from './sendapprovalReducer';
import sendReportReducer from './sendReportReducer';
import productReducer from './productReducer';
import worksiteReducer from './worksiteReducer';
import companyReducer from './companyReducer';
import worklogReducer from "./worklogReducer";
import customerReducer from "./customerReducer";
import employeeReducer from "./employeeReducer";
import modelReducer from "./modelReducer";

export default combineReducers({
    user: userReducer,
    products: productReducer,
    worksites: worksiteReducer,
    company: companyReducer,
    approval: sendapprovalReducer,
    report: sendReportReducer,
    worklogs: worklogReducer,
    customers: customerReducer,
    employees: employeeReducer,
    models: modelReducer,
});
