import React, {Component} from 'react';

import "./FilterMenu.css";
class FilterMenu extends Component{


    render() {
        const {anchor} = this.props;
        return (
            <div className="filterMenu" style={{top: (anchor.top + anchor.height) + 5, left: anchor.left - (100 - anchor.width / 2)}}>
                <div className="filterMenu-container">
                    <div className="filterMenu-notch">

                    </div>

                    <div className="filterMenu-content">
                        {this.props.children}
                    </div>
                </div>

            </div>
    );
    }
}

export default FilterMenu;
