import React from 'react';

import TextField from "./TextField";
import PropTypes from 'prop-types';
import './TextFieldWithLabel.css';
const TextFieldWithLabel = props => {
    const {inputStyles, containerStyles, label, value, error} = props;
    return (
        <div className="tf-label-container">
            <div className="tf-label-left">
                <p>{`${label}:`}</p>
            </div>

            <div className="tf-label-right">
                <TextField
                    inputStyles={inputStyles}
                    containerStyles={containerStyles}
                    value={value}
                    onChange={props.onChange}
                    select={props.select}
                    children={props.children}
                    error={error}
                />

                <p className="tf-error-text">{props.errorText}</p>

            </div>
        </div>
    )
};

TextFieldWithLabel.propTypes = {
    select: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    label: PropTypes.string.isRequired,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired
};

export default TextFieldWithLabel;
