//import React, {useState} from 'react';
import React, {Component} from 'react';

import {
    TextField,
    Button,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@material-ui/core';


import {withTranslation} from "react-i18next";

class CustomerDialog extends Component {
    //const [searchTerm, setSearchTerm] = useState("");
    //const [searchList, setSearchList] = useState([]);
    //const [selectionList, setSelectionList] = useState(props.selectedCustomers);
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: "",
            searchList: [],
            selectionList: this.props.selectedCustomers
        };
    }

    searchCustomers = event => {
        const searchTerm = event.target.value;

        const searchList = [];

        this.props.customers && this.props.customers.forEach(customer => {
            if (customer.name.toLowerCase().search(searchTerm.toLowerCase()) > -1) searchList.push(customer);
        });

        //setSearchTerm(searchTerm);
        //setSearchList(searchList);
        this.setState({searchTerm, searchList})
    };

    onCustomerSelect = customer => {
        const list = this.state.selectionList;

        if (!list.includes(customer)) list.push(customer);
        else {
            const removeIndex = list.indexOf(customer);
            list.splice(removeIndex, 1);
        }

        //setSelectionList(list);
        this.setState({selectionList: list});
    };

    renderListItem = item => {
        const {selectionList} = this.state;

        return (
            <div key={item.id} style={{display: 'flex', marginTop: 10}}>
                <div style={{width: "20%", textAlign: 'center'}}>
                    <Checkbox
                        style={{
                            color: '#1770B2',
                            margin: 0,
                            padding: 0
                        }}
                        checked={selectionList.includes(item)}
                        onChange={() => this.onCustomerSelect(item)}
                    />
                </div>
                <div style={{width: "30%", textAlign: 'center', paddingTop: 5}}>{item.name}</div>
                <div style={{width: "50%", textAlign: 'center', paddingTop: 5, overflow: 'auto'}}>{item.email}</div>
            </div>
        )
    };

    render() {
        const {searchList, searchTerm, selectionList} = this.state;

        return (

            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Asiakasvalinta</DialogTitle>
                <DialogContent>
                    <TextField
                        variant="outlined"
                        placeholder="Etsi..."
                        InputProps={{
                            style: {height: 30}
                        }}
                        value={searchTerm || ""}
                        onChange={this.searchCustomers}
                        fullWidth
                    />

                    <div style={{borderBottom: "0.5px solid #1770B2", display: 'flex', marginTop: 25}}>
                        <div style={{width: "20%", textAlign: 'center'}}>{""}</div>
                        <div style={{width: "30%", textAlign: 'center'}}>As. nimi</div>
                        <div style={{width: "50%", textAlign: 'center'}}>As. sähköposti</div>
                    </div>

                    <div style={{width: '100%', height: 300, overflow: "auto"}}>
                        {searchTerm.length === 0 && this.props.customers.map(this.renderListItem)}
                        {searchTerm.length > 0 && searchList.map(this.renderListItem)}
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.props.onConfirm(selectionList)}
                    >
                        Valitse
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
;

export default withTranslation()(CustomerDialog);

