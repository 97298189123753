import React, {Component} from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/functions";
import {
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress,
} from '@material-ui/core';

import {validateCrmEmail} from "../validations/crmEmail";
import CustomerDialog from "./CRMCustomerDialog";
// Import React FilePond
import {FilePond, registerPlugin} from "react-filepond";

import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

import {
    Chip,
} from "@material-ui/core";

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";

import "./EmailComponent.css";

registerPlugin(FilePondPluginFileEncode);

class EmailComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            customers: [],
            company: {},
            customerMethod: "select",
            selectedCustomers: [],
            useAttachment: false,
            showAttachment: false,
            email: {},
            attachmentName: "",
            attachmentString: "",
            attachmentLoading: false,
            fileCounter: 0,
            customerSelectDialog: false,
            errors: {},
            emailSent: false,
            sending: false,
            activeStatus: true,
        }
    }

    componentDidMount() {
        const {user} = this.props.user;

        this.unsubCustomers = firebase.firestore().collection("customers")
            .where("companyID", "array-contains", user.companyID)
            .onSnapshot(querySnap => {
                const customers = [];

                querySnap.forEach(doc => {
                    const customer = doc.data();
                    customer.id = doc.id;
                    customers.push(customer)
                });

                this.setState({customers, loading: false});
            });

        this.unsubCompany = firebase.firestore().collection("company").doc(user.companyID).onSnapshot(docSnap => {
            const company = docSnap.data();
            company.id = docSnap.id;

            const email = {
                title: "",
                message: "",
                emailAddress: company.email ? company.email : "",
            };

            this.setState({company, email});
        });
    }

    componentWillUnmount() {
        this.unsubCustomers && this.unsubCustomers();
        this.unsubCompany && this.unsubCompany();
    }

    formSendingList = event => {
        const {customers} = this.state;

        const customerMethod = event.target.value;
        if (customerMethod === "select") return this.setState({customerMethod, selectedCustomers: []});
        if (customerMethod === "ownselection") return this.setState({customerMethod, selectedCustomers: []});

        let selectedCustomers = []

        if (customerMethod === "all") selectedCustomers = customers;
        if (customerMethod === "allCompany") selectedCustomers = customers.filter(customer => customer.type && customer.type === "company")
        if (customerMethod === "allPrivate") selectedCustomers = customers.filter(customer => (!customer.type || customer.type && customer.type === "private")); //no type defaults to private

        this.setState({
            customerMethod,
            selectedCustomers
        }, () => this.filterSendingListByActiveStatus());
    };

    filterSendingListByActiveStatus = () => {
        const {selectedCustomers, activeStatus} = this.state;
        console.log("filterSendingListByActiveStatus ", activeStatus);
        const list = selectedCustomers.filter(customer => customer.active.toString() === activeStatus.toString());
        console.log("filterSendingListByActiveStatus filtered list ", list);
        this.setState({ selectedCustomers: list });
    }

    onFieldChange = (event, field) => {
        const {email, errors} = this.state;

        email[field] = event.target.value;
        errors[field] = "";

        this.setState({email, errors});
    };

    onFiles = fileArr => {
        const {fileCounter} = this.state;

        if (fileCounter > 0 && fileCounter % 2 === 0) {
            fileArr && fileArr.forEach(uploadedFile => {
                const dataUrl = uploadedFile.getFileEncodeBase64String();
                const attachmentString = `data:application/pdf;base64,${dataUrl}`;

                this.setState({attachmentString, files: fileArr})
            });
        }


        if (fileArr.length === 0) this.setState({fileCounter: 0, files: []});
    };


    sendMassMail = () => {
        const {email, useAttachment, attachmentString, selectedCustomers} = this.state;


        email.useAttachment = useAttachment;
        email.attachmentString = attachmentString.replace("data:application/pdf;base64,", "");
        email.selectedCustomers = selectedCustomers;
        // Validate email
        const {errors, isValid} = validateCrmEmail(email);

        if (!isValid) return this.setState({errors});

        this.setState({sending: true})

        while(selectedCustomers.length) {
            this.setState({sending: true})
            const customersChunk = selectedCustomers.splice(0,10);
            console.log("customersChunk ", customersChunk);
            email.selectedCustomers = customersChunk;

            firebase.functions().httpsCallable("crmEmail")(email)
                .then(() => {
                    //this.setState({emailSent: true, sending: false});
                    console.log("emailSent for customers: ", email.selectedCustomers)
                })
                .catch(err => {
                    console.log(err);
                    //this.setState({emailSent: false, sending: false});
                })


            console.log("==================================");
            //this.setState({sending: false})
            this.sleep(1000);
        }

        this.setState({emailSent: true, sending: false});

        /*
            firebase.functions().httpsCallable("crmEmail")(email)
                .then(() => {
                    this.setState({emailSent: true, sending: false});
                    console.log("emailSent")
                })
                .catch(err => {
                    console.log(err);
                this.setState({emailSent: false, sending: false, message: err});
            })
        */


    };

    sleep = (milliseconds) =>  {
        const date = Date.now();
        let currentDate = null;
        do {
            currentDate = Date.now();
        } while (currentDate - date < milliseconds);
    }

    renderConfirmationDialog = () => {
        return (
            <Dialog
                open={this.state.emailSent}
                onClose={() => this.setState({emailSent: false})}
            >
                <DialogTitle>{this.props.t("Emails_sent")}</DialogTitle>

                <DialogContent>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.setState({emailSent: false})}
                        fullWidth
                    >
                        {this.props.t("Close")}
                    </Button>
                </DialogContent>
            </Dialog>
        )
    };

    chipDeleteHandler = item => {
        const {selectedCustomers} = this.state;
        let list = selectedCustomers.filter(customer => customer.id !== item.id);

        this.setState({
            selectedCustomers: list
        });
    };

    render() {
        const {email, errors, selectedCustomers, sending} = this.state;
        const {t} = this.props;
        return (
            <div className="crm-email-container">
                <div className="crm-flexbox">

                    <div className="email-container">
                        <TextField
                            variant="outlined"
                            label="Valitse lähetyslista"
                            style={{
                                width: '40%'
                            }}
                            value={this.state.customerMethod || ""}
                            onChange={event => this.formSendingList(event)}
                            error={!!errors.selectedCustomers}
                            helperText={errors.selectedCustomers ? t(errors.selectedCustomers) : ""}
                            select
                        >
                            <MenuItem value="select">Valitse</MenuItem>
                            <MenuItem value="ownselection">Oma valinta</MenuItem>
                            <MenuItem value="all">Kaikki</MenuItem>
                            <MenuItem value="allPrivate">Kaikki Yksitysasiakkaat</MenuItem>
                            <MenuItem value="allCompany">Kaikki Yritysasiakkaat</MenuItem>

                        </TextField>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{color: "#1770B2"}}
                                    checked={this.state.activeStatus}
                                    onChange={() => this.setState({activeStatus: !this.state.activeStatus}, () => this.filterSendingListByActiveStatus())}
                                />
                            }
                            label={this.state.activeStatus ? "Vain Aktiiviset" : "Vain Ei-Aktiiviset"}
                        />


                        {this.state.customerMethod === "ownselection" &&
                        <div style={{width: '40%', marginTop: 10}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                fullWidth
                                onClick={() => this.setState({customerDialog: true})}
                            >
                                Valinta
                            </Button>
                        </div>
                        }

                        <TextField
                            variant="outlined"
                            value={email.emailAddress || ""}
                            style={{
                                width: '40%',
                                marginTop: 10
                            }}
                            label="Lähettäjän sähköposti"
                            onChange={event => this.onFieldChange(event, "emailAddress")}
                            error={!!errors.emailAddress}
                            helperText={errors.emailAddress ? t(errors.emailAddress) : ""}
                        />


                        <TextField
                            variant="outlined"
                            label="Sähköpostin otsikko"
                            style={{
                                marginTop: 10,
                                width: '40%'
                            }}
                            value={email.title || ""}
                            onChange={event => this.onFieldChange(event, "title")}
                            error={!!errors.title}
                            helperText={errors.title ? t(errors.title) : ""}
                        />


                        <TextField
                            variant="outlined"
                            label="Sähköpostin viesti"
                            style={{
                                marginTop: 10
                            }}
                            rows={5}
                            multiline
                            value={email.message || ""}
                            onChange={event => this.onFieldChange(event, "message")}
                            error={!!errors.message}
                            helperText={errors.message ? t(errors.message) : ""}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    style={{color: "#1770B2"}}
                                    checked={this.state.useAttachment}
                                    onChange={() => this.setState(prevState => ({useAttachment: !prevState.useAttachment}))}
                                />
                            }
                            label="Käytä liitettä"
                        />

                        {this.state.useAttachment &&
                        <div style={{width: '100%'}}>
                            <div style={{width: '40%'}}>
                                <FilePond
                                    files={this.state.files}
                                    allowMultiple={false}
                                    maxFiles={1}
                                    onInit={() => ""}
                                    onupdatefiles={files => this.setState(prevState => ({
                                        fileCounter: prevState.fileCounter + 1,
                                        showAttachment: false
                                    }), () => this.onFiles(files))}
                                    allowFileEncode={true}
                                />
                            </div>


                            <TextField
                                variant="outlined"
                                label="Liitteen nimi"
                                style={{
                                    marginTop: 10,
                                    width: "40%"
                                }}
                                InputProps={{
                                    endAdornment: <p style={{color: '#ccc', margin: 0, padding: 0}}>.pdf</p>
                                }}
                                value={email.pdfName || ""}
                                onChange={event => this.onFieldChange(event, "pdfName")}
                                error={!!errors.pdfName}
                                helperText={errors.pdfName ? t(errors.pdfName) : ""}
                            />


                        </div>
                        }


                    </div>

                    {this.state.useAttachment &&
                    <div className="attachment-container">
                        <Button
                            variant="outlined"
                            color={"primary"}
                            onClick={() => this.setState(prevState => ({showAttachment: !prevState.showAttachment}))}
                            style={{width: 200}}
                        >
                            {!this.state.showAttachment && "Näytä liite"}
                            {this.state.showAttachment && "Piiloita liite"}
                        </Button>


                        <br/>

                        {!this.state.attachmentLoading && this.state.showAttachment &&
                        <object data={this.state.attachmentString} width="100%" height={600} style={{marginTop: 20}}/>
                        }
                    </div>
                    }

                </div>

                <div className="crm-send-row">

                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.sendMassMail}
                        disabled={sending}
                    >
                        {sending &&
                        <div style={{
                            alignSelf: 'right',
                            width: 'auto',
                            height: 'auto',
                        }}>
                            <CircularProgress/>
                        </div>
                        }
                        Lähetä
                    </Button>
                </div>


                {selectedCustomers && selectedCustomers.length > 0 &&
                <div style={{flex: 1, height: 45 + 32 * selectedCustomers.length}}>
                    <div style={{
                        height: 25,
                        backgroundColor: '#ccc',
                        width: 481,
                        marginTop: 5,
                        marginBottom: 5,
                        marginLeft: 10
                    }}>
                        <p style={{fontSize: 12, marginLeft: 5, paddingTop: 2, paddingBottom: 5}}>Valittuna</p>
                    </div>

                    {/*
                        <div key={customer.id} style={{flex: 1, height: 32, overflow: 'auto', marginLeft: 15,}}>
                            {customer.email}
                        </div> */}

                    {selectedCustomers.map(customer => customer.email ? (
                            <div key={customer.id} style={{flex: 1, height: 32, overflow: 'auto', marginLeft: 15,}}>
                                <Chip
                                    key={customer}
                                    label={customer.email}
                                    onDelete={() => this.chipDeleteHandler(customer)}
                                />
                            </div>
                        ) : (
                            <div key={customer.id}
                                 style={{flex: 1, color: 'red', height: 32, overflow: 'auto', marginLeft: 15,}}>
                                Asiakkaalla {customer.name} ei ole sähköpostia
                            </div>)
                    )}
                </div>
                }


                {this.state.customerDialog &&
                <CustomerDialog
                    open={this.state.customerDialog}
                    onClose={() => this.setState({customerDialog: false})}
                    onConfirm={list => this.setState({selectedCustomers: list, customerDialog: false})}
                    customers={this.state.customers}
                    selectedCustomers={selectedCustomers}
                />
                }

                {this.state.emailSent &&
                this.renderConfirmationDialog()
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(EmailComponent));