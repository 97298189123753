import React, {Component} from 'react';

import {
    Button,
    FormControlLabel,
    Radio,
    ClickAwayListener,
    TextField,
} from "@material-ui/core";
import moment from 'moment';
import "moment/locale/fi";
//import "moment/locale/en-gb";
import AutocompleteReports from '../AutocompleteReports';
//import DateMenuComponent from './DateMenuComponent';
import {DateRange} from 'react-date-range';
import {fi, enGB} from 'date-fns/locale'

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";

class HourComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchData: {
                employee: "",
                start: this.props.dateRange.start ? moment(this.props.dateRange.start) : moment().subtract(1, 'month').startOf('month'),
                end: this.props.dateRange.end ? moment(this.props.dateRange.end) : moment().subtract(1, 'month').endOf('month'),
                accuracy: "billable"
            },
            datePicker: false,
            pickField: "",
            anchor: {},
            menu: false,
        };
    }



    handleMenu = event => {
        this.setState({
            menu: true,
            anchor: event.getBoundingClientRect(),
        })
        //setMenu(true);
        //setAnchor(event.getBoundingClientRect());
    };

    closeMenu = () => {
        this.setState({
            menu: false,
            anchor: {},
        })
        //setMenu(false);
        //setAnchor(null)
    };

    handleDate = event => {
        console.log("handleDate with date range ", event)
        this.props.onChange(event, 'date');  //this will chenge daterange for all timereports
        //set time also for this component's serachdata
        this.onDataChange(event.selection.startDate, "start");
        this.onDataChange(event.selection.endDate, "end");
        this.closeMenu();
    };


    onDataChange = (event, field) => {
        const {searchData} = this.state;

        //if (field === "end") { searchData[field] = moment(event).endOf("day"); }
        //else
        searchData[field] = event;

        this.setState({
            searchData,
            pickField: "",
            datePicker: false,
        });
    };

    formSearchList = () => {
        const {searchData} = this.state;
        const workTimes = {
            normal: 0,
            normalByWeek: {},
            evenings: 0,
            holiday: 0,
            worklogs: []
        };

        const ref = {};
        const refByWeek = {};

        const worklogs = [];

        console.log("formSearchList with searchData ", searchData)

        this.props.worklogs.forEach(worklog => {

            if (moment(worklog.startDT).isBetween(searchData.start, searchData.end) && (worklog.status === "Finished" || worklog.status === "Submitted" || worklog.status === "Confirmed" || worklog.status === "Billed")) {
                if ((worklog.previousStatus && worklog.previousStatus !== "Cancelled") || !worklog.previousStatus) worklogs.push(worklog);

            }
            if (moment(worklog.startDT).isBetween(searchData.start, searchData.end) && ((worklog.status === "Cancelled" || worklog.previousStatus === "Cancelled") && worklog.billingPercent > 0.1 && worklog.invoiceText)) {
                //worklog.billing.billableHoursByTask[0].duration = (worklog.billing.billableHoursByTask[0].duration * worklog.billingPercent);
                worklogs.push(worklog);
            }
        });


        worklogs.forEach(log => {
            if (log.status === "Cancelled" || log.previousStatus === "Cancelled") console.log(log);
            const worklog = JSON.parse(JSON.stringify(log));

            worklog.employee && worklog.employee.forEach(emp => {
                const employee = JSON.parse(JSON.stringify(emp));

                // Format empty total summary
                if (!ref[employee]) {
                    ref[employee] = {...workTimes};
                }

                // Format empty week split
                if (!refByWeek[employee]) {
                    refByWeek[employee] = {}
                }

                // Format empty week on week split
                const weekNumber = moment(worklog.startDT).format("W");
                if (!refByWeek[employee][weekNumber]) {
                    refByWeek[employee][weekNumber] = {normal: 0, worklogs: []}
                }

                // Count hours here, and take in consideration cancelled times
                let workingHours = searchData.accuracy === "billable" ? (worklog.billing.billableHours ? (worklog.billing.billableHours / 3600000) : 0) : (worklog.duration ? (worklog.duration / 3600000) : 0);
                if (log.status === "Cancelled" || log.previousStatus === "Cancelled") workingHours *= worklog.billingPercent;

                // check times per task for the report use
                let workHoursPerTask = null;
                if (searchData.accuracy === "billable") {
                    //console.log('worklog.billing.billableHoursByTask ', worklog.billing.billableHoursByTask)
                    if (!worklog.billing.billableHoursByTask) {
                        console.log('NO billableHoursByTask ', worklog.id)
                        workHoursPerTask = {name: this.props.t("Unreported task"), duration: worklog.duration}

                    } else if (worklog.billing.billableHoursByTask.length > 1) {
                        workHoursPerTask = [];
                        worklog.billing.billableHoursByTask.forEach((task) => {
                            //console.log('worklog.billing.billableHoursByTask  task ', task)
                            workHoursPerTask.push({
                                name: task.name ? task.name : "-",
                                duration: task.duration ? task.duration / 3600000 : 0
                            })
                        });
                        //console.log('workHoursPerTask  ', workHoursPerTask)
                    } else if (worklog.billing.billableHoursByTask.length === 1) {
                        //console.log('worklog.billing.billableHoursByTask ONLY ONE TASK ', worklog.billing.billableHoursByTask)
                        const name = worklog.billing.billableHoursByTask[0].name ? worklog.billing.billableHoursByTask[0].name : "-";
                        const duration = worklog.billing.billableHoursByTask[0].duration ? worklog.billing.billableHoursByTask[0].duration / 3600000 : 0;
                        workHoursPerTask = {name: name, duration: duration};
                    }

                } else {
                    //worklog.tasks && task.duration - may not exist?
                    //TODO!

                }

                let pauseTime = 0; //TODO

                // Forms total between start and end times
                ref[employee].normal += workingHours;

                // Split worked hours by week
                refByWeek[employee][weekNumber].normal += workingHours;

                const simpleWorklog = {
                    employee: worklog.employee,
                    startDT: worklog.startDT,
                    customer: worklog.customer,
                    worksite: worklog.worksite,
                    duration: workingHours,
                    durationPerTask: workHoursPerTask ? workHoursPerTask : null,
                    pauses: pauseTime,
                };

                refByWeek[employee][weekNumber].worklogs.push(simpleWorklog);

            });
        });

        // Combine two object
        Object.keys(ref).forEach(id => {
            ref[id]["normalByWeek"] = refByWeek[id];
        });


        const employee = this.props.employees.find(employee => employee.name === searchData.employee);
        if (employee) {
            const employeeRef = {};
            if (ref[employee.id]) employeeRef[employee.id] = ref[employee.id];
            this.props.onSearch(employeeRef);
        }
        else {
            this.props.onSearch(ref);
        }

    };

    render() {
        const {searchData, menu, anchor} = this.state;
        const {user} = this.props.user;
        const {t, i18n} = this.props;

        return (
            <div style={{width: '100%'}}>
                <AutocompleteReports
                    searchArray={this.props.employees}
                    field="Employee"
                    label={this.props.t("Search employees")}
                    onSelect={event => this.onDataChange(event, "employee")}
                />

                <TextField
                    variant="outlined"
                    label={t("Select range")}
                    style={{
                        width: '100%',
                        marginTop: 10
                    }}
                    value={`${moment(this.props.dateRange.selection.startDate).locale(user.lang ? user.lang : "fi").format('L')} - ${moment(this.props.dateRange.selection.endDate).locale(user.lang ? user.lang : "fi").format('L')}`}
                    onClick={event => this.handleMenu(event.currentTarget)}
                />
                {/* this.props.errors.date.length > 0 && <p style={{color: '#B00', marginLeft: 60}}>{this.props.errors.date}</p> */ }

                {menu &&
                <ClickAwayListener onClickAway={this.closeMenu }>
                    <div
                        style={{
                            position: 'absolute',
                            top: anchor.y + anchor.height,
                            left: anchor.x,
                            zIndex: 10,
                            backgroundColor: '#FFF',
                            width: anchor.width - 2,
                            borderStyle: 'solid',
                            borderColor: '#ccc',
                            borderWidth: 1,
                            borderRadius: 4,
                        }}
                    >
                        <div style={{marginLeft: 30, marginTop: 10}}>
                            <DateRange
                                moveRangeOnFirstSelection={false}
                                ranges={[this.props.dateRange.selection]}
                                style={{
                                    width: '100%',
                                }}
                                locale={i18n.language === "fi" ? fi : enGB}
                                onChange={event => this.handleDate(event)}
                            />
                        </div>


                    </div>
                </ClickAwayListener>
                }

                {/*
                    <div style={{textAlign: 'center', marginTop: 20}}>
                        <Button style={{marginRight: 5}} onClick={event => this.setState({
                            datePicker: !this.state.datePicker,
                            anchor: event.currentTarget,
                            pickField: "start"
                        })}>
                            {moment(searchData.start).locale(user.lang ? user.lang : "fi").format('L')}
                        </Button>
                        -
                        <Button style={{marginLeft: 5}} onClick={event => this.setState({
                            datePicker: !this.state.datePicker,
                            anchor: event.currentTarget,
                            pickField: "end"
                        })}>
                            {moment(searchData.end).locale(user.lang ? user.lang : "fi").format('L')}
                        </Button>
                    </div>
                */}

                <div style={{marginLeft: 65}}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={searchData.accuracy === "duration"}
                                onChange={() => this.onDataChange("duration", "accuracy")}
                                color="primary"
                            />
                        }
                        label={this.props.t("ByExactTime")}
                    />

                    <FormControlLabel
                        control={
                            <Radio
                                checked={searchData.accuracy === "billable"}
                                onChange={() => this.onDataChange("billable", "accuracy")}
                                color="primary"
                            />
                        }
                        label={this.props.t("ByBillableTime")}
                    />

                </div>

                <div style={{textAlign: 'center', marginTop: 20}}>
                    <Button
                        variant="outlined"
                        onClick={this.props.onReset}
                    >
                        {this.props.t("Reset")}
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        style={{marginLeft: 20}}
                        onClick={() => this.formSearchList()}
                    >
                        {this.props.t("Search")}
                    </Button>

                </div>

                {/*this.state.datePicker &&
                <DateMenuComponent
                    anchor={this.state.anchor}
                    pickField={this.state.pickField}
                    searchData={searchData}
                    onSelect={this.onDataChange}
                />
                */}
            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(HourComponent));