import React, {Component} from 'react';

import {
    MenuItem,
    TextField
} from '@material-ui/core';

import firebase from 'firebase/app';
import 'firebase/firestore';

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";


class CommonSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            language: "fi"
        };
    }

    componentDidMount() {
        const {user} = this.props.user;

        if (user.lang) {
            this.setState({
                language: user.lang
            });
        }
    }

    handleLanguageChange = event => {
        let {user, userID} = this.props.user;
        const lang = event.target.value;
        const {i18n} = this.props;

        user.lang = lang;

        i18n.changeLanguage(lang);

        this.setState({language: lang});

        firebase.firestore().collection('employees').doc(userID).set(user);
    };

    render = () => {
        const {t} = this.props;
        return (
            <div style={{padding: 20}}>
                <TextField
                    variant="outlined"
                    label={t("Language")}
                    style={{
                        width: 200,
                        backgroundColor: '#FFF',
                        borderRadius: 4
                    }}
                    value={this.state.language}
                    onChange={event => this.handleLanguageChange(event)}
                    select
                >
                    <MenuItem value={"fi"} key={"fi"}>{t("Finnish")}</MenuItem>
                    <MenuItem value={"en"} key={"en"}>{t("English")}</MenuItem>
                </TextField>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(CommonSettings));