import React from 'react';

import moment from 'moment';
import "moment/locale/fi";

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';

import {withTranslation} from "react-i18next";
import {changeToDot} from "../../BillingComponents/BillDetailsComponents/BillNumberHelpers";



const SearchListComponent = props => {
    const {t} = props;

    const getTotalPrice = (bill) => {

        const price = parseFloat(bill.billObj.invoice.fullPrice);
        const other = parseFloat(changeToDot(bill.billObj.billingFees.billing))*1.24 + parseFloat(changeToDot(bill.billObj.billingFees.freight))*1.24;

        let totalPrice = ((bill.type === "compensation" ? 0-price : price) + other).toFixed(2);

        return totalPrice;
    }

    return (
        <Table style={{width: '100%'}}>
            <TableHead>
                <TableRow>
                    <TableCell>{t("Customer")}</TableCell>
                    <TableCell>{t("Bill number")}</TableCell>
                    <TableCell>{t("Bill date")}</TableCell>
                    <TableCell>{t("Due date")}</TableCell>
                    <TableCell>{t("Bill amount")}</TableCell>
                    <TableCell>{t("Bill status")}</TableCell>

                </TableRow>
            </TableHead>
            <TableBody>
                {props.searchArray && props.searchArray.map(bill => (

                    <TableRow key={bill.id}>
                        {/* <TableCell>{props.findCustomer(bill.customerID)}</TableCell> */}
                        <TableCell>{bill.billObj.customer.company ? bill.billObj.customer.company + '/' + bill.billObj.customer.contact.name : bill.billObj.customer.contact.name}</TableCell>
                        <TableCell>{bill.billNumber}</TableCell>
                        <TableCell>{moment(bill.billDate).locale("fi").format("L")}</TableCell>
                        <TableCell>{moment(bill.billObj.invoice.dates.dueFinish).locale("fi").format("L")}</TableCell>
                        <TableCell>{getTotalPrice(bill)}</TableCell>
                        <TableCell>{t(bill.status)}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default withTranslation()(SearchListComponent);
