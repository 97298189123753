import React from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import {Button} from "@material-ui/core";

import {withTranslation} from "react-i18next";

const Pdfqr = props => {

    const generatePDF = () => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;

        const docRef = {
            content: [
                { text: props.title || "", style: "title"},
                { text: props.msg || "", style: "msg"},
                { qr: props.code || "", style: "qr"}
            ],

            styles: {
                title: {
                    fontSize: 30,
                    alignment: 'center',
                    bold: true,
                    margin: [0, 20, 0, 30]
                },
                msg : {
                    fontSize: 18,
                    alignment: 'center',
                    margin: [0, 0, 0, 60]
                },
                qr: {
                    alignment: 'center'
                }
            }
        };

        pdfMake.createPdf(docRef).open();
    };


    return (
        <Button
            variant="outlined"
            color="primary"
            style={{
                marginRight: 20
            }}
            onClick={() => generatePDF()}
        >
            {props.t("Print")}
        </Button>
    );

};

export default withTranslation()(Pdfqr);