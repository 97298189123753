import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import moment from 'moment';

import {
    Button
} from '@material-ui/core';

import {
    Link
} from 'react-router-dom';

import {
    UtilityBar,
    ListAreaHeader,
    ListAreaBody,
    ListAreaItem
} from '../ListComponents';

import { validateFBWorklog } from "../validations/worklog";
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import "./UserList.css";
class UserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            users: [],
            usersCopy: [],
            worklogs: [],
            searchTerm: "",
        }
    }

    componentDidMount() {
        const { user } = this.props.user;

        this.unsubscribeWorklogListener = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs")
            .where("status", "==", "Ongoing").onSnapshot(querySnap => {
                const worklogs = [];

                querySnap.forEach(doc => {
                    const worklog = doc.data();

                    const { isValid } = validateFBWorklog(worklog);
                    if (isValid && moment(worklog.startDT).isSame(moment(), "day")) worklogs.push(worklog);
                });

                this.setState({ worklogs });
            });

        this.unsubscribeEmployeeListener = firebase.firestore().collection("employees")
            .where("companyID", "==", user.companyID).onSnapshot(querySnap => {
                const users = [];

                querySnap.forEach(doc => {
                    const user = doc.data();
                    user.id = doc.id;
                    user.name = `${user.firstName} ${user.familyName}`;
                    if (!user.isDeleted) users.push(user);
                });

                this.setState({
                    users,
                    loading: false,
                    usersCopy: JSON.parse(JSON.stringify(users))
                });
            })
    }

    componentWillUnmount() {
        this.unsubscribeWorklogListener && this.unsubscribeWorklogListener();
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
    }

    onUserSearch = event => {
        const { usersCopy } = this.state;

        const searchTerm = event.target.value;
        const users = [];

        usersCopy && usersCopy.forEach(user => {
            if (user.name.toLowerCase().search(searchTerm.toLowerCase()) > -1) users.push(user)
        });
        this.setState({ users, searchTerm });
    };

    renderListItem = user => {
        const { worklogs } = this.state;
        const { t } = this.props;
        const worklog = worklogs.find(worklog => worklog.employee.includes(user.id));

        return (
            <Link to={`/registry/employees/details/${user.id}`} className="user-link" key={user.id}>
                <ListAreaBody>
                    <ListAreaItem variant="list" size="large">{user.name}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{(!user.phone && !user.phoneWork ? " - " : (user.phoneWork ? (user.phoneWork + t("Phone Work Short")) : (user.phone + t("Phone Home Short"))))}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{user.email}</ListAreaItem>
                    <ListAreaItem variant="list" size="medium">{user.isSubcontractor === "true" ? this.props.t("Y") : this.props.t("N")}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{worklog ? (t("Ongoing")) : " - "}</ListAreaItem>
                </ListAreaBody>
            </Link>

        );
    };

    render() {
        const { loading, searchTerm, users } = this.state;
        const { t } = this.props;
        if (loading) return <div></div>
        else return (
            <div className="userlist-container">
                <UtilityBar
                    t={t}
                    searchBar
                    searchTerm={searchTerm}
                    searchOnChange={this.onUserSearch}
                >
                    <div className="user-utility">
                        <Link to={"/registry/employees/add"} className="user-link">
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#1770B2', color: '#FFF', height: 40 }}
                            >
                                {t("Add")}
                            </Button>
                        </Link>

                    </div>
                </UtilityBar>

                <ListAreaHeader>
                    <ListAreaItem
                        variant="title"
                        size="large"
                    >
                        {t("Name")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="large"
                    >
                        {t("Phone")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="large"
                    >
                        {t("Email")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="medium"
                    >
                        {t("Subcontractor")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                    >
                        {t("Status")}
                    </ListAreaItem>
                </ListAreaHeader>

                {users && users.map(this.renderListItem)}

                <div style={{ height: 80 }} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(UserList));
