import {isEmpty} from "./isEmpty";
import Validator from 'validator';

export const validateCustomerEditor = (customer, customerCopy, customers) => {
    let errors = {};

    customer.customerNumber = !isEmpty(customer.customerNumber) ? customer.customerNumber.toString() : "";
    customer.name = !isEmpty(customer.name) ? customer.name : "";
    customer.companyID = customer.companyID && Array.isArray(customer.companyID) ? customer.companyID : "";

    if (Validator.isEmpty(customer.customerNumber)) {
        errors.customerNumber = "CN_required";
    }

    // Check there is no duplicates
    if (customer.customerNumber && customers) {
        const idArray = customers.map(customer => parseInt(customer.customerNumber));
        if (parseInt(customer.customerNumber) !== parseInt(customerCopy.customerNumber)) {
            if (idArray.includes(parseInt(customer.customerNumber))) errors.customerNumber = "CN_duplicate";
        }
    }

    if (Validator.isEmpty(customer.name)) {
        errors.name = "NameRequired";
    }

    if (!Array.isArray(customer.companyID) && Validator.isEmpty(customer.companyID)) {
        errors.companyID = "CID";
    }

    // Check that email is valid
    if (customer.email) {
        if (!Validator.isEmail(customer.email)) errors.email = "InvalidEmail";
    }

    if (customer.phone) {
        if (!Validator.isMobilePhone(customer.phone)) errors.phone = "InvalidPhone";
    }

    if (customer.phoneWork) {
        if (!Validator.isMobilePhone(customer.phoneWork)) errors.phoneWork = "InvalidPhone"
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
};
