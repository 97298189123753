import React, { Component } from 'react';

import classNames from 'classnames';

import axios from 'axios';
import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";

import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faLongArrowAltDown, faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";
import { Add, Close } from '@material-ui/icons';
import {
    IconButton,
    ClickAwayListener,
    CircularProgress,
} from '@material-ui/core';

import {
    UtilityBar,
    ListAreaHeader,
    ListAreaItem,
    ListAreaBody,
    FilterMenu,
    SelectButton,
    ConfirmDialog
} from "../ListComponents";

import { base64StringToBlob } from "blob-util";
import { createBillAttachment } from "./BillDetailsComponents/PDFAttachment";
import { authenticateSender, formInvoiceAndUrl } from "./BillDetailsComponents/sendToServer";
import { validateFBWorklog } from "../validations/worklog";
import { StatusSelection, SendMethodSelection } from "./FilterMenuContents";
import { DataSearch } from "../util/DataSearch";
import { sortList } from '../ListComponents/SortList';
import { changeIntToString, changeToDot } from "./BillDetailsComponents/BillNumberHelpers";
import { billSummaryPrices } from "./BillDetailsComponents/InvoiceObjectForming";

import { Transition, config } from 'react-spring/renderprops';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import "./BillList.css";
import { dateTime } from "../util/StringHelpers";
import {isEmpty} from "../validations/isEmpty";
import { Toast, ToastWrapper } from "show-toast-box"
import {solveBillingErrorMessage} from "../util/BillServerErrorHelper";

class BillsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            addNew: false,
            billStatus: "all",
            searchTerm: "",
            bills: [],
            billsCopy: [],
            selectedBills: [],
            worklogs: [],
            employees: [],
            models: [],
            sortOrder: {
                field: "billNumber",
                dir: "asc"
            },
            filterAnchor: null,
            filterValues: [
                { type: "Created", field: "status" },
                { type: "Sent", field: "status" },
                { type: "Error", field: "status" },
                { type: "InQue", field: "status" },
                //{type: "Archived", field: "status"},
            ],
            selection: false,
            selectAll: false,
            menuActions: [
                { action: "status", methods: ["Created", "Sent", "Paid", "Error", "Archived"] },
                { action: "sendMethod", methods: ["email", "email / apix", "apix", "netvisor", "talenom"] },
                { action: "send" }
            ],
            confirmDialog: false,
            confirmObj: {
                title: "",
                message: "",
            },
            saveLoading: false,
            saveComplete: false,
            pollMassSending: false,
            massStatusCheckOngoing: false,
        };

        this.wrapper = React.createRef();
    }

    componentDidMount() {
        const { user } = this.props.user;

        this.unsubscribeBillListener = firebase.firestore().collection('company').doc(user.companyID).collection('bills')
            //.where("status", "!=", "Archived")
            .where("billDate", ">=", moment().subtract(4, "month").format("YYYYMMDDTHHmmss"))
            //TODO - REDO FIX TEE PVM ja STATUS VALINTA
            .onSnapshot(this.onBillSnapshot);
        this.unsubscribeWorklogListener = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").onSnapshot(querySnap => this.onQuerySnap(querySnap, "worklogs"));
        // TODO Optimize time accordingly
        this.unsubscribeEmployeeListener = firebase.firestore().collection("employees").where("companyID", "==", user.companyID).onSnapshot(querySnap => this.onQuerySnap(querySnap, "employees"));
        this.unsubscribeModelListener = firebase.firestore().collection("company").doc(user.companyID).collection("jobModels").onSnapshot(querySnap => this.onQuerySnap(querySnap, "models"))
    }

    onQuerySnap = (querySnap, dataName) => {
        const dataArr = [];

        querySnap.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;

            if (dataName === "employees") data.name = `${data.firstName} ${data.familyName}`;

            if (dataName === "worklogs") {
                const { isValid } = validateFBWorklog(data);
                if (isValid) dataArr.push(data);
            }
            else dataArr.push(data);
        });
        this.setState({
            [dataName]: dataArr
        });
    };

    onBillSnapshot = querySnapshot => {
        const { user } = this.props.user;
        const bills = [];

        querySnapshot.forEach(doc => {
            let bill = doc.data();
            bill.id = doc.id;

            bill = changeIntToString(bill, user);

            bills.push(bill)
        });

        const sortedBills = bills.sort((a, b) => {
            if (moment(a.billDate).isBefore(b.billDate)) return 1;
            if (moment(a.billDate).isAfter(b.billDate)) return -1;
        });

        console.log("sortedBills: ", sortedBills)
        this.setState({
            bills: sortedBills,
            billsCopy: JSON.parse(JSON.stringify(sortedBills)),
            loading: false
        }, () => {
            this.filterBills(null, null);
            //this.checkMassSendingResultsUntilAllSentOrError()
        });
    };

    componentWillUnmount() {
        this.unsubscribeBillListener && this.unsubscribeBillListener();
        this.unsubscribeWorklogListener && this.unsubscribeWorklogListener();
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
        this.unsubscribeModelListener && this.unsubscribeModelListener();

    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    toastaa = (type, title, description, duration=5000) => {

        if (type === "success") {
            this.wrapper.current.add(
                <Toast 
                    title={title} 
                    description={description}
                    type="success" 
                    animation="slide-up" 
                    removeWhenClick={true} 
                    autoRemove={true} 
                    delay={1} 
                    duration={duration} 
                    animationDuration={2000} 
            />)
        } else if (type === "error") {
            this.wrapper.current.add(
                <Toast 
                    title={title} 
                    description={description}
                    type="error" 
                    animation="slide-up" 
                    removeWhenClick={true} 
                    delay={1} 
                    animationDuration={2000} 
            />)
        } else if (type === "warning") {
            this.wrapper.current.add(
                <Toast
                    title={title} 
                    description={description}
                    type="warning" 
                    animation="slide-up" 
                    autoRemove={true} 
                    removeWhenClick={true} 
                    delay={1} 
                    duration={duration} 
                    animationDuration={2000} 
                />
            )
        }
    }

    roundNumber = d => {
        return parseFloat(d).toFixed(2);
    };

    searchBills = event => {
        const { billsCopy, bills, filterValues } = this.state;
        const searchTerm = event.target.value;

        const filteredBills = [];
        let filterSource = [];
        //if there already are filters, apply search to filtered bills (bills), If not, use all bills (billsCopy)
        if (filterValues) {
            filterSource = bills;
        } else {
            filterSource = billsCopy;
        }

        filterSource.forEach(bill => {
            const searchTarget = bill.billNumber + bill.billObj.customer.contact.name;

            if (searchTarget.toLowerCase().search(searchTerm.toLowerCase()) > -1) filteredBills.push(bill)
        });

        this.setState({ bills: filteredBills, searchTerm })
    };

    handleSelectButton = action => {
        if (action === "Add_bill") {
            this.props.history.push("/bills");
            this.setState({ addNew: true })
        }
        else if (action === "Check") this.setState({
            //confirmDialog: true,
            massStatusCheck: true,
            saveLoading: true
        //}, () => this.massStatusCheck());
        }, () => this.checkMassSendingResultsUntilAllSentOrError());
        else this.setState(prevState => ({ selection: !prevState.selection }))
    };

    sortBills = (field, parse) => {
        const { sortOrder, bills } = this.state;

        const { newSortOrder, results } = sortList(field, parse, sortOrder, bills);

        this.setState({
            sortOrder: newSortOrder,
            bills: results
        });
    };

    filterBills = (type, field) => {
        const { billsCopy, sortOrder, filterValues } = this.state;

        const newFilterValue = { type: type, field: field };

        const existingValue = filterValues.find(value => value.type === newFilterValue.type && value.field === newFilterValue.field);

        if (type && field && !existingValue) filterValues.push(newFilterValue);
        else if (type !== null && field !== null) {
            const removeIndex = filterValues.indexOf(existingValue);
            filterValues.splice(removeIndex, 1);
        }


        const billFilter = new DataSearch();
        billFilter.setSearchData(billsCopy);
        if (filterValues.some(value => value.field === "status")) billFilter.appendField("status", filterValues.filter(value => value.field === "status").map(value => value.type), "searchValueIncludes");
        let results = billFilter.executeSearch(sortOrder.dir === "asc" ? "desc" : "asc", sortOrder.field)

        const sendMethods = filterValues.filter(value => value.field === "deliveryMethod").map(value => value.type);

        if (filterValues.some(value => value.field === "deliveryMethod")) results = results.filter(item => sendMethods.includes(item.sendingMethod.sending));

        this.setState({ bills: results, filterValues });

    };

    toggleFilterMenu = (event, filterField) => {
        const newAnchor = event.currentTarget ? event.currentTarget.getBoundingClientRect() : null;

        this.setState({ filterAnchor: newAnchor, filterField })
    };

    arrowDirection = field => {
        const { sortOrder } = this.state;

        if (sortOrder.field === field && sortOrder.dir === "desc") {
            return (
                <FontAwesomeIcon icon={faLongArrowAltUp} style={{ height: 15, width: 15 }} />
            );
        }
        else return <FontAwesomeIcon icon={faLongArrowAltDown} style={{ height: 15, width: 15 }} />
    };

    handleBillSelection = id => {
        const { selectedBills } = this.state;

        if (!selectedBills.includes(id)) selectedBills.push(id);
        else {
            const removeIndex = selectedBills.indexOf(id);
            selectedBills.splice(removeIndex, 1);
        }

        this.setState({ selectedBills });
    };

    handleMassBillSelection = () => {
        let { bills, selectAll } = this.state;

        let selectedBills = [];
        const selectMethod = !selectAll;

        if (!selectMethod) selectedBills = [];
        else {
            selectedBills = bills.map(bill => bill.id)
        }

        this.setState({
            selectAll: selectMethod,
            selectedBills
        });

    };

    handleMassActionSelect = (action, option) => {
        const { confirmObj } = this.state;
        const { t } = this.props;

        confirmObj.title = t("Attention");
        confirmObj.action = action;
        confirmObj.option = option;

        if (action === "send") confirmObj.message = t("SendTo");
        else confirmObj.message = `${t("ChangeTo")} ${t("Bills")}`;


        if (action === "status") confirmObj.status = `${t("status")}: ${t(option)}`;
        if (action === "sendMethod") confirmObj.status = `${t("sendMethod")}: ${t(option)}`;

        this.setState(prevState => ({
            confirmDialog: !prevState.confirmDialog,
            confirmObj
        }));
    };

    onMassActionConfirm = () => {
        const { billsCopy, confirmObj, selectedBills } = this.state;
        const { user } = this.props.user;

        const batch = firebase.firestore().batch();

        if (confirmObj.action === "send") return this.massSendBills();

        selectedBills.forEach(id => {
            const bill = billsCopy.find(bill => bill.id === id);

            if (bill) {
                if (confirmObj.action === "status") bill.status = confirmObj.option;

                if (confirmObj.action === "sendMethod") bill.sendingMethod.sending = confirmObj.option;

                const billRef = firebase.firestore().collection("company").doc(user.companyID).collection("bills").doc(bill.id);
                batch.set(billRef, bill);
            }
        });


        batch.commit()
            .then(() => {
                this.setState({
                    saveComplete: true,
                    selectedBills: [],
                    selectAll: false
                });
            });

    };

    massSendBills = async () => {
        const { selectedBills, billsCopy, worklogs, employees, models } = this.state;
        const { user } = this.props.user;
        const { company } = this.props.company;
        const { products } = this.props.products;
        const { t } = this.props;

        // Authenticate user to invoice server
        let authToken = await authenticateSender();
        if (!authToken) return console.log("Something went wrong while authenticating");

        // From settings for axios
        const serverUrl = "https://icf.heinolasoftwares.fi/queue";
        const serverConfig = {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
                "x-access-token": authToken
            }
        };

        const batch = firebase.firestore().batch();


        for (let i = 0; i < selectedBills.length; i++) {
            // Find required data in arrays
            const bill = billsCopy.find(bill => bill.id === selectedBills[i]);
            const customer = bill.billObj.customer;
            const billWorklogs = worklogs.filter(worklog => bill.worklogs && bill.worklogs.includes(worklog.id));

            const attachmentName = `${moment().format("MM-YYYY")}-${bill.billObj.customer.contact.name.replace(/\s+/g, '').replace(/\//g, '_').replace(/&/g, '_').replace(/-/g, '')}_hours`;

            console.log("attachmentName", attachmentName);

            // If bill requires attachment, create it and upload to server
            let attachmentString = "";
            let attachmentResponse = {};
            let sendAttachment = bill.type !== "compensation";
            if (sendAttachment) {
                attachmentString = await createBillAttachment(this.props.t, billWorklogs, company, customer, employees, products, models, user, bill.billObj.rows).catch(err => console.log(err));
                const blob = base64StringToBlob(attachmentString, "application/pdf");
                const blobName = attachmentName + ".pdf";
                const formData = new FormData();
                formData.append("file", blob);
                formData.append("name", blobName);
                attachmentResponse = await axios.post("https://icf.heinolasoftwares.fi/uploadBlob", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        'Access-Control-Allow-Origin': '*',
                        "x-access-token": authToken
                    }
                })
                    .catch(error => {
                        console.log("ATTACHMENT UPLOAD ERROR", error);
                        this.toastaa("error", t("errUploadAttachment"), solveBillingErrorMessage(bill.sendingMethod?.sending, error, t));
                        //this.toastaa("error", t("errUploadAttachment"), `${t("errBillNumber")}: ${bill.billNumber} ${t("errBillNumberError")}: ${error}`);
                    })
                if (attachmentResponse) console.log("ATTACHMENT UPLOADED", attachmentResponse);
            }


            const { invoice } = await formInvoiceAndUrl(bill, user, company, attachmentName, sendAttachment);

            //Select sending queue (APIX = ICF)
            const queueName = bill.sendingMethod.sending.toLowerCase() === "talenom" ? "Talenom" : bill.sendingMethod.sending.toLowerCase() === "netvisor" ? "Netvisor" : "ICF";
            const sendObject = { queue: { name: queueName, data: invoice } };

            console.log("Invoice", invoice);

            // If we are sending with attachment check that no errors occured while uploading it to server
            let queResponse = {};
            if (sendAttachment && attachmentResponse) {
                console.log("Uploading after attachment upload");
                queResponse = await axios.post(serverUrl, sendObject, serverConfig).catch(error => {
                    console.log("ERROR WHILE UPLOADING TO QUE", error)
                    this.toastaa("error", t("errUploadQueue"), solveBillingErrorMessage(bill.sendingMethod?.sending, error, t));
                });
            }
            if (!sendAttachment) {
                console.log("No attachment or bill.type === compensation");
                queResponse = await axios.post(serverUrl, sendObject, serverConfig).catch(error => {
                    console.log("ERROR WHILE UPLOADING TO QUE", error)
                    this.toastaa("error", t("errUploadQueue"), solveBillingErrorMessage(bill.sendingMethod?.sending, error, t));
                });
            }
            console.log("queResponse ", queResponse);

            if (!isEmpty(queResponse)) {
                bill.queID = queResponse?.data?.job?.id;
                bill.status = "InQue";
                // Should status be in In que?
                const billRef = firebase.firestore().collection("company").doc(user.companyID).collection("bills").doc(bill.id);
                batch.set(billRef, bill);
                this.toastaa("success", t("uploadedToQueue"), "", 3000);
            } else {
                this.toastaa("error", t("errBillQueueEmptyResponse"), `${t("errBillQueueEmptyResponseDescription")}`);
            }
        }

        // TODO commit stuff to FB
        batch.commit()
            .then(() => {
                this.setState({ saveComplete: true, selectedBills: [] }, async () => {
                    await this.sleep(60000);
                    this.checkMassSendingResultsUntilAllSentOrError();
                })
            })
            .catch(err => {
                console.log(err)
                //this.toastaa("error", t("errBillQueueBatchCommit"), `${err}`);
                this.toastaa("error", t("errUploadAttachment"), solveBillingErrorMessage(bill.sendingMethod?.sending, err, t));
            })
            
    };

    massStatusCheck = async () => {
        const { user } = this.props.user;
        const { t } = this.props;

        console.log("massStatusCheck", this.state.massStatusCheckOngoing);

        if (!this.state.massStatusCheckOngoing) {
            this.setState( { massStatusCheckOngoing: true })

            const billsInQue = this.state.billsCopy.filter(bill => bill.status === "InQue");
            console.log("billsInQue", billsInQue);
            let authToken = await authenticateSender();
            if (!authToken) {
                this.toastaa("error", t("errBillQueueMassStatusCheckAuthError"), `${t("errBillQueueMassStatusCheckAuthErrorDescription")}`);
                return console.log("Something went wrong while authenticating");
            }

            // Server config for Axios
            const serverConfig = {
                headers: {
                    "Content-Type": "application/json",
                    'Access-Control-Allow-Origin': '*',
                    "x-access-token": authToken
                }
            };

            const batch = firebase.firestore().batch();

            // If there is no bills with InQue status, return
            if (billsInQue.length === 0) return this.setState({ saveComplete: true });
            for (let i = 0; i < billsInQue.length; i++) {
                const bill = billsInQue[i];

                const serverUrl = `https://icf.heinolasoftwares.fi/queue/getJob/${bill.queID}/state`;

                const response = await axios.get(serverUrl, serverConfig);

                if (response && response.data && response.data.jobState) {

                    if (response.data.jobState === "completed") { 
                        bill.status = "Sent";
                        this.toastaa("success", t("deliveredFromQueue"), "", 3000);
                    }
                    if (response.data.jobState === "failed") {
                        bill.status = "Error";
                        this.toastaa("error", t("failedFromQueue"), `Lasku nro ${bill.billNumber}`);
                    }

                    const billRef = firebase.firestore().collection("company").doc(user.companyID).collection("bills").doc(bill.id);
                    batch.set(billRef, bill);
                }
            }


            batch.commit().then(() => {
                this.setState({
                    saveComplete: true,
                    massStatusCheckOngoing: false,
                });
            });
        }
    };

    renderFilterItem = (filter, field, props) => {
        const { t } = this.props;
        return (
            <div className="filterItem-container" style={props}>
                <p className="filterItem-text">{t(filter)}</p>
                <IconButton style={{ marginLeft: 10, padding: 7 }} onClick={() => this.filterBills(filter, field)}>
                    <Close style={{ height: 10, width: 10 }} />
                </IconButton>
            </div>
        );
    };

    renderListItem = bill => {
        const { user } = this.props.user;
        const { massStatusCheckOngoing } = this.state;
        const { billingFees, invoice } = bill.billObj;
        const { withoutVat, totalVat, fullPrice } = billSummaryPrices(bill.billObj.rows, billingFees, invoice, user);


        if (this.state.selection) {
            return (
                <ListAreaBody key={bill.id}>                    
                    <ListAreaItem variant="list" size="small">{bill.billNumber}</ListAreaItem>
                    <ListAreaItem variant="list"
                        size="medium">{bill.billObj.customer.company ? bill.billObj.customer.company + '/' + bill.billObj.customer.contact.name : bill.billObj.customer.contact.name}</ListAreaItem>
                    <ListAreaItem variant="list"
                        size="medium">{moment(bill.billDate).locale(user.lang ? user.lang : "fi").format('L')}</ListAreaItem>
                    <ListAreaItem variant="list"
                        size="medium">{moment(bill.billObj.invoice.dates.dueFinish).locale(user.lang ? user.lang : "fi").format('L')}</ListAreaItem>
                    {massStatusCheckOngoing && bill.status === "InQue" &&
                    <ListAreaItem variant="list" size="small">
                        <CircularProgress />   
                    </ListAreaItem>
                    }
                    <ListAreaItem variant="list" size="medium">{this.props.t(bill.status)}</ListAreaItem>
                    
                    <ListAreaItem variant="list" size="medium">{fullPrice}</ListAreaItem>
                    <ListAreaItem variant="list" size="medium">{this.props.t(bill.sendingMethod.sending)}</ListAreaItem>
                    {this.state.selection &&
                    <ListAreaItem variant="list" size="checkbox" checked={this.state.selectedBills.includes(bill.id)}
                        show={this.state.selection} onChange={() => this.handleBillSelection(bill.id)} />
                    }
                </ListAreaBody>
            )
        }
        else return (
            <Link to={"/bills/edit/" + bill.id} className="link" key={bill.id}>
                <ListAreaBody>
                    <ListAreaItem variant="list" size="small">{bill.billNumber}</ListAreaItem>
                    <ListAreaItem variant="list"
                        size="medium">{bill.billObj.customer.company ? bill.billObj.customer.company + '/' + bill.billObj.customer.contact.name : bill.billObj.customer.contact.name}</ListAreaItem>
                    <ListAreaItem variant="list"
                        size="medium">{moment(bill.billDate).locale(user.lang ? user.lang : "fi").format('L')}</ListAreaItem>
                    <ListAreaItem variant="list"
                        size="medium">{moment(bill.billObj.invoice.dates.dueFinish).locale(user.lang ? user.lang : "fi").format('L')}</ListAreaItem>
                    {massStatusCheckOngoing && bill.status === "InQue" &&
                    <ListAreaItem variant="list" size="small">
                        <CircularProgress />   
                    </ListAreaItem>
                    }
                    <ListAreaItem variant="list" size="medium">{this.props.t(bill.status)}</ListAreaItem>
                    <ListAreaItem variant="list" size="medium">{fullPrice}</ListAreaItem>
                    <ListAreaItem variant="list" size="medium">{this.props.t(bill.sendingMethod.sending)}</ListAreaItem>
                </ListAreaBody>
            </Link>
        );
    };

    countBillsTotalAmount = () => {
        const { user } = this.props.user;
        const { bills } = this.state;
        const replaceValue = user && user.lang === "fi" ? "," : ".";

        let totalBillsAmount = 0;
        if (bills) {
            bills.map(bill => {
                const { billingFees, invoice } = bill.billObj;
                const { fullPrice } = billSummaryPrices(bill.billObj.rows, billingFees, invoice, user);
                totalBillsAmount += parseFloat(changeToDot(fullPrice));
            });
        }

        return totalBillsAmount.toFixed(2).replace(".", replaceValue);
    };

    checkMassSendingResultsUntilAllSentOrError = async () => {
        const { massStatusCheckOngoing } = this.state;

        let numBillsInQueue = 0;
        const WAIT_TIME_BETWEEN_CHECKS_IN_MS = 60000;

        do {
            numBillsInQueue = this.state.billsCopy.filter(bill => bill.status === "InQue")?.length;
            console.log("checkMassSendingResultsUntilAllSentOrError: ", massStatusCheckOngoing, numBillsInQueue)
            if (!massStatusCheckOngoing && numBillsInQueue>0) {
                
                this.setState({
                    //confirmDialog: true,
                    //massStatusCheckOngoing: true,
                    saveLoading: true
                }, () => this.massStatusCheck(), async () => { console.log("sleeping, waiting, for the next bill queue check"),  await this.sleep(WAIT_TIME_BETWEEN_CHECKS_IN_MS) });
            }
            await this.sleep(WAIT_TIME_BETWEEN_CHECKS_IN_MS);
        } while (numBillsInQueue > 0);
    }

    render() {
        const { t } = this.props;
        const { searchTerm, loading, bills, filterAnchor, filterField, filterValues } = this.state;

        const content = classNames({
            "content": true,
            "disable-scroll": Boolean(filterAnchor)
        });

        const totalBillsAmount = this.countBillsTotalAmount();

        return (
            <div className={content}>
                <UtilityBar
                    t={t}
                    searchBar
                    searchTerm={searchTerm}
                    searchOnChange={event => this.searchBills(event)}
                >
                    <div className="bill-utility">
                        <div className="bill-utility left">
                            <Transition
                                items={filterValues}
                                keys={item => item.type + item.field}
                                from={{ transform: "scale(0)" }}
                                enter={{ transform: "scale(1)" }}
                                leave={{ transform: "scale(0)" }}
                                config={config.stiff}
                            >
                                {item => props => this.renderFilterItem(item.type, item.field, props)}
                            </Transition>
                        </div>
                        <div className="bill-utility right">

                            <SelectButton
                                onChange={this.handleSelectButton}
                                options={["Add_bill", "Selection", "Check"]}
                            />
                        </div>

                    </div>
                </UtilityBar>

                <ListAreaHeader>
                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }}
                                onClick={() => this.sortBills("billNumber", true)}>
                                {this.arrowDirection("billNumber")}
                            </IconButton>
                        }
                    >
                        {t("Bill number")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="medium"
                    >
                        {t("Customer")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="medium"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }}
                                onClick={() => this.sortBills("billDate", false)}>
                                {this.arrowDirection("billDate")}
                            </IconButton>
                        }
                    >
                        {t("Bill date")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="medium"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }}
                                onClick={() => this.sortBills("dueFinish", false)}>
                                {this.arrowDirection("dueDate")}
                            </IconButton>
                        }
                    >
                        {t("Due date")}
                    </ListAreaItem>
                    
                    <ListAreaItem
                        variant="title"
                        size="medium"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }}
                                onClick={event => this.toggleFilterMenu(event, "status")}>
                                <FontAwesomeIcon icon={faFilter} style={{ height: 15, width: 15 }} />
                            </IconButton>
                        }
                    >
                        {t("Status")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="medium"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }}
                                onClick={() => this.sortBills("billSum", true)}>
                                {this.arrowDirection("billSum")}
                            </IconButton>
                        }
                    >
                        {t("Bill")}
                    </ListAreaItem>


                    <ListAreaItem
                        variant="title"
                        size="medium"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }}
                                onClick={event => this.toggleFilterMenu(event, "deliveryMethod")}>
                                <FontAwesomeIcon icon={faFilter} style={{ height: 15, width: 15 }} />
                            </IconButton>
                        }
                    >
                        {t("Delivery")}
                    </ListAreaItem>
                    {this.state.selection &&
                        <ListAreaItem
                            variant="title"
                            size="checkbox"
                            style={{ marginLeft: 20, marginRight: 10 }}
                            show={this.state.selection}
                            checked={this.state.selectAll}
                            onChange={this.handleMassBillSelection}
                            onSelect={this.handleMassActionSelect}
                            menuActions={this.state.menuActions}
                        />
                    }
                </ListAreaHeader>

                {!loading && bills.map(this.renderListItem)}

                {loading &&
                    <div style={{
                        alignSelf: 'center',
                        width: '50vw',
                        height: 'auto',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '3%'
                    }}>
                        <CircularProgress />
                    </div>
                }


                <div style={{ height: 60, width: '100%' }}>
                    {!loading && bills.length > 0 &&
                        <div>
                            <div className="wl-right">
                                <div className="billing-summary-item">{t("Bills count")}</div>
                                <div className="billing-summary-item">{t("Bills sum")}</div>
                            </div>
                            <div className="wl-right">
                                <div className="billing-summary-item">{bills.length}</div>
                                <div className="billing-summary-item">{totalBillsAmount}€</div>

                            </div>
                        </div>
                    }
                </div>

                {Boolean(filterAnchor) &&
                    <ClickAwayListener onClickAway={() => this.toggleFilterMenu({}, filterField)}>
                        <FilterMenu
                            anchor={filterAnchor}
                        >
                            {filterField === "status" &&
                                <StatusSelection
                                    filterValues={filterValues}
                                    filterBills={this.filterBills}
                                    t={this.props.t}
                                />
                            }

                            {filterField === "deliveryMethod" &&
                                <SendMethodSelection
                                    filterValues={filterValues}
                                    filterBills={this.filterBills}
                                    t={this.props.t}
                                />
                            }

                        </FilterMenu>
                    </ClickAwayListener>
                }

                {this.state.confirmDialog &&
                    <ConfirmDialog
                        open={this.state.confirmDialog}
                        onClose={() => this.setState({
                            confirmDialog: false,
                            saveComplete: false,
                            saveLoading: false,
                            confirmObj: { title: "", message: "", status: "", action: "", option: "" }
                        })}
                        onConfirm={() => this.setState({ saveLoading: true }, () => this.onMassActionConfirm())}
                        title={this.state.confirmObj.title}
                        message={this.state.confirmObj.message}
                        status={this.state.confirmObj.status}
                        loading={this.state.saveLoading}
                        saveComplete={this.state.saveComplete}
                        comp={this.props.t("BillsOwn")}
                    />
                }

                {/* Needed so we can get base64 url barcode*/}
                <canvas id="barcode" style={{ opacity: 0 }}>

                </canvas>

                <ToastWrapper ref={this.wrapper} />

                {this.state.addNew &&
                    <Redirect to={"/bills/add"} />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    company: state.company,
    products: state.products
});

export default connect(mapStateToProps, {})(withTranslation()(BillsList));

