import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField
} from "@material-ui/core";

import {withTranslation} from 'react-i18next';

const WorksiteDialog = props => {
    const {t} = props;

    const {errors, isValid} = props.onValidate(props.selectedWorksite);

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            maxWidth="sm"
            fullWidth
        >
            {!props.editMode && <DialogTitle style={{marginLeft: 15}}>{t("New worksite")}</DialogTitle>}
            {props.editMode && <DialogTitle>{t("Edit worksite")}</DialogTitle>}
            <DialogContent>
                <div style={{margin: 15}}>
                    <TextField
                        variant="outlined"
                        label={t("Name")}
                        style={{
                            width: '100%',
                        }}
                        value={props.selectedWorksite.name || ""}
                        error={!!errors.name}
                        onChange={event => props.onChange(event, 'name')}
                    />

                    <TextField
                        variant="outlined"
                        label={t("Worksite number")}
                        style={{
                            width: '100%',
                            marginTop: 10
                        }}
                        value={props.selectedWorksite.wNumber || ""}
                        onChange={event => props.onChange(event, 'wNumber')}
                    />

                    <TextField
                        variant="outlined"
                        label={t("Street address")}
                        style={{
                            width: '100%',
                            marginTop: 10
                        }}
                        value={props.selectedWorksite.streetAddress || ""}
                        onChange={event => props.onChange(event, 'streetAddress')}
                        error={!!errors.streetAddress}
                    />

                    <Grid container spacing={24}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                label={t("City")}
                                style={{
                                    width: '100%',
                                    marginTop: 10
                                }}
                                value={props.selectedWorksite.city || ""}
                                onChange={event => props.onChange(event, 'city')}
                                error={!!errors.city}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                label={t("Post code")}
                                style={{
                                    width: '100%',
                                    marginTop: 10
                                }}
                                value={props.selectedWorksite.postCode || ""}
                                onChange={event => props.onChange(event, 'postCode')}
                                error={!!errors.postCode}
                            />
                        </Grid>
                    </Grid>

                    <TextField
                        variant="outlined"
                        label={t("Instructions")}
                        style={{
                            width: '100%',
                            marginTop: 10
                        }}
                        multiline
                        rowsMax={3}
                        rows={3}
                        value={props.selectedWorksite.instructions || ""}
                        onChange={event => props.onChange(event, 'instructions')}
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={props.selectedWorksite.isDefault === "true"}
                                onChange={(event) => props.onChange(event, 'isDefault')}
                                value="true"
                                color="primary"
                            />
                        }
                        label={t("Default")}
                        style={{
                            marginTop: 20
                        }}
                    />
                </div>
            </DialogContent>

            <DialogActions>
                <div style={{marginTop: 15, marginRight: 35}}>


                    <Button
                        variant="outlined"
                        color="secondary"
                        style={{
                            width: 100,
                            marginRight: 210
                        }}
                        onClick={() => props.onDelete(props.selectedWorksite)}
                    >
                        {t("Delete")}
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        style={{width: 100, marginRight: 10}}
                        onClick={() => props.onClose()}
                    >
                        {t("Cancel")}
                    </Button>


                    <Button
                        variant="outlined"
                        color="primary"
                        style={{width: 100}}
                        onClick={() => props.onSave()}
                        disabled={!isValid}
                    >
                        {t("Save")}
                    </Button>

                </div>
            </DialogActions>

        </Dialog>
    );
};


export default withTranslation()(WorksiteDialog);