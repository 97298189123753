import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import keycode from 'keycode';
import {
    TextField,
    Button,
    Grid,
    Paper,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormLabel,
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Chip,
    MenuItem,
    CircularProgress
} from '@material-ui/core';

import moment from 'moment';

import {SkipNext, Create, Clear, Delete, Add, Print} from '@material-ui/icons';

import {connect} from 'react-redux';

import {withTranslation} from "react-i18next";

import GlobalConfirmationDialog from "./CommonDialogs/GlobalConfirmationDialog";
import ProductQRDialog from './ProductQRDialog';

class ProductEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: {},
            id: "",
            addUnit: false,
            units: [],
            newUnit: {},
            currentTODO: [],
            todoText: "",
            goBack: false,
            loading: true,
            printDialog: false,
            confirmationDialog: false,
            editingField: '', // The field being edited
            previousValue: '', // The original value of the field before editing
        };
    }

    componentDidMount() {
        const {user} = this.props.user;
        const {match: {params}} = this.props;
        if (params.productId) {
            const id = params.productId;
            if (id && this.state.loading) {
                this.unsubscripeProductListener = firebase.firestore().collection('company').doc(user.companyID).collection('products').doc(id).onSnapshot(docSnapshot => {
                    const product = docSnapshot.data();
                    if (docSnapshot && docSnapshot.id) product.id = docSnapshot.id;
                    this.setState({
                        product,
                        id: docSnapshot.id,
                        loading: false,
                    });
                })
            }
        }
        this.unsubscripeUnitListener = firebase.firestore().collection('company').doc(user.companyID).collection('units').onSnapshot(querySnapshot => {
            let units = [];
            querySnapshot.forEach(doc => {
                let unit = doc.data();
                unit.id = doc.id;
                units.push(unit)
            });
            this.setState({
                units: units
            })
        });

        if (!params.productId) {
            this.unsubscripeProductlistListener = firebase.firestore().collection('company').doc(user.companyID).collection('products').onSnapshot(querySnapshot => {
                let productsList = [];
                querySnapshot.forEach(doc => {
                    let product = doc.data();
                    product.id = doc.id;
                    productsList.push(product);
                });
                const product = {
                    pNumber: productsList.length + 1,
                    isActive: "true",
                    isBillable: "true",
                    isMaterial: "false",
                    vat: 24
                };
                this.setState({
                    product: product,
                    loading: false
                });
            })
        }
    }

    componentWillUnmount() {
        if (this.unsubscripeProductListener) {
            this.unsubscripeProductListener();
        }
        if (this.unsubscripeUnitListener) {
            this.unsubscripeUnitListener();
        }
        if (this.unsubscripeProductlistListener) {
            this.unsubscripeProductlistListener();
        }
    }

    handleUnitAdd = () => {
        const {user} = this.props.user;
        const {newUnit} = this.state;


        firebase.firestore().collection('company').doc(user.companyID).collection('units').add(newUnit);

        this.setState({
            newUnit: {
                name: "",
                unit: "",
                id: "",
            }
        })
    };

    handleUnitDelete = (id) => {
        const {units} = this.state;
        const {user} = this.props.user;
        units.map(unit => {
            if (unit.id === id) {
                firebase.firestore().collection('company').doc(user.companyID).collection('units').doc(unit.id).delete();
            }
        });
    };

    changeUnitHandler = (event, field) => {
        let {newUnit} = this.state;

        newUnit[field] = event.target.value;
        this.setState({
            newUnit: newUnit
        })
    };


    changeHandler = (event, field) => {
        let { product } = this.state;

        const updatedProduct = { ...product };

        // Update the specific field with the new value
        updatedProduct[field] = event.target.value;

        // Update state with the new product object
        this.setState({
            product: updatedProduct,
        });
    };

    // Handler to capture original value on focus
    handleFocus = (field) => {
        const { product } = this.state;
        this.setState({
            editingField: field,
            previousValue: product[field],
        });
    };

   handleBlur = (field) => {
        const { previousValue, product } = this.state;

        if (product.id && previousValue !== product[field]) {
            const change = {
                change: field,
                oldValue: previousValue,
                newValue: product[field],
                date: moment().format('YYYYMMDDTHHmmss'),
            };

            // Update changeHistory
            if (!product.changeHistory) {
                product.changeHistory = [];
            }
            product.changeHistory.push(change);

            this.setState({
                product, // Update state with new change history
                editingField: '', // Clear editing field
                previousValue: '', // Clear previous value
            });
        } else {
            // Clear state if no change
            this.setState({
                editingField: '',
                previousValue: '',
            });
        }
    };

    handleKeyDown = (event) => {
        const {todoText, currentTODO} = this.state;

        if (keycode(event) === "enter") {
            currentTODO.push(todoText);
            this.setState({
                currentTODO: currentTODO,
                todoText: ""
            });
        }

        if (todoText === "" && keycode(event) === "backspace") {
            currentTODO.pop();
            this.setState({
                currentTODO: currentTODO
            });
        }
    };

    handleChipDelete = (item) => {
        const {currentTODO} = this.state;

        let list = currentTODO.filter(task => task !== item);
        this.setState({
            currentTODO: list
        });
    };

    generateQR = () => {
        const {product} = this.state;

        //TODO: is this going to be a proble between companies?
        product.code = `app.mobilityworkcloud.com/registry/products/${product.pNumber}`;

        this.setState({product});
    };

    checkQRExists = () => {
        const {product} = this.state;

        if (product.code) this.setState({printDialog: true})
    };

    saveHandler = () => {
        const {user} = this.props.user;
        let {product} = this.state;
        product.todo = this.state.currentTODO;
        console.log("add or update product: ", product)
        //update if we have id already
        if (this.state.id) {
            firebase.firestore().collection('company').doc(user.companyID).collection('products').doc(this.state.id).set(product)
                .catch(err => {
                    console.log("Error updating product ", err);
                    //TODO handle error
                })
        }

        //else create new
        else {
            firebase.firestore().collection('company').doc(user.companyID).collection('products').add(product)
                .catch(err => {
                    console.log("Error adding product ", err);
                    //TODO handle error
                })
        }

        this.props.history.goBack();
    };

    onDelete = () => {
        const {product} = this.state;
        const {user} = this.props.user;

        product.isDeleted = true;

        firebase.firestore().collection("company").doc(user.companyID).collection("products").doc(product.id).set(product)
            .then(() => {
                this.props.history.goBack();
            })
            .catch(err => {
                console.log("Error deleting product ", err);
                //TODO handle error
            })
    };

    renderUnitDialog = () => {
        return (
            <Dialog
                open={this.state.addUnit}
                onClose={() => this.setState({addUnit: !this.state.addUnit})}
                maxWidth="md"
                fullWidth
            >
                <Grid container style={{width: '100%'}}>
                    <Grid item xs={12} md={11}>
                        <DialogTitle style={{paddingLeft: '48%'}}>Edit units</DialogTitle>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <IconButton onClick={() => this.setState({addUnit: !this.state.addUnit})}>
                            <Clear/>
                        </IconButton>
                    </Grid>
                </Grid>
                <DialogContent>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Unit</TableCell>
                                <TableCell>{""}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.units.map(unit => (
                                <TableRow key={unit.id}>
                                    <TableCell>{unit.name}</TableCell>
                                    <TableCell>{unit.unit}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => this.handleUnitDelete(unit.id)}>
                                            <Delete/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        value={this.state.newUnit.name}
                                        onChange={(event) => this.changeUnitHandler(event, 'name')}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        value={this.state.newUnit.unit}
                                        onChange={(event) => this.changeUnitHandler(event, 'unit')}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => this.handleUnitAdd()}>
                                        <Add/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{
                            marginRight: 40
                        }}
                        onClick={() => this.setState({addUnit: !this.state.addUnit})}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    generate = () => {
        const {t} = this.props;
        return (

            <Paper style={{height: '100%', marginBottom: 10}}>
                <div style={{width: '100%', height: '8%', backgroundColor: '#2B81C1'}}>
                    {!this.state.id &&
                    <h1 style={{color: '#FFF', paddingTop: 20, paddingLeft: 40}}>{t("Add product")}</h1>}
                    {this.state.id &&
                    <h1 style={{color: '#FFF', paddingTop: 20, paddingLeft: 40}}>{t("Edit product")}</h1>}
                </div>

                <Grid container style={{height: '100%'}}>
                    <Grid item xs={11} style={{height: '80%', marginLeft: 30, marginRight: 30}}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label={t("Product number")}
                                    variant="outlined"
                                    margin="normal"
                                    value={this.state.product.pNumber || ''}
                                    onChange={(event) => this.changeHandler(event, 'pNumber')}
                                    onFocus={() => this.handleFocus('pNumber')} // Capture initial value
                                    onBlur={() => this.handleBlur('pNumber')}  // record changes
                                    style={{width: '100%'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <IconButton style={{marginTop: 20}}>
                                    <SkipNext/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <TextField
                                    label={t("Name")}
                                    variant="outlined"
                                    margin="normal"
                                    value={this.state.product.name || ''}
                                    onChange={(event) => this.changeHandler(event, 'name')}
                                    onFocus={() => this.handleFocus('name')} // Capture initial value
                                    onBlur={() => this.handleBlur('name')}  // record changes
                                    style={{width: '100%'}}
                                />
                            </Grid>
                        </Grid>


                        <Grid container spacing={16}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label={t("Unit")}
                                    variant="outlined"
                                    margin="normal"
                                    value={this.state.product.unit || ''}
                                    onChange={(event) => this.changeHandler(event, 'unit')}
                                    onFocus={() => this.handleFocus('unit')} // Capture initial value
                                    onBlur={() => this.handleBlur('unit')}  // record changes
                                    style={{width: '100%'}}
                                    select
                                >
                                    {this.state.units.map(unit => (
                                        <MenuItem key={unit.id} value={unit.unit}>{unit.unit}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>

                            <Grid item xs={12} md={1}>
                                <IconButton style={{marginTop: 20}}
                                            onClick={() => this.setState({addUnit: !this.state.addUnit})}>
                                    <Create/>
                                </IconButton>
                            </Grid>

                            <Grid item xs={12} md={7}>
                                <TextField
                                    label="EAN / QR"
                                    variant="outlined"
                                    margin="normal"
                                    value={this.state.product.code || ''}
                                    onChange={(event) => this.changeHandler(event, 'code')}
                                    onFocus={() => this.handleFocus('code')} // Capture initial value
                                    onBlur={() => this.handleBlur('code')}  // record changes
                                    style={{width: '100%'}}
                                    InputProps={{
                                        endAdornment: <div style={{display: 'flex'}}>
                                            <Button onClick={() => this.generateQR()}>{t("Generate")} </Button>
                                            <IconButton onClick={() => this.checkQRExists()}><Print/> </IconButton>
                                        </div>
                                    }}

                                />
                            </Grid>
                        </Grid>

                        <Grid container spacing={16}>
                            <Grid item xs={12} md={5}>
                                <FormControl>
                                    <FormLabel>{t("Type")}</FormLabel>
                                    <RadioGroup
                                        value={this.state.product.isMaterial}
                                        onChange={(event) => this.changeHandler(event, "isMaterial")}
                                    >
                                        <FormControlLabel value={"true"} control={<Radio color='primary'/>}
                                                          label={t("Material")}/>
                                        <FormControlLabel value={"false"} control={<Radio color='primary'/>}
                                                          label={t("Service")}/>
                                        <FormControlLabel value={"checkpoint"} control={<Radio color="primary"/>}
                                                          label={t("Checkpoint")}/>
                                        <FormControlLabel value={"travel"} control={<Radio color="primary"/>}
                                                          label={t("Travel")}/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormControl>
                                    <FormLabel>{t("Active_sub")}</FormLabel>
                                    <RadioGroup
                                        value={this.state.product.isActive}
                                        onChange={(event) => this.changeHandler(event, "isActive")}
                                    >
                                        <FormControlLabel value={"true"} control={<Radio color='primary'/>}
                                                          label={t("Yes")}/>
                                        <FormControlLabel value={"false"} control={<Radio color='primary'/>}
                                                          label={t("No")}/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl>
                                    <FormLabel>{t("Generate bill") + "?"}</FormLabel>
                                    <RadioGroup
                                        value={this.state.product.isBillable}
                                        onChange={event => this.changeHandler(event, "isBillable")}
                                    >
                                        <FormControlLabel value={"true"} control={<Radio color="primary"/>}
                                                          label={t("Yes")}/>
                                        <FormControlLabel value={"false"} control={<Radio color="primary"/>}
                                                          label={t("No")}/>
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                        </Grid>


                        <Grid container spacing={16}>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label={t("Buy in price")}
                                    variant="outlined"
                                    margin="normal"
                                    value={this.state.product.buyPrice || ''}
                                    onChange={(event) => this.changeHandler(event, 'buyPrice')}
                                    onFocus={() => this.handleFocus('buyPrice')} // Capture initial value
                                    onBlur={() => this.handleBlur('buyPrice')}  // record changes
                                    style={{width: '100%'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label={t("Sell price")}
                                    variant="outlined"
                                    margin="normal"
                                    value={this.state.product.sellPrice || ''}
                                    onChange={(event) => this.changeHandler(event, 'sellPrice')}
                                    onFocus={() => this.handleFocus('sellPrice')} // Capture initial value
                                    onBlur={() => this.handleBlur('sellPrice')}  // record changes
                                    style={{width: '100%'}}
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    label={t("VAT")}
                                    variant="outlined"
                                    margin="normal"
                                    value={this.state.product?.vat}
                                    onChange={(event) => this.changeHandler(event, 'vat')}
                                    onFocus={() => this.handleFocus('vat')} // Capture initial value
                                    onBlur={() => this.handleBlur('vat')}  // record changes
                                    style={{width: '100%'}}
                                    select
                                >
                                    <MenuItem value={25.5} key={25.5}>25,5 %</MenuItem>
                                    <MenuItem value={24} key={24}>24 %</MenuItem>
                                    <MenuItem value={14} key={14}>14 %</MenuItem>
                                    <MenuItem value={10} key={10}>10 %</MenuItem>
                                    <MenuItem value={0} key={0}>0 %</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>


                        <TextField
                            label={t("Invoice text")}
                            variant="outlined"
                            margin="normal"
                            value={this.state.product.invoiceText || ''}
                            onChange={(event) => this.changeHandler(event, 'invoiceText')}
                            onFocus={() => this.handleFocus('invoiceText')} // Capture initial value
                            onBlur={() => this.handleBlur('invoiceText')}  // record changes
                            style={{width: '100%'}}
                        />


                        <TextField
                            label={t("Description")}
                            variant="outlined"
                            margin="normal"
                            value={this.state.product.description || ''}
                            onChange={(event) => this.changeHandler(event, 'description')}
                            onFocus={() => this.handleFocus('description')} // Capture initial value
                            onBlur={() => this.handleBlur('description')}  // record changes
                            style={{width: '100%'}}
                            multiline
                        />

                        {this.state.product.isMaterial === "false" && <TextField
                            label={t("TODO list")}
                            variant="outlined"
                            margin="normal"
                            value={this.state.todoText}
                            onChange={(event) => this.setState({todoText: event.target.value})}
                            style={{width: '100%'}}
                            InputProps={{
                                startAdornment: this.state.currentTODO.map(item => (
                                    <Chip
                                        key={item}
                                        label={item}
                                        onDelete={() => this.handleChipDelete(item)}
                                    />
                                )),
                                onKeyDown: this.handleKeyDown
                            }}
                        />}

                    </Grid>


                    <Grid item xs={11} style={{marginLeft: 30, marginTop: 20, marginBottom: 20}}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} md={8}>
                                {this.state.id && <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => this.setState({confirmationDialog: true})}
                                >
                                    {t("Delete")}
                                </Button>}
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    variant="outlined"
                                    style={{width: '100%'}}
                                    onClick={() => this.props.history.goBack()}
                                >
                                    {t("Cancel")}
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{width: '100%'}}
                                    onClick={() => this.saveHandler()}
                                >
                                    {t("Save")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Paper>
        );
    };

    
    render() {
        const {t} = this.props;

        console.log("ProductEditor.js", this.state.product);
        
        return (
            <div style={{width: '100%', height: '100%', overflowY: 'auto'}}>
                <div style={{
                    alignSelf: 'center',
                    width: '50vw',
                    height: 'auto',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '3%'
                }}>
                    {this.state.loading && <CircularProgress/>}
                    {!this.state.loading && this.generate()}
                    {this.renderUnitDialog()}
                </div>

                {this.state.printDialog &&
                <ProductQRDialog
                    open={this.state.printDialog}
                    onClose={() => this.setState({printDialog: false})}
                    code={this.state.product.code}
                />
                }

                <GlobalConfirmationDialog
                    open={this.state.confirmationDialog}
                    onClose={() => this.setState({confirmationDialog: false})}
                    onConfirm={() => this.setState({confirmationDialog: false}, () => this.onDelete())}
                    title={t("Attention")}
                    message={`${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DeleteActionProduct")}?`}
                />
            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(ProductEditor));
