
import React, { Component } from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";

import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";

import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';

import { createReport, uploadToFirebaseStorage } from "../reportStoreFunctions";
import TextField from "../../TextComponents/TextField";
import ReportTypes from "../ReportTypes";
import { isEmpty } from '../../validations/isEmpty';
import { durationToHHmm  } from '../../util/TimeUtils';

class PDFWorkdays extends Component {

    createPdf = (key) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { t } = this.props;
        const { docRef } = this.state;
        const { user } = this.props.user;

        console.log("createPdf this.props ", this.props);

        //TODO: FINISH THIS : ONE REPORT PER EMPLOYEE!! - NO LOOP HERE

        const { searchObj } = this.props;
        const searchKeys = Object.keys(searchObj);

        const workdayRows = [];

        //searchKeys && searchKeys.forEach(key => {
        const employee = this.props.getEmployee(key);
        console.log("createPdf for emplName ", employee.name);

        //const newMainLine = {text: `${name} - Yht: ${(searchObj[key].normal).toFixed(2)}h`, style: "main"};
        const newMainLine = [
            {
                text: employee.name,
                style: "main",
                fontSize: 14, bold: true
            }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: durationToHHmm((searchObj[key].normal)) }, { text: "h" }
        ];
        workdayRows.push(newMainLine);

        workdayRows.push([{ text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }]);
        workdayRows.push([{ text: t('Date') }, { text: t('DayStartTime') }, { text: t('DayEndTime') }, { text: t('Total time') }, { text: t('Pauses') }, { text: t('Normal time') }, { text: t('Evening time') }, { text: t('Night time') }, { text: t('Saturday short') }, { text: t('Sunday short') }]);

        let workdays = [];

        Object.keys(searchObj[key]["normalByWeek"]).forEach(weekNumber => {
            searchObj[key]["normalByWeek"][weekNumber].workdays.forEach(workday => workdays.push(workday));
        });

        workdays = workdays.sort((a, b) => {
            if (moment(a.date).isBefore(b.date)) return -1;
            if (moment(a.date).isAfter(b.date)) return 1;
        });

        workdays.forEach(workday => {
            //let pausesTotal = 0
            //if (workday.pauses) workday.pauses.map(pause => { pausesTotal += pause.duration/3600000} )


            const newRow = [
                {
                    text: moment(workday.date).locale(user.lang ? user.lang : "fi").format('ddd')  //weekday
                        + " " + moment(workday.date).locale(user.lang ? user.lang : "fi").format("L")
                }, //time
                { text: !isEmpty(workday.startDT) ? moment(workday.startDT).locale(user.lang ? user.lang : "fi").format('LT') : '--:--' },
                { text: !isEmpty(workday.endDT)  ? moment(workday.endDT).locale(user.lang ? user.lang : "fi").format('LT') : '--:--' },
                { text: durationToHHmm(workday.duration) },
                { text: durationToHHmm(workday.pauses) },
                { text: durationToHHmm(workday.normal) },
                { text: durationToHHmm(workday.evening) },
                { text: durationToHHmm(workday.night) },
                { text: durationToHHmm(workday.saturday.toFixed) },
                { text: durationToHHmm(workday.sunday) },
            ];
            workdayRows.push(newRow);

        });

        //empty line between employees
        //const emptyLine = [ {text: ""}, {text: ""}, {text: ""}, {text: ""}, {text: ""}, {text: ""}];
        //workdayRows.push([{ text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }]);
        const endOfEmployeeLine = [
            {
                text: employee.name,
                style: "main",
            }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "Yht: " }, { text: durationToHHmm(searchObj[key].normal) }, { text: "" }
        ];
        workdayRows.push(endOfEmployeeLine);



        const workDaysTable = {
            fontSize: 10,
            margin: [10, 0, 10, 0],
            table: {
                headerRows: 0,
                widths: [80, 60, 60, 60, 30, 30, 30, 30, 35, 30],
                body: workdayRows,
            },
            layout: "noBorders",
            //layout: 'lightHorizontalLines',
        };

        docRef.content.push(workDaysTable);

        let total = {
            fontSize: 10,
            margin: [20, 20, 30, 0],
            table: {
                headerRows: 0,
                widths: [50, 50, 50, 50, 50],
                body: [
                    [{ text: t('Normal time') }, { text: t('Evening time') }, { text: t('Night time') }, { text: t('Saturday short') }, { text: t('Sunday short') }],
                    [{ text: durationToHHmm(searchObj[key].normal) }, { text: durationToHHmm(searchObj[key].evenings) }, { text: durationToHHmm(searchObj[key].nights) }, { text: durationToHHmm(searchObj[key].saturdays) }, { text: durationToHHmm(searchObj[key].sundays),  }]
                ],
                pageBreak: 'after'
            }
        };

        docRef.content.push(total)

        console.log("createPdf docRef for key", key);
        console.log("createPdf docRef ", docRef.content);

        //const summary = this.genereateSummary(reportSummary);
        //docRef.content.push(summary);

        //TODO: checkbox: "open report in browser tab"
        if (this.props.openInNewTab) pdfMake.createPdf(docRef).open();

        pdfMake.createPdf(docRef).getBase64((dataUrl) => {

            const startWeek = Object.keys(searchObj[key]["normalByWeek"])[0];
            const endWeek = Object.keys(searchObj[key]["normalByWeek"])[Object.keys(searchObj[key]["normalByWeek"]).length - 1];

            const recipients = [employee.email];
            //TODO, other default address
            const fileName = t(ReportTypes.WORKTIME_PERIOD) + '_' + (employee.name ? employee.name : '') + "_" + t('Weeks') + ' ' + startWeek + '-' + endWeek;

            uploadToFirebaseStorage(user, dataUrl, ReportTypes.WORKTIME_PERIOD).then(filestorageSuccess => {
                console.log('succesfully writed pdf to storage', filestorageSuccess)


                createReport(user, ReportTypes.WORKTIME_PERIOD, employee.name, recipients, "email", fileName, dataUrl, filestorageSuccess)
                    .then((report) => {

                        console.log("storeReport success: ", report);
                        this.props.onReportsCreated(true);
                    })
                    .catch((err) => {
                        console.log("storeReport ERROR: ", err);
                        this.props.onReportsCreated(false);
                    })
            });
        });
    };

    generateHeader = (key) => new Promise((resolve, reject) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { company, t } = this.props;
        const { searchObj } = this.props;
        const searchKeys = Object.keys(searchObj);

        //const key = searchKeys[0];
        const startWeek = Object.keys(searchObj[key]["normalByWeek"])[0];
        const endWeek = Object.keys(searchObj[key]["normalByWeek"])[Object.keys(searchObj[key]["normalByWeek"]).length - 1];

        const emplName = this.props.getEmployee(key).name;
        console.log("generate header for ", emplName)

        const docRef = {
            content: [
                {
                    margin: [0, 10, 10, 10],
                    table: {
                        headerRows: 1,
                        widths: ['*', '*'],
                        body: [
                            [
                                { text: company.name, alignment: 'left' },
                                { text: "", alignment: 'right', style: "subheader" },

                            ],
                            [
                                { text: company.streetAddress, alignment: 'left' },
                                { text: "", alignment: 'right', style: "subheader" },
                            ],
                            [
                                { text: company.city + ' ' + company.postCode, alignment: 'left' },
                                { text: "", alignment: 'right', style: "subheader" },
                            ]
                        ]
                    },
                    layout: "noBorders",
                },
                [""],
                { text: t("Employee report worktime") },
                {/* text: moment(dateRange.selection.startDate).format('L') + ' - ' + moment(dateRange.selection.endDate).format('L') , alignment: 'left' */ },
                { text: t("Weeks") + " " + startWeek + " - " + endWeek, alignment: 'left' },
                { text: t("Employee") + " " + emplName, alignment: 'left' },
                { text: "" },

            ],
            pageMargins: [20, 30, 20, 10],
            styles: {
                subheader: {
                    fontSize: 15,
                    bold: true
                }
            }
        };

        this.setState({
            docRef: docRef
        }, () => resolve());
    });


    generateReports = () => {
        console.log("createPdf this.props ", this.props);

        const { searchObj } = this.props;
        const searchKeys = Object.keys(searchObj);

        this.props.onReportCreate()

        try {
            searchKeys && searchKeys.forEach(key => {

                this.sleep(100).then(() => {
                    this.generateHeader(key).then(() => {  //created new docRef, new doc for each employee
                        this.createPdf(key); // one employee report 
                    })
                });

            })
        } catch (error) {
            this.props.onReportCreateError()
        }

    }


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    render() {
        return (
            <div style={{
                alignItems: 'center',
            }}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.generateReports()}
                    style={{
                        width: 180,
                        marginLeft: 75,
                        marginTop: 10
                    }}
                >
                    {this.props.t("Create")}
                </Button>
                <FormControlLabel
                    style={{
                        marginLeft: 75,
                        marginTop: 10,
                    }}
                    control={
                        <Checkbox
                            checked={this.props.openInNewTab}
                            onChange={(event) => this.props.onChangeOpenInNewTab(event.target.checked)}
                            value={"checkOpenOInNewTab"}
                            color="primary"
                        />
                    }
                    label={this.props.t("Open in new tab")}
                />
                <div style={{
                    marginLeft: 75,
                    marginTop: 10,
                }}>{this.props.t("Open in new tab tooltip")}</div>
            </div>
        )
    }
}


const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(PDFWorkdays));