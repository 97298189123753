import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/storage";
import moment from 'moment';
import ReportStatus from "./ReportStatus"
import uuid from 'uuid';

export const createReport = (user, type, subject, sendTo, sendMethod, fileName, pdf, filestorage) => new Promise((resolve, reject) => {
    //report date = now
    //type = HOUR_WAGER, PERIOD, PERIOD_HOUR, USER_HOUR, APPROVAL, CUSTOMER_TIME, CUSTOMER_BILLS, BILLS , KTV,
    //subject of the report: can be emplyeer or customer name
    //sendTo = {} - customer or employee or email
    //sentAt / not sent
    //pdf base64 data
    const recipients = Array.isArray(sendTo) ? sendTo : [sendTo];
    const createdAt = moment().format("YYYYMMDDTHHmmss")
    const report = {
        createdAt: createdAt,
        createdBy: user.name ? user.name : '',
        type: type,
        subject: subject, //subject of the report: can be emplyeer or customer name
        status: ReportStatus.CREATED,
        recipients: recipients,
        sendMethod: sendMethod,
        fileName: fileName,
        //pdf: pdf, //no more storing to DB - save to Storage
        storagePath: filestorage && filestorage.metadata && filestorage.metadata.fullPath ? filestorage.metadata.fullPath : '',
    }

    console.log("createReport ", report, user.name)

    firebase.firestore().collection('company').doc(user.companyID).collection('reports').add(report)
        .then(report => {
            console.log("createReport resolve ", report);
            resolve(report)
        })
        .catch(err => {
            console.log("Error adding report ", err);
            //TODO handle error
            reject(err)
        })
});

export const uploadToFirebaseStorage = (user, pdf, reportType) => new Promise((resolve, reject) => {
    console.log("uploadToFirebaseStorage ")

    if (user && pdf && reportType) {
        firebase.storage().ref('reports').child(user.companyID + '/' + reportType + '/' + uuid() + '.pdf').putString(pdf, 'base64').then(snapshot => {
            console.log("uploadToFirebaseStorage gonna resold snapshot ", snapshot)
            resolve(snapshot)
        }).catch(err => reject(err));
    } else reject(err);
});

export const storeReport = (user, reportti) => new Promise((resolve, reject) => {

    let report = { ...reportti };
    console.log("storeReport ", report, user);

    if (report) {
        if (report.id) {
            console.log("update Report ", report)
            report.updatedAt = moment().format("YYYYMMDDHHmmss");
            report.updatedBy = user.name ? user.name : '';

            firebase.firestore().collection('company').doc(user.companyID).collection('reports').doc(report.id).set(report, { merge: true })
                .then(report => resolve(report))
                .catch(err => {
                    console.log("Error updating report ", report, err);
                    //TODO handle error
                    reject(err)
                })

        } else {
            console.log("create Report ", report)

            report.createdAt = moment().format("YYYYMMDDHHmmss");
            report.createdBy = user.name;

            firebase.firestore().collection('company').doc(user.companyID).collection('reports').add(report)
                .then(report => resolve(report))
                .catch(err => {
                    console.log("Error adding report ", err);
                    //TODO handle error
                    reject(err)
                })
        }
    } else {
        console.log("Error storing report:  NO report!");
    }
});


export const deleteReport = (user, report) => {

    if (report && report.id) {
        firebase.firestore().collection("company").doc(user.companyID).collection("reports").doc(report.id).delete()
            .then(() => {
                console.log("Report deleted ");
            })
            .catch(err => {
                console.log("Error deleting report ", report.id, err);
                //TODO handle error
            })
    } else {
        console.log("Error deleting report NO ID ", report);
    }

}

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
    const reader = new FileReader;
    reader.onerror = reject;
    reader.onload = () => {
        resolve(reader.result);
    };
    reader.readAsDataURL(blob);
});

export const getReportPdf = (report) => new Promise((resolve, reject) => {

    console.log("getReportPdf ", report)
    if (report.storagePath) {
        firebase.storage().ref(report.storagePath).getDownloadURL().then(url => {
            console.log("fetch url ", url);
            fetch(url)
                .then(response => {
                    console.log('response', response)
                    return response.blob()
                })
                .then(blob => {
                    convertBlobToBase64(blob).then(base64 => {
                        base64 = base64.replace('octet-stream', 'pdf')
                        console.log("PDF: ", base64);
                        resolve(base64);
                    })
                })
                .catch((err) => {
                    console.log(err)
                    reject(err);
                });
            console.log(url);
        })
    } else if (report.pdf) {
        console.log("PDF ", report.pdf)
        const base64 = `data:application/pdf;base64,${report.pdf}`;
        resolve (base64);
    } else {
        reject();
    }
});