import React, { Component } from 'react';
import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";

import {
    UtilityBar,
    ListAreaHeader,
    ListAreaBody,
    ListAreaItem
} from '../ListComponents';

import {
    Button,
    IconButton
} from '@material-ui/core';

import { faLongArrowAltDown, faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';

import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import "./Keylist.css";


class Keylist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            keys: [],
            keysCopy: [],
            customers: [],
            employees: [],
            searchTerm: "",
            sortOrder: { dir: "desc", field: "number" }
        };
    }

    componentDidMount() {
        const { user } = this.props.user;

        this.unsubscribeKeyListener = firebase.firestore().collection("company").doc(user.companyID).collection("keys").onSnapshot(querySnap => this.onQuerySnap(querySnap, "keys"))

        this.unsubscribeCustomerListener = firebase.firestore().collection("customers")
            .where("companyID", "array-contains", user.companyID).onSnapshot(querySnap => this.onQuerySnap(querySnap, "customers"))

        this.unsunscribeEmployeeListener = firebase.firestore().collection("employees")
            .where("companyID", "==", user.companyID).onSnapshot(querySnap => this.onQuerySnap(querySnap, "employees"))
    }

    componentWillUnmount() {
        this.unsubscribeKeyListener && this.unsubscribeKeyListener();
        this.unsubscribeCustomerListener && this.unsubscribeCustomerListener();
        this.unsunscribeEmployeeListener && this.unsunscribeEmployeeListener();
    }


    onQuerySnap = (querySnap, arrayName) => {
        const data = [];
        let loading = true;

        querySnap.forEach(doc => {
            const singleData = doc.data();
            singleData.id = doc.id;

            if (arrayName === "employees") {
                singleData.name = `${singleData.firstName} ${singleData.familyName}`;
                loading = false;
            }

            if (arrayName === "keys") singleData.received = singleData.received ? moment(singleData.received.seconds * 1000).toDate() : "";
            if (arrayName === "keys") singleData.returned = singleData.returned ? moment(singleData.returned.seconds * 1000).toDate() : "";

            data.push(singleData);
        });

        this.setState({ [arrayName]: data, loading }, () => {
            if (arrayName === "employees") this.sortKeys("number", true);
        });
    };

    searchKeys = event => {
        let { keysCopy, customers, employees } = this.state;

        if (keysCopy.length === 0) keysCopy = JSON.parse(JSON.stringify(this.state.keys));

        const searchTerm = event.target.value;

        const keys = [];

        keysCopy.forEach(key => {

            const customer = customers.find(customer => customer.id === key.belongsTo);
            const employee = employees.find(employee => employee.id === key.holder);

            const searchTarget = key.number + (customer ? customer.name : "") + (employee ? employee.name : "");

            if (searchTarget.toLowerCase().search(searchTerm.toLowerCase()) > -1) keys.push(key);
        });

        this.setState({ keys, keysCopy, searchTerm });
    };

    sortKeys = (field, parse) => {
        let { sortOrder, keys } = this.state;

        let direction = "";

        if (sortOrder.field === field) direction = sortOrder.dir === "asc" ? "desc" : "asc";
        else direction = "desc";

        keys = keys.sort((a, b) => {
            const aField = parse ? parseFloat(a[field]) : a[field];
            const bField = parse ? parseFloat(b[field]) : b[field];

            if (direction === "asc" ? aField > bField : aField < bField) return -1;
            if (direction === "asc" ? aField < bField : aField > bField) return 1;
        });

        sortOrder.dir = direction;
        sortOrder.field = field;

        this.setState({ sortOrder, keys });
    };

    arrowDirection = field => {
        const { sortOrder } = this.state;

        if (sortOrder.field === field && sortOrder.dir === "desc") {
            return (
                <FontAwesomeIcon icon={faLongArrowAltUp} style={{ height: 15, width: 15 }} />
            );
        }
        else return <FontAwesomeIcon icon={faLongArrowAltDown} style={{ height: 15, width: 15 }} />
    };

    renderListItem = key => {
        const { customers, employees } = this.state;
        const { user } = this.props.user;


        const customer = customers.find(customer => customer.id === key.belongsTo);
        const employee = key.holder !== "Locker" ? employees.find(employee => employee.id === key.holder) : this.props.t("Locker")

        return (
            <Link key={key.id} to={`/registry/keys/edit/${key.id}`} className="key-link">
                <ListAreaBody>
                    <ListAreaItem variant="list" size="small">{key.number}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{customer && customer.name}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{employee && employee.name ? employee.name : employee}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{key.received ? moment(key.received).locale(user.lang ? user.lang : "fi").format("L") : ""}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{key.returned ? moment(key.returned).locale(user.lang ? user.lang : "fi").format("L") : ""}</ListAreaItem>

                </ListAreaBody>
            </Link>

        )
    };

    render() {
        const { loading, searchTerm } = this.state;
        const { t } = this.props;

        if (loading) return <div></div>
        else return (
            <div className="keylist-container">
                <UtilityBar
                    t={t}
                    searchBar
                    searchTerm={searchTerm}
                    searchOnChange={this.searchKeys}
                >
                    <div className="keylist-utility">
                        <Link to={"/registry/keys/add"} className="key-link">
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#1770B2", color: "#FFF" }}
                            >
                                {this.props.t("Add")}
                            </Button>
                        </Link>

                    </div>
                </UtilityBar>

                <ListAreaHeader>
                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortKeys("number", true)}>
                                {this.arrowDirection("number")}
                            </IconButton>
                        }
                        style={{ marginLeft: 0 }}
                    >
                        {`${t("Key")}#`}
                    </ListAreaItem>


                    <ListAreaItem
                        variant="title"
                        size="large"
                        style={{ marginLeft: 25 }}
                    >
                        {t("Customer")}
                    </ListAreaItem>


                    <ListAreaItem
                        variant="title"
                        size="large"
                    >
                        {t("Employee")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortKeys("received", false)}>
                                {this.arrowDirection("received")}
                            </IconButton>
                        }
                    >
                        {t("Received")}
                    </ListAreaItem>


                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortKeys("returned", false)}>
                                {this.arrowDirection("returned")}
                            </IconButton>
                        }
                    >
                        {t("Returned")}
                    </ListAreaItem>
                </ListAreaHeader>

                {this.state.keys.map(this.renderListItem)}
            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(Keylist));
