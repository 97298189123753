import React, {Component} from 'react';

import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { fi, enGB} from 'date-fns/locale'

import {
    Dialog,
    DialogActions,
    Button, DialogTitle
} from "@material-ui/core";

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";

class DateDialog extends  Component{
    constructor(props) {
        super(props);
        this.state = {
            dateRange: {
                selection: {
                    startDate: props.start ? props.start : props.value,
                    endDate: props.end ? props.end : props.value,
                    key: "selection"
                }
            }
        }
    }

    onDateChange = event => {
        const {dateRange} = this.state;

        dateRange.selection = event.selection;

        this.setState({ dateRange })
    };

    render() {
        const {dateRange} = this.state;
        const {t} = this.props;
        const {user} = this.props.user;
        const locale = user.lang ? (user.lang === "en-gb" ? enGB : fi) : fi;

        return (
            <div>
                {this.props.selectType === "single" &&
                <Dialog
                    open={this.props.open}
                    onClose={() => this.props.onClose()}
                >
                    <DateRange
                        ranges={[dateRange.selection]}
                        onChange={event => this.onDateChange(event)}
                        locale={locale}
                        moveRangeOnFirstSelection={true}
                        showDateDisplay={false}
                        preview={{
                            startDate: dateRange.selection.startDate,
                            endDate: dateRange.selection.endDate,
                            color: '#FFF'
                        }}

                    />

                    <DialogActions>
                        <Button onClick={() => this.props.onClose()}>
                            {t("Cancel")}
                        </Button>

                        <Button
                            onClick={() => this.props.onChange(this.state.dateRange)}
                        >
                            {t("Save")}
                        </Button>
                    </DialogActions>
                </Dialog>
                }

                {this.props.selectType === "range" &&
                <Dialog
                    open={this.props.open}
                    onClose={() => this.props.onClose()}
                >
                    <DialogTitle>Select range</DialogTitle>
                    <DateRange
                        ranges={[dateRange.selection]}
                        onChange={event => this.onDateChange(event)}
                        locale={locale}
                        moveRangeOnFirstSelection={false}
                    />
                    <DialogActions style={{marginRight: 70}}>
                        <Button onClick={() => this.props.onClose()}>
                            {t("Cancel")}
                        </Button>

                        <Button onClick={() => this.props.onChange(this.state.dateRange)}>
                            {t("Select")}
                        </Button>
                    </DialogActions>
                </Dialog>
                }
            </div>
        );
    }


}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(DateDialog));