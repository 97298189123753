import React, { Component } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';

import {
    UtilityBar,
    ListAreaHeader,
    ListAreaBody,
    ListAreaItem,
} from '../ListComponents';

import {
    Button
} from '@material-ui/core';

import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import "./JobModelList.css";
class JobModelList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            models: [],
            modelsCopy: [],
            searchTerm: "",
        };
    }

    componentWillMount() {
        const { user } = this.props.user;
        this.unsubscribeJobmodelListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').onSnapshot(this.onModelSnapshot);
    }

    onModelSnapshot = querySnapshot => {
        let models = [];

        querySnapshot.forEach(doc => {
            let model = doc.data();
            model.id = doc.id;

            if (model.type === "model") models.push(model);
        });

        this.setState({
            models: models,
            modelsCopy: JSON.parse(JSON.stringify(models))
        });
    };

    componentWillUnmount() {
        this.unsubscribeJobmodelListener && this.unsubscribeJobmodelListener();
    }

    searchModel = event => {
        const { modelsCopy } = this.state;

        const searchTerm = event.target.value;
        const models = [];

        modelsCopy && modelsCopy.forEach(model => {
            if (model.name.toLowerCase().search(searchTerm.toLowerCase()) > -1) models.push(model);
        });

        this.setState({ models, searchTerm });
    };

    renderListItem = model => {

        let taskArr = [];
        model.tasks && model.tasks.forEach(task => {
            taskArr.push(task.name);
        });


        return (
            <Link to={`/registry/jobmodels/edit/${model.id}`} className="jm-link" key={model.id}>
                <ListAreaBody>
                    <ListAreaItem variant="list" size="large">{model.name}</ListAreaItem>
                    <ListAreaItem variant="list" size="fullWidth">{model.notes ? model.notes : ""}</ListAreaItem>
                </ListAreaBody>
            </Link>
        );
    };


    render() {
        const { searchTerm, models } = this.state;
        const { t } = this.props;
        return (
            <div className="jm-list-container">
                <UtilityBar
                    t={t}
                    searchBar
                    searchTerm={searchTerm}
                    searchOnChange={this.searchModel}
                >
                    <div className="jm-utility">
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#1770B2', color: '#FFF', marginRight: 5 }}
                            onClick={() => this.props.history.goBack()}
                        >
                            {t("Back")}
                        </Button>
                        <Link to={"/registry/jobmodels/add"} className="jm-link">
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#1770B2', color: '#FFF', height: 40 }}
                            >
                                {t("Add")}
                            </Button>
                        </Link>

                    </div>
                </UtilityBar>

                <ListAreaHeader>
                    <ListAreaItem
                        variant="title"
                        size="large"
                    >
                        {t("Name")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="fullWidth"
                    >
                        {t("Tasks")}
                    </ListAreaItem>
                </ListAreaHeader>

                {models && models.map(this.renderListItem)}

                <div style={{ height: 80 }} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(JobModelList));
