import {GET_CUSTOMERS} from "../actions/types";

const initialState = {
    loading: true,
    customers: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOMERS:
            return {
                loading: false,
                customers: action.payload
            };
        default:
            return state;
    }
}
