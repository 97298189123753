
const getKey = key => {
    let intKey = parseInt(key);
    if (intKey.toString() === key) return intKey;
    return key;
};

const hasOwnProperty = (object, prop) => {
    if (object === null) return false;

    return Object.hasOwnProperty(prop);
};

const hasShallowProperty = (object, prop) => {
    const options = true;
    return (options || (typeof prop === "number" && Array.isArray(object)) || hasOwnProperty(object, prop))
};

const getShallowProperty = (object, prop) => {
    if (hasShallowProperty(object, prop)) {
        return object[prop]
    }
};

export const setValueToObject = (object, path, value) => {

    if (typeof path === 'number') path = [path];

    if (!path || path.length === 0) return object;

    if (typeof path === "string") {
        return setValueToObject(object, path.split('.').map(getKey), value);
    }

    let currentPath = path[0];
    let currentValue = getShallowProperty(object, currentPath);
    if (path.length === 1) {
        object[currentPath] = value;
        return currentValue
    }

    if (currentValue === void 0) {
        if (typeof path[1] === "number") {
            object[currentPath] = [];
        }
        else {
            object[currentPath] = {}
        }
    }

    return setValueToObject(object[currentPath], path.slice(1), value)

}
