import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Button } from "@material-ui/core";

import {
    UtilityBar,
    ListAreaHeader,
    ListAreaBody,
    ListAreaItem
} from '../ListComponents';

import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./JobTemplatesList.css"
class JobTemplateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            templates: [],
            templatesCopy: [],
            searchTerm: ""
        };
    }

    componentWillMount() {
        const { user } = this.props.user;
        this.unsubscribeModelListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').onSnapshot(this.onModelSnapshot);
    }

    onModelSnapshot = querySnapshot => {
        const templates = [];

        querySnapshot.forEach(doc => {
            let template = doc.data();
            template.id = doc.id;
            if (template.type === "template" && !template.isDeleted) {
                templates.push(template);
            }
        });
        this.setState({
            loading: false,
            templates: templates,
            templatesCopy: JSON.parse(JSON.stringify(templates))
        });
    };

    componentWillUnmount() {
        this.unsubscribeModelListener && this.unsubscribeModelListener();
    }

    searchModel = event => {
        const { templatesCopy } = this.state;

        const searchTerm = event.target.value;
        const templates = [];

        templatesCopy && templatesCopy.forEach(template => {
            if (template.name.toLowerCase().search(searchTerm.toLowerCase()) > -1) templates.push(template);
        });

        this.setState({ searchTerm, templates });
    };

    renderListItem = template => {
        return (
            <Link key={template.id} to={`/registry/jobtemplates/edit/${template.id}`} className="jt-link">
                <ListAreaBody>
                    <ListAreaItem variant="list" size="large">{template.name}</ListAreaItem>
                    <ListAreaItem variant="list" size="fullWidth">{template.notes}</ListAreaItem>
                </ListAreaBody>
            </Link>
        )
    };

    render() {
        const { loading, searchTerm, templates } = this.state;
        const { t } = this.props;
        if (loading) return <div></div>
        else return (
            <div className="jt-container">
                <UtilityBar
                    t={t}
                    searchBar
                    searchTerm={searchTerm}
                    searchOnChange={this.searchModel}
                >
                    <div className="jt-utility">
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#1770B2', color: '#FFF', marginRight: 5 }}
                            onClick={() => this.props.history.goBack()}
                        >
                            {t("Back")}
                        </Button>
                        <Link to={"/registry/jobtemplates/add/"} className="jt-link">
                            <Button
                                variant="contained"
                                style={{ backgroundColor: '#1770B2', color: '#FFF' }}
                            >
                                {t("Add")}
                            </Button>
                        </Link>
                    </div>
                </UtilityBar>

                <ListAreaHeader>
                    <ListAreaItem
                        variant="title"
                        size="large"
                    >
                        {t("Name")}
                    </ListAreaItem>

                    <ListAreaItem
                        varinat="title"
                        size="fullWidth"
                    >
                        {t("Description")}
                    </ListAreaItem>
                </ListAreaHeader>

                {templates && templates.map(this.renderListItem)}

                <div style={{ height: 80 }} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(JobTemplateList));
