import {isEmpty} from "./isEmpty";
import Validator from 'validator';

export const validateFBWorklog = worklog => {
    let errors = {};

    worklog.jobStartDT = !isEmpty(worklog.jobStartDT) ? worklog.jobStartDT : "";
    worklog.customer = !isEmpty(worklog.customer) ? worklog.customer : "";
    worklog.jobID = !isEmpty(worklog.jobID) ? worklog.jobID : "";
    worklog.status = !isEmpty(worklog.status) ? worklog.status : "";
    worklog.employee = worklog.employee && worklog.employee.length > 0 ? worklog.employee : "";


    if (typeof worklog.startDT === "object") errors.startDT = "StartDT is object";
    if (worklog.endDT && typeof worklog.endDT === "object") errors.endDT = "EndDT is object";

    if (Validator.isEmpty(worklog.jobStartDT)) {
        errors.jobStartDT = "Required";
    }

    if (Validator.isEmpty(worklog.customer)) {
        errors.customer = "Required";
    }

    if (Validator.isEmpty(worklog.jobID)) {
        errors.jobID = "Required";
    }

    if (Validator.isEmpty(worklog.status)) {
        errors.status = "Required";
    }

    // Check billing object is it is supposed to exist
    if (worklog.status !== "Ongoing" && worklog.status !== "Discarded" && worklog.status !== "Cancelled" & worklog.previousStatus !== "Cancelled") {
        worklog.billing = !isEmpty(worklog.billing) ? worklog.billing : "";

        if (typeof worklog.billing === "string") {
            if (Validator.isEmpty(worklog.billing)) errors.billing = "Required"
        }
        else if (typeof worklog.billing !== "string" && !worklog.billing.billableHours && worklog.billing.billableHours < 0) errors.billableHours = "Required";
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
};