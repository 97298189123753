import React, { Component } from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { Button, FormControlLabel, Checkbox } from '@material-ui/core';

import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";

import { connect } from 'react-redux';

import { saveSend } from "../../actions/sendapprovalActions";

import { withTranslation } from "react-i18next";
import { createReport, uploadToFirebaseStorage } from "../reportStoreFunctions";
import { isEmpty } from "../../validations/isEmpty";
import ReportTypes from "../ReportTypes";

class PDFApproval extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docRef: {},
            looping: false
        };
    }

    findEmployee = ids => {
        const { employees, selectedEmployee } = this.props;

        if (selectedEmployee) return selectedEmployee;

        const nameArr = [];

        ids && ids.forEach(id => {
            const employee = employees.find(employee => employee.id === id);

            const name = `${employee.firstName} ${employee.familyName}`;

            if (employee && !nameArr.includes(name)) nameArr.push(name)
        });

        return nameArr.join(", ");
    };

    checkTitle = (oldTitle, newTitle) => {

        const commaRegex = /^.*[,].*$/;

        const newTitleArr = [];

        if (commaRegex.test(oldTitle)) oldTitle.split(", ").forEach(item => {
            if (!newTitleArr.includes(item)) newTitleArr.push(item);
        });
        else if (!newTitleArr.includes(oldTitle)) newTitleArr.push(oldTitle);


        if (commaRegex.test(newTitle)) newTitle.split(", ").forEach(item => {
            if (!newTitleArr.includes(item)) newTitleArr.push(item);
        });
        else if (!newTitleArr.includes(newTitle)) newTitleArr.push(newTitle);


        return newTitleArr.join(", ");

    };

    formRowTitle = item => {
        console.log('formRowTitle', item);
        const { descriptionField } = this.props;
        const { t } = this.props;

        let title = descriptionField === "task" ? [] : "";

        if (descriptionField === "task" && item.billing && item.billing.billableHoursByTask) {
            item.billing.billableHoursByTask.forEach((task, index) => {

                if (task.duration > 0) title.push(task.name)
            });

            return title.length === 0 ? t("No_billable_task") : title.length === 1 ? title[0] : title.join(", ");
        }
        if (descriptionField !== "task") return item.additionalDescription ? item.additionalDescription : "";
        else return "";

    };

    formFreeText = () => {
        const { company } = this.props;

        let freeText = "";

        if (company.settings.reports && company.settings.reports.approvalFreeText) freeText = company.settings.reports.approvalFreeText;

        return freeText
    };


    addWorklogDataToDay = (oldRow, newRow) => {

        let combinedRow = [...oldRow];
        combinedRow[2] = this.checkTitle(oldRow[2], newRow[2]);
        combinedRow[3] = oldRow[3] === newRow[3] ? oldRow[3] : oldRow[3] + ', ' + newRow[3];
        combinedRow[5] = parseFloat(oldRow[5]) + parseFloat(newRow[5]);
        combinedRow[8] = parseFloat(oldRow[8]) + parseFloat(newRow[8]);
        combinedRow[9] = parseFloat(oldRow[9]) + parseFloat(newRow[9]);
        return combinedRow;
    };

    createPdf = data => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { t } = this.props;
        const { docRef } = this.state;
        const ref = {};
        const { user } = this.props.user;
        const { products } = this.props.products;
        const { selectedWorksite, company } = this.props;

        const weekNumbers = [];
        const weekdayArr = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const body = [];

        const reportSummary = {
            normalHours: 0,
            km: 0
        };

        data && data.forEach(item => {
            console.log('data.forEach', item)
            const itemWeek = moment(item.startDT).format('w');
            const itemDay = moment(item.startDT).format('dddd');

            // Count hours
            let hours = 0;
            if (this.props.selectedEmployee) hours = item.billing && item.billing.billableHours ? (item.billing.billableHours / 3600000).toFixed(2) : 0;
            else hours = item.billing && item.billing.billableHours ? ((item.billing.billableHours / 3600000) * item.employee.length).toFixed(2) : 0;

            // Find travel
            let km = 0;

            item.materials && item.materials.forEach(material => {
                const product = products.find(product => product.pNumber === material.pNumber);
                if (product && product.isMaterial === "travel") km += parseFloat(material.amount)
            });

            // Create day
            let day = [
                t(moment(item.startDT).format('dd')),
                moment(item.startDT).locale(user.lang ? user.lang : "fi").format('L'),
                this.formRowTitle(item),
                this.findEmployee(item.employee),
                this.props.selectedWorksite && this.props.selectedWorksite.name ? this.props.selectedWorksite.name : "",
                hours, "", "",
                hours,
                km
            ];


            if (ref[itemWeek]) {
                // If day already Exists add required fields to that instead of overwriting

                if (ref[itemWeek][itemDay] && ref[itemWeek][itemDay].length > 0) ref[itemWeek][itemDay] = this.addWorklogDataToDay(ref[itemWeek][itemDay], day);
                else ref[itemWeek][itemDay] = day;
                ref[itemWeek].weekTotal += parseFloat(hours);
                reportSummary.normalHours += parseFloat(hours);
                reportSummary.km += km;
            }
            else {
                ref[itemWeek] = {
                    Monday: [],
                    Tuesday: [],
                    Wednesday: [],
                    Thursday: [],
                    Friday: [],
                    Saturday: [],
                    Sunday: [],
                    weekTotal: 0,
                    weekKm: 0
                };
                weekNumbers.push(itemWeek);
                ref[itemWeek][itemDay] = day;
                ref[itemWeek].weekTotal += parseFloat(hours);
                reportSummary.normalHours += parseFloat(hours);
                reportSummary.km += km;
            }
        });

        weekNumbers.forEach(week => {
            weekdayArr.forEach(day => {
                const short = day.slice(0, 2);
                const emptyRow = [t(short), "", "", "", "", "", "", "", "", ""];
                ref[week][day].length > 0 ? body.push(ref[week][day]) : body.push(emptyRow);
            });
        });

        let week = {
            fontSize: 10,
            margin: [10, 0, 0, 0],
            table: {
                headerRows: 0,
                widths: [50, 60, 200, 100, 100, 30, 30, 30, 30, 30],
                body: body
            }
        };

        let total = {
            fontSize: 10,
            margin: [506, 0, 30, 0],
            table: {
                headerRows: 0,
                widths: [50, 30, 30, 30, 30, 30],
                body: [
                    [{
                        text: t("Total") + ":",
                        fontSize: 10,
                        alignment: "right"
                    }, reportSummary.normalHours, 0, 0, reportSummary.normalHours, reportSummary.km]
                ]
            }
        };

        let date = {
            text: t("Date") + ":_________________________________", marginLeft: 200, marginTop: 50
        };

        let signature = {
            text: t("Signature") + ":_________________________________", marginLeft: 172, marginTop: 10
        };


        docRef.content.push(week);
        docRef.content.push(total);
        docRef.content.push(date);
        docRef.content.push(signature);


        //pdfMake.createPdf(docRef).download();
        if (this.props.openInNewTab) pdfMake.createPdf(docRef).open();

        pdfMake.createPdf(docRef).getBase64((dataUrl) => {
            this.saveToRedux(dataUrl);

            let reportOwner = !isEmpty(this.props.customer) ? this.props.customer : '';
            if (!isEmpty(this.state.worksiteName)) reportOwner = reportOwner + "/" + this.state.worksiteName;

            //TODO, other default address
            const recipients = this.props.initialEmail ? this.props.initialEmail : [];

            const fileName = t(ReportTypes.APPROVAL) + '_' + reportOwner + "_" + t('Weeks') + ' ' + this.state.startWeek + '-' + this.state.endWeek;

            uploadToFirebaseStorage(user, dataUrl, ReportTypes.APPROVAL).then(filestorageSuccess => {
                console.log('succesfully writed pdf to storage', filestorageSuccess)

                createReport(user, ReportTypes.APPROVAL, reportOwner, recipients, "email", fileName, dataUrl, filestorageSuccess)
                    .then((report) => {
                        console.log("storeReport success: ", report);
                        this.props.onReportsCreated(true);
                    })
                    .catch((err) => {
                        console.log("storeReport ERROR: ", err);
                        this.props.onReportsCreated(false);
                    })
            })
        })
    }

    saveToRedux = (pdf) => {
        const customer = this.props.customers.find(item => item.name === this.props.customer);

        this.props.saveSend(customer, this.props.selectedLogs, this.props.company, pdf)
    };


    generateHeader = data => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { customerModel, selectedWorksite, t, start, end } = this.props;
        const { company } = this.props;

        this.props.onReportCreate();

        const worksiteName = selectedWorksite && selectedWorksite.name ? `${selectedWorksite.name}, ${selectedWorksite.city}` : "";
        const worksiteNumber = selectedWorksite && selectedWorksite.wNumber ? `Työ nro: ${selectedWorksite.wNumber}` : "Työ nro:";
        const workNumber = customerModel && customerModel.workNumber ? `Työnumero: ${customerModel.workNumber}` : "Työnumero: ";
        //selectedWorksite && selectedWorksite.wNumber? `Työnro: ${selectedWorksite.wNumber}` : "Työnro:"

        let headerSize = 14,
            normalText = 10,
            smallText = 8;


        data.sort(function (a, b) {
            if (moment(a.startDT).isSameOrBefore(b.startDT)) return -1;
            if (moment(a.startDT).isSameOrAfter(b.startDT)) return 1;
        });

        let first = data[0];
        let last = data[data.length - 1];

        let body = [];

        if (this.props.company) {

            body = [

                [{ text: company.name, fontSize: headerSize }, { text: this.props.customer, fontSize: headerSize }],
                [{ text: company.streetAddress, fontSize: normalText }, { text: 'Kohde:', fontSize: normalText }],
                [{ text: company.postCode + ' ' + company.city, fontSize: normalText }, {
                    text: `${worksiteName}`,
                    fontSize: normalText
                }],
                [{ text: 'Puh. ' + company.phone, fontSize: normalText }, { text: worksiteNumber, fontSize: normalText }],
                [{ text: company.email, fontSize: normalText }, ''],
                [{ text: 'Y-tunnus: ' + company.yCode, fontSize: normalText }, {
                    text: this.formFreeText(),
                    fontSize: normalText
                }],
                [{ text: company.name, fontSize: normalText, fontSize: normalText }, {
                    text: workNumber,
                    fontSize: normalText
                }]
            ]
        }
        else {
            body = [

                [{ text: 'Geo Exact oy', fontSize: headerSize }, { text: 'Rakennus Omera Oy', fontSize: headerSize }],
                [{ text: 'Hurtinmäentie 24', fontSize: normalText }, { text: 'Kohde:', fontSize: normalText }],
                [{ text: '03300 Otalampi', fontSize: normalText }, {
                    text: 'Pääkirjasto, Kirkkonummi',
                    fontSize: normalText
                }],
                [{ text: 'Puh. 050 123 4567', fontSize: normalText }, { text: 'Työnro: 3317', fontSize: normalText }],
                [{ text: 'joni.ainesmaa@geoexxact-fi', fontSize: normalText }, ''],
                [{ text: 'Y-tunnus: 2754021-8', fontSize: normalText }, { text: 'Geo Exact Oy', fontSize: normalText }],
                [{ text: 'Sop. Ehd. KSE 2013', fontSize: normalText }, { text: 'Työnumero: G3047', fontSize: normalText }]
            ]
        }

        let docRef = {
            header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                console.log("called header fucntion with ", currentPage, "/", pageCount, pageSize);

                return [
                    /*{ text: "Sivu:" + currentPage + "/" + pageCount, alignment: 'right', marginRight: 15, marginTop: 15},*/
                    {
                        margin: [30, 15, 15, 0],
                        fontSize: 10,
                        layout: 'noBorders',
                        table: {
                            headerRows: 1,
                            widths: [pageSize.width / 10, pageSize.width / 10, pageSize.width / 2 - 100, pageSize.width / 10, pageSize.width / 10, pageSize.width / 10],
                            body: [
                                [
                                    { text: company.name, fontSize: smallText },
                                    { text: 'Y-tunnus: ' + company.yCode, fontSize: smallText },
                                    {
                                        text: 'Hyväksyntäraportti vkot: ' + moment(start).format('ww') + '-' + moment(end).format('ww') + "/" + moment(first).format('YYYY'),
                                        fontSize: smallText,
                                        alignment: 'center'
                                    },
                                    { text: worksiteNumber, fontSize: smallText },
                                    { text: workNumber, fontSize: smallText },
                                    {
                                        text: "Sivu:" + currentPage + "/" + pageCount,
                                        fontSize: smallText,
                                        alignment: 'right'
                                    },
                                ],
                            ],
                        }
                    }
                ]
            },
            footer: function (currentPage, pageCount) {
                return [{
                    text: "Sivu:" + currentPage + "/" + pageCount,
                    alignment: 'right',
                    fontSize: smallText,
                    marginRight: 25,
                    marginBottom: 5
                }]
            },
            content: [
                {
                    margin: [0, 0, 0, 0],
                    table: {
                        headerRows: 1,
                        widths: ['*'],
                        body: [
                            [
                                {
                                    margin: [10, 0, 0, 10],
                                    table: {
                                        headerRows: 1,
                                        widths: ['*', '*'],
                                        body: body
                                    },
                                    layout: 'noBorders'
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    text: moment(first).format('YYYY') + ' viikot ' + moment(start).format('ww') + ' - ' + moment(end).format('ww'),
                    marginLeft: 280
                },
                {
                    margin: [10, 5, 0, 0],
                    fontSize: 10,
                    table: {
                        headerRows: 1,
                        widths: [50, 60, 200, 100, 100, 30, 30, 30, 30, 30],
                        body: [
                            [t('Day'), t('Date'), t('Done job'), t('Employee'), t('Worksite'), 'h', '50%', '100%', t("Sum") + ':', 'Km'],
                        ]
                    }
                }
            ],
            pageMargins: [30, 60, 10, 30],
            pageOrientation: 'landscape'
        };

        this.setState({
            docRef: docRef,
            startWeek: moment(start).format('ww'),
            endWeek: moment(end).format('ww'),
            worksiteName: worksiteName,
        });

        setTimeout(() => {
            this.createPdf(data)
        }, 100);

    };

    render() {
        
        return (
            <div>
                <Button
                    variant="outlined"
                    style={{
                        width: 210,
                        marginLeft: 50,
                        marginTop: 10
                    }}
                    onClick={() => this.generateHeader(this.props.selectedLogs)}
                >
                    {this.props.t("Generate_PDF")}
                </Button>
                <FormControlLabel
                    style={{
                        marginLeft: 50,
                        marginTop: 10,
                    }}
                    control={
                        <Checkbox
                            checked={this.props.openInNewTab}
                            onChange={(event) => this.props.onChangeOpenInNewTab(event.target.checked)}
                            value={"checkOpenOInNewTab"}
                            color="primary"
                        />
                    }
                    label={this.props.t("Open in new tab")}
                />
                <div style={{
                    marginLeft: 50,
                    marginTop: 10,
                }}>{this.props.t("Open in new tab tooltip")}</div>

            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    user: state.user,
    products: state.products
});

export default connect(mapStateToProps, { saveSend })(withTranslation()(PDFApproval));