import React from 'react';

import {
    TextField,
    MenuItem, Button
} from '@material-ui/core';

import AutocompleteReports from '../AutocompleteReports';
import moment from 'moment';

import {withTranslation} from 'react-i18next';

const PeriodComponent = props => {
    const {t} = props;

    const formPeriodSelector = () => {
        const {company} = props;

        // Set preset values
        let start = moment("2019-01-01T00:00");  //use this inital value, rare occurrence of Jan 1 & monday & week 1
        let end = moment("2019-01-21T00:00");
        //let selectedYear = "2020";

        // Override preset values if period weeks are set from settings
        if (company.settings && company.settings.job && company.settings.job.period) {
            start = moment(company.settings.job.period.start);
            end = moment(company.settings.job.period.end);
        }

        console.log("formPeriodSelector", start.format('YYYYMMDD'), end.format('YYYYMMDD'))

        let adder = (moment(end).format('W') - moment(start).format('W') + 1);
        const periods = [];

        if (moment(end) > moment().subtract(12, "months")) {
            const firstPeriod = `${t("Week")} ${moment(start).format('W')}/${moment(start).format('YYYY')} - ${moment(end).format('W')}/${moment(end).format('YYYY')}`;
            periods.push(firstPeriod)
        }

        do {
            start = moment(start).add(adder, "weeks");
            end = moment(end).add(adder, "weeks");

            if (moment(end) > moment().subtract(12, "months")) {

                // handle weeks at around new year -
                let startWeek = moment(start).format('W');
                let startYear = moment(start).format('YYYY');
                let endWeek = moment(end).format('W');
                let endYear = moment(end).format('YYYY');

                if (endYear > startYear) {
                    //jos vuosi vaihtunut alku ja loppu ajan välissä
                    //jos aikaväli alkaa vuoden vikalla viikolla, niin momenting viikko on oikein, mutta vuosi väärin
                    //sääntö: jos vuoden eka päivä on ennen torstaita niin vko on nro 1 muuten 52 tai 53
                    let weeksInStartYear = moment(start).weeksInYear();
                    console.log("year has changed during period ", startWeek, startYear, endWeek, endYear)
                    if (startWeek < weeksInStartYear && startYear < endYear) {
                        startYear = endYear;
                    }
                }

                const countedPeriod = `${t("Week")} ${startWeek}/${startYear} - ${endWeek}/${endYear} `;
                console.log("push period: ", countedPeriod)
                periods.push(countedPeriod);
            }

        } while (end < moment().add(adder, "weeks"));

        let counter = 0;
        return periods.map(item => {
            counter++;
            return React.cloneElement(
                <MenuItem value={item} key={counter}>{item}</MenuItem>
            );

        });
    };

    const getError = (field) => {
        return props.errors[field].length > 0
    };


    return (
        <div>

            <TextField
                variant="outlined"
                value={props.selectedPeriod}
                onChange={event => props.onChange(event, 'selectedPeriod')}
                style={{
                    width: '100%',
                    marginTop: 10
                }}
                select
                error={getError("period")}
                helperText={props.errors.period}
            >
                <MenuItem key="select" value="select">{t("Select period")}</MenuItem>
                {formPeriodSelector()}
            </TextField>


            <AutocompleteReports
                searchArray={props.employees}
                field="Employee"
                label={t("Search employees")}
                onSelect={event => props.onChange(event, 'employee')}
            />

            <br/>

            <Button
                variant="outlined"
                style={{
                    marginTop: 20,
                    marginLeft: 50
                }}
                onClick={() => props.onReset()}
            >
                {t("Reset")}
            </Button>

            <Button
                variant="outlined"
                color="primary"
                style={{
                    marginLeft: 50,
                    marginTop: 20
                }}
                onClick={() => props.onSearch()}
            >
                {t("Search")}
            </Button>
        </div>
    );
};

export default withTranslation()(PeriodComponent);