import React, { Component } from 'react';

import {
    Button,
    Checkbox,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel, IconButton,
    MenuItem,
    TextField
} from "@material-ui/core";

import { Delete } from "@material-ui/icons";

import keycode from 'keycode';
import moment from 'moment';
import "moment/locale/en-gb";
import "moment/locale/fi";


import DateDialog from './DateDialog';
import RecurrenceDialog from './RecurrenceDialog';
import TimePicker from './TimePicker';
import AutocompleteCalendar from './AutocompleteCalendar';

import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

class FullscreenDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchCustomer: "",
            searchEmployee: "",
            todoText: "",
            selectedModel: {},
            customerWorksites: [],
            models: [],
            datePicker: false,
            timePicker: false,
            pickField: "",
            selectType: "",
            value: "",
            repeats: "",
        };
    }

    componentDidMount() {
        if (this.props.editMode && this.props.selectedJob.customer) {
            const { selectedJob, customers, models, worksites } = this.props;

            const searchCustomer = selectedJob.customerLabel;
            const customer = customers.find(customer => customer.id === selectedJob.customer);
            const modelsArr = models.filter(model => (model.type === "model" && !model.isDeleted));

            let customerWorksites = [];
            let selectedModel = {};

            if (selectedJob && selectedJob.templateID) {
                const template = models.find(template => template.id === selectedJob.templateID);

                if (template && !template.isDeleted) {
                    selectedModel = template;

                    models.forEach(model => {
                        if (model.customer === selectedJob.customer) modelsArr.push(model);
                    });

                    this.props.onChange(selectedModel, "selectedModel");
                    this.props.onChange(false, "createTemplate")
                }
            }

            if (selectedJob && selectedJob.changeImpact) {
                this.props.onChange(selectedJob.changeImpact, "recurringRule")
            }

            if (customer) {
                customerWorksites = worksites.filter(site => site.customer === customer.id);
            }

            const repeats = selectedJob.rrule.INTERVAL === 0 ? "noRepeat" : "custom1";


            this.setState({
                searchCustomer,
                customerWorksites,
                repeats,
                models: modelsArr,
            });
        }
        else {
            const modelArr = this.props.models.filter(model => (model.type === "model" && !model.isDeleted));
            this.setState({ models: modelArr })
        }
    }

    getIntervalText = (freq, interval) => {
        const { t } = this.props;
        switch (freq) {
            case "DAILY":
                return interval === 1 ? `${t("Every")} ${t("day")}` : `${t("Every")} ${interval} ${t("days")}`;
            case "WEEKLY":
                if (interval === 0) {
                    return t("Does not repeat")
                }
                else {
                    return interval === 1 ? `${t("Every")} ${t("week")}` : `${t("Every")} ${interval} ${t("weeks")}`;
                }
            case "MONTHLY":
                return interval === 1 ? `${t("Every")} ${t("month")}` : `${t("Every")} ${interval} ${t("months")}`;
            default:
                return "?"
        }
    };

    handleDateChange = (event, field) => {
        const selectedJob = { ...this.props.selectedJob };

        if (field !== "recurringRange") {

            const time = moment(selectedJob[field]).format('HH:mm:ss');

            const date = moment(event.selection.startDate).format('YYYY-MM-DD');

            selectedJob[field] = moment(date + 'T' + time).toDate();

            if (field === "startDT") {
                const endTime = moment(selectedJob.endDT).format('HH:mm:ss');

                selectedJob.endDT = moment(date + 'T' + endTime).toDate();
            }

            this.props.onChange(selectedJob, "selectedJob");
        }
        else {
            this.props.onChange(event, "recurringRange")
        }


        this.setState({
            datePicker: false,
        });
    };

    handleTimeChange = (event, field) => {
        const { selectedJob } = this.props;

        const duration = selectedJob.endDT - selectedJob.startDT;

        const time = moment(event).format('HH:mm:ss');

        const date = moment(selectedJob[field]).format('YYYY-MM-DD');

        selectedJob[field] = moment(date + 'T' + time).toDate();

        if (field === "startDT") {

            const endTime = moment(moment(event) + moment(duration)).format('HH:mm:ss');

            const endDate = moment(selectedJob.endDT).format("YYYY-MM-DD");

            selectedJob.endDT = moment(endDate + 'T' + endTime).toDate();
        }

        console.log(moment(selectedJob.endDT).isBefore(selectedJob.startDT));
        if (moment(selectedJob.endDT).isBefore(selectedJob.startDT)) selectedJob.endDT = moment(selectedJob.endDT).add(1, "day").toDate();

        this.setState({
            timePicker: false,
        });

        this.props.onChange(selectedJob, "selectedJob");
    };

    handleCustomerSelect = customerId => {
        const { customers, worksites } = this.props;
        const selectedJob = { ...this.props.selectedJob };
        let { searchCustomer, selectedModel } = this.state;
        const { models } = this.props;

        let customerWorksites = [];
        const repeats = selectedJob.rrule.INTERVAL === 0 ? "noRepeat" : "custom1";

        console.log("handleCustomerSelect ", customerId)
        //tää menee pieleen jos on sama nimi esim toisessa yritys mukana
        //const customer = customers.find(customer => customer.name === event);
        const customer = customers.find(customer => customer.id === customerId);

        if (customer) {
            // Set fields for selectedJob
            selectedJob.customer = customer.id;
            selectedJob.customerLabel = customer.name //Maybe? + (customer.companyName?'/'+customer.companyName : '') + (customer.customerNumber?' ('+customer.customerNumber+')':'');
            searchCustomer = customer.name + (customer.companyName ? '/' + customer.companyName : '') + (customer.customerNumber ? ' (' + customer.customerNumber + ')' : '');

            customerWorksites = worksites.filter(site => site.customer === customer.id);

            const defaultSite = customerWorksites.find(site => site.isDefault === "true");

            if (defaultSite) {
                selectedJob.worksite = defaultSite.id;
                selectedJob.worksiteName = defaultSite.name;
            }
            else if (customerWorksites.length > 0) {
                selectedJob.worksite = customerWorksites[0].id;
                selectedJob.worksiteName = customerWorksites[0].name;

            } else delete selectedJob.worksite;
        }


        const modelArr = models.filter(model => model.type === "model");

        if (customer) {
            selectedModel = models.find(model => model.customer === customer.id && !model.isDeleted);

            if (selectedModel) {
                this.props.onChange(selectedModel, "selectedModel");
                this.props.onChange(false, "createTemplate");
            }

            models.forEach(model => {
                if (model.customer === customer.id && !model.isDeleted) modelArr.push(model)
            });
        }

        this.props.onChange(selectedJob, "selectedJob");


        this.setState({
            searchCustomer,
            customerWorksites,
            repeats,
            models: modelArr
        });
    };

    repeatHandler = event => {
        const { selectedJob } = this.props;

        if (event.target.value === "noRepeat") {
            if (!selectedJob.rrule) {
                selectedJob.rrule = {
                    FREQ: 'WEEKLY',
                    INTERVAL: 0,
                    UNTIL: moment(selectedJob.endDT).format('YYYYMMDDTHHmmss'),
                }
            }
        }

        if (event.target.value === "daily") {
            selectedJob.rrule = {
                FREQ: "DAILY",
                INTERVAL: 1
            };
            if (selectedJob.rrule.UNTIL) {
                delete selectedJob.rrule.UNTIL
            }

        }
        if (event.target.value === "weekly") {
            selectedJob.rrule = {
                FREQ: "WEEKLY",
                INTERVAL: 1
            };
            if (selectedJob.rrule.UNTIL) {
                delete selectedJob.rrule.UNTIL
            }
        }

        if (event.target.value === "weekly2") {
            selectedJob.rrule = {
                FREQ: "WEEKLY",
                INTERVAL: 2
            };
            if (selectedJob.rrule.UNTIL) {
                delete selectedJob.rrule.UNTIL
            }
        }

        if (event.target.value === "custom") {
            if (!selectedJob.rrule) {
                selectedJob.rrule = {
                    FREQ: "WEEKLY",
                    INTERVAL: 1
                }
            }
        }

        this.props.onChange(selectedJob, "selectedJob");

        this.setState({
            repeats: event.target.value
        });
    };

    recurrenceDialogHandler = event => {
        this.props.onChange(event, "selectedJob");
        this.setState({
            repeats: "custom1"
        });
    };

    modelHandler = event => {
        const { selectedJob } = this.props;
        const selectedModel = this.props.models.find(model => model.name === event.target.value);

        if (selectedModel) {
            selectedJob.templateID = selectedModel.id;
            this.props.onChange(selectedJob, "selectedJob");
            this.props.onChange(selectedModel, "selectedModel");
        }
    };

    worksiteHandler = event => {
        const { selectedJob } = this.props;
        const { customerWorksites } = this.state;

        selectedJob.worksite = event.target.value;
        const worksite = customerWorksites.find(site => site.id === event.target.value)
        //selectedJob.worksiteName = worksite && !worksite.isDefault ? worksite.name : "";
        selectedJob.worksiteName = worksite && worksite.name ? worksite.name : "";
        console.log("worksiteHandler set worksite label to: ", selectedJob.worksiteName)
        this.props.onChange(selectedJob, "selectedJob");
    };

    employeeHandler = event => {
        const { selectedJob } = this.props;
        console.log("employeeHandler prpops empl ", this.props.employees)
        console.log("employeeHandler prpops empl ", event)
        if (!selectedJob.employees) selectedJob.employees = [];

        const employee = selectedJob.employees.find(employee => employee.id === event);

        if (employee) {
            const removeIndex = selectedJob.employees.indexOf(employee);
            selectedJob.employees.splice(removeIndex, 1);
        }
        else {
            const employeeObject = this.props.employees.find(employee => employee.id === event);
            if (employeeObject) {
                const newEmployee = {
                    name: employeeObject.name,
                    id: employeeObject.id
                };
                selectedJob.employees.push(newEmployee);
            } else {
                console.log("employee cannot be found")
            }
        }

        this.props.onChange(selectedJob, "selectedJob");
    };

    taskHandler = (event, action) => {
        const { selectedJob } = this.props;

        if (!selectedJob.tasks) selectedJob.tasks = [];

        const task = selectedJob.tasks.find(task => task.name === event.target.value);

        if (task && action === "remove") {
            const removeIndex = selectedJob.tasks.indexOf(task);
            selectedJob.tasks.splice(removeIndex, 1);
        }
        else {
            const newTask = this.props.selectedModel.tasks.find(task => task.name === event.target.value);
            if (newTask) selectedJob.tasks.push(newTask);
            selectedJob.title ? selectedJob.title = selectedJob.title + ', ' + newTask.name : selectedJob.title = newTask.name;
        }

        this.props.onChange(selectedJob, "selectedJob");
    };

    extraTodoHandler = event => {
        const { todoText } = this.state;
        const { selectedJob } = this.props;

        if (keycode(event) === "enter") {
            selectedJob.extraTodo ? selectedJob.extraTodo.push(todoText) : selectedJob.extraTodo = [todoText];

            this.props.onChange(selectedJob, "selectedJob");
            this.setState({
                todoText: ""
            });
        }

        if (todoText === "" && keycode(event) === "backspace") {
            if (selectedJob.extraTodo) selectedJob.extraTodo.pop();

            this.props.onChange(selectedJob, "selectedJob");
        }
    };

    chipDeleteHandler = item => {
        const { selectedJob } = this.props;

        const list = selectedJob.extraTodo.filter(todo => todo !== item);
        selectedJob.extraTodo = list;

        this.props.onChange(selectedJob, "selectedJob");
    };

    textHandler = (event, field) => {
        const { selectedJob } = this.props;
        selectedJob[field] = event.target.value;
        this.props.onChange(selectedJob, "selectedJob");
    };

    editHandler = () => {
        const { selectedJob, editMode } = this.props;

        if (!editMode) {
            this.props.onSave("add");
        }
        else if (editMode && selectedJob.rrule.INTERVAL === 0) {
            this.props.onSave("straight");
        }
        else {
            this.props.onSave("recurrence");
        }
    };

    render() {
        const { selectedJob, employees, t } = this.props;
        const { user } = this.props.user;

        const selectedEmplCount = selectedJob.employees && selectedJob.employees.length ? selectedJob.employees.length : 0;
        const selectedTasksCount = selectedJob.tasks && selectedJob.tasks.length ? selectedJob.tasks.length : 0;
        const selectedEmployeesAndTasksHeight = 80 + 60 * (selectedEmplCount >= selectedTasksCount ? selectedEmplCount : selectedTasksCount);

        console.log("FullscreenDialog selectedJob ", selectedJob);

        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={() => this.props.onClose()}
                    fullScreen
                >
                    {this.props.editMode && <DialogTitle>{t("Edit job")}</DialogTitle>}
                    {!this.props.editMode && <DialogTitle>{t("Add job")}</DialogTitle>}
                    <DialogContent>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1 }}>

                                <Button onClick={() => this.setState({
                                    pickField: 'startDT',
                                    datePicker: true,
                                    selectType: "single",
                                    value: selectedJob.startDT
                                })}>
                                    {moment(selectedJob.startDT).locale(user.lang ? user.lang : 'fi').format('L')}
                                </Button>

                                <Button style={{ marginRight: 5 }} onClick={() => this.setState({
                                    pickField: 'startDT',
                                    timePicker: true,
                                    value: selectedJob.startDT
                                })}>
                                    {moment(selectedJob.startDT).format('LT')}
                                </Button>
                                -

                                <Button style={{ marginLeft: 5 }} onClick={() => this.setState({
                                    pickField: 'endDT',
                                    datePicker: true,
                                    selectType: "single",
                                    value: selectedJob.endDT
                                })}>
                                    {moment(selectedJob.endDT).locale(user.lang ? user.lang : 'fi').format('L')}
                                </Button>

                                <Button onClick={() => this.setState({
                                    pickField: 'endDT',
                                    timePicker: true,
                                    value: selectedJob.endDT
                                })}>
                                    {moment(selectedJob.endDT).format('LT')}
                                </Button>
                                <br />

                                <AutocompleteCalendar
                                    searchData={this.props.customers}
                                    searchTerm={this.state.searchCustomer}
                                    placeholder={t("Search customers")}
                                    width={481}
                                    onChange={event => this.setState({ searchCustomer: event.target.value })}
                                    onSelect={event => this.handleCustomerSelect(event)}
                                />


                                <br />

                                <TextField
                                    variant="outlined"
                                    label={t("Repeats")}
                                    value={this.state.repeats}
                                    onChange={event => this.repeatHandler(event)}
                                    style={{ width: 230, marginTop: 10 }}
                                    select
                                >
                                    <MenuItem value="noRepeat" key="noRepeat">{t("Does not repeat")}</MenuItem>
                                    <MenuItem value="daily" key="daily">{t("Daily")}</MenuItem>
                                    <MenuItem value="weekly"
                                        key="weekly">{t("Weekly on") + " " + moment(selectedJob.startDT).locale(user.lang ? user.lang : "fi").format('dddd')}</MenuItem>
                                    <MenuItem value="weekly2" key="weekly2">{t("Every two weeks")}</MenuItem>
                                    <MenuItem value="custom" key="custom">{t("Custom")}</MenuItem>
                                    {this.state.repeats === "custom1" && <MenuItem
                                        value="custom1">{this.getIntervalText(selectedJob.rrule.FREQ, selectedJob.rrule.INTERVAL)}</MenuItem>}
                                </TextField>

                                <TextField
                                    variant="outlined"
                                    value={this.props.selectedModel.name || ""}
                                    label={t("Job model")}
                                    onChange={event => this.modelHandler(event)}
                                    style={{
                                        width: 230,
                                        fontSize: 10,
                                        marginLeft: 21,
                                        marginTop: 10
                                    }}
                                    select
                                >
                                    {this.state.models && this.state.models.map(model => (
                                        <MenuItem value={model.name} key={model.id}>{model.name}</MenuItem>
                                    ))}
                                </TextField>


                                <br />

                                <FormControlLabel
                                    control={
                                        <Checkbox color="primary" checked={this.props.createTemplate}
                                            onChange={() => this.props.onChange(!this.props.createTemplate, "createTemplate")}
                                        />}
                                    label={t("Create_template")}
                                    labelPlacement="start"
                                    style={{ marginLeft: 260 }}
                                />

                                <br />

                                <TextField
                                    variant="outlined"
                                    label={t("Worksite")}
                                    style={{
                                        width: 481,
                                        marginTop: 10
                                    }}
                                    select
                                    value={selectedJob.worksite || ""}
                                    onChange={event => this.worksiteHandler(event)}
                                >
                                    {!selectedJob.customer &&
                                        <MenuItem value={"noCustomer"} key={"noCustomer"}>{t("No_customer")}</MenuItem>}
                                    {this.state.customerWorksites && this.state.customerWorksites.map(site => (
                                        <MenuItem value={site.id} key={site.id}>{site.name}</MenuItem>
                                    ))}
                                </TextField>

                                <br />

                                <div style={{ height: 20, backgroundColor: '#ccc', width: 481, marginTop: 50 }}>

                                </div>

                                <div style={{ display: 'flex', width: 481 }}>
                                    <div style={{ flex: 1, height: selectedEmployeesAndTasksHeight, overflow: 'auto' }}>

                                        <AutocompleteCalendar
                                            searchData={this.props.employees}
                                            searchTerm={this.state.searchEmployee}
                                            placeholder={t("Search employees")}
                                            width={230}
                                            onChange={event => this.setState({ searchEmployee: event.target.value })}
                                            onSelect={event => this.employeeHandler(event)}
                                        />


                                        {selectedJob.employees && selectedJob.employees.map(employee => (
                                            <div style={{ display: 'flex', marginTop: 10, marginLeft: 10 }}
                                                key={employee.id}>
                                                <div style={{ flex: 4, marginTop: 10 }}>
                                                    {employee.name}
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <IconButton onClick={() => this.employeeHandler(employee.id)}>
                                                        <Delete />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div style={{ flex: 1, height: selectedEmployeesAndTasksHeight, overflow: 'auto' }}>
                                        <TextField
                                            value={""}
                                            variant="outlined"
                                            label={t("Tasks")}
                                            style={{ width: 230, marginLeft: 10, marginTop: 10 }}
                                            select
                                            onChange={event => this.taskHandler(event, "add")}
                                        >
                                            {!this.props.selectedModel.tasks &&
                                                <MenuItem value={"noValue"}>No model selected</MenuItem>}
                                            {this.props.selectedModel.tasks && this.props.selectedModel.tasks.map(task => (
                                                <MenuItem key={task.name} value={task.name}>{task.name}</MenuItem>
                                            ))}
                                        </TextField>

                                        {selectedJob.tasks && selectedJob.tasks.map(task => (
                                            <div style={{ display: 'flex', marginTop: 10, marginLeft: 25 }}
                                                key={task.name}>
                                                <div style={{ flex: 4, marginTop: 10 }}>
                                                    {task.name}
                                                </div>
                                                <div style={{ flex: 1 }}>
                                                    <IconButton
                                                        onClick={() => this.taskHandler({ target: { value: task.name } }, "remove")}>
                                                        <Delete />
                                                    </IconButton>
                                                </div>

                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <br />

                                {selectedJob.extraTodo &&
                                    <div style={{ flex: 1, height: 45 + 35 * selectedJob.extraTodo.length }}>
                                        <div style={{
                                            height: 25,
                                            backgroundColor: '#ccc',
                                            width: 481,
                                            marginTop: 5,
                                            marginBottom: 5
                                        }}>
                                            <p style={{
                                                fontSize: 12,
                                                margin: 0,
                                                paddingTop: 2,
                                                paddingBottom: 5,
                                                paddingLeft: 5
                                            }}>{t("Extra work")}</p>
                                        </div>
                                        {selectedJob.extraTodo.map(item => (
                                            <div key={item} style={{ flex: 1, height: 32, overflow: 'auto' }}>
                                                <Chip
                                                    key={item}
                                                    label={item}
                                                    onDelete={() => this.chipDeleteHandler(item)}
                                                />
                                            </div>
                                        ))
                                        }
                                    </div>
                                }

                                <TextField
                                    variant="outlined"
                                    label={t("Extra work tip")}
                                    style={{
                                        width: 481,
                                        marginTop: 20,
                                    }}
                                    value={this.state.todoText || ""}
                                    onChange={event => this.setState({ todoText: event.target.value })}
                                    InputProps={{
                                        onKeyDown: this.extraTodoHandler
                                    }}
                                />

                                {/*
                                <TextField
                                    variant="outlined"
                                    label={t("Extra work")}
                                    style={{
                                        width: 481,
                                        marginTop: 20,
                                    }}
                                    value={this.state.todoText || ""}
                                    onChange={event => this.setState({todoText: event.target.value})}
                                    InputProps={{
                                        startAdornment: this.props.selectedJob.extraTodo && this.props.selectedJob.extraTodo.map(item => (
                                            <Chip
                                                key={item}
                                                label={item}
                                                onDelete={() => this.chipDeleteHandler(item)}
                                            />
                                        )),
                                        onKeyDown: this.extraTodoHandler
                                    }}
                                />
                                */
                                }


                                <br />

                                <TextField
                                    variant="outlined"
                                    label={t("Title")}
                                    style={{
                                        width: 481,
                                        marginTop: 20
                                    }}
                                    value={selectedJob.title || ""}
                                    onChange={event => this.textHandler(event, 'title')}
                                />

                                <br />

                                <TextField
                                    variant="outlined"
                                    label={t("Additional info")}
                                    style={{
                                        width: 481,
                                        marginTop: 20
                                    }}
                                    value={selectedJob.additionalInfo || ""}
                                    onChange={event => this.textHandler(event, 'additionalInfo')}
                                />

                                <br />

                                {this.props.editMode && selectedJob.rrule && selectedJob.rrule.INTERVAL > 0 &&
                                    <div style={{ marginTop: 10, marginLeft: 20 }}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.props.recurringRule === "once"}
                                                    onChange={() => this.props.onChange("once", "recurringRule")}
                                                    value="once"
                                                    color="primary"
                                                />
                                            }
                                            label={t("One time")}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.props.recurringRule === "range"}
                                                    onChange={() => this.props.onChange("range", "recurringRule")}
                                                    value="range"
                                                    color="primary"
                                                />
                                            }
                                            label={t("Range")}
                                        />

                                        {/* show notide if incoming changes coming but not passed */}
                                        {selectedJob?.inComingChanges && selectedJob.inComingChanges.length > 0 &&
                                            selectedJob.inComingChanges.some(change => moment.tz(change.replaceDT ,selectedJob.TZ).isAfter(moment.tz(selectedJob.startDT,selectedJob.TZ)))  &&
                                            <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("Notice_changes_in_future")}</p>
                                        }

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.props.recurringRule === "onward"}
                                                    onChange={() => this.props.onChange("onward", "recurringRule")}
                                                    value="onward"
                                                    color="primary"
                                                />
                                            }
                                            label={selectedJob?.inComingChanges && selectedJob.inComingChanges.length > 0 && 
                                                selectedJob.inComingChanges.some(change => moment.tz(change.replaceDT ,selectedJob.TZ).isAfter(moment.tz(selectedJob.startDT,selectedJob.TZ))) ?
                                                t("This and onward preserve incoming") : t("This and onward")}
                                        />

                                        {selectedJob?.inComingChanges && selectedJob.inComingChanges.length > 0 &&
                                            selectedJob.inComingChanges.some(change => moment.tz(change.replaceDT ,selectedJob.TZ).isAfter(moment.tz(selectedJob.startDT,selectedJob.TZ)))  &&
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.props.recurringRule === "onward_override"}
                                                    onChange={() => this.props.onChange("onward_override", "recurringRule")}
                                                    value="onward_override"
                                                    color="primary"
                                                />
                                            }
                                            label={t("This and onward override")}
                                        />
                                        }  

                                        {selectedJob && selectedJob.disableDT &&
                                            <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("Notice_disabled_future_pre")} 
                                                {moment(selectedJob.disableDT).format("L")} {t("Notice_disabled_future_post") }  
                                            </p>
                                        }

                                        {this.props.recurringRule === "range" && <div>
                                            <Button onClick={() => this.setState({
                                                pickField: "recurringRange",
                                                selectType: "range",
                                                datePicker: true,
                                                value: this.state.rrStart
                                            })}>
                                                {this.props.rrStart === null && this.props.rrEnd === null && t("Select")}
                                                {this.props.rrStart !== null && this.props.rrEnd !== null && moment(this.props.rrStart).locale('fi').format('L') + ' - ' + moment(this.props.rrEnd).locale('fi').format('L')}
                                            </Button>
                                        </div>}

                                    </div>}

                                <br />

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                        marginTop: 20
                                    }}
                                    onClick={() => this.props.onClose()}
                                >
                                    {t("Close")}
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                        marginTop: 20,
                                        marginLeft: 20
                                    }}
                                    onClick={() => this.editHandler()}
                                >
                                    {t("Save")}
                                </Button>

                            </div>


                            <div style={{ flex: 1 }}>
                                { /* must be started exact time  */
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={selectedJob.isOntime}
                                                onChange={() => {
                                                    selectedJob.isOntime = !selectedJob.isOntime;
                                                    this.props.onChange(selectedJob, "selectedJob");
                                                }}
                                            />}
                                        label={t('On time start')}
                                        labelPlacement="end"
                                        style={{ alignItems: 'flex-start', width: 250 }}
                                    />
                                }

                                <br />

                                { /* must be done exact time  */
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={selectedJob.endOntime}
                                                onChange={() => {
                                                    selectedJob.endOntime = !selectedJob.endOntime;
                                                    this.props.onChange(selectedJob, "selectedJob");
                                                }}
                                            />}
                                        label={t('End ontime')}
                                        labelPlacement="end"
                                        style={{ alignItems: 'flex-start', width: 250 }}
                                    />
                                }

                                {
                                    /*
                                     * other options
                                     * done before - time
                                     * start after - time
                                     *  -> vapaa toteutus esim 8-16 välillä TAI pitää olle tehty ennen klo 10 TAI voi aloittaa vasta 18 jälkeen
                                     */
                                }


                                <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("max_employees")}</p>
                                <TextField
                                    value={selectedJob.maxEmp}
                                    onChange={event => this.textHandler(event, "maxEmp")}
                                    variant="outlined"
                                    style={{
                                        width: 250,
                                    }}
                                    inputProps={{
                                        style: { textAlign: 'center' }
                                    }}
                                />

                            </div>

                            {
                                <div>
                                    {selectedJob.createdBy &&
                                        <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("CreatedBy")} {selectedJob.createdBy && employees ? employees.find(empl => empl.id === selectedJob.createdBy)?.name : ""} </p>
                                    }
                                    {selectedJob.modifiedBy &&
                                        <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("ModifiedBy")} {selectedJob.modifiedBy && employees ? employees.find(empl => empl.id === selectedJob.modifiedBy)?.name : ""} </p>
                                    }
                                    {selectedJob.changeImpact === "once" &&
                                        <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("Once impact")} </p>
                                    }

                                    {selectedJob.changeImpact === "range" &&
                                        <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("Range impact")} </p>
                                    }

                                    {selectedJob.rangeInfo && selectedJob.rangeInfo.rangeStart && selectedJob.rangeInfo.rangeEnd &&
                                        <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>
                                            {moment(selectedJob.rangeInfo.rangeStart).format("L")} - {moment(selectedJob.rangeInfo.rangeEnd).format("L")}
                                        </p>
                                    }

                                    {selectedJob.changeImpact === "onward" &&
                                        <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("Onwards impact")} </p>
                                    }

                                    {selectedJob.createInfo && selectedJob.createInfo.reason === "onward" && selectedJob.createInfo.startDT &&
                                        <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("Onwards impact")} {moment(selectedJob.createInfo.startDT).format("L")} {t("Onwards impact after text")}</p>

                                    }

                                </div>
                            }

                            <div style={{ flex: 1 }}>

                            </div>
                        </div>
                    </DialogContent>
                </Dialog>

                {this.state.datePicker &&
                    <DateDialog
                        open={this.state.datePicker}
                        selectType={this.state.selectType}
                        value={this.state.value}
                        onClose={() => this.setState({ dateDialog: false })}
                        onChange={event => this.handleDateChange(event, this.state.pickField)}
                    />
                }

                {this.state.timePicker &&
                    <TimePicker
                        onChange={event => this.handleTimeChange(event, this.state.pickField)}
                        onClose={() => this.setState({ timePicker: false })}
                        value={this.state.value}
                    />
                }

                {this.state.repeats === "custom" &&
                    <RecurrenceDialog
                        selectedJob={this.props.selectedJob}
                        onChange={event => this.recurrenceDialogHandler(event)}
                        onClose={() => this.setState({ repeats: "noRepeat" })}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(FullscreenDialog));
