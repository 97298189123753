import React from 'react';

import moment from 'moment';
import "moment/locale/fi";

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';

import {withTranslation} from "react-i18next";

const SearchListComponent = props => {
    const {t} = props;

    const getBillableTime = hours => {

        let time = hours / 3600000;

        if (time >= 3 && time < 3.1) {
            time = 3
        }

        return time;
    };

    //console.log("SearchListComponent ", props.searchArray)

    return (
        <Table style={{width: '100%'}}>
            <TableHead>
                <TableRow>
                    <TableCell>{t("Employee")}</TableCell>
                    <TableCell>{t("Customer")}</TableCell>
                    <TableCell>{t("Worksite")}</TableCell>
                    <TableCell>{t("Date")}</TableCell>
                 
                </TableRow>
            </TableHead>
            <TableBody>
                {props.searchArray && props.searchArray.map((jobOccurrence, index) => (
                    <TableRow key={jobOccurrence.id+'-'+index}>
                        <TableCell>{props.getEmployeeNameString(jobOccurrence.employees)}</TableCell>
                        <TableCell>{props.findCustomer(jobOccurrence.customer)}</TableCell>
                        <TableCell>{props.getWorksite(jobOccurrence.worksite)}</TableCell>
                        <TableCell>{moment(jobOccurrence.start).locale("fi").format("L")}</TableCell>
                    
                    
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default withTranslation()(SearchListComponent);
