import React, { Component } from 'react';

import {
    Collapse
} from "@material-ui/core";

import {
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@material-ui/icons';

import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";

import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { isEmpty } from '../../validations/isEmpty';
import { durationToHHmm } from '../../util/TimeUtils';


class WorkdaysListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchObj: {},
            searchKeys: [],
            showMore: null,
            showMoreWeek: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prev = Object.keys(prevProps.searchObj);
        const next = Object.keys(this.props.searchObj);

        if (prev.length !== next.length) {
            this.setState({
                searchObj: this.props.searchObj,
                searchKeys: next
            });
        }
    }

    roundTimes = d => {
        return (Math.ceil(d * 2) / 2).toFixed(2);
    };


    handleCollapseMenu = key => {
        let { showMore } = this.state;

        if (key === showMore) {
            showMore = null;
        }
        else {
            showMore = key
        }

        this.setState({
            showMore
        })
    };

    handleCollapseWeeklyMenu = week => {
        const { showMoreWeek } = this.state;

        if (!showMoreWeek.includes(week)) showMoreWeek.push(week);
        else {
            const removeIndex = showMoreWeek.indexOf(week);
            showMoreWeek.splice(removeIndex, 1);
        }
        this.setState({ showMoreWeek });
    };

    render() {
        const { user } = this.props.user;
        const { searchObj, t } = this.props;
        const searchKeys = Object.keys(searchObj);

        searchKeys && searchKeys.map((key, index) => (
            console.log("searchObj[key][normalByWeek]", key, searchObj[key]["normalByWeek"])
        ))

        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomColor: '#ccc', borderBottomStyle: 'solid' }}>
                    <p style={{ fontSize: 14, color: '#7d888d', width: '15%', marginLeft: 20 }}>{t('Name')}</p>
                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Total time')}</p>
                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Normal time')}</p>
                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Evening time')}</p>
                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Night time')}</p>
                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Saturday')}</p>
                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Sunday')}</p>
                    {/* workdayRows.push([{ text: t('Date') }, { text: t('DayStartTime') }, { text: t('DayEndTime') }, { text: t('Total time') }, { text: t('Pauses') }, { text: t('Normal time') }, { text: t('Evening time') }, { text: t('Night time') }, { text: t('Saturday short') }, { text: t('Sunday short') }]);
*/}
                </div>
                {searchKeys && searchKeys.map((key, index) => (
                    <div key={index} style={{ borderBottom: "0.5px solid #ccc" }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
                            <p style={{ fontSize: 14, width: '15%', marginLeft: 20 }} onClick={() => this.handleCollapseMenu(key)}>{this.props.getEmployee(key)}</p>
                            <p style={{ fontSize: 14, width: '10%' }}>{durationToHHmm(searchObj[key].total)}</p>
                            <p style={{ fontSize: 14, width: '10%' }}>{durationToHHmm(searchObj[key].normal)}</p>
                            <p style={{ fontSize: 14, width: '10%' }}>{durationToHHmm(searchObj[key].evenings)}</p>
                            <p style={{ fontSize: 14, width: '10%' }}>{durationToHHmm(searchObj[key].nights)}</p>
                            <p style={{ fontSize: 14, width: '10%' }}>{durationToHHmm(searchObj[key].saturdays)}</p>
                            <p style={{ fontSize: 14, width: '10%' }}>{durationToHHmm(searchObj[key].sundays)}</p>


                            {this.state.showMore === key && <KeyboardArrowDown style={{ paddingTop: 10 }} />}
                            {this.state.showMore !== key && <KeyboardArrowUp />}
                        </div>

                        <Collapse in={this.state.showMore === key}>
                            <div style={{ width: '100%', marginLeft: 40, marginTop: 10 }}>
                                {Object.keys(searchObj[key]["normalByWeek"]).map(weekNumber => (
                                    <div style={{ borderBottom: "0.5px solid #1770B2", marginTop: 10 }} key={key + weekNumber} onClick={() => this.handleCollapseWeeklyMenu(weekNumber)}>
                                        <div style={{ display: "flex", }}>
                                            <p style={{ margin: 0, padding: 0 }}>{this.props.t("Week") + " " + weekNumber}: </p>
                                            <p style={{ margin: 0, padding: 0, marginLeft: 10, marginRight: 20 }}>{durationToHHmm(searchObj[key]["normalByWeek"][weekNumber].total)}</p>
                                            {this.state.showMoreWeek.includes(weekNumber) && <KeyboardArrowDown />}
                                            {!this.state.showMoreWeek.includes(weekNumber) && <KeyboardArrowUp />}
                                        </div>
                                        <br />

                                        <Collapse in={this.state.showMoreWeek.includes(weekNumber)}>
                                            <div key={key + weekNumber} style={{ marginLeft: 80, fontSize: 14 }}>
                                                <div style={{ display: 'flex', justifyContent: 'flex-start' }} >
                                                    <p style={{ fontSize: 14, color: '#7d888d', width: '15%' }}>{t('Date')}</p>
                                                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('DayTimeSpan')}</p>
                                                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Total time')}</p>
                                                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Pauses')}</p>
                                                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Normal time')}</p>
                                                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Evening time')}</p>
                                                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Night time')}</p>
                                                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Saturday')}</p>
                                                    <p style={{ fontSize: 14, color: '#7d888d', width: '10%' }}>{t('Sunday')}</p>
                                                </div>

                                                {searchObj[key]["normalByWeek"][weekNumber]?.workdays?.map((workday, index) => (
                                                    <div style={{ display: "flex", marginBottom: 10 }} key={key + workday.date}>
                                                        <p style={{ margin: 0, padding: 0, width: '15%' }}>{moment(workday.date).locale(user.lang ? user.lang : "fi").format("L")}</p>
                                                        {/*<p style={{margin:0, padding: 0, marginLeft: 10}}>{" - " + this.props.getEmployee(workday.employee) }</p> */}
                                                        <p style={{ margin: 0, padding: 0, marginLeft: 0, width: '10%' }}>{`${!isEmpty(workday.startDT) ? moment(workday.startDT).format('LT') : "__"}-${!isEmpty(workday.endDT) ? moment(workday.endDT).format("LT") : "__"}`}</p>
                                                        <p style={{ margin: 0, padding: 0, marginLeft: 0, width: '10%' }}>{durationToHHmm(workday.duration)}</p>
                                                        <p style={{ margin: 0, padding: 0, marginLeft: 0, width: '10%' }}>{durationToHHmm(workday.pauses)}</p>
                                                        <p style={{ margin: 0, padding: 0, marginLeft: 0, width: '10%' }}>{durationToHHmm(workday.normal)}</p>
                                                        <p style={{ margin: 0, padding: 0, marginLeft: 0, width: '10%' }}>{durationToHHmm(workday.evening)}</p>
                                                        <p style={{ margin: 0, padding: 0, marginLeft: 0, width: '10%' }}>{durationToHHmm(workday.night)}</p>
                                                        <p style={{ margin: 0, padding: 0, marginLeft: 0, width: '10%' }}>{durationToHHmm(workday.saturday)}</p>
                                                        <p style={{ margin: 0, padding: 0, marginLeft: 10, width: '10%' }}>{durationToHHmm(workday.sunday)}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </Collapse>
                                    </div>
                                ))}

                            </div>
                        </Collapse>
                    </div>



                ))}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(WorkdaysListComponent));