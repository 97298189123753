import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';

import GlobalConfirmationDialog from "../CommonDialogs/GlobalConfirmationDialog";
import Autocomplete from '../util/Autocomplete'
import Tutorial from './Tutorial';

import {
    Paper,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    IconButton,
} from '@material-ui/core';

import {
    Delete,
    PlaylistAdd
} from '@material-ui/icons';

import TodoDialog from './TodoDialog';
import {withTranslation} from "react-i18next";

import "./JobModelEditor.css";
import keycode from 'keycode';
import {validateJobModel, validateJobModelProductArray} from "../validations/jobmodel";
import Validator from "validator";

class JobModelEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productArray: [],
            products: [],
            loading: true,
            model: {},
            id: null,
            todoDialog: false,
            selectedRow: "",
            selectedTask: {},
            selectedTasks: [],
            tutorialFields: [],
            tutorial: false,
            gotFields: false,
            confirmationDialog: false,
        };
    }

    componentDidMount() {
        const {user} = this.props.user;
        const {match: {params}} = this.props;

        if (params.modelId) {
            const id = params.modelId;
            this.unsubscribeModelListener =
                firebase.firestore().collection('company').doc(user.companyID).collection("jobModels").doc(id).onSnapshot(this.onModelSnapshot);
        }
        else {
            const productArray = [];
            for (let i = 0; i < 10; i++) {
                let row = {
                    rowID: i
                };
                productArray.push(row);
            }
            this.setState({
                productArray: productArray
            });
        }

        this.unsubscribeProductListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('products').onSnapshot(this.onProductSnapshot);


    }

    onModelSnapshot = docSnapshot => {

        let model = docSnapshot.data();

        const productArray = [];
        let id = 0;

        if (model.tasks) {
            model.tasks.forEach(task => {
                task.rowID = id;
                if (task.task) {
                    task.name = task.task;
                    delete task.task;
                }
                productArray.push(task);
                id++;
            });
        }

        this.setState({
            id: docSnapshot.id,
            model: model,
            productArray: productArray,
        });
    };

    onProductSnapshot = querySnapshot => {
        let products = [];

        querySnapshot.forEach(doc => {
            let product = doc.data();
            product.id = doc.id;

            products.push(product);
        });

        this.setState({
            products: products,
            loading: false
        });
    };

    componentWillUnmount() {
        this.unsubscribeModelListener && this.unsubscribeModelListener();
        this.unsubscribeProductListener && this.unsubscribeProductListener();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {user} = this.props.user;
        const firstRow = {
            name: document.getElementById("name-0"),
            pNumber: document.getElementById("pNumber-0"),
            invoice: document.getElementById("invoice-0"),
            description: document.getElementById("desc-0"),
            unit: document.getElementById("unit-0"),
            dialog: document.getElementById("dialog-0"),
        };


        if (firstRow.name && !this.state.tutorial && !this.state.gotFields) {
            const fieldArr = ["name", "pNumber", "invoice", "description", "unit", "dialog"];
            let tutorial = false;

            // Show tutorial automatically if user hasn't seen it yet
            if (!user.tutorials) tutorial = true;
            else if (user.tutorials && !user.tutorials.jobModels) tutorial = true;

            fieldArr.forEach(field => {
                firstRow[field] = firstRow[field].getBoundingClientRect();
            });

            this.setState({
                tutorial,
                gotFields: true,
                tutorialFields: firstRow
            });
        }
    }

    addRow = () => {
        const {productArray} = this.state;

        let id = 0;

        productArray.forEach(product => {
            product.rowID = id;
            id++
        });

        const newRow = {
            rowID: productArray.length
        };

        productArray.push(newRow);

        this.setState({
            productArray: productArray
        });
    };

    openAddTodo = rowID => {
        const {productArray} = this.state;

        const task = productArray[rowID];

        this.setState({
            selectedTask: task,
            todoDialog: true
        });
    };

    saveExtraOptions = (task) => {
        const {productArray} = this.state;

        productArray[task.rowID] = task;

        this.setState({
            todoDialog: false,
            productArray: productArray
        });
    };

    deleteRow = (rowID) => {
        const {productArray} = this.state;

        const row = productArray[rowID];

        const index = productArray.indexOf(row);
        if (index > -1) {
            productArray.splice(index, 1)
        }

        let id = 0;

        productArray.forEach(product => {
            product.rowID = id;
            id++;
        });

        this.setState({
            productArray: productArray
        });
    };

    changeHandler = (event, index, field) => {
        const {productArray} = this.state;

        productArray[index][field] = event.target.value;
        this.setState({
            productArray: productArray
        });
    };

    mainFieldHandler = (event, field) => {
        const {model} = this.state;

        model[field] = event.target.value;

        this.setState({
            model: model
        });
    };

    onAutocompleteSelect = (product, rowID) => {
        const {productArray} = this.state;
        const {user} = this.props.user;

        console.log("product", product);
        console.log("rowID", rowID);
        productArray[rowID] = {
            rowID: rowID,
            name: product.name,
            pNumber: product.pNumber,
            unit: product.unit ? product.unit : (user.lang === "fi" ? "kpl" : "pcs"),
            invoiceText: product.invoiceText ? product.invoiceText : "",
            description: product.description ? product.description : "",
        };

        product.todo ? productArray[rowID].todo = product.todo : delete productArray[rowID].todo;


        this.setState({
            productArray: productArray
        });

    };

    fieldType = (id, index) => {
        const {productArray, products} = this.state;

        if (productArray[id] && productArray[id].pNumber) {
            return (
                <TextField
                    variant="outlined"
                    value={productArray[id].name || ""}
                    onChange={event => this.changeHandler(event, id, "name")}
                    InputProps={{
                        disableunderline: "true"
                    }}
                    fullWidth
                    //error={Validator.isEmpty(productArray[id].name)}
                    error={typeof productArray[id].name == "undefined" || (typeof productArray[id].name != "undefined" && Validator.isEmpty(productArray[id].name))}
                />
            );
        }

        if (productArray[id] && !productArray[id].pNumber) {

            return (
                <Autocomplete
                    style={{width: "100%", borderColor: 'red', borderWidth: 1}}
                    searchArray={products}
                    searchValues={["pNumber", "name"]}
                    onSelect={event => this.onAutocompleteSelect(event, id)}
                    listMaxLength={5}
                />
            );
        }
    };

    onKeyDown = (event, field, id) => {
        const {productArray, products} = this.state;

        if (field === "pNumber" && keycode(event) === "enter") {
            products.forEach(product => {
                console.log("pNumber: " + parseInt(product.pNumber), " productArray " + parseInt(productArray[id].pNumber));
                if (parseInt(product.pNumber) === parseInt(productArray[id].pNumber)) {
                    let newProduct = {
                        rowID: id,
                        name: product.name,
                        pNumber: product.pNumber,
                        invoiceText: product.invoiceText ? product.invoiceText : "",
                        description: product.description ? product.description : "",
                        unit: product.unit ? product.unit : ""
                    };
                    product.todo ? newProduct.todo = product.todo : delete newProduct.todo;
                    productArray[id] = newProduct;
                }
            });
            this.setState({
                productArray: productArray
            });
        }
    };


    textField = (id, field) => {
        const {productArray} = this.state;

        const {errors, isValid} = validateJobModelProductArray(productArray, id, field);

        return <TextField
            variant={"outlined"}
            value={productArray[id][field] || ""}
            onChange={event => this.changeHandler(event, id, field)}
            InputProps={{
                disableUnderline: true,
                onKeyDown: event => this.onKeyDown(event, field, id)
            }}
            fullWidth
            error={!isValid}
        />
    };

    deleteHandler = () => {
        const {user} = this.props.user;
        console.log(" delete model " , id)

        firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').doc(this.state.id).delete();
        console.log("  model deleted " , id)
        this.props.history.goBack();
    };

    /*
    deleteHandler = () => {
        const {model} = this.state;
        const {user} = this.props.user;

        model.isDeleted = true;

        firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').doc(this.state.id).set(model)
            .then(() => {
                this.props.history.goBack();
            });
    };*/


    saveHandler = () => {
        const {model, productArray, id} = this.state;
        const {user} = this.props.user;

        const tasks = [];

        productArray.forEach(product => {
            if (product.pNumber) {
                delete product.rowID;
                tasks.push(product)
            }
        });

        model.tasks = tasks;
        model.type = "model";


        if (id !== null) {
            firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').doc(id).set(model)
        }
        else {
            firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').add(model);
        }

        this.props.history.goBack()
    };


    render() {
        const {t} = this.props;
        const {productArray, model} = this.state;

        const {errors, isValid} = validateJobModel(model, productArray);

        console.log("errors ", errors, isValid);

        return (
            <div style={{width: '100%'}}>
                {!this.state.loading && <Paper style={{width: '98%', margin: 20, marginRight: 30, paddingBottom: 20}}>
                    <div style={{display: 'flex'}}>
                        <div style={{width: '24%', display: 'flex', flexDirection: 'row', marginTop: 20}}>
                            <TextField
                                variant="outlined"
                                label={t("Model name")}
                                style={{width: '100%', marginLeft: 10}}
                                value={model.name || ""}
                                onChange={event => this.mainFieldHandler(event, 'name')}
                                error={!!errors.name}
                            />
                        </div>

                        <div style={{width: '24%', display: 'flex', marginTop: 20}}>
                            <TextField
                                variant="outlined"
                                label={t("Notes")}
                                style={{width: '100%', marginLeft: 10}}
                                value={model.notes || ""}
                                onChange={event => this.mainFieldHandler(event, 'notes')}
                            />
                        </div>

                        <div style={{flexGrow: 1, display: 'flex', justifyContent: 'flex-end'}}>
                            <IconButton style={{height: 50, width: 50}} onClick={() => this.setState({tutorial: true})}>
                                ?
                            </IconButton>
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => this.addRow()}
                                style={{
                                    marginTop: 45,
                                    marginLeft: 10
                                }}>
                                {t("Add row")}
                            </Button>
                        </div>
                        <div style={{display: 'flex', flexGrow: 1, justifyContent: 'flex-end', marginTop: 40}}>
                            {this.state.id !== null && <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#b22717',
                                    color: '#FFF',
                                    marginRight: 20,
                                    height: 40
                                }}
                                onClick={() => this.setState({confirmationDialog: true})}
                            >
                                {t("Delete model")}
                            </Button>}

                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#1770B2',
                                    color: '#FFF',
                                    marginRight: 20,
                                    height: 40
                                }}
                                onClick={() => this.props.history.goBack()}
                            >
                                {t("Cancel")}
                            </Button>

                            <Button
                                variant="contained"
                                disabled={!isValid}
                                style={{
                                    backgroundColor: !isValid ? '#909497' : '#1770B2',
                                    color: '#FFF',
                                    marginRight: 20,
                                    height: 40
                                }}
                                onClick={() => this.saveHandler()}

                            >
                                {t("Save")}
                            </Button>
                        </div>
                    </div>


                    <div style={{margin: 10, width: '99%'}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={"table-cell-name"}>{t("Name")}</TableCell>
                                    <TableCell className={"table-cell-pNumber"}>{t("Product number")}</TableCell>
                                    <TableCell className={"table-cell-invoice"}>{t("Invoice text")}</TableCell>
                                    <TableCell className={"table-cell-desc"}>{t("Description")}</TableCell>
                                    <TableCell className={"table-cell-unit"}>{t("Unit")}</TableCell>
                                    <TableCell className={"table-cell-icon"}>{t("Add")}</TableCell>
                                    <TableCell className={"table-cell-icon"}>{""}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productArray && productArray.map((product, index) => (
                                    <TableRow key={product.rowID}>
                                        <TableCell className={"table-cell-name"}
                                                   id={"name-" + index}>{this.fieldType(product.rowID, index)}</TableCell>
                                        <TableCell align="center" className={"table-cell-pNumber"}
                                                   id={"pNumber-" + index}>{this.textField(product.rowID, "pNumber")}</TableCell>
                                        <TableCell className={"table-cell-invoice"}
                                                   id={"invoice-" + index}>{this.textField(product.rowID, 'invoiceText')}</TableCell>
                                        <TableCell className={"table-cell-desc"}
                                                   id={"desc-" + index}>{this.textField(product.rowID, 'description')}</TableCell>
                                        <TableCell className={"table-cell-unit"}
                                                   id={"unit-" + index}>{this.textField(product.rowID, 'unit')}</TableCell>
                                        <TableCell className={"table-cell-icon"} align="center" id={"dialog-" + index}>
                                            <IconButton onClick={() => this.openAddTodo(product.rowID)}
                                                        disabled={!product.name}>
                                                <PlaylistAdd/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className={"table-cell-icon"} align="center">
                                            <IconButton onClick={() => this.deleteRow(product.rowID)}>
                                                <Delete/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                    </div>
                    <div style={{height: 80}}/>
                </Paper>}
                {this.state.todoDialog &&
                <TodoDialog
                    onClose={() => this.setState({todoDialog: false})}
                    onSave={this.saveExtraOptions}
                    selectedTask={this.state.selectedTask}
                    products={this.state.products}

                />
                }
                {this.state.tutorial &&
                <Tutorial
                    tutorialFields={this.state.tutorialFields}
                    onClose={() => this.setState({tutorial: false})}
                />
                }

                <GlobalConfirmationDialog
                    open={this.state.confirmationDialog}
                    onClose={() => this.setState({confirmationDialog: false})}
                    onConfirm={() => this.setState({confirmationDialog: false}, () => this.deleteHandler())}
                    title={t("Attention")}
                    message={`${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DeleteActionJobModel")}?`}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(JobModelEditor));
