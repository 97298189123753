import React from 'react';

import {
    List,
    ListItem,
    ListItemText
} from '@material-ui/core';

import {withTranslation} from "react-i18next";

const LeftNavigation = props => {
    const {t} = props;

    let settings = [];

    props.user?.settings?.permissions?.mainUser ? settings = [
        "Common",
        "Job",
        "Reports",
        "Billing",
        "Company",
        "Modules",
    ] : settings = ["Common"];



    return (
        <div>
            <p style={{marginLeft:10, fontSize: 20, fontWeight: 'bold'}}>{t("Category")}</p>
            <List>
                {settings.map(item => (
                    <ListItem
                        key={item}
                        button
                        onClick={() => props.onChange(item)}
                        style={{
                            backgroundColor: props.activeSetting === item ? "#1770B2" : "#FFF",
                        }}
                    >
                        <ListItemText
                            primary={t(item)}
                            disableTypography
                            style={{
                                color: props.activeSetting === item ? "#FFF" : "#000"
                            }}

                        />
                    </ListItem>
                ))}
            </List>
        </div>
    )
};

export default withTranslation()(LeftNavigation);