import React, { Component } from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { Button, Checkbox, FormControlLabel } from '@material-ui/core';

import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";

import { connect } from 'react-redux';

import { saveSend } from "../../actions/sendReportActions";

import { withTranslation } from "react-i18next";
import { changeToDot } from "../../BillingComponents/BillDetailsComponents/BillNumberHelpers";
import { createReport, uploadToFirebaseStorage } from "../reportStoreFunctions";
import { isEmpty } from "../../validations/isEmpty";
import ReportTypes from "../ReportTypes";

class PDFCompanyBillSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docRef: {},
            looping: false
        };
    }

    roundNumber = d => {
        return parseFloat(d).toFixed(2)
    };

    isProductMaterial = (productNumber) => {
        const { products } = this.props.products;

        const product = products.find(product => product.pNumber === productNumber)

        return product && product.isMaterial && product.isMaterial === "true" ? true : false;

    }

    countMaterialFees = (bill) => {
        let materialFees = 0.0;

        bill.billObj.rows.forEach(row => {
            if (this.isProductMaterial(row.pNumber)) {
                const materialFee = (parseFloat(row.price) * parseFloat(row.quantity) * (1 + parseFloat(row.alv))) - row.discount;
                console.log("countMaterialFees: row material fee ", materialFee, row.name);
                materialFees += materialFee;

            }
        })

        return materialFees;
    }


    createPdf = bills => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { t, company, dateRange} = this.props;
        const { docRef } = this.state;
        const ref = {};
        const { user } = this.props.user;
        const body = [];

        console.log("createPdf this.props ", this.props);

        let reportSummary = {
            totalAmount: 0,
            totalVATAmount: 0,
            totalAmountWithoutVAT: 0,
            totalBillingFees: 0,
            totalBillingFeesVAT: 0,
            totalLaborFees: 0,
            totalMaterialFees: 0,
        };

        /*
        const sortedWorklogs = worklogs.sort((a, b) => {
            if (moment(a.startDT).isBefore(b.startDT)) return -1;
            if (moment(a.startDT).isAfter(b.startDT)) return 1;
        });
        */

        const reportRows = [];

        bills.forEach(bill => {

            if (bill.status !== "Cancelled") {
                const vatRate = bill.billObj.billingFees.vatRate !== undefined ? parseFloat(bill.billObj.billingFees.vatRate) : 0.255;

                // const billingFees = (bill.billObj.billingFees.billing ? parseFloat(changeToDot(bill.billObj.billingFees.billing)) : 0) + (bill.billObj.billingFees.freight ? parseFloat(changeToDot(bill.billObj.billingFees.freight)) : 0 );
                // const billingFeesVat = (bill.billObj.billingFees.billing ? parseFloat(changeToDot(bill.billObj.billingFees.billing)) * 0.24 : 0) + (bill.billObj.billingFees.freight ? parseFloat(changeToDot(bill.billObj.billingFees.freight)) * 0.24 : 0); //ADD VAT
                // Calculate billing fees without VAT
                const billing = bill.billObj.billingFees.billing ? parseFloat(changeToDot(bill.billObj.billingFees.billing)) : 0;
                const freight = bill.billObj.billingFees.freight ? parseFloat(changeToDot(bill.billObj.billingFees.freight)) : 0;
                const billingFees = billing + freight;

                // Calculate billing fees VAT dynamically using the VAT rate
                const billingFeesVat = billingFees * vatRate;

                console.log("createPdf billingFees ", billingFees);
                let fullPrice = bill.type === "compensation" ? 0 - bill.billObj.invoice.fullPrice : bill.billObj.invoice.fullPrice;
                let price = bill.type === "compensation" ? 0 - bill.billObj.invoice.price : bill.billObj.invoice.price;
                let vat = bill.type === "compensation" ? 0 - (bill.billObj.invoice.vat+billingFeesVat) :(bill.billObj.invoice.vat+billingFeesVat);
                
                //fullPrice += billingFees; //seems theses are not in fullprice?! :o

                reportSummary.totalAmount += fullPrice;
                reportSummary.totalBillingFees += billingFees;
                reportSummary.totalAmountWithoutVAT += price;
                reportSummary.totalVATAmount += vat;
                reportSummary.totalBillingFeesVAT += billingFeesVat;

                console.log("createPdf bill ", fullPrice, bill);

                // [t('Bill number'), t('Bill date'), t('Due date'), t('Bill amount'), t('Bill work charge amount'), t('Materials and billing fees amount')],
                const row1 = [
                    { text: bill.billNumber },
                    { text: bill.billObj?.customer?.contact?.name },
                    { text: moment(bill.billDate).locale("fi").format("L") },
                    { text: moment(bill.billObj.invoice.dates.dueFinish).locale("fi").format("L") },
                    { text: this.roundNumber(price+billingFees), alignment: "right", fontSize: 10 },
                    { text: "€", alignment: 'left', fontSize: 10 },
                    { text: this.roundNumber(vat), alignment: "right", fontSize: 10 },
                    { text: "€", alignment: 'left', fontSize: 10 },
                    { text: this.roundNumber(fullPrice+billingFees+billingFeesVat), alignment: "right", fontSize: 10 },
                    { text: "€", alignment: 'left', fontSize: 10 },
                    { text: t(bill.status), alignment: "right", fontSize: 10 }
                ];
                reportRows.push(row1)
            }
        });

        console.log("createPdf reportRows ", reportRows);

        const reportTable = {
            fontSize: 10,
            margin: [10, 0, 0, 0],
            table: {
                headerRows: 0,
                widths: [50, 100, 60, 60, 45, 5, 40, 5, 40, 5, 50],
                body: reportRows
            },
            layout: "noBorders",
        };

        docRef.content.push(reportTable);
        console.log("createPdf docRef ", docRef);

        const summary = this.generateSummary(reportSummary);
        docRef.content.push(summary);

        //pdfMake.createPdf(docRef).download();
        if (this.props.openInNewTab) pdfMake.createPdf(docRef).open();

        pdfMake.createPdf(docRef).getBase64((dataUrl) => {
            this.saveToRedux(dataUrl);

            const reportOwner = !isEmpty(company) ? company.name : '';

            const recipients = [company.email];

            //TODO, other default address
            const fileName = t(ReportTypes.BILLS) + '_' + reportOwner + "_" + ' ' + dateRange.selection.startDate + '-' + dateRange.selection.endDate;

            uploadToFirebaseStorage(user, dataUrl, ReportTypes.KTV).then(filestorageSuccess => {
                console.log('succesfully writed pdf to storage', filestorageSuccess)

                createReport(user, ReportTypes.BILLS, reportOwner, recipients, "email", fileName, dataUrl, filestorageSuccess)
                    .then((report) => {
                        console.log("storeReport success: ", report);
                        this.props.onReportsCreated(true);
                    })
                    .catch((err) => {
                        console.log("storeReport ERROR: ", err);
                        this.props.onReportsCreated(false);
                    })
            });
        });

    };

    saveToRedux = (pdf) => {
        //const customer = this.props.customers.find(item => item.name === this.props.customer);

        this.props.saveSend(this.props.customer, this.props.selectedBills, this.props.company, pdf)
    };


    generateSummary = reportSummary => {
        const { t } = this.props;
        const summary = {
            margin: [20, 30, 20, 10],
            table: {
                headerRows: 1,
                widths: ['*', 55, 55],
                body: [
                    ['', '', { text: t('Total'), alignment: "right" }],
                    ['', { text: t('Amount'), alignment: "right" }, { text: t('Unit'), alignment: "right" }],
                    [{ text: t('Bill Total'), alignment: "right" },
                    { text: this.roundNumber(reportSummary.totalAmount), alignment: "right", fontSize: 10 },
                    { text: '€', alignment: "right" }
                    ],
                    [{ text: t('Bill total amount without vat'), alignment: "right" },
                    { text: this.roundNumber(reportSummary.totalAmountWithoutVAT+reportSummary.totalBillingFees), alignment: "right", fontSize: 10 },
                    { text: '€', alignment: "right" }
                    ],
                    [{ text: t('Bill vat total'), alignment: "right" },
                    { text: this.roundNumber(reportSummary.totalVATAmount), alignment: "right", fontSize: 10 },
                    { text: '€', alignment: "right" }
                    ],

                ],
                alignment: "right",
            },
            layout: "headerLineOnly"
        };


        console.log("return summary ", summary);


        return summary;
    }

    generateHeader = bills => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { company, dateRange, t } = this.props;
        let headerSize = 14, normalText = 10;
        const body = [];

        this.props.onReportCreate();

        const docRef = {
            content: [
                {
                    margin: [0, 0, 0, 0],
                    table: {
                        headerRows: 1,
                        widths: ['*', '*', '*'],
                        body: [
                            [
                                { text: company.name, alignment: 'left', style: "subheader" },
                                { text: t("Period bill report"), alignment: 'center', style: "subheader" },
                                { text: "", alignment: 'right', style: "subheader" },

                            ],
                            [
                                { text: company.streetAddress, alignment: 'left' },
                                { text: "", alignment: 'center', style: "subheader" },
                                { text: "", alignment: 'right' },
                            ],
                            [
                                { text: company.city + ' ' + company.postCode, alignment: 'left' },
                                {
                                    text: moment(dateRange.selection.startDate).locale("fi").format('L') + ' - ' + moment(dateRange.selection.endDate).locale("fi").format('L'),
                                    alignment: 'center',
                                    style: "subheader"
                                },
                                { text: "", alignment: 'right' },
                            ],
                            [{ text: `y-tunnus: ${company.yCode ? company.yCode : ''}` }, { text: '' }, { text: '' }]
                        ]
                    },
                    layout: "noBorders",
                },

                {
                    margin: [5, 40, 20, 15],
                    table: {
                        headerRows: 1,
                        widths: [55, 95, 55, 55, 45, 40, 50, 40],
                        body: [
                            [t('Bill number'), t('Bill Recipient'), t('Bill date'), t('Due date'), t('Bill amount without vat'), t('Bill vat'), t('Bill amount'), t('Bill status') ],
                        ]
                    },
                    layout: "headerLineOnly"
                }

            ],
            pageMargins: [20, 10, 20, 10],
            styles: {
                subheader: {
                    fontSize: 15,
                    bold: true
                }
            }
        };

        this.setState({
            docRef: docRef
        });


        setTimeout(() => {
            this.createPdf(bills)
        }, 100);
    };

    render() {
        return (
            <div>
                <Button
                    variant="outlined"
                    style={{
                        width: 210,
                        marginLeft: 50,
                        marginTop: 10
                    }}
                    onClick={() => this.generateHeader(this.props.selectedBills)}
                >
                    {this.props.t("Generate_PDF")}
                </Button>
                <FormControlLabel
                    style={{
                        marginLeft: 75,
                        marginTop: 10,
                    }}
                    control={
                        <Checkbox
                            checked={this.props.openInNewTab}
                            onChange={(event) => this.props.onChangeOpenInNewTab(event.target.checked)}
                            value={"checkOpenOInNewTab"}
                            color="primary"
                        />
                    }
                    label={this.props.t("Open in new tab")}
                />
                <div style={{
                    marginLeft: 75,
                    marginTop: 10,
                }}>{this.props.t("Open in new tab tooltip")}</div>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    user: state.user,
    products: state.products
});

export default connect(mapStateToProps, { saveSend })(withTranslation()(PDFCompanyBillSummary));