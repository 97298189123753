import React from 'react';

import {TextField} from '@material-ui/core';

import "./TimeButtonCoponent.css";
const TimeButtonComponent = props => {
    const step = 0.25;

    const decrease = () => {
        const newValue = props.value.length === 0 ? "0.00" : (parseFloat(props.value) - step).toFixed(2);
        //const newValue = props.value === 0 ? 0 : props.value - step;
        props.onChange(newValue, props.field, props.index);
    };

    const increase = () => {
        const newValue = props.value.length === 0 ? "0.00" : (parseFloat(props.value) + step).toFixed(2);
        //const newValue = props.value + step;
        props.onChange(newValue, props.field, props.index);
    };

    const direct = event => {
        props.onChange(event.target.value, props.field, props.index);
    };

    console.log("TimeButtonComponent props ", props)

    return (
        <div className="time-container">
            <button className="decrease-button" onClick={decrease}>
                -
            </button>
            <div className="time-screen">
                <TextField
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            height: 25
                        }
                    }}
                    style={{
                        width: 75
                    }}
                    inputProps={{
                        style: {
                            textAlign: 'center'
                        }
                    }}
                    value={props.value}
                    onChange={event => direct(event)}
                />
            </div>
            <button className="increase-button" onClick={increase}>
                +
            </button>
        </div>

    );
};

export default TimeButtonComponent;