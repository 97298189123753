import React, {Fragment} from 'react';

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button
} from '@material-ui/core';

import PropTypes from 'prop-types';

import {withTranslation} from "react-i18next";

const GlobalConfirmationDialog = props => {
    const {open, title, message, mode, t} = props;
    return (
        <Dialog
            open={open}
            onClose={props.onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions style={{ display: "flex", justifyContent: "space-between"}}>
                <Fragment>

                <Button
                    variant="contained"
                    style={{
                        color: '#FFF',
                        backgroundColor: '#1770B2',
                        width: '49%'
                    }}
                    onClick={props.onClose}
                >
                    {t("Cancel")}
                </Button>

                {(!mode || mode === "delete") &&
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: "#b22717",
                            color: "#FFF",
                            width: '49%'
                        }}
                        onClick={props.onConfirm}
                    >
                        {t("Delete")}
                    </Button>
                }
                {mode === "create" &&
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#1770B2',
                            color: '#FFF',
                            width: '49%'
                        }}
                        onClick={props.onConfirm}
                    >
                        {t("Create")}
                    </Button>

                }
                {mode === "end" &&
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#1770B2',
                            color: '#FFF',
                            width: '49%'
                        }}
                        onClick={props.onConfirm}
                    >
                        {t("End")}
                    </Button>

                }
                </Fragment>

            </DialogActions>
        </Dialog>
    );
};

GlobalConfirmationDialog.propTypes = {
    onConfirm: PropTypes.func,
    onClose: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
    open: PropTypes.bool,
    mode: PropTypes.string
};

export default withTranslation()(GlobalConfirmationDialog);
