import React from 'react';
import Autocomplete from "react-autocomplete";

import {
    TextField,
    MenuItem,
    Divider
} from "@material-ui/core";


const AutocompleteCalendar = props => {
    const {width, placeholder} = props;

    const renderTextField = props => {
        const {InputProps, ref, ...other} = props;
        return <TextField
            placeholder={placeholder}
            style={{
                width: width,
                marginTop: 10
            }}
            variant="outlined"
            InputProps={{
                inputRef: ref,
                ...InputProps
            }}
            {...other}
        />
    };

    return (
        <Autocomplete
            items={props.searchData}
            getItemValue={(item) => item.id }
            //getItemValue={(item) => { return ({id:item.id, name: item.name, customerNumber: item.customerNumber, companyName: item.companyName}) }}
            shouldItemRender={(item, value) => item.name.toLowerCase().search(value.toLowerCase()) > -1}
            renderMenu={children => (
                <div style={{backgroundColor: '#FFF', position: 'absolute', opacity: 100, zIndex: 10, width: width, borderWidth: 1, borderStyle: 'solid', borderColor: '#ccc'}}>
                    {children}
                </div>
            )}
            renderItem={item =>
                <div key={item.id}>
                    <MenuItem>{item.name}{item.companyName ? '/'+item.companyName : ''}{item.customerNumber?'('+item.customerNumber+')':''}</MenuItem>
                    <Divider style={{backgroundColor: '#ccc', width: '97%', marginLeft: 10}}/>
                </div>
            }
            renderInput={renderTextField}
            value={props.searchTerm}
            onChange={event => props.onChange(event)}
            onSelect={event => props.onSelect(event)}
        />
    )
};

export default AutocompleteCalendar;