import React, {Component} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Divider,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';

import {
    Search
} from "@material-ui/icons";

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import "./TaskDialog.css";

class TaskDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedLog: {},
            selectedTemplate: null,
            customerTemplates: [],
            open: this.props.open,
            searchTerm: "",
            customerTemplate: null,
            selectedTasks: [],
            selectedMaterials: [],
            searchList: [],
        };
    }

    componentDidMount() {
        const {models, customers, selectedLog, selectedJob} = this.props;

        let log = JSON.parse(JSON.stringify(selectedLog));

        const customerID = selectedJob ? selectedJob.customer : (selectedLog ? selectedLog.customer : "");

        if (customerID) {

            let customerTemplates = models.filter(template => template.customer === customerID);

            this.setState({
                customerTemplates,
                selectedLog: log
            })
        }

        else {
            this.setState({
                selectedLog: log
            });
        }

    }

    handleSelect = model => {
        this.setState({
            selectedTemplate: model
        });
    };

    isChecked = item => {
        const {selectedTasks, selectedLog} = this.state;
        const {products} = this.props.products;

        const product = products.find(product => product.pNumber === item.pNumber);

        const taskExists = selectedTasks.find(task => task.name === item.name && task.pNumber === item.pNumber);

        return product && product.isMaterial === "true" && selectedLog.materials ? selectedLog.materials.includes(product) : !!taskExists;
    };

    isTaskOrMaterial = item => {
        const {products} = this.props.products;

        const product = products.find(product => product.pNumber === item.pNumber);

        product && product.isMaterial === "true" ? this.handleMaterials(item, product) : this.handleTasks(item);

    };

    searchTemplate = event => {
        const {models} = this.props;

        const searchList = [];

        models && models.forEach(model => {
            if (model.name.toLowerCase().search(event.target.value.toLowerCase()) > -1) searchList.push(model);
        });

        this.setState({
            searchList,
            searchTerm: event.target.value
        });
    };

    handleTasks = task => {
        const {selectedLog, selectedTasks} = this.state;

        const newTask = {
            name: task.name,
            duration: "0.00",
            pNumber: task.pNumber,
            invoiceText: task.invoiceText ? task.invoiceText : "",
            unit: task.unit ? task.unit : "",
        };

        if (selectedLog.billing && !selectedLog.billing.billableHoursByTask) selectedLog.billing.billableHoursByTask = [];

        const taskExists = selectedLog.billing.billableHoursByTask.find(eTask => eTask.name === task.name && eTask.pNumber === task.pNumber);

        if (taskExists) {
            const removeIndex = selectedLog.billing.billableHoursByTask.indexOf(newTask);
            selectedLog.billing.billableHoursByTask.splice(removeIndex, 1);
        }
        else selectedLog.billing.billableHoursByTask.push(newTask);


        /*
        // Array that holds selected tasks
        if(!selectedTasks.includes(newTask)) {
            selectedTasks.push(newTask);
        }
        else {
            const removeIndex = selectedTasks.indexOf(newTask);
            selectedTasks.splice(removeIndex, 1);
        }

         */

        const selectedItem = selectedTasks.find(sItem => sItem.name === task.name && sItem.pNumber === task.pNumber);

        if (selectedItem) {
            const removeIndex = selectedTasks.indexOf(selectedItem);
            selectedTasks.splice(removeIndex, 1);
        }
        else selectedTasks.push(newTask);


        this.setState({
            selectedLog: selectedLog,
            selectedTasks: selectedTasks
        });
    };

    handleMaterials = (item, product) => {
        const {selectedLog} = this.state;

        if (!selectedLog.materials) selectedLog.materials = [];

        if (!selectedLog.materials.includes(product)) {
            product.amount = 1;
            selectedLog.materials.push(product)
        }
        else {
            const removeIndex = selectedLog.materials.indexOf(product);
            selectedLog.materials.splice(removeIndex, 1);
        }

        this.setState({
            selectedLog
        });
    };

    render() {
        const {t} = this.props;
        return (
            <Dialog
                open={this.state.open}
                onClose={() => this.props.onClose()}
                maxWidth="sm"
            >
                <DialogTitle>{t("Add_task_material")}</DialogTitle>
                <DialogContent>
                    <div className="task-search">
                        <TextField
                            variant="outlined"
                            InputProps={{
                                style: {
                                    height: 30,
                                    fontSize: 13
                                },
                                startAdornment: <Search/>
                            }}
                            value={this.state.searchTerm}
                            InputLabelProps={{
                                className: "task-label"
                            }}
                            style={{
                                marginTop: 5,
                                width: 130
                            }}
                            onChange={event => this.searchTemplate(event)}
                        />
                        <p className="task-list-title">{t("Customer template")}</p>
                        {this.state.customerTemplates && this.state.customerTemplates.length > 0 && this.state.customerTemplates.map(template => (
                            <p className="task-list-item" onClick={() => this.handleSelect(template)}
                               key={template.id + template.customer}>{template.name} </p>
                        ))
                        }
                        {this.state.customerTemplate && this.state.customerTemplates.length === 0 &&
                        <p className="task-list-item">{t("No template")} </p>
                        }
                        <p className="task-list-title">{t("Other")}</p>
                        {this.state.searchTerm.length === 0 && this.props.models && this.props.models.map(model => model.type === "model" ? (
                            <p key={model.id} className="task-list-item"
                               onClick={() => this.handleSelect(model)}>{model.name}</p>
                        ) : null)}

                        {this.state.searchTerm.length > 0 && this.state.searchList && this.state.searchList.map(model => (
                            <p key={model.id} className="task-list-item"
                               onClick={() => this.handleSelect(model)}>{model.name}</p>
                        ))}
                    </div>

                    <Divider style={{width: '100%', backgroundColor: '#ccc'}}/>

                    <div className="task-list" style={{height: 200}}>
                        {this.state.selectedTemplate &&
                        <p className="task-list-title">{t("Select")}</p>
                        }
                        {this.state.selectedTemplate && this.state.selectedTemplate.tasks &&
                        this.state.selectedTemplate.tasks.map(item => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        style={{
                                            color: '#1770B2'
                                        }}
                                        checked={this.isChecked(item)}
                                        onChange={() => this.isTaskOrMaterial(item)}
                                    />
                                }
                                label={item.name}
                                key={item.pNumber}
                                style={{marginLeft: 2}}
                            />
                        ))}


                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => this.props.onClose()}
                    >
                        {t("Cancel")}
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.props.addTasks(this.state.selectedLog)}
                    >
                        {t("Add")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    products: state.products
});

export default connect(mapStateToProps, {})(withTranslation()(TaskDialog));