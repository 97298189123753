import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/storage";
import * as jwt from 'jsonwebtoken';

import PDFComponent from '../PDFComponent';
import EmailHelper from '../EmailHelper';
import { Button, TextField, } from '@material-ui/core';

export default function (props) {

    const [pdf, setPdf] = useState(null);
    const [authorizationCodeSent, setAuthorizationCodeSent] = useState(false);
    const [authorizationCode, setAuthorizationCode] = useState(null);
    const [userTypedAuthorizationCode, setuserTypedAuthorizationCode] = useState(null);
    const token = useParams();

    const decoded = jwt.verify(token.jwt, 'shhh');
    const path = decoded.path;
    console.log('decoded', decoded);
    console.log('path', path);

    const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
        const reader = new FileReader;
        reader.onerror = reject;
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.readAsDataURL(blob);
    });


    firebase.storage().ref(path).getDownloadURL().then(url => {
        fetch(url)
            .then(response => {
                console.log('response', response)
                return response.blob()
            })

            .then(blob => {
                convertBlobToBase64(blob).then(base64 => {
                    base64 = base64.replace('octet-stream', 'pdf')
                    setPdf(base64);
                    console.log(base64);
                })
            })
            .catch(console.error);
        console.log(url);
    })

    const getAuthorizationCode = async () => {
        console.log('path', path)
        return await firebase.firestore().collection('approveReports').where('storagePath', '==', path).get().then(querySnapshot => {
            return querySnapshot.docs[0].data().authorizationCode;
        })
    }

    const sendAuthorizationCode = () => {
        console.log('sending authorization code')
        getAuthorizationCode().then(code => {
            setAuthorizationCode(code);
            EmailHelper.sendEmail('villaaksonen@gmail.com', code, 'Vahvistuskoodi').then(response => {
                console.log('email response', response);
                setAuthorizationCodeSent(true);
                //TODO add to i18n
                alert('Sinulle lähetettiin varmennuskoodi sähköpostiisi');
            });
        })
    }

    const verifyAuthorizationCode = () => {
        const verifyAuthorizationCodeAndSetReportApproved = firebase.functions().httpsCallable('verifyAuthorizationAndSetReportApproved')
        verifyAuthorizationCodeAndSetReportApproved({ userTypedAuthorizationCode: userTypedAuthorizationCode, reportStoragePath: path }).then(response => {
            console.log(response);
            if (response?.data?.success === true) {
                alert('Raportti hyväksytty onnistuneesti');
            }
        }).catch(err => console.error(err));
    }

    if (!pdf) {
        return null;
    }
    return (
        <div style={{ width: '100%', height: '80%' }}>
            <PDFComponent pdf={pdf} height='100%' />
            {!authorizationCodeSent ?
                <Button variant='contained' color='primary' onClick={() => sendAuthorizationCode()}>Vahvista</Button>
                :
                <>
                    <Button variant='contained' color='primary' onClick={() => verifyAuthorizationCode()}>Vahvista</Button>
                    <TextField
                        required
                        variant='outlined'
                        onChange={e => setuserTypedAuthorizationCode(e.target.value)}
                    />
                </>
            }
        </div>
    );
}