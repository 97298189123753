import React, { Component } from 'react';

import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment-timezone";

class ClickDialog extends Component {

    getIntervalText = (freq, interval) => {
        const { t } = this.props;
        switch (freq) {
            case "DAILY":
                return interval === 1 ? `${t("Every")} ${t("day")}` : `${t("Every")} ${interval} ${t("days")}`;
            case "WEEKLY":
                if (interval === 0) {
                    return t("Does not repeat")
                }
                else {
                    return interval === 1 ? `${t("Every")} ${t("week")}` : `${t("Every")} ${interval} ${t("weeks")}`;
                }
            case "MONTHLY":
                return interval === 1 ? `${t("Every")} ${t("month")}` : `${t("Every")} ${interval} ${t("months")}`;
            default:
                return "?"
        }
    };


    render() {
        const { selectedJob, worksites, employees, open, t } = this.props;
        const { user } = this.props.user;

        let worksite = {};

        if (worksites) {
            const site = worksites.find(worksite => worksite.id === selectedJob.worksite);
            if (site) worksite = site;
        }

        console.log("ClickDialog render() selectedJob ", selectedJob)

        return (
            <Dialog
                open={open}
                maxWidth="sm"
                fullWidth
                onClose={() => this.props.onClose()}
            >
                <DialogTitle>
                    {/*
                    <Grid container>
                        <Grid item xs={12} md={11}>
                            {selectedJob.title}
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <IconButton onClick={() => this.props.onClose()}>
                                <Close/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    */}
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                        <div style={{ marginTop: 20 }}>
                            <p>{selectedJob.title} - {selectedJob.customerLabel}</p>
                            <p>{t("Worksite")}: {worksite.name}</p>
                        </div>

                        <IconButton onClick={() => this.props.onClose()}>
                            <Close />
                        </IconButton>
                    </div>

                </DialogTitle>
                <DialogContent>
                    <div>
                        <p style={{
                            marginBottom: 0,
                            paddingBottom: 0
                        }}>{moment(selectedJob.startDT).locale(user.lang ? user.lang : "fi").format('dddd, MMMM DD')}</p>
                        <p style={{
                            fontSize: 12,
                            marginTop: 0,
                            paddingTop: 0,
                            marginBottom: 0,
                            paddingBottom: 0
                        }}>{moment(selectedJob.startDT).locale('fi').format('LT') + ' - ' + moment(selectedJob.endDT).locale('fi').format('LT')}</p>
                    </div>
                    <div>
                        {selectedJob.rrule && <p style={{
                            fontSize: 12,
                            marginTop: 0,
                            paddingTop: 0
                        }}>{this.getIntervalText(selectedJob.rrule.FREQ, selectedJob.rrule.INTERVAL)}</p>}

                        {selectedJob.changeImpact && selectedJob.changeImpact === "once" &&
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>
                                {`${t('Once impact')} - ${selectedJob.modifiedBy && employees ? employees.find(empl => empl.id === selectedJob.modifiedBy)?.name : ''}`}</p>
                        }

                        {selectedJob.changeImpact && selectedJob.changeImpact === "onward" &&
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>
                                {`${t('Onwards impact')} - ${selectedJob.modifiedBy && employees ? employees.find(empl => empl.id === selectedJob.modifiedBy)?.name : ''}`}</p>
                        }

                        {selectedJob.changeImpact && selectedJob.changeImpact === "range" &&
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>
                                {`${t('Range impact')} - ${selectedJob.modifiedBy && employees ? employees.find(empl => empl.id === selectedJob.modifiedBy)?.name : ''}`}</p>
                        }

                        {selectedJob.isOntime &&
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>
                                {t('On time start')}</p>
                        }

                        {selectedJob.endOntime &&
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>
                                {t('End ontime')}</p>
                        }


                        {selectedJob.maxEmp && selectedJob.maxEmp > 0 &&
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>
                                {t('max_employees')}: {selectedJob.maxEmp}</p>
                        }

                    </div>

                    <div>
                        <p style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 5 }}>{t("Customer")}</p>
                        <p style={{
                            fontSize: 12,
                            marginTop: 0,
                            paddingTop: 0,
                            marginBottom: 0,
                            paddingBottom: 0
                        }}>{selectedJob.customerLabel}</p>
                    </div>

                    {worksite && worksite.name && <div>
                        <p style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 5 }}>{t("Worksite")}</p>
                        <p style={{
                            fontSize: 12,
                            marginTop: 0,
                            paddingTop: 0,
                            marginBottom: 0,
                            paddingBottom: 0
                        }}>{worksite.name}</p>
                        <p style={{
                            fontSize: 12,
                            marginTop: 0,
                            paddingTop: 2,
                            marginBottom: 0,
                            paddingBottom: 0
                        }}>{`${worksite.streetAddress}, ${worksite.postCode} ${worksite.city}`}</p>
                    </div>}

                    {selectedJob.additionalInfo && <div>
                        <p style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 5 }}>{t("Additional info")}</p>
                        <p style={{
                            fontSize: 12,
                            marginTop: 0,
                            paddingTop: 0,
                            marginBottom: 0,
                            paddingBottom: 0
                        }}>{selectedJob.additionalInfo}</p>
                    </div>}

                    <div>
                        <p style={{ marginBottom: 0, paddingBottom: 0, paddingTop: 5 }}>{t("Employees")}</p>
                        {selectedJob.employees && selectedJob.employees.map(employee => (
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }} key={employee.id}>{employee.name}</p>
                        ))}
                    </div>
                </DialogContent>

                <DialogActions style={{ width: '100%' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        paddingLeft: 20,
                        paddingRight: 20,
                        height: 40
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '50%' }}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => this.props.onDelete()}
                            >
                                {t("Delete")}
                            </Button>

                            <Button
                                variant="outlined"
                                color="secondary"
                                style={{
                                    marginLeft: 10
                                }}
                                onClick={() => this.props.onCancel()}
                            >
                                {t("Cancel job")}
                            </Button>
                        </div>


                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{ marginRight: 10 }}
                                onClick={() => this.props.onClose()}
                            >
                                {t("Close")}
                            </Button>

                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => this.props.onEdit()}
                            >
                                {t("Edit")}
                            </Button>
                        </div>
                    </div>


                </DialogActions>
            </Dialog>
        );
    }
}


const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(ClickDialog));