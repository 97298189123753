import React, { Component } from 'react';

import {
    Paper,
    TextField,
    Button,
    FormControlLabel,
    Radio
} from '@material-ui/core';
import firebase from 'firebase/app';
import "firebase/firestore";
import "firebase/functions";
import keys from './util/keys';
import { connect } from 'react-redux';

import moment from 'moment';

import GlobalConfirmationDialog from "./CommonDialogs/GlobalConfirmationDialog";
import { createUserSettings } from "./SettingsComponents/CreateSettings";
import { withTranslation } from "react-i18next";
// Because firebase create account signs in automatically, this second account is used for account creation
let createUser = "";

/* NOTE if running locally, change this to development, else production*/
// if (process.env.NODE_ENV === "production") {

if (process.env.REACT_APP_ENV === "development") {
    createUser = firebase.initializeApp(keys.fConfig, "Secondary");
} else {
    createUser = firebase.initializeApp(keys.fProduction, "Secondary");
}

// FOR NOW AS ALWAYS RUNNING LOCALLY TO PRODUCTION - ALWAYS USE PRODUCTION ENV SETTINGS
// createUser = firebase.initializeApp(keys.fProduction, "Secondary");


class EmployeeEditor extends Component {

    constructor(props) {

        /* Properties */

        super(props);

        /* State variables */

        this.state = {
            employee: {
                isSubcontractor: "false",
                isActive: "true"
            },
            employeeCopy: {},
            editMode: false,
            confirmationDialog: false,
        };

    }

    componentDidMount() {
        const { match: { params } } = this.props;
        if (params.employeeId) {
            this.unsubscribeEmployeeListener = firebase.firestore().collection('employees').doc(params.employeeId).onSnapshot(docSnapshot => {
                const employee = docSnapshot.data();
                employee.id = docSnapshot.id;
                this.setState({
                    employee: employee,
                    employeeCopy: JSON.parse(JSON.stringify(employee)),
                    loading: false,
                    editMode: true,
                });
            })
        }
    }


    componentWillUnmount() {
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
    }

    changeHandler(event, field) {
        const { employee } = this.state;
        employee[field] = event.target.value;

        this.setState({ employee: employee });
    }


    saveHandler = () => {
        const { employee, employeeCopy } = this.state;
        const { user } = this.props.user;
        employee.companyID = user.companyID;


        // update if we have id already
        if (employee.id) {
            // Set Activation / deactivation dates if needed for billing user account
            if (employee.isActive !== employeeCopy.isActive) {
                const date = moment().format("YYYYMMDDTHHmmss");

                if (employee.isActive === "false") {
                    employee.activeArray && employee.activeArray.forEach(activation => {
                        if (activation.active && !activation.deactive) {
                            activation.deactive = date;
                        }
                    });
                    firebase.functions().httpsCallable("userAccountHandler")({ uid: employee.id, method: "deactive" });
                }
                else {
                    if (employee.activeArray) employee.activeArray.push({ active: date });
                    else employee.activeArray = [{ active: date }];
                    firebase.functions().httpsCallable("userAccountHandler")({ uid: employee.id, method: "active" });
                }
            }

            firebase.firestore().collection('employees').doc(employee.id).set(this.state.employee);
        }

        // else create new

        else {

            //create user account for employee
            //after account is created, create new employee with uid
            // Also add email address to users table so we can root out duplicate values
            const user = {
                name: `${employee.firstName} ${employee.familyName}`,
                email: employee.email,
            };

            // Add creation date
            employee.created = moment().format("YYYYMMDDTHHmmss");

            // Add settings object
            employee.settings = {};
            employee.settings.permissions = createUserSettings();

            // Add array for active dates
            employee.activeArray = [{ active: employee.created }];

            createUser.auth().createUserWithEmailAndPassword(employee.email, employee.firstName + '123').then(function (firebaseUser) {

                user.userID = firebaseUser.user.uid;

                firebase.firestore().collection('employees').doc(firebaseUser.user.uid).set(employee);

                createUser.auth().signOut();
            });

            firebase.firestore().collection('users').add(user);
        }

        this.props.history.goBack();
    };

    deleteHandler = () => {
        const { employee } = this.state;

        employee.isDeleted = true;
        employee.isActive = "false"; //inactivate also when deleting

        // Set employee as deactived as well
        employee.activeArray && employee.activeArray.forEach(activation => {
            if (activation.active && !activation.deactive) activation.deactive = moment().format("YYYYMMDDTHHmmss");
        });

        const userMethod = {
            uid: employee.id,
            method: "deactive"
        };

        firebase.functions().httpsCallable("userAccountHandler")(userMethod);

        firebase.firestore().collection("employees").doc(employee.id).set(employee)
            .then(() => {
                this.props.history.replace("/registry/employees/")
            });
    };


    render() {
        const { employee } = this.state;
        const { t } = this.props;
        return (
            <div style={{ width: '100%', height: '100%', overflowY: 'auto' }}>

                <div style={{ width: '50vw', marginLeft: 'auto', marginRight: 'auto', marginTop: '3%', height: 'auto' }}>
                    <Paper style={{ width: '100%', marginBottom: 10 }}>
                        <div style={{ width: '100%', height: '8%', backgroundColor: '#2B81C1' }}>
                            {!this.state.id &&
                                <h1 style={{ color: '#FFF', paddingTop: 20, paddingLeft: 40 }}>{t("Add employee")}</h1>}
                            {this.state.id &&
                                <h1 style={{ color: '#FFF', paddingTop: 20, paddingLeft: 40 }}>{t("Edit employee")}</h1>}
                        </div>

                        <div style={{ padding: 20 }}>

                            <div style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '49%' }}>
                                    <TextField
                                        variant="outlined"
                                        label={t("First name")}
                                        style={{
                                            backgroundColor: '#FFF',
                                            borderRadius: 4,
                                            width: '100%'
                                        }}
                                        value={employee.firstName || ""}
                                        onChange={event => this.changeHandler(event, 'firstName')}
                                    />
                                </div>
                                <div style={{ flexGrow: 1 }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <TextField
                                            variant="outlined"
                                            label={t("Family name")}
                                            style={{
                                                width: '100%',
                                                marginLeft: 20
                                            }}
                                            value={employee.familyName || ""}
                                            onChange={event => this.changeHandler(event, 'familyName')}
                                        />
                                    </div>
                                </div>
                            </div>


                            <TextField
                                variant="outlined"
                                label={t("Street address")}
                                style={{
                                    width: '100%',
                                    marginTop: 10
                                }}
                                value={employee.streetAddress || ""}
                                onChange={event => this.changeHandler(event, 'streetAddress')}
                            />


                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start', width: '70%' }}>
                                    <TextField
                                        variant="outlined"
                                        label={t("City")}
                                        style={{
                                            width: '100%',
                                        }}
                                        value={employee.city || ""}
                                        onChange={event => this.changeHandler(event, 'city')}
                                    />
                                </div>
                                <div style={{ flexGrow: 1 }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                        <TextField
                                            variant="outlined"
                                            label={t("Post code")}
                                            style={{
                                                width: '100%',
                                                marginLeft: 20
                                            }}
                                            value={employee.postCode || ""}
                                            onChange={event => this.changeHandler(event, 'postCode')}
                                        />
                                    </div>
                                </div>
                            </div>

                            <TextField
                                variant="outlined"
                                label={t("Home phone")}
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                                value={employee.phone || ""}
                                onChange={event => this.changeHandler(event, 'phone')}
                            />

                            <TextField
                                variant="outlined"
                                label={t("Work phone")}
                                style={{
                                    width: '100%',
                                    marginTop: 10
                                }}
                                value={employee.phoneWork || ""}
                                onChange={event => this.changeHandler(event, 'phoneWork')}
                            />


                            <TextField
                                variant="outlined"
                                label={t("Email")}
                                style={{
                                    width: '100%',
                                    marginTop: 10
                                }}
                                disabled={this.state.editMode}
                                value={employee.email || ""}
                                onChange={event => this.changeHandler(event, 'email')}
                            />

                            <TextField
                                variant="outlined"
                                label={t("Website")}
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                                value={employee.website || ""}
                                onChange={event => this.changeHandler(event, 'website')}
                            />

                            <TextField
                                variant="outlined"
                                label={t("Additional info")}
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                                value={employee.additionalInfo || ""}
                                onChange={event => this.changeHandler(event, 'additionalInfo')}
                            />

                            <br />

                            {/* Employee type */}
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <p style={{ fontSize: 18, fontWeight: 'bold' }}>{t("Type")}</p>
                                    <FormControlLabel
                                        style={{
                                            marginTop: 10
                                        }}
                                        control={
                                            <Radio
                                                color="primary"
                                                checked={employee.isSubcontractor === "false"}
                                                value={"false"}
                                                onChange={event => this.changeHandler(event, 'isSubcontractor')}
                                            />
                                        }
                                        label={t("Company employee")}
                                    />
                                    <br />
                                    <FormControlLabel
                                        style={{
                                            marginTop: 10
                                        }}
                                        control={
                                            <Radio
                                                color="primary"
                                                checked={employee.isSubcontractor === "true"}
                                                value={"true"}
                                                onChange={event => this.changeHandler(event, 'isSubcontractor')}
                                            />
                                        }
                                        label={t("Subcontractor")}
                                    />
                                </div>

                                {/* Active status */}
                                <div style={{ marginLeft: 50 }}>
                                    <p style={{ fontSize: 18, fontWeight: 'bold' }}>{t("Active_sub")}</p>
                                    <FormControlLabel
                                        style={{
                                            marginTop: 10
                                        }}
                                        control={
                                            <Radio
                                                color="primary"
                                                value={"true"}
                                                checked={employee.isActive === "true"}
                                                onChange={(event) => this.changeHandler(event, 'isActive')}
                                            />
                                        }
                                        label={t("Yes")}
                                    />
                                    <br />
                                    <FormControlLabel
                                        style={{
                                            marginTop: 10
                                        }}
                                        control={
                                            <Radio
                                                color="primary"
                                                value={"false"}
                                                checked={employee.isActive === "false"}
                                                onChange={(event) => this.changeHandler(event, 'isActive')}
                                            />
                                        }
                                        label={t("No")}
                                    />
                                </div>
                            </div>


                            <br />
                            {employee.isSubcontractor === "true" &&
                                <TextField
                                    variant="outlined"
                                    label={t("Company ID")}
                                    style={{
                                        backgroundColor: '#FFF',
                                        borderRadius: 4,
                                        width: '100%',
                                        marginTop: 10
                                    }}
                                    value={employee.yCode || ""}
                                    onChange={event => this.changeHandler(event, 'yCode')}
                                />
                            }


                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}>
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: '#b22717',
                                        color: "#FFF",
                                        marginRight: 10
                                    }}
                                    onClick={() => this.setState({ confirmationDialog: true })}
                                >
                                    {t("Delete")}
                                </Button>

                                <Button
                                    variant="contained"
                                    style={{
                                        color: '#FFF',
                                        backgroundColor: '#1770B2',
                                        marginRight: 10
                                    }}
                                    onClick={() => this.props.history.goBack()}
                                >
                                    {t("Cancel")}
                                </Button>

                                <Button
                                    variant="contained"
                                    style={{
                                        color: '#FFF',
                                        backgroundColor: '#1770B2',
                                    }}
                                    onClick={() => this.saveHandler()}
                                >
                                    {t("Save")}
                                </Button>
                            </div>
                        </div>
                    </Paper>

                </div>

                <GlobalConfirmationDialog
                    open={this.state.confirmationDialog}
                    onClose={() => this.setState({ confirmationDialog: false })}
                    onConfirm={() => this.setState({ confirmationDialog: false }, () => this.deleteHandler())}
                    title={t("Attention")}
                    message={`${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DeleteActionEmployee")}?`}
                />

            </div>


        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(EmployeeEditor));
