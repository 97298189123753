
export const changeBillableToString = selectedLog => {

    const newLog = selectedLog;

    // Just to be sure check that billing object is present
    if (!newLog.billing) newLog.billing = {billableHours: "0.00"};

    if (newLog.billing.billableHours) newLog.billing.billableHours = (newLog.billing.billableHours / 3600000).toFixed(2);

    newLog.billing.billableHoursByTask && newLog.billing.billableHoursByTask.forEach(task => {
        task.duration = (task.duration / 3600000).toFixed(2)
    });

    return newLog;
};

export const changeBillableToInt = selectedLog => {

    const newLog = selectedLog;

    if (newLog.billing.billableHours.length === 0) newLog.billing.billableHours = 0;

    newLog.billing.billableHours = parseFloat(newLog.billing.billableHours) * 3600000;

    newLog.billing.billableHoursByTask && newLog.billing.billableHoursByTask.forEach(task => {
        if (task.duration.length === 0) task.duration = 0;

        task.duration = parseFloat(task.duration) * 3600000
    });

    return newLog;
};


export const validateBillableTime = (billing, user) => {
    if (!billing) return "";

    if (billing.billableHours && billing.billableHoursByTask) {
        let totalTaskDuration = 0.0;
        billing.billableHoursByTask.forEach((task) => {
            totalTaskDuration += task.duration;
            if (!task.unit) {
                return "Billable task unit missing";
            }
            if (task.duration <= 0) {
                return "Billable task unit without duration";
            }
        });

        if (billing.billableHours !== totalTaskDuration) {
            return "Billable sums mismatch";
        }
    } else if (billing.billableHours && !billing.billableHoursByTask) {
        return "Billable time per task missing";
    }

    return "OK"
};