import React, { Component } from 'react';

import {
    Button,
    FormControlLabel,
    Radio,
    CircularProgress,
    ClickAwayListener,
    TextField,
} from "@material-ui/core";
import moment from 'moment';
import "moment/locale/fi";
//import "moment/locale/en-gb";
import AutocompleteReports from '../AutocompleteReports';
//import DateMenuComponent from './DateMenuComponent';
import { DateRange } from 'react-date-range';
import { fi, enGB } from 'date-fns/locale'

import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { isEmpty } from '../../validations/isEmpty';
import { ContactSupportOutlined } from '@material-ui/icons';
import { getWorkdayWorklogs, getDayStartAndEndTimeFromWorklogs, findPausesFromWorklogsAndAddToWorkday, calculateEveningTime, calculateNightTime, calculateEveningPauses, calculateNightPauses, calculateDaytimePauses, createEmptyDaysForPeriod } from "../../util/WorkDayFunctions";


class WorktimeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchData: {
                employee: "",
                start: this.props.dateRange.start ? moment(this.props.dateRange.start) : moment().subtract(1, 'month'),
                end: this.props.dateRange.end ? moment(this.props.dateRange.end) : moment().endOf("day"),
                accuracy: "duration"
            },
            datePicker: false,
            pickField: "",
            anchor: {},
            menu: false,
            loading: false,
        };
    }



    handleMenu = event => {
        this.setState({
            menu: true,
            anchor: event.getBoundingClientRect(),
        })
        //setMenu(true);
        //setAnchor(event.getBoundingClientRect());
    };

    closeMenu = () => {
        this.setState({
            menu: false,
            anchor: {},
        })
        //setMenu(false);
        //setAnchor(null)
    };

    handleDate = event => {
        console.log("handleDate with date range ", event)
        this.props.onChange(event, 'date');  //this will chenge daterange for all timereports
        //set time also for this component's serachdata
        this.onDataChange(event.selection.startDate, "start");
        this.onDataChange(event.selection.endDate, "end");
        this.closeMenu();
    };


    onDataChange = (event, field) => {
        const { searchData } = this.state;

        //if (field === "end") { searchData[field] = moment(event).endOf("day"); }
        //else
        searchData[field] = event;

        this.setState({
            searchData,
            pickField: "",
            datePicker: false,

        }, this.props.onChange(event, field));
    };




    formSearchList = () => {
        const { selectedEmployee, employees } = this.props;
        const { searchData, eveningShiftStartTime, nightShiftStartTime, workdayStartTime } = this.state;
        const workTimes = {
            total: 0,
            normal: 0,
            normalByWeek: {},
            evenings: 0,
            nights: 0,
            saturdays: 0,
            sundays: 0,
            workdays: []
        };

        const ref = {};
        const refByWeek = {};


        console.log("formSearchList with searchData ", searchData)

        this.setState({ loading: true }, () => this.props.onWorkDaysProcessing())

        //let employee = this.props.employees.find(employee => employee.name === selectedEmployee);
        const employee = this.props.employees.find(employee => employee.name === searchData.employee);
        //const employee = employees && employees.find(emp => emp.name === selectedEmployee);

        const wDays = [];
        this.props.workdays && this.props.workdays.forEach(wDay => {
            if (moment(wDay.date).isBetween(searchData.start, searchData.end)) { // && (workday.status === "Finished" || workday.status === "Submitted" || workday.status === "Confirmed" )) {
                if (selectedEmployee && wDay.employee === employee.id)
                    wDays.push(wDay);
                else
                    wDays.push(wDay);
            }
        });

        
        // createEmptyDaysForPeriod = (start, end, loggedDays = [], selectedEmployee, allEmployees) 
        // create empty workdays and figure times if there are logged some hours
        const workdays = createEmptyDaysForPeriod(searchData.start, searchData.end, wDays, selectedEmployee, this.props.employees, this.props.worklogs, this.props.company);

        //create empty days for the whole period, even one has not created any day or worklogs in a normal-fashion
        /*
        let loopDate = moment(searchData.start).endOf("day");
        while (moment(loopDate) <= moment(moment(searchData.end).endOf("day"))) {
            console.log("loop all days, day ", loopDate.format("YYYYMMDD"))
            this.props.employees.forEach(empl => {
                if ((selectedEmployee === "" || selectedEmployee === empl.name) && empl.isActive !== "false") {
                    console.log("loop employee ", empl.name, empl.isValid)

                    const dateStr = moment(loopDate).format("YYYYMMDD");
                    //check if workdays array does not exist for this date & this emplyee, then create new and empty
                    if (!wDays.some(wd => wd.date === dateStr && wd.employee === empl.id)) {
                        //create emopty day for employee
                        console.log("Creating new log date for employee ", empl.name, dateStr)
                        wDays.push({ date: dateStr, employee: empl.id, duration: 0, generated: true }); //new 
                    } else {
                        console.log("day found ", dateStr, empl.name, (!wDays.some(wd => wd.date === dateStr && wd.employee === empl.id)))
                    }
                }
            })
            loopDate = moment(loopDate).add(1, "day");
        }

        //sort again and put to workdays array
        const workdays = wDays.sort((a, b) => {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
        });*/


        // now, loop all days - these are either reported of genererated above
        workdays && workdays.length>0 && workdays.forEach(oneWorkday => {

            let workday = JSON.parse(JSON.stringify(oneWorkday));
            // if selection made, or all
            if (isEmpty(employee) || workday.employee === employee?.id) {

                if (workday.employee) {
                    const employeeID = workday.employee; //JSON.parse(JSON.stringify(emp));

                    console.log("workday for employee ", employee, workday.date, moment(workday.date).format('dddd'))

                    // Format empty total summary
                    if (!ref[employeeID]) {
                        ref[employeeID] = { ...workTimes };
                    }

                    // Format empty week split
                    if (!refByWeek[employeeID]) {
                        refByWeek[employeeID] = {}
                    }

                    // Format empty week on week split
                    const weekNumber = moment(workday.date).format("W");
                    if (!refByWeek[employeeID][weekNumber]) {
                        refByWeek[employeeID][weekNumber] = { total : 0, normal: 0, evenings: 0, nights: 0, saturdays: 0, sundays: 0, workdays: [] }
                    }

                    //get total time from workday - this should exist, if logged times or day correctly
                    let eveningTime = 0, nightTime = 0, totalTime = 0, saturdayTime = 0, sundayTime = 0, pauseTime = 0;

                    totalTime = workday.duration ? (workday.duration ) : workday.endDT && workday.startDT ? (moment(workday.endDT) - moment(workday.startDT))  : 0;

                    //get worklogs for this employee and for this date

                    
                    const wLogs = getWorkdayWorklogs(workday.date, this.props.worklogs, employeeID, this.props.company);

                    /* this all don in "createEmptyDaysForPeriod"
                    //if workday not found - means no work or worktime not logged propely
                    if (totalTime === 0 && (isEmpty(workday.endDT) || isEmpty(workday.startDT))) {
                        //if day is not started or ended properly, try checking the logs if there is any
                        const estimation = getDayStartAndEndTimeFromWorklogs(wLogs)
                        console.log("got getDayStartAndEndTimeFromWorklogs workday times estimation for ", workday.date, estimation)
                        if (estimation.startTime && estimation.endTime) {

                            if (isEmpty(workday.startDT) || (!isEmpty(workday.startDT) && moment(estimation.startTime).isBefore(moment(workday.startDT)))) workday.startDT = estimation.startTime;
                            if (isEmpty(workday.endDT) || (!isEmpty(workday.endDT) && moment(estimation.endTime).isAfter(moment(workday.endDT)))) workday.endDT = estimation.endTime;

                            workday.duration = (moment(workday.endDT) - moment(workday.startDT))
                            totalTime = workday.duration 
                        }
                    }
                    */  

                    if (totalTime > 0) {
                        // get pauses and day-breaks from worklogs, checks overlapping pauses beween day and worklog
                        workday = findPausesFromWorklogsAndAddToWorkday(workday, wLogs);

                        console.log("totalTime for employee ", employeeID, totalTime, workday, weekNumber)
                        eveningTime = calculateEveningTime(workday, this.props.company);
                        console.log("evening time 1 before deductions for employee ", employeeID, eveningTime, workday)

                        if (workday.pauses) {
                            let eveningPauseDuration = calculateEveningPauses(workday, this.props.company);
                            console.log("evening pauses duration ", eveningPauseDuration);
                            eveningTime -= eveningPauseDuration;
                        }
                        console.log("evening time 2 for employee ", employeeID, eveningTime)

                        nightTime = calculateNightTime(workday, this.props.company)

                        if (workday.pauses) {
                            let nightPauseDuration = calculateNightPauses(workday, this.props.company);
                            console.log("night pauses duration ", nightPauseDuration);
                            nightTime -= nightPauseDuration;
                        }


                        console.log("nightTime for employee ", employeeID, nightTime)

                        //daytime pauses, deducts normal time
                        pauseTime = 0; // own errands = unpaid pauses
                        if (workday.pauses) {
                            pauseTime = calculateDaytimePauses(workday, this.props.company)

                            console.log("daytime pauses duration ", pauseTime);
                        }

                        saturdayTime = moment(workday.date).format('dddd') === "Saturday" ? totalTime - pauseTime : 0;
                        sundayTime = moment(workday.date).format('dddd') === "Sunday" ? totalTime - pauseTime : 0;

                        console.log("saturdayTime for employee ", employeeID, saturdayTime)
                        console.log("sundayTime for employee ", employeeID, sundayTime)
                    }
                    // less pauses - deduct any of the times if not payable

                    const normalTime = totalTime - eveningTime - nightTime - saturdayTime - sundayTime - pauseTime;
                    const totalWork = normalTime + eveningTime + nightTime + saturdayTime + sundayTime;

                    console.log("normalTime and totalwork for employee ", employeeID, normalTime, totalWork)

                    // Forms total 
                    ref[employeeID].total += totalWork; //should be all time without pauses
                    ref[employeeID].normal += normalTime;
                    ref[employeeID].evenings += eveningTime;
                    ref[employeeID].nights += nightTime;
                    ref[employeeID].saturdays += saturdayTime;
                    ref[employeeID].sundays += sundayTime;

                    // Split worked hours by week
                    refByWeek[employeeID][weekNumber].total += totalWork;
                    refByWeek[employeeID][weekNumber].normal += normalTime;
                    refByWeek[employeeID][weekNumber].evenings += eveningTime;
                    refByWeek[employeeID][weekNumber].nights += nightTime;
                    refByWeek[employeeID][weekNumber].saturdays += saturdayTime;
                    refByWeek[employeeID][weekNumber].sundays += sundayTime;


                    const simpleWorkday = {
                        date: workday.date,
                        employee: workday.employee,
                        startDT: workday.startDT,
                        endDT: workday.endDT,
                        duration: totalTime,
                        normal: normalTime,
                        evening: eveningTime,
                        night: nightTime,
                        saturday: saturdayTime,
                        sunday: sundayTime,
                        pauses: pauseTime,

                    };

                    refByWeek[employeeID][weekNumber].workdays.push(simpleWorkday);

                }
            }
            
        });

        // Combine two object
        Object.keys(ref).forEach(id => {
            ref[id]["normalByWeek"] = refByWeek[id];
        });


        ///employee = this.props.employees.find(employee => employee.name === searchData.employee);
        if (!isEmpty(employee)) {
            const employeeRef = {};
            if (ref[employee.id]) employeeRef[employee.id] = ref[employee.id];
            this.props.onSearch(employeeRef);
        }
        else {
            this.props.onSearch(ref);
        }

        this.setState({ loading: false }, () => this.props.onWorkDaysProcessed())

    };

    render() {
        const { searchData, menu, anchor, loading } = this.state;
        const { user } = this.props.user;
        const { t, i18n } = this.props;

        return (
            <div style={{ width: '100%' }}>
                <AutocompleteReports
                    searchArray={this.props.employees}
                    field="Employee"
                    label={this.props.t("Search employees")}
                    onSelect={event => this.onDataChange(event, "employee")}

                />

                <TextField
                    variant="outlined"
                    label={t("Select range")}
                    style={{
                        width: '100%',
                        marginTop: 10
                    }}
                    value={`${moment(this.props.dateRange.selection.startDate).locale(user.lang ? user.lang : "fi").format('L')} - ${moment(this.props.dateRange.selection.endDate).locale(user.lang ? user.lang : "fi").format('L')}`}
                    onClick={event => this.handleMenu(event.currentTarget)}
                />
                {/* this.props.errors.date.length > 0 && <p style={{color: '#B00', marginLeft: 60}}>{this.props.errors.date}</p> */}

                {menu &&
                    <ClickAwayListener onClickAway={this.closeMenu}>
                        <div
                            style={{
                                position: 'absolute',
                                top: anchor.y + anchor.height,
                                left: anchor.x,
                                zIndex: 10,
                                backgroundColor: '#FFF',
                                width: anchor.width - 2,
                                borderStyle: 'solid',
                                borderColor: '#ccc',
                                borderWidth: 1,
                                borderRadius: 4,
                            }}
                        >
                            <div style={{ marginLeft: 30, marginTop: 10 }}>
                                <DateRange
                                    moveRangeOnFirstSelection={false}
                                    ranges={[this.props.dateRange.selection]}
                                    style={{
                                        width: '100%',
                                    }}
                                    locale={i18n.language === "fi" ? fi : enGB}
                                    onChange={event => this.handleDate(event)}
                                />
                            </div>


                        </div>
                    </ClickAwayListener>
                }

                {/*
                    <div style={{textAlign: 'center', marginTop: 20}}>
                        <Button style={{marginRight: 5}} onClick={event => this.setState({
                            datePicker: !this.state.datePicker,
                            anchor: event.currentTarget,
                            pickField: "start"
                        })}>
                            {moment(searchData.start).locale(user.lang ? user.lang : "fi").format('L')}
                        </Button>
                        -
                        <Button style={{marginLeft: 5}} onClick={event => this.setState({
                            datePicker: !this.state.datePicker,
                            anchor: event.currentTarget,
                            pickField: "end"
                        })}>
                            {moment(searchData.end).locale(user.lang ? user.lang : "fi").format('L')}
                        </Button>
                    </div>
                */}

                {/* 
                <div style={{marginLeft: 65}}>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={searchData.accuracy === "duration"}
                                onChange={() => this.onDataChange("duration", "accuracy")}
                                color="primary"
                            />
                        }
                        label={this.props.t("ByExactTime")}
                    />

                    <FormControlLabel
                        control={
                            <Radio
                                checked={searchData.accuracy === "billable"}
                                onChange={() => this.onDataChange("billable", "accuracy")}
                                color="primary"
                            />
                        }
                        label={this.props.t("ByBillableTime")}
                    />

                </div>
                */
                }

                <div style={{ textAlign: 'center', marginTop: 20 }}>
                    <Button
                        variant="outlined"
                        onClick={this.props.onReset}
                        disabled={loading}
                    >
                        {this.props.t("Reset")}
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: 20 }}
                        onClick={() => this.formSearchList()}
                        disabled={loading}
                    >
                        {this.props.t("Search")}
                    </Button>

                    {loading &&
                        <CircularProgress />
                    }

                </div>

                {/*this.state.datePicker &&
                <DateMenuComponent
                    anchor={this.state.anchor}
                    pickField={this.state.pickField}
                    searchData={searchData}
                    onSelect={this.onDataChange}
                />
                */}
            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(WorktimeComponent));