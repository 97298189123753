import moment from 'moment';

export class DataSearch {

    _searchArray = [];
    _searchData = [];

    setSearchData(searchArray) {
        this._searchArray = searchArray;
        return this;
    }

    appendField(searchField, searchValue, actionType) {
        const newSearchData = {
            searchField,
            searchValue,
            actionType
        };

        this._searchData.push(newSearchData);
    }

    sortFinalData = (sortDir, field, sortArr) => {

        let results = sortArr;

        if (sortDir && field) {
            if (sortDir === "asc") {
                results = results.sort((a, b) => {
                    if (a[field] < b[field]) return -1;
                    if (a[field] > b[field]) return 1;
                })
            }
            else {
                results = results.sort((a, b) => {
                    if (a[field] > b[field]) return -1;
                    if (a[field] < b[field]) return 1;
                })
            }
        }

        return results;
    };

    executeSearch(sortDir, field) {
        //console.log("executeSearch  _searchData ", this._searchData);
        //console.log("executeSearch 0  searchValue ", this._searchData[0].searchValue, this._searchData.length);
        
        /*if (!this._searchData.some(data => data.searchValue.length > 0)) {    
            console.log("executeSearch return " );
            return this.sortFinalData(sortDir, field, this._searchArray);
        }*/

        let results = JSON.parse(JSON.stringify(this._searchArray));
        //console.log("executeSearch 1 data to search from  ", results);

        this._searchData.forEach(dataRow => {
            console.log(" actiontype " + dataRow.actionType+ " dataRow.searchField " + dataRow.searchField + " dataRow.searchValue ", dataRow.searchValue)

            // Action type === "search"
            if (dataRow.actionType === "search") {
                results = results.filter(item => item[dataRow.searchField].toLowerCase().search(dataRow.searchValue.toLowerCase()) > -1);
            }

            // Action type === "includes"
            if (dataRow.actionType === "includes") {
                results = results.filter(item => item[dataRow.searchField].includes(dataRow.searchValue));
            }

            // Action type === "!includes"
            if (dataRow.actionType === "!includes") {
                results = results.filter(item => !item[dataRow.searchField].includes(dataRow.searchValue));
            }

            // Instead of checking if item[field] includes, check if searchValue includes item[field]
            if (dataRow.actionType === "searchValueIncludes") {
                results = results.filter(item => dataRow.searchValue.includes(item[dataRow.searchField]));
            }

            // Action type === "isEqual"
            if (dataRow.actionType === "isEqual") {
                results = results.filter(item => item[dataRow.searchField] === dataRow.searchValue);
            }

            // Action type === "includes"
            if (dataRow.actionType === "notEqual") {
                results = results.filter(item => item[dataRow.searchField] !== dataRow.searchValue);
            }

            // Action type === "between"
            if (dataRow.actionType === "between") {
                console.log("executeSearch between 1 ", dataRow.searchValue.selection.startDate, " - ", dataRow.searchValue.selection.endDate)
                const startMoment = moment(dataRow.searchValue.selection.startDate);
                const endMoment = moment(dataRow.searchValue.selection.endDate);
                console.log("executeSearch between 2 ", startMoment, " - ", endMoment )
                results = results.filter(item => {
                    console.log(" => " , moment(item[dataRow.searchField]).isBetween(startMoment, endMoment, undefined, '[]'))  //Version 2.13.0 introduces inclusivity. A [ indicates inclusion of a value. A ( indicates exclusion. If the inclusivity parameter is used, both indicators must be passed.
                    return moment(item[dataRow.searchField]).isBetween(startMoment, endMoment, undefined, '[]')
                })
            }
            /*
            if (dataRow.actionType === "betweenDateRange") {
                console.log("executeSearch betweenDateRange 1 ", dataRow.searchValue.selection.startDate, " - ", dataRow.searchValue.selection.endDate)
                const startMoment = moment(dataRow.searchValue.selection.startDate);
                const endMoment = moment(dataRow.searchValue.selection.endDate);
                console.log("executeSearch betweenDateRange 2 ", startMoment, " - ", endMoment )
                results = results.filter(item => {
                    console.log(" => " , moment(item[dataRow.searchField]).isBetween(startMoment, endMoment))
                    return moment(item[dataRow.searchField]).isBetween(startMoment, endMoment)
                })
            }*/

        });

        return this.sortFinalData(sortDir, field, results);

    }
}
