import React, {Component} from 'react';

import Autocomplete from 'react-autocomplete';

import {
    MenuItem,
    Divider,
    TextField
} from '@material-ui/core';

import "./JobModelEditor.css";

class AutocompleteComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: ""
        };


    }

    renderSearch = (props) => {
        const {InputProps, ref, ...other} = props;

        return <TextField
            InputProps={{
                disableUnderline: true,
                inputRef: ref,
                ...InputProps}}
            {...other}
            fullWidth
        />
    };

    renderVariantSearch = (props) => {
        const {InputProps, ref, ...other} = props;
        return <TextField
            placeholder={this.props.placeholder ? this.props.placeholder : ""}
            variant="outlined"
            label={this.props.label ? this.props.label : ""}
            InputProps={{
                inputRef: ref,
                ...InputProps}}
            {...other}
            style={{
                width: 355
            }}
        />
    };

    render() {
        return (
            <Autocomplete
                items={this.props.searchArray}
                getItemValue={(item) => item.name}
                shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) >-1}
                renderMenu={children => (
                    <div style={{backgroundColor: '#FFF', position: 'absolute', zIndex: 10, width: 500, borderWidth: 0.25, borderStyle: 'solid', borderColor: '#ccc'}}>
                        {children}
                    </div>
                )}
                renderItem={(item) =>
                    <div key={item.id}>
                        <MenuItem>{this.props.addNumber ? `${item.pNumber}, ${item.name}` : item.name}</MenuItem>
                        <Divider style={{backgroundColor: '#ccc', width: 500}}/>
                    </div>

                }
                renderInput={this.props.useVariant ? this.renderVariantSearch : this.renderSearch}
                value={this.state.searchTerm}
                onChange={event => this.setState({searchTerm: event.target.value})}
                onSelect={event => this.props.onSelect(event, this.props.rowID)}
            />
        );
    }
}


export default AutocompleteComponent;