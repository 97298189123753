import React, { Component } from 'react';

import {
    UtilityBar,
    ListAreaHeader,
    ListAreaItem,
    ListAreaBody,
    FilterMenu,
    SelectButton,
    ConfirmDialog
} from "../ListComponents";

import {
    IconButton,
    ClickAwayListener,
} from "@material-ui/core";

import {
    Add,
    Close
} from '@material-ui/icons';

import { Transition, config } from "react-spring/renderprops";

import FilterMenuContents from './FilterMenuContents';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faLongArrowAltDown, faLongArrowAltUp } from "@fortawesome/free-solid-svg-icons";

import { DataSearch } from "../util/DataSearch";

import firebase from 'firebase/app';
import 'firebase/firestore';

import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

import "./ProductList.css";

class ProductList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            searchTerm: "",
            products: [],
            selectedProducts: [],
            productCopy: [],
            sortOrder: {
                dir: "desc",
                field: "pNumber"
            },
            filterAnchor: null,
            filterField: "",
            filterValues: [{ type: "true", field: "active" }],
            addNew: false,
            selection: false,
            selectAll: false,
            menuActions: [
                { action: "active", methods: ["Yes", "No"] },
                { action: "type", methods: ["material", "service", "checkpoint", "travel"] }
            ],
            confirmObj: {},
            confirmDialog: false,
            saveLoading: false,
            saveComplete: false
        }
    }

    componentDidMount() {
        const { user } = this.props.user;
        this.unsubProducts = firebase.firestore().collection("company").doc(user.companyID).collection("products").onSnapshot(querySnap => {
            let products = [];

            querySnap.forEach(doc => {
                const product = doc.data();
                product.id = doc.id;
                product.isActive = product.isActive ? product.isActive.toString() : "false";
                if (!product.isBillable) { console.log('NOTE: PRODUCT ' + product.pNumber + ' MISSING BILLABLE INFOQ') }
                product.isBillable = product.isBillable ? product.isBillable.toString() : "false";
                if (!product.isDeleted) products.push(product);
            });

            this.setState({
                products,
                productCopy: JSON.parse(JSON.stringify(products)),
                loading: false
            }, () => this.filterProducts(null, null))
        })
    }

    componentWillUnmount() {
        this.unsubProducts && this.unsubProducts();
    }

    getTypeText = type => {
        const { t } = this.props;
        switch (type) {
            case "false":
                return t("Service");
            case "true":
                return t("Material");
            case "checkpoint":
                return t("Checkpoint");
            case "travel":
                return t("Travel");
            default:
                return ""
        }
    };

    searchProducts = event => {
        const searchTerm = event.target.value;

        const products = [];

        this.state.productCopy.forEach(product => {
            const searchTarget = product.pNumber + product.name;

            if (searchTarget.toLowerCase().search(searchTerm.toLowerCase()) > -1) products.push(product);
        });

        this.setState({ products, searchTerm });
    };

    sortProducts = (field, parse) => {
        let { sortOrder, products } = this.state;

        let direction = "";

        if (sortOrder.field === field) direction = sortOrder.dir === "asc" ? "desc" : "asc";
        else direction = "desc";

        products = products.sort((a, b) => {
            const aField = parse ? parseFloat(a[field]) : a[field];
            const bField = parse ? parseFloat(b[field]) : b[field];

            if (direction === "asc" ? aField > bField : aField < bField) return -1;
            if (direction === "asc" ? aField < bField : aField > bField) return 1;
        });


        sortOrder.dir = direction;
        sortOrder.field = field;

        this.setState({ sortOrder, products });
    };

    filterProducts = (type, field) => {
        const { productCopy, sortOrder, filterValues } = this.state;

        const newFilterValue = { type: type, field: field };

        const existingFilterValue = filterValues.find(value => value.type === newFilterValue.type && value.field === newFilterValue.field);

        if (type && field && !existingFilterValue) filterValues.push(newFilterValue);
        else if (type !== null && field !== null) {
            const removeIndex = filterValues.indexOf(existingFilterValue);
            filterValues.splice(removeIndex, 1);
        }

        const productFilter = new DataSearch();
        productFilter.setSearchData(productCopy);
        if (filterValues.some(value => value.field === "type")) productFilter.appendField("isMaterial", filterValues.filter(value => value.field === "type").map(value => value.type), "searchValueIncludes");
        if (filterValues.some(value => value.field === "billable")) productFilter.appendField("isBillable", filterValues.filter(value => value.field === "billable").map(value => value.type), "searchValueIncludes");
        if (filterValues.some(value => value.field === "active")) productFilter.appendField("isActive", filterValues.filter(value => value.field === "active").map(value => value.type), "searchValueIncludes");

        const results = productFilter.executeSearch(sortOrder.dir === "asc" ? "desc" : "asc", sortOrder.field);

        this.setState({
            products: results,
            filterValues
        });
    };

    toggleFilterMenu = (event, filterField) => {

        let newAnchor = event.currentTarget ? event.currentTarget.getBoundingClientRect() : null;

        this.setState({
            filterAnchor: newAnchor,
            filterField
        });
    };

    arrowDirection = field => {
        const { sortOrder } = this.state;

        if (sortOrder.field === field && sortOrder.dir === "desc") {
            return (
                <FontAwesomeIcon icon={faLongArrowAltUp} style={{ height: 15, width: 15 }} />
            );
        }
        else return <FontAwesomeIcon icon={faLongArrowAltDown} style={{ height: 15, width: 15 }} />
    };

    handleSelectButton = action => {
        if (action === "Add") {
            this.props.history.push("/registry/products");
            this.setState({ addNew: true });
        }
        else this.setState(prevState => ({ selection: !prevState.selection }))
    };

    handleProductSelection = id => {
        const { selectedProducts } = this.state;

        if (!selectedProducts.includes(id)) selectedProducts.push(id);
        else {
            const removeIndex = selectedProducts.indexOf(id);
            selectedProducts.splice(removeIndex, 1);
        }

        this.setState({ selectedProducts });
    };

    handleMassProductSelection = () => {
        const { selectAll, products } = this.state;

        let selectedProducts = [];

        if (selectAll) selectedProducts = [];
        else selectedProducts = products.map(product => product.id);

        this.setState(prevState => ({ selectedProducts, selectAll: !prevState.selectAll }));
    };

    handleMassActionSelect = (action, option) => {
        const { confirmObj } = this.state;
        const { t } = this.props;

        confirmObj.title = t("Attention");
        confirmObj.action = action;
        confirmObj.option = option;
        confirmObj.message = `${t("ChangeTo")} ${t("ProductsOwn")}`;

        if (action === "type") confirmObj.status = `${t("type")}: ${t(option)}`;
        if (action === "active") confirmObj.status = `${t("active")}: ${t(option)}`;

        this.setState({
            confirmObj,
            confirmDialog: true
        });
    };

    onMassActionConfirm = () => {
        const { confirmObj, selectedProducts, productCopy } = this.state;
        const { user } = this.props.user;

        const batch = firebase.firestore().batch();

        selectedProducts.forEach(id => {
            const product = productCopy.find(product => product.id === id);

            if (product) {
                if (confirmObj.action === "active") product.isActive = confirmObj.option === "Yes" ? "true" : "false";

                if (confirmObj.action === "type") {
                    if (confirmObj.option === "material") product.isMaterial = "true";
                    else if (confirmObj.option === "service") product.isMaterial = "false";
                    else product.isMaterial = confirmObj.option;
                }


                const productRef = firebase.firestore().collection("company").doc(user.companyID).collection("products").doc(product.id);
                batch.set(productRef, product)
            }
        });

        batch.commit().then(() => {
            this.setState({
                saveComplete: true,
                selectedProducts: [],
                selectAll: false
            });
        });
    };

    renderFilterItem = (filter, field, props) => {
        let value = "";

        if (field === "active") {
            if (filter === "true") value = "Aktiiviset";
            if (filter === "false") value = "Pois käytöstä";
        }

        if (field === "billable") {
            if (filter === "true") value = "Laskutettavat";
            if (filter === "false") value = "Ei-laskutettavat";
        }

        if (field === "type") {
            if (filter === "true") value = "Materiaalit";
            if (filter === "false") value = "Palvelut";
            if (filter === "checkpoint") value = "Tallennuspisteet";
            if (filter === "travel") value = "Matkat";
        }

        return (
            <div className="filterItem-container" style={props}>
                <p className="filterItem-text">{value}</p>
                <IconButton style={{ marginLeft: 10, padding: 7 }} onClick={() => this.filterProducts(filter, field)}>
                    <Close style={{ height: 10, width: 10 }} />
                </IconButton>
            </div>
        );
    };

    renderListItem = product => {
        let productVatPrice = product.sellPrice && product.vat ? `(${(parseFloat(product.sellPrice) * (1 + parseFloat(product.vat) / 100.0)).toFixed(2)})` : "";

        const width = window.innerWidth;

        let invoiceText = product.invoiceText;
        let name = product.name;
        if (width < 2170 && product.invoiceText) invoiceText = product.invoiceText.slice(0, 30) + '...';
        if (width < 1600 && product.name) name = product.name.slice(0, 30) + '...';

        if (this.state.selection) {
            return (
                <ListAreaBody key={product.id}>
                    {this.state.selection && <ListAreaItem variant="list" size="checkbox" checked={this.state.selectedProducts.includes(product.id)} show={this.state.selection} onChange={() => this.handleProductSelection(product.id)} />}
                    <ListAreaItem variant="list" size="small">{product.pNumber}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{name}</ListAreaItem>
                    <ListAreaItem variant="list" size="medium">{invoiceText}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{this.getTypeText(product.isMaterial)}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{product.isActive === "true" ? "K" : "E"}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{product.isBillable === "true" ? "K" : "E"}</ListAreaItem>
                    <ListAreaItem variant="list" size="medium">{`${product.sellPrice ? product.sellPrice : 0} ${productVatPrice}`}</ListAreaItem>
                </ListAreaBody>

            );
        }
        else return (
            <Link to={"/registry/products/edit/" + product.id} key={product.id} className="product-link">
                <ListAreaBody>
                    {this.state.selection && <ListAreaItem variant="list" size="checkbox" checked={""} show={this.state.selection} />}
                    <ListAreaItem variant="list" size="small">{product.pNumber}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{name}</ListAreaItem>
                    <ListAreaItem variant="list" size="medium">{invoiceText}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{this.getTypeText(product.isMaterial)}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{product.isActive === "true" ? "K" : "E"}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{product.isBillable === "true" ? "K" : "E"}</ListAreaItem>
                    <ListAreaItem variant="list" size="medium">{`${product.sellPrice ? product.sellPrice : 0} ${productVatPrice}`}</ListAreaItem>
                </ListAreaBody>
            </Link>
        )

    };


    render() {
        const { searchTerm, loading, products, filterAnchor, filterValues } = this.state;
        const { t } = this.props;

        return (
            <div className="product-content">

                <UtilityBar
                    t={t}
                    searchBar
                    searchTerm={searchTerm}
                    searchOnChange={event => this.searchProducts(event)}
                >
                    <div className="product-utility">
                        <div className="product-utility left">
                            <Transition
                                items={filterValues}
                                keys={item => item.type + item.field}
                                from={{ transform: "scale(0)" }}
                                enter={{ transform: "scale(1)" }}
                                leave={{ transform: "scale(0)" }}
                                config={config.stiff}
                            >
                                {item => props => this.renderFilterItem(item.type, item.field, props)}
                            </Transition>
                        </div>

                        <div className="product-utility right">
                            <SelectButton
                                onChange={this.handleSelectButton}
                                options={["Add", "Selection"]}
                            />

                        </div>
                    </div>
                </UtilityBar>



                <ListAreaHeader>
                    {this.state.selection &&
                        <ListAreaItem
                            variant="title"
                            size="checkbox"
                            style={{ marginLeft: 30 }}
                            show={this.state.selection}
                            checked={this.state.selectAll}
                            onChange={this.handleMassProductSelection}
                            onSelect={this.handleMassActionSelect}
                            menuActions={this.state.menuActions}
                        />
                    }


                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortProducts("pNumber")}>
                                {this.arrowDirection("pNumber")}
                            </IconButton>
                        }
                    >
                        {t("ProductNumber_short")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="large"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortProducts("name")}>
                                {this.arrowDirection("name")}
                            </IconButton>
                        }
                    >
                        {t("Name")}
                    </ListAreaItem>

                    <ListAreaItem variant="title" size="medium">{t("Invoice text")}</ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 10 }} onClick={event => this.toggleFilterMenu(event, "type")}>
                                <FontAwesomeIcon icon={faFilter} style={{ height: 15, width: 15 }} />
                            </IconButton>
                        }
                    >
                        {t("Type")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 10 }} onClick={event => this.toggleFilterMenu(event, "active")}>
                                <FontAwesomeIcon icon={faFilter} style={{ height: 15, width: 15 }} />
                            </IconButton>
                        }
                    >
                        {t("ProductActive")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 10 }} onClick={event => this.toggleFilterMenu(event, "billable")}>
                                <FontAwesomeIcon icon={faFilter} style={{ height: 15, width: 15 }} />
                            </IconButton>
                        }
                    >
                        {t("Generate bill")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="medium"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortProducts("sellPrice", true)}>
                                {this.arrowDirection("sellPrice")}
                            </IconButton>
                        }
                    >
                        {t("Price")}
                    </ListAreaItem>

                </ListAreaHeader>

                {!loading && products.map(this.renderListItem)}

                <div style={{ height: 100 }} />

                {Boolean(filterAnchor) &&
                    <ClickAwayListener onClickAway={() => this.toggleFilterMenu({}, this.state.filterField)}>
                        <FilterMenu
                            anchor={filterAnchor}
                        >
                            <FilterMenuContents
                                filterField={this.state.filterField}
                                filterValues={filterValues}
                                filterProducts={this.filterProducts}
                                t={this.props.t}
                            />

                        </FilterMenu>
                    </ClickAwayListener>

                }

                {this.state.confirmDialog &&
                    <ConfirmDialog
                        open={this.state.confirmDialog}
                        onClose={() => this.setState({ confirmDialog: false, saveComplete: false, sendLoading: false, confirmObj: { title: "", message: "", status: "", action: "", option: "" } })}
                        onConfirm={() => this.setState({ sendLoading: true }, () => this.onMassActionConfirm())}
                        title={this.state.confirmObj.title}
                        message={this.state.confirmObj.message}
                        status={this.state.confirmObj.status}
                        loading={this.state.saveLoading}
                        saveComplete={this.state.saveComplete}
                        comp={this.props.t("Products")}
                    />
                }

                {this.state.addNew &&
                    <Redirect to={"/registry/products/add"} />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(ProductList));



