import React from 'react';

import {ClickAwayListener} from "@material-ui/core";

const Menu = props => {
    const {anchor} = props;

    return (
        <ClickAwayListener onClickAway={() => props.onClose()}>
            <div
                style={{
                    position: "absolute",
                    top: anchor.height,
                    left: 0,
                    zIndex: 10,
                    backgroundColor: '#FFF',
                    borderRadius: 4,
                    width: anchor.width,
                    boxShadow: "0 3px 6px rgba(0, 0, 0, 0.2), 2px 2px 2px rgba(0, 0, 0, 0.2)"
                }}
            >
                {props.children}
            </div>
        </ClickAwayListener>
    );
};

export default Menu;
