import moment from "moment";

export const filterWithBillableTime = async (worklogs, products) => {
    const nonBillableProducts = products.filter(product => product.isBillable === "false").map(product => product.pNumber);

    const filteredLogs = [];

    worklogs.forEach(worklog => {
        if (worklog.status === "Cancelled" && worklog.billingPercent > 0.1 && worklog.invoiceText) filteredLogs.push(worklog);
        if (worklog.status !== "Cancelled" && worklog.billing && worklog.billing.billableHoursByTask) {
            if (worklog.billing.billableHoursByTask.some(task => !nonBillableProducts.includes(task.pNumber))) filteredLogs.push(worklog);
        }
    });

    return new Promise(resolve => resolve(filteredLogs));
};

export const checkInvoiceRows = async (invoiceRows, roundNumber) => {
    const returnRows = [];

    if (invoiceRows && !invoiceRows.some(invoiceRow => invoiceRow.manuallyAdded)) return returnRows;

    invoiceRows && invoiceRows.forEach(invoiceRow => {
        if (invoiceRow && invoiceRow.manuallyAdded) {
            const row = [
                {text: moment(invoiceRow.startDT).locale("fi").format("L"), alignment: "center", fontSize: 10},
                {text: " - ", alignment: 'center', fontSize: 10},
                {text: roundNumber(invoiceRow.quantity), alignment: 'center', fontSize: 10},
                {text: invoiceRow.invoiceText, alignment: "center", fontSize: 10},
            ];
            returnRows.push(row)
        }
    });
    return new Promise(resolve => resolve(returnRows));
};

export const formFromWorklogs = async (worklogs, models, products, getEmployee, roundNumber, t) => {
    const worklogRows = [];

    worklogs.forEach(worklog => {
        let template = models.find(template => template.id === worklog.templateID);

        if (!template) {
            const customerTemplates = models.filter(template => template.customer === worklog.customer);

            if (customerTemplates && customerTemplates.length === 1) template = customerTemplates[0];
            else {template = {tasks: []}}
        }

        if (worklog.previousStatus !== "Cancelled") {
            worklog.employee.forEach(employee => {
                worklog.billing.billableHoursByTask && worklog.billing.billableHoursByTask.forEach(task => {
                    const templateTask = template && template.tasks && template.tasks.find(tempTask => tempTask.name === task.name);
                    const product = products.find(product => product.pNumber === task.pNumber);

                    const invoiceText  = templateTask && templateTask.invoiceText ? templateTask.invoiceText : (product && product.invoiceText ? product.invoiceText : task.name);

                    const row1 = [
                        {text: moment(worklog.startDT).locale("fi").format("L"), alignment: "center", fontSize: 10},
                        {text: getEmployee(employee), alignment: "center", fontSize: 10 },
                        {text: roundNumber(task.duration / 3600000), alignment: "center", fontSize: 10},
                        {text: invoiceText, alignment: "center", fontSize: 10},
                    ];
                    if (task.duration > 0 && product?.isBillable === "true") worklogRows.push(row1)
                });
            });
        }
        if (worklog.previousStatus === "Cancelled" && worklog.billing && worklog.billing.billableHoursByTask) {
            const templateTask = worklog.billing.billableHoursByTask[0];

            const row1 = [
                {text: moment(worklog.startDT).locale("fi").format("L"), alignment: "center", fontSize: 10},
                {text: t("Cancelled"), alignment: "center", fontSize: 10 },
                {text: roundNumber((templateTask.duration / 3600000) * worklog.employee.length), alignment: "center", fontSize: 10},
                {text: worklog.invoiceText ? worklog.invoiceText : t("Billable cancellation") + ' ' + (worklog.billingPercent*100)+ '%', alignment: "center", fontSize: 10},
            ];

            worklogRows.push(row1);
        }

        worklog.materials && worklog.materials.forEach(material => {

            const templateTask = template && template.tasks && template.tasks.find(tempTask => tempTask.pNumber === material.pNumber);
            const product = products.find(product => product.pNumber === material.pNumber);

            const invoiceText  = templateTask && templateTask.invoiceText ? templateTask.invoiceText : (product && product.invoiceText ? product.invoiceText : material.name);

            const row = [
                {text: moment(worklog.startDT).locale("fi").format("L"), alignment: "center", fontSize: 10},
                {text: " - ", alignment: 'center', fontSize: 10 },
                {text: roundNumber(material.amount), alignment: 'center', fontSize: 10},
                {text: invoiceText, alignment: "center", fontSize: 10},
            ];

            if (product?.isBillable === "true") worklogRows.push(row);
        })
    });

    return new Promise(resolve => resolve(worklogRows));
};


