import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {ListItem, List, Grid, CircularProgress, Typography} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {AccountCircle} from "@material-ui/icons";
import Avatar from "@material-ui/core/Avatar";
import mouse from "./img/hiiri.jpg";
import {UISref, UISrefActive} from "@uirouter/react";
import ListItemText from "@material-ui/core/ListItemText";

import PropTypes from 'prop-types';


/* Redux */
import {connect} from 'react-redux';
import moment from "moment";


class AuditLogView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            auditLog: [],
            value: 0
        };
    }

    componentDidMount() {
        const {user} = this.props.user;
        //console.log('get audit logs for company ', user.companyID);
        this.unsubscribeAuditLogListener = firebase.firestore()
            .collection('audit_log').doc(user.companyID)
            .collection('logs')
            .onSnapshot(querySnapshot => {
                let auditEventList = [];

                querySnapshot.forEach(doc => {
                    if (doc.exists) {
                        let event = doc.data();
                        //console.log("Audit log event, ", event)
                        event.id = doc.id;
                        auditEventList.push(event)
                    } else { console.log("Audit log event NOT EXIST!")}
                });

                auditEventList.sort(function (a, b) {
                    if (moment(a.timestamp).isSameOrAfter(b.timestamp)) return -1;
                    if (moment(a.timestamp).isSameOrBefore(b.timestamp)) return 1;
                });

                this.setState({
                    auditLog: auditEventList,
                    loading: false,
                });
            })
    }

    componentWillUnmount() {

        if (this.unsubscribeAuditLogListener) {
            this.unsubscribeAuditLogListener();
        }

    }

    formatLogRow(log) {
        let row = '';

        //console.log("Audit log formatLogRow, ", log)

        if (log.logEventType == 'JOB') {
            //console.log('LOG: ', log.log);

            if (log.log.created) {
                const change = log.log.created;
                row += ' new job ' + change.title;
                row += ' for ' + change.customer;
                row += ' by ' + change.modifiedBy;
            }

            if (log.log.time_changed) {
                const change = log.log.time_changed;
                row += ' time_changed ' + change.startDate;
                row += ' for ' + change.endDate;
                row += ' by ' + change.modifiedBy;
            }

            if (log.log.status_changed) {
                const change = log.log.status_changed;
                row += ' status_changed ' + change.status_after;
            }

            if (log.log.tasks_changed) {
                const change = log.log.tasks_changed;
                row += ' tasks_changed ' + change.tasks_after;
            }


            if (log.log.employees_changed) {
                const change = log.log.employees_changed;
                row += ' employees changed ' + change.employees_after;
            }


        }
        if (log.logEventType == 'WORKLOG') {
            if (log.log.started) {
                row += ' Logging started ';
            }
            if (log.log.ended) {
                row += ' Logging ended ';
            }
            if (log.times_corrected) {
                row += ' Work times corrected manually ';

            }
            if (log.common_after) {
                row += ' by: ' + log.common_after.modifiedBy;
            }

            //undefined
            if (row == '') {
                row = ' undefined worklog change: ' + log.log;
            }
        }

        return row;
    }

    generateLog() {
        return this.state.auditLog.map(log => {
                return React.cloneElement(
                    <ListItem button>
                        <ListItemIcon>
                           <AccountCircle/>
                        </ListItemIcon>
                        <ListItemText inset={false}
                                      primary={
                                              <Typography variant="caption" style={{color: 'black'}}>
                                                  {moment(log.timestamp).format('LLL') + ' ' + log.logEventType}
                                              </Typography>

                                      }
                                      secondary={
                                          <Typography variant="caption" style={{color: '#ff6f00'}}>
                                              { this.formatLogRow(log) } 
                                          </Typography>

                                      }
                        />

                    </ListItem>, {
                        key: log.id,
                    });
            },
        );
    }


    handleChange = (event, value) => {
        this.setState({value});
    };

    render() {
        const {value} = this.state;
        return (
            <div>

                <Grid container style={{padding: 20}}>

                    <Grid item xs={12} md={12}>
                        <div>
                            {
                                this.state.loading &&
                                <CircularProgress/>
                            }

                            {
                                <List>
                                    {this.generateLog()}
                                </List>
                            }
                        </div>

                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(AuditLogView);