import React, {Component} from 'react';

import {
    TextField,
    Button
} from '@material-ui/core';

class CheckCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: ""
        };
    }

    render() {
        const { t } = this.props;
        return (
            <div style={{width: '100%'}}>
                <p style={{marginTop: 20}}>Käytä saamaasi rekisteröintikoodia</p>

                <TextField
                    variant="outlined"
                    style={{
                        width: 250,
                        marginTop: 10
                    }}
                    label={t("Code")}
                    value={this.state.code}
                    onChange={event => this.setState({code: event.target.value})}
                />

                <Button
                    variant="outlined"
                    color="primary"
                    style={{
                        marginTop: 10,
                        marginLeft: 10,
                        height: 55
                    }}
                    onClick={() => this.props.onValidate(this.state.code)}
                >
                    {t("Validate")}
                </Button>
            </div>
        );
    }

}

export default CheckCode;