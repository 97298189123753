import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";

import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';

class AuditLogComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
            auditLogs: [],
            employees: [],
            customers: [],
        };
    }

    componentDidMount() {
        const {user} = this.props.user;

        this.unsubscribeCustomerListener = firebase.firestore().collection("customers")
            .where("companyID", "array-contains", user.companyID).onSnapshot(querySnap => this.onQuerySnap(querySnap, "customers"));

        this.unsubscribeEmployeeListener = firebase.firestore().collection("employees")
            .where("companyID", "==", user.companyID).onSnapshot(querySnap => this.onQuerySnap(querySnap, "employees"));

        this.unsubscribeLogAuditListener = firebase.firestore().collection("audit_log").doc(user.companyID).collection("logs")
            .where("logEventType", "==", "WORKLOG")
            .where("modifiedTime", ">=", moment().subtract(1, "month").format("YYYYMMDDTHHmmss"))
            //.where("logEventType", "==", "JOB")
            .orderBy("modifiedTime", "desc")
            .onSnapshot(querySnap => {
            const auditLogs = [];

            querySnap.forEach(doc => {
                const data = doc.data();
                data.id = doc.id;
                auditLogs.push(data);
            });

            //console.log("Audit logs: ", auditLogs)

            const sortedArray = auditLogs.sort((a, b) => {
                if (moment(a.modifiedTime).isBefore(b.modifiedTime)) return 1;
                if (moment(a.modifiedTime).isAfter(b.modifiedTime)) return -1;
            });

            this.setState({
                auditLogs: sortedArray
            });
        });

    }

    componentWillUnmount() {
        this.unsubscribeCustomerListener && this.unsubscribeCustomerListener();
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
        this.unsubscribeLogAuditListener && this.unsubscribeLogAuditListener();
    }

    onQuerySnap = (querySnap, array) => {
        const dataArr = [];

        querySnap.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;

            if (array === "employees") {
                data.name = `${data.firstName} ${data.familyName}`
            }
            dataArr.push(data);
        });

        this.setState({
            [array]: dataArr
        });
    };

    getCustomer = id => {
        const {customers} = this.state;

        const customer = customers.find(customer => customer.id === id);

        return customer && customer.name ? customer.name : "Asiakasta ei löytynyt"
    };

    getEmployee = id => {
        const {employees} = this.state;

        const employee = employees.find(employee => employee.id === id);

        return employee && employee.name ? employee.name : id;  //this is a workaround for the problem where modified.user is person name (should be id)
    };

    getWorksite = id => {
        const {worksites} = this.props.worksites;

        const worksite = worksites.find(worksite => worksite.id === id);

        return worksite && worksite.name ? worksite.name : this.props.t("No_worksite")
    };

    getWorklogChange = item => {
        const {t} = this.props;

        return (
            <div>
                <p style={{paddingTop: 5, paddingBottom: 0, marginBottom: 3, fontWeight: 'bold'}}>{`${this.getEmployee(item.modifiedBy)} ${t(item.description)}`}</p>
                <p style={{padding: 0, margin: 0}}>{`${t("Customer")}: ${this.getCustomer(item.customer)}`}</p>
                <p style={{padding: 0, margin: 0}}>{`${t("Worksite")}: ${this.getWorksite(item.worksite)}`}</p>
            </div>
        );
    };


    renderItem = item => {
        const {user} = this.props.user;
        return (
            <div style={{display: 'flex'}} key={item.id}>
                <div style={{width: 100, textAlign: 'center'}}>
                    <p style={{fontSize: 18, fontWeight: 'bold'}}>{moment(item.modifiedTime).format("LT")}</p>
                    <p style={{fontSize: 12}}>{moment(item.modifiedTime).locale(user.lang ? user.lang : "fi").format("L")}</p>
                </div>

                <div>
                    {/*If worklog startd*/}
                    {item.logEventType === "WORKLOG" && this.getWorklogChange(item)}
                </div>
            </div>
        )
    };

    render() {
        return (

            <div>
                {this.state.auditLogs.map(this.renderItem)}
            </div>

        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    worksites: state.worksites
});

export default connect(mapStateToProps, {})(withTranslation()(AuditLogComponent));