import React, { Component } from 'react';

import ModuleUserListItem from './ModuleSettignsComponents/ModuleUserListItem';
import ModulePrice from './ModuleSettignsComponents/ModulePrice';
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Paper, Button } from '@material-ui/core';

import "./ModuleSettings.css";

class ModuleSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: this.props.company,
            employees: this.props.employees,
            changedEmployees: [],
            loading: false,
            itemOpen: [],
            userOpen: "",
            selectedUser: {}
        };
    }


    openUser = user => {
        let { userOpen, selectedUser } = this.state;

        if (userOpen === user.id) {
            userOpen = null;
            selectedUser = {};
        }
        else {
            userOpen = user.id;
            selectedUser = user
        }

        this.setState({
            userOpen: userOpen,
            selectedUser: selectedUser
        });
    };

    openItem = name => {
        const { itemOpen } = this.state;

        if (!itemOpen.includes(name)) {
            itemOpen.push(name)
        }
        else {
            const itemIndex = itemOpen.indexOf(name);
            itemOpen.splice(itemIndex, 1)
        }

        this.setState({
            itemOpen: itemOpen
        });
    };

    onPermissionChange = employee => {
        const { employees, changedEmployees } = this.state;

        // Change value in array as well
        const replaceIndex = employees.indexOf(employee);
        employees[replaceIndex] = employee;

        // Push data to changedEmployees array, this way we dont have to update every employee in database
        if (!changedEmployees.includes(employee.id)) {
            changedEmployees.push(employee.id);
        }

        // Return data to Settings
        //this.props.updateUsers(employees, changedEmployees);

        this.setState({
            selectedUser: employee,
            employees: employees
        });
    };

    render() {
        const { t } = this.props;
        const { loading, employees } = this.state;
        return (
            <div className="module-container">
                {!loading &&
                    <div className="module-display">
                        <div style={{ marginBottom: 20 }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                component={Link}
                                to={"/registry/employees/add"}
                            >
                                {t("Add")}
                            </Button>
                        </div>
                        <div className="module-display-userHeader">
                            <p>{t("Name")}</p>
                            <p className="list-item-center">{t("Main user")}</p>
                            <p className="list-item-center">{t("Management_settings")}</p>
                            <p className="list-item-center">{t("Time")}</p>
                            <p className="list-item-center">{t("Reports")}</p>
                            <p className="list-item-center">{t("Billing")}</p>
                            <p className="list-item-center">{t("CRM")}</p>
                            <p className="list-item-center">{t("Keys")}</p>
                            <p className="list-item-center">{t("SpotOn")}</p>
                            <p className="list-item-center">{t("Registry")}</p>

                        </div>

                        {employees.map(employee => {
                            return employee.isActive === "true" ? (
                                <ModuleUserListItem
                                    employee={employee}
                                    openUser={this.openUser}
                                    userOpen={this.state.userOpen}
                                    selectedUser={this.state.selectedUser}
                                    itemOpen={this.state.itemOpen}
                                    openItem={this.openItem}
                                    onChange={this.onPermissionChange}
                                    key={employee.id}
                                />
                            ) : null
                           })
                        }

                    </div>
                }

                {!loading &&
                    <div className="module-price-display">
                        <h4>{t("Price")}</h4>

                        <div>
                            <ModulePrice
                                employees={this.state.employees}
                            />
                        </div>
                    </div>
                }

                <Paper className="settings-footer">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.props.onSave(this.state.employees, this.state.changedEmployees)}
                    >
                        {t("Save")}
                    </Button>
                </Paper>
            </div>
        );
    }

}

export default withTranslation()(ModuleSettings);


