import React, {useState, useEffect} from 'react';

import classNames from 'classnames';

import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import Menu from './Menu';

import './TextField.css';
const TextField = props => {
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [currentValue, setCurrentValue] = useState("");

    useEffect(() => {
        let value = "";
        props.children && props.children.forEach(child => {
            if (child.props.value === props.value) value = child.props.children;
        });

        setCurrentValue(value);
    }, []);


    const handleClick = event => {
        const anchor = event.currentTarget.getBoundingClientRect();
        if (menuAnchor === null) setMenuAnchor(anchor);
        else setMenuAnchor(null)
    };

    const changeValueAndUpdate = value => {
        let newValue = "";

        props.children && props.children.forEach(child => {
            if (child.props.value === value) newValue = child.props.children;
        });

        setMenuAnchor(null);
        setCurrentValue(newValue);
        props.onChange(value);
    };

    const inputName = classNames({
        "textfield-container": true,
        "textfield-select": props.select,
        "textfield-error": props.error
    });

    return (
        <div className={inputName} style={props.containerStyles} onClick={event => props.select ? handleClick(event) : ""}>
            {!props.select &&
                <input
                    type="text"
                    value={props.value}
                    onChange={props.onChange}
                    style={props.inputStyles}
                />
            }


            {/*  Below this point is required items for select  */}
            {props.select &&
                <div className="textfield-select-input" >
                    {currentValue}
                </div>
            }


            {props.select &&
                <FontAwesomeIcon icon={faAngleDown} style={{height: 15, width: 15, marginTop: 3}} />
            }


            {Boolean(menuAnchor) &&
                <Menu
                    anchor={menuAnchor}
                    onClose={() => setMenuAnchor(null)}
                >
                    {props.children && props.children.map((child, index) => (
                        <div onClick={() => changeValueAndUpdate(child.props.value)} key={index}>{child}</div>
                    ))}
                </Menu>
            }

        </div>
    )
};

export default TextField;
