import React from "react";
import ReactDOM from "react-dom";
import './i18n'
import "./index.css";
import App from "./App";

import firebase from 'firebase/app';
//import 'firebase/firestore';

import { BrowserRouter } from "react-router-dom";

import keys from './util/keys';


// If NODE_ENV === development use test server
// If NODE_ENV === production use production server

/* NOTE if running locally, change this to development, else production*/
/* if (process.env.NODE_ENV === "production") { */

if (process.env.REACT_APP_ENV === "development") {
    firebase.initializeApp(keys.fConfig)
} else {
    firebase.initializeApp(keys.fProduction);
}

// FOR NOW AS RUNNING LOCALLY TO PRODUCTION -  USE PRODUCTION ENV SETTINGS
// firebase.initializeApp(keys.fProduction);

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById("root")
);
