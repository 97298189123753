import {GET_MODELS} from "../actions/types";

const initialState = {
    loading: true,
    models: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MODELS:
            return {
                loading: false,
                models: action.payload
            };
        default:
            return state;
    }
}
