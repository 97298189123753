import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    ListItemText,
    ListItem,
} from '@material-ui/core';

import {
    Close,
} from '@material-ui/icons';

import moment from 'moment';
import "moment/locale/fi";
const EditOptionDialog = props => {

    const getEmployee = employees => {

        if (employees.length === 1) {
            const employee = props.employees.find(employee => employee.id === employees[0]);

            return `${employee.firstName} ${employee.familyName}`
        }
        else {
            employees.forEach((employee, index) => {
                 console.log(index)
            });
        }

    };

    const generate = (items) => {
        return items.map(item => {
            return React.cloneElement(
                <ListItem button key={item.id} onClick={() => props.onSelect(item)}>
                    <ListItemText
                        primary={`${moment(item.startDT).format('LT')} - ${moment(item.endDT).format('LT')}`}
                        secondary={getEmployee(item.employee)}
                    />
                </ListItem>
            )
        })
    };

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            maxWidth="xs"
            fullWidth
        >
            <div style={{display: 'flex', width: '100%'}}>
                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                    <DialogTitle>{moment(props.selectedJob.startDT).locale("fi").format('L')}</DialogTitle>
                </div>
                <div style={{flexGrow: 1}}>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: 10}}>
                        <IconButton onClick={() => props.onClose()}>
                            <Close/>
                        </IconButton>
                    </div>
                </div>
            </div>
            <DialogContent style={{height: 300}}>
                <div>
                    {props.jobWorklogs.length > 0 && generate(props.jobWorklogs)}
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default EditOptionDialog