import React, {Component} from 'react';

import {withTranslation} from "react-i18next";

import {
    Dialog,
    DialogContent,
    DialogActions,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    Button,
    TextField
} from "@material-ui/core";

class PaymentConditionDialog extends  Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentCondition: {},
            loading: true
        };
    }

    componentDidMount() {
        const paymentCondition = this.props.company.settings.billing.paymentCondition;

        paymentCondition.daysArray.push(0);

        this.setState({
            paymentCondition: paymentCondition,
            loading: false
        });
    }

    changePaymentConditionDays = (event, index) => {
        const {paymentCondition} = this.state;

        paymentCondition.daysArray[index] = event.target.value;

        if (paymentCondition.daysArray[paymentCondition.daysArray.length -1] > 0) {
            paymentCondition.daysArray.push(0)
        }

        this.setState({
            paymentCondition: paymentCondition
        });
    };

    changePaymentConditionDefault = event => {
        const {paymentCondition} = this.state;

        paymentCondition.default = event;

        this.setState({
            paymentCondition: paymentCondition
        });
    };

    onSave = () => {
        const {paymentCondition} = this.state;

        const sorted = paymentCondition.daysArray.sort(function (a, b) {
            if (a > b) return 1;
            if (a < b) return -1;
        });

        paymentCondition.daysArray = sorted.map(day => parseInt(day));

        const company = this.props.company;

        company.settings.billing.paymentCondition = paymentCondition;

        this.props.onChange(company);
    };

    renderTextField = index => {
        return (
            <TextField
                style={{width: 40}}
                InputProps={{
                    disableUnderline: true,
                }}
                type="number"
                value={this.state.paymentCondition.daysArray[index]}
                onChange={event => this.changePaymentConditionDays(event, index)}
            />
        )
    };

    render() {
        const {t} = this.props;
        const {paymentCondition} = this.state;
        return (
            <Dialog
                open={this.props.open}
                onClose={() => this.props.onClose()}
                maxWidth="sm"
            >
                {!this.state.loading &&
                    <DialogContent>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{borderColor: '#ccc', borderStyle: 'solid',borderWidth:0, borderRightWidth: 1, borderBottomWidth: 1}}>{t("Days")}</TableCell>
                                    <TableCell>{t("Default")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {paymentCondition.daysArray.map((day, index) => (
                                    <TableRow key={day}>
                                        <TableCell style={{borderColor: '#ccc', borderStyle: 'solid', borderWidth: 0, borderRightWidth: 1, borderBottomWidth: 1}}>
                                            {this.renderTextField(index)}
                                        </TableCell>
                                        <TableCell>
                                            <Checkbox
                                                checked={paymentCondition.default === day}
                                                style={{
                                                    color: "#1770B2"
                                                }}
                                                onChange={() => this.changePaymentConditionDefault(day)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                }
                <DialogActions style={{display:' flex', justifyContent: 'space-around'}}>
                    <Button
                        variant="outlined"
                        onClick={() => this.props.onClose()}
                    >
                        {t("Cancel")}
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.onSave()}
                    >
                        {t("Close")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

}

export default withTranslation()(PaymentConditionDialog);