import React, { Component } from "react";
import { connect } from "react-redux";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

import {
  Paper,
  TextField,
  MenuItem,
  Button,
  Tabs,
  ClickAwayListener,
  Tab,
  Typography,
  Dialog,
  CircularProgress,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

import SearchListComponent from "./SearchListComponent";
import SendApproval from "../TimeReports/SendApproval";
import CustomerComponent from "./CustomerComponent";
import PeriodComponent from "./PeriodComponent";

import PDFCustomerKTV from "./PDFCustomerKTV";
import PDFCompanyBillSummary from "./PDFCompanyBillSummary";

import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import moment from "moment";
import "moment/locale/fi";
import PropTypes from "prop-types";

import { resetSend } from "../../actions/sendReportActions";

import { DateRange } from "react-date-range";
import { fi, enGB } from "date-fns/locale";

function TabContainer(props) {
  return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

/*
 *
 * pohja otettu Timerposteista ja laajennetaan laskuraportteihin

 */

class BillReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: [],
      selectedEmployee: "",
      customers: [],
      selectedCustomer: "",
      worksites: [],
      selectedWorksite: "",
      worklogs: [],
      company: {},
      jobModels: [],
      customerModel: {},
      selectedPeriod: "select",
      reportType: "select",
      dateRange: {
        selection: {
          startDate: moment().subtract(1, "year").toDate(),
          endDate: moment().toDate(),
          key: "selection",
        },
      },
      dateChanged: false,
      tabValue: 0,
      resetPeriod: "gotPeriod",
      resetApproval: "gotApproval",
      resetArray: "gotArray",
      resetHour: "gotHour",
      searchArray: [],
      searchObj: {},
      errors: {
        period: "",
        date: "",
      },
      email: {
        pdfName: `laskulista_${moment().locale("fi").format("L")}`,
        message: "ohessa lista laskuistanne",
        subject: "Lista Laskuistanne",
      },
      emailLoading: false,
      emailSent: false,
      descriptionField: "task",
      menu: false,
      anchor: {},
      searchDisabled: false,
    };
  }

  componentDidMount() {
    const { user } = this.props.user;

    this.unsubscribeCompanyListener = firebase
      .firestore()
      .collection("company")
      .doc(user.companyID)
      .onSnapshot(this.onCompanySnapshot);

    this.unsubscribeCustomerListener = firebase
      .firestore()
      .collection("customers")
      .where("companyID", "array-contains", user.companyID)
      .onSnapshot(this.onCustomerSnapshot);
  }

  onCompanySnapshot = (docSnapshot) => {
    const company = docSnapshot.data();
    this.setState({
      company: company,
    });
  };

  onCustomerSnapshot = (querySnapshot) => {
    const customers = [];

    querySnapshot.forEach((doc) => {
      const customer = doc.data();
      customer.id = doc.id;
      customers.push(customer);
    });

    this.setState({
      customers: customers,
    });
  };

  componentWillUnmount() {
    this.unsubscribeCompanyListener && this.unsubscribeCompanyListener();
    this.unsubscribeCustomerListener && this.unsubscribeCustomerListener();
  }

  handleMenu = (event) => {
    this.setState({
      menu: true,
      anchor: event.getBoundingClientRect(),
    });
    //setMenu(true);
    //setAnchor(event.getBoundingClientRect());
  };

  closeMenu = () => {
    this.setState({
      menu: false,
      anchor: {},
    });
    //setMenu(false);
    //setAnchor(null)
  };

  handleDate = (event) => {
    let { errors } = this.state;
    console.log("handleDate with date range ", event);
    //this.props.onChange(event, 'date');  //this will chenge daterange for all timereports

    errors.date = "";
    const dateRange = event;
    console.log("set selected dateRange 1", dateRange);
    dateRange.selection.endDate = moment(dateRange.selection.endDate)
      .endOf("day")
      .toDate();
    console.log("set selected dateRange 2", dateRange);

    this.setState(
      {
        dateRange,
        errors: errors,
        dateChanged: true,
        searchDisabled: false,
        searchArray: [],
      },
      () => this.closeMenu()
    );

    //set time also for this component's serachdata
    //this.onDataChange(moment(event.selection.startDate), "start");
    //this.onDataChange(moment(event.selection.endDate), "end");
    //this.closeMenu();
  };

  /*
    onDataChange = (event, field) => {
        let {searchData} = this.state;
        //if (field === "end") { searchData[field] = moment(event).endOf("day"); }
        //else
        
        searchData[field] = event;

        this.setState({
            searchData,
            pickField: "",
            datePicker: false,
        });
    };*/

  setMailToAccordingCustomer = (callback) => {
    const { email, customers, selectedCustomer } = this.state;

    const customer = customers.find(
      (customer) => customer.name === selectedCustomer
    );

    if (customer) {
      email.sendTo = customer.email ? customer.email : "customer has no email";

      this.setState({ email: email }, () => (callback ? callback() : null));
    }
  };

  setKTVEmailPresets = () => {
    const { email, dateRange, selectedCustomer, company, customer } =
      this.state;

    console.log(
      "setKTVEmailPresets selectedCustomer ",
      selectedCustomer,
      customer
    );
    //email.mailFrom = "noreply@mobilityworkcloud.com";
    email.pdfName = `Kotitalousvähennysraportti_${
      company.name
    }_${selectedCustomer}_${moment(dateRange.selection.startDate)
      .locale("fi")
      .format("L")}-${moment(dateRange.selection.endDate)
      .locale("fi")
      .format("L")}`;
    email.subject = `${company.name}: Raportti kotitalousvähennystä varten`;
    email.message = `Hei ${selectedCustomer},<br><br>Ohessa raportti kotitalousvähennykseen oikeuttavista maksuistanne ajalta ${moment(
      dateRange.selection.startDate
    )
      .locale("fi")
      .format("L")}-${moment(dateRange.selection.endDate)
      .locale("fi")
      .format("L")} <br><br> Terveisin, <br><br>${company.name}`;

    this.setState({ email: email });
  };

  setCompanySelfBillSummaryEmailPresets = () => {
    const { email, dateRange, company } = this.state;

    console.log("setCompanySelfBillSummaryEmailPresets  ", company);
    const dateSpan = `${moment(dateRange.selection.startDate)
      .locale("fi")
      .format("L")} - ${moment(dateRange.selection.endDate)
      .locale("fi")
      .format("L")}`;
    //email.mailFrom = "noreply@mobilityworkcloud.com";
    email.pdfName = `Laskuraportti_${company.name}_${dateSpan}`;
    email.subject = `${company.name}: Laskuraportti kirjanpitoon ${dateSpan}`;
    email.message = `Hei, <br><br>Ohessa laskuraportti ajalta ${dateSpan} <br><br> Terveisin, <br><br>${company.name}`;

    this.setState({ email: email });
  };

  changeDateRangeToState = (startMoment, endMoment, callBack) => {
    const dateRange = {
      selection: {
        startDate: startMoment,
        endDate: endMoment,
        key: "selection",
      },
    };
    this.setState(
      { dateRange: dateRange, searchDisabled: false },
      callBack ? callBack() : null
    );
  };

  fieldChangeHandler = (event, field) => {
    const { errors, reportType } = this.state;

    console.log("fieldChangeHandler ", field, event);

    if (field === "customer") {
      this.setState({ selectedCustomer: event }, () =>
        this.setMailToAccordingCustomer(
          reportType === "ktv"
            ? () => {
                this.setKTVEmailPresets(); // set email presets after customer has been set
              }
            : null
        )
      );
    }

    if (field === "selectedPeriod") {
      errors.period = "";
      this.setState({
        selectedPeriod: event.target.value,
        errors: errors,
        searchDisabled: false,
      });
    }
    if (field === "tabValue") {
      this.setState({ tabValue: event });
    }
    if (field === "reportType") {
      this.setState({ reportType: event.target.value, searchDisabled: false });
      //change also the default range for different report types
      if (event.target.value === "ktv") {
        this.changeDateRangeToState(
          moment().subtract(1, "year").startOf("year").toDate(),
          moment().subtract(1, "year").endOf("year").toDate(),
          () => {
            this.setKTVEmailPresets(); // set presets after date has been set
          }
        );
      }

      if (event.target.value === "period") {
        this.changeDateRangeToState(
          moment().subtract(1, "month").startOf("month").toDate(),
          moment().subtract(1, "month").endOf("month").toDate(),
          () => {
            this.setCompanySelfBillSummaryEmailPresets(); // set presets after date has been set
          }
        );
      }
    }

    if (field === "descriptionField") {
      this.setState({ descriptionField: event.target.value });
    }
    if (field === "date") {
      errors.date = "";
      const dateRange = event;
      dateRange.selection.endDate = moment(dateRange.selection.endDate)
        .endOf("day")
        .toDate();
      this.setState({
        dateRange,
        errors: errors,
        dateChanged: true,
        searchDisabled: false,
      });
    }
  };

  getCustomerName = (id) => {
    const { customers } = this.state;

    const customer = customers.find((customer) => customer.id === id);

    if (customer) {
      return customer.name;
    } else {
      return "Couldn't find name";
    }
  };

  searchDB = (customer, start, end) => {
    const { user } = this.props.user;
    const { reportType } = this.state;

    const searchArray = [];
    console.log("search bills with customer id ", customer, start, end);

    if (customer && reportType === "ktv") {
      firebase
        .firestore()
        .collection("company")
        .doc(user.companyID)
        .collection("bills")
        .where("customerID", "==", customer.id)
        .where("billDate", ">=", start)
        .where("billDate", "<=", end)
        .get()
        .then((docSnap) => {
          if (!docSnap.empty) {
            docSnap.forEach((doc) => {
              console.log(doc.data());
              searchArray.push(doc.data());
            });
          }
          this.setState({
            searchArray: searchArray,
            resetArray:
              this.state.resetArray === "gotArray" ? "noArray" : "gotArray",
            customer: customer,
            searchDisabled: true,
          });
        })
        .catch((error) => {
          //TODO: pop up this!
          console.log("error in serachDB  ", error);
        });
    } else if (reportType === "period") {
      firebase
        .firestore()
        .collection("company")
        .doc(user.companyID)
        .collection("bills")
        .where("billDate", ">=", start)
        .where("billDate", "<=", end)
        .get()
        .then((docSnap) => {
          if (!docSnap.empty) {
            docSnap.forEach((doc) => {
              console.log(doc.data());
              searchArray.push(doc.data());
            });
          }
          this.setState({
            searchArray: searchArray,
            resetArray:
              this.state.resetArray === "gotArray" ? "noArray" : "gotArray",
            customer: customer,
            searchDisabled: true,
          });
        })
        .catch((error) => {
          //TODO: pop up this!
          console.log("error in serachDB  ", error);
        });
    } else {
      //TODO: pop up this!
      console.log("customer must be selected");
    }
  };

  searchBills = () => {
    const { customers, selectedCustomer, dateChanged, dateRange, errors } =
      this.state;

    console.log("search bills with customer  ", selectedCustomer, dateRange);

    const customer = customers.find(
      (customer) => customer.name === selectedCustomer
    );

    this.searchDB(
      customer,
      moment(dateRange?.selection?.startDate).format("YYYYMMDDTHHmmss"),
      moment(dateRange?.selection?.endDate).format("YYYYMMDDTHHmmss")
    );
  };

  changeEmail = (event, field) => {
    const { email } = this.state;

    email[field] = event.target.value;

    this.setState({
      email: email,
    });
  };

  sendEmail = () => {
    const { pdf } = this.props.report;
    const { email } = this.state;
    this.setState({
      emailLoading: true,
    });

    console.log("sending email: ", email);
    firebase
      .functions()
      .httpsCallable("sendMail")({
        mailTo: email.sendTo,
        pdf: pdf,
        msg: email.message,
        pdfName: email.pdfName,
        subject: email.subject,
      })
      .then((res) => {
        this.props.resetSend();
        this.setState({
          emailSent: true,
          tabValue: 0,
          email: {},
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  resetParameters = () => {
    this.setState({
      selectedCustomer: "",
      selectedEmployee: "",
      reportType: "select",
      selectedPeriod: "select",
      selectedWorksite: {},
      dateRange: {
        selection: {
          startDate: moment(),
          endDate: moment(),
          key: "selection",
        },
      },
      dateChanged: false,
      tabValue: 0,
      resetPeriod:
        this.state.resetPeriod === "gotPeriod" ? "noPeriod" : "gotPeriod",
      resetApproval:
        this.state.resetApproval === "gotApproval"
          ? "noApproval"
          : "gotApproval",
      searchArray: [],
      searchObj: [],
      resetHour: this.state.resetHour === "gotHour" ? "noHour" : "gotHour",
      resetArray: this.state.resetArray === "gotArray" ? "noArray" : "gotArray",
      errors: {
        period: "",
        date: "",
      },
    });
  };

  renderEmailDialog = () => {
    if (this.state.emailLoading) {
      return (
        <Dialog
          open={this.state.emailLoading}
          onClose={() => this.setState({ emailLoading: false })}
          maxWidth="xs"
        >
          {!this.state.emailSent && <CircularProgress />}
          {this.state.emailSent && <DialogTitle>Email sent!</DialogTitle>}
          {this.state.emailSent && (
            <DialogContent>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => this.setState({ emailLoading: false })}
                style={{
                  marginLeft: 10,
                }}
              >
                Close
              </Button>
            </DialogContent>
          )}
        </Dialog>
      );
    }
  };

  renderReportsCreateDialog = () => {
    if (this.state.reportsLoading) {
      return (
        <Dialog
          open={this.state.reportsLoading}
          onClose={() => this.setState({ reportsLoading: false })}
          maxWidth="xs"
        >
          <DialogContent>
            <CircularProgress />
          </DialogContent>
        </Dialog>
      );
    }
  };

  render() {
    const { t, i18n } = this.props;
    const { user } = this.props.user;
    const { menu, anchor, searchDisabled } = this.state;
    console.log("state ", this.state);
    console.log("props ", this.props);

    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          marginLeft: 20,
          marginTop: 20,
        }}
      >
        <div
          style={{ display: "flex", justifyContent: "flex-start", width: 350 }}
        >
          <Paper
            style={{
              width: "100%",
              padding: 10,
              height: "auto",
              overflowY: "auto",
            }}
          >
            {/* Laskuraportit
             * kotitalousvähennys - edellinen vuosi - huomioi laskutusmaksut
             *      lähetys asiakkaalle - sama mekanismi kuin approvalissa
             *      LATER: raporttien lähetys kaikille automatic
             * laskulista esim kirjanpitoon - geneerinen
             *      asiakkaan laskulista -> onko sama kuin yllä mutta valitaan asiakas?
             *
             * TODO: Raportin tallennus
             * raportin lähetys onko lähetys asiakkaalle tai kirjanpitoon?
             */}

            {
              <TextField
                variant="outlined"
                label={t("Report type")}
                style={{
                  width: "100%",
                }}
                value={this.state.reportType}
                //onChange={event => this.setState({reportType: event.target.value})}
                onChange={(event) =>
                  this.fieldChangeHandler(event, "reportType")
                }
                select
              >
                {
                  <MenuItem key="select" value="select">
                    {t("Select type")}
                  </MenuItem>
                }
                {this.state.reportType !== "period" && (
                  <MenuItem key="period" value="period">
                    {t("Period bill report")}
                  </MenuItem>
                )}
                {this.state.reportType !== "ktv" && (
                  <MenuItem key="ktv" value="ktv">
                    {t("Annual ktv bill report")}
                  </MenuItem>
                )}
              </TextField>
            }

            {this.state.reportType === "ktv" && (
              <div>
                <Tabs
                  value={this.state.tabValue}
                  onChange={(event, value) =>
                    this.setState({ tabValue: value })
                  }
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: { backgroundColor: "#ccc" },
                  }}
                >
                  <Tab label={t("Search")} />
                  <Tab label={t("Send report")} />
                </Tabs>
                <br />
                {this.state.tabValue === 0 && (
                  <TabContainer>
                    <CustomerComponent
                      reportType={this.state.reportType}
                      customers={this.state.customers}
                      selectedCustomer={this.state.selectedCustomer}
                      onChange={this.fieldChangeHandler}
                      dateRange={this.state.dateRange}
                      tabValue={this.state.tabValue}
                      key={this.state.resetApproval}
                      onReset={this.resetParameters}
                      onSearch={this.searchBills}
                      errors={this.state.errors}
                      descriptionField={this.state.descriptionField}
                    />
                    {this.state.searchArray.length > 0 &&
                      this.state.customer && (
                        <PDFCustomerKTV
                          start={this.state.dateRange.selection.startDate}
                          end={this.state.dateRange.selection.endDate}
                          selectedCustomer={this.state.selectedCustomer}
                          customer={this.state.customer}
                          customers={this.state.customers}
                          selectedBills={this.state.searchArray}
                          company={this.state.company}
                          descriptionField={this.state.descriptionField}
                          dateRange={this.state.dateRange}
                          onReportCreate={() =>
                            this.setState({ reportsLoading: true })
                          }
                          onReportsCreated={() =>
                            this.setState({ reportsLoading: false })
                          }
                          openInNewTab={this.state.openInNewTab}
                          onChangeOpenInNewTab={(checked) =>
                            this.setState({ openInNewTab: checked })
                          }
                        />
                      )}
                  </TabContainer>
                )}

                {this.state.tabValue === 1 && (
                  <TabContainer>
                    <SendApproval
                      gotEmail={(event) => this.setState({ sendTo: event })}
                      onChange={this.changeEmail}
                      initialEmail={this.props.report.customer}
                      email={this.state.email}
                      sendEmail={this.sendEmail}
                    />
                  </TabContainer>
                )}
              </div>
            )}

            {/* laskuraportti periodille - yrityksen omat laskut */}
            {this.state.reportType === "period" && (
              <div>
                <Tabs
                  value={this.state.tabValue}
                  onChange={(event, value) =>
                    this.setState({ tabValue: value })
                  }
                  variant="fullWidth"
                  TabIndicatorProps={{
                    style: { backgroundColor: "#ccc" },
                  }}
                >
                  <Tab label={t("Search")} />
                  <Tab label={t("Send report")} />
                </Tabs>
                <br />
                {this.state.tabValue === 0 && (
                  <TabContainer>
                    <TextField
                      variant="outlined"
                      label={t("Select range")}
                      style={{
                        width: "100%",
                        marginTop: 10,
                      }}
                      value={`${moment(this.state.dateRange.selection.startDate)
                        .locale(user.lang ? user.lang : "fi")
                        .format("L")} - ${moment(
                        this.state.dateRange.selection.endDate
                      )
                        .locale(user.lang ? user.lang : "fi")
                        .format("L")}`}
                      onClick={(event) => this.handleMenu(event.currentTarget)}
                    />
                    {this.state.errors.date.length > 0 && (
                      <p style={{ color: "#B00", marginLeft: 60 }}>
                        {this.state.errors.date}
                      </p>
                    )}

                    {menu && (
                      <ClickAwayListener onClickAway={this.closeMenu}>
                        <div
                          style={{
                            position: "absolute",
                            top: anchor.y + anchor.height,
                            left: anchor.x,
                            zIndex: 10,
                            backgroundColor: "#FFF",
                            width: anchor.width - 2,
                            borderStyle: "solid",
                            borderColor: "#ccc",
                            borderWidth: 1,
                            borderRadius: 4,
                          }}
                        >
                          <div style={{ marginLeft: 30, marginTop: 10 }}>
                            <DateRange
                              moveRangeOnFirstSelection={false}
                              ranges={[this.state.dateRange?.selection]}
                              style={{
                                width: "100%",
                              }}
                              locale={i18n.language === "fi" ? fi : enGB}
                              onChange={(event) => this.handleDate(event)}
                            />
                          </div>
                        </div>
                      </ClickAwayListener>
                    )}

                    {this.state.searchArray.length > 0 && (
                      <PDFCompanyBillSummary
                        start={this.state.dateRange.selection.startDate}
                        end={this.state.dateRange.selection.endDate}
                        //selectedCustomer={this.state.selectedCustomer}
                        //customer={this.state.customer}
                        customers={this.state.customers}
                        selectedBills={this.state.searchArray}
                        company={this.state.company}
                        //descriptionField={this.state.descriptionField}
                        dateRange={this.state.dateRange}
                        onReportCreate={() =>
                          this.setState({ reportsLoading: true })
                        }
                        onReportsCreated={() =>
                          this.setState({ reportsLoading: false })
                        }
                        openInNewTab={this.state.openInNewTab}
                        onChangeOpenInNewTab={(checked) =>
                          this.setState({ openInNewTab: checked })
                        }
                      />
                    )}

                    <Button
                      disabled={searchDisabled}
                      variant="outlined"
                      color={searchDisabled ? "secondary" : "primary"}
                      style={{
                        marginLeft: 50,
                        marginTop: 20,
                        //color: searchDisabled ? 'grey' : 'red',
                      }}
                      //onClick={() => props.onSearch()}
                      onClick={this.searchBills}
                    >
                      {t("Search")}
                    </Button>
                  </TabContainer>
                )}

                {this.state.tabValue === 1 && (
                  <TabContainer>
                    <SendApproval
                      gotEmail={(event) => this.setState({ sendTo: event })}
                      onChange={this.changeEmail}
                      initialEmail={this.props.report.customer}
                      email={this.state.email}
                      sendEmail={this.sendEmail}
                    />
                  </TabContainer>
                )}
              </div>
            )}
            {/* End of laskuraportti */}

            {/* info texts */}

            {this.state.reportType === "select" && (
              <div>
                <div
                  style={{
                    width: "95%",
                    height: "85vh",
                    overflow: "auto",
                    padding: 20,
                    fontSize: 12,
                  }}
                >
                  <div style={{ padding: 5, marginBottom: 20, fontSize: 13 }}>
                    Valitse laskuraportin tyyppi:
                  </div>
                  <div style={{ padding: 5 }}>
                    Yrityksen laskuraportti sisältää valitun aikavälin laskut
                    laskupäivän mukaan esim kirjanpitoa varten.
                  </div>
                  <div style={{ padding: 5 }}>
                    Kotitalousvähennysraportti sisältää aikavälin (yleensä
                    verovuoden) kotitalousvähennykseen oikeuttavat maksut eli
                    työsuoritukset per asiakas. Laskutusmaksut, sekä materiaalit
                    on vähennetty ja eritelty raportilla.
                  </div>
                  <div style={{ padding: 5 }}></div>
                  <div style={{ padding: 5 }}></div>
                </div>
              </div>
            )}
          </Paper>
        </div>

        <div style={{ flexGrow: 1 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <Paper style={{ width: "100%", height: "85vh", overflow: "auto" }}>
              {
                <SearchListComponent
                  searchArray={this.state.searchArray}
                  findCustomer={this.getCustomerName}
                  key={this.state.resetArray}
                />
              }
              {/*this.state.reportType === "hour" &&
                            <HourListComponent
                                searchObj={this.state.searchObj}
                                employees={this.state.employee}
                                getEmployee={this.getEmployeeName}
                                getCustomer={this.getCustomerName}
                                getWorksite={this.getWorksiteName}
                            />*/}
            </Paper>
          </div>
        </div>

        {this.renderEmailDialog()}
        {this.renderReportsCreateDialog()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  report: state.report,
});

export default withRouter(
  connect(mapStateToProps, { resetSend })(withTranslation()(BillReports))
);
