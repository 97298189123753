import React from 'react';
import {TextField} from "@material-ui/core";

const BillPriceItem = props => {

    return (
        <div className="bill-delivery-item">
            <p className="delivery-left">{props.label}</p>

            <div className="delivery-right">
                <TextField
                    value={props.value}
                    onChange={event => props.onChange(event, props.field)}
                    variant="outlined"
                    fullWidth
                    style={{
                        marginLeft: 10,
                        marginTop: 9
                    }}
                    InputProps={{
                        style: {
                            height: 25
                        }
                    }}
                />
            </div>
        </div>
    )
};

export default BillPriceItem;