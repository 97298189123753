import React, {Component} from 'react';

import {
    Paper,
    Button,
    FormControlLabel,
    Checkbox,
    Divider,
    TextField,
    MenuItem,
    CircularProgress
} from '@material-ui/core';


import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import moment from "moment-timezone";
import "moment/locale/fi";
import "moment/locale/en-gb";
import DateDialog from "../CalendarComponents/DateDialog";

class JobSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: this.props.company,
            datePicker: false,
            value: "",
            saving: false,
            unsavedChanges: this.props.unsavedChanges,
        };
    }


    handlePeriodChange = event => {
        let {pickField, company} = this.state;

        if (pickField === 'start') {
            company.settings.job.period.start = moment(event.selection.startDate).format("YYYYMMDDTHHmmss");
        }
        else if (pickField === "MS") {
            company.settings.job.managementStart = moment(event.selection.startDate).format("YYYYMMDDTHHmmss");
        }
        else {
            company.settings.job.period.end = moment(event.selection.startDate).format("YYYYMMDDTHHmmss");
        }

        this.setState({
            company: company,
            datePicker: false,
            value: "",
            unsavedChanges: true
        });
    };

    checkboxHandler = (value, field) => {
        const {company} = this.state;

        company.settings.job[field] = value;

        this.setState({
            company: company,
            unsavedChanges: true
        });
    };

    deadlineChanger = event => {
        const {company} = this.state;

        company.settings.job.workDeadline = event.target.value;

        this.setState({
            company: company,
            unsavedChanges: true
        });
    };

    handleChange = (event, field) => {
        const {company} = this.state;

        company.settings.job[field] = event.target.value;

        this.setState({
            company,
            unsavedChanges: true
        });
    };

    render() {
        const {t} = this.props;
        const {user} = this.props.user;
        const {period, workdayStart, transfers, workDeadline, managementStart, defaultEmp, billableRounding} = this.state.company.settings.job;
        return (
            <div style={{padding: 20}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div style={{paddingLeft: 20, width: '30%'}}>
                        <h4>{t("Settings work periods")}</h4>

                        <Button
                            onClick={() => this.setState({
                                pickField: 'start',
                                value: moment(period.start).toDate(),
                                datePicker: true,
                            })}
                        >
                            {`${t("Start wk")}: ${moment(period.start).format('WW')}/${moment(period.start).format('YY')}`}
                        </Button>

                        <br/>

                        <Button
                            onClick={() => this.setState({
                                pickField: 'end',
                                value: moment(period.end).toDate(),
                                datePicker: true
                            })}
                        >
                            {`${t("End wk")}: ${moment(period.end).format('WW')}/${moment(period.end).format('YY')}`}
                        </Button>

                        <p style={{marginTop: 0, fontWeight: 'bold'}}>{t("Settings work periods")}</p>
                        <p style={{marginTop: 5, paddingTop: 2}}>{t("Settings period advice text")}</p>
                        <p style={{marginTop: 1, paddingTop: 2}}>{t("Settings period advice text cont")}</p>

                        <br/>

                    </div>


                    <div style={{paddingLeft: 10, width: '30%'}}>
                        <h4>{t("Work_deadline")}</h4>
                        <TextField
                            value={workDeadline || ""}
                            onChange={event => this.deadlineChanger(event)}
                            variant="outlined"
                            InputProps={{
                                style: {height: 30}
                            }}
                            style={{width: 150, marginTop: 2}}
                            select
                        >
                            <MenuItem key={1} value={1}>{`1 ${t("setting_week")}`}</MenuItem>
                            <MenuItem key={2} value={2}>{`2 ${t("setting_weeks")}`}</MenuItem>
                            <MenuItem key={3} value={3}>{`3 ${t("setting_weeks")}`}</MenuItem>
                            <MenuItem key={4} value={4}>{`4 ${t("setting_weeks")}`}</MenuItem>
                        </TextField>
                    </div>

                    <div style={{paddingRight: 20, width: '30%'}}>
                        <h4>{t("Management start")}</h4>
                        <Button
                            onClick={() => this.setState({
                                pickField: "MS",
                                value: moment(managementStart === 0 ? this.state.company.created : managementStart).toDate(),
                                datePicker: true
                            })}
                        >
                            {managementStart === 0
                                ? moment(this.state.company.created).locale(user.lang ? user.lang : "fi").format('L')
                                : moment(managementStart).locale(user.lang ? user.lang : "fi").format('L')
                            }
                        </Button>
                    </div>

                </div>

                <Divider style={{backgroundColor: '#ccc', width: '98%', marginLeft: '1%'}}/>

                <div style={{display: 'flex', justifyContent: 'space-around'}}>
                    <div>
                        <h4>{t("Default_emp")}</h4>
                        <TextField
                            variant="outlined"
                            value={defaultEmp || ""}
                            InputProps={{
                                style: {
                                    height: 30
                                }
                            }}
                            onChange={event => this.handleChange(event, "defaultEmp")}
                            style={{width: 100}}
                        />
                    </div>

                    <div>
                        <h4>{t("Billable time rounding")}</h4>
                        <TextField
                            value={billableRounding || ""}
                            variant="outlined"
                            InputProps={{
                                style: {
                                    height: 30,
                                }
                            }}
                            style={{
                                marginBottom: 10,
                                width: 220
                            }}
                            onChange={event => this.handleChange(event, "billableRounding")}
                            select
                        >
                            <MenuItem key={1} value={4}>{t("starting_15")}</MenuItem>
                            <MenuItem key={2} value={2}>{t("starting_30")}</MenuItem>
                            <MenuItem key={3} value={1}>{t("starting_1")}</MenuItem>
                        </TextField>
                    </div>
                </div>


                <Divider style={{backgroundColor: '#ccc', width: '98%', marginLeft: '1%'}}/>

                <div style={{paddingLeft: 20}}>
                    <h4>{t("Workday start")}</h4>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={workdayStart === "atOffice"}
                                onChange={() => this.checkboxHandler("atOffice", "workdayStart")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("At the office")}/>

                    <br/>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={workdayStart === "atCustomer"}
                                onChange={() => this.checkboxHandler("atCustomer", "workdayStart")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("At first customer")}/>
                </div>

                <Divider style={{backgroundColor: '#ccc', width: '98%', marginLeft: '1%'}}/>

                <div style={{paddingLeft: 20}}>
                    <h4>{t("Transfers")}</h4>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={transfers === true}
                                onChange={() => this.checkboxHandler(true, "transfers")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("Are paid")}/>

                    <br/>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={transfers === false}
                                onChange={() => this.checkboxHandler(false, "transfers")}
                                style={{
                                    color: '#1770B2'
                                }}
                            />
                        }
                        label={t("Are not paid")}/>
                </div>


                {this.state.datePicker && <DateDialog
                    selectType="date"
                    onClose={() => this.setState({datePicker: false})}
                    onChange={event => this.handlePeriodChange(event)}
                    value={this.state.value}
                />}

                <Paper className="settings-footer">
                    {this.state.unsavedChanges &&
                    <Button
                        variant="outlined"
                        color="primary"
                        //onClick={() => this.props.onSave(this.state.company)}
                        onClick={(() => this.setState({saving: true}),
                                () => this.props.onSave(this.state.company,
                                    () => this.setState({
                                        saving: false,
                                        unsavedChanges: false
                                    }))
                        )}
                    >
                        {this.state.saving &&
                        <div style={{
                            alignSelf: 'right',
                            width: 'auto',
                            height: 'auto',
                        }}>
                            <CircularProgress/>
                        </div>
                        }
                        {t("Save")}
                    </Button>
                    }
                </Paper>

            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(JobSettings));

