import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';

import CryptoJS from 'crypto-js';

import {
    Paper,
} from '@material-ui/core';

import keys from '../util/keys';
import {withTranslation} from "react-i18next";
import {createSettingsObject, createUserSettings, jsonFormatter} from "./CreateSettings";

/* Component Imports*/
import LeftNavigation from './LeftNavigation';
import CommonSettings from './CommonSettings';
import JobSettings from './JobSettings';
import BillingSettings from './BillingSettings';
import CompanySettings from './CompanySettings';
import ModuleSettings from './ModuleSettings';
import ReportSettings from './ReportSettings';

import "./Settings.css";

class Settings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: {},
            companyCopy: {},
            employees: [],
            employeesCopy: [],
            changedEmployees: [],
            activeSetting: "Common",
            loading: true,
            saving: false,
            unsavedChanges: false,
        };

    }

    componentDidMount() {
        const {user} = this.props.user;
        this.unsubscribeCompanyListener =
            firebase.firestore().collection('company').doc(user.companyID).onSnapshot(this.onCompanySnapshot);

        this.unsubscribeEmployeeListener =
            firebase.firestore().collection('employees')
                .where("companyID", "==", user.companyID)
                .onSnapshot(this.onEmployeeSnapshot);

    }

    componentWillUnmount() {
        this.unsubscribeCompanyListener && this.unsubscribeCompanyListener();
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
    }


    onCompanySnapshot = querySnapshot => {
        const company = querySnapshot.data();
        company.id = querySnapshot.id;

        // If there is no settings create default
        if (!company.settings) {
            this.createSettings(company);
        }

        // Decrypt Talenom and Apix passwords
        if (company.settings.billing.talenom && company.settings.billing.talenom.password.length > 0) {
            const decrypt = CryptoJS.AES.decrypt(company.settings.billing.talenom.password, keys.secret, {format: jsonFormatter});
            company.settings.billing.talenom.password = CryptoJS.enc.Utf8.stringify(decrypt);
        }

        this.setState({
            company: company,
            companyCopy: JSON.parse(JSON.stringify(company)),
        });
    };

    onEmployeeSnapshot = querySnapshot=> {
        const employees = [];

        querySnapshot.forEach(doc => {
            const employee = doc.data();
            employee.id = doc.id;
            employee.name = `${employee.firstName} ${employee.familyName}`;

            // IF employee doesn't have settings object
            if (!employee.settings) {
                employee.settings = {};
                employee.settings.permissions = createUserSettings();
            }
            else {
                // Perform check that user has all fields, this prevents errors
                const mainSettings = ["management", "time", "reports", "billing", "keys", "registry", "crm"];

                const settingsObject = createUserSettings();

                mainSettings.forEach(mainSetting => {
                    if (!employee.settings.permissions) employee.settings.permissions = createUserSettings();
                    if (!employee.settings.permissions[mainSetting]) employee.settings.permissions[mainSetting] = {};

                    const keys = Object.keys(settingsObject[mainSetting]);

                    keys.forEach(key => {
                        if (!employee.settings.permissions[mainSetting][key]) {
                            employee.settings.permissions[mainSetting][key] = false
                        }
                    });
                });
            }

            employees.push(employee)
        });

        this.setState({
            employees: employees,
            employeesCopy: JSON.parse(JSON.stringify(employees)),
            loading: false,
        });
    };

    createSettings = company => {
        company.settings = createSettingsObject();
        firebase.firestore().collection('company').doc(company.id).set(company);
    };


    changeActiveView = item => {
        let {activeSetting, employees, company} = this.state;

        if (activeSetting !== "Modules") {
            company = JSON.parse(JSON.stringify(this.state.companyCopy));
        }
        else {
            employees = JSON.parse(JSON.stringify(this.state.employeesCopy))
        }

        this.setState({
            activeSetting: item,
            company: company,
            employees: employees
        });
    };

    onCompanyFieldChange = (event, field) => {
        const {company} = this.state;

        company[field] = event.target.value;

        this.setState({
            company: company,
            unsavedChanges: true,
        });
    };

    returnUpdatedCompany = company => {
        this.setState({
            company: company,
            unsavedChanges: true,
        });
    };


    saveCompany = (companyObj, callBck) => {
        const {user} = this.props.user;

        this.setState({saving: true});

        const company = JSON.parse(JSON.stringify(companyObj));

        if (company.settings.billing.talenom.password.length > 0) {
            const encryptTalenom = CryptoJS.AES.encrypt(company.settings.billing.talenom.password, keys.secret);
            company.settings.billing.talenom.password = jsonFormatter.stringify(encryptTalenom);
        }

        firebase.firestore().collection('company').doc(user.companyID).set(company)
            .then(() => this.setState({unsavedChanges: false, saving: false}, callBck ? callBck() : null))
            .catch((err) => this.setState({unsavedChanges: false, saving: false, error: err}, callBck ? callBck(err): null))
    };

    saveUsers = (employees, changedEmployees) => {

        this.setState({saving: true});

        const employeesCopy = JSON.parse(JSON.stringify(employees));

        changedEmployees.forEach(id => {
            const employee = employeesCopy.find(employee => employee.id === id);
            const eID = employee.id;

            // Delete fields we dont want to end up id firebase
            delete employee.id;
            delete employee.name;

            // Save
            firebase.firestore().collection('employees').doc(eID).set(employee)
                .then(() => this.setState({unsavedChanges: false, saving: false}))
                .catch((err) => this.setState({unsavedChanges: false, saving: false, error: err}))
        });
    };

    render() {
        const {activeSetting, loading, unsavedChanges, saving} = this.state;
        const {user} = this.props.user;
        return (
            <div className="settings-container">
                <Paper className="settings-navigation">
                    <LeftNavigation
                        activeSetting={this.state.activeSetting}
                        onChange={this.changeActiveView}
                        user={user}
                    />
                </Paper>

                {!loading &&
                    <Paper className="settings-display">
                        {activeSetting === "Common" &&
                        <CommonSettings

                        />
                        }

                        {activeSetting === "Job" &&
                        <JobSettings
                            company={this.state.company}
                            updateCompany={this.returnUpdatedCompany}
                            onSave={this.saveCompany}
                            unsavedChanges={unsavedChanges}
                            saving={saving}
                        />
                        }

                        {activeSetting === "Reports" &&
                            <ReportSettings
                                updateCompany={this.returnUpdatedCompany}
                                company={this.state.company}
                                onSave={this.saveCompany}
                                unsavedChanges={unsavedChanges}
                                saving={saving}
                            />
                        }

                        {activeSetting === "Billing" &&
                        <BillingSettings
                            updateCompany={this.returnUpdatedCompany}
                            company={this.state.company}
                            onSave={this.saveCompany}
                            unsavedChanges={unsavedChanges}
                            saving={saving}
                        />
                        }

                        {activeSetting === "Company" &&
                        <CompanySettings
                            company={this.state.company}
                            onChange={this.onCompanyFieldChange}
                            onSave={this.saveCompany}
                            unsavedChanges={unsavedChanges}
                            saving={saving}
                        />
                        }

                        {activeSetting === "Modules" &&
                        <ModuleSettings
                            company={this.state.company}
                            employees={this.state.employees}
                            onSave={this.saveUsers}
                            unsavedChanges={unsavedChanges}
                            saving={saving}
                        />
                        }
                    </Paper>


                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(Settings));