import React from 'react';

import {
    Schedule,
    LibraryBooks,
    Attachment,
    DeviceHub,
    Email,
    EuroSymbol,
    Receipt,
    ViewList,
} from '@material-ui/icons';


export const checkPermissions = user => {
    const { permissions } = user.settings;

    const navigationItemArray = [];

    console.log("permissions ", permissions)
    // Check if user is mainUser
    if (permissions.mainUser) {
        // Go through every main category, this determines which modules user has
        if (permissions.management.admin) {
            const management = {
                name: "Management",
                icon: <Schedule />,
                items: [
                    { name: "Calendar", path: "/management/calendar" },
                    { name: "Jobcards", path: "/management/jobcards" },
                    { name: "Worklogs", path: "/management/worklogs" },
                    /*{name: "Schedule", path: "/management/schedule"},*/
                    {name: "WorkDays", path: "/management/workdays"},
                ]
            };
            navigationItemArray.push(management);
        }

        if (permissions.reports.admin) {
            const reports = {
                name: "Reports",
                icon: <Receipt />,
                items: [
                    {name: "Created reports", path: "/reports"},
                    {name: "Time reports", path: "/reports/timereports"},
                    {name: "Bill reports", path: "/reports/billreports"},
                    {name: "Workshifts", path: "/reports/workshifts"}
                ]
            };
            navigationItemArray.push(reports);
        }

        if (permissions.billing.admin) {
            const billing = {
                name: "Billing",
                icon: <EuroSymbol />,
                items: [
                    { name: "Created bills", path: "/bills" },
                    { name: "Create bills", path: "/billing" },
                ]
            };
            navigationItemArray.push(billing);
        }


        if (!permissions.crm) permissions.crm = {};
        if (permissions.crm.admin) {
            const crm = {
                name: "CRM",
                icon: <Email />,
                items: [
                    { name: "CRM email", path: "/crm/email" }
                ]
            };
            navigationItemArray.push(crm);
        }

        console.log('permissions', permissions);
        if (!permissions.spotOn) permissions.spotOn = {};
        if (permissions.spotOn.admin) {
            const spotOn = {
                name: "SpotOn",
                icon: <ViewList />,
                items: [
                    { name: "Vehicles", path: "spoton/vehicles" }
                ]
            }
            navigationItemArray.push(spotOn);
        }

        if (permissions.registry.admin) {
            const registry = {
                name: "Registry",
                icon: <ViewList />,
                items: [
                    { name: "Job models", path: "/registry/jobmodels" },
                    { name: "Job templates", path: "/registry/jobtemplates" },
                    { name: "Products", path: "/registry/products" },
                    { name: "Keys", path: "/registry/keys" },
                    { name: "Customers", path: "/registry/customers" },
                    { name: "Employees", path: "/registry/employees" }
                ]
            };
            navigationItemArray.push(registry);
        }
       

    }
    else {
        if (permissions.management.admin) {
            const management = {
                name: "Management",
                icon: <Schedule />,
                items: [
                    { name: "Calendar", path: "/management/calendar" },
                    { name: "Jobcards", path: "/management/jobcards" },
                    { name: "Worklogs", path: "/management/worklogs" },
                    { /* name: "Schedule", path: "/management/schedule" */},
                    {name: "WorkDays", path: "/management/workdays"},                ]
            };
            navigationItemArray.push(management);
        }

        if (permissions.reports.admin) {
            const reports = {
                name: "Reports",
                icon: <Receipt />,
                //items: [{name: "Time reports", path: "/reports/timereports"}]
                items: [
                    {name: "Created reports", path: "/reports"},
                    {name: "Time reports", path: "/reports/timereports"},
                    {name: "Bill reports", path: "/reports/billreports"},
                ]
            };
            navigationItemArray.push(reports);
        }

        if (permissions.billing.admin) {
            const billing = {
                name: "Billing",
                icon: <EuroSymbol />,
                items: [
                    { name: "Created bills", path: "/bills" },
                    { name: "Create bills", path: "/billing" },
                ]
            };
            navigationItemArray.push(billing);
        }

        if (!permissions.crm) permissions.crm = {};
        if (permissions.crm.admin) {
            const crm = {
                name: "CRM",
                icon: <Email />,
                items: [
                    { name: "CRM email", path: "/crm/email" }
                ]
            };
            navigationItemArray.push(crm);
        }

        console.log('permissions', permissions);
        if (permissions.spotOn) {
            const spotOn = {
                name: "SpotOn",
                icon: <ViewList />,
                items: [
                    { name: "Vehicles", path: "spoton/vehicles" }
                ]
            }
            navigationItemArray.push(spotOn);
        }

        if (permissions.registry.admin) {
            const registry = {
                name: "Registry",
                icon: <ViewList />,
                items: [
                    { name: "Job models", path: "/registry/jobmodels" },
                    { name: "Job templates", path: "/registry/jobtemplates" },
                    { name: "Products", path: "/registry/products" },
                    { name: "Keys", path: "/registry/keys" },
                    { name: "Customers", path: "/registry/customers" },
                    { name: "Employees", path: "/registry/employees" }
                ]
            };
            navigationItemArray.push(registry);
        }
    }
    console.log(navigationItemArray);
    return navigationItemArray;
};

export const checkManagementPermissions = user => {
    return user.settings.permissions.mainUser || user.settings.permissions.management.admin
};

export const checkRedirect = listItems => {
    let path = "/settings";

    if (listItems.length > 0) {
        path = listItems[0].items[0].path;
    }

    return path;
};
