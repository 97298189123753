import React, { Component } from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import moment from 'moment';
import {
    Checkbox,
    Button,
    FormControlLabel,
    CircularProgress,
    TextField,
    MenuItem,
} from "@material-ui/core";

import WorklogAC from "./WorklogAC";
import { DataSearch } from "../util/DataSearch";
import { validateFBWorklog } from "../validations/worklog";
import { employeesToString, dateTime, tasksToString, billableTime } from "../util/StringHelpers";
import { validateBillableTime } from "./WorklogHelpers";

import { WorklogSelect, SelectItem } from "./WorklogSelect";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ConfirmationDialog from './ConfirmationDialog';

import { setWorklogs } from "../actions/worklogActions";
import { setCustomers } from "../actions/customerActions";
import { setEmployees } from "../actions/employeeActions";

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import {isEmpty} from "../validations/isEmpty";

import "./WorklogsLanding2.css";
import { getOccurrences } from "../CalendarComponents/OccurrencesComponent";
import NotReportedListComponent from './NotReportedListComponent';

const INITIAL_DB_QUERY_DAYS_BACK = 45;

class WorklogsLanding2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            worklogStatusArray: ["all", "notReported", "Ongoing", "Finished", "Submitted", "Confirmed", "Billed", "Archived", "Cancelled"],
            searchCustomer: "",
            searchEmployee: "",
            worklogs: [],
            worklogsLoading: true,
            selectedWorklogs: [],
            searchResults: [],
            massSelect: false,
            selectedStatus: "all",
            dateRange: [moment(moment().subtract(1, "month")).startOf("day").toDate(), moment().endOf("month").toDate()],  //get month backwards, then extend search if date is backdated more
            ////dateRange = [moment(`${startDate}T000000`).toDate(), moment(`${endDate}T235959`).toDate()];
            dateChanged: true,
            showCancelled: false,
            open: false,
            updated: false,
            sortDir: "desc",
            worklogsSaving: false,
            worklogsSaved: false,
            wlQueryTimerStart: moment(),
            jobQueryTimerStart: moment(),
            wlQueryFromValue: moment().subtract(INITIAL_DB_QUERY_DAYS_BACK, "day").startOf('day').format("YYYYMMDD"),
            produts: []
        }
    }

    componentDidMount() {
        const { user } = this.props.user;
        //const {worklogs} = this.props.worklogs;
        const { worklogs, dateRange } = this.state;
        const { customers } = this.props.customers;
        const { employees } = this.props.employees;
        //const {products} = this.props.products;

        if (!customers.length > 0) firebase.firestore().collection("customers").where("companyID", "array-contains", user.companyID).get().then(querySnap => this.onQuerySnap(querySnap, "customers"));
        if (!employees.length > 0) firebase.firestore().collection("employees").where("companyID", "==", user.companyID).where("isActive", "==", "true").get().then(querySnap => this.onQuerySnap(querySnap, "employees"));

        this.unsubscribeProductListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('products').onSnapshot(this.onProductSnapshot);


        this.unsubsubscribeCompanyListener =
            firebase.firestore().collection("company").doc(user.companyID).onSnapshot(docScap => {
                if (docScap) {
                    const company = docScap.data();
                    this.setState({ company })
                }
            });

        this.getURLParameters();

        //if (!worklogs.length > 0)  firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").get().then(querySnap => this.onWorklogQuerySnap(querySnap)

        this.unsubscribeWorksiteListener = firebase.firestore().collection('company').doc(this.props.user.user.companyID).collection('worksites')
            .onSnapshot(querySnapshot => this.onWorksiteSnapShot(querySnapshot));

        this.queryJobsForUnreported();

        this.queryWorklogs(dateRange);

        /*
        if (customers.length > 0 && employees.length > 0 && worklogs.length > 0) {

            this.setState({loading: false}
                //() => this.getURLParameters(),
                //() => this.queryJobsForUnreported()
            )
        }*/
    }

    componentWillUnmount() {
        if (this.unsubscribeWorklogListener) this.unsubscribeWorklogListener();
        if (this.unsubscribeWorksiteListener) this.unsubscribeWorksiteListener();
        if (this.unsubscribeJobListener) this.unsubscribeJobListener();
        if (this.unsubsubscribeCompanyListener) this.unsubsubscribeCompanyListener();
        if (this.unsubscribeProductListener) this.unsubscribeProductListener();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const customerLoading = prevProps.customers.loading;
        const employeesLoading = prevProps.employees.loading;
        //const worklogsLoading = prevProps.worklogs.loading;
        const worklogsLoading = this.state.worklogsLoading;

        if (!customerLoading && !employeesLoading && !worklogsLoading && !this.state.updated) {
            this.setState({ loading: false, updated: true }, () => this.getURLParameters())
        }
    }

    queryWorklogs = (dateRange) => {
        const { user } = this.props.user;
        const { worklogs, wlQueryFromValue } = this.state;

        let newQueryFromValue = { ...wlQueryFromValue };

        console.log("DB query Worklogs dateRange[0] ", dateRange[0] ? moment(dateRange[0]).format("YYYYMMDDTHHmmss") : "N/A");
        //let startValue = moment(dateRange[0]).format("YYYYMMDD");
        //let startValue = dateRange && dateRange[0] ? moment(dateRange[0]).format("YYYYMMDDTHHmmss") : moment().subtract(INITIAL_DB_QUERY_DAYS_BACK, "day").set({hour: 0, minute: 0, second: 0, millisecond: 0}).format("YYYYMMDDTHHmmss");

        let startValue = dateRange && dateRange[0] ? moment(dateRange[0]).format("YYYYMMDDTHHmmss") : wlQueryFromValue;

        console.log("DB query Worklogs startValue 1", startValue);

        // query INITIAL_DB_QUERY_DAYS_BACK unlessa start date is further back
        if (startValue && moment(startValue) < moment(wlQueryFromValue)) {
            newQueryFromValue = startValue;
        }

        console.log("DB query Worklogs startValue 2", startValue);

        this.setState({ loading: true, worklogsLoading: true, wlQueryFromValue: newQueryFromValue, wlQueryTimerStart: moment() });

        if (this.unsubscribeWorklogListener) this.unsubscribeWorklogListener();

        this.unsubscribeWorklogListener = firebase.firestore().collection('company').doc(user.companyID).collection("worklogs")
            .where("jobStartDT", ">=", startValue)
            .orderBy("jobStartDT", "desc")
            .onSnapshot(querySnap => this.onWorklogQuerySnap(querySnap));
    }

    onWorklogQuerySnap = querySnap => {
        const { wlQueryTimerStart } = this.state;
        const worklogs = [];

        console.log("DEBUG: WORKLOG QUERY TOOK ", (moment() - wlQueryTimerStart) / 1000);

        const timer1 = moment();
        querySnap.forEach(doc => {

            const worklog = doc.data();
            worklog.id = doc.id;
            const { isValid } = validateFBWorklog(worklog);

            if (isValid) worklogs.push(worklog);
            else { console.log("worklog not valid!!  ", worklog) }
        });

        //this.props.setWorklogs(worklogs);
        console.log("DEBUG: WORKLOG LOOP TOOK ", (moment() - timer1) / 1000);
        console.log("worklogs ", worklogs)

        this.setState({
            worklogs: worklogs,
            worklogsLoading: false,
            //loading: false,
        }, () => this.filterWorklogs());
    };


    onQuerySnap = (querySnap, dataName) => {
        const dataArr = [];

        querySnap.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;
            if (dataName === "employees") data.name = `${data.firstName} ${data.familyName}`;
            dataArr.push(data);
        });

        if (dataName === "customers") this.props.setCustomers(dataArr);
        if (dataName === "employees") this.props.setEmployees(dataArr);
    };

    onWorksiteSnapShot = querySnapshot => {
        let worksites = [];

        querySnapshot.forEach(doc => {
            let worksite = doc.data();
            worksite.id = doc.id;
            worksites.push(worksite);
        });

        this.setState({
            worksites: worksites,
        });
    };

    onProductSnapshot = querySnapshot => {
        let products = [];

        querySnapshot.forEach(doc => {
            let product = doc.data();
            product.id = doc.id;
            products.push(product);
        });

        this.setState({
            products: products,
        });
    };

    queryJobsForUnreported = () => {
        const { user } = this.props.user;

        this.setState({ jobQueryTimerStart: moment() })

        //TODO: no need to be snapshot, plus limit somehow?
        this.unsubscribeJobListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('jobs').onSnapshot(this.onJobSnapshot);

    }

    getCustomer = id => {
        const { customers } = this.props.customers;

        const customer = customers.find(cust => cust.id === id);

        if (customer) {
            return customer.name;
        }
        else {
            return "Couldn't find name";
        }
    };

    getEmployee = id => {
        const { employees } = this.props.employees;

        const employee = employees.find(emp => emp.id === id);

        if (employee) {
            return employee.name;
        }
        else {
            return "Couldn't find name";
        }

    };

    searchJobs = (searchCustomer, searchEmployee, logStatus, dateRange, dateChanged) => {
        const { jobs, worklogs, discardedWorklogs, company } = this.state;
        const { customers } = this.props.customers;
        const { employees } = this.props.employees;

        let jobList = [];
        let notReported = [];

        console.log("searchJobs dateRange ", dateRange);
        const startDate = dateRange[0];
        const endDate = dateRange[1];

        // managementStart holds date wich is considered as "Start jobs from this onward"
        const managementStart = company && company.settings && company.settings.job && company.settings.job.managementStart ? company.settings.job.managementStart : "2019-04-16T00:00";

        // countBackAmount is x number of weeks company wants to count backwards
        const countBackAmount = company && company.settings && company.settings.job && company.settings.job.workDeadline ? company.settings.job.workDeadline : 2;

        // xWeekMark is x number of weeks which determines using managementStart or counting x number of weeks backwards
        const xWeekMark = moment(managementStart).add(countBackAmount, "weeks");

        // Set startMoment based on these values
        //const startMoment = moment().isAfter(xWeekMark) ? moment().startOf("day").subtract(countBackAmount, "weeks") : moment(managementStart);
        const startMoment = startDate ? moment(startDate) : moment().startOf("day").subtract(countBackAmount, "weeks");

        // If whole month is selected, stops counting jobs past current Date
        const isEndAfterToday = moment(endDate).isAfter(moment().endOf("day"));

        const endMoment = dateChanged ? (isEndAfterToday ? moment().endOf("day") : moment(endDate)) : moment().endOf("day");

        let counter = 0;

        jobs && jobs.forEach(job => {
            const occurrencesArray = getOccurrences(job, startMoment, endMoment);

            const newArr = [];

            occurrencesArray && occurrencesArray.forEach(occ => {
                const newJob = { ...job };
                newJob.startDT = moment(occ.start);
                newJob.endDT = moment(occ.end);
                newJob.counter = counter;
                if (moment(newJob.startDT).isBetween(startMoment, endMoment)) newArr.push(newJob);
                counter++;
            });

            jobList = jobList.concat(newArr);
        });


        const reference = {};
        let worklogArray = worklogs.concat(discardedWorklogs);

        //console.log("searchJobs startMoment-endmomoment ", startMoment.format("YYYYMMDDTHHmmss") , " - ", endMoment.format("YYYYMMDDTHHmmss"));

        for (let i = 0; i < worklogArray.length; i++) {

            //DBG
            //if (worklogArray[i] && worklogArray[i].jobID === 'CpTDcAlhmHvpTQdjB4EY') console.log("DEBUG böhlinki :", worklogArray[i])
            //if (worklogArray[i] && worklogArray[i].jobID === 'PUH7G5m9zdFzZc6puiMo') console.log("DEBUG ARTS jobStartDT:", worklogArray[i].jobStartDT)
            //if (worklogArray[i] && worklogArray[i].id === 'e4xrMNruGagOij9oOhzs') console.log("DEBUG ARTS FOUND MATCH WL:", worklogArray[i])

            if (worklogArray[i]) {
                if (moment(worklogArray[i].startDT).isBetween(startMoment, endMoment)) {
                    let id = worklogArray[i].jobID;

                    //reference[id] ? reference[id] += 1 : reference[id] = 1
                    reference[id] ? reference[id].push(worklogArray[i].jobStartDT) : reference[id] = [worklogArray[i].jobStartDT];
                    //if (id === 'CpTDcAlhmHvpTQdjB4EY') console.log("DEBUG ADD böhlinki 2 :", reference[id])
                }

            } else {
                console.log("empty worklogArray[i], i =  ", i)
            }
        }

        //NOTE: this does not match the case where job schdule i changed after worklog is started... will show unreported, even there is a worklog!!
        for (let i = 0; i < jobList.length; i++) {
            let id = jobList[i].id;
            const startDT = moment(jobList[i].startDT).format('YYYYMMDDTHHmmss');
            if (!reference[id] || !reference[id].includes(startDT)) {
                console.log("ADD TO NOTREPORTED ", startDT, id, reference[id], jobList[i].customerLabel)
                notReported.push(jobList[i])
            }

        }

        /*
        const result = jobList.reduce(function (r, job) {
            let f = worklogs.find(log => log.jobID === job.id && moment(log.jobStartDT).isSame(job.startDT));
            if (!f) {
                r.push(job);
            }
            return r;
        }, []);

        notReported = result;
        */

        if (searchCustomer.length > 0 && searchEmployee.length > 0) {
            const emp = employees.find(employee => employee.name === searchEmployee);
            const cust = customers.find(customer => customer.name === searchCustomer);
            const newList = [];
            notReported.forEach(job => {
                job.employees && job.employees.forEach(employee => {
                    if (employee.id === emp.id && job.customer === cust.id) {
                        newList.push(job)
                    }
                });
            });
            notReported = newList;
        }
        else {
            if (searchEmployee.length > 0 && searchCustomer.length === 0) {
                const emp = employees.find(employee => employee.name === searchEmployee);
                const newList = [];
                notReported.forEach(job => {
                    job.employees && job.employees.forEach(employee => {
                        if (employee.id === emp.id) {
                            newList.push(job)
                        }
                    });
                });


                notReported = newList;
            }

            if (searchCustomer.length > 0 && searchEmployee.length === 0) {
                const cust = customers.find(customer => customer.name === searchCustomer);
                const newList = notReported.filter(item => item.customer === cust.id);
                notReported = newList;
            }
        }

        const sortList = notReported.sort(function (a, b) {
            if (moment(a.startDT).isBefore(b.startDT)) return -1;
            if (moment(a.startDT).isAfter(b.startDT)) return 1;
        });


        this.setState({
            notReported: sortList,
            showType: "jobs",
            loading: false,
            searchCustomer: searchCustomer,
            searchEmployee: searchEmployee,
            logStatus: logStatus,
            dateRange: dateRange,
            dateChanged: dateChanged
        });
    };


    onJobSnapshot = querySnapshot => {
        const { jobQueryTimerStart } = this.state;
        const jobs = [];

        console.log("DEBUG: JOB QUERY TOOK ", (moment() - jobQueryTimerStart) / 1000);

        const timer1 = moment();
        querySnapshot.forEach(doc => {
            let job = doc.data();
            job.id = doc.id;

            if (!job.rrule) console.log(job);
            jobs.push(job);
        });

        console.log("DEBUG: JOB LOOP TOOK ", (moment() - timer1) / 1000);

        this.setState({
            jobs: jobs,
        });
    };

    getURLParameters = () => {
        const { customers } = this.props.customers;
        const { employees } = this.props.employees;
        let { dateRange } = this.state;

        const params = new URLSearchParams(this.props.location.search);
        const customer = params.get("customer");
        const employee = params.get("employee");
        const status = params.get("status");
        const startDate = params.get("startDate");
        const endDate = params.get("endDate");

        let searchCustomer = "";
        let searchEmployee = "";
        let dateChanged = false;
        //let dateRange = [moment().startOf("month").toDate(), moment().endOf("month").toDate()];
        if (customer) searchCustomer = customers.find(customerObj => customerObj.name.toLowerCase() === customer.split("_").join(" "));
        if (employee) searchEmployee = employees.find(employeeObj => employeeObj.name.toLowerCase() === employee.split("_").join(" "));
        if (startDate && endDate) {
            dateRange = [moment(`${startDate}T000000`).toDate(), moment(`${endDate}T235959`).toDate()];
            dateChanged = true;
        }

        this.setState({
            searchEmployee: searchEmployee ? searchEmployee.name : "",
            searchCustomer: searchCustomer ? searchCustomer.name : "",
            selectedStatus: status ? status : "all",
            dateRange,
            dateChanged
        }, () => this.filterWorklogs());

        this.shouldLoadMoreDataFromDB(dateRange, dateChanged);

    };

    shouldLoadMoreDataFromDB = (dateRange, dateChanged) => {
        const { wlQueryFromValue } = this.state;
        const startDate = dateRange && dateRange[0] ? moment(dateRange[0]).format("YYYYMMDD") : moment().subtract(INITIAL_DB_QUERY_DAYS_BACK, "day").format("YYYYMMDD");

        //trigger DB query if start date is earlier than INITIAL_DB_QUERY_DAYS_BACK days
        console.log("shouldLoadMoreDataFromDB: 1", startDate);
        console.log("shouldLoadMoreDataFromDB: 2", moment().subtract(INITIAL_DB_QUERY_DAYS_BACK, "day").format("YYYYMMDD"));
        console.log("shouldLoadMoreDataFromDB: 3", wlQueryFromValue);

        if (dateChanged && startDate && moment(wlQueryFromValue) > moment(startDate)) {
            console.log("getURLParameters: trigger DB query: start date is earlier than INITIAL_DB_QUERY_DAYS_BACK days ", startDate);
            this.queryWorklogs(dateRange);
        } else {
            console.log("NOPE");
        }
    }

    searchURLCombiner = () => {
        const { searchCustomer, searchEmployee, selectedStatus, dateRange } = this.state;

        let customerSearchString = "";
        let employeeSearchString = "";
        let statusSearchString = "";

        if (searchCustomer) {
            const value = searchCustomer.toLowerCase().split(" ").join("_");
            customerSearchString = `customer=${value}`
        }

        if (searchEmployee) {
            const value = searchEmployee.toLowerCase().split(" ").join("_");
            employeeSearchString = `employee=${value}`;
        }

        if (selectedStatus) {
            statusSearchString = `status=${selectedStatus}`;
        }

        const startValue = dateRange && dateRange[0] ? moment(dateRange[0]).format("YYYYMMDD") : moment().subtract(1, "month").toDate();
        const endValue = dateRange && dateRange[1] ? moment(dateRange[1]).format("YYYYMMDD") : moment().endOf("day").toDate().format("YYYYMMDD");
        const dateStartString = `startDate=${startValue}`;
        const dateEndString = `endDate=${endValue}`;

        const finalString = `/management/worklogs/?${customerSearchString}${employeeSearchString ? "&" : ""}${employeeSearchString}${statusSearchString ? "&" : ""}${statusSearchString}&${dateStartString}&${dateEndString}`;
        this.props.history.push(finalString);

        this.filterWorklogs();
    };

    onValueSelect = (item, field) => {
        let value = typeof item === "object" ? item.name : item;
        this.setState({ [field]: value }, () => this.searchURLCombiner());
    };

    onDateChange = event => {
        console.log("onDateChange: ", event)
        const dateRange = event ? event : [moment().subtract(1, "month").toDate(), moment().endOf("day").toDate()]
        console.log("dateRange: ", dateRange);


        this.setState({ dateRange: dateRange, dateChanged: true }, () => this.searchURLCombiner());

        this.shouldLoadMoreDataFromDB(dateRange, true);
    };

    onWorklogSelect = id => {
        const { selectedWorklogs } = this.state;

        if (!selectedWorklogs.includes(id)) selectedWorklogs.push(id);
        else {
            const removeIndex = selectedWorklogs.indexOf(id);
            selectedWorklogs.splice(removeIndex, 1);
        }
        console.log("onWorklogSelect: ", selectedWorklogs)
        this.setState({ selectedWorklogs });
    };

    onWorklogMassSelect = () => {
        const { searchResults } = this.state;
        let selectedWorklogs = [];
        if (this.state.massSelect) selectedWorklogs = [];
        else selectedWorklogs = searchResults.map(worklog => worklog.id);

        this.setState(prevState => ({ selectedWorklogs, massSelect: !prevState.massSelect }));
    };

    filterWorklogs = () => {
        const { searchCustomer, searchEmployee, selectedStatus, dateRange, dateChanged, showCancelled } = this.state;
        const { customers } = this.props.customers;
        const { employees } = this.props.employees;
        //const {worklogs} = this.props.worklogs;
        const { worklogs } = this.state;

        const timer1 = moment();

        this.setState({ loading: true });

        console.log("filterWorklogs selectedStatus : ", selectedStatus);
        const customer = customers.find(customer => customer.name === searchCustomer);
        const employee = employees.find(employee => employee.name === searchEmployee);


        //if notReported -> query jobs that done have worklogs instead
        if (selectedStatus === "notReported") return this.searchJobs(searchCustomer, searchEmployee, selectedStatus, dateRange, dateChanged);

        //else continue with worklog search
        const worklogSearch = new DataSearch();

        worklogSearch.setSearchData(worklogs);
        if (customer) worklogSearch.appendField("customer", customer.id, "isEqual");
        if (employee) worklogSearch.appendField("employee", employee.id, "includes");
        if (selectedStatus !== "all") worklogSearch.appendField("status", selectedStatus, "isEqual");

        console.log("filterWorklogs : dateRange " + moment(dateRange[0]).format("YYYYMMDDTHHmmss") + " - " + moment(dateRange[1]).format("YYYYMMDDTHHmmss"));
        //dateRange = [moment(`${startDate}T000000`).toDate(), moment(`${endDate}T235959`).toDate()];

        if (dateChanged) {
            const dateObj = {
                selection: {
                    startDate: moment(dateRange[0]).format("YYYYMMDDTHHmmss"),
                    endDate: moment(dateRange[1]).format("YYYYMMDDTHHmmss"),
                    //startDate: moment(`${dateRange[0]}T000000`).format("YYYYMMDDTHHmmss"),
                    //endDate: moment(`${dateRange[1]}T235959`).format("YYYYMMDDTHHmmss"),
                }
            };
            console.log("filterWorklogs : dateObj.selection ", dateObj.selection)

            worklogSearch.appendField("startDT", dateObj, "between");
        }


        if (!showCancelled && selectedStatus === "all" && selectedStatus !== "notReported") worklogSearch.appendField("status", "Cancelled", "notEqual");

        const results = worklogSearch.executeSearch(this.state.sortDir, "startDT");

        console.log("DEBUG: FILTER  WORKLOGS  TOOK ", (moment() - timer1) / 1000);

        console.log("after executeSearch results -filtered:  ", results.length);

        this.setState({
            searchResults: results,
            showType: "logs",
            loading: false,
            searchCustomer: searchCustomer,
            searchEmployee: searchEmployee,
            selectedStatus,
            dateRange: dateRange,
            dateChanged: dateChanged,
            showCancelled: showCancelled
        });
    };

    onSearchParameterReset = () => {
        this.setState({
            searchResults: [],
            searchCustomer: "",
            searchEmployee: "",
            selectedStatus: "all",
            dateRange: [moment().subtract(1, "month").startOf("day").toDate(), moment().endOf("month").toDate()]
        }, this.filterWorklogs);

        this.props.history.replace("/management/worklogs")
    };

    renderSelectBox = () => {
        const { t } = this.props;
        return (
            <TextField
                value="Select"
                variant="outlined"
                style={{ height: 60, width: 120 }}
                onChange={event => this.changeStatus(event)}
                select
            >
                <MenuItem value="Select" key="Select">{t("Select")}</MenuItem>
                <MenuItem value="Finished" key="Finished">{t("Finished")}</MenuItem>
                <MenuItem value="Submitted" key="Submitted">{t("Submitted")}</MenuItem>
                <MenuItem value="Confirmed" key="Confirmed">{t("Confirmed")}</MenuItem>
                <MenuItem value="Billed" key="Billed">{t("Billed")}</MenuItem>
                <MenuItem value="Archived" key="Archived">{t("Archived")}</MenuItem>
            </TextField>
        );
    };

    changeStatus = (event) => {
        const { selectedWorklogs, worklogs } = this.state;
        const { user } = this.props.user;

        console.log("changeStatus event ", event);
        console.log("selectedWorklogs ", selectedWorklogs);

        if (selectedWorklogs.length === 1) {
            const worklogId = selectedWorklogs[0];
            const id = worklogId;
            if (worklogs && worklogs.length > 0) {
                const worklog = worklogs.find(worklog => worklog.id === id);
                console.log("found worklog ", worklog);
                if (worklog) {
                    worklog.status = event.target.value;
                    console.log(" worklog.status  ", worklog.status);
                    firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').doc(id).set(worklog);
                }
            }
            this.setState({
                selectedWorklogs: []
            });
        }
        else {
            this.setState({
                confirmationText: event.target.value,
                confirmationDialog: true
            });
        }
    };

    onStatusConfirm = (nextPatch) => {
        const { selectedWorklogs, worklogs, confirmationText } = this.state;
        const { user } = this.props.user;

        //get actual worklogs thats been selected for batch update
        let workLogsForBatching = [];
        selectedWorklogs.map(selectedWLID => {
            const worklog = worklogs.find(worklog => worklog.id === selectedWLID);
            if (worklog) workLogsForBatching.push(worklog);
        });

        console.log("workLogsBatching ", workLogsForBatching);

        let firstBatch = [];
        if (!nextPatch) firstBatch = workLogsForBatching.splice(0, 299);
        else firstBatch = nextPatch.splice(0, 299);

        let rest = [];
        if (!nextPatch) rest = workLogsForBatching.splice(0, workLogsForBatching.length - 1);
        else rest = nextPatch.splice(0, rest.length - 1);

        const batch = firebase.firestore().batch();

        firstBatch.forEach(log => {
            log.status = confirmationText;

            const logRef = firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').doc(log.id);
            batch.set(logRef, log);
        });


        batch.commit()
            .then(() => {
                if (rest.length > 0) this.onStatusConfirm(rest);
                else {
                    console.log("rest lenght", rest.length);
                    console.log("set state to complete")
                    this.setState({ worklogsSaved: true })
                }
            })
            .catch(err => console.log("error while saving ", err));
    };


    renderTotalDuration = () => {
        const { searchResults } = this.state;
        const { user } = this.props.user;

        let totalTimeInMillis = 0.0;

        if (searchResults && searchResults.length > 0) {
            searchResults.map(worklog => {
                if (worklog.duration) totalTimeInMillis += worklog.duration
            })
        }
        //return (billing.billableHours / 3600000).toFixed(2).replace(".", user.lang === "fi" ? "," : ".");
        return (totalTimeInMillis / 3600000).toFixed(2).replace(".", user.lang === "fi" ? "," : ".") + "h";
    }

    renderTotalHours = () => {
        const { searchResults } = this.state;
        const { user } = this.props.user;

        let totalTimeInMillis = 0.0;

        if (searchResults && searchResults.length > 0) {
            searchResults.map(worklog => {
                if (worklog.billing && worklog.billing.billableHours) {
                    const numEmpl = worklog.employee && Array.isArray(worklog.employee) ? worklog.employee.length : 1;
                    totalTimeInMillis += (worklog.billing.billableHours * numEmpl);
                }
            })
        }
        //return (billing.billableHours / 3600000).toFixed(2).replace(".", user.lang === "fi" ? "," : ".");
        return (totalTimeInMillis / 3600000).toFixed(2).replace(".", user.lang === "fi" ? "," : ".") + "h";
    }

    getWorksiteName = wsID => {
        const { worksites } = this.state;
        const { t } = this.props;

        const worksite = worksites && worksites.find(worksite => worksite.id === wsID);

        if (worksite && worksite.name) return worksite.name;
        else if (worksite && worksite.number) return t("Worksite number") + worksite.number;
        else return "missing";
    }

    isProductBillable = (pNumber) => {
        const { t, user } = this.props;
        const { products } = this.state;
        const replaceValue = user && user.lang === "fi" ? "," : ".";

        const product = products.find(product => parseInt(product.pNumber) === parseInt(pNumber));
        if (product && (product.isBillable || product.isBillable.toLowerCase) === "true")
            return true;
        //did not get product billability, return false
        return false;
    }

    getTasksToStingWithBillability = worklog => {
        const { products } = this.state;
        const { t } = this.props;

        const taskNameArr = [];
        let divClassName = "wl-list-item small ";
        let taskLine = "";

        if (worklog.status === "Cancelled" || worklog.previousStatus === "Cancelled") {
            divClassName += " warn "
            return <div className={divClassName}>{t("cancelled")}</div>;
        }

        const billing = worklog.billing;
        if (!billing || (billing && isEmpty(billing.billableHoursByTask))) return <div className="wl-list-item small warn">{t("No_task_selected")}</div>
        else {
            billing.billableHoursByTask.forEach(task => {
                if (!this.isProductBillable(task.pNumber)) {
                    divClassName += " not-billable "  //if product is not billabe or that info does not exist, change color
                }
                taskNameArr.push(task.name + ("(#" + task.pNumber + ")"));

            });
        }


        return <div className={divClassName}>{taskNameArr.join(", ")}</div>;

    };


    renderListItem = worklog => {
        const { selectedWorklogs } = this.state;
        const { user } = this.props.user;
        const { customers } = this.props.customers;
        const { employees } = this.props.employees;

        const { t } = this.props;

        const customer = customers && customers.find(customer => customer.id === worklog.customer);
        const validateBillableTimeString = validateBillableTime(worklog.billing, user);
        const worksiteString = this.getWorksiteName(worklog.worksite);

        return (
            <div className="wl-list-row" key={worklog.id}>
                <div className="wl-left">
                    <Checkbox
                        checked={selectedWorklogs.includes(worklog.id)}
                        onChange={() => this.onWorklogSelect(worklog.id)}
                        style={{ color: '#000' }}
                    />
                </div>
                <Link to={`/management/worklogs/edit/${worklog.id}`} className="wl-link">
                    <div className="wl-right">
                        <div className="wl-list-item large">{customer ? customer.name : " - "}</div>
                        {worksiteString !== "missing" &&
                            <div className="wl-list-item large">{worksiteString}</div>
                        }
                        {worksiteString === "missing" &&
                            <div className="wl-list-item large warn">{t('No_worksite')}</div>
                        }
                        <div className="wl-list-item large">{employeesToString(worklog.employee, employees)}</div>
                        <div className="wl-list-item small">{dateTime(worklog.startDT, "D", user)}</div>
                        <div
                            className="wl-list-item small">{`${dateTime(worklog.startDT, "T", user)} - ${dateTime(worklog.endDT, "T", user)}`}</div>


                        {tasksToString(worklog) === "cancelled" &&
                            <div className="wl-list-item small warn">{tasksToString(worklog)}</div>}
                        {/*tasksToString(worklog) != "cancelled" &&
                            <div className="wl-list-item small">{tasksToString(worklog)}</div>*/}

                        {this.getTasksToStingWithBillability(worklog)}

                        <div className="wl-list-item small">{billableTime(worklog.billing, user)}</div>
                        {validateBillableTimeString === "OK" &&
                            <div className="wl-list-item small">{t(validateBillableTimeString)}</div>}
                        {validateBillableTimeString != "OK" &&
                            <div className="wl-list-item small warn">{t(validateBillableTimeString)}</div>}
                        <div className="wl-list-item small">{t(worklog.status)}</div>
                    </div>
                </Link>

            </div>
        )
    };

    render() {
        const { loading, workLogsLoading, searchCustomer, searchEmployee, selectedWorklogs, searchResults, selectedStatus, dateRange, dateChanged, showType } = this.state;
        const { customers } = this.props.customers;
        const { employees } = this.props.employees;
        const { t } = this.props;

        console.log("render(): selectedWorklogs ", selectedWorklogs)

        return (
            <div className="wl-container">
                <div className="wl-search">
                    <WorklogAC
                        searchValues={["name"]}
                        searchData={customers}
                        listShowField={"name"}
                        listMaxLength={5}
                        onSelect={this.onValueSelect}
                        onChange={event => this.setState({ searchCustomer: event })}
                        value={searchCustomer || ""}
                        fieldName="searchCustomer"
                        placeholder={t("Customer")}
                    />

                    <WorklogAC
                        searchValues={["name"]}
                        searchData={employees}
                        listShowField={"name"}
                        listMaxLength={5}
                        onSelect={this.onValueSelect}
                        onChange={event => this.setState({ searchEmployee: event })}
                        value={searchEmployee || ""}
                        fieldName="searchEmployee"
                        placeholder={t("Employee")}
                    />

                    <WorklogSelect
                        value={t(this.state.selectedStatus)}
                        onChange={event => this.onValueSelect(event, "selectedStatus")}
                        placeHolder={t("Status")}
                    >
                        {this.state.worklogStatusArray.map(status => (
                            <SelectItem value={status} key={status}>{t(status)}</SelectItem>
                        ))}

                    </WorklogSelect>

                    <DateRangePicker
                        value={this.state.dateRange}
                        locale="fi-FI"
                        className="wl-date-range"
                        onChange={this.onDateChange}
                        isOpen={this.state.open}
                    />

                    <FormControlLabel
                        style={{ height: 40, marginLeft: 10 }}
                        control={
                            <Checkbox
                                style={{ color: '#1770B2' }}
                                checked={this.state.showCancelled}
                                onChange={() => this.setState(prevState => ({ showCancelled: !prevState.showCancelled }), () => this.filterWorklogs())}
                            />
                        }
                        label={t("Show_cancelled")}
                    />

                    <Button
                        variant={"contained"}
                        style={{ backgroundColor: '#1770B2', color: '#FFF', margin: "0 10px", height: 40 }}
                        onClick={this.onSearchParameterReset}
                    >
                        {t("Reset")}
                    </Button>

                    <Button
                        variant={"contained"}
                        style={{ backgroundColor: '#1770B2', color: '#FFF', height: 40 }}
                        onClick={this.filterWorklogs}
                    >
                        {t("Search")}
                    </Button>
                </div>


                {loading &&
                    <div style={{
                        alignSelf: 'center',
                        width: '50vw',
                        height: 'auto',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '3%'
                    }}>
                        <CircularProgress />
                    </div>
                }

                {!this.state.loading && this.state.showType === "jobs" &&
                    <NotReportedListComponent
                        customers={customers}
                        employees={employees}
                        models={this.state.models}
                        notReported={this.state.notReported}
                        getCustomer={this.getCustomer}
                        getEmployee={this.getEmployee}
                        getWorksite={this.getWorksiteName}
                    />
                }

                {selectedWorklogs && selectedWorklogs.length > 0 &&
                    <div className="wl-on-select-header">
                        <div className="wl-on-select-row">
                            <div className="wl-list-item large">{t("Change_message")}</div>
                            {this.renderSelectBox()}
                        </div>
                    </div>
                }

                {!this.state.loading && this.state.showType === "logs" &&
                    <div className="wl-list-header">
                        <div className="wl-left">
                            <Checkbox
                                checked={this.state.massSelect}
                                onChange={this.onWorklogMassSelect}
                                className="wl-checkbox"
                                style={{ color: '#1770b2' }}
                            />
                        </div>

                        <div className="wl-header-right">
                            <div className="wl-list-item large">{t("Customer")}</div>
                            <div className="wl-list-item large">{t("Worksite")}</div>
                            <div className="wl-list-item large">{t("Employee")}</div>
                            <div className="wl-list-item small">{t("Date")}</div>
                            <div className="wl-list-item small">{t("Time")}</div>
                            <div className="wl-list-item small">{t("Task")}</div>
                            <div className="wl-list-item small">{t("Billable hours")}</div>
                            <div className="wl-list-item small">{t("Check result")}</div>
                            <div className="wl-list-item small">{t("Status")}</div>
                        </div>


                    </div>
                }

                {!this.state.loading && this.state.showType === "logs" &&
                    <div className="wl-list-body">

                        {!this.state.loading && searchResults.length > 0 &&
                            searchResults.map(this.renderListItem)}


                        {selectedWorklogs && selectedWorklogs.length > 0 &&
                            <div className="wl-on-select-header">
                                <div className="wl-on-select-row">
                                    <div className="wl-list-item large">{t("Change_message")}</div>
                                    {this.renderSelectBox()}
                                </div>
                            </div>
                        }

                        {this.state.confirmationDialog &&
                            <ConfirmationDialog
                                open={this.state.confirmationDialog}
                                onClose={() => this.setState({
                                    confirmationDialog: false,
                                    worklogsSaving: false,
                                    massSelect: false,
                                    worklogsSaved: false,
                                    selectedWorklogs: [],
                                    confirmationText: ""
                                })}
                                onConfirm={() => this.setState({ worklogsSaving: true }, () => this.onStatusConfirm())}
                                confirmationText={t(this.state.confirmationText)}
                                worklogsSaving={this.state.worklogsSaving}
                                worklogsSaved={this.state.worklogsSaved}
                                selectedWorklogs={selectedWorklogs}
                            />
                        }

                        {!loading && searchResults.length > 0 &&
                            <div>
                                <div className="wl-right">
                                    <div className="wl-summary-item">{t("Worklogs count")}</div>
                                </div>
                                <div className="wl-right">
                                    <div className="wl-summary-item">{searchResults.length}</div>
                                </div>
                            </div>
                        }

                        {!loading && searchResults.length > 0 &&
                            <div>
                                <div className="wl-right">
                                    <div className="wl-summary-item">{t("Worklog total billable")}</div>
                                </div>
                                <div className="wl-right">
                                    <div className="wl-summary-item">{this.renderTotalHours()}</div>
                                </div>
                            </div>
                        }

                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.user,
    //worklogs: state.worklogs,
    customers: state.customers,
    employees: state.employees,
});

export default withRouter(connect(mapStateToProps, {
    //setWorklogs,
    setCustomers,
    setEmployees,
})(withTranslation()(WorklogsLanding2)));
