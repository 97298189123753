import {isEmpty} from "./isEmpty";
import Validator from 'validator';

export const validateWorksite = (worksite) => {
    let errors = {};

    console.log("validate worksite ", worksite)

    if(isEmpty(worksite)) {
        errors.name = "NameRequired";
        errors.streetAddress = "AddressRequired";
    } else {

        worksite.wNumber = worksite.wNumber ? worksite.wNumber.toString() : "";
        //worksite.name = !isEmpty(worksite.name) ? worksite.name : "";

        if (worksite.name === "" || Validator.isEmpty(worksite.name)) {
            errors.name = "NameRequired";
        }

        if (isEmpty(worksite.streetAddress)) {
            errors.streetAddress = "AddressRequired";
        }

        if (isEmpty(worksite.city)) {
            errors.city = "AddressRequired";
        }

        if (isEmpty(worksite.postCode)) {
            errors.postCode = "AddressRequired";
        }

        /* customer will be added on save
        if (isEmpty(worksite.customer)) {
            errors.customer = "CID";
        }*/

        // Check that email is valid
        if (worksite.email) {
            if (!Validator.isEmail(worksite.email)) errors.email = "InvalidEmail";
        }

        if (worksite.phone) {
            if (!Validator.isMobilePhone(worksite.phone)) errors.phone = "InvalidPhone";
        }
    }

    console.log("validate worksite errors ", errors)
    return {
        errors,
        isValid: isEmpty(errors)
    }
};
