import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';

import AutocompleteComponent from './AutocompleteComponent';
import Autocomplete from "../util/Autocomplete";

import {
    Paper,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Button,
    IconButton,
    ClickAwayListener, Divider
} from '@material-ui/core';

import {
    Delete,
    PlaylistAdd
} from '@material-ui/icons';

import TodoDialog from './TodoDialog';
import CustomerDialog from './CustomerDialog';
import GlobalConfirmationDialog from "../CommonDialogs/GlobalConfirmationDialog";
import keycode from 'keycode';
import {validateJobTemplate, validateJobModelProductArray} from "../validations/jobmodel";
import Validator from "validator";

import {withTranslation} from "react-i18next";

import "./JobModelEditor.css";
import {isEmpty} from "../validations/isEmpty";

class JobTemplateEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productArray: [],
            products: [],
            customers: [],
            loading: true,
            modelsLoading: true,
            model: {},
            models: [],
            id: null,
            todoDialog: false,
            customerDialog: false,
            selectedRow: "",
            selectedTasks: [],
            selectedCustomers: [],
            searchTerm: "",
            searchList: [],
            menuAnchor: null,
            open: false,
            confirmationDialog: false
        };
    }

    componentDidMount() {
        const {user} = this.props.user;
        const {match: {params}} = this.props;

        const id = params.templateId;

        this.unsubscribeProductListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('products').onSnapshot(this.onProductSnapshot);

        this.unsubscribeModelListener =
            firebase.firestore().collection('company').doc(user.companyID).collection("jobModels").onSnapshot(querySnapshot => this.onModelSnapshot(querySnapshot, id));

        this.waitModelsLoadingEnds();  //this waits that tempplate is first loaded, before getting customer name

        this.unsubscribeCustomerListener = firebase.firestore().collection("customers")
            .where("companyID", "array-contains", user.companyID).onSnapshot(querySnap => {
                const customers = [];
                let searchTerm = "";
                querySnap.forEach(doc => {
                    const customer = doc.data();
                    customer.id = doc.id;

                    if (this.state.model && this.state.model.customer === customer.id) searchTerm = customer.name;
                    customers.push(customer);
                });
                this.setState({
                    customers,
                    searchTerm,
                    loading: false,
                });
            })

    }

    waitModelsLoadingEnds = async () => {
        let i = 0;
        do {
            await this.sleep(200);
            console.log("LOOP " + i + " loading = " + this.state.loading + " models loading " + this.state.modelsLoading)
            i += 1;
        } while (this.state.modelsLoading && i < 10);
    }

    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }


    onModelSnapshot = (querySnapshot, id) => {
        const models = [];
        const productArray = [];
        let selectedModel = {};

        querySnapshot.forEach(doc => {
            let model = doc.data();
            model.id = doc.id;

            if (model.id === id) {

                selectedModel = model;
                let rowID = 0;
                model.tasks && model.tasks.forEach(task => {
                    let newTask = {...task};
                    newTask.rowID = rowID;
                    if (newTask.task) {
                        newTask.name = newTask.task;
                        delete newTask.task;
                    }
                    productArray.push(newTask);
                    rowID++;
                });
            }
            if (model.type === "model") {
                models.push(model);
            }
        });


        this.setState({
            id: id && id.length > 0 ? id : null,
            models: models,
            model: selectedModel,
            productArray: productArray,
            modelsLoading: false
        });
    };

    onProductSnapshot = querySnapshot => {
        let products = [];

        querySnapshot.forEach(doc => {
            let product = doc.data();
            product.id = doc.id;
            products.push(product);
        });

        this.setState({
            products: products,
        });
    };

    componentWillUnmount() {
        this.unsubscribeProductListener && this.unsubscribeProductListener();
        this.unsubscribeModelListener && this.unsubscribeModelListener();
    }

    addRow = () => {
        const {productArray} = this.state;

        let id = 0;

        productArray.forEach(product => {
            product.rowID = id;
            id++
        });

        const newRow = {
            rowID: productArray.length
        };

        productArray.push(newRow);

        this.setState({
            productArray: productArray
        });
    };

    openAddTodo = rowID => {
        const {productArray} = this.state;

        const task = productArray[rowID];

        this.setState({
            selectedTask: task,
            todoDialog: true
        });
    };

    saveExtraOptions = task => {
        const {productArray} = this.state;

        productArray[task.rowID] = task;

        this.setState({
            todoDialog: false,
            productArray: productArray
        });
    };

    deleteRow = (rowID) => {
        const {productArray} = this.state;

        const row = productArray[rowID];

        const index = productArray.indexOf(row);
        if (index > -1) {
            productArray.splice(index, 1)
        }

        let id = 0;

        productArray.forEach(product => {
            product.rowID = id;
            id++;
        });

        this.setState({
            productArray: productArray
        });
    };

    changeHandler = (event, index, field) => {
        const {productArray} = this.state;

        productArray[index][field] = event.target.value;

        this.setState({
            productArray: productArray
        });
    };

    mainFieldHandler = (event, field) => {
        const {model} = this.state;

        model[field] = event.target.value;

        this.setState({
            model: model
        });
    };

    onAutocompleteSelect = (product, rowID) => {
        const {productArray} = this.state;
        const {user} = this.props.user;


        productArray[rowID] = {
            rowID: rowID,
            name: product.name,
            pNumber: product.pNumber,
            unit: product.unit ? product.unit : (user.lang === "fi" ? "kpl" : "pcs"),
            invoiceText: product.invoiceText ? product.invoiceText : "",
            description: product.description ? product.description : "",
        };

        product.todo ? productArray[rowID].todo = product.todo : delete productArray[rowID].todo;

        this.setState({
            productArray: productArray
        });

    };

    onModelSelect = event => {
        let {models, productArray} = this.state;

        models.forEach(model => {
            if (model.name === event) {
                let id = 0;
                model.tasks.forEach(task => {
                    let newTask = {...task};
                    newTask.rowID = id;
                    if (newTask.task) {
                        newTask.name = newTask.task;
                        delete newTask.task;
                    }
                    productArray.push(newTask);
                    id++;
                });
            }
        });

        this.setState({
            productArray: productArray
        });
    };

    searchCustomer = event => {
        const {customers} = this.state;
        const searchTerm = event.target.value;

        const searchList = [];

        customers.forEach(customer => {
            if (customer.name.toLowerCase().search(searchTerm.toLowerCase()) > -1) searchList.push(customer);
        });

        this.setState({searchTerm, searchList})
    };

    onCustomerSelect = customer => {
        const {model} = this.state;

        model.customer = customer.id;
        const searchTerm = customer.name;

        this.setState({
            model,
            searchTerm,
            menuAnchor: null,
            open: false
        });
    };

    fieldType = (id) => {
        const {productArray, products} = this.state;

        if (productArray[id] && productArray[id].pNumber) {
            return (
                <TextField
                    variant={"outlined"}
                    value={productArray[id].name || ""}
                    onChange={event => this.changeHandler(event, id, "name")}
                    InputProps={{
                        disableunderline: "true"
                    }}
                    fullWidth
                    error={productArray[id].name && Validator.isEmpty(productArray[id].name)}
                />
            );
        }

        if (productArray[id] && !productArray[id].pNumber) {
            return (
                <Autocomplete
                    style={{width: "100%"}}
                    searchArray={products}
                    searchValues={["pNumber", "name"]}
                    onSelect={event => this.onAutocompleteSelect(event, id)}
                    listMaxLength={5}
                />
            )

        }
    };

    handleKeyDown = (event, field, id) => {
        const {productArray, products} = this.state;

        if (field === "pNumber" && keycode(event) === "enter") {
            const product = products.find(product => parseInt(product.pNumber) === parseInt(productArray[id].pNumber));

            let newProduct = {
                rowID: id,
                name: product.name,
                pNumber: product.pNumber,
                invoiceText: product.invoiceText ? product.invoiceText : "",
                description: product.description ? product.description : "",
                unit: product.unit ? product.unit : "",
            };

            product.todo ? newProduct.todo = product.todo : delete newProduct.todo;

            productArray[id] = newProduct;

            this.setState({
                productArray: productArray
            });
        }

    };

    textField = (id, field) => {
        const {productArray} = this.state;

        console.log("textField validateJobModelProductArray ", productArray, id, field)

        const {errors, isValid} = validateJobModelProductArray(productArray, id, field);

        return <TextField
            value={productArray[id][field] || ""}
            onChange={event => this.changeHandler(event, id, field)}
            InputProps={{
                disableUnderline: true,
                onKeyDown: event => this.handleKeyDown(event, field, id)
            }}
            fullWidth
            error={!isValid}
        />
    };

    deleteHandler = () => {
        const {model, id} = this.state;
        const {user} = this.props.user;

        model.isDeleted = true;
        console.log(" delete template " , id)
        //firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').doc(this.state.id).set(model)
        firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').doc(id).delete()
            .then(() => {
                console.log("template deleted" , model, id)
                this.props.history.goBack();
            });
    };

    saveHandler = () => {
        const {model, productArray} = this.state;
        const {user} = this.props.user;

        const tasks = [];

        productArray.forEach(product => {
            if (product.pNumber) {
                delete product.rowID;
                tasks.push(product)
            }
        });

        model.tasks = tasks;
        model.type = "template";

        if (model.id) {
            firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').doc(model.id).set(model)
                .then(() => {
                    this.props.history.goBack();
                });
        }
        else {
            firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').add(model)
                .then(() => {
                    this.props.history.goBack();
                });

        }

    };

    renderProductPrice = (incompleteProduct) => {
        const {t, user} = this.props;
        const {products} = this.state;
        const replaceValue = user && user.lang === "fi" ? "," : ".";

            const product = products.find(product => parseInt(product.pNumber) === parseInt(incompleteProduct.pNumber));
            if (product) {
                const price = product.sellPrice;
                const priceWithVat = (parseFloat(product.sellPrice ? product.sellPrice : 0.0).toFixed(2).replace(".", replaceValue) * (1 + (product.vat>0?product.vat/100:0))).toFixed(2);
                return (<div style={{fontSize: 13, marginTop: -35, marginLeft: 30}}>
                    <div>{t("Price")}:</div>
                    <div>{price}€</div>
                    <div>({priceWithVat}€)</div>
                </div>);
            }

        return '';
    }


    renderItem = item => {

        return (
            <div className="key-list-item" key={item.id}>
                <div style={{padding: 15}} onClick={() => this.onCustomerSelect(item)}>
                    <p style={{fontSize: 16, margin: 0, padding: 0}}>{item.name}</p>
                </div>
                <Divider style={{width: '100%', backgroundColor: '#ccc'}}/>
            </div>

        )
    };

    render() {
        const {productArray, model, menuAnchor, searchList, open} = this.state;
        const {t} = this.props;

        const {errors, isValid} = !this.state.loading && validateJobTemplate(model, productArray);

        console.log('edit template ', model)

        return (
            <div style={{width: '100%'}}>
                {!this.state.loading && <Paper style={{width: '98%', margin: 20, marginRight: 30, paddingBottom: 20}}>

                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div style={{width: 365, paddingLeft: 10, paddingTop: 20, display: 'flex'}}>
                            <AutocompleteComponent
                                searchArray={this.state.models}
                                useVariant={true}
                                label={t("Copy from model")}
                                onSelect={this.onModelSelect}
                            />
                        </div>

                        <div style={{width: 355, paddingTop: 20, display: 'flex'}}>
                            <TextField
                                variant="outlined"
                                label={t("Customer")}
                                value={this.state.searchTerm}
                                style={{
                                    width: '100%'
                                }}
                                onClick={event => this.setState({
                                    menuAnchor: event.currentTarget.getBoundingClientRect(),
                                    open: true
                                })}
                                onChange={event => this.searchCustomer(event)}
                            />
                        </div>

                        {open &&
                        <ClickAwayListener onClickAway={() => this.setState({menuAnchor: null})}>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: menuAnchor ? menuAnchor.y : 0 + menuAnchor ? menuAnchor.height : 0,
                                    left: menuAnchor ? menuAnchor.x : 0,
                                    zIndex: 10,
                                    backgroundColor: '#FFF',
                                    width: menuAnchor ? menuAnchor.width - 2 : 0,
                                    borderStyle: 'solid',
                                    borderColor: '#ccc',
                                    borderWidth: 1,
                                    borderRadius: 4
                                }}
                            >
                                {searchList && searchList.map(this.renderItem)}
                            </div>
                        </ClickAwayListener>
                        }


                    </div>
                    <br/>

                    <div style={{display: 'flex', flexDirection: 'row'}}>

                        <div style={{width: 365, display: 'flex', marginTop: 20}}>

                            <TextField
                                variant="outlined"
                                label={t("Template name")}
                                style={{width: '100%', marginLeft: 10}}
                                value={model.name || ""}
                                onChange={event => this.mainFieldHandler(event, 'name')}
                                helperText={this.state.selectedCustomers && this.state.selectedCustomers.length > 1 ? `${this.state.selectedCustomers.length} customers selected. On save templates will be saved: Customer name - Template name` : ""}
                                error={!!errors.name}
                            />
                        </div>

                        <div style={{width: 365, display: 'flex', marginTop: 20}}>
                            <TextField
                                variant="outlined"
                                label={t("Notes")}
                                style={{width: '100%', marginLeft: 10}}
                                value={model.notes || ""}
                                onChange={event => this.mainFieldHandler(event, 'notes')}
                            />
                        </div>

                        <div style={{width: 365, display: 'flex', marginTop: 20}}>
                            <TextField
                                variant="outlined"
                                label={t("Work number")}
                                style={{width: '100%', marginLeft: 10}}
                                value={model.workNumber || ""}
                                onChange={event => this.mainFieldHandler(event, 'workNumber')}
                            />
                        </div>
                    </div>

                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => this.addRow()}
                                style={{
                                    marginTop: 45,
                                    marginLeft: 10
                                }}
                            >
                                {t("Add row")}
                            </Button>
                        </div>
                        <div style={{display: 'flex', flexGrow: 1, justifyContent: 'flex-end', marginTop: 40}}>
                            {this.state.id !== null && <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#b22717',
                                    color: '#FFF',
                                    marginRight: 20,
                                    height: 40
                                }}
                                onClick={() => this.setState({confirmationDialog: true})}
                            >
                                {t("Delete template")}
                            </Button>}

                            <Button
                                variant="contained"
                                style={{
                                    backgroundColor: '#1770B2',
                                    color: '#FFF',
                                    marginRight: 20,
                                    height: 40
                                }}
                                onClick={() => this.props.history.goBack()}
                            >
                                {t("Cancel")}
                            </Button>

                            <Button
                                variant="contained"
                                disabled={!isValid}
                                style={{
                                    backgroundColor: !isValid ? '#909497' : '#1770B2',
                                    color: '#FFF',
                                    marginRight: 20,
                                    height: 40
                                }}
                                onClick={() => this.saveHandler()}
                            >
                                {t("Save")}
                            </Button>
                        </div>
                    </div>


                    <div style={{margin: 10, width: '99%', marginBottom: 20}}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={"table-cell-name"}>{t("Name")}</TableCell>
                                    <TableCell className={"table-cell-pNumber"}>{t("Product number")}</TableCell>
                                    <TableCell className={"table-cell-invoice"}>{t("Invoice text")}</TableCell>
                                    <TableCell className={"table-cell-desc"}>{t("Description")}</TableCell>
                                    <TableCell className={"table-cell-unit"}>{t("Unit")}</TableCell>
                                    <TableCell className={"table-cell-icon"}>{t("Add")}</TableCell>
                                    <TableCell className={"table-cell-icon"}>{""}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {productArray && productArray.map(product => (
                                    <TableRow key={product.rowID}>
                                        <TableCell
                                            className={"table-cell-name"}>{this.fieldType(product.rowID)}</TableCell>
                                        <TableCell align="center"
                                                   className={"table-cell-pNumber"}>
                                            <div style={{flex: 1, flexDirection: 'row'}}>
                                            {this.textField(product.rowID, "pNumber")}
                                            {this.renderProductPrice(product)}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            className={"table-cell-invoice"}>{this.textField(product.rowID, 'invoiceText')}</TableCell>
                                        <TableCell
                                            className={"table-cell-desc"}>{this.textField(product.rowID, 'description')}
                                        </TableCell>
                                        <TableCell
                                            className={"table-cell-unit"}>{this.textField(product.rowID, 'unit')}</TableCell>
                                        <TableCell className={"table-cell-icon"} align="center">
                                            <IconButton onClick={() => this.openAddTodo(product.rowID)}
                                                        disabled={!product.name}>
                                                <PlaylistAdd/>
                                            </IconButton>
                                        </TableCell>
                                        <TableCell className={"table-cell-icon"} align="center">
                                            <IconButton onClick={() => this.deleteRow(product.rowID)}>
                                                <Delete/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>}
                {this.state.todoDialog &&
                <TodoDialog
                    onClose={() => this.setState({todoDialog: false})}
                    onSave={this.saveExtraOptions}
                    selectedTask={this.state.selectedTask}
                    products={this.state.products}
                />
                }
                {this.state.customerDialog &&
                <CustomerDialog
                    selectedCustomers={this.state.selectedCustomers}
                    onClose={() => this.setState({customerDialog: false})}
                    onAccept={this.onCustomerSelect}
                />
                }

                <GlobalConfirmationDialog
                    open={this.state.confirmationDialog}
                    onClose={() => this.setState({confirmationDialog: false})}
                    onConfirm={() => this.setState({confirmationDialog: false}, () => this.deleteHandler())}
                    title={t("Attention")}
                    message={`${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DeleteActionJobTemplate")}?`}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(JobTemplateEditor));
