import React from 'react';

import moment from 'moment';
import "moment/locale/fi";

import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@material-ui/core';

import {withTranslation} from "react-i18next";

const SearchListComponent = props => {
    const {t} = props;

    const getBillableTime = hours => {

        let time = hours / 3600000;

        if (time >= 3 && time < 3.1) {
            time = 3
        }

        return time;
    };

    const secondsTohms = d => {
        isNaN(d) ? d = 0 : Number(d);

        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);

        let hDisplay = h === 0 ? "00:" : (h < 10 ? "0"+h+":" : h+':');
        let mDisplay = m === 0 ? "00" : (m < 10 ? "0"+m : m);

        return hDisplay + mDisplay;
    };

    return (
        <Table style={{width: '100%'}}>
            <TableHead>
                <TableRow>
                    <TableCell>{t("Employee")}</TableCell>
                    <TableCell>{t("Customer")}</TableCell>
                    <TableCell>{t("Worksite")}</TableCell>
                    <TableCell>{t("Date")}</TableCell>
                    <TableCell>{t("Work time")}</TableCell>
                    <TableCell>{t("Billable hours")}</TableCell>
                    <TableCell>{t("Status")}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.searchArray && props.searchArray.map(worklog => (
                    <TableRow key={worklog.id}>
                        <TableCell>{worklog.employee && worklog.employee.map(employee => (
                            props.findEmployee(employee)
                        ))}</TableCell>
                        <TableCell>{props.findCustomer(worklog.customer)}</TableCell>
                        <TableCell>{props.getWorksite(worklog.worksite)}</TableCell>
                        <TableCell>{moment(worklog.startDT).locale("fi").format("L")}</TableCell>
                        <TableCell>{secondsTohms(worklog.duration / 1000)}</TableCell>
                        <TableCell>{worklog.billing && getBillableTime(worklog.billing.billableHours)}</TableCell>
                        <TableCell>{(worklog.status + (worklog.previousStatus ? "/" + worklog.previousStatus: ''))}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default withTranslation()(SearchListComponent);
