import React, {Component} from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';

import {connect} from 'react-redux';

import FullCalendar from 'sardius-fullcalendar-wrapper';
import 'sardius-fullcalendar-wrapper/dist/fullcalendar.min.css';

import moment from 'moment-timezone';
import "moment/locale/fi";

import uuid from 'uuid';

import {addIncomingChanges, checkEmployeeTasks, formatJobDates} from "../util/JobHandleHelpers";
import {getOccurrences} from "./OccurrencesComponent";

import FullscreenDialog from './FullscreenDialog';
import ClickDialog from './ClickDialog';
import EditDialog from './EditDialog';
import CancelDialog from './CancelDialog';


import {withTranslation} from "react-i18next";


import {
    CircularProgress
} from '@material-ui/core';

/*
 * Deprecated?
 */
class CalendarComponent extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: true,
            renderView: false,
            viewRange: null,
            activeStart: "",
            activeEnd: "",
            defaultView: "",
            defaultDate: moment().toDate(),
            jobs: [],
            employees: [],
            employeeArr: [],
            events: [],
            eventsCopy: [],
            selectedJob: {},
            selectedJobDT: {},
            selectedEvent: {},
            preEvent: {},
            rootJob: {},
            clickDialog: false,
            deleteMode: false,
            editDialog: false,
            addDialog: false,
            cancelDialog: false,
            editMode: false,
            addMode: false,
            repeats: "noRepeat",
            datePicker: false,
            pickField: "",
            pickerTime: null,
            timePicker: false,
        };

        this.fullCalendar = React.createRef();

    }

    componentDidMount() {

        const {user} = this.props.user;

        let today = moment(moment().format('YYYY-MM-DDT00:01'));

        let todayNumber = moment().format('d');

        let startMoment = "";
        let endMoment = "";

        const time11 = moment();


        let workLogFetchStartMoment = moment.tz(moment(today).subtract(2, 'month'), "Europe/Helsinki");

        this.unsubscribeEmployeeListener =
            firebase.firestore().collection('employees').onSnapshot(this.onEmployeesSnapshot);

        this.unsubscribeJobListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('jobs').onSnapshot(this.onJobsSnapshot);

        //this.unsubscribeWorklogListener = firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').onSnapshot(this.onWorklogSnapshot);
        this.unsubscribeWorklogListener = firebase.firestore().collection('company').doc(user.companyID).collection("worklogs")
            .where("jobStartDT", ">=", workLogFetchStartMoment.toDate())
            .orderBy("jobStartDT", "desc")
            .onSnapshot(querySnap => this.onWorklogQuerySnap(querySnap));


        // Set scope for getting events for one day

        if (this.props.filterBy === "HomeView") {
            startMoment = moment.tz(moment(today).subtract(1, 'days'), "Europe/Helsinki");
            endMoment = moment.tz(moment(today).add(1, 'days'), "Europe/Helsinki");
        }

        if (this.props.filterBy === "Calendar") {
            startMoment = moment.tz(moment(today).subtract(todayNumber, 'days'), "Europe/Helsinki");
            endMoment = moment.tz(moment(today).add(7, 'days'), "Europe/Helsinki");
        }


        setTimeout(() => {
            this.eventHandler({
                view: {
                    activeStart: startMoment,
                    activeEnd: endMoment
                }
            }, false);
        }, 2000)

    }


    onEmployeesSnapshot = querySnapshot => {

        const {user} = this.props.user;

        let employees = [];

        querySnapshot.forEach(doc => {

            let employee = doc.data();
            employee.id = doc.id;

            employee.name = `${employee.firstName} ${employee.familyName}`;

            if (employee.companyID === user.companyID) {
                if (!employee.isDeleted) employees.push(employee);
            }
        });

        this.setState({
            employees: employees
        });

        if (!this.state.loading) {
            this.eventHandler({
                view: {
                    activeStart: this.state.activeStart,
                    activeEnd: this.state.activeEnd
                }
            }, true);
        }
    };

    onJobsSnapshot = querySnapshot => {

        let jobs = [];

        querySnapshot.forEach(doc => {
            let job = doc.data();
            job.id = doc.id;

            const time1 = moment();

            // Change dates to moment objects for comparison purposes
            if (job.inComingChanges) {
                job.inComingChanges.map(change => {
                    if (change.impact === "once") {
                        change.startDT = moment.tz(change.startDT, job.TZ);
                        change.endDT = moment.tz(change.endDT, job.TZ);
                        change.replaceDT = moment.tz(change.replaceDT, job.TZ);
                    }
                    if (change.impact === "onward") {
                        change.startDT = moment.tz(change.startDT, job.TZ);
                        change.endDT = moment.tz(change.endDT, job.TZ);
                        change.replaceDT = moment.tz(change.replaceDT, job.TZ);
                        change.until = moment.tz(change.until, job.TZ);
                    }
                    if (change.impact === "range") {
                        change.replaceStart = moment.tz(change.replaceStart, job.TZ);
                        change.replaceEnd = moment.tz(change.replaceEnd, job.TZ);
                        change.nextNormal = moment.tz(change.nextNormal, job.TZ);
                        change.startDT = moment.tz(change.startDT, job.TZ);
                        change.endDT = moment.tz(change.endDT, job.TZ);
                    }
                });
            }

            const time2 = moment();
            console.log("PERF DEBUG: job.inComingChanges loop took ", (time2-time1)/1000)

            jobs.push(job);
        });

        this.setState({
            jobs: jobs,
        });

        if (!this.state.loading) {
            this.eventHandler({
                view: {
                    activeStart: this.state.activeStart,
                    activeEnd: this.state.activeEnd
                }
            }, true);
        }


    };

    onWorklogQuerySnap = querySnapshot => {
        let worklogs = [];

        const time1 = moment();

        querySnapshot.forEach(doc => {
            let worklog = doc.data();
            worklog.id = doc.id;
            if (worklog.status === "Cancelled" || (worklog.previousStatus && worklog.previousStatus === "Cancelled")) {
                if (worklog.previousStatus === "Cancelled") {

                }
                worklogs.push(worklog);
            }
        });

        const time2 = moment();
        console.log("PERF DEBUG: onWorklogQuery loop took ", (time2-time1)/1000)

        this.setState({
            worklogs: worklogs
        });

        if (!this.state.loading) {
            this.eventHandler({
                view: {
                    activeStart: this.state.activeStart,
                    activeEnd: this.state.activeEnd
                }
            }, true);
        }

    };

    componentWillUnmount() {

        this.unsubscribeJobListener && this.unsubscribeJobListener();
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
        this.unsubscribeWorklogListener && this.unsubscribeWorklogListener();
    }


    getEmployee = (id, field) => {

        const {employees} = this.state;

        const employee = employees && employees.find(employee => employee.id === id);

        if (field === "color") {
            return employee.color ? employee.color : '#cccccc';
        }

        if (field === "name") {
            return employee ? `${employee.firstName} ${employee.familyName}` : "";
        }

        return "";
    };


    clickHandler = (event) => {

        if (this.state.jobs) {
            this.state.jobs.map(job => {
                if (job.id === event.event. extendedProps.jobID) {
                    let selectedJob = JSON.parse(JSON.stringify(job));
                    const selectedJobDT = JSON.parse(JSON.stringify(job));

                    selectedJob.startDT = event.event.start;
                    selectedJob.endDT = event.event.end;

                    selectedJobDT.startDT = event.event.start;
                    selectedJobDT.endDT = event.event.end;

                    // This method modifies employee and task array if inComingChanges array is detected
                    if (selectedJob.inComingChanges) {
                        selectedJob = checkEmployeeTasks(selectedJob)

                    }

                    this.setState({
                        rootJob: JSON.parse(JSON.stringify(job)),
                        selectedJob: selectedJob,
                        searchCustomer: selectedJob.customerLabel,
                        selectedJobDT: selectedJobDT,
                        selectedEvent: {...event},
                        clickDialog: true,
                        rrStart: event.event.start,
                        rrEnd: event.event.start
                    });

                    if (job.rrule) {
                        this.setState({
                            repeats: "custom1"
                        });
                    }
                }
            });
        }
    };

    selectHandler = (event) => {

        this.setState({
            selectedJob: {
                startDT: event.start,
                endDT: event.end,
            },
            addDialog: true,
            editMode: false,
        });
    };

    resizeHandler = (event) => {
        if (this.state.jobs) {
            this.state.jobs.map(job => {
                if (job.id === event.event.extendedProps.jobID) {
                    const selectedJob = JSON.parse(JSON.stringify(job));
                    const selectedJobDT = JSON.parse(JSON.stringify(job));
                    const rootJob = JSON.parse(JSON.stringify(job));

                    selectedJob.startDT = event.event.start;
                    selectedJob.endDT = event.event.end;

                    // Get the unchanged event dates, this is if day is changed
                    this.state.events.map(e => {

                        if (e.id === parseInt(event.event.id)) {
                            selectedJobDT.startDT = e.start;
                            selectedJobDT.endDT = e.end;
                        }
                    });


                    this.setState({
                        rrStart: event.event.start,
                        rrEnd: event.event.start,
                        rootJob: rootJob,
                        selectedJob: selectedJob,
                        selectedJobDT: selectedJobDT,
                        selectedEvent: {...event},
                        editDialog: true
                    })
                }
            })
        }
    };


    dropHandler = (event) => {
        const {jobs, events} = this.state;

        let prevEvent = {};

        if (events) {
            events.map(e => {
                if (e.jobID === event.event.extendedProps.jobID && moment(event.prevEvent.start).isSame(e.start)) {
                    //console.log("event ",event)
                    //console.log(e)
                    e.start = event.event.start;
                    e.end = event.event.end;

                    prevEvent = JSON.parse(JSON.stringify(e));
                }

            });
        }

        prevEvent.start = event.prevEvent.start;
        prevEvent.end = event.prevEvent.end;

        if (jobs) {
            jobs.map(job => {
                if (job.id === event.event.extendedProps.jobID) {
                    const selectedJob = JSON.parse(JSON.stringify(job));
                    const selectedJobDT = JSON.parse(JSON.stringify(job));
                    const rootJob = JSON.parse(JSON.stringify(job));

                    selectedJob.startDT = event.event.start;
                    selectedJob.endDT = event.event.end;

                    selectedJobDT.startDT = event.prevEvent.start;
                    selectedJobDT.endDT = event.prevEvent.end;

                    this.setState({
                        selectedJob: selectedJob,
                        selectedJobDT: selectedJobDT,
                        prevEvent: prevEvent,
                        rootJob: rootJob,
                        events: events,
                        editDialog: true,
                        dropMode: true
                    });
                }
            });

        }

    };

    cancelHandler = () => {

        const {events, prevEvent} = this.state;

        if (this.state.dropMode) {
            if (events) {
                events.map(event => {
                    if (event.id === prevEvent.id) {
                        event.start = prevEvent.start;
                        event.end = prevEvent.end
                    }
                });

                this.setState({
                    editDialog: false,
                    events: events,
                    dropMode: false
                })
            }
        } else {
            this.setState({
                editDialog: false,
                dropMode: false,
                deleteMode: false,
            });
        }
    };

    createTemp = (selectedJob, selectedModel) => {
        const {user} = this.props.user;

        const newTemplate = {...selectedModel};
        newTemplate.name = selectedJob.customerLabel + (selectedJob.worksite ? selectedJob.worksite.name : '') + ' - ' + 'template'; //TODO - create template name with worksite name too
        newTemplate.notes = "auto-created";
        newTemplate.type = "template";

        const tempRef = firebase.firestore().collection('company').doc(user.companyID).collection('jobsModels').doc();

        let customer = {
            templateID: tempRef.id
        };

        const customerID = selectedJob.customer;

        if (selectedJob.tasks) {
            firebase.firestore().collection('customers').doc(customerID).set(customer, {merge: true});
            firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').doc(tempRef.id).set(newTemplate);
        }
    };

    addjobHandler = (selectedJob, createTemplate, selectedModel) => {
        const {user} = this.props.user;

        selectedJob.active = true;

        //Give job a timezone
        selectedJob.TZ = moment.tz.guess();

        // Check recurrence object
        if (!selectedJob.rrule) {
            selectedJob.rrule = {
                FREQ: "WEEKLY",
                INTERVAL: 0,
                UNTIL: moment(selectedJob.endDT).format('YYYYMMDDTHHmmss')
            }
        }

        selectedJob.startDT = moment(selectedJob.startDT).format('YYYYMMDDTHHmmss');
        selectedJob.endDT = moment(selectedJob.endDT).format('YYYYMMDDTHHmmss');

        // Add new template for customer if createTemplate === true
        if (createTemplate) {
            this.createTemp(selectedJob, selectedModel);
        }

        let docID = firebase.firestore().collection('company').doc(user.companyID).collection('jobs').doc();

        firebase.firestore().collection('company').doc(user.companyID).collection('jobs').doc(docID.id).set(selectedJob);

        this.setState({
            editMode: false,
            addDialog: false,
            selectedJob: {}
        });
    };

    editjobHandler = (recurringRule, rrStart, rrEnd, selectedJob, extraTodo) => {

        //TODO: ADD THE user who made the edit to this change 
        let {rootJob, selectedJobDT, events} = this.state;
        const {user} = this.props.user;
        console.log("edit job recurringRule = ", recurringRule, user.email)

        if (recurringRule === "onward") {

            // Take id for saving rootjob
            const rootID = rootJob.id;

            delete rootJob.id;
            delete selectedJob.id;

            // Check dates, if dates match -> just overwrite rootjob, if doesn't -> create new job

            if (moment(rootJob.startDT).isSame(selectedJob.startDT)) {
                selectedJob = formatJobDates(selectedJob);
                console.log("DEPRECATED? same date selectedJob: ", selectedJob)

                firebase.firestore().collection('company').doc(user.companyID).collection('jobs').doc(rootID).set(selectedJob);
            }
            else {
                // Set uuid so we can link jobs together if needed
                if (!rootJob.rrule.uuid) {
                    const id = uuid();
                    rootJob.rrule.uuid = id;
                    selectedJob.rrule.uuid = id;
                }

                rootJob = formatJobDates(rootJob);
                selectedJob = formatJobDates(selectedJob);

                // Set disableDT so iCalendar knows where to stop repeating
                rootJob.disableDT = moment(selectedJob.endDT).subtract(1, 'day').format('YYYYMMDDTHHmmss');

                delete selectedJob.inComingChanges;

                console.log("DEPRECATED? after delete selectedJob.inComingChanges: ", selectedJob)

                firebase.firestore().collection('company').doc(user.companyID).collection('jobs').doc(rootID).set(rootJob);
                firebase.firestore().collection('company').doc(user.companyID).collection('jobs').add(selectedJob);
            }


        }
        else if (this.state.deleteMode && recurringRule === "onward") {
            // Set disable DT and save to FB
            rootJob.disableDT = moment(selectedJob.endDT).subtract(1, 'day').format('YYYYMMDDTHHmmss');
            const rootID = rootJob.id;
            delete rootJob.id;
            firebase.firestore().collection('company').doc(user.companyID).collection('jobs').doc(rootID).set(rootJob);
        }
        else if (this.state.deleteMode && recurringRule === "all") {
            if (rootJob.rrule.uuid) {
                const id = rootJob.rrule.uuid;
                this.state.jobs.forEach(job => {
                    if (job.rrule.uuid && job.rrule.uuid === id) {
                        firebase.firestore().collection('company').doc(user.companyID).collection('jobs').doc(job.id).delete();
                    }
                });
            }
            else {
                const rootID = rootJob.id;
                firebase.firestore().collection('company').doc(user.companyID).collection('jobs').doc(rootID).delete();
            }
        }

        else {
            rootJob.inComingChanges = addIncomingChanges(rootJob, selectedJob, selectedJobDT, events, recurringRule, extraTodo, rrStart, rrEnd, user);

            // Format every date to YYYYMMDDTHHmmss
            rootJob = formatJobDates(rootJob);
            
            let id = selectedJob.id;

            //Delete id field from rootJob if it exists
            delete rootJob.id;

            //console.log("IMGROOT", rootJob);
            firebase.firestore().collection('company').doc(user.companyID).collection('jobs').doc(id).set(rootJob);
        }
        this.setState({
            editDialog: false,
            addDialog: false,
            selectedJob: {},
            editMode: false,
            dropMode: false,
            deleteMode: false,
            clickDialog: false
        });

    };

    deletejobHandler = (recurringRule, rrStart, rrEnd) => {
        const {selectedJob, selectedEvent} = this.state;

        if (selectedJob.employees) {
            selectedJob.employees.map(employee => {
                if (employee.id === selectedEvent.event.extendedProps.employee) {
                    let index = selectedJob.employees.indexOf(employee);
                    if (index > -1) {
                        selectedJob.employees.splice(index, 1)
                    }
                }
            });
        }

        let startTime = moment(selectedJob.startDT).format('HH:mm:ss');
        let endTime = moment(selectedJob.endDT).format('HH:mm:ss');

        let startDate = moment(rrStart).format('YYYY-MM-DD');
        let endDate = moment(rrEnd).format('YYYY-MM-DD');

        rrStart = moment(startDate + 'T' + startTime);
        rrEnd = moment(endDate + 'T' + endTime);

        this.editjobHandler(recurringRule, rrStart, rrEnd, selectedJob);
    };


    editAcceptHandler = (recurringRule, rrStart, rrEnd) => {
        const {selectedJob} = this.state;
        this.editjobHandler(recurringRule, rrStart, rrEnd, selectedJob);
    };


    fullscreenClose = () => {
        this.setState({
            editMode: false,
            addDialog: false,
            selectedJob: {}
        });
    };

    fullscreenAdd = (selectedJob, createTemplate) => {
        this.setState({
            editMode: false,
            addDialog: false,
            selectedJob: selectedJob
        });

    };

    fullscreenEdit = (selectedJob, recurringRule, extraTodo, rrStart, rrEnd, createTemplate, selectedModel) => {

        let todo = [];
        let start = moment().toDate();
        let end = moment().toDate();

        extraTodo.length > 0 ? todo = extraTodo : todo = [];

        if (recurringRule === "range") {
            start = rrStart;
            end = rrEnd;
        }

        // Create new template for job if selected
        if (createTemplate) {
            this.createTemp(selectedJob, selectedModel);
        }

        this.editjobHandler(recurringRule, start, end, selectedJob, todo);
    };

    eventHandler = (event, override) => {

        let events = [];

        const startMoment = moment.tz(event.view.activeStart, "Europe/Helsinki");
        const endMoment = moment.tz(event.view.activeEnd, "Europe/Helsinki");


        if (this.state.viewRange === null) {
            this.setState({
                renderView: true,
                viewRange: event.view.activeStart
            });
        }


        if ((!moment(this.state.viewRange).isSame(event.view.activeStart) && this.state.jobs) || override || this.props.key) {

            const time1 = moment();
            this.state.jobs.forEach(job => {
                //let duration = moment(job.endDT) - moment(job.startDT);

                let newArr = getOccurrences(job, startMoment, endMoment);

                events = events.concat(newArr);
            });
            const time2 = moment();
            console.log("PERF DEBUG: eventHandler job occurrences loop took ", (time2-time1)/1000)

            // map events and event employees to form events for every employee and set ID for each
            let id = 1;
            const finalEvents = [];


            const time11 = moment();
            // Worklog loop needs to be changed. Else it creates duplicate event for each worklog in database
            events.forEach(event => {
                if (event.employees) {
                    if (event.employees.length === 0) {
                        let finalEvent = {...event};
                        finalEvent.id = id;
                        finalEvents.push(event);
                        id++;
                    }
                    event.employees.forEach(employee => {
                        let finalEvent = {...event};
                        finalEvent.color = this.getEmployee(employee.id, 'color');
                        finalEvent.id = id;
                        finalEvent.employee = employee.id;
                        finalEvents.push(finalEvent);
                        id++;
                    });
                }
            });
            const time12 = moment();
            console.log("PERF DEBUG: eventHandler events.forEach loop took ", (time12-time11)/1000)

            const time21 = moment();
            // Go trough final events array to find cancelled jobs
            finalEvents.forEach(event => {
                this.state.worklogs && this.state.worklogs.forEach(log => {
                    if (moment(event.start).isSame(log.startDT) && event.jobID === log.jobID) {
                        event.color = "#333333"
                    }
                });
            });

            const time22 = moment();
            console.log("PERF DEBUG: eventHandler finalEvents.forEach loop took ", (time22-time21)/1000)


            if (finalEvents && finalEvents.length >= 1) {
                const time31 = moment();

                // If not all employees are selected in Calendar.js splice them of the array
                let copyArr = JSON.parse(JSON.stringify(finalEvents));
                if (this.props.employeeArr && this.props.employeeArr.length > 0) {
                    let eventCopy = [...finalEvents];

                    this.props.employeeArr.forEach(emp => {
                        eventCopy.forEach(event => {
                            if (emp === event.employee) {

                                let index = finalEvents.indexOf(event);
                                if (index > -1) {
                                    finalEvents.splice(index, 1);
                                }
                            }
                        });
                    });
                }

                const time32 = moment();
                console.log("PERF DEBUG: eventHandler finalEvents. emplArr forEach split loop took ", (time32-time31)/1000)


                this.setState({
                    loading: false,
                    renderView: false,
                    events: finalEvents,
                    eventsCopy: copyArr,
                    viewRange: event.view.activeStart,
                    //defaultDate: event.view.activeStart,
                    activeStart: event.view.activeStart,
                    activeEnd: event.view.activeEnd
                });
            }
        }

    };


    onCalendarNextClick = () => {

        const {defaultDate} = this.state;

        let days = this.props.activeDays;

        let newStart = moment(defaultDate).add(days, 'days').toDate();

        this.setState({
            defaultDate: newStart
        });

        this.props.resetDate && this.props.resetDate();
    };

    onCalendarBackClick = () => {

        const {defaultDate} = this.state;

        let days = this.props.activeDays;

        let newStart = moment(defaultDate).subtract(days, 'days').toDate();

        this.setState({
            defaultDate: newStart
        });

        this.props.resetDate && this.props.resetDate();
    };

    onCalendarTodayClick = () => {
        this.setState({
            defaultDate: moment().toDate()
        });
    };


    render() {
        return (
            <div>
                {this.state.loading &&
                <div>
                    <CircularProgress style={{color: "secondary"}}/>
                </div>
                }

                {!this.state.loading &&
                <FullCalendar
                    ref={this.fullCalendar}
                    defaultDate={this.props.defaultDate === null ? this.state.defaultDate : this.props.defaultDate}
                    height={'90vh'}
                    firstDay={1}
                    slotLabelFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: false,
                        hour12: false
                    }}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        meridiem: false,
                        hour12: false
                    }}
                    header={{
                        left: 'myPrev, myNext, myToday',
                        right: ''
                    }}
                    timeZone={"local"}
                    defaultView={this.props.defaultView}
                    views={{
                        selectedView: {
                            type: "agenda",
                            duration: {days: this.props.activeDays}
                        }
                    }}
                    customButtons={{
                        myNext: {
                            text: this.props.t('Next_c'),
                            click: this.onCalendarNextClick
                        },
                        myPrev: {
                            text: this.props.t('Prev_c'),
                            click: this.onCalendarBackClick
                        },
                        myToday: {
                            text: this.props.t('Today'),
                            click: this.onCalendarTodayClick
                        }
                    }}
                    events={this.state.events}
                    locale={this.props.user.user.lang ? this.props.user.user.lang : "fi"}
                    editable
                    selectHelper
                    selectable
                    select={this.selectHandler}
                    datesRender={event => this.eventHandler(event, false)}
                    eventClick={event => this.clickHandler(event)}
                    eventResize={this.resizeHandler}
                    eventDrop={event => this.dropHandler(event)}
                />
                }


                {this.state.addDialog &&
                <FullscreenDialog
                    employees={this.state.employees}
                    selectedJob={this.state.selectedJob}
                    editMode={this.state.editMode}
                    onClose={() => this.fullscreenClose()}
                    onAdd={this.addjobHandler}
                    onEdit={this.fullscreenEdit}
                />
                }
                {this.state.clickDialog &&
                <ClickDialog
                    selectedJob={this.state.selectedJob}
                    onClose={() => this.setState({clickDialog: false})}
                    onCancel={() => this.setState({clickDialog: false, cancelDialog: true})}
                    onEdit={() => this.setState({clickDialog: false, addDialog: true, editMode: true})}
                    onDelete={() => this.setState({clickDialog: false, editDialog: true, deleteMode: true})}
                />
                }

                {this.state.editDialog &&
                <EditDialog
                    deleteMode={this.state.deleteMode}
                    selectedJob={this.state.selectedJob}
                    onClose={() => this.cancelHandler()}
                    onDelete={this.deletejobHandler}
                    onAccept={this.editAcceptHandler}
                />
                }

                {this.state.cancelDialog &&
                <CancelDialog
                    selectedJob={this.state.selectedJob}
                    onClose={() => this.setState({cancelDialog: false})}
                />
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(CalendarComponent));
