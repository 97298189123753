import {Component} from "react";
import PropTypes from "prop-types";
import {ChromePicker} from "react-color";
import React from "react";

import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
} from '@material-ui/core';

export default class ColorDialog extends Component {

    static propTypes = {
        open: PropTypes.bool,
        //defaultColor: PropTypes.object,
        onCancel: PropTypes.func,
        onSave: PropTypes.func,
    };

    constructor(props) {

        super(props);

        this.state = {
            currentColor: props.defaultColor,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.defaultColor !== this.props.defaultColor) {
            this.setState({
                currentColor: this.props.defaultColor,
            })
        }
    }

    render() {

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                maxWidth={false}
                style={{width: 400, marginLeft: '45%'}}
            >
                <DialogContent>
                    <ChromePicker
                        color={this.state.currentColor || this.props.defaultColor}
                        onChangeComplete={currentColor => this.setState({currentColor})}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.props.onCancel}>Cancel</Button>
                    <Button onClick={() => this.props.onSave(this.state.currentColor)}>Save</Button>
                </DialogActions>
            </Dialog>
        );
    }
}