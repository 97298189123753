import React, {useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem
} from '@material-ui/core';
import {withTranslation} from "react-i18next";
import "./CustomerSelectDialog.css";
export const CustomerSelectDialog = props => {
    const {t} = props;

    const [searchList, setSearchList] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");

    const searchCustomer = event => {
        const searchValue = event.target.value;

        const formedList = [];

        props.customers.forEach(customer => {
            const customerSearch = `${customer.customerNumber} ${customer.name} ${customer.companyName}`;

            if (customerSearch.toLowerCase().search(searchValue.toLowerCase()) > -1) formedList.push(customer);
        });

        setSearchTerm(searchValue);
        setSearchList(formedList);
    };

    const renderItem = item => {
        return (
            <div className="customer-item" onClick={() => props.onCustomerSelect(item)} key={item.id}>
                <p style={{width: '30%', textAlign: 'center'}}>{item.customerNumber}</p>
                <p style={{width: '70%', textAlign: 'center'}}>{item.name}</p>
                {item.companyName && <p style={{width: '70%', textAlign: 'center'}}>{item.companyName}</p> }
            </div>
        );
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle style={{textAlign: 'center'}}>Valitse asiakas</DialogTitle>
            <DialogContent>
                <div style={{height: 300, overFlow: 'hidden'}}>
                    <TextField
                        variant="outlined"
                        label={t("Search customers")}
                        value={searchTerm}
                        onChange={event => searchCustomer(event)}
                        style={{marginTop: 10}}
                        fullWidth
                    />
                    <div style={{display: 'flex'}}>
                        <p style={{width: '30%', textAlign: 'center'}}>{t("Number")}</p>
                        <p style={{width: '70%', textAlign: 'center'}}>{t("Name")}</p>
                    </div>
                    {searchTerm.length === 0 && props.customers.map(renderItem)}
                    {searchTerm.length > 0 && searchList.map(renderItem)}
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={props.onClose}
                >
                    {t("Close")}
                </Button>

            </DialogActions>

        </Dialog>
    );
};

export default withTranslation()(CustomerSelectDialog);
