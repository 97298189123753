import React, {Component} from 'react';

import CSVReader from 'react-csv-reader';
import axios from 'axios';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    LinearProgress
} from '@material-ui/core';

import firebase from 'firebase/app';
import 'firebase/firestore';

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
const geoApi = require('../config/keys').geoApi;
class ImportDialog extends Component{
    constructor(props) {
        super(props);
        this.state = {
            worksites: [],
            data: [],
            duration: 0,
            preparing: true,
            showInfo: false,
            onGoing: false,
            importComplete: false,
            progress: 0,
        }
    }

    componentDidMount() {
        const {user} = this.props.user;
        this.unsubscribeWorksiteListener = firebase.firestore().collection("company").doc(user.companyID).collection("worksites").onSnapshot(querySnap => {
            const worksites = [];

            querySnap.forEach(doc => {
                const worksite = doc.data();
                worksite.id = doc.id;
                worksites.push(worksite);
            });

            this.setState({ worksites });
        });
    }

    componentWillUnmount() {
        this.unsubscribeWorksiteListener && this.unsubscribeWorksiteListener();

        this.importInterval && clearInterval(this.importInterval);
        this.progressInterval && clearInterval(this.progressInterval);
        this.linearProgress && clearInterval(this.linearProgress);
    }

    secondsToHms = (d) => {
        d = Number(d);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hDisplay = h === 0 ? "00." : (h < 10 ? "0" + h + "." : h + '.');
        let mDisplay = m === 0 ? "00." : (m < 10 ? "0" + m + "." : m + '.');
        let sDisplay = s === 0 ? "00" : (s < 10 ? "0" + s : s);

        return hDisplay + mDisplay + sDisplay;
    };

    startProgressInterval = size => {
        const dur = (1300 * size) / 100;

        this.linearProgress = setInterval(() => {
            this.setState(prevState => ({progress: prevState.progress + 1}))
        }, dur);

        this.progressInterval = setInterval(() => {
            this.setState(prevState => ({duration: prevState.duration - 1}))
        }, 1000)
    };

    csvPreparation = (data) => {
        const duration = data.length * 1.3;

        this.setState({ duration, data, preparing: false, showInfo: true });
    };

    importCustomers = () => {
        const {data, worksites} = this.state;
        const {user} = this.props.user;

        this.startProgressInterval(data.length);

        let i = 0;
        this.importInterval = setInterval(() => {
            console.log(data[i]);
            const singleData = data[i];
            let type = singleData[12];

            console.log('import customer type ' +  type + " data: " + singleData);
            if (type === "Business") {
                type = 'company';
            } else {
                type = 'private';
            }


            let newCustomer = {
                customerNumber: singleData[0],
                name: singleData[1],
                active: singleData[2],
                streetAddress: singleData[3],
                postCode: singleData[4],
                city: singleData[5],
                phone: singleData[6],
                email: singleData[7],
                streetAddressExt: singleData[8],
                yCode: singleData[9],
                type: type,
                companyID: [user.companyID],
                createdAt: Date(),
                createdBy: user.name,
            };

            if (type === "company") {
                newCustomer["companyName"] = singleData[1]; //same as name
                const eInvoice = singleData[10];
                const operatorIdentifier = singleData[11];
                if (eInvoice || operatorIdentifier) {

                    const billingSettings = {
                        eInvoiceAddress: eInvoice,
                        operatorIdentifier: operatorIdentifier,
                        sendingMethod: "apix",
                    }
                    
                    newCustomer["billingSettings"] = billingSettings;
                }
            }

            newCustomer.active = newCustomer.active === "Y";

            console.log('import customer ', newCustomer);

            const docRef = firebase.firestore().collection("customers").doc();

            const newWorkSite = {
                streetAddress: newCustomer.streetAddress,
                city: newCustomer.city,
                postCode: newCustomer.postCode,
                name: newCustomer.streetAddress,
                isDefault: "true",
                customer: docRef.id
            };

            const customerExists = this.props.customers.find(customer => parseInt(customer.customerNumber) === parseInt(newCustomer.customerNumber));


            if (customerExists) {
                const workSiteExists = worksites.filter(worksite => worksite.customer === customerExists.customerNumber).find(site => site.name === newWorkSite.name);
                if (!workSiteExists) this.getCoordinates(newWorkSite);
                //update data - uncomment if need to update
                //firebase.firestore().collection("customers").doc(docRef.id).set(newCustomer);
            }
            else {
                // Add customer
                firebase.firestore().collection("customers").doc(docRef.id).set(newCustomer);

                // Add worksite
                this.getCoordinates(newWorkSite);
            }

            if (i === data.length - 1) {
                clearInterval(this.importInterval);
                clearInterval(this.progressInterval);
                clearInterval(this.linearProgress);

                this.setState({
                    importComplete: true,
                    onGoing: false
                })
            }

            i++;
        }, 1300);

    };

    getCoordinates = site => {
        const {user} = this.props.user;
        // Get coordinates for worksite
        if (site.streetAddress && site.city) {
            let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${site.streetAddress},+${site.city}&key=${geoApi}`;
            axios
                .get(url)
                .then(resp => {
                    let location = resp.data.results[0].geometry.location;
                    site.lat = location.lat;
                    site.lng = location.lng;
                    firebase.firestore().collection('company').doc(user.companyID).collection('worksites').add(site);

                })
                .catch(err => console.log(err));
        }
    };

    onDialogClose = () => {
        clearInterval(this.importInterval);
        clearInterval(this.progressInterval);
        clearInterval(this.linearProgress);

        this.props.onClose();
    };

    render() {
        const {t} = this.props;

        return (
            <Dialog
                open={this.props.open}
                onClose={this.onDialogClose}
                maxWidth="xs"
                fullWidth
            >
                <DialogTitle style={{textAlign: 'center'}}>{t("Import")}</DialogTitle>
                <DialogContent>
                    {this.state.preparing &&

                        <CSVReader
                            cssClass="csv-input"
                            label="Valitse CSV tiedosto"
                            onFileLoaded={this.csvPreparation}
                            onError={err => console.log(err)}
                            inputID="Customers"
                        />


                    }

                    {this.state.showInfo &&
                        <div style={{ height: 80, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'}}>
                            <p>{this.props.t("ImportingCustomers")}</p>
                            <p style={{fontWeight: 'bold'}}>{this.secondsToHms(this.state.duration)}</p>
                            <p>{this.props.t("WorksiteCoord")}</p>
                        </div>
                    }

                    {this.state.onGoing &&
                        <div>
                            <LinearProgress
                                variant="determinate"
                                value={this.state.progress}
                            />

                            {this.secondsToHms(this.state.duration)}
                        </div>
                    }

                    {this.state.importComplete &&
                        <div style={{textAlign: 'center'}}>
                            <p style={{fontSize: 18, fontWeight: 'bold'}}>{this.props.t("Complete")}</p>
                        </div>
                    }


                </DialogContent>
                <DialogActions>
                    {this.state.showInfo &&
                        <Button
                            onClick={() => this.setState({onGoing: true, showInfo: false}, () => this.importCustomers())}
                        >
                            {this.props.t("Import")}
                        </Button>
                    }

                </DialogActions>

            </Dialog>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(ImportDialog));
