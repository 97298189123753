import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import moment from 'moment-timezone';

import FullCalendarComponent from './FullCalendar';

import {connect} from 'react-redux';
import {getOccurrences} from "../CalendarComponents/OccurrencesComponent";

class HomeCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            employees: [],
            selectedEmployees: [],
            customers: [],
            models: [],
            worksites: [],
            jobs: [],
            worklogs: [],
            events: [],
            eventsCopy: [],
            dateRange: {
                selection: {
                    key: 'selection',
                    startDate: moment().startOf("day").toDate(),
                    endDate: moment().endOf("day").toDate()
                }
            },
            viewRange: null,
            activeDates: {
                view: {
                    activeStart: moment().startOf("week"),
                    activeEnd: moment().add(1, "week").startOf("week")
                }
            },
            fullCalendar: {
                defaultDate: moment(),
                defaultView: "agendaDay",
                activeDays: 0,
                aspectRatio: (window.innerWidth * 0.6) / (window.innerHeight * 0.74)
            },
            defaultDate: "",
            wlQueryFromValue: moment().startOf("week").format("YYYYMMDD"),
        }
    }

    componentDidMount() {
        const {user} = this.props.user;
        const {wlQueryFromValue} = this.state;

        this.unsubscribeEmployees = firebase.firestore().collection('employees')
            .where("companyID", "==", user.companyID)
            .onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "employees"));


        this.unsubscribeCustomers = firebase.firestore().collection('customers')
            .where("companyID", "array-contains", user.companyID)
            .onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "customers"));

        this.unsubscribeWorklogs = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs")
            .where("status", "==", "Cancelled")
            .where("startDT", ">=", wlQueryFromValue)
            .onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "worklogs"));

        this.unsubscribeJobModels = firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "models"));

        this.unsubscribeWorksites = firebase.firestore().collection('company').doc(user.companyID).collection('worksites').onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "worksites"));

        this.unsubscribeJob = firebase.firestore().collection('company').doc(user.companyID).collection('jobs').onSnapshot(querySnapshot => this.onQuerysnapshot(querySnapshot, "jobs"));

        this.unsubscribeCompany = firebase.firestore().collection('company').doc(user.companyID).onSnapshot(docSnap => {
            const company = docSnap.data();
            company.id = docSnap.id;

            this.setState({ company })
        });

    }

    componentWillUnmount() {
        this.unsubscribeEmployees && this.unsubscribeEmployees();
        this.unsubscribeCustomers && this.unsubscribeCustomers();
        this.unsubscribeJobModels && this.unsubscribeJobModels();
        this.unsubscribeWorksites && this.unsubscribeWorksites();
        this.unsubscribeJob && this.unsubscribeJob();
        this.unsubscribeWorklogs && this.unsubscribeWorklogs();
        this.unsubscribeCompany && this.unsubscribeCompany();
    }

    onQuerysnapshot= (snap, field) => {
        const dataArr = [];
        const selectedEmployees = [];

        snap.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;

            if (field === "employees") {
                data.name = `${data.firstName} ${data.familyName}`;
                //dont include deleted employees
                if(!data.isDeleted) {
                    selectedEmployees.push(data.id);
                    dataArr.push(data)
                }
            } else {
                dataArr.push(data)
            }
        });

        this.setState({
            [field]: dataArr
        });

        if (field === "employees") this.setState({ selectedEmployees });
        if (field === "jobs") this.eventHandler(this.state.activeDates, true);
        if (field === "worklogs" && !this.state.loading) this.eventHandler(this.state.activeDates, true);
    };

    getEmployee = (id, field) => {
        const {employees} = this.state;

        const employee = employees.find(employee => employee.id === id);

        if (employee) {
            return employee[field];
        }
    };

    eventHandler = (event, override) => {
        const startMoment = moment(event.view.activeStart);
        const endMoment = moment(event.view.activeEnd);

        let events = [];

        if (this.state.viewRange === null) {
            this.setState({ viewRange: event.view.activeStart});
        }

        if ((!moment(this.state.viewRange).isSame(event.view.activeStart) && this.state.jobs) || override) {

            this.state.jobs.forEach(job => {

                const eventArr = getOccurrences(job, startMoment, endMoment);

                events = events.concat(eventArr)
            });


            let id = 1;
            let finalEvents = [];

            events.forEach(event => {
                if (event.employees) {
                    if (event.employees.length === 0) {
                        let employeeEvent = {...event};
                        employeeEvent.id = id;
                        finalEvents.push(employeeEvent);
                        id++;
                    }
                    event.employees.forEach(employee => {
                        const employeeEvent = {...event};
                        employeeEvent.color = this.getEmployee(employee.id, "color");
                        employeeEvent.id = id;
                        employeeEvent.employee = employee.id;
                        finalEvents.push(employeeEvent);
                        id++;
                    });
                }
            });


            if (this.state.employees.length !== this.state.selectedEmployees.length) {
                const filteredByEmployee = finalEvents.filter(event => this.state.selectedEmployees.includes(event.employee));
                finalEvents = filteredByEmployee
            }

            // Go trough final events array to find cancelled jobs
            finalEvents.forEach(event => {
                this.state.worklogs && this.state.worklogs.forEach(log => {
                    if (moment(event.start).isSame(log.startDT) && event.jobID === log.jobID) {
                        event.color = "#333333"
                    }
                });
            });


            if (finalEvents && finalEvents.length > 0) {
                const {fullCalendar, activeDates} = this.state;

                fullCalendar.defaultDate = event.view.activeStart;

                activeDates.view.activeStart = event.view.activeStart;
                activeDates.view.activeEnd = event.view.activeEnd;

                if (this.state.loading) fullCalendar.defaultDate = moment().startOf("day");

                this.setState({
                    loading: false,
                    events: finalEvents,
                    eventsCopy: [...finalEvents],
                    viewRange: event.view.activeStart,
                    activeDates,
                    fullCalendar
                });
            }

        }
    };

    render() {
                
        return (
            <FullCalendarComponent
                jobs={this.state.jobs}
                events={this.state.events}
                getEvents={this.eventHandler}
                fullCalendar={this.state.fullCalendar}
                worksites={this.state.worksites}
                customers={this.state.customers}
                employees={this.state.employees}
                models={this.state.models}
                company={this.state.company}
                defaultDate={this.state.defaultDate}
            />
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(HomeCalendar);
