import React, {Component} from 'react';

import {ClickAwayListener} from "@material-ui/core";
import classNames from 'classnames';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChevronRight,
    faChevronLeft
} from "@fortawesome/free-solid-svg-icons";

import './ActionMenu.css';
class ActionMenu extends Component{
    constructor(props) {
        super(props);
        this.state = {
            activeOption: null
        }
    }


    handleExtraMenu = action => {
        this.setState({ activeOption: action });
    };

    handleExtraMenuClick = option => {
        const {activeOption} = this.state;

        this.props.onSelect(activeOption.action, option);
        this.props.onClickAway();
    };

    render() {
        const {anchor} = this.props;

        const horizontal = classNames({
            "action-menu-horizontal": true,
            "right-active": this.state.activeOption

        });
        return (
            <ClickAwayListener onClickAway={this.props.onClickAway}>
                <div className="action-menu" style={{top: anchor.height + 3, left: anchor.width - 110,}}> {/* how menu will align */}
                        <div className={horizontal}>
                            <div className="horizontal-left">
                                {this.props.menuActions.map(item => (
                                    <React.Fragment  key={item.action}>
                                        {!item.methods &&
                                            <div className="action-menu-item" onClick={() => this.props.onSelect(item.action)}>
                                                {this.props.t(item.action)}
                                            </div>
                                        }
                                        {item.methods &&
                                            <div className="action-menu-item" id="action-menu-item" onClick={() => this.handleExtraMenu(item)}>
                                                {this.props.t(item.action)}

                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </div>
                                        }
                                    </React.Fragment>
                                ))}
                            </div>

                            <div className="horizontal-right">
                                <div className="action-menu-item back" onClick={() => this.setState({activeOption: null })}>
                                    <FontAwesomeIcon icon={faChevronLeft} />

                                    <p>{this.props.t("Back")}</p>

                                </div>

                                {this.state.activeOption && this.state.activeOption.methods.map(option => (
                                    <div key={option} className="action-menu-item" id="action-menu-item" onClick={() => this.handleExtraMenuClick(option)}>
                                        {this.props.t(option)}


                                    </div>
                                ))}
                            </div>

                        </div>


                </div>
            </ClickAwayListener>

        )
    }

}

export default ActionMenu;
