import React, { Component } from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { Button, FormControlLabel, Checkbox } from '@material-ui/core';

import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";

import { connect } from 'react-redux';

import { saveSend } from "../../actions/sendapprovalActions";

import { withTranslation } from "react-i18next";
import { createReport, uploadToFirebaseStorage } from "../reportStoreFunctions";
import { isEmpty } from "../../validations/isEmpty";
import ReportTypes from "../ReportTypes";
import { durationToHHmm } from '../../util/TimeUtils';

class PDFWorkshifts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docRef: {},
            looping: false
        };
    }

    /*
    secondsToHHMM = (d) => {
        console.log("secondsToHHMM ", d)
        d = Number(d);

        if (d > 60) {
            let h = Math.floor(d / 3600);
            let m = Math.floor(d % 3600 / 60);
            let s = Math.floor(d % 3600 % 60);

            let hDisplay = h === 0 ? "00:" : (h < 10 ? "0" + h + "h " : h + 'h');
            let mDisplay = m === 0 ? "00" : (m < 10 ? "0" + m + "min" : m + "min");
            console.log("secondsToHHMM hDisplay + mDisplay ", hDisplay + mDisplay)
            return hDisplay + mDisplay;

        } else {
            return "--:--"
        }
    }
    '*/

    findEmployee = ids => {
        const { employees, selectedEmployee } = this.props;

        if (selectedEmployee) return selectedEmployee;

        const nameArr = [];

        ids && ids.forEach(id => {
            const employee = employees.find(employee => employee.id === id);

            const name = `${employee.firstName} ${employee.familyName}`;

            if (employee && !nameArr.includes(name)) nameArr.push(name)
        });

        return nameArr.join(", ");
    };

    getPartners = (job, employee) => {
        const { employees } = this.props;

        let partners = [];

        if (job.employees) {
            partners = job.employees.filter((empl) => empl.id != employee.id)
        }

        if (partners && partners.length > 0) {
            const nameArr = [];
            partners.forEach(partner => {
                //console.log("partner ", partner)
                const name = partner.name.slice(0, partner.name.indexOf(" ")); //taker first part of name
                if (employee && !nameArr.includes(name)) nameArr.push(name)
            })
            return nameArr.join(", ");
        } else {
            return null;
        }
    }

    checkTitle = (oldTitle, newTitle) => {

        const commaRegex = /^.*[,].*$/;

        const newTitleArr = [];

        if (commaRegex.test(oldTitle)) oldTitle.split(", ").forEach(item => {
            if (!newTitleArr.includes(item)) newTitleArr.push(item);
        });
        else if (!newTitleArr.includes(oldTitle)) newTitleArr.push(oldTitle);


        if (commaRegex.test(newTitle)) newTitle.split(", ").forEach(item => {
            if (!newTitleArr.includes(item)) newTitleArr.push(item);
        });
        else if (!newTitleArr.includes(newTitle)) newTitleArr.push(newTitle);


        return newTitleArr.join(", ");

    };

    formRowTitle = item => {
        const { descriptionField } = this.props;
        const { t } = this.props;

        let title = descriptionField === "task" ? [] : "";

        if (descriptionField === "task" && item.billing && item.billing.billableHoursByTask) {
            item.billing.billableHoursByTask.forEach((task, index) => {

                if (task.duration > 0) title.push(task.name)
            });

            return title.length === 0 ? t("No_billable_task") : title.length === 1 ? title[0] : title.join(", ");
        }
        if (descriptionField !== "task") return item.additionalDescription ? item.additionalDescription : "";
        else return "";

    };

    formFreeText = () => {
        const { company } = this.props;

        let freeText = "";

        if (company.settings.reports && company.settings.reports.approvalFreeText) freeText = company.settings.reports.approvalFreeText;

        return freeText
    };


    /*
    addWorkDataToDay = (oldRow, newRow) => {

        let combinedRow = [...oldRow];
        combinedRow[2] = this.checkTitle(oldRow[2], newRow[2]);
        combinedRow[3] = oldRow[3] === newRow[3] ? oldRow[3] : oldRow[3] + ', ' + newRow[3];
        combinedRow[5] = parseFloat(oldRow[5]) + parseFloat(newRow[5]);
        combinedRow[8] = parseFloat(oldRow[8]) + parseFloat(newRow[8]);
        combinedRow[9] = parseFloat(oldRow[9]) + parseFloat(newRow[9]);

        console.log("addWorkDataToDay ",combinedRow)
        return combinedRow;
    };
    */

    addJobToDay = (day, job) => {
        let combinedDay = { day };
        console.log("addJobToDay ", combinedDay, job);

        if (!combinedDay.jobs) {
            combinedDay.jobs = [];
        }
        combinedDay.jobs.push(job);

        //day.dayTotal += job[4];
        return combinedDay;
    }

    createPdf = (data, employee) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { t } = this.props;
        const { docRef } = this.state;
        let ref = {};
        const { user } = this.props.user;
        const { company } = this.props;

        const weekNumbers = [];
        const weekdayArr = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
        const body = [];

        const reportSummary = {
            normalHours: 0,
        };

        data.sort((a, b) => moment(a.start) - moment(b.start));

        data && data.forEach(item => {
            const itemWeek = moment(item.start).format('w');
            const itemDay = moment(item.start).format('dddd');

            console.log("data.forEach item ", itemDay, "week", itemWeek);

            // Count hours
            let hours = 0;
            //if (this.props.selectedEmployee) hours = item.billing && item.billing.billableHours ? (item.billing.billableHours / 3600000).toFixed(2) : 0;
            //else hours = item.billing && item.billing.billableHours ? ((item.billing.billableHours / 3600000) * item.employee.length).toFixed(2) : 0;

            //create job
            const duration = (moment(item.end) - moment(item.start));
            let job = {
                start: item.start, //moment(item.start).locale(user.lang ? user.lang : "fi").format('HH:mm')
                end: item.end,
                title: item.title, //this.formRowTitle(item),
                //this.props.getEmployeeNameString(item.employees),
                partners: this.getPartners(item, employee),
                duration: durationToHHmm(duration),
                height: duration / 1000 / 60 / 3,  //use as height = 3min / 1px
            };

            console.log("job= ", job);


            if (ref[itemWeek]) {
                console.log("existing week ", itemWeek, itemDay);
                // If day already Exists add required fields to that instead of overwriting
                if (ref[itemWeek][itemDay] && ref[itemWeek][itemDay].jobs && ref[itemWeek][itemDay].jobs.length > 0) {
                    console.log("existing jobs ", itemWeek, itemDay, ref[itemWeek][itemDay].jobs, " add job ", job);
                    //ref[itemWeek][itemDay] = this.addJobToDay(ref[itemWeek][itemDay], job);
                    ref[itemWeek][itemDay].jobs.push(job)
                    console.log("added job to a day new day to week ref[itemWeek][itemDay] ", ref[itemWeek][itemDay], itemDay, itemWeek, job)
                }
                else {
                    console.log("NEW jobs Arrayfor ", itemWeek, itemDay, " == ", ref[itemWeek][itemDay]);
                    ref[itemWeek][itemDay].jobs = [];
                    ref[itemWeek][itemDay].jobs.push(job);
                    console.log("created new day to week ref[itemWeek][itemDay] ", ref[itemWeek][itemDay], itemDay, itemWeek)
                }
                ref[itemWeek].weekTotal += parseFloat(hours);
                reportSummary.normalHours += parseFloat(hours);
                //reportSummary.km += km;
            }
            else {
                //create new week and add this one jo to correct day
                console.log("new week ", itemWeek);
                ref[itemWeek] = {
                    Monday: {},
                    Tuesday: {},
                    Wednesday: {},
                    Thursday: {},
                    Friday: {},
                    Saturday: {},
                    Sunday: {},
                    weekTotal: 0,
                    //weekKm: 0
                };
                weekNumbers.push(itemWeek);
                ref[itemWeek][itemDay].jobs = [];
                ref[itemWeek][itemDay].jobs.push(job);
                ref[itemWeek].weekTotal += parseFloat(hours);
                reportSummary.normalHours += parseFloat(hours);
                //reportSummary.km += km;
            }
        });

        console.log("ref table ", ref);

        /*
        weekNumbers.forEach(week => {
            //one row is x minutes for each day 

            weekdayArr.forEach(day => {
            
                const short = day.slice(0, 2);
                const emptyRow = ["", "", "", "", "", "", ""];
                ref[week][day].length > 0 ? body.push(ref[week][day]) : body.push(emptyRow);
            });
        });*/

        let weeksTable = [];

        weekNumbers.forEach(week => {
            //let weekTable = [];
            //one row is x minutes for each day  - unfortunately like this its only for the days that have jobs
            weekdayArr.forEach(day => {
                let dayTable = [];
                let marginTop = 0;
                let marginBottom = 0;

                // fist line is the date of the day
                dayTable.push({
                    margin: [0, 0, 0, 0],
                    layout: 'noBorders',
                    table: {
                        heights: [8],
                        widths: [96],
                        body: [
                            [{
                                text: `${ref[week][day].jobs ? moment(ref[week][day].jobs[0].start).locale(user.lang ? user.lang : "fi").format("LL")  : "-" }`
                            },
                            ]
                        ],
                    }
                })

                if (ref[week][day].jobs && ref[week][day].jobs.length > 0) {
                    let prevEnd = moment(ref[week][day].jobs[0].start);
                    prevEnd.set({ hour: "06", minute: "00", second: "00" }) //todo - parameter!
                    console.log("prevEnd ", prevEnd);

                    ref[week][day].jobs.forEach((job) => {
                        const prevTime = (moment(job.start) - prevEnd) / 1000 / 60 / 3; //3 minutes in pixels
                        marginTop = prevTime > 0 ? prevTime : 0;
                        console.log("marginTop ", marginTop);
                        dayTable.push({
                            margin: [0, marginTop, 0, marginBottom],
                            table: {
                                border: [true, true, true, true],
                                fillColor: "red",
                                heights: [job.height],
                                widths: [92],
                                body: [
                                    [{
                                        text: `${moment(job.start).locale(user.lang ? user.lang : "fi").format('HH:mm')} - ${moment(job.end).locale(user.lang ? user.lang : "fi").format('HH:mm')}
                                         ${job.title} ${job.partners ? ` ${t("Workshift_report_partner_in_short")} ${job.partners}` : ''}`
                                    },
                                    ]
                                ],
                            }
                        })
                        prevEnd = moment(job.end);
                        console.log("prevEnd ", prevEnd);
                    })
                    //todo font and margin
                } else {
                    console.log("empty day ", day);
                }
                console.log("dayTable ", dayTable);

                weeksTable.push(dayTable)
            })
        });
        console.log("weeksTable ", weeksTable[0]);

        let week = {
            fontSize: 8,
            margin: [10, 0, 0, 0],
            table: {
                headerRows: 0,
                widths: [100, 100, 100, 100, 100, 100, 100],
                heights: [400, 400, 400, 400, 400, 400, 400],
                
                body: [weeksTable],

            },
            //pageBreak: 'after'
            /*layout: {
                hLineColor: function (i, node) {
                    return (i === 0 || i === node.table.body.length) ? 'red' : 'blue';
                },
                vLineColor: function (i, node) {
                    return (i === 0 || i === node.table.widths.length) ? 'red' : 'blue';
                },
            }*/
        };

        console.log("week ", week);

        /*
        let total = {
            fontSize: 10,
            margin: [506, 0, 30, 0],
            table: {
                headerRows: 0,
                widths: [50, 30, 30, 30, 30, 30],
                body: [
                    [{
                        text: t("Total") + ":",
                        fontSize: 10,
                        alignment: "right"
                    }, reportSummary.normalHours, 0, 0, reportSummary.normalHours, 0]
                ]
            }
        };*/

        docRef.content.push(week);
        //docRef.content.push(total);

        //pdfMake.createPdf(docRef).download();
        if (this.props.openInNewTab) pdfMake.createPdf(docRef).open();
        //this.props.onReportsCreated(false);

        pdfMake.createPdf(docRef).getBase64((dataUrl) => {

            let reportOwner = !isEmpty(employee) && employee.name ? employee.name : '';
            //if (!isEmpty(this.state.worksiteName))reportOwner = reportOwner + "/" + this.state.worksiteName;

            //TODO, other default address
            const recipients = [employee.email]; //this.props.initialEmail ? this.props.initialEmail : [];

            const fileName = t(ReportTypes.WORKLIST) + '_' + reportOwner + "_" + t('Weeks') + ' ' + this.state.startWeek + '-' + this.state.endWeek;

            uploadToFirebaseStorage(user, dataUrl, ReportTypes.WORKLIST).then(filestorageSuccess => {
                console.log('succesfully writed pdf to storage', filestorageSuccess)

                createReport(user, ReportTypes.WORKLIST, reportOwner, recipients, "email", fileName, dataUrl, filestorageSuccess)
                    .then((report) => {
                        console.log("storeReport success: ", report);
                        this.setState({
                            docRef: {}
                        })
                        this.props.onReportsCreated(true);
                    })
                    .catch((err) => {
                        console.log("storeReport ERROR: ", err);
                        this.setState({
                            docRef: {}
                        })
                        this.props.onReportsCreated(false);
                    })

            })
        })
    }



    generateHeader = (data, employee) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { user, customerModel, selectedEmployee, t, start, end, company } = this.props;

        let headerSize = 14,
            normalText = 10,
            smallText = 8;

        data.sort(function (a, b) {
            if (moment(a.startDT).isSameOrBefore(b.startDT)) return -1;
            if (moment(a.startDT).isSameOrAfter(b.startDT)) return 1;
        });

        let first = data[0];
        let last = data[data.length - 1];

        let body = [];

        if (this.props.company) {

            body = [
                [{ text: company.name, fontSize: headerSize }, { text: employee.name, fontSize: headerSize }],
                [{ text: company.streetAddress, fontSize: normalText }, { text: '', fontSize: normalText }],
                [{ text: company.postCode + ' ' + company.city, fontSize: normalText }, { text: ``, fontSize: normalText }],
            ]
        }


        let docRef = {
            header: function (currentPage, pageCount, pageSize) {
                // you can apply any logic and return any valid pdfmake element
                //console.log("called header fucntion with ", currentPage, "/", pageCount, pageSize);

                return [
                    /*{ text: "Sivu:" + currentPage + "/" + pageCount, alignment: 'right', marginRight: 15, marginTop: 15},*/
                    {
                        margin: [30, 10, 15, 0],
                        fontSize: 10,
                        layout: 'noBorders',
                        table: {
                            headerRows: 1,
                            widths: [pageSize.width / 10, pageSize.width / 10, pageSize.width / 2 - 100, pageSize.width / 10, pageSize.width / 10, pageSize.width / 10],
                            body: [
                                [
                                    { text: company.name, fontSize: smallText },
                                    { text: t("Report page Company_id title") + company.yCode, fontSize: smallText },
                                    {
                                        text: t("Workshift_weeks") + moment(start).format('ww') + '-' + moment(end).format('ww') + "/" + moment(first).format('YYYY'),
                                        fontSize: smallText,
                                        alignment: 'center'
                                    },
                                    { text: t("Workshift report created") + moment().locale(user.lang ? user.lang : "fi").format("LLL"), fontSize: smallText },

                                    {
                                        text: t("Report page title") + currentPage + "/" + pageCount,
                                        fontSize: smallText,
                                        alignment: 'right'
                                    },
                                ],
                            ],
                        }
                    }
                ]
            },
            footer: function (currentPage, pageCount) {
                return [{
                    text: "Sivu:" + currentPage + "/" + pageCount,
                    alignment: 'right',
                    fontSize: smallText,
                    marginRight: 25,
                    marginBottom: 5
                }]
            },
            content: [
                {
                    margin: [0, 0, 0, 0],
                    table: {
                        headerRows: 1,
                        widths: ['*'],
                        body: [
                            [
                                {
                                    margin: [10, 0, 0, 10],
                                    table: {
                                        headerRows: 1,
                                        widths: ['*', '*'],
                                        body: body
                                    },
                                    layout: 'noBorders'
                                }
                            ]
                        ]
                    },
                    layout: 'noBorders'
                },
                {
                    text: moment(first).format('YYYY') + ' viikot ' + moment(start).format('ww') + ' - ' + moment(end).format('ww'),
                    marginLeft: 280
                },
                {
                    margin: [10, 5, 0, 0],
                    fontSize: 10,
                    table: {
                        headerRows: 1,
                        widths: [100, 100, 100, 100, 100, 100, 100],
                        body: [
                            [t('Mo'), t('Tu'), t('We'), t('Th'), t('Fr'), t('Sa'), t('Su')],
                        ]
                    }
                }
            ],
            pageMargins: [30, 60, 10, 30],
            pageOrientation: 'landscape'
        };

        this.setState({
            docRef: docRef,
            startWeek: moment(start).format('ww'),
            endWeek: moment(end).format('ww'),
        }, () => { //DO AFTER STATE HAS BEEN SET
            console.log("call createPdf with data ", data)
            this.createPdf(data, employee);
        });

        /*
        setTimeout(() => {
            console.log("call createPdf with data ", data)
            this.createPdf(data, employee)
        }, 100);
        */

    };

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    startGenerate = () => {
        const { selectedEmployee, selectedJobs, employees, t, start, end, reportsCreating } = this.props;

        if (selectedEmployee) {

            this.props.onReportCreate();

            const employee = employees.find(empl => empl.name === selectedEmployee);
            this.generateHeader(selectedJobs, employee);
        } else {
            //all employees
            employees.forEach((employee, index) => {

                this.sleep(100).then(() => { //give some time for state update
                    console.log("reportsCreating ", reportsCreating);
                    if (reportsCreating) {
                        while (reportsCreating) {
                            console.log("WAITING FOR PREVIOUS REPORT TO COMPLETE", reportsCreating)
                            this.sleep(50).then(() => {
                                console.log("WAITING FOR PREVIOUS REPORT TO COMPLETE...", reportsCreating)
                            })
                        }
                    }
                    if ((employee.isActive || employee.isActive === "true")) {  //filter by active employees (can be bool or string -type )
                        //start processing one employee
                        this.props.onReportCreate();
                        const jobsCopy = [...selectedJobs]; //take copy of array, which has all employee's jobs


                        console.log("find jobs for employee ", employee)
                        const jobs = jobsCopy.filter((job) => (job.employees.some((emp) => emp.id === employee.id))) //filte by employee in turn

                        if (jobs && jobs.length > 0) { //if jobs found for this employee
                            this.generateHeader(jobs, employee); //start create pdf
                        }
                        else console.log("cannot find jobs for employee ", employee)
                    } else {
                        console.log(" employee ", employee, " is not active ");
                    }
                })
            })

        }
    }

    render() {

        return (
            <div>
                <Button
                    variant="outlined"
                    style={{
                        width: 210,
                        marginLeft: 50,
                        marginTop: 10
                    }}
                    onClick={() => this.startGenerate()}
                >
                    {this.props.t("Generate_PDF")}
                </Button>
                <FormControlLabel
                    style={{
                        marginLeft: 50,
                        marginTop: 10,
                    }}
                    control={
                        <Checkbox
                            checked={this.props.openInNewTab}
                            onChange={(event) => this.props.onChangeOpenInNewTab(event.target.checked)}
                            value={"checkOpenOInNewTab"}
                            color="primary"
                        />
                    }
                    label={this.props.t("Open in new tab")}
                />
                <div style={{
                    marginLeft: 50,
                    marginTop: 10,
                }}>{this.props.t("Open in new tab tooltip")}</div>

            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, { saveSend })(withTranslation()(PDFWorkshifts));