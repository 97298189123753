import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from "@material-ui/core";
import moment from "moment-timezone";

import {withTranslation} from "react-i18next";

import DateDialog from './DateDialog';

class EditDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: true,
            datePicker: false,
            recurringRule: "",
            rrStart: null,
            rrEnd: null
        };
    }

    handleDateChange = event => {

        this.setState({
            datePicker: false,
            rrStart: event.selection.startDate,
            rrEnd: event.selection.endDate
        });
    };

    getTitleText = (mode, selectedJob) => {
        const interval = selectedJob.rrule.INTERVAL;

        let title = "";

        if (interval === 0 && !mode) { title = "Edit event"}
        if (interval === 0 && mode) { title = "Delete event"}
        if (interval > 0 && !mode) { title = "Edit recurring event"}
        if (interval > 0 && mode) { title = "Delete recurring event"}
        return title;

    };

    render() {
        const {t} = this.props;
        return (
            <div>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.props.onClose()}
                    maxWidth="sm"
                    fullWidth
                    disableEnforceFocus
                >
                    <DialogTitle>{t(this.getTitleText(this.props.deleteMode, this.props.selectedJob))}</DialogTitle>

                    <DialogContent>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.recurringRule === "once"}
                                    onChange={() => this.setState({recurringRule: "once"})}
                                    value="once"
                                    color="primary"
                                />
                            }
                            label={t("One time")}
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.recurringRule === "onward"}
                                    onChange={() => this.setState({recurringRule: "onward"})}
                                    value="onward"
                                    color="primary"
                                />
                            }
                            label={t("This and onward")}
                        />
                        <br/>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.recurringRule === "range"}
                                    onChange={() => this.setState({recurringRule: "range"})}
                                    value="range"
                                    color="primary"
                                />
                            }
                            label={t("Range")}
                        />
                        <br/>
                        {this.props.deleteMode &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.recurringRule === "all"}
                                        onChange={() => this.setState({recurringRule: "all"})}
                                        value="all"
                                        color="primary"
                                    />
                                }
                                label={t("Delete all events")}
                            />
                        }
                        <br/>
                        {this.state.recurringRule === "range" && <div>
                            <Button onClick={() => this.setState({datePicker: true})}>
                                {this.state.rrStart === null && this.state.rrEnd === null && t("Select")}
                                {this.state.rrStart !== null && this.state.rrEnd !== null && moment(this.state.rrStart).locale('fi').format('L') + ' - ' + moment(this.state.rrEnd).locale('fi').format('L')}
                            </Button>
                        </div>}

                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.props.onClose()}
                        >
                            {t("Cancel")}
                        </Button>

                        {!this.props.deleteMode && <Button
                            variant="outlined"
                            color="primary"
                            style={{
                                marginLeft: 10,
                                marginRight: 50
                            }}
                            onClick={() => this.props.onAccept(this.state.recurringRule, this.state.rrStart, this.state.rrEnd)}
                        >
                            {t("Save")}
                        </Button>}

                        {this.props.deleteMode && <Button
                            variant="outlined"
                            color="primary"
                            style={{
                                marginLeft: 10,
                                marginRight: 50
                            }}
                            onClick={() => this.props.onDelete(this.state.recurringRule, this.state.rrStart, this.state.rrEnd)}
                        >
                            {t("Save")}
                        </Button>}
                    </DialogActions>
                </Dialog>

                {this.state.datePicker &&
                    <DateDialog
                        selectType="range"
                        onClose={() => this.setState({datePicker: false})}
                        onChange={event => this.handleDateChange(event)}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(EditDialog));