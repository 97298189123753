import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';

import AutocompleteReports from '../ReportComponents/AutocompleteReports'
import DateDialog from '../CalendarComponents/DateDialog';

import moment from 'moment';
import "moment/locale/fi"
import uuid from 'uuid';

import {
    Paper,
    Button,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    CircularProgress
} from '@material-ui/core';

import { DateRange } from "react-date-range";
import { fi, enGB } from 'date-fns/locale'

import { validateFBWorklog } from "../validations/worklog";
import { withTranslation } from 'react-i18next';
import { isEmpty } from "../validations/isEmpty";
import { customerObject, invoiceObject, rowsArray, refNumberCounter } from "./BillDetailsComponents/InvoiceObjectForming";

import "./BillingLanding.css";
import { Toast, ToastWrapper } from "show-toast-box"
import {solveBillingErrorMessage} from "../util/BillServerErrorHelper";

class BillingLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: {},
            customers: [],
            employees: [],
            worklogs: [],
            products: [],
            nonBillableProducts: [],
            models: [],
            selectedCustomer: "",
            dateRange: {
                selection: {
                    startDate: moment().toDate(),
                    endDate: moment().toDate(),
                    key: "selection"
                }
            },
            dateChanged: false,
            bills: {},
            customerHasBills: [],
            customerWillBeBilled: [],
            resetCustomer: "gotCustomer",
            billingDate: moment().startOf("day"),
            dateDialog: false,
            loading: true,
            savingBills: false,
            savingDialog: false,
            errorMsg: ''
        };

        this.wrapper = React.createRef();
    }

    componentDidMount() {
        const { user } = this.props.user;
        this.unsubscribeCustomerListener =
            firebase.firestore().collection('customers')
                .where("companyID", "array-contains", user.companyID)
                .onSnapshot(this.onCustomerSnapshot);

        this.unsubscribeEmployeeListener =
            firebase.firestore().collection('employees')
                .where("companyID", "==", user.companyID)
                .onSnapshot(this.onEmployeeSnapshot);

        this.unsubscribeWorklogListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('worklogs')
                //.where("status", "==", "Confirmed")
                //TODO: LIMIT BY TIME OR status
                .onSnapshot(this.onWorklogSnapshot);

        /*this.unsubscribeProductListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('products')
                .onSnapshot(this.onProductSnapshot);*/

        firebase.firestore().collection('company').doc(user.companyID).collection('products')
            .orderBy('pNumber', 'asc') 
            .get().then(this.onProductSnapshot);

        this.unsubscribeModelListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('jobModels')
                .onSnapshot(this.onModelSnapshot);


        // no need to be snapshot here -> yes you need if running bills again.. if not snap -then the increment bill number does not work!!
        this.unsubscribeCompanyListener = firebase.firestore().collection('company').doc(user.companyID).onSnapshot(docSnapshot => {
            this.setState({
                company: docSnapshot.data(),
                loading: false
            });
        });
    }

    onCustomerSnapshot = querySnapshot => {
        const customers = [];

        querySnapshot.forEach(doc => {
            let customer = doc.data();
            customer.id = doc.id;
            customers.push(customer)
        });

        this.setState({
            customers: customers
        });
    };

    onEmployeeSnapshot = querySnapshot => {
        const employees = [];

        querySnapshot.forEach(doc => {
            let employee = doc.data();
            employee.id = doc.id;
            employee.name = `${employee.firstName} ${employee.familyName}`;
            employees.push(employee);
        });

        this.setState({
            employees: employees
        });
    };

    onWorklogSnapshot = querySnapshot => {
        const { user } = this.props.user;
        const worklogs = [];
        querySnapshot.forEach(doc => {
            let worklog = doc.data();
            worklog.id = doc.id;

            const { errors, isValid } = validateFBWorklog(worklog);

            //if (worklog.status === "Confirmed" && worklog.billingPercent) console.log(worklog);
            if (!isEmpty(errors)) console.log("worklog " + worklog.id, " has errors ", worklog, errors, isValid);

            //if (worklog.customer === "BoP1J0o0FRcVx6b97ZRs") console.log("worklog of Mrs Green ", worklog, errors, isValid);

            if ((worklog.status === "Confirmed" || worklog.status === "Cancelled") && isValid) {
                worklogs.push(worklog);
            }
        });

        this.setState({
            worklogs: worklogs
        });
    };

    onProductSnapshot = querySnapshot => {
        const products = [];
        const nonBillableProducts = [];

        querySnapshot.forEach(doc => {
            let product = doc.data();
            product.id = doc.id;
            products.push(product);

            if (product.isBillable === "false") nonBillableProducts.push(product.pNumber);
        });

        console.log("onProductSnapshot ", products, nonBillableProducts, querySnapshot);
        this.setState({
            products: products,
            nonBillableProducts: nonBillableProducts,
        });
    };

    onModelSnapshot = querySnapshot => {
        const models = [];

        querySnapshot.forEach(doc => {
            let model = doc.data();
            model.id = doc.id;
            models.push(model);
        });

        this.setState({
            models: models
        });
    };


    componentWillUnmount() {

        this.unsubscribeCustomerListener && this.unsubscribeCustomerListener();
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
        this.unsubscribeWorklogListener && this.unsubscribeWorklogListener();
        this.unsubscribeProductListener && this.unsubscribeProductListener();
        this.unsubscribeModelListener && this.unsubscribeModelListener();
        this.unsubscribeCompanyListener && this.unsubscribeCompanyListener();
    }

    toastaa = (type, title, description) => {
        console.log("toastaa ", type, title, description)
        if (type === "success") {
            this.wrapper.current.add(
                <Toast 
                    title={title} 
                    description={description}
                    type="success" 
                    animation="slide-up" 
                    removeWhenClick={true} 
                    autoRemove={true} 
                    delay={1} 
                    duration={5000} 
                    animationDuration={2000} 
                />
            )
        } else if (type === "error") {
            this.wrapper.current.add(
                <Toast 
                    title={title} 
                    description={description}
                    type="error" 
                    animation="slide-up" 
                    removeWhenClick={true} 
                    delay={1} 
                    duration={50000} 
                    animationDuration={2000} 
                />
            )
        } else if (type === "warning") {
            this.wrapper.current.add(
                <Toast
                    title={title} 
                    description={description}
                    type="warning" 
                    animation="slide-up" 
                    autoRemove={true} 
                    removeWhenClick={true} 
                    delay={1} 
                    duration={5000} 
                    animationDuration={2000} 
                />
            )
        }
    }


    getEmployee = id => {
        const { employees, company } = this.state;
        const { t } = this.props;

        const employee = employees.find(employee => employee.id === id);

        if (!employee) {
            this.toastaa("error", t("errEmployeeNotFound"), `${t("errEmployeeID")}: ${id}`);
            return `${company.name}`
        }

        return `${employee.firstName} ${employee.familyName}`
    };


    getCustomerTemplateForInvoice = (template, product, customer) => {
        const { models } = this.state;
        const { t } = this.props;

        if(template && product && customer) {
            // If Cancelled use worklog invoice text instead of template
            if (product.name === "Peruutettu" || product.name === "Cancelled") return product.invoiceText ? product.invoiceText : " - ";

            console.log("getInvoice template ", template, models, customer)

            let templateObj = models.find(temp => temp?.id === template);
            const customerTemplates = models.filter(template => template.customer === customer?.id);

            if (!templateObj && customerTemplates.length === 1) {

                templateObj = customerTemplates[0];
                const task = templateObj.tasks.find(task => task.name === product.name);
                return task && task.invoiceText ? task.invoiceText : " - ";
            }

            if (!templateObj || !product || (templateObj && !templateObj.tasks)) {
                return " - "
            }
            else {
                //console.log(" templateObj ", templateObj)
                const task = templateObj.tasks.find(task => task.name === product.name);
                return task && task.invoiceText ? task.invoiceText : " - ";
            }
        } else {
            this.toastaa("error", t("errGetCustomerTemplateForInvoice"), `${template}, tuote: ${product?.name}, asiakas: ${customer?.name}`);
        }
    };

    getProduct = (pNumber, field) => {
        const { products } = this.state;
        const { t } = this.props;

        if (!pNumber) {
            this.toastaa("error", t("errProductNumberNotFound"), `${field} / ${pNumber}`);
            return " - ";
        }

        const product = products.find(product => parseInt(product.pNumber) === parseInt(pNumber));
        if (product) {
            if (field === "name") {
                return product?.name.substring(0, 20) + (product.name.length > 20 ? "..." : "");
                //return product.name;
            }
            else {
                return product?.unit
            }
        } else {
            this.toastaa("error", t("errProductNotFound"), `${t("errProductNumber")} ${pNumber}, ${field}`);
            return " - ";
        }
    };

    handleDateChange = event => {

        const dateRange = {
            selection: {
                key: 'selection',
                startDate: event.selection.startDate,
                endDate: moment(event.selection.endDate).endOf("day").toDate()
            }
        };


        this.setState({
            dateRange: dateRange,
            dateChanged: true
        });

    };

    resetSearch = () => {
        this.setState({
            selectedCustomer: "",
            resetCustomer: this.state.resetCustomer === "gotCustomer" ? "noCustomer" : "gotCustomer",
            customerHasBills: [],
            bills: {},
            dateChanged: false,
            dateRange: {
                selection: {
                    key: 'selection',
                    startDate: moment().toDate(),
                    endDate: moment().toDate()
                }
            }
        });
    };

    handleCustomerSelect = event => {
        let { customerWillBeBilled } = this.state;
        const id = event.target.value;

        if (!customerWillBeBilled.includes(id)) {
            customerWillBeBilled.push(id)
        }
        else {
            const idAdd = customerWillBeBilled.filter(item => item !== id);
            customerWillBeBilled = idAdd
        }

        this.setState({
            customerWillBeBilled: customerWillBeBilled
        });

    };

    onDateChange = event => {
        this.setState({
            billingDate: moment(event.selection.startDate).startOf("day"),
            dateDialog: false
        });
    };

    roundNumbers = number => {
        return parseFloat(number).toFixed(2);
    };


    formCheckList = () => {
        const { worklogs, selectedCustomer, customers, dateRange, dateChanged } = this.state;

        // Create obj where key is customer id and it holds array of worklogs
        const refObj = {};
        const refArr = [];

        const filteredLogs = [];

        // Filter out logs that are not billable
        worklogs && worklogs.forEach(log => {
            if (log.status !== "Cancelled" && log.billing && log.billing.billableHoursByTask) {
                if (log.billing.billableHoursByTask.some(task => !this.state.nonBillableProducts.includes(task.pNumber))) { filteredLogs.push(log) }
            }
            if (log.status === "Cancelled" && log.billingPercent > 0.1) filteredLogs.push(log);
        });

        if (selectedCustomer.length === 0) {
            filteredLogs.forEach(log => {
                if (dateChanged) {
                    if (moment(log.startDT).isBetween(dateRange.selection.startDate, dateRange.selection.endDate)) {
                        refObj[log.customer] ? refObj[log.customer].push(log) : refObj[log.customer] = [log];
                        if (!refArr.includes(log.customer)) refArr.push(log.customer)
                    }
                }
                else {
                    refObj[log.customer] ? refObj[log.customer].push(log) : refObj[log.customer] = [log];
                    if (!refArr.includes(log.customer)) refArr.push(log.customer)
                }

            });
        }
        else {

            let customer = {};

            customer = customers.find(customer => customer.name === selectedCustomer);

            filteredLogs.forEach(log => {
                if (dateChanged) {
                    if (moment(log.startDT).isBetween(dateRange.selection.startDate, dateRange.selection.endDate) && log.customer === customer.id) {
                        refObj[log.customer] ? refObj[log.customer].push(log) : refObj[log.customer] = [log];
                        if (!refArr.includes(log.customer)) {
                            refArr.push(log.customer)
                        }
                    }
                }
                else {
                    if (log.customer === customer.id) {
                        refObj[log.customer] ? refObj[log.customer].push(log) : refObj[log.customer] = [log];
                        if (!refArr.includes(log.customer)) {
                            refArr.push(log.customer)
                        }
                    }
                }
            });
        }


        this.setState({
            bills: refObj,
            customerHasBills: refArr,
            customerWillBeBilled: [...refArr]
        });

    };

    formBills = () => {
        const { company, bills, customerWillBeBilled, customers, products, models, nonBillableProducts } = this.state;
        const { user } = this.props.user;
        const billsArr = [];
        const { t } = this.props;

        console.log("formBills customerWillBeBilled ", customerWillBeBilled);
        this.setState({ "errorMsg": '' });

        console.log("customerWillBeBilled ", customerWillBeBilled);
        customerWillBeBilled && customerWillBeBilled.forEach(id => {
            console.log("customerWillBeBilled ", id);
            let customer = customers.find(customer => customer.id === id);
            if (!customer) { 
                customer = {}; // Customer is important field, if for some reason customer is not found set it to empty object
                this.toastaa("error", t("errCustomerNotFound"), `${t("errCustomerID")} ${id}`);
                
            } else {

                console.log("formBills customer ", customer)

                const invoiceBase = {
                    invoice: invoiceObject(this.state.billingDate, company, customer),
                    customer: customerObject(customer),
                    customerID: customer.id,
                };

                console.log("formBills invoiceBase ", invoiceBase)

                const worklogs = bills[id];

                try{
                    const { rows, totalPrice, price, totalVat } = rowsArray(customer, models, worklogs, products, nonBillableProducts, this.props.t);

                    invoiceBase.invoice.due = totalPrice;
                    invoiceBase.invoice.fullPrice = totalPrice;
                    invoiceBase.invoice.price = price;
                    invoiceBase.invoice.vat = totalVat;
                    invoiceBase.rows = rows;

                    console.log("formBills invoiceBase 2 ", invoiceBase)

                    rows && rows.length > 0 && billsArr.push(invoiceBase);
                } catch (error) {
                    this.setState({
                        savingBills: false,
                        errorMsg: "No bills created"
                    }, () => {
                        this.toastaa("error", t("errCreateBillProductRow"), `${error}, ${t("onCustomer")} ${customer?.name} `);
                    });
                    
                }
            }
        });

        console.log(billsArr)

        if (billsArr.length > 0) {
            this.saveBills(billsArr)
            
        }
        else {
            this.setState({
                savingBills: false,
                errorMsg: "No bills created"
            }, () => {
                this.toastaa("warning", t("No bills created"), `${t("No bills created")}`);
            });
        }

    };

    saveBills = (bills) => {
        const { customerHasBills, company, customers, worklogs, dateChanged, dateRange } = this.state;
        const { user } = this.props.user;
        const { billing } = this.state.company.settings;
        const { billingFees } = this.state.company.settings.billing;
        // Mby Check billing array if number is incremented normally
        let billNumber = billing.serialNumbers && billing.serialNumbers.salesInvoice > 100 ? billing.serialNumbers.salesInvoice + 1 : 100;
        console.log("got Bill number ", billNumber);


        this.setState({ totalNumBillsToGenerate: bills.length })

        const db = firebase.firestore();

        bills.forEach((bill, index) => {
            const customer = customers.find(customer => customer.id === bill.customerID);
            const customerBill = {};
            customerBill.billDate = moment(this.state.billDate).format("YYYYMMDDTHHmmss");
            customerBill.status = "Created";
            customerBill.billObj = bill;
            customerBill.billNumber = billNumber;
            customerBill.customerID = bill.customerID;
            customerBill.sendingMethod = {
                sending: customer.billingSettings && customer.billingSettings.sendingMethod ? customer.billingSettings.sendingMethod : billing.sending.default,
                paymentTracking: billing.paymentTracking.default,
                accounting: billing.paymentTracking.default,
            };

            // Form reference number
            customerBill.billObj.invoice.referenceNumber = refNumberCounter(billNumber);

            // Add additional fees as well
            let billingFee = billingFees.billing; //defaults to this
            console.log("saveBills : billingFees ", billingFees);
            console.log("saveBills : customerBill ", customerBill);
            console.log("saveBills : customerBill.sendingMethod:", customerBill.sendingMethod);

            if (billingFees.billingFeeDependsOnDeliveryMethod && customerBill.sendingMethod && customerBill.sendingMethod.sending) {

                //APIX invoices & depends on how delivered
                if ((customerBill.sendingMethod.sending === "apix" || customerBill.sendingMethod.sending === "email / apix") && customer.billingSettings && customer.billingSettings.deliveryMethod) {

                    if (customer.billingSettings.deliveryMethod === "emailInvoice" && billingFees.emailInvoice) {
                        billingFee = billingFees.emailInvoice;
                    } else if (customer.billingSettings.deliveryMethod === "confirmedEmailInvoice" && billingFees.confirmedEmailInvoice) {
                        billingFee = billingFees.confirmedEmailInvoice;
                    } else if (customer.billingSettings.deliveryMethod === "eInvoice" && billingFees.eInvoice) {
                        billingFee = billingFees.eInvoice;
                    } else if (customer.billingSettings.deliveryMethod === "paperLetterInvoice" && billingFees.paperLetterInvoice) {
                        billingFee = billingFees.paperLetterInvoice;
                    } else if (customer.billingSettings.deliveryMethod === "netpostiInvoice" && billingFees.netpostiInvoice) {
                        billingFee = billingFees.netpostiInvoice;
                    } else {
                        billingFee = billingFees.paperLetterInvoice;
                    }
                }
                //Traditional sending methods
                else if (customerBill.sendingMethod.sending === "email" && billingFees.emailInvoice) {
                    billingFee = billingFees.emailInvoice;
                }
                else if (customerBill.sendingMethod.sending === "print" && billingFees.paperLetterInvoice) {
                    billingFee = billingFees.paperLetterInvoice;
                }
                else { //APIX and not delivery method, or not apix
                    //defaultelse {
                    //billingFee = billingFees.paperLetterInvoice;
                    console.log("saveBills : customer has no delivery method or not handled", customer)
                }
                //TODO: other API's, eg. Talenom, Netvisor etc

            } else {
                //default
                console.log("saveBills : using default billing fee for customer " + customer)
            }

            console.log("saveBills : got billingFee " + billingFee);
            customerBill.billObj.billingFees = {
                freight: company.settings.billing.billingFees && company.settings.billing.billingFees.freight ? company.settings.billing.billingFees.freight : 0,
                billing: billingFee,
            };

            // Filter worklogs again here -> duplicate code but we avoid forming empty invoices when initial forming is done
            let customerLogs = worklogs.filter(log => log.customer === customerBill.customerID);
            if (dateChanged) {
                const start = moment(dateRange.selection.startDate);
                const end = moment(dateRange.selection.endDate);
                customerLogs = customerLogs.filter(log => moment(log.startDT).isBetween(start, end));
            }

            customerBill.worklogs = customerLogs.map(log => log.id);

            billNumber++;

            // Save to FB

            const batch = db.batch();
            const billRef = db.collection("company").doc(user.companyID).collection("bills").doc();

            batch.set(billRef, customerBill);

            customerLogs && customerLogs.forEach(log => {
                const previousStatus = log.status;
                const logRef = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").doc(log.id);
                batch.set(logRef, { status: "Billed", previousStatus: previousStatus }, { merge: true });
            });


            batch.commit().then(() => {
                const remainingBills = customerHasBills.filter(id => id !== customerBill.customerID);
                this.setState({
                    customerHasBills: remainingBills
                });

                if (index === bills.length - 1) {
                    this.setState({
                        savingBills: false
                    });
                }
            });


        });


        const incrementValue = company.settings.billing.serialNumbers && company.settings.billing.serialNumbers.salesInvoice < 100 ? billNumber : bills.length;
        // Does company.settings.billing.serialNumber.salesInvoice need to be updated?
        console.log("set salesInvoiceIncrementVAlue - increment by: ", incrementValue, bills.length);

        firebase.firestore().collection("company").doc(user.companyID).set({
            settings: {
                billing: {
                    serialNumbers: {
                        salesInvoice: firebase.firestore.FieldValue.increment(incrementValue)
                    }
                }
            }
        }, { merge: true });

    };

    renderSavingDialog = () => {
        const { totalNumBillsToGenerate, customerHasBills } = this.state;

        return (
            <Dialog
                open={this.state.savingDialog}
                onClose={() => this.setState({ savingDialog: false })}
            >
                <DialogContent style={{ textAlign: 'center' }}>
                    {this.state.savingBills &&
                        <div>
                            <div>{this.props.t("BillsGenerating")}:</div>
                            <CircularProgress style={{ color: "secondary" }} />
                            {totalNumBillsToGenerate &&
                                <div>{totalNumBillsToGenerate - customerHasBills.length}/{totalNumBillsToGenerate}</div>
                            }
                        </div>
                    }
                    {!this.state.savingBills &&
                        <div>
                            {this.state.errorMsg &&
                                <h4>{this.props.t("BillsSavedFailure")}</h4>
                            }
                            {this.state.errorMsg &&
                                <p>{this.state.errorMsg}</p>
                            }
                            {!this.state.errorMsg &&
                                <h4>{this.props.t("BillsSavedSuccess")}</h4>
                            }
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                    width: '100%',
                                    marginTop: 10
                                }}
                                onClick={() => this.setState({ savingDialog: false }, () => this.formCheckList())}  //create bills and re-run list forming for those that were unselected
                            >
                                {this.props.t("Close")}
                            </Button>
                        </div>
                    }
                </DialogContent>
            </Dialog>
        );
    };

    

    generate = (item) => {
        const { t } = this.props;
        const { company } = this.state;

        const customer = this.state.customers.find(customer => customer.id === item);

        if (!customer) {
            console.log("noCustomer", item)
            this.toastaa("error", t("errCustomerNotFound"), `${t("errCustomerID")} ${item}`);
        }

        const template = customer ? this.state.models.find(template => template.id === customer.templateID) : { tasks: [] };

        const byTask = {};
        const ref = {};
        const worklogs = this.state.bills[item];
        const byEmployee = [];
        const materialRef = {};

        worklogs && worklogs.forEach(log => {

            if (log.billing.billableHoursByTask && log.status !== "Cancelled") {
                log.billing.billableHoursByTask.forEach(task1 => {
                    if (log.employee && log.employee.length > 0) {
                        log.employee.forEach(employee => {
                            const task = JSON.parse(JSON.stringify(task1));

                            if (!ref[employee]) {
                                ref[employee] = {};
                                byEmployee.push(employee);
                                byTask[employee] = [];
                            }

                            if (!ref[employee][task.name] && task.duration > 0 && !this.state.nonBillableProducts.includes(task.pNumber)) {
                                ref[employee][task.name] = {
                                    name: task.name,
                                    duration: 0,
                                    pNumber: task.pNumber,
                                    employee: employee,
                                    templateID: log.templateID
                                };
                                byTask[employee].push(task.name);
                            }

                            if (ref[employee][task.name]) ref[employee][task.name].duration += task.duration;

                        });
                    } else {
                      // treat company as employee - saadaan laskutettua vaikkei olisi tekijää
                        const employee = company.name;
                        const task = JSON.parse(JSON.stringify(task1));
                        if (!ref[employee]) {
                            ref[employee] = {};
                            byEmployee.push(employee);
                            byTask[employee] = [];
                        }
                        if (!ref[employee][task.name] && task.duration > 0 && !this.state.nonBillableProducts.includes(task.pNumber)) {
                            ref[employee][task.name] = {
                                name: task.name,
                                duration: 0,
                                pNumber: task.pNumber,
                                employee: employee,
                                templateID: log.templateID
                            };
                            byTask[employee].push(task.name);
                        }

                        if (ref[employee][task.name]) ref[employee][task.name].duration += task.duration;
                    }
                });
            }

            else if (log.billing && log.status !== "Cancelled") {
                log.employee.forEach(employee => {
                    if (!ref[employee]) {
                        ref[employee] = {};
                        byEmployee.push(employee);
                        byTask[employee] = [];
                    }

                    if (!ref[employee].noTask) {
                        ref[employee].noTask = {
                            name: t("No task"),
                            duration: 0,
                            employee: employee,
                            templateID: log.templateID
                        };
                        byTask[employee].push("noTask");
                    }

                    ref[employee].noTask.duration += log.billing.billableHours;
                });
            }

            //console.log("eval log: ", log)
            if (log.status === "Cancelled" && log.billingPercent > 0 && log.billing && log.billing.billableHoursByTask) {
                // normally there are employees, but if not, use whole time for one "company-employee"
                if (log.employee && log.employee.length > 0) {
                    log.employee.forEach(employee => {
                        if (!ref[employee]) {
                            ref[employee] = {};
                            byEmployee.push(employee);
                            byTask[employee] = [];
                        }

                        if (!ref[employee].cancelled) {

                            ref[employee].cancelled = {
                                name: t("Cancelled"),
                                pNumber: log.billing && log.billing?.billableHoursByTask?.length > 0 ? log.billing.billableHoursByTask[0].pNumber : (template ? template.tasks[0].pNumber : ""),  //TODO: RETHINK: hätäkorjaus, otetaan tuote (josta hinta) ekasta tenmplaten taskista
                                duration: 0,
                                employee: employee,
                                templateID: log.templateID,
                                invoiceText: log.invoiceText ? log.invoiceText : t("Billable cancellation"),
                            };
                            byTask[employee].push("cancelled")
                        }

                        ref[employee].cancelled.duration += log.billing.billableHours * log.billingPercent;
                    })
                } else {
                    // treat company as employee - saadaan laskutettua jos on peruttu vaikkei olisi tekijää
                    const employee = company.name;
                    if (!ref[employee]) {
                        ref[employee] = {};
                        byEmployee.push(employee);
                        byTask[employee] = [];
                    }
                    if (!ref[employee].cancelled) {

                        ref[employee].cancelled = {
                            name: t("Cancelled"),
                            pNumber: log.billing && log.billing.billableHoursByTask ? log.billing.billableHoursByTask[0].pNumber : "",
                            duration: 0,
                            employee: employee,
                            templateID: log.templateID,
                            invoiceText: log.invoiceText ? log.invoiceText : t("Billable cancellation"),
                        };
                        byTask[employee].push("cancelled")
                    }

                    console.log("eval ref[employee]: ", ref[employee].cancelled)

                    if (!ref[employee].cancelled.invoiceText) ref[employee].cancelled.invoiceText = t("Billable cancellation"); // if missing test, use default

                    ref[employee].cancelled.duration += log.billing.billableHours * log.billingPercent;
                }
            }


            log.materials && log.materials.forEach(material => {
                if (!materialRef[material.name]) {
                    materialRef[material.name] = {
                        name: material.name,
                        pNumber: material.pNumber,
                        amount: 0,
                        templateID: log.templateID
                    };
                    materialRef.materialArray ? materialRef.materialArray.push(material.name) : materialRef.materialArray = [material.name]
                }

                materialRef[material.name].amount += material.amount;
            });
        });

        if (byEmployee && byEmployee.length > 0) byEmployee.forEach(employee => {
            let customerHasBillableHours = false;
            byTask[employee].forEach(task => {
                if (!this.state.nonBillableProducts.includes(task.pNumber)) customerHasBillableHours = true;
            });
        });


        return (
            <div key={item} style={{ marginBottom: 40, padding: 15 }}>
                <div
                    style={{ height: 45, width: '100%', backgroundColor: '#2B81C1', textAlign: 'center', paddingTop: 1 }}>

                    <div style={{ display: 'flex' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', width: '90%' }}>
                            <p style={{ color: '#FFF' }}>{customer && customer.name}</p>
                        </div>
                        <div style={{ flexGrow: 1 }}>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Checkbox
                                    style={{
                                        color: '#FFF'
                                    }}
                                    value={item}
                                    checked={this.state.customerWillBeBilled.includes(item)}
                                    onChange={event => this.handleCustomerSelect(event)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Table style={{ width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-cell-employee">{t("Employee")}</TableCell>
                            <TableCell className="table-cell-task">{t("Task")}</TableCell>
                            <TableCell className="table-cell-product">{t("Product")}</TableCell>
                            <TableCell className="table-cell-invoice">{t("Invoice text")}</TableCell>
                            <TableCell className="table-cell-unit">{t("Unit")}</TableCell>
                            <TableCell className="table-cell-bill">{t("Bill")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {byEmployee && byEmployee.length > 0 && byEmployee.map(employee => (
                            byTask[employee].map(task => {
                                console.log("ref[employee][task]", ref[employee][task], employee, task);
                                return (
                                    <TableRow key={employee + task}>
                                        <TableCell
                                            className="table-cell-employee">{this.getEmployee(ref[employee][task].employee)}</TableCell>
                                        <TableCell className="table-cell-task">{ref[employee][task].name}</TableCell>
                                        <TableCell
                                            className="table-cell-product">{this.getProduct(ref[employee][task].pNumber, 'name')}</TableCell>
                                        <TableCell
                                            className="table-cell-invoice">{this.getCustomerTemplateForInvoice(ref[employee][task].templateID, ref[employee][task], customer)}</TableCell>
                                        <TableCell
                                            className="table-cell-unit">{this.getProduct(ref[employee][task].pNumber, 'unit')}</TableCell>
                                        <TableCell
                                            className="table-cell-bill">{this.roundNumbers(ref[employee][task].duration / 3600000)}</TableCell>
                                    </TableRow>
                            )})
                        ))}
                        {materialRef && materialRef.materialArray && materialRef.materialArray.map((material, index) => (
                            <TableRow key={customer.id + index}>
                                <TableCell className="table-cell-employee">-</TableCell>
                                <TableCell className="table-cell-task">{t("Added_material")}</TableCell>
                                <TableCell className="table-cell-product">{materialRef[material].name}</TableCell>
                                <TableCell
                                    className="table-cell-invoice">{this.getCustomerTemplateForInvoice(materialRef[material].templateID, materialRef[material], customer)}</TableCell>
                                <TableCell
                                    className="table-cell-unit">{this.getProduct(materialRef[material].pNumber, 'unit')}</TableCell>
                                <TableCell className="table-cell-bill">{materialRef[material].amount}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        );
    };

    render() {
        const { customerHasBills } = this.state;
        const { t, i18n } = this.props;
        return (
            <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: 350, marginTop: 20, marginLeft: 20 }}>
                    <Paper style={{ width: '100%', height: 650, padding: 10 }}>
                        <AutocompleteReports
                            searchArray={this.state.customers}
                            label={t("Search customers")}
                            onSelect={event => this.setState({ selectedCustomer: event })}
                            key={this.state.resetCustomer}
                        />
                        <div style={{ marginTop: 20, marginLeft: 30 }}>
                            <DateRange
                                ranges={[this.state.dateRange.selection]}
                                moveRangeOnFirstSelection={false}
                                onChange={event => this.handleDateChange(event)}
                                style={{
                                    width: '100%'
                                }}
                                locale={i18n.language === "fi" ? fi : enGB}
                            />
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                            <Button
                                variant="outlined"
                                style={{
                                    marginTop: 20
                                }}
                                onClick={() => this.resetSearch()}
                            >
                                {t("Reset")}
                            </Button>

                            <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                    marginTop: 20
                                }}
                                onClick={() => this.formCheckList()}
                            >
                                {t("Search")}
                            </Button>
                        </div>


                        <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 20 }}>
                            <p style={{ marginLeft: 10 }}>{t("Bill_date")}</p>
                            <Button
                                onClick={() => this.setState({ dateDialog: true })}
                            >
                                {moment(this.state.billingDate).locale("fi").format('L')}
                            </Button>
                        </div>
                        <br />

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                variant="outlined"
                                color="primary"
                                style={{
                                    width: 240,
                                }}
                                onClick={() => this.setState({
                                    savingBills: true,
                                    savingDialog: true
                                }, () => this.formBills())}
                                disabled={customerHasBills.length === 0}
                            >
                                {t("Create bills")}
                            </Button>
                        </div>

                    </Paper>
                </div>

                <div style={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', margin: 20 }}>
                        <Paper style={{ width: '100%', height: '90vh', overflow: 'auto' }}>
                            {customerHasBills && customerHasBills.map(this.generate)}
                            {customerHasBills.length === 0 && <div style={{ marginTop: 20, marginLeft: 20 }}>
                                <p>{t("Billable hours not found")}</p></div>}

                        </Paper>
                    </div>
                </div>

                {this.state.dateDialog &&
                    <DateDialog
                        onClose={() => this.setState({ dateDialog: false })}
                        value={moment(this.state.billingDate).toDate()}
                        selectType="date"
                        onChange={this.onDateChange}
                    />
                }

                {this.state.savingDialog &&
                    this.renderSavingDialog()
                }

                <ToastWrapper ref={this.wrapper} />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(BillingLanding));
