import React, {Component} from 'react';
import QRCode from 'qrcode.react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button
} from '@material-ui/core';

import {withTranslation} from "react-i18next";
import Pdfqr from './PDFQR';

class ProductQrDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            msg: "",
        };
    }

    onTextChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };


    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                maxWidth="md"
            >
                <DialogTitle style={{textAlign: 'center'}}>{this.props.t("Print QR code")}</DialogTitle>
                <DialogContent>
                    <div style={{display: 'flex', flexDirection: 'row', paddingTop: 10}}>
                        <QRCode
                            value={this.props.code}
                        />

                        <div style={{marginLeft: 10}}>
                            <TextField
                                variant="outlined"
                                name="title"
                                label={this.props.t("Title_opt")}
                                value={this.state.title}
                                onChange={event => this.onTextChange(event)}
                                style={{
                                    width: 300
                                }}
                            />

                            <br/>


                            <TextField
                                variant="outlined"
                                name="msg"
                                label={this.props.t("Msg_opt")}
                                value={this.state.msg}
                                onChange={event => this.onTextChange(event)}
                                style={{
                                    width: 300,
                                    marginTop: 15
                                }}
                                multiline
                                rowsMax={5}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        style={{
                            marginRight: 15
                        }}
                        onClick={this.props.onClose}
                    >
                        {this.props.t("Close")}
                    </Button>

                    <Pdfqr
                        title={this.state.title}
                        msg={this.state.msg}
                        code={this.props.code}
                    />
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(ProductQrDialog);