import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';

import {
    Button,
    Checkbox,
    IconButton
} from "@material-ui/core";

import {Close} from "@material-ui/icons";

import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';

import "./Tutorial.css";
class Tutorial extends Component{
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            dontShowAgain: true,
            tutorialContent: [
                {
                    title: "modelTitle1",
                    msg: "modelMsg1"
                },
                {
                    title: "modelTitle2",
                    msg: "modelMsg2"
                },
                {
                    title: "modelTitle3",
                    msg: "modelMsg3"
                },
                {
                    title: "modelTitle4",
                    msg: "modelMsg4"
                },
                {
                    title: "modelTitle5",
                    msg: "modelMsg5"
                },
                {
                    title: "modelTitle6"
                }
            ]
        };
    }


    stepHandler = (value, method) => {
        let {step} = this.state;

        method === "increase"
            ? (value === 6 ? step = 6 : step += 1)
            : (value === 1 ? step = 1 : step -= 1);

        this.setState({
            step
        });
    };

    closeTutorial = () => {
        const {user, userID} = this.props.user;

        if (this.state.dontShowAgain) {
            if (user.tutorials && !user.tutorials.jobModels) {
                user.tutorials.jobModels = true;
                console.log("!jobModels")
                //firebase.firestore().collection('employees').doc(userID).set(user);
            }
            else if (!user.tutorials){
                user.tutorials = {
                    jobModels: true
                };
                console.log("Else")
                firebase.firestore().collection('employees').doc(userID).set(user);
            }
        }

        this.props.onClose();
    };



    render() {
        const {step} = this.state;
        const {tutorialFields} = this.props;
        return (
            <div>
                {step === 1 &&
                    <div style={{position: 'absolute', left: tutorialFields.name.x, top: tutorialFields.name.y, width: tutorialFields.name.width, height: tutorialFields.name.height}} className="window">

                    </div>
                }

                {step === 2 &&
                    <div style={{position: 'absolute', left: tutorialFields.pNumber.x, top: tutorialFields.pNumber.y, width: tutorialFields.pNumber.width, height: tutorialFields.pNumber.height}} className="window">

                    </div>
                }

                {step === 3 &&
                    <div style={{position: 'absolute', left: tutorialFields.invoice.x, top: tutorialFields.invoice.y, width: tutorialFields.invoice.width, height: tutorialFields.invoice.height}} className="window">

                    </div>
                }

                {step === 4 &&
                    <div style={{position: 'absolute', left: tutorialFields.description.x, top: tutorialFields.description.y, width: tutorialFields.description.width, height: tutorialFields.description.height}} className="window">

                    </div>
                }

                {step === 5 &&
                    <div style={{position: 'absolute', left: tutorialFields.unit.x, top: tutorialFields.unit.y, width: tutorialFields.unit.width, height: tutorialFields.unit.height}} className="window">

                    </div>
                }

                {step === 6 &&
                    <div style={{position: 'absolute', left: tutorialFields.dialog.x, top: tutorialFields.dialog.y, width: tutorialFields.dialog.width, height: tutorialFields.dialog.height}} className="window">

                    </div>
                }



                <div className="tutorial-container">
                   <div style={{display: 'flex', justifyContent: 'space-between', marginLeft: '46%'}}>
                       <h2>{this.props.t(this.state.tutorialContent[step - 1].title)}</h2>
                        <IconButton style={{width: 50, height: 50}} onClick={() => this.props.onClose()}>
                            <Close style={{color: '#FFF'}}/>
                        </IconButton>
                   </div>

                    <div>
                        {step > 0 && step <= 5 &&
                        <div style={{width: '100%', textAlign: 'center'}}>
                            {this.props.t(this.state.tutorialContent[step - 1].msg)}
                        </div>
                        }
                        {step === 6 &&
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <Checkbox style={{color: '#FFF'}} checked={true}/>
                                <p style={{color: '#FFF'}}>Don't show again</p>
                            </div>
                        }
                        <br/>

                        <Button
                            variant="outlined"
                            style={{
                                backgroundColor: '#FFF',
                                marginRight: 20
                            }}
                            onClick={() => this.stepHandler(step, "decrease")}
                        >
                            Back
                        </Button>

                        {step < 6 &&
                            <Button
                                variant="outlined"
                                style={{
                                    backgroundColor: '#FFF',
                                }}
                                onClick={() => this.stepHandler(step, "increase")}
                            >
                                Next
                            </Button>
                        }
                        {step === 6 &&
                        <Button
                            variant="outlined"
                            style={{
                                backgroundColor: '#FFF',
                            }}
                            onClick={() => this.closeTutorial()}
                        >
                            Close
                        </Button>
                        }
                    </div>


                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(Tutorial));