import React from 'react';
import {
    TextField, Button,
} from '@material-ui/core';

const CreateRegistry = props => {

    const {errors, sampleJobModels, sampleProducts, useGeneratedSampleRegistry, t} = props;

    console.log("props sampleProducts ", sampleProducts);
    console.log("props sampleJobModels ", sampleJobModels);

    return (
        <div>
            <h4>{t("Register create example products")}</h4>

            <br/>

            <h5>{t("Pruducts")}</h5>
            <ul>
                {sampleProducts.map((value, index) => {
                    return <li key={index}>{value.name}</li>
                })}
            </ul>

            <br/>
            {sampleProducts && sampleProducts.length >= 9 &&
            <p>{t("Register max example products")}</p>
            }

            <Button
                variant="outlined"
                color="primary"
                disabled={sampleProducts.length > 9}
                onClick={() => props.onAddProduct()}
            >
                {t("Register add example product")}
            </Button>

            <br/>

            <h5>Työmallit:</h5>
            {sampleJobModels && sampleJobModels.length > 0 &&
            sampleJobModels.forEach(model => {
                return <h6>{model.name}</h6>
            })
            }

            <ul>
                {sampleJobModels.map((value, index) => {
                    return <li key={index}>{value.name}</li>
                })}
            </ul>

            {useGeneratedSampleRegistry && sampleJobModels && sampleJobModels.length >= 2 &&
            <p>{t("Register max example jobModels")}</p>
            }

            {sampleProducts && sampleProducts.length > 0 &&
            <Button
                variant="outlined"
                color="primary"
                disabled={sampleJobModels.length > 2}
                onClick={() => props.onAddModel()}
            >
                {t("Register add example jobModel")}
            </Button>
            }

            <br/> <br/>

            {useGeneratedSampleRegistry &&
            <Button
                variant="outlined"
                color="primary"
                onClick={() => props.onRemoveSampleData()}
            >
                {t("Register remove example data")}
            </Button>
            }

        </div>
    );
};

export default CreateRegistry;