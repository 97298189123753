import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as firebase from 'firebase';

import moment from 'moment';

import FullCalendar from 'sardius-fullcalendar-wrapper';
import 'sardius-fullcalendar-wrapper/dist/fullcalendar.min.css';

import EditOptionDialog from './EditOptionDialog';
import EditLogDialog from '../WorkLogComponents/EditLogDialog';

import {getOccurrences} from "../CalendarComponents/OccurrencesComponent";

class JobCalList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateControl: moment().toDate(),
            loading: true,
            viewRange: null,
            events: [],
            clickDialog: false,
            selectedJob: {},
            jobWorklogs: [],
            //worklogs: [],
            //employees: [],
            switchValue: 0,
            selectedWorklog: {},
            editMode: false
        };
    }


    componentDidMount() {
        //const {user} = this.props.user;

        /*this.unsubscribeWorklogListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').onSnapshot(this.onWorklogSnapshot)
        */
        /*
        this.unsubscribeEmployeeListener =
            firebase.firestore().collection('employees')
                .where("companyID", "==", user.companyID).onSnapshot(this.onEmployeeSnapshot)
                */
    }

    /*onWorklogSnapshot = querySnapshot => {
        const worklogs = [];

        querySnapshot.forEach(doc => {
            const worklog = doc.data();
            worklog.id = doc.id;

            if (worklog.customer === this.props.customer) {
                worklogs.push(worklog);
            }
        });

        this.setState({
            worklogs: worklogs
        });

    };
    */

    /*
    onEmployeeSnapshot = querySnapshot => {
        const employees = [];

        querySnapshot.forEach(doc => {
            const employee = doc.data();
            employee.id = doc.id;
            employee.name = `${employee.firstName} ${employee.familyName}`;
            employees.push(employee)
        });

        this.setState({
            employees: employees
        });
    };
    */

    componentWillUnmount() {
        //this.unsubscribeWorklogListener && this.unsubscribeWorklogListener();
    }


    eventHandler = (event, override) => {
        const {viewRange} = this.state;
        const {jobs, worklogs} = this.props;


        const startMoment = moment(event.view.activeStart);
        const endMoment = moment(event.view.activeEnd);

        let jobOccurrences = [];

        if (viewRange === null) {
            this.setState({
                viewRange: startMoment
            });
        }

        if (!moment(viewRange).isSame(startMoment) || override) {

            jobs && jobs.forEach(job => {
                const occArr = getOccurrences(job, startMoment, endMoment);

                jobOccurrences = jobOccurrences.concat(occArr);
            });


            this.setState({
                events: jobOccurrences,
                viewRange: startMoment
            });
        }
    };

    clickHandler = event => {

        const id = event.event.extendedProps.jobID;
        const {worklogs} = this.props;

        const job = this.props.jobs.find(job => job.id === id);

        const selectedJob = JSON.parse(JSON.stringify(job));

        const jobWorklogs = [];

        selectedJob.startDT = event.event.start;
        selectedJob.end = event.event.end;

        const formattedStart = moment(event.event.start).format('YYYYMMDDTHHmmss');

        if (job && worklogs) {
            worklogs.forEach(log => {
                if (log.jobID === id && formattedStart === log.jobStartDT) {
                    jobWorklogs.push(log)
                }
            });
        }

        this.setState({
            clickDialog: true,
            selectedJob: selectedJob,
            jobWorklogs: jobWorklogs
        });
    };

    onCalendarNavigationClick = (field) => {
        let {dateControl} = this.state;

        if (field === "today") {
            dateControl = moment().toDate()
        }

        if (field === "next") {
            dateControl = moment(dateControl).add(1, "week").toDate();
        }

        if (field === "prev") {
            dateControl = moment(dateControl).subtract(1, "week").toDate()
        }

        this.setState({
            dateControl: dateControl
        });
    };


    render() {
        const {jobs, worklogs} = this.props;

        //console.log("JobCalList props ", this.props);
        //console.log("JobCalList props jobs ", jobs);
        //console.log("JobCalList props worklogs ", worklogs);

        return (
            <div>
                {jobs && jobs.length > 0 &&
                <FullCalendar
                    height="parent"
                    events={this.state.events}
                    defaultView="listWeek"
                    datesRender={event => this.eventHandler(event, false)}
                    timeZone={"local"}
                    firstDay={1}
                    weekLabel="W"
                    defaultDate={this.state.dateControl}
                    header={{
                        left: 'myPrev, myNext, myToday',
                        right: ''
                    }}
                    customButtons={{
                        myNext: {
                            text: '>',
                            click: () => this.onCalendarNavigationClick('next')
                        },
                        myPrev: {
                            text: '<',
                            click: () => this.onCalendarNavigationClick('prev')
                        },
                        myToday: {
                            text: 'Today',
                            click: () => this.onCalendarNavigationClick('today')
                        }
                    }}
                    eventTimeFormat={{
                        hour: '2-digit',
                        minute: '2-digit',
                        merifiem: false,
                        hour12: false
                    }}
                    eventClick={event => this.clickHandler(event)}
                    eventRender= {function (info) {
                    {
                        if (info.event.extendedProps.status === 'done') {
                            // Change background color of row
                            //info.el.style.backgroundColor = 'red';

                            // Change color of dot marker
                            var dotEl = info.el.getElementsByClassName('fc-event-dot')[0];
                            if (dotEl) {
                                dotEl.style.backgroundColor = 'green';
                            }
                        }
                    }
                }}
                />
                }

                {this.state.clickDialog &&
                <EditOptionDialog
                    open={this.state.clickDialog}
                    onClose={() => this.setState({clickDialog: false})}
                    selectedJob={this.state.selectedJob}
                    jobWorklogs={this.state.jobWorklogs}
                    employees={this.state.employees}
                    onSelect={(event) => this.setState({selectedWorklog: event, editMode: true})}
                />
                }

                {this.state.editMode &&
                <EditLogDialog
                    onClose={() => this.setState({editMode: false})}
                    employees={this.state.employees}
                    selectedLog={this.state.selectedWorklog}
                    editMode={true}
                />
                }

            </div>

        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(JobCalList);
