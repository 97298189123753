import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";

import {filterWithBillableTime, formFromWorklogs, checkInvoiceRows} from "./PDFAttachmentFunctions";

import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";

export const createBillAttachment = async (t, worklogs, company, customer, employees, products, models, user, invoiceRows, callBack) => {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    console.log("CREATING PDF ATTACHMENT")
    const sortedWorklogs = worklogs.sort((a, b) => {
        if (moment(a.startDT).isBefore(b.startDT)) return -1;
        if (moment(a.startDT).isAfter(b.startDT)) return 1;
    });

    const roundNumber = d => {
        return parseFloat(d).toFixed(2)
    };

    const getEmployee = id => {
        const employee = employees.find(employee => employee.id === id);
        //format to firstname + first letter of last nema
        const [firstName, lastName] = employee.name.split(' ');
        const firstLetterOfLastName = lastName.charAt(0);

        const formattedName = `${firstName} ${firstLetterOfLastName}.`;
        return formattedName;
    };

    let rows = [
        [
            {text: t("Date"), alignment: 'center'},
            {text: t("User"), alignment: 'center'},
            {text: t("Amount"), alignment: 'center'},
            {text: t("Description"), alignment: 'center'},
        ]
    ];

    const filteredList = await filterWithBillableTime(sortedWorklogs, products);
    const rowsFromWorklogs = await formFromWorklogs(filteredList, models, products, getEmployee, roundNumber, t);
    const rowsFromInvoice = await checkInvoiceRows(invoiceRows, roundNumber);

    rows = [...rows, ...rowsFromWorklogs, ...rowsFromInvoice];

    const docRef = {
        content: [
            {
                margin: [0, 0, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['*', '*'],
                    body: [
                        [
                            {text: company.name, alignment: 'left', style: "subheader"},
                            {text: customer.contact.name, alignment: 'right', style: "subheader"},

                        ],
                        [
                            {text: company.streetAddress, alignment: 'left'},
                            {text: customer.address.address, alignment: 'right'},
                        ],
                        [
                            {text: company.city + ' ' + company.postCode, alignment: 'left'},
                            {text: customer.address.city + ' ' + customer.address.postalCode, alignment: 'right'},
                        ]
                    ]
                },
                layout: "noBorders"
            },
            {
                margin: [20, 50, 20, 10],
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*', '*'],
                    body: rows
                },
                layout: "headerLineOnly"
            }
        ],
        pageMargins: [20, 10, 20, 10],
        styles: {
            subheader: {
                fontSize: 15,
                bold: true
            }
        }
    };


    //let pdfUrl = "";
    //pdfMake.createPdf(docRef).getBase64(dataUrl => pdfUrl = dataUrl);

    return new Promise(resolve => {
        pdfMake.createPdf(docRef).getBase64(dataUrl => {
            resolve(dataUrl);
        });
    })
};

