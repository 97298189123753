import React, {Component} from 'react';
import { withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps";
import mapIcon from '../img/mapIcon.png';
import {
    CircularProgress,
} from "@material-ui/core";
const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");

class MapComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMarkerShown: false,
            defaultCenter: { lat: 61.2102006, lng: 26.0370042 },
            loading: true,
        }
    }

    componentDidMount() {
        let {defaultCenter} = this.state;

        //console.log('MAP props: ', this.props);

        if (this.props.type === "CustomerWorksites") {
            //console.log('this.props.worksites: ', this.props.worksites);

            this.props.worksites && this.props.worksites.map(site => {
                console.log('CustomerWorksite: ', site);
                defaultCenter = {lat: site.lat, lng: site.lng}
            })
            this.setState({defaultCenter, loading: false});
        } else if (this.props.type === "WorklogLocations") {
            if (this.props.employees[0]) defaultCenter = {lat: this.props.employees[0].lat, lng: this.props.employees[0].lng}
            this.setState({defaultCenter, loading: false});
        }
        else {
            this.setState({defaultCenter, loading: false});
        }

        this.markerTimeOut();
    }

    markerTimeOut = () => {
        setTimeout(() => {
            this.setState({isMarkerShow: true})
        }, 1000)
    }

    showMarkers() {
        if (this.props.setLat || this.props.setLng) {
            return (
                 this.state.isMarkerShow && <Marker position={{ lat: this.props.setLat, lng: this.props.setLng }}/>
            );
        }
        if (this.props.customers) {
            return (
                this.state.isMarkerShow && this.props.customers.map(location => (
                    <Marker key={location.id} position={{lat: location.lat, lng: location.lng}}/>
                ))
            );
        }
        if (this.props.employees) {
            console.log('employees location : ', this.props.employees);
            return (
                this.state.isMarkerShow && this.props.employees.map(location => (location && location.lat !=0 && location.lng != 0 ) ? (
                    <Marker label={location.id}
                            key={location.id}
                            position={{lat: location.lat, lng: location.lng}}
                            icon={{url: mapIcon }}
                    />
                ): null));
        }

        // For showing all worksites on the map
        if (this.props.type === "CustomerWorksites") {
            return (
                this.state.isMarkerShow && this.props.worksites && this.props.worksites.map(site => (
                    <Marker key={site.id} position={{lat: site.lat, lng: site.lng}} />
                ))
            );
        }
    }

    render(){
        const {defaultCenter, loading} = this.state;

        console.log("render map ", defaultCenter, loading)

        return (

            <div>
                {loading &&
                <div style={{
                    alignSelf: 'center',
                    width: '50vw',
                    height: 'auto',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '3%'
                }}>
                    <CircularProgress/>
                </div>
                }

                {!loading && this.props.type !== "CustomerWorksites" &&
                <GoogleMap
                    defaultZoom={15}
                    defaultCenter={defaultCenter}
                >
                    {/*<Circle  defaultCenter={defaultCenter} defaultRadius={75}/>*/}
                    {this.showMarkers()}

                </GoogleMap>}

                {!loading && this.props.type === "CustomerWorksites" &&
                <GoogleMap
                    defaultZoom={13}
                    defaultCenter={defaultCenter}

                >
                    {this.showMarkers()}
                </GoogleMap>
                }
            </div>

        );
    }

}

export default withGoogleMap(MapComponent);