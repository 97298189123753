import React, {Component} from 'react';

import "moment/locale/fi";

import "./NotReportingListComponent.css";

import {withTranslation} from 'react-i18next';
import {Link, withRouter} from "react-router-dom";
import {dateTime, jobEmployeesToString} from "../util/StringHelpers";
import {setCustomers} from "../actions/customerActions";
import {setEmployees} from "../actions/employeeActions";
import {connect} from "react-redux";
import moment from "moment-timezone";
import "moment/locale/fi";
import "moment/locale/en-gb";

class NotReportedListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addDialog: false,
            selectedLog: {},
            selectedJob: {}
        };
    }

    getBillableHours = (hours) => {
        //console.log("hours", hours / 3600000)

        let time = hours / 3600000;

        const rounded = Math.round(time * 4) / 4;


        if (time >= 3 && time < 3.1) {
            time = 3
        }

        return rounded;
    };

    render() {
        const {notReported, t} = this.props;
        const {user} = this.props.user;
        const {employees} = this.props.employees;

        return (
            <div className="wl-list-body">
                <div className="wl-list-header">
                    <div className="wl-header-right">
                        <div className="wl-list-item large">{t("Customer")}</div>
                        <div className="wl-list-item large">{t("Worksite")}</div>
                        <div className="wl-list-item large">{t("Assigned_emp")}</div>
                        <div className="wl-list-item large">{t("Date")}</div>
                        <div className="wl-list-item large">{t("Scheduled")}</div>
                    </div>
                </div>
                <div className="wl-list-body">
                    {notReported && notReported.map(job => (
                        <div className="wl-list-row" key={job.id+moment(job.startDT).format("YYYYMMDDTHHmmss")}>
                            <Link to={`/management/worklogs/add/${job.id},${ moment(job.startDT).format("YYYYMMDDTHHmmss")},${ moment(job.endDT).format("YYYYMMDDTHHmmss")}`}
                                  className="wl-link">
                                <div className="wl-right">
                                    <div className="wl-list-item large">{this.props.getCustomer(job.customer)}</div>
                                    <div className="wl-list-item large">{this.props.getWorksite(job.worksite)}</div>
                                    <div
                                        className="wl-list-item large">{jobEmployeesToString(job.employees, employees)}</div>
                                    <div className="wl-list-item large">{dateTime(job.startDT, "D", user)}</div>
                                    <div
                                        className="wl-list-item large">{`${dateTime(job.startDT, "T", user)} - ${dateTime(job.endDT, "T", user)}`}</div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    customers: state.customers,
    employees: state.employees
});

export default withRouter(connect(mapStateToProps, {
    setCustomers,
    setEmployees
})(withTranslation()(NotReportedListComponent)));


//export default withTranslation()(NotReportedListComponent);