
import moment from 'moment';

export const searchPeriod = (worklogs, employees, selectedEmployee, selectedPeriod) => {

    let employee = "";
    let selectedLogs = [];

    selectedEmployee.length > 0 ? employee = employees.find(employee => employee.name === selectedEmployee) : employee = "";

    // Period select will be required, so we can split it
    const periodSplitter = selectedPeriod.split(" ");

    let startWeek = periodSplitter[1].substr(0,2);
    let startYear = periodSplitter[1].substr(3,4);
    let endWeek = periodSplitter[3].substr(0,2);
    let endYear = periodSplitter[3].substr(3,4);

    // Use moment to get start and end dates of corresponding weeks
    const start = moment().year(startYear).week(startWeek).startOf('week');
    const end = moment().year(endYear).week(endWeek).endOf('week');

    console.log(" search with start and end moments ", moment(start).format("YYYYMMDD"), moment(end).format("YYYYMMDD"))

    // If employee is selected match worklogs to ID and dates
    if (employee && employee.name) {
        worklogs.forEach(log => {
            if (log.employee.includes(employee.id) && moment(log.startDT).isBetween(start, end)) {
                selectedLogs.push(log)
            }
        });
    }
    // If no employee selected, just get every log between start and end
    else {
        worklogs.forEach(log => {
            if (moment(log.startDT).isBetween(start, end)) {
                selectedLogs.push(log)
            }
        });
    }

    return selectedLogs;

};

export const searchApproval = (worklogs, employees, customers, selectedEmployee, selectedCustomer, dateChanged, dateRange, worksite) => {
    let selectedLogs = [];
    console.log("worksite", worksite)
    // Check is employee selected
    let employee =  selectedEmployee && selectedEmployee.length > 0 ? employees.find(employee => employee.name === selectedEmployee) : "";
    let customer = selectedCustomer && selectedCustomer.length > 0 ? customers.find(customer => customer.name === selectedCustomer) : "";

    if (customer && customer.name && employee && employee.name) {

        selectedLogs = worklogs.filter(worklog => worklog.customer === customer.id && worklog.employee.includes(employee.id));
    }
    else {

        if (customer && customer.name && selectedEmployee.length === 0) {

            selectedLogs = worklogs.filter(worklog => customer.id === worklog.customer);
        }
        if (employee && employee.name && selectedCustomer.length === 0) {

            selectedLogs = worklogs.filter(worklog => worklog.employee.includes(employee.id));
        }
    }
    // Todo when worksites are added to worklogs add filtering to that aswell

    if (dateChanged) {
        const dateCheck = selectedCustomer.length > 0 || selectedEmployee.length > 0 ? selectedLogs : worklogs;
        const end = moment(dateRange.selection.endDate).endOf("day");

        selectedLogs = dateCheck.filter(worklog => moment(worklog.startDT).isBetween(dateRange.selection.startDate, end));
    }

    // If all fields are empty for some reason
    if (selectedCustomer.length === 0 && selectedEmployee.length === 0 && !dateChanged) {
        selectedLogs = worklogs
    }

    return selectedLogs;

};
