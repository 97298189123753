import moment from 'moment';
import CryptoJS from 'crypto-js';

export const createSettingsObject = () => {
    return {
        common: {},
        job: {
            period: {
                start: moment().startOf("year").format("YYYYMMDDTHHmmss"),
                end: moment().startOf("year").format("YYYYMMDDTHHmmss")
            },
            workdayStart: "atOffice",
            transfers: true,
            workDeadline: 2,
            managementStart: 0,
            billableRounding: 0,
            defaultEmp: 1
        },
        reports: {
            approvalFreeText: ""
        },
        billing: {
            paymentCondition: {
                default: 14,  // This value will be default when generating bills
                daysArray: [7, 10, 14, 15, 20, 21, 30] // These vales will be shown in bill details selectbox & in settings
            },
            serialNumbers: {
                salesInvoice: 100 // Determines starting number for salesBills
            },
            sending: {
                default: "email",
            },
            paymentTracking: {
                default: "manual"
            },
            accounting: {
                default: "none",
                accountantEmail: "",
            },
            texts: {
                defaultBill: "" // Text that will be default in all bills. It can be overwritten in billDetails,
            },
            documentTemplates: {
                email: { // Default email template
                    senderName: "",
                    senderEmail: "",
                    subject: "",
                    message: ""
                }
            },
            talenom: {
                username: "",
                password: "",
            },
            apix: {
                username: "",
                password: "",
            },
            billingFees: {
                freight: 0,
                billingFeeDependsOnDeliveryMethod: false,
                billing: 0,  //generic to all bills if billingFeeDependsOnDeliveryMethod == false
                eInvoice: 0,
                emailInvoice: 0,
                paperLetterInvoice: 0,
                netpostiInvoice: 0,
            },
        },
        company: {},
        modules: {},
    }
};


export const createUserSettings = () => {
    return {
        mainUser: false,
        management: {
            admin: false,
            jobList: false,
            createAndEdit: false,
        },
        time: {
            admin: false,
            mobileApplication: true,
            autoJob: true, // Mobile -> Automatic job tracking
            reportPartner: true, // Mobile -> Allows user report time for partner
            addJob: true, // Mobile -> Allows user to add new job
            addMaterials: true, // Mobile -> Allows user to add products to jobs
            addCustomer: true, // Mobile -> Allows user to add new customer (in addJob)
            webApplication: true,
            ownReports: true, // Web -> Allows user to see his / hers own reports
            modifyWorklogs: true // Web -> Allows user to modify worklogs
        },
        reports: {
            admin: false,
            timeReports: false, //Web -> Allows user to user all time reports
        },
        billing: {
            admin: false,
            manageBills: false, // Web -> Allows user to create and manage bills
        },
        keys: {
            admin: false,
            mobileApplication: false,
            registerKey: false, // Mobile -> Allows user to register new key
            returnCustomer: false, // Mobile -> Allows user to return key to customer
            webApplication: false,
            manageKeys: false // Web -> Allows user to modify all keys in web

        },
        registry: {
            admin: false,
            jobModels: false, // Web -> Allows user to add and modify jobModels
            jobTemplates: false, // Web -> Allows user to add and modify jobTemplates
            products: false, // Web -> Allows user to add and modify products
            customers: false // Web -> Allows user to add and modify Ccstomers
        },
        crm: {
            admin: false,
            email: false
        },
        spotOn: {
            admin: false,
            vehicles: false
        },
    }
};

export const createMainUser = () => {
    return {
        mainUser: true,
        management: {
            admin: true,
            jobList: true,
            createAndEdit: true,
        },
        time: {
            admin: true,
            mobileApplication: true,
            autoJob: true, // Mobile -> Automatic job tracking
            reportPartner: true, // Mobile -> Allows user report time for partner
            addJob: true, // Mobile -> Allows user to add new job
            addMaterials: true, // Mobile -> Allows user to add products to jobs
            addCustomer: true, // Mobile -> Allows user to add new customer (in addJob)
            webApplication: true,
            ownReports: true, // Web -> Allows user to see his / hers own reports
            modifyWorklogs: true // Web -> Allows user to modify worklogs
        },
        reports: {
            admin: true,
            timeReports: true, //Web -> Allows user to user all time reports
        },
        billing: {
            admin: true,
            manageBills: true, // Web -> Allows user to create and manage bills
        },
        keys: {
            admin: true,
            mobileApplication: true,
            registerKey: true, // Mobile -> Allows user to register new key
            returnCustomer: true, // Mobile -> Allows user to return key to customer
            webApplication: true,
            manageKeys: true // Web -> Allows user to modify all keys in web
        },
        registry: {
            admin: true,
            jobModels: true, // Web -> Allows user to add and modify jobModels
            jobTemplates: true, // Web -> Allows user to add and modify jobTemplates
            products: true, // Web -> Allows user to add and modify products
            customers: true // Web -> Allows user to add and modify Ccstomers
        },
        crm: {
            admin: true,
            email: true
        },
        spotOn: {
            admin: true,
            vehicles: true
        },
    }
};

export const jsonFormatter = {
    stringify: cipherParams => {

        // Create json object with cipherText
        const jsonObj = {ct: CryptoJS.enc.Base64.stringify(cipherParams.ciphertext)};

        // Optionally add iv or salt
        if (cipherParams.iv) {
            jsonObj.iv = cipherParams.iv.toString()
        }

        if (cipherParams.salt) {
            jsonObj.s = cipherParams.salt.toString()
        }

        // Stringify jsonObj
        return JSON.stringify(jsonObj);
    },
    parse: jsonString => {

        // Parse json string
        const jsonObj = JSON.parse(jsonString);

        // Extract ciphertext from json object and create cipher params object
        const cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(jsonObj.ct)
        });

        // Optionally extract iv and salt
        if (jsonObj.iv) {
            cipherParams.iv = CryptoJS.enc.Hex.parse(jsonObj.iv);
        }

        if (jsonObj.s) {
            cipherParams.salt = CryptoJS.enc.Hex.parse(jsonObj.s);
        }

        return cipherParams;
    }
};