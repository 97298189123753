import React, { Component } from 'react';

import firebase from 'firebase/app';
import 'firebase/firestore';

import moment from 'moment';
import {
    Checkbox,
    Button,
    FormControlLabel,
    CircularProgress,
    TextField,
    MenuItem,
} from "@material-ui/core";

//borrow these - make general later
import WorklogAC from "../WorkLogComponents/WorklogAC";
import { WorklogSelect, SelectItem } from "../WorkLogComponents/WorklogSelect";
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import ConfirmationDialog from '../CommonDialogs/ConfirmationDialog';

import { DataSearch } from "../util/DataSearch";
import { dateTime } from "../util/StringHelpers";
import { workDayWorkTime, workDayPausesSummary } from "../util/WorkDayFunctions";

import { setWorklogs } from "../actions/worklogActions";
//import { setCustomers } from "../actions/customerActions";
import { setEmployees } from "../actions/employeeActions";

import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { getWorkdayWorklogs, getDayStartAndEndTimeFromWorklogs, findPausesFromWorklogsAndAddToWorkday, calculateEveningTime, calculateNightTime, calculateEveningPauses, calculateNightPauses, calculateDaytimePauses, createEmptyDaysForPeriod } from "../util/WorkDayFunctions";
import { validateFBWorklog } from "../validations/worklog";
import "./WorkDaysLanding.css";
import { durationToHHmm, sleep } from '../util/TimeUtils';
import { isEmpty } from "../validations/isEmpty";

//import { getOccurrences } from "../CalendarComponents/OccurrencesComponent";
//import NotReportedListComponent from './NotReportedListComponent';

const INITIAL_DB_QUERY_DAYS_BACK = 45;

class WorkDaysLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            worklogStatusArray: ["all", "Created", "Ongoing", "Finished", "Submitted", "Confirmed", "Archived"],
            //searchCustomer: "",
            searchEmployee: "",
            //worklogs: [],
            workdays: [],
            //worklogsLoading: true,
            workDaysLoading: true,
            selectedWorkdays: [],
            searchResults: [],
            massSelect: false,
            selectedStatus: "all",
            dateRange: [moment(moment().subtract(1, "month")).startOf("day").toDate(), moment().endOf("day").toDate()],  //get 1 month backwards, then extend search if date is backdated more
            ////dateRange = [moment(`${startDate}T000000`).toDate(), moment(`${endDate}T235959`).toDate()];
            dateChanged: true,
            showCancelled: false,
            open: false,
            updated: false,
            sortDir: "desc",
            workdaysSaving: false,
            workdaysSaved: false,
            //wlQueryTimerStart: moment(),
            wdQueryTimerStart: moment(),
            jobQueryTimerStart: moment(),
            queryFromValue: moment().subtract(INITIAL_DB_QUERY_DAYS_BACK, "day").startOf('day').format("YYYYMMDD"),

        }
    }

    componentDidMount() {
        const { user } = this.props.user;
        const { worklogs } = this.props.worklogs;
        const { dateRange } = this.state;
        //const { customers } = this.props.customers;
        const { employees } = this.props.employees;

        //if (!customers.length > 0) firestore().collection("customers").where("companyID", "array-contains", user.companyID).get().then(querySnap => this.onQuerySnap(querySnap, "customers"));


        this.unsubsubscribeCompanyListener =
            firebase.firestore().collection("company").doc(user.companyID).onSnapshot(docScap => {
                if (docScap) {
                    const company = docScap.data();
                    this.setState({ company })
                }
            });

        this.getURLParameters();

        //if (isEmpty(worklogs)) {
        //this.queryWorklogs();
        //}

        if (!employees.length > 0) {
            //console.log("GOTTA GET EMPLOYEES")
            firebase.firestore().collection("employees").where("companyID", "==", user.companyID)
                .get() //where("isActive", "==", "true")
                .then(querySnap => this.onQuerySnap(querySnap, "employees")
                    .then(() => {
                        this.queryWorklogs()
                            .then(() => {
                                console.log("queryWorklogs resolved 1 -> query days")
                                this.queryWorkDays();
                            })
                    })
                );
        } else {
            console.log("EMPLOYEES FOUND GET WORKLOGS")
            this.queryWorklogs()
                .then(() => {
                    console.log("EMPLOYEES FOUND GET WORKDAYS")
                    this.queryWorkDays();
                })
        }
    }

    componentWillUnmount() {
        //if (this.unsubscribeWorklogListener) this.unsubscribeWorklogListener();
        //if (this.unsubscribeWorkDayListener) this.unsubscribeWorkDayListener();
        //if (this.unsubscribeWorksiteListener) this.unsubscribeWorksiteListener();
        //if (this.unsubscribeJobListener) this.unsubscribeJobListener();
        if (this.unsubsubscribeCompanyListener) this.unsubsubscribeCompanyListener();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const employeesLoading = prevProps.employees.loading;
        const workDaysLoading = this.state.workDaysLoading;

        console.log("componentDidUpdate", this.props.employees)

        //if (this.props.employees && isEmpty(this.state.workDays) && !workDaysLoading) {
        //this.queryWorkDays();
        //}

        if (!employeesLoading && !this.state.updated && !workDaysLoading && !worklogsLoading) {
            this.setState({ loading: false, updated: true }, () => this.getURLParameters())
        }
    }

    queryWorkDays = () => {

        const { dateRange, queryFromValue } = this.state;
        const { employees } = this.props.employees;

        let newQueryFromValue = { ...queryFromValue };

        //let startValue = dateRange && dateRange.selection.startDate ? moment(dateRange.selection.startDate).format("YYYYMMDD") : queryFromValue;
        let startValue = dateRange && dateRange[0] ? moment(dateRange[0]).format("YYYYMMDDTHHmmss") : queryFromValue;

        // query INITIAL_DB_QUERY_DAYS_BACK unlessa start date is further back
        if (startValue && moment(startValue) < moment(queryFromValue)) {
            newQueryFromValue = startValue;
        }

        //console.log("DB query WorkDays startValue ", startValue, employees);

        this.setState({ workdaysLoading: true, queryFromValue: newQueryFromValue, wdQueryTimerStart: moment() });

        const promises = [];

        if (employees && startValue) {
            employees.forEach((employee, index) => {
                if (employee?.isActive !== "false") {
                    console.log("get days for employee ", employee.name)
                    const emplquery = this.queryOneEmployeeWorkday(startValue, employee.id)
                    promises.push(emplquery);
                }
            })

            Promise.all(promises)
                .then((values) => {
                    console.log("resolverd values ", values);
                    Promise.all([this.solveAndGeneratePassedMissingDays()])
                        .then(() => {
                            console.log("solveAndGenerateMissingDays resolved =>  filter days");
                            this.filterWorkDays();
                            //this.setState({ workdaysLoading: false })
                        })
                        .catch((error) => {
                            //this.setState({ workdaysLoading: false }, 
                            this.filterWorkDays()
                        })
                }).catch((error) => {
                    console.log("error  ", error);
                    this.setState({ workdaysLoading: false, })
                })

        } else {
            console.log("ERR NO EMPLOYEES available")
            this.setState({ workdaysLoading: false })
        }
    }

    queryOneEmployeeWorkday = (startValue, emplId) => new Promise((resolve, reject) => {
        const { user } = this.props.user;
        console.log("get days for employee ", startValue)
        firebase.firestore().collection('company').doc(user.companyID).collection("workdays").doc(emplId).collection("worktimes")
            .where("date", ">=", startValue)
            .get()
            .then(querySnap =>
                this.onWorkDayQuerySnap(querySnap, emplId)
                    .then(() => {
                        resolve();
                    })
                    .catch((error) => {
                        reject(error)
                    })
            );
    })

    onWorkDayQuerySnap = (querySnap, emplId) => new Promise((resolve, reject) => {
        const { wdQueryTimerStart, employees } = this.state;
        let workdays = [...this.state.workdays];

        try {
            querySnap.forEach(doc => {

                let workday = doc.data();
                workday.id = doc.id;
                workday.employee = emplId;

                if (!workday.status) { //make up status if not set
                    let status = "Unknown";
                    if (workday.startDT && workday.endDT) {
                        status = "Finished" //TODO: do general component checking status
                    } else if (workday.startDT && !workday.endDT) {
                        status = "Ongoing";
                    }
                    workday.status = status;
                }
                //console.log("GOT WORKDAY DATA : ", workday)

                const index = workdays.findIndex((wDay => wDay.id === workday.id)); //TODO: checks
                if (index === -1) workdays.push(workday);
                else {
                    // there is a duplicate workday - replace or merge
                    console.log("duplicate workDay -> merge !!  ", workday)
                    if (workday.endDT && workday.startDT && (isEmpty(workdays[index].startDT) || isEmpty(workdays[index].endDT))) {
                        //if either of previous datetimes is empty, and currecnt one has both,  replace whole day
                        console.log("replace day")
                        workdays.splice(index, 1, workday);
                    } else {
                        console.log("merge day")
                        let mergedDay = {...workdays[index]}
                        
                        if (isEmpty(mergedDay.startDT) || (!isEmpty(mergedDay.startDT) && moment(workday.startDT).isBefore(moment(mergedDay.startDT)))) { 
                            mergedDay.startDT = workday.startDT;
                            mergedDay.startCoordinates = workday.startCoordinates;
                            mergedDay.startAddress = workday.startAddress;
                        }
                        if (isEmpty(mergedDay.endDT) || (!isEmpty(mergedDay.endDT) && moment(workday.endDT).isAfter(moment(mergedDay.endDT)))) { 
                            mergedDay.endDT = workday.endDT;
                            mergedDay.endCoordinates = workday.endCoordinates;
                            mergedDay.endAddress = workday.endAddress;
                        }
                        
                        console.log("merged day", mergedDay)
                        workdays.splice(index, 1, mergedDay);
                    }
                }
                //workdays.push(workday);
            });

            this.setState({ workdays: workdays }, () => resolve());

        } catch (error) {
            reject(error)
        }
    });

    solveAndGeneratePassedMissingDays = () => new Promise((resolve, reject) => {
        const { dateRange, queryFromValue, searchEmployee } = this.state;
        const { employees } = this.props.employees;
        const { worklogs } = this.props.worklogs;

        const wDays = [...this.state.workdays];

        const startDate = dateRange[0];
        const endDate = moment(dateRange[1]).isBefore(moment().startOf("day")) ? dateRange[1] : moment().subtract(1, "day").endOf("day").toDate();  //only passed days 

        console.log("solveAndGenerateMissingDays ", wDays.length, dateRange)

        createEmptyDaysForPeriod(startDate, endDate, wDays, searchEmployee, employees, worklogs, this.props.company)
            .then((wdays) => {
                console.log("solveAndGenerateMissingDays RESOLVED ", wdays)
                this.setState({ workdays: wdays },
                    () => resolve())
            })
            .catch((error) => { reject(error) })

        //TODO -FIX DATE RANGE
        // SEEMS TO GENERATE DAYS EVEN FOR EXIS STING DAYS => DUPLICATES!!
        // HOW AND WHEN DO WE STORE THESE DAYST

        //const workdays = obj.workdays;
        //const emptyDays = obj.emptyDays;
        /*
        if (!isEmpty(emptyDays)) {
            //we can only modify finished days, and only afterwards ( no ongoing, confirmed, other status .. also give user time to modify it til end of day)
            employees && employees.forEach((employee) => {
                if (employee?.isActive !== "false") {
                    console.log("solveAndGenerateMissingDays processing employee ", employee.name)
                    const employeesEmptyDays = emptyDays.filter(day => day.employee === employee.id)
                    console.log("found " + employeesEmptyDays.length + " empty days for employee ", emloyee.name);
                    employeesEmptyDays.forEach((day) => {
                        // try to fill in empty workday's times by worklogs and save those which have any work done (thinking that its useless here to create free days, as its in the reports)
                        const wLogs = getWorkdayWorklogs(workday.date, worklogs, employeeID, this.props.company);
                        const estimation = getDayStartAndEndTimeFromWorklogs(wLogs)
                        console.log("got getDayStartAndEndTimeFromWorklogs workday times estimation for ", day.date, estimation)
                        if (estimation.startTime && estimation.endTime) {
                            day.startDT = estimation.startTime;
                            day.endDT = estimation.endTime;
                            day.duration = (moment(day.endDT) - moment(day.startDT))
                            //what now - store and merge to list (push and sort)?


                        } else {
                            console.log("getDayStartAndEndTimeFromWorklogs NO WORKLOGS FOR DAY / USER ", day.date, employee.name)
                        }
                    })
                }
            })
        } else {*/


    })


    queryWorklogs = () => new Promise((resolve, reject) => {
        const { user } = this.props.user;
        const { dateRange, wlQueryFromValue } = this.state;

        let newQueryFromValue = { ...wlQueryFromValue };

        console.log("DB query Worklogs dateRange[0] ", dateRange[0] ? moment(dateRange[0]).format("YYYYMMDDTHHmmss") : "N/A");

        let startValue = dateRange && dateRange[0] ? moment(dateRange[0]).format("YYYYMMDDTHHmmss") : wlQueryFromValue;

        console.log("DB query Worklogs startValue 1", startValue);

        // query INITIAL_DB_QUERY_DAYS_BACK unlessa start date is further back
        if (startValue && moment(startValue) < moment(wlQueryFromValue)) {
            newQueryFromValue = startValue;
        }

        console.log("DB query Worklogs startValue 2", startValue);

        this.setState({ loading: true, worklogsLoading: true, wlQueryFromValue: newQueryFromValue, wlQueryTimerStart: moment() });

        //if (this.unsubscribeWorklogListener) this.unsubscribeWorklogListener();

        firebase.firestore().collection('company').doc(user.companyID).collection("worklogs")
            .where("jobStartDT", ">=", startValue)
            .orderBy("jobStartDT", "desc")
            .get()
            .then(querySnap => {
                this.onWorklogQuerySnap(querySnap).then(() => {
                    resolve();
                }).catch((error) => reject(error));
            })

    })



    onWorklogQuerySnap = querySnap => new Promise((resolve, reject) => {
        const { wlQueryTimerStart } = this.state;
        const worklogs = [];

        console.log("DEBUG: WORKLOG QUERY TOOK ", (moment() - wlQueryTimerStart) / 1000);

        const timer1 = moment();
        querySnap.forEach(doc => {

            const worklog = doc.data();
            worklog.id = doc.id;
            const { isValid } = validateFBWorklog(worklog);

            if (isValid) worklogs.push(worklog);
            else { console.log("worklog not valid!!  ", worklog) }
        });
        console.log("DEBUG: WORKLOG resulted ", worklogs);
        //this.props.setWorklogs(worklogs);
        console.log("DEBUG: WORKLOG LOOP TOOK ", (moment() - timer1) / 1000);

        //console.log("worklogs ", worklogs)
        //setWorklogs(worklogs);

        this.filterWorklogs(worklogs).then(() => {
            resolve();
        }).catch((error) => {
            reject();
        })

    });


    onQuerySnap = (querySnap, dataName) => new Promise((resolve, reject) => {
        const dataArr = [];

        querySnap.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;
            if (dataName === "employees") data.name = `${data.firstName} ${data.familyName}`;
            dataArr.push(data);
        });

        //if (dataName === "customers") this.props.setCustomers(dataArr);
        if (dataName === "employees") {
            this.props.setEmployees(dataArr);
        }
        resolve();
    });


    getEmployee = id => {
        const { employees } = this.props.employees;

        const employee = employees.find(emp => emp.id === id);

        if (employee) {
            return employee.name;
        }
        else {
            return "Couldn't find name";
        }

    };

    getURLParameters = () => {
        //const { customers } = this.props.customers;
        const { employees } = this.props.employees;
        let { dateRange } = this.state;

        const params = new URLSearchParams(this.props.location.search);
        //const customer = params.get("customer");
        const employee = params.get("employee");
        const status = params.get("status");
        const startDate = params.get("startDate");
        const endDate = params.get("endDate");

        //let searchCustomer = "";
        let searchEmployee = "";
        let dateChanged = false;
        //let dateRange = [moment().startOf("month").toDate(), moment().endOf("month").toDate()];
        //if (customer) searchCustomer = customers.find(customerObj => customerObj.name.toLowerCase() === customer.split("_").join(" "));
        if (employee) searchEmployee = employees.find(employeeObj => employeeObj.name.toLowerCase() === employee.split("_").join(" "));
        if (startDate && endDate) {
            dateRange = [moment(`${startDate}T000000`).toDate(), moment(`${endDate}T235959`).toDate()];
            dateChanged = true;
        }

        this.setState({
            searchEmployee: searchEmployee ? searchEmployee.name : "",
            //searchCustomer: searchCustomer ? searchCustomer.name : "",
            selectedStatus: status ? status : "all",
            dateRange,
            dateChanged
        }, () => {
            if (this.shouldLoadMoreDataFromDB()) {
                this.queryWorklogs()
                    .then(() => {
                        console.log("queryWorklogs resolved -> quesry days")
                        this.queryWorkDays();
                    })
                //this.filterWorkDays()
            }
        });



    };

    shouldLoadMoreDataFromDB = () => {
        const { wlQueryFromValue } = this.state;
        let { dateRange, dateChanged } = this.state;
        const startDate = dateRange && dateRange[0] ? moment(dateRange[0]).format("YYYYMMDD") : moment().subtract(INITIAL_DB_QUERY_DAYS_BACK, "day").format("YYYYMMDD");

        console.log("shouldLoadMoreDataFromDB: 1", startDate);

        if (dateChanged && startDate && moment(wlQueryFromValue) > moment(startDate)) {
            console.log("getURLParameters: trigger DB query: start date is earlier than INITIAL_DB_QUERY_DAYS_BACK days ", startDate);

            return true

        } else {
            console.log("shouldLoadMoreDataFromDB NOPE");
            return false
        }
    }

    searchURLCombiner = () => {
        const { searchCustomer, searchEmployee, selectedStatus, dateRange } = this.state;

        //let customerSearchString = "";
        let employeeSearchString = "";
        let statusSearchString = "";

        /*if (searchCustomer) {
            const value = searchCustomer.toLowerCase().split(" ").join("_");
            customerSearchString = `customer=${value}`
        }*/

        if (searchEmployee) {
            const value = searchEmployee.toLowerCase().split(" ").join("_");
            employeeSearchString = `employee=${value}`;
        }

        if (selectedStatus) {
            statusSearchString = `status=${selectedStatus}`;
        }

        const startValue = dateRange && dateRange[0] ? moment(dateRange[0]).format("YYYYMMDD") : moment().subtract(1, "month").toDate();
        const endValue = dateRange && dateRange[1] ? moment(dateRange[1]).format("YYYYMMDD") : moment().endOf("day").toDate(); //.format("YYYYMMDD");
        const dateStartString = `startDate=${startValue}`;
        const dateEndString = `endDate=${endValue}`;

        const finalString = `/management/workdays/?${employeeSearchString ? "&" : ""}${employeeSearchString}${statusSearchString ? "&" : ""}${statusSearchString}&${dateStartString}&${dateEndString}`;
        this.props.history.push(finalString);

        this.filterWorkDays();
    };

    onValueSelect = (item, field) => {
        let value = typeof item === "object" ? item.name : item;
        this.setState({ [field]: value }, () => this.searchURLCombiner());
    };

    onDateChange = event => {
        console.log("onDateChange: ", event)
        const dateRange = event ? event : [moment().subtract(1, "month").toDate(), moment().endOf("day").toDate()]
        console.log("dateRange: ", dateRange);


        this.setState({ dateRange: dateRange, dateChanged: true }, () => this.searchURLCombiner());

        this.shouldLoadMoreDataFromDB(dateRange, true);
    };

    onWorkdaySelect = id => {
        const { selectedWorkdays } = this.state;

        if (!selectedWorkdays.includes(id)) selectedWorkdays.push(id);
        else {
            const removeIndex = selectedWorkdays.indexOf(id);
            selectedWorkdays.splice(removeIndex, 1);
        }
        console.log("onWorkdaySelect: ", selectedWorkdays)
        this.setState({ selectedWorkdays });
    };

    onWorkDaysMassSelect = () => {
        const { searchResults } = this.state;
        let selectedWorkdays = [];
        if (this.state.massSelect) selectedWorkdays = [];
        else selectedWorkdays = searchResults.map(workday => workday.id);

        this.setState(prevState => ({ selectedWorkdays, massSelect: !prevState.massSelect }));
    };

    filterWorkDays = () => {
        const { searchEmployee, dateRange, selectedStatus, workdays } = this.state;
        let { dateChanged } = this.state;
        //const { customers } = this.props.customers;
        const { employees } = this.props.employees;
        //const {worklogs} = this.props.worklogs;

        const timer1 = moment();

        this.setState({ loading: true });

        console.log("filterWorkDAYS  selectedStatus, dateChanged: ", selectedStatus, dateChanged);
        //const customer = customers.find(customer => customer.name === searchCustomer);
        const employee = employees.find(employee => employee.name === searchEmployee);

        const workdaySearch = new DataSearch();
        const workdaysData = [...workdays];
        workdaySearch.setSearchData(workdaysData);

        if (employee) workdaySearch.appendField("employee", employee.id, "includes");
        if (selectedStatus !== "all") workdaySearch.appendField("status", selectedStatus, "isEqual");

        //console.log("filterWorkDAYs : dateRange " + moment(dateRange[0]).format("YYYYMMDDTHHmmss") + " - " + moment(dateRange[1]).format("YYYYMMDDTHHmmss"));
        //dateRange = [moment(`${startDate}T000000`).toDate(), moment(`${endDate}T235959`).toDate()];

        //if (dateChanged) {
        const dateObj = {
            selection: {
                startDate: moment(dateRange[0]).format("YYYYMMDDTHHmmss"),
                endDate: moment(dateRange[1]).format("YYYYMMDDTHHmmss"),
            }
        };
        console.log("filterWorkDAYS : dateObj.selection ", dateObj.selection)

        workdaySearch.appendField("date", dateObj, "between");
        //    dateChanged = false;
        //}

        //if (!showCancelled && selectedStatus === "all" && selectedStatus !== "notReported") worklogSearch.appendField("status", "Cancelled", "notEqual");
        const results = workdaySearch.executeSearch(this.state.sortDir, "date");

        console.log("DEBUG: FILTER  workDays  TOOK ", (moment() - timer1) / 1000);
        console.log("after executeSearch workDays results -filtered:  ", results);

        this.setState({
            workdaysLoading: false,
            searchResults: results,
            showType: "logs",
            loading: false,
            //searchCustomer: searchCustomer,
            searchEmployee: searchEmployee,
            //
            dateRange: dateRange,
            dateChanged: dateChanged,
            //showCancelled: showCancelled
        });

    }


    filterWorklogs = (worklogs) => new Promise((resolve, reject) => {
        const { searchEmployee, selectedStatus, dateRange, dateChanged, showCancelled } = this.state;
        //const { customers } = this.props.customers;
        const { employees } = this.props.employees;
        //const { worklogs } = this.props.worklogs;


        const timer1 = moment();

        this.setState({ loading: true });

        console.log("filterWorklogs selectedStatus : ", selectedStatus);
        //const customer = customers.find(customer => customer.name === searchCustomer);
        const employee = employees.find(employee => employee.name === searchEmployee);


        //if notReported -> query jobs that done have worklogs instead
        //if (selectedStatus === "notReported") return this.searchJobs(searchCustomer, searchEmployee, selectedStatus, dateRange, dateChanged);

        //else continue with worklog search
        const worklogSearch = new DataSearch();

        console.log("filterWorklogs worklogs : ", worklogs);

        worklogSearch.setSearchData(worklogs);
        //if (customer) worklogSearch.appendField("customer", customer.id, "isEqual");
        if (employee) worklogSearch.appendField("employee", employee.id, "includes");
        if (selectedStatus !== "all") worklogSearch.appendField("status", selectedStatus, "isEqual");

        console.log("filterWorklogs : dateRange " + moment(dateRange[0]).format("YYYYMMDDTHHmmss") + " - " + moment(dateRange[1]).format("YYYYMMDDTHHmmss"));
        //dateRange = [moment(`${startDate}T000000`).toDate(), moment(`${endDate}T235959`).toDate()];

        if (dateChanged) {
            const dateObj = {
                selection: {
                    startDate: moment(dateRange[0]).format("YYYYMMDDTHHmmss"),
                    endDate: moment(dateRange[1]).format("YYYYMMDDTHHmmss"),
                    //startDate: moment(`${dateRange[0]}T000000`).format("YYYYMMDDTHHmmss"),
                    //endDate: moment(`${dateRange[1]}T235959`).format("YYYYMMDDTHHmmss"),
                }
            };
            console.log("filterWorklogs : dateObj.selection ", dateObj.selection)

            worklogSearch.appendField("startDT", dateObj, "between");
        }


        if (!showCancelled && selectedStatus === "all" && selectedStatus !== "notReported") worklogSearch.appendField("status", "Cancelled", "notEqual");

        const results = worklogSearch.executeSearch(this.state.sortDir, "startDT");

        console.log("DEBUG: FILTER  WORKLOGS  TOOK ", (moment() - timer1) / 1000);

        console.log("after executeSearch of WORKLOGS results -filtered:  ", results);

        setWorklogs(worklogs);

        this.setState({
            //searchResults: results,
            worklogsLoading: false,
            showType: "logs",
            loading: false,
            //searchCustomer: searchCustomer,
            searchEmployee: searchEmployee,
            selectedStatus,
            dateRange: dateRange,
            dateChanged: dateChanged,
            showCancelled: showCancelled
        }, () => resolve());
    });


    onSearchParameterReset = () => {
        this.setState({
            searchResults: [],
            //searchCustomer: "",
            searchEmployee: "",
            selectedStatus: "all",
            dateRange: [moment().subtract(1, "month").startOf("day").toDate(), moment().endOf("month").toDate()]
        }, this.filterWorkDays);

        this.props.history.replace("/management/workdays")
    };

    renderSelectBox = () => {
        const { t } = this.props;
        return (
            <TextField
                value="Select"
                variant="outlined"
                style={{ height: 60, width: 120 }}
                onChange={event => this.changeStatus(event)}
                select
            >
                <MenuItem value="Select" key="Select">{t("Select")}</MenuItem>
                <MenuItem value="Finished" key="Finished">{t("Finished")}</MenuItem>
                <MenuItem value="Submitted" key="Submitted">{t("Submitted")}</MenuItem>
                <MenuItem value="Confirmed" key="Confirmed">{t("Confirmed")}</MenuItem>
                <MenuItem value="Archived" key="Archived">{t("Archived")}</MenuItem>
            </TextField>
        );
    };

    changeStatus = (event) => {
        const { selectedWorkdays, workDays } = this.state;
        const { user } = this.props.user;

        console.log("changeStatus event ", event);
        console.log("selectedWorkdays ", selectedWorkdays);

        if (selectedWorkdays.length === 1) {
            const workDayId = selectedWorkdays[0];
            const id = workDayId;
            if (workDays && workDays.length > 0) {
                const workday = workDays.find(workday => workday.id === id);
                console.log("found workday ", workday);
                if (workday) {
                    workday.status = event.target.value;
                    console.log(" workday.status  ", workday.status);
                    //TODO: is this necessary? firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').doc(id).set(worklog);
                }
            }
            this.setState({
                selectedWorkdays: []
            });
        }
        else {
            this.setState({
                confirmationText: event.target.value,
                confirmationDialog: true
            });
        }
    };

    onStatusConfirm = (nextPatch) => {
        const { selectedWorkdays, workDays, confirmationText } = this.state;
        const { user } = this.props.user;

        //get actual worklogs thats been selected for batch update
        let workDaysForBatching = [];
        selectedWorkdays.map(selectedID => {
            const workday = workDays.find(workday => workday.id === selectedID);
            if (workday) workDaysForBatching.push(workday);
        });

        console.log("workDaysBatching ", workDaysForBatching);

        let firstBatch = [];
        if (!nextPatch) firstBatch = workDaysForBatching.splice(0, 299);
        else firstBatch = nextPatch.splice(0, 299);

        let rest = [];
        if (!nextPatch) rest = workDaysForBatching.splice(0, workDaysForBatching.length - 1);
        else rest = nextPatch.splice(0, rest.length - 1);

        const batch = firebase.firestore().batch();

        firstBatch.forEach(day => {
            day.status = confirmationText;

            //TODO: const logRef = firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').doc(log.id);
            const logRef = firebase.firestore().collection('company').doc(user.companyID).collection('workdays').doc(day.employee).collection("worktimes").doc(day.id);
            batch.set(logRef, day);
        });


        batch.commit()
            .then(() => {
                if (rest.length > 0) this.onStatusConfirm(rest);
                else {
                    console.log("rest lenght", rest.length);
                    console.log("set state to complete")
                    this.setState({ worklogsSaved: true })
                }
            })
            .catch(err => console.log("error while saving ", err));
    };


    renderTotalDuration = () => {
        const { searchResults } = this.state;
        const { user } = this.props.user;

        let totalTimeInMillis = 0.0;

        if (searchResults && searchResults.length > 0) {
            searchResults.map(workday => {
                if (workday.duration) totalTimeInMillis += workday.duration
            })
        }
        //return (billing.billableHours / 3600000).toFixed(2).replace(".", user.lang === "fi" ? "," : ".");
        return (totalTimeInMillis / 3600000).toFixed(2).replace(".", user.lang === "fi" ? "," : ".") + "h";
    }

    /*renderTotalHours = () => {
        const { searchResults } = this.state;
        const { user } = this.props.user;

        let totalTimeInMillis = 0.0;

        if (searchResults && searchResults.length > 0) {
            searchResults.map(worklog => {
                if (worklog.billing && worklog.billing.billableHours) {
                    const numEmpl = worklog.employee && Array.isArray(worklog.employee) ? worklog.employee.length : 1;
                    totalTimeInMillis += (worklog.billing.billableHours * numEmpl);
                }
            })
        }
        //return (billing.billableHours / 3600000).toFixed(2).replace(".", user.lang === "fi" ? "," : ".");
        return (totalTimeInMillis / 3600000).toFixed(2).replace(".", user.lang === "fi" ? "," : ".") + "h";
    }*/

    /*getWorksiteName = wsID => {
        const { worksites } = this.state;
        const { t } = this.props;

        const worksite = worksites && worksites.find(worksite => worksite.id === wsID);

        if (worksite && worksite.name) return worksite.name;
        else if (worksite && worksite.number) return t("Worksite number") + worksite.number;
        else return "missing";
    }*/

    renderListItem = workday => {
        const { selectedWorkdays } = this.state;
        const { workDays } = this.state;
        const { user } = this.props.user;
        //const { customers } = this.props.customers;
        const { employees } = this.props.employees;
        const { t, company } = this.props;


        //console.log("render workday ", workday)

        return (
            <div className="wl-list-row" key={workday.id}>
                {<div className="wl-left">
                    <Checkbox
                        checked={selectedWorkdays.includes(workday.id)}
                        onChange={() => this.onWorkdaySelect(workday.id)}
                        style={{ color: '#000' }}
                    />
                </div>
                }

                <Link to={`/management/workdays/edit/${workday.id},${workday.employee}`} className="wl-link">
                    <div className="wl-right">
                        <div className="wl-list-item small">{dateTime(workday.date, "D", user)}</div>
                        <div className="wl-list-item medium">{this.getEmployee(workday.employee)}</div>
                        <div className="wl-list-item small">{dateTime(workday.startDT, "T", user)}</div>
                        <div className="wl-list-item small">{dateTime(workday.endDT, "T", user)}</div>
                        <div className="wl-list-item small">{t(workday.status)}</div>
                        <div className="wl-list-item small">{workDayPausesSummary(workday, user)}</div>
                        {/*<div className="wl-list-item small">{workDayWorkTime(workday, user)}</div>*/}
                        <div className="wl-list-item small">{durationToHHmm(workday.duration)}</div>
                        <div className="wl-list-item small">{durationToHHmm(calculateEveningTime(workday, company))}</div>
                        <div className="wl-list-item small">{durationToHHmm(calculateNightTime(workday, company))}</div>

                    </div>
                </Link>

            </div>
        )
    };

    render() {
        const { loading, workDays, workLogsLoading, searchEmployee, selectedWorkdays, searchResults, selectedStatus, dateRange, dateChanged, showType } = this.state;
        //const { customers } = this.props.customers;
        const { employees } = this.props.employees;
        const { t } = this.props;

        //console.log("render(): searchResults ", searchResults)
        return (
            <div className="wl-container">
                <div className="wl-search">
                    {/* <WorklogAC
                        searchValues={["name"]}
                        searchData={customers}
                        listShowField={"name"}
                        listMaxLength={5}
                        onSelect={this.onValueSelect}
                        onChange={event => this.setState({searchCustomer: event})}
                        value={searchCustomer || ""}
                        fieldName="searchCustomer"
                        placeholder={t("Customer")}
                    />
                    */}

                    {
                        <WorklogAC
                            searchValues={["name"]}
                            searchData={employees}
                            listShowField={"name"}
                            listMaxLength={5}
                            onSelect={this.onValueSelect}
                            onChange={event => this.setState({ searchEmployee: event })}
                            value={searchEmployee || ""}
                            fieldName="searchEmployee"
                            placeholder={t("Employee")}
                        />
                    }

                    {
                        <WorklogSelect
                            value={t(this.state.selectedStatus)}
                            onChange={event => this.onValueSelect(event, "selectedStatus")}
                            placeHolder={t("Status")}
                        >
                            {this.state.worklogStatusArray.map(status => (
                                <SelectItem value={status} key={status}>{t(status)}</SelectItem>
                            ))}

                        </WorklogSelect>
                    }

                    <DateRangePicker
                        value={this.state.dateRange}
                        locale="fi-FI"
                        className="wl-date-range"
                        onChange={this.onDateChange}
                        isOpen={this.state.open}
                    />

                    {/* 
                    <FormControlLabel
                        style={{height: 40, marginLeft: 10}}
                        control={
                            <Checkbox
                                style={{color: '#1770B2'}}
                                checked={this.state.showCancelled}
                                onChange={() => this.setState(prevState => ({showCancelled: !prevState.showCancelled}), () => this.filterWorklogs())}
                            />
                        }
                        label={t("Show_cancelled")}
                    />
                    */}

                    <Button
                        variant={"contained"}
                        style={{ backgroundColor: '#1770B2', color: '#FFF', margin: "0 10px", height: 40 }}
                        onClick={this.onSearchParameterReset}
                    >
                        {t("Reset")}
                    </Button>

                    <Button
                        variant={"contained"}
                        style={{ backgroundColor: '#1770B2', color: '#FFF', height: 40 }}
                        onClick={this.filterWorkDays}
                    >
                        {t("Search")}
                    </Button>
                </div>


                {loading &&
                    <div style={{
                        alignSelf: 'center',
                        width: '50vw',
                        height: 'auto',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '3%'
                    }}>
                        <CircularProgress />
                    </div>
                }


                {selectedWorkdays && selectedWorkdays.length > 0 &&
                    <div className="wl-on-select-header">
                        <div className="wl-on-select-row">
                            <div className="wl-list-item large">{t("Change_message")}</div>
                            {this.renderSelectBox()}
                        </div>
                    </div>
                }

                {!this.state.loading && this.state.showType === "logs" &&
                    <div className="wl-list-header">
                        <div className="wl-left">
                            <Checkbox
                                checked={this.state.massSelect}
                                onChange={this.onWorkDaysMassSelect}
                                className="wl-checkbox"
                                style={{ color: '#1770b2' }}
                            />
                        </div>

                        <div className="wl-header-right">
                            <div className="wl-list-item small">{t("Date")}</div>
                            <div className="wl-list-item medium">{t("Employee")}</div>
                            <div className="wl-list-item small">{t("DayStartTime")}</div>
                            <div className="wl-list-item small">{t("DayEndTime")}</div>
                            <div className="wl-list-item small">{t("Status")}</div>
                            <div className="wl-list-item small">{t("Pauses summary")}</div>
                            <div className="wl-list-item small">{t("Work time")}</div>
                            <div className="wl-list-item small">{t("Evening time")}</div>
                            <div className="wl-list-item small">{t("Night time")}</div>

                        </div>
                    </div>
                }

                {!this.state.loading && this.state.showType === "logs" &&
                    <div className="wl-list-body">

                        {!this.state.loading && searchResults.length > 0 &&
                            searchResults.map(this.renderListItem)}


                        {/*selectedWorklogs && selectedWorklogs.length > 0 &&
                    <div className="wl-on-select-header">
                        <div className="wl-on-select-row">
                            <div className="wl-list-item large">{t("Change_message")}</div>
                            {this.renderSelectBox()}
                        </div>
                    </div>
                    */}
                        { /* this should be done more generic*/}
                        {this.state.confirmationDialog &&
                            <ConfirmationDialog
                                open={this.state.confirmationDialog}
                                onClose={() => this.setState({
                                    confirmationDialog: false,
                                    workdaysSaving: false,
                                    massSelect: false,
                                    workdaysSaved: false,
                                    selectedWorkdays: [],
                                    confirmationText: ""
                                })}
                                onConfirm={() => this.setState({ workdaysSaving: true }, () => this.onStatusConfirm())}
                                confirmationText={t(this.state.confirmationText)}
                                saving={this.state.workdaysSaving}
                                saved={this.state.workdaysSaved}
                                numSelected={selectedWorkdays.length}
                            />
                        }

                        {!loading && searchResults.length > 0 &&
                            <div>
                                <div className="wl-right">
                                    <div className="wl-summary-item">{t("Workdays count")}</div>
                                </div>
                                <div className="wl-right">
                                    <div className="wl-summary-item">{searchResults.length}</div>
                                </div>
                            </div>
                        }

                        {/*!loading && searchResults.length > 0 &&
                            <div>
                                <div className="wl-right">
                                    <div className="wl-summary-item">{t("Worklog total billable")}</div>
                                </div>
                                <div className="wl-right">
                                    <div className="wl-summary-item">{this.renderTotalHours()}</div>
                                </div>
                            </div>
                        */}

                    </div>
                }
            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.user,
    worklogs: state.worklogs,
    //customers: state.customers,
    employees: state.employees
});

export default withRouter(connect(mapStateToProps, {
    setWorklogs,
    //setCustomers,
    setEmployees
})(withTranslation()(WorkDaysLanding)));
