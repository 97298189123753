import React, {Component} from 'react';

import {
    Menu
} from '@material-ui/core';

import {DateRange} from "react-date-range";
import { fi, enGB} from 'date-fns/locale'

import moment from 'moment';

import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';

class DateMenu extends Component{
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
            dateRange: {
                selection: {
                    startDate: this.props.value,
                    endDate: moment(this.props.value).add(1, "minute"),
                    key: "selection"
                }
            }
        }
    }

    render() {
        const {dateRange} = this.state;
        const {anchorEl} = this.props;
        const {user} = this.props.user;

        const locale = user.lang && user.lang === "en" ? enGB : fi;
        const open = Boolean(anchorEl);

        return (
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "bottom"
                }}
                transformOrigin={{
                    horizontal: "right",
                    vertical: "top"
                }}
                open={open}
                onClose={() => this.props.closeMenu()}
            >
                <DateRange
                    ranges={[dateRange?.selection]}
                    onChange={event => this.props.onChange(event, this.props.field)}
                    locale={locale}
                    moveRangeOnFirstSelection={true}
                    showDateDisplay={false}
                    preview={{
                        startDate: dateRange?.selection?.startDate,
                        endDate: dateRange?.selection?.endDate,
                        color: "#FFF"
                    }}
                />
            </Menu>
        )
    }

}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(DateMenu));