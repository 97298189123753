import {SAVE_SEND, RESET_SEND} from "./types";

export const saveSend = (customer, worklogs, company, pdf) => {
    return {
        type: SAVE_SEND,
        customer: customer,
        worklogs: worklogs,
        company: company,
        pdf: pdf
    }
};

export const resetSend = () => {
    return {
        type: RESET_SEND
    }
};