import React from 'react';

import {withTranslation} from "react-i18next";


import BillDetailsListItem from './BillDetailsListItem';
import DateMenu from './DateMenu';
import SelectMenu from './SelectMenu';
import {
    TextField,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";

import {
    CheckBoxOutlineBlank,
    CheckBox,
    KeyboardArrowDown
} from '@material-ui/icons';

import moment from 'moment';
import "moment/locale/fi";

import "./BillDetailsInformation.css";

import { isEmpty } from '../../validations/isEmpty';

const BillDetailsInformation = props => {
    const {customer, invoice} = props.bill.billObj;
    const {anchorEl, t, company} = props;

   //console.log("company.settings.billing ", company.settings.billing, company)

    // Determine what array to give to SelectMenu component
    let loopArr = [];
    if (props.selectTarget === "currency") {
        loopArr = invoice.currencies
    }
    else if (props.selectTarget === "sendingMethod") {
        
        //loopArr = ["Email / APIX", "APIX", "Netvisor", "Talenom", "Email", "Print"]
        loopArr = ["Email", "Print"];

        if(!isEmpty(company.settings.billing?.apix)) {
            loopArr.push("Email / APIX");
            loopArr.push("APIX");
        }
        if(!isEmpty(company.settings.billing?.netvisor)) {
            loopArr.push("Netvisor");
        }
        if(!isEmpty(company.settings.billing?.talenom)) {
            loopArr.push("Talenom");
        }
    }
    else if (props.selectTarget === "paymentTracking") {
        loopArr = ["Manual", "APIX", "Netvisor", "Talenom"]
    }
    else {
        loopArr = props.company.settings.billing.paymentCondition.daysArray;
    }


    return (
        <div>
            <div className="details-header">
                <p className="details-header-text">{t("Bill information")}</p>
            </div>

            <div className="information-container">

                {/* !-------- Left Component ------------!  */}

                <div className="information-left">

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Bill number")}:</p> }
                        right={<p style={{paddingTop: 10}}>{props.bill.billNumber}</p> }
                    />

                    <BillDetailsListItem
                        style={{height: 50}}
                        left={<p style={{paddingTop: 10}}>{t("Type")}:</p>}
                        right={<p style={{margin: 0}}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={
                                            <CheckBoxOutlineBlank style={{height:20, width: 20}}/>
                                        }
                                        checkedIcon={
                                            <CheckBox  style={{height: 20, width: 20}} />
                                        }
                                        checked={!props.bill.type || props.bill.type === "bill"}
                                        value="bill"
                                        onChange={event => props.onChange(event, 'billType')}
                                        style={{
                                            color: '#2B81C1'
                                        }}
                                    />
                                }
                                label={t("Bill")} />

                            <br/>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={
                                            <CheckBoxOutlineBlank style={{height:20, width: 20}}/>
                                        }
                                        checkedIcon={
                                            <CheckBox style={{height: 20, width: 20}}/>
                                        }
                                        checked={props.bill.type === "compensation"}
                                        value="compensation"
                                        onChange={event => props.onChange(event, 'billType')}
                                        style={{
                                            color: '#2B81C1',
                                        }}
                                    />
                                }
                                label={t("Hyvityslasku")} />
                        </p>}
                    />
                    <br/>
                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Customer number")}:</p>}
                        right={<TextField
                            variant="outlined"
                            style={{
                                marginTop: 9
                            }}
                            InputProps={{
                                style:{
                                    height: 25,
                                    fontSize: 14
                                }
                            }}
                            value={customer.contact.customerNumber}
                            onClick={props.openCustomerDialog}
                        />}
                    />

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Customer name")}:</p>}
                        right={<p style={{paddingTop: 10}}>{customer.contact.name}</p>}
                    />

                </div>

                {/* !-------- Center Component ------------!  */}


                <div className="information-center">
                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Time to pay")}:</p>}
                        right={<TextField
                            variant="outlined"
                            style={{
                                marginTop: 9,
                                width: 195
                            }}
                            InputProps={{
                                style:{
                                    height: 25
                                },
                                endAdornment: <KeyboardArrowDown style={{height: 15, width: 15}}/>
                            }}
                            value={invoice.timeToPay}
                            onClick={event => props.handleSelect(event, 'timeToPay')}
                        />}
                    />

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Delivery terms")}:</p>}
                        right={<TextField
                            variant="outlined"
                            style={{
                                marginTop: 9
                            }}
                            InputProps={{
                                style:{
                                    height: 25
                                }
                            }}
                        />}
                    />

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Delivery type")}:</p>}
                        right={<TextField
                            variant="outlined"
                            style={{
                                marginTop: 9,
                                width: 195
                            }}
                            InputProps={{
                                style:{
                                    height: 25
                                },
                                endAdornment: <KeyboardArrowDown style={{height: 15, width: 15}} />
                            }}
                            value={t(props.bill.sendingMethod.sending)}
                            onClick={event => props.handleSelect(event, "sendingMethod")}
                        />
                        }
                    />

                    {props.bill.sendingMethod.sending === "email / apix" &&
                        <BillDetailsListItem
                            left={<p style={{paddingTop: 10}}>{t("Payment tracking")}</p>}
                            right={
                                <TextField
                                    variant="outlined"
                                    style={{
                                        marginTop: 9,
                                        width: 195
                                    }}
                                    InputProps={{
                                        style:{
                                            height: 25
                                        },
                                        endAdornment: <KeyboardArrowDown style={{height: 15, width: 15}} />
                                    }}
                                    value={t(props.bill.sendingMethod.paymentTracking)}
                                    onClick={event => props.handleSelect(event, "paymentTracking")}
                                />
                            }
                        />
                    }

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Your order number")}:</p>}
                        right={<TextField
                            variant="outlined"
                            style={{
                                marginTop: 9
                            }}
                            InputProps={{
                                style:{
                                    height: 25
                                }
                            }}
                        />}
                    />

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Reference number")}:</p>}
                        right={<p style={{paddingTop: 10}}>{invoice.referenceNumber || ""}</p>}
                    />
                </div>


                {/* !-------- Right Component ------------!  */}

                <div className="information-right">
                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Bill date")}:</p>}
                        right={<TextField
                            variant="outlined"
                            style={{
                                marginTop: 9
                            }}
                            InputProps={{
                                style:{
                                    height: 25
                                }
                            }}
                            onClick={event => props.handleMenu(event, "billDate")}
                            value={moment(props.bill.billDate).locale("fi").format("L")}
                        />}
                    />

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Due date")}:</p>}
                        right={<TextField
                            variant="outlined"
                            style={{
                                marginTop: 9
                            }}
                            InputProps={{
                                style:{
                                    height: 25
                                }
                            }}
                            onClick={event => props.handleMenu(event, 'dueDate')}
                            value={moment(invoice.dates.dueFinish).locale("fi").format("L")}
                        />}
                    />


                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Delivery date")}:</p>}
                        right={<TextField
                            variant="outlined"
                            style={{
                                marginTop: 9
                            }}
                            InputProps={{
                                style:{
                                    height: 25
                                }
                            }}
                            onClick={event => props.handleMenu(event, "deliveryDate")}
                            value={moment(props.bill.billDate).locale("fi").format("L")}
                        />}
                    />

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Our reference")}:</p>}
                        right={<TextField
                            variant="outlined"
                            value={invoice.ourReference || ""}
                            onChange={event => props.onInvoiceChange(event, "ourReference")}
                            style={{
                                marginTop: 9
                            }}
                            InputProps={{
                                style:{
                                    height: 25
                                }
                            }}
                        />}
                    />

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Your reference")}:</p>}
                        right={<TextField
                            variant="outlined"
                            value={invoice.customerReference || ""}
                            onChange={event => props.onInvoiceChange(event, "customerReference")}
                            style={{
                                marginTop: 9
                            }}
                            InputProps={{
                                style:{
                                    height: 25
                                }
                            }}
                        />}
                    />

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 10}}>{t("Currency")}:</p>}
                        right={
                            <TextField
                            variant="outlined"
                            style={{
                                marginTop: 9,
                                width: 195
                            }}
                            InputProps={{
                                style:{
                                    height: 25
                                },
                                endAdornment: <KeyboardArrowDown style={{height: 15, width: 15}}/>
                            }}
                            value={invoice.currency}
                            onClick={event => props.handleSelect(event, "currency")}
                        />
                        }
                    />

                    <BillDetailsListItem
                        left={<p style={{paddingTop: 15}}>{t("VAT prices")}:</p>}
                        right={
                            <Checkbox
                            icon={
                                <CheckBoxOutlineBlank style={{height:20, width: 20, marginTop: 10}}/>
                            }
                            checkedIcon={
                                <CheckBox  style={{height: 20, width: 20, marginTop: 10}} />
                            }
                            checked={props.priceWithVat}
                            onChange={() => props.changePriceType()}
                            style={{
                                color: '#2B81C1'
                            }}
                        />
                        }
                    />

                    {props.anchorEl !== null &&
                        <DateMenu
                            anchorEl={anchorEl}
                            closeMenu={props.closeMenu}
                            value={props.value}
                            field={props.dateField}
                            onChange={props.onDateChange}
                        />
                    }

                    {props.anchorEs !== null &&
                        <SelectMenu
                            anchorEs={props.anchorEs}
                            closeMenu={props.closeMenu}
                            selectTarget={props.selectTarget}
                            loopArr={loopArr}
                            onSelect={(event,field) => props.onInvoiceChange(event, field)}
                        />
                    }
                </div>
            </div>
        </div>
    )
};

export default withTranslation()(BillDetailsInformation);
