import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";
import { isEmpty } from '../validations/isEmpty';
import { durationToHHmm, durationToHours } from '../util/TimeUtils';

const eveningShiftStartTime = "1800";
const nightShiftStartTime = "2200";
const workdayStartTime = "0600";
const sundayWorkStartTimeSat = "2000";   //TODO


export const workDayWorkTime = (workDay, user) => {

    if (!workDay.duration) return "0,0";
    else return durationToHours(workDay.duration, user.lang);
};

//työpäivän vaihtumisen huomioivat ajat
//sunday work may start saturday evening
export const getWorkDayTimeRange = (workDay) => {
    const weekday = moment(workday.date).format('dddd');

    //normal days, modify in case is weekend with setting "sundayWorkStartTimeSat"
    let workdayStartDate = workDay.date; //this is string YYYYMMDD
    let workdayEndDate = moment(workdayStartDate).add(1, "day").format("YYYYMMDD");
    let workdayStartTime = company?.settings?.job?.workday?.workdayStartTime ? company?.settings?.job?.workday?.workdayStartTime : workdayStartTime;
    let nextDayStartTime = company?.settings?.job?.workday?.workdayStartTime ? company?.settings?.job?.workday?.workdayStartTime : workdayStartTime;

    if (weekday === "Sunday") {
        if (company?.settings?.job?.workday?.sundayWorkStartTimeSat) {
            workdayStartDate = moment(workdayStartDate).add(-1, "day").format("YYYYMMDD");
            workdayStartTime = company.settings.job.workday.sundayWorkStartTimeSat;
            console.log("Sunday exceptional setting sundayWorkStartTimeSat ", workdayStartDate, workdayStartTime)
        }
    } else if (weekday === "Saturday") {
        if (company?.settings?.job?.workday?.sundayWorkStartTimeSat) {
            workdayEndDate = workdayStartDate;
            workdayEndTime = company.settings.job.workday.sundayWorkStartTimeSat;
            console.log("Saturday exceptional setting sundayWorkStartTimeSat ", workdayEndDate, workdayEndTime)
        }
    }

    const workdayStartMoment = moment(workdayStartDate + thisDayStartTime);
    const workdayEndMoment = moment(workDayEndDate + nextDayStartTime);

    const dayTimeRange = {
        startTime: workdayStartMoment.format("YYYYMMDDTHHmmss"),
        endTime: workdayEndMoment.format("YYYYMMDDTHHmmss"),
    }
    console.log("getWorkDayTimeRange ", dayTimeRange)
    return dayTimeRange;
}

export const workDayPausesSummary = (workDay, user) => {
    if (!workDay.pauses) return "0,0";
    else {

        const pausesCount = workDay.pauses.length;
        let pausesDuration = 0;
        workDay.pauses.map((pause) => {
            if (pause.duration) pausesDuration += pause.duration;
        })

        return (`${pausesCount}/${durationToHours(pausesDuration.duration, user.lang)}`);
    }
}

export const createEmptyDaysForPeriod = (start, end, userLoggedDays = [], selectedEmployee, allEmployees, worklogs, company) => new Promise((resolve, reject) => {

    let loggedDays = [...userLoggedDays];
    //const emptyDays = [];

    let loopDate = moment(start).startOf("day");
    console.log("createEmptyDaysForPeriod dates ", start, end, loopDate.isBefore(end))
    console.log("loggedDays ", loggedDays)

    while (loopDate.isBefore(moment(end))) {
        console.log("createEmptyDaysForPeriod loop all days, day ", loopDate.format("YYYYMMDD"), moment(end).format("YYYYMMDD"))
        allEmployees.forEach(empl => {
            //console.log("createEmptyDaysForPeriod employee ", empl);
            if ((selectedEmployee === "" || selectedEmployee === empl.name) && empl?.isActive !== "false") {
                
                const dateStr = moment(loopDate).format("YYYYMMDD");
                const emplID = empl.id  //needs to be set to const

                console.log("loop employee ", empl.name, emplID, dateStr, loggedDays.findIndex(wd => wd.employee === emplID && wd.date === dateStr))
                
                let indexOfWday = loggedDays.findIndex(wd => wd.employee === emplID && wd.date === dateStr) //.findIndex(ewd => ewd.date == dateStr)
                let workDay = {}
                if (indexOfWday > -1) {
                    console.log("found workday ", loggedDays[indexOfWday], indexOfWday)
                    workDay = loggedDays[indexOfWday];
                } 

                //check if workdays array does not exist for this date & this emplyee, then create new and empty
                //if (!loggedDays.some(wd => wd.date === dateStr && wd.employee === empl.id)) {
                if (isEmpty(workDay)) {
                    //if workday not found - means no work or worktime not logged propely
                    //create emopty day for employee
                    console.log("Creating new log date for employee ", empl.name, dateStr)
                    workDay = { date: dateStr, startDT: {}, endDT: {}, employee: emplID, duration: 0, generated: true, status: "Created" }
                    //loggedDays.push(workDay); //new 
                    //emptyDays.push(workDay);
                } else {
                    console.log("day found ", dateStr, empl.name, workDay)
                }
                //try to create day based on the worklogs or check id day missing end or start
                const wLogs = getWorkdayWorklogs(dateStr, worklogs, emplID, company);

                //if day is not started or ended properly, try checking the logs if there is any
                const estimation = getDayStartAndEndTimeFromWorklogs(wLogs)
                console.log("got getDayStartAndEndTimeFromWorklogs workday times estimation for ", workDay.date, estimation)
                if (estimation.startTime && estimation.endTime) {

                    //either add or fix times
                    if (isEmpty(workDay.startDT) || (!isEmpty(workDay.startDT) && moment(estimation.startTime).isBefore(moment(workDay.startDT)))) workDay.startDT = estimation.startTime;
                    if (isEmpty(workDay.endDT) || (!isEmpty(workDay.endDT) && moment(estimation.endTime).isAfter(moment(workDay.endDT)))) workDay.endDT = estimation.endTime;
                    //emptyDay.startDT = estimation.startTime;
                    //emptyDay.endDT = estimation.endTime;

                    workDay.duration = (moment(workDay.endDT) - moment(workDay.startDT))
                }

                //ADD OR REPLACE OLD
                //console.log("replace workday or add to list", indexOfWday, workDay)
                 
                if (indexOfWday > -1) {
                    console.log("replace workdau in a list", indexOfWday, workDay)
                    loggedDays.splice(indexOfWday, 1, workDay);
                } else  {
                    console.log("add workday to a list", indexOfWday, workDay)
                    loggedDays.push(workDay)
                };
            
            }
            else {
                console.log("not match createEmptyDaysForPeriod ", selectedEmployee, empl);
            }
        })
        loopDate = loopDate.add(1, "day");
    }

    console.log("createEmptyDaysForPeriod AFTER LOOP ", loggedDays);

    //sort again and put to workdays array
    const workdays = loggedDays.sort((a, b) => {
        if (a.date < b.date) return -1;
        if (a.date > b.date) return 1;
    });

    resolve(workdays);
})

export const getWorkdayWorklogs = (workDayDate, worklogs, employeeID, company) => {
    const wLogs = [];

    const sameWorkdayStartMoment = company?.settings?.job?.workday?.workdayStartTime ? moment(workDayDate + ' ' + company?.settings?.job?.workday?.workdayStartTime) : moment(workDayDate + ' ' + workdayStartTime)
    const nextWorkdayStartMoment = company?.settings?.job?.workday?.workdayStartTime ? moment(workDayDate + ' ' + company?.settings?.job?.workday?.workdayStartTime).add(1, "day") : moment(workDayDate + ' ' + workdayStartTime).add(1, "day")

    worklogs && worklogs.forEach(worklog => {
        if (worklog?.employee && worklog.employee.includes(employeeID)) {
            //should this be workday start & end, not actual day?
            if (moment(worklog.startDT).isBetween(sameWorkdayStartMoment, nextWorkdayStartMoment, "undefined", '[]') &&
                 (worklog.status !== "Ongoing" && worklog.status !== "discarded")) {
                console.log("getWorkdayWorklogs wLog ", worklog)
                wLogs.push(worklog);
            }
        }
    })

    const sortedWorklogs = wLogs.sort((a, b) => {
        if (a.startDT < b.startDT) return -1;
        if (a.startDT > b.startDT) return 1;
    });
    console.log("getWorkdayWorklogs ", workDayDate, sortedWorklogs)

    return sortedWorklogs;
}

export const getDayStartAndEndTimeFromWorklogs = (wLogs) => {
    let startTime = {}
    let endTime = {}

    // loop thru worklogs
    // compose "workdays" for those who did not log normally, i.e. added just worklogs afterwards
    wLogs && wLogs.forEach(log => {
        if (isEmpty(startTime)) startTime = log.startDT;
        else if (!isEmpty(startTime) && moment(log.startDT) < moment(startTime)) startTime = log.startDT;

        if (isEmpty(endTime)) endTime = !isEmpty(log.endDT) ? log.endDT : log.startDT;
        else if (!isEmpty(endTime) && moment(log.endDT) > moment(endTime)) endTime = log.endDT;

    })
    console.log("getDayStartAndEndTimeFromWorklogs ", wLogs, startTime, endTime)

    return { startTime: startTime, endTime: endTime }
}

export const pauseOverlaps = (wdPauses, pause) => {
    //TODO!!!
    return false;
}

export const findPausesFromWorklogsAndAddToWorkday = (workday, wLogs) => {
    const maxTransferTimeConvertedToDaybreak = 60000; //60min, get this from settings

    const workDay = { ...workday }
    let prevEndDT = {}

    //this user and this day worklogs 
    wLogs && wLogs.forEach(log => {

        if (!isEmpty(prevEndDT)) {
            if (moment(log.startDT) - moment(prevEndDT) > maxTransferTimeConvertedToDaybreak) {
                //add dybrk
                const dayBreakPause = { type: "daybreak", isPayable: false, startDT: prevEndDT, endDT: log.startDT, duration: moment(log.startDT) - moment(prevEndDT), reason: "unpaid pause added automatically" }
                console.log("add day break for day 1", dayBreakPause, moment(log.startDT).format("YYYYMMDDHHmmss"), moment(prevEndDT).format("YYYYMMDDHHmmss"))
                if (isEmpty(workDay.pauses)) workDay.pauses = [];

                if (!pauseOverlaps(workDay.pauses, dayBreakPause)) {
                    workDay.pauses.push(dayBreakPause);
                }
                console.log("add day break for day 2 ", workDay.pauses)
            }
            prevEndDT = log.endDT;

        } else { prevEndDT = log.endDT }


        if (log.pauses) {
            log.pauses.map(pause => {
                if (!pause.isPayble) {
                    if (!workDay.pauses) workDay.pauses = [];

                    if (!pauseOverlaps(workDay.pauses, pause)) {
                        workDay.pauses.push(pause);
                        console.log("add worklog pause for day ", workDay)
                    }
                }
            })
        }
    })
    return workDay;
}

export const calculateEveningTime = (workday, company) => {

    //use company setting if exist or default
    const eveningShiftStartMoment = company?.settings?.job?.workday?.eveningShiftStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.eveningShiftStartTime) : moment(workday.date + ' ' + eveningShiftStartTime)
    const nightShiftStartMoment = company?.settings?.job?.workday?.nightShiftStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.nightShiftStartTime) : moment(workday.date + ' ' + nightShiftStartTime)

    //console.log("evening and night shift starts ", eveningShiftStartMoment.format("YYYYMMDDHHmm"), nightShiftStartMoment.format("YYYYMMDDHHmm"))

    return workday.eveningDuration ? workday.eveningDuration :
        workday.endDT && workday.startDT ?
            moment(workday.startDT).isBefore(eveningShiftStartMoment) && moment(workday.endDT).isSameOrAfter(nightShiftStartMoment) ? (nightShiftStartMoment - eveningShiftStartMoment) : // if full evening time
                moment(workday.startDT).isBefore(eveningShiftStartMoment) && moment(workday.endDT).isSameOrAfter(eveningShiftStartMoment) && moment(workday.endDT).isBefore(nightShiftStartMoment) ? (moment(workday.endDT) - eveningShiftStartMoment) : // start before evening shift, ends before night shoift
                    moment(workday.startDT).isSameOrAfter(eveningShiftStartMoment) && moment(workday.endDT).isSameOrAfter(nightShiftStartMoment) ? (nightShiftStartMoment - moment(workday.startDT)) :  // starts on evening shift, ends after night shift starts
                        moment(workday.startDT).isSameOrAfter(eveningShiftStartMoment) && moment(workday.endDT).isSameOrAfter(eveningShiftStartMoment) && moment(workday.endDT).isBefore(nightShiftStartMoment) ? (moment(workday.endDT) - moment(workday.startDT)) : // work only during "evening work window"
                            0 : 0;  // else case is if 

}

export const calculateEveningPauses = (workday, company) => {
    let eveningPauseDuration = 0;

    //use company setting if exist or default
    const eveningShiftStartMoment = company?.settings?.job?.workday?.eveningShiftStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.eveningShiftStartTime) : moment(workday.date + ' ' + eveningShiftStartTime)
    const nightShiftStartMoment = company?.settings?.job?.workday?.nightShiftStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.nightShiftStartTime) : moment(workday.date + ' ' + nightShiftStartTime)

    workday.pauses.map(pause => {
        if (!pause.isPayble) {
            if (moment(pause.startDT).isBetween(eveningShiftStartMoment, nightShiftStartMoment) && moment(pause.endDT).isBetween(eveningShiftStartMoment, nightShiftStartMoment)) {
                eveningPauseDuration += pause.duration
            }
            //else if pause started evening but conitnues till night 
            else if (moment(pause.startDT).isBetween(eveningShiftStartMoment, nightShiftStartMoment) && moment(pause.endDT).isAfter(nightShiftStartMoment)) {
                eveningPauseDuration += (eveningShiftStartMoment - moment(pause.startDT));
            }
            //else if pause started before evening but ends at evening time 
            else if (moment(pause.startDT).isBefore(eveningShiftStartMoment) && moment(pause.endDT).isBetween(eveningShiftStartMoment, nightShiftStartMoment)) {
                eveningPauseDuration += (moment(pause.endDT) - eveningShiftStartMoment);
            }
            //else if whole evening time paused
            else if (moment(pause.startDT).isBefore(eveningShiftStartMoment) && moment(pause.endDT).isAfter(nightShiftStartMoment)) {
                eveningPauseDuration += (nightShiftStartMoment - eveningShiftStartMoment);
            }
        }
    })
    return eveningPauseDuration;
}

export const calculateNightTime = (workday, company) => {

    //use company setting if exist or default
    const nightShiftStartMoment = company?.settings?.job?.workday?.nightShiftStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.nightShiftStartTime) : moment(workday.date + ' ' + nightShiftStartTime)
    const nextWorkdayStartMoment = company?.settings?.job?.workday?.workdayStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.workdayStartTime).add(1, "day") : moment(workday.date + ' ' + workdayStartTime).add(1, "day")

    return workday.nightDuration ? workday.nightDuration :
        workday.endDT && workday.startDT ?
            moment(workday.startDT).isBefore(nightShiftStartMoment) && moment(workday.endDT).isSameOrAfter(nextWorkdayStartMoment) ? (nextWorkdayStartMoment - nightShiftStartMoment) : // if night  time
                moment(workday.startDT).isBefore(nightShiftStartMoment) && moment(workday.endDT).isSameOrAfter(nightShiftStartMoment) && moment(workday.endDT).isBefore(nextWorkdayStartMoment) ? (moment(workday.endDT) - nightShiftStartMoment) : // start before night shift, ends before next day
                    moment(workday.startDT).isSameOrAfter(nightShiftStartMoment) && moment(workday.endDT).isSameOrAfter(nightShiftStartMoment) && moment(workday.endDT).isSameOrAfter(nextWorkdayStartMoment) ? (nextWorkdayStartMoment - moment(workday.startDT)) :  // starts on night shift, ends after next dayt starts
                        moment(workday.startDT).isSameOrAfter(nightShiftStartMoment) && moment(workday.endDT).isSameOrAfter(nightShiftStartMoment) && moment(workday.endDT).isBefore(nextWorkdayStartMoment) ? (moment(workday.endDT) - moment(workday.startDT)) :
                            0 : 0;  // else case is if work only during "night work window" but not in full
}



export const calculateNightPauses = (workday, company) => {

    let nightPauseDuration = 0;

    //use company setting if exist or default
    const eveningShiftStartMoment = company?.settings?.job?.workday?.eveningShiftStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.eveningShiftStartTime) : moment(workday.date + ' ' + eveningShiftStartTime)
    const nightShiftStartMoment = company?.settings?.job?.workday?.nightShiftStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.nightShiftStartTime) : moment(workday.date + ' ' + nightShiftStartTime)
    const nextWorkdayStartMoment = company?.settings?.job?.workday?.workdayStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.workdayStartTime).add(1, "day") : moment(workday.date + ' ' + workdayStartTime).add(1, "day")


    workday.pauses.map(pause => {
        if (!pause.isPayble) {
            if (moment(pause.startDT).isBetween(nightShiftStartMoment, nextWorkdayStartMoment) && moment(pause.endDT).isBetween(nightShiftStartMoment, nextWorkdayStartMoment)) {
                nightPauseDuration += pause.duration
            }
            //else if pause started night but continues till day 
            else if (moment(pause.startDT).isBetween(nightShiftStartMoment, nextWorkdayStartMoment) && moment(pause.endDT).isAfter(nextWorkdayStartMoment)) {
                nightPauseDuration += (eveningShiftStartMoment - moment(pause.startDT));
            }
            //else if pause started before night but ends at evening time 
            else if (moment(pause.startDT).isBefore(nightShiftStartMoment) && moment(pause.endDT).isBetween(nightShiftStartMoment, nextWorkdayStartMoment)) {
                nightPauseDuration += (moment(pause.endDT) - eveningShiftStartMoment);
            }
            //else if whole night time paused
            else if (moment(pause.startDT).isBefore(nightShiftStartMoment) && moment(pause.endDT).isAfter(nextWorkdayStartMoment)) {
                nightPauseDuration += (nightShiftStartMoment - eveningShiftStartMoment);
            }
        }
    })

    return nightPauseDuration;
}

export const calculateDaytimePauses = (workday, company) => {
    let pauseTime = 0

    //use company setting if exist or default
    const eveningShiftStartMoment = company?.settings?.job?.workday?.eveningShiftStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.eveningShiftStartTime) : moment(workday.date + ' ' + eveningShiftStartTime)
    const sameWorkdayStartMoment = company?.settings?.job?.workday?.workdayStartTime ? moment(workday.date + ' ' + company?.settings?.job?.workday?.workdayStartTime) : moment(workday.date + ' ' + workdayStartTime)

    workday.pauses.map(pause => {
        if (!pause.isPayble) {
            console.log("daytime pause found ", pause, sameWorkdayStartMoment, eveningShiftStartMoment);
            if (moment(pause.startDT).isBetween(sameWorkdayStartMoment, eveningShiftStartMoment)) {
                //what about pause that continues? is it counted evening time?
                pauseTime += pause.duration
                console.log("daytime pause added ", pause.duration, pauseTime);
            }
        }
    })
    return pauseTime;
}

