import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
    Button, Checkbox,
    Chip,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider, FormControlLabel,
    IconButton,
    MenuItem,
    TextField
} from "@material-ui/core";
import * as moment from 'moment';
import "moment/locale/fi";
import Autocomplete from "react-autocomplete";
import {Delete} from "@material-ui/icons";

import RecurrenceDialog from './RecurrenceDialog';
import DateDialog from './DateDialog';
import TimeDialog from './TimeDialog';
import ErrorDialog from './ErrorDialog';

import keycode from "keycode";
import {withTranslation} from "react-i18next";

class FullscreenDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            selectedJob: {},
            selectedModel: "",
            selectedModelObj: {},
            employees: [],
            customers: [],
            worksites: [],
            customerWorksites: [],
            models: [],
            extraTodo: [],
            extraTodoText: "",
            repeats: "noRepeat",
            recurringRule: "",
            searchCustomer: "",
            datePicker: false,
            timePicker: false,
            selectType: "",
            rrStart: moment().toDate(),
            rrEnd: moment().toDate(),
            createTemplate: true,
            err: false
        };
    }

    componentWillMount() {
        const {user} = this.props.user;

        this.unsubscribePropListener = this.setState({
            selectedJob: this.props.selectedJob,
            repeats: this.props.selectedJob.rrule ? "custom1" : "noRepeat",
            searchCustomer: this.props.selectedJob.customerLabel ? this.props.selectedJob.customerLabel : "",
            employees: this.props.employees,
            dialogOpen: true
        });

        if (this.props.editMode) {
            this.setState({
                createTemplate: false
            });
        }

        this.unsubscribeJobModelListener = firebase.firestore().collection('company').doc(user.companyID).collection('jobModels').onSnapshot(this.onJobModelSnapshot);

        this.unsubscribeWorksiteListener = firebase.firestore().collection('company').doc(user.companyID).collection('worksites').onSnapshot(this.onWorksiteSnapshot);

        this.unsubscribeCustomerListener =
            firebase.firestore().collection('customers')
                .where('companyID', 'array-contains', user.companyID)
                .onSnapshot(this.onCustomerSnapshot);


    }

    componentWillUnmount() {
        if (this.unsubscribePropListener) {
            this.unsubscribePropListener();
        }

        if (this.unsubscribeJobModelListener) {
            this.unsubscribeJobModelListener();
        }

        if (this.unsubscribeCustomerListener) {
            this.unsubscribeCustomerListener();
        }

        if (this.unsubscribeWorksiteListener) {
            this.unsubscribeWorksiteListener();
        }
    }

    onJobModelSnapshot = querySnapshot => {
        let models = [];
        let selectedModelObj =  {};
        querySnapshot.forEach(doc => {
            let model = doc.data();
            model.id = doc.id;

            if (model.id === this.state.selectedModel) {
                selectedModelObj = model;
            }

            models.push(model);
        });

        this.setState({
            models: models,
            selectedModelObj: selectedModelObj
        });
    };

    onCustomerSnapshot = querySnapshot => {
        const customers = [];
        let selectedModel = "";

        querySnapshot.forEach(doc => {
            let customer = doc.data();
            customer.id = doc.id;
            customers.push(customer);

            if (customer.id === this.props.selectedJob.customer) {
                if (customer.templateID) {
                    selectedModel = customer.templateID;

                }
            }
        });
        this.setState({
            customers: customers,
            selectedModel: selectedModel,
        });

        this.filterModels(selectedModel);
    };

    onWorksiteSnapshot = querySnapshot => {
        const worksites = [];
        const customerWorksites = [];

        querySnapshot.forEach(doc => {
            let worksite = doc.data();
            worksite.id = doc.id;
            worksites.push(worksite);

            if (worksite.customer === this.props.selectedJob.customer) {
                customerWorksites.push(worksite)
            }
        });

        this.setState({
            worksites: worksites,
            customerWorksites: customerWorksites
        });
    };

    filterModels = selectedModel => {
        const {models} = this.state;

        if (selectedModel && selectedModel.length > 5) {
            const modelArr = models.filter(model => model.id === selectedModel);

            this.setState({
                models: modelArr
            });
        }
    };

    getIntervalText = (freq, interval) => {
        const {t} = this.props;
        switch (freq) {
            case "DAILY":
                return interval === 1 ? `${t("Every")} ${t("day")}` : `${t("Every")} ${interval} ${t("days")}`;
            case "WEEKLY":
                if (interval === 0) {
                    return t("Does not repeat")
                }
                else {
                    return interval === 1 ? `${t("Every")} ${t("week")}` : `${t("Every")} ${interval} ${t("weeks")}`;
                }
            case "MONTHLY":
                return interval === 1 ? `${t("Every")} ${t("month")}` : `${t("Every")} ${interval} ${t("months")}`;
            default:
                return "?"
        }
    };

    handleTimeChange = (event, field) => {
        const {selectedJob} = this.state;

        const duration = selectedJob.endDT - selectedJob.startDT;

        const time = moment(event).format('HH:mm:ss');

        const date = moment(selectedJob[field]).format('YYYY-MM-DD');

        selectedJob[field] = moment(date + 'T' + time).toDate();

        if (field === "startDT") {

            const endTime = moment(moment(event) + moment(duration)).format('HH:mm:ss');

            selectedJob.endDT = moment(date + 'T' + endTime).toDate();
        }

        this.setState({
            timePicker: false,
            selectedJob: selectedJob
        });
    };

    handleDateChange = (event, field) => {
        const {selectedJob} = this.state;

        if (field === "startDT" || field === "endDT") {

            let time = moment(selectedJob[field]).format('HH:mm:ss');

            let date = moment(event.selection.startDate).format('YYYY-MM-DD');

            selectedJob[field] = moment(date + 'T' + time).toDate();

            if (field === "startDT") {
                let endTime = moment(selectedJob.endDT).format('HH:mm:ss');

                selectedJob.endDT = moment(date + 'T' + endTime).toDate();
            }

            this.setState({
                datePicker: false,
                selectedJob: selectedJob
            });

        }

        if (field === "recurringRange") {
            this.setState({
                rrStart: event.selection.startDate,
                rrEnd: event.selection.endDate,
                datePicker: false
            });
        }


    };


    customerHandler = (event) => {
        let {selectedJob, selectedModel, selectedModelObj, customers, models, searchCustomer, worksites} = this.state;

        let selectedCustomer = {};

        let secondary = [];

        let createTemplate =  true;

        let modelArr = [];

        if (customers) {
            customers.forEach(customer => {
                if (customer.name === event) {
                    selectedCustomer = customer;
                    selectedJob.customer = customer.id;
                    selectedJob.customerLabel = customer.name;
                    searchCustomer = event
                }
            });
        }

        // If customer has a template, use that one, else from model array that only contains models
        if (models && selectedCustomer.templateID) {
            const findModel = models.find(model => model.id === selectedCustomer.templateID);

            selectedModel = findModel.id;
            selectedModelObj = findModel;
            createTemplate = false;
        }
        else {
            modelArr = models.filter(model => model.type !== "template")
        }

        if (worksites) {

            worksites.forEach(site => {
                if (site.customer === selectedCustomer.id) {
                    secondary.push(site)
                }
            });

            if (secondary.length > 0) {

                if (secondary.some(item => item.isDefault === "true")) {
                    let worksite = secondary.find(item => item.isDefault === "true");
                    if (worksite) {selectedJob.worksite = worksite.id}
                }
                else {
                    selectedJob.worksite = secondary[0].id;
                }
            }
        }


        this.setState({
            selectedJob: selectedJob,
            selectedModel: selectedModel,
            selectedModelObj: selectedModelObj,
            searchCustomer: searchCustomer,
            customerWorksites: secondary,
            createTemplate: createTemplate,
            models: modelArr
        });
    };

    repeatHandler = event => {
        const {selectedJob} = this.state;

        if (event.target.value === "noRepeat") {
            if (!selectedJob.rrule) {
                selectedJob.rrule = {
                    FREQ: 'WEEKLY',
                    INTERVAL: 0,
                    UNTIL: moment(selectedJob.endDT).format('YYYYMMDDTHHmmss'),
                }
            }
        }

        if (event.target.value === "daily") {
            selectedJob.rrule = {
                FREQ: "DAILY",
                INTERVAL: 1
            };
            if (selectedJob.rrule.UNTIL) {delete selectedJob.rrule.UNTIL}

        }
        if (event.target.value === "weekly") {
            selectedJob.rrule = {
                FREQ: "WEEKLY",
                INTERVAL: 1
            };
            if (selectedJob.rrule.UNTIL) {delete selectedJob.rrule.UNTIL}
        }

        if (event.target.value === "weekly2") {
            selectedJob.rrule = {
                FREQ: "WEEKLY",
                INTERVAL: 2
            };
            if (selectedJob.rrule.UNTIL) {delete selectedJob.rrule.UNTIL}
        }

        if (event.target.value === "custom") {
            if (!selectedJob.rrule) {
                selectedJob.rrule = {
                    FREQ: "WEEKLY",
                    INTERVAL: 1
                }
            }
        }

        this.setState({
            selectedJob: selectedJob,
            repeats: event.target.value
        });
    };


    modelHandler = (event) => {
        let {models, selectedModel, selectedModelObj} = this.state;

        if (models) {
            models.map(model => {
                if (model.id === event.target.value) {
                    selectedModel = event.target.value;
                    selectedModelObj = model
                }
            });
        }

        this.setState({
            selectedModelObj: selectedModelObj,
            selectedModel: selectedModel
        })
    };

    handleWorksite = event => {
        const {selectedJob} = this.state;

        selectedJob.worksite = event.target.value;

        this.setState({
            selectedJob: selectedJob
        });

    };

    employeeHandler = (event, action) => {
        const {employees, selectedJob} = this.state;

        let added = false;

        // If no employees added yet
        if ((!selectedJob.employees && action === "add") || (selectedJob.employees && selectedJob.employees.length === 0)) {
            employees.map(employee => {
                if (employee.name === event) {
                    selectedJob.employees = [
                        {
                            id: employee.id,
                            name: employee.name
                        }
                    ]
                }
            });
            added = true;
        }

        // If employees array exists, add new && block adding duplicates
        if (selectedJob.employees && action === "add" && !added) {
            let count = 0;
            selectedJob.employees.map(employee => {
                if (employee.name !== event) {
                    count++;
                }
                if (count === selectedJob.employees.length) {
                    employees.map(employee => {
                        if (employee.name === event) {
                            let newEmployee = {
                                id: employee.id,
                                name: employee.name
                            };
                            selectedJob.employees.push(newEmployee)
                        }
                    });
                }
            });
        }

        if (action === "delete") {
            selectedJob.employees.map(employee => {
                if (employee.name === event) {
                    let index = selectedJob.employees.indexOf(employee);
                    if (index > -1 ) {
                        selectedJob.employees.splice(index, 1)
                    }
                }
            });
        }

        this.setState({
            selectedJob: selectedJob,
            searchEmployee: ""
        });
    };


    taskHandler = (event, action) => {
        const {selectedModelObj, selectedJob} = this.state;

        let added = false;

        // If selected job doesn't have any tasks yet
        if ((!selectedJob.tasks && action === "add") || (selectedJob.tasks && selectedJob.tasks.length === 0)) {
            selectedModelObj.tasks.map(task => {
                if (task.name === event.target.value) {
                    selectedJob.tasks = [
                        task
                    ];
                    if (!selectedJob.title) {
                        selectedJob.title = task.name
                    }
                    else {
                        selectedJob.title = selectedJob.title + ', ' + task.name
                    }
                }
            });

            added = true;
        }

        // If tasks array exists, add new && block adding duplicates
        if (selectedJob.tasks && action === "add" && !added) {
            let count = 0;
            selectedJob.tasks.map(task => {
                if (task.name !== event.target.value) {
                    count++;
                }
                if (count === selectedJob.tasks.length) {
                    selectedModelObj.tasks.map(task => {
                        if (task.name === event.target.value) {
                            selectedJob.tasks.push(task);
                            !this.state.editMode ? selectedJob.title = selectedJob.title + ', ' + task.name : selectedJob.title = selectedJob.title;
                        }
                    });
                }
            });
        }

        if (action === "delete") {
            selectedJob.tasks.map(task => {
                if (task.name === event) {
                    let index = selectedJob.tasks.indexOf(task);
                    if (index > -1 ){
                        selectedJob.tasks.splice(index, 1)
                    }
                }
            });
        }

        this.setState({
            selectedJob: selectedJob
        });

    };

    keyDownHandler = event => {
        const {extraTodo, extraTodoText} = this.state;

        if (keycode(event) === "enter") {
            extraTodo.push(extraTodoText);

            this.setState({
                extraTodoText: "",
                extraTodo: extraTodo
            })
        }

        if (extraTodoText === "" && keycode(event) === "backspace") {
            extraTodo.pop();

            this.setState({
                extraTodoText: "",
                extraTodo: extraTodo
            })
        }


    };

    chipDeleteHandler = item => {
        const {extraTodo} = this.state;
        let list = extraTodo.filter(task => task !== item);

        this.setState({
            extraTodo: list
        });
    };

    handleChange = (event, field) => {
        const {selectedJob} = this.state;

        selectedJob[field] = event.target.value;

        this.setState({
            selectedJob: selectedJob
        });
    };

    editJobButton = () => {
        const {selectedJob, extraTodo, recurringRule, rrStart, rrEnd, createTemplate, selectedModelObj} = this.state;

        if (recurringRule === "") {
            this.setState({
                err: true
            });
        }
        else {
            this.props.onEdit(selectedJob, recurringRule, extraTodo, rrStart, rrEnd, createTemplate, selectedModelObj );
        }
    };

    renderSearch = (props) => {
        const {InputProps, ref, ...other} = props;
        return <TextField
            placeholder={this.props.t("Search employees")}
            style={{
                backgroundColor: '#FFF',
                borderRadius: 4,
                width: 230,
                marginTop: 10
            }}
            variant="outlined"
            InputProps={{
                inputRef: ref,
                ...InputProps}}
            {...other}
        />
    };

    renderCustomerSearch = (props) => {
        const {InputProps, ref, ...other} = props;
        return <TextField
            placeholder={this.props.t("Search customers")}
            style={{
                backgroundColor: '#FFF',
                borderRadius: 4,
                width: 481,
                marginTop: 10
            }}
            variant="outlined"
            InputProps={{
                inputRef: ref,
                ...InputProps}}
            {...other}
        />
    };

    render() {
        const {selectedJob} = this.state;
        const {user} = this.props.user;
        const {t} = this.props;
        if (this.state.dialogOpen) {
            return (
               <div>
                    <Dialog
                        open={this.state.dialogOpen}
                        onClose={() => this.props.onClose()}
                        fullScreen
                    >
                        {this.props.editMode && <DialogTitle>{t("Edit job")}</DialogTitle>}
                        {!this.props.editMode && <DialogTitle>{t("Add job")}</DialogTitle>}
                        <DialogContent>
                            <div style={{display: 'flex'}}>
                                <div style={{flex: 1}}>

                                    <Button onClick={() => this.setState({pickField: 'startDT', datePicker: true, selectType: "date", value: selectedJob.startDT})}>
                                        {moment(selectedJob.startDT).locale('fi').format('L')}
                                    </Button>

                                    <Button style={{marginRight: 5}} onClick={() => this.setState({pickField: 'startDT', timePicker: true, value: selectedJob.startDT})}>
                                        {moment(selectedJob.startDT).format('LT')}
                                    </Button>
                                    -

                                    <Button style={{marginLeft: 5}} onClick={() => this.setState({pickField: 'endDT', datePicker: true, selectType: "date", value: selectedJob.endDT})}>
                                        {moment(selectedJob.endDT).locale('fi').format('L')}
                                    </Button>

                                    <Button onClick={() => this.setState({pickField: 'endDT', timePicker: true, value: selectedJob.endDT})}>
                                        {moment(selectedJob.endDT).format('LT')}
                                    </Button>
                                    <br/>

                                    <Autocomplete
                                        items={this.state.customers}
                                        getItemValue={(item) => item.name}
                                        shouldItemRender={(item, value) => item.name.toLowerCase().search(value.toLowerCase()) > -1}
                                        renderMenu={children => (
                                            <div style={{backgroundColor: '#FFF', position: 'absolute', opacity: 100, zIndex: 10, width: 481}}>
                                                {children}
                                            </div>
                                        )}
                                        renderItem={item =>
                                            <div key={item.id}>
                                                <MenuItem>{item.name}</MenuItem>
                                                <Divider style={{backgroundColor: '#ccc', width: '97%', marginLeft: 10}}/>
                                            </div>
                                        }
                                        renderInput={this.renderCustomerSearch}
                                        value={this.state.searchCustomer}
                                        onChange={event => this.setState({searchCustomer: event.target.value})}
                                        onSelect={event => this.customerHandler(event)}
                                    />

                                    <br/>

                                    <TextField
                                        variant="outlined"
                                        label={t("Repeats")}
                                        value={this.state.repeats}
                                        onChange={event => this.repeatHandler(event)}
                                        style={{width: 230, marginTop: 10}}
                                        select
                                    >
                                        <MenuItem value="noRepeat" key="noRepeat">{t("Does not repeat")}</MenuItem>
                                        <MenuItem value="daily" key="daily">{t("Daily")}</MenuItem>
                                        <MenuItem value="weekly" key="weekly">{t("Weekly on") + " " + moment(selectedJob.startDT).locale(user.lang ? user.lang : "fi").format('dddd')}</MenuItem>
                                        <MenuItem value="weekly2" key="weekly2">{t("Every two weeks")}</MenuItem>
                                        <MenuItem value="custom" key="custom">{t("Custom")}</MenuItem>
                                        {this.state.repeats === "custom1" && <MenuItem value="custom1">{this.getIntervalText(selectedJob.rrule.FREQ, selectedJob.rrule.INTERVAL)}</MenuItem>}
                                    </TextField>

                                    <TextField
                                        variant="outlined"
                                        value={this.state.selectedModel || ""}
                                        label={t("Job model")}
                                        onChange={event => this.modelHandler(event)}
                                        style={{
                                            width: 230,
                                            fontSize: 10,
                                            marginLeft: 21,
                                            marginTop: 10
                                        }}
                                        select
                                    >
                                        {this.state.models && this.state.models.map(model => (
                                            <MenuItem value={model.id} key={model.id}>{model.name}</MenuItem>
                                        ))}
                                    </TextField>
                                    <br/>

                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary" checked={this.state.createTemplate}
                                                 onChange={() => this.setState(prevState =>
                                                {return {createTemplate: !prevState.createTemplate}})}
                                        />}
                                        label={t("Create_template")}
                                        labelPlacement="start"
                                        style={{marginLeft: 260}}
                                    />

                                    <br/>

                                    <TextField
                                        variant="outlined"
                                        label={t("Worksite")}
                                        style={{
                                            width: 481,
                                            marginTop: 10
                                        }}
                                        select
                                        value={selectedJob.worksite || ""}
                                        onChange={event => this.handleWorksite(event)}
                                    >
                                        {!selectedJob.customer && <MenuItem value={"noCustomer"} key={"noCustomer"}>{t("No_customer")}</MenuItem>}
                                        {this.state.customerWorksites && this.state.customerWorksites.map(site => (
                                            <MenuItem value={site.id} key={site.id}>{site.name}</MenuItem>
                                        ))}
                                    </TextField>

                                    <br/>

                                    <div style={{height: 20, backgroundColor: '#ccc', width: 481, marginTop: 50}}>

                                    </div>

                                    <div style={{display: 'flex', width: 481}}>
                                        <div style={{flex: 1, height: 200, overflow: 'auto'}}>

                                            <Autocomplete
                                                items={this.state.employees}
                                                getItemValue={item => item.name}
                                                shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                                                renderMenu={children => (
                                                    <div style={{backgroundColor: '#FFF', position: 'absolute', opacity: 100, zIndex: 10, width: 230}}>
                                                        {children}
                                                    </div>
                                                )}
                                                renderItem={item =>
                                                    <div key={item.name}>
                                                        <MenuItem>{item.name}</MenuItem>
                                                        <Divider style={{backgroundColor: '#ccc', width: '97%', marginLeft: 10}}/>
                                                    </div>
                                                }
                                                renderInput={this.renderSearch}
                                                value={this.state.searchEmployee}
                                                onChange={event => this.setState({searchEmployee: event.target.value})}
                                                onSelect={event => this.employeeHandler(event, "add")}
                                            />

                                            {selectedJob.employees && selectedJob.employees.map(employee => (
                                                <div style={{display: 'flex', marginTop: 10, marginLeft: 10}} key={employee.id}>
                                                    <div style={{flex: 4, marginTop: 10}}>
                                                        {employee.name}
                                                    </div>
                                                    <div style={{flex: 1}}>
                                                        <IconButton onClick={() => this.employeeHandler(employee.name, "delete")}>
                                                            <Delete/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        <div style={{flex: 1, height: 200, overflow: 'auto'}}>
                                            <TextField
                                                value={""}
                                                variant="outlined"
                                                label={t("Tasks")}
                                                style={{width: 230, marginLeft: 10, marginTop: 10}}
                                                select
                                                onChange={event => this.taskHandler(event, "add")}
                                            >
                                                {!this.state.selectedModelObj.tasks && <MenuItem value={"noValue"}>No model selected</MenuItem>}
                                                {this.state.selectedModelObj.tasks && this.state.selectedModelObj.tasks.map(task => (
                                                    <MenuItem key={task.id} value={task.name}>{task.name}</MenuItem>
                                                ))}
                                            </TextField>

                                            {selectedJob.tasks && selectedJob.tasks.map(task => (
                                                <div style={{display: 'flex', marginTop: 10, marginLeft: 25}} key={task.name}>
                                                    <div style={{flex: 4, marginTop: 10}}>
                                                        {task.name}
                                                    </div>
                                                    <div style={{flex: 1}}>
                                                        <IconButton onClick={() => this.taskHandler(task.name, "delete")}>
                                                            <Delete />
                                                        </IconButton>
                                                    </div>

                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <br/>

                                    <TextField
                                        variant="outlined"
                                        label={t("Extra work")}
                                        style={{
                                            width: 481,
                                            marginTop: 20,
                                        }}
                                        value={this.state.extraTodoText || ""}
                                        onChange={event => this.setState({extraTodoText: event.target.value})}
                                        InputProps={{
                                            startAdornment: this.state.extraTodo.map(item => (
                                                <Chip
                                                    key={item}
                                                    label={item}
                                                    onDelete={() => this.chipDeleteHandler(item)}
                                                />
                                            )),
                                            onKeyDown: this.keyDownHandler
                                        }}
                                    />

                                    <br/>

                                    <TextField
                                        variant="outlined"
                                        label={t("Title")}
                                        style={{
                                            width: 481,
                                            marginTop: 20
                                        }}
                                        value={selectedJob.title || ""}
                                        onChange={event => this.handleChange(event, 'title')}
                                    />

                                    <br/>
                                    {this.props.editMode && <div style={{marginTop: 10, marginLeft: 20}}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.recurringRule === "once"}
                                                    onChange={() => this.setState({recurringRule: "once"})}
                                                    value="once"
                                                    color="primary"
                                                />
                                            }
                                            label={t("One time")}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.recurringRule === "onward"}
                                                    onChange={() => this.setState({recurringRule: "onward"})}
                                                    value="onward"
                                                    color="primary"
                                                />
                                            }
                                            label={t("This and onward")}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.recurringRule === "range"}
                                                    onChange={() => this.setState({recurringRule: "range"})}
                                                    value="range"
                                                    color="primary"
                                                />
                                            }
                                            label={t("Range")}
                                        />

                                        {this.state.recurringRule === "range" && <div>
                                            <Button onClick={() => this.setState({pickField: "recurringRange", selectType: "range", datePicker: true, value: this.state.rrStart})}>
                                                {this.state.rrStart === null && this.state.rrEnd === null && t("Select")}
                                                {this.state.rrStart !== null && this.state.rrEnd !== null && moment(this.state.rrStart).locale('fi').format('L') + ' - ' + moment(this.state.rrEnd).locale('fi').format('L')}
                                            </Button>
                                        </div>}

                                    </div>}

                                    <br/>

                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{
                                            marginTop: 20
                                        }}
                                        onClick={() => this.props.onClose()}
                                    >
                                        {t("Close")}
                                    </Button>

                                    {this.props.editMode && <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{
                                            marginTop: 20,
                                            marginLeft: 20
                                        }}
                                        onClick={() => this.editJobButton()}
                                    >
                                        {t("Save")}
                                    </Button>}

                                    {!this.props.editMode &&
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        style={{
                                            marginTop: 20,
                                            marginLeft: 20
                                        }}
                                        onClick={() => this.props.onAdd(this.state.selectedJob, this.state.createTemplate, this.state.selectedModelObj)}
                                    >
                                        {t("Save")}
                                    </Button>}

                                </div>




                                <div style={{flex: 1}}>

                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>

                    {this.state.repeats === "custom" &&
                    <RecurrenceDialog
                        selectedJob={this.state.selectedJob}
                        onChange={event => this.setState({selectedJob: event, repeats: "custom1"})}
                        onClose={() => this.setState({repeats: "noRepeat"})}
                    />}

                    {this.state.datePicker &&
                        <DateDialog
                            selectType={this.state.selectType}
                            onChange={event => this.handleDateChange(event, this.state.pickField)}
                            onClose={() => this.setState({datePicker: false})}
                            value={this.state.value}
                        />
                    }

                    {this.state.timePicker &&
                        <TimeDialog
                            onChange={event => this.handleTimeChange(event, this.state.pickField)}
                            onClose={() => this.setState({timePicker: false})}
                            value={this.state.value}
                        />
                    }

                    {this.state.err &&
                        <ErrorDialog
                            onClose={() => this.setState({err: false})}
                        />
                    }
                </div>
            );
        }

    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(FullscreenDialog));