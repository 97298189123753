import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    Button,
    Checkbox,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    TextField,
} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import moment from "moment-timezone";

import {withTranslation} from "react-i18next";

import DateDialog from './DateDialog';

class RecurrenceDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedJob: {},
            dialogOpen: false,
            repeatEnd: "never",
            repeatEndOcc: "",
            repeatEndDT: moment().endOf("day").toDate(),
            datePicker: false,
            value: "",
            daysArray: []
        };
    }

    componentWillMount() {
        const {selectedJob} = this.props;
        let day = moment(selectedJob.startDT).format('dd');

        let daysArray = [];
        if (!selectedJob.rrule.BYDAY) {
            daysArray = [day.toUpperCase()];
        }
        else {
            daysArray = [this.props.selectedJob.rrule.BYDAY];
        }

        if (selectedJob.rrule && selectedJob.rrule.INTERVAL === 0) selectedJob.rrule.INTERVAL = 1;


        this.setState({
            daysArray: daysArray,
            selectedJob,
            dialogOpen: true
        });
    }

    componentWillUnmount() {
        if (this.unsubscribeJobListener) {
            this.unsubscribeJobListener();
        }
    }


    changeHandler = (event, field) => {
        const {selectedJob} = this.state;

        selectedJob.rrule[field] = event.target.value;

        if (this.state.repeatEnd === "never") {
            delete selectedJob.rrule.UNTIL;
        }

        this.setState({
            selectedJob: selectedJob
        });
    };

    handleDateChange = (event) => {
        const repeatEndDT = moment(event.selection.startDate).endOf("day").toDate();

        this.setState({
            datePicker: false,
            repeatEndDT
        });
    };

    selectHandler = day => {
        let {daysArray} = this.state;

        if (!daysArray.includes(day)) {
            daysArray.push(day)
        }
        else {
            daysArray = daysArray.filter(item => item !== day)
        }

        this.setState({
            daysArray: daysArray
        });
    };

    confirmHandler = () => {
        const {selectedJob, daysArray, repeatEnd} = this.state;

        let byday = daysArray.join(',');

        if (daysArray.length > 1) {
            selectedJob.rrule.BYDAY = byday;
        }

        if (repeatEnd === "until") {
            selectedJob.rrule.UNTIL = moment(this.state.repeatEndDT).format('YYYYMMDDTHHmmss');
        }

        if (repeatEnd === "after") {
            selectedJob.rrule.COUNT = this.state.repeatEndOcc;
        }

        this.props.onChange(this.state.selectedJob);
    };

    render() {
        const {selectedJob} = this.state;
        const {t} = this.props;
        return (
            <div>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.props.onClose()}
                    maxWidth="xs"
                    fullWidth
                    disableEnforceFocus
                >
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={12} md={11} style={{marginTop: 20}}>
                                {t("Custom")}
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <IconButton onClick={() => this.props.onClose()}>
                                    <Close />
                                </IconButton>
                            </Grid>

                        </Grid>
                    </DialogTitle>

                    <DialogContent>
                        <div style={{display: "inline-block"}}>
                            <p style={{display: "inline-block"}}>{t("Repeats every")}</p>
                            <TextField
                                variant="outlined"
                                value={selectedJob.rrule.INTERVAL || ""}
                                onChange={event => this.changeHandler(event, 'INTERVAL')}
                                style={{
                                    marginTop: 9,
                                    width: 60,
                                    marginLeft: 10,
                                }}
                                type="number"
                                InputProps={{
                                    style: {height: 30}
                                }}
                            />

                            <TextField
                                variant="outlined"
                                value={selectedJob.rrule.FREQ || ""}
                                onChange={event => this.changeHandler(event, 'FREQ')}
                                style={{
                                    marginTop: 9,
                                    width: 100,
                                    marginLeft: 10,
                                }}
                                InputProps={{
                                    style: {height: 30}
                                }}
                                select
                            >

                                <MenuItem value={"DAILY"} key={"day"}>{(selectedJob.rrule.INTERVAL <= 1 ? t("day") : t("days"))}</MenuItem>
                                <MenuItem value={"WEEKLY"} key={"week"}>{(selectedJob.rrule.INTERVAL <= 1 ? t("week") : t("weeks"))}</MenuItem>
                                <MenuItem value={"MONTHLY"} key={"month"}>{(selectedJob.rrule.INTERVAL <= 1 ? t("month") : t("months"))}</MenuItem>

                            </TextField>
                        </div>

                        <p style={{fontWeight: 'bold'}}>{t("Repeat on")}</p>

                        <div style={{width: '85%', display: 'flex'}}>
                            <div style={{flex: 1, width: 30, height: 30, marginRight: 10, backgroundColor: this.state.daysArray.includes("MO") ? '#2B81C1' : '#ccc'}} onClick={() => this.selectHandler("MO")}>
                                <p style={{marginLeft: 8, marginTop: 8, color: this.state.daysArray.includes("MO") ? '#FFF' : '#000'}}>{t("Recurrence_Mo")}</p>
                            </div>

                            <div style={{flex: 1, width: 30, height: 30, marginRight: 10, backgroundColor: this.state.daysArray.includes("TU") ? '#2B81C1' : '#ccc'}} onClick={() => this.selectHandler("TU")}>
                                <p style={{marginLeft: 8, marginTop: 8, color: this.state.daysArray.includes("TU") ? '#FFF' : '#000'}}>{t("Recurrence_Tu")}</p>
                            </div>

                            <div style={{flex: 1, width: 30, height: 30, marginRight: 10, backgroundColor: this.state.daysArray.includes("WE") ? '#2B81C1' : '#ccc'}} onClick={() => this.selectHandler("WE")}>
                                <p style={{marginLeft: 8, marginTop: 8, color: this.state.daysArray.includes("WE") ? '#FFF' : '#000'}}>{t("Recurrence_We")}</p>
                            </div>

                            <div style={{flex: 1, width: 30, height: 30, marginRight: 10, backgroundColor: this.state.daysArray.includes("TH") ? '#2B81C1' : '#ccc'}} onClick={() => this.selectHandler("TH")}>
                                <p style={{marginLeft: 8, marginTop: 8, color: this.state.daysArray.includes("TH") ? '#FFF' : '#000'}}>{t("Recurrence_To")}</p>
                            </div>

                            <div style={{flex: 1, width: 30, height: 30, marginRight: 10, backgroundColor: this.state.daysArray.includes("FR") ? '#2B81C1' : '#ccc'}} onClick={() => this.selectHandler("FR")}>
                                <p style={{marginLeft: 8, marginTop: 8, color: this.state.daysArray.includes("FR") ? '#FFF' : '#000'}}>{t("Recurrence_Fr")}</p>
                            </div>

                            <div style={{flex: 1, width: 30, height: 30, marginRight: 10, backgroundColor: this.state.daysArray.includes("SA") ? '#2B81C1' : '#ccc'}} onClick={() => this.selectHandler("SA")}>
                                <p style={{marginLeft: 8, marginTop: 8, color: this.state.daysArray.includes("SA") ? '#FFF' : '#000'}}>{t("Recurrence_Sa")}</p>
                            </div>

                            <div style={{flex: 1, width: 30, height: 30, marginRight: 10, backgroundColor: this.state.daysArray.includes("SU") ? '#2B81C1' : '#ccc'}} onClick={() => this.selectHandler("SU")}>
                                <p style={{marginLeft: 8, marginTop: 8, color: this.state.daysArray.includes("SU") ? '#FFF' : '#000'}}>{t("Recurrence_Su")}</p>
                            </div>
                        </div>

                        <p style={{fontWeight: 'bold'}}>End</p>

                        <div>
                            <FormControlLabel
                                style={{width: 300}}
                                control={
                                    <Checkbox
                                        checked={this.state.repeatEnd === "never"}
                                        onChange={() => this.setState({repeatEnd: "never"})}
                                        value="never"
                                        color="primary"
                                    />
                                }
                                label={t("Never")}
                            />

                            <div style={{display: 'flex'}}>
                                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <FormControlLabel
                                        style={{ width: 100}}
                                        control={
                                            <Checkbox
                                                checked={this.state.repeatEnd === "until"}
                                                onChange={() => this.setState({repeatEnd: 'until'})}
                                                value="until"
                                                color="primary"
                                            />
                                        }
                                        label={t("Until")}
                                    />
                                </div>

                                <div style={{flexGrow: 1}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <Button onClick={() => this.setState({value: this.state.repeatEndDT, datePicker: true})}>
                                            {moment(this.state.repeatEndDT).locale('fi').format('L')}
                                        </Button>
                                    </div>
                                </div>
                            </div>



                            <div style={{display: 'flex'}}>
                                <div style={{display: 'flex', justifyContent: 'flex-start', width: 150}}>
                                    <FormControlLabel
                                        style={{ width: '100%'}}
                                        control={
                                            <Checkbox
                                                checked={this.state.repeatEnd === "after"}
                                                onChange={() => this.setState({repeatEnd: "after"})}
                                                value="after"
                                                color="primary"
                                            />
                                        }
                                        label={t("Repeat_Occ")}
                                    />
                                </div>

                                <div style={{flexGrow: 1}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <TextField
                                            type="number"
                                            value={this.state.repeatEndOcc || ""}
                                            onChange={event => this.setState({repeatEndOcc: parseInt(event.target.value)})}
                                            style={{
                                                width: 40,
                                                display: "inline-block"
                                            }}
                                        />
                                        <p style={{display: "inline-block", marginLeft: 20}}>{t("occurrences")}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => this.props.onClose()}
                        >
                            {t("Cancel")}
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => this.confirmHandler()}
                        >
                            {t("Save")}
                        </Button>
                    </DialogActions>
                </Dialog>

                {this.state.datePicker &&
                    <DateDialog
                        open={this.state.datePicker}
                        onChange={event => this.handleDateChange(event)}
                        onClose={() => this.setState({datePicker: false})}
                        value={this.state.value}
                        selectType="single"
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(RecurrenceDialog));