import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Button, Dialog, DialogActions, DialogTitle} from "@material-ui/core";
import {DateRange} from "react-date-range";
import { fi, enGB} from 'date-fns/locale'
import {withTranslation} from "react-i18next";
import moment from 'moment-timezone';

class DateDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dateRange: {
                selection: {
                    startDate: moment().toDate(),
                    endDate: moment().toDate()
                }
            },
            dialogOpen: true
        };
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({
                dateRange: {
                    selection: {
                        startDate: this.props.value,
                        endDate: this.props.value
                    }
                }
            });
        }
    }


    handleDateChange = event => {
        this.setState({
            dateRange: {
                selection: {
                    startDate: event.range1.startDate,
                    endDate: event.range1.endDate
                }
            }
        });
    };

    handleDateConfirm = () => {
        this.props.onChange(this.state.dateRange);
    };

    render() {
        const {dateRange} = this.state;
        const {user} = this.props.user;
        const {t}= this.props;
        let locale = "";
        if (user.lang && user.lang === "en") {
            locale = enGB;
        } else {
            locale = fi
        }

        if (this.props.selectType === "date") {
            return (
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.props.onClose()}
                >
                    <DateRange
                        ranges={[dateRange.selection]}
                        onChange={event => this.handleDateChange(event)}
                        locale={locale}
                        moveRangeOnFirstSelection={true}
                        showDateDisplay={false}
                        preview={{
                            startDate: dateRange.selection.startDate,
                            endDate: dateRange.selection.endDate,
                            color: '#FFF'
                        }}

                    />

                    <DialogActions>
                        <Button onClick={() => this.props.onClose()}>
                            {t("Cancel")}
                        </Button>

                        <Button
                            onClick={() => this.handleDateConfirm()}
                        >
                            {t("Save")}
                        </Button>
                    </DialogActions>

                </Dialog>
            );
        }
        if (this.props.selectType === "range") {
            return (
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.props.onClose()}
                >
                    <DialogTitle>Select range</DialogTitle>
                    <DateRange
                        ranges={[dateRange.selection]}
                        onChange={event => this.handleDateChange(event)}
                        locale={locale}
                        moveRangeOnFirstSelection={false}
                    />
                    <DialogActions style={{marginRight: 70}}>
                        <Button onClick={() => this.props.onClose()}>
                            {t("Cancel")}
                        </Button>

                        <Button onClick={() => this.handleDateConfirm()}>
                            {t("Select")}
                        </Button>
                    </DialogActions>
                </Dialog>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(DateDialog));