import {GET_USER, USER_LOADING, COLLAPSE_MENU, RESET_USER} from "./types";
import firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/auth";


export const getUser2 = employee => dispatch => {
    dispatch({
        type: GET_USER,
        payload: employee,
        id: employee.id
    })
};

export const getUser = () => dispatch => {
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            firebase.firestore().collection('employees').doc(user.uid).onSnapshot(docSnapshot => dispatch({
                type: GET_USER,
                payload: docSnapshot.data(),
                id: user.uid,
            }))
        }
    })
};

export const resetCurrentUser = () => dispatch => {
    dispatch({
        type: RESET_USER
    });
};

export const collapseMenu = () => {
    return {
        type: COLLAPSE_MENU
    }
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING
    }
};
