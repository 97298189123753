import React from 'react';

import {withTranslation} from "react-i18next";

import "./BillDetailsListItem.css";
const BillDetailsListItem = props => {


    return (
        <div className={"information-container2"} style={{...props.style}}>
            <div className={"field-centering-left"}>
                {props.left}
            </div>

            <div className={"field-centering-right"}>
                {props.right}
            </div>
        </div>
    )
};


export default withTranslation()(BillDetailsListItem);


