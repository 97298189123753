import keys from '../../config/keys';
import axios from 'axios';
import {formFinvoice, formEmail, formTalenomFinvoiceAPI, formTalenom, formNetvisorFinvoiceAPI} from "./InvoiceObjectForming";
import {base64StringToBlob} from "blob-util";

export const authenticateSender = async () => {

    const authUrl = "https://icf.heinolasoftwares.fi/auth";

    const authObject = {
        username: keys.ICF.user,
        password: keys.ICF.pass
    };

    const authConfig = {
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
        }
    };

    const res = await axios.post(authUrl, authObject, authConfig);

    return new Promise((resolve, reject) => {
        if (res.data) resolve(res.data.token);
        else reject();
    })
};


export const formInvoiceAndUrl = async (bill, user, company, attachmentName, sendAttachment) => {
    let invoice = {};
    let serverUrl = "";

    console.log("formInvoiceAndUrl with attacmentName ", attachmentName)
    if (bill.sendingMethod.sending === "email") {
        invoice = formEmail(bill, user, company, attachmentName, sendAttachment);
        serverUrl = "https://icf.heinolasoftwares.fi/email";
    }
    else if (bill.sendingMethod.sending === "talenom") {
        //invoice = formTalenom(bill, user, company);
        invoice = formTalenomFinvoiceAPI(bill, user, company, attachmentName);
        serverUrl = "https://icf.heinolasoftwares.fi/talenom/newInvoice";
    }
    else if (bill.sendingMethod.sending === "netvisor") {
        //invoice = formTalenom(bill, user, company);
        invoice = formNetvisorFinvoiceAPI(bill, user, company, attachmentName);
        serverUrl = "https://icf.heinolasoftwares.fi/netvisor/salesInvoice";
    }
    else if (bill.sendingMethod.sending === "apix" || bill.sendingMethod.sending === "email / apix") {
        /* NOTE if running locally, change this to development, else production*/
        /* if (process.env.NODE_ENV === "production") { */
        /*if (process.env.NODE_ENV === "development") {
            invoice = formApix2(bill, user, company, attachmentName);
            serverUrl = "https://icf.heinolasoftwares.fi/apix/ICF";
        }
        else {
            invoice = formApix2(bill, user, company, attachmentName);
            serverUrl = "https://icf.heinolasoftwares.fi:4433/apix/ICFTest"
        }*/
        //always send to production
        invoice = formFinvoice("apix", bill, user, company, attachmentName);
        serverUrl = "https://icf.heinolasoftwares.fi/apix/ICF";

        // If not sending with attachment
        if (!sendAttachment) {
            // Find default pdf and remove it
            const removeObj = invoice.apix.InvoiceUrlTexts.find(val => val.value.search(".zip") > -1);
            const removeObjIndex = invoice.apix.InvoiceUrlTexts.indexOf(removeObj);
            invoice.apix.InvoiceUrlTexts.splice(removeObjIndex, 1);

            //Remove APIX_ATTACHMENT
            const removeTextIndex = invoice.apix.InvoiceUrlNameTexts.indexOf("APIX_ATTACHMENT");
            invoice.apix.InvoiceUrlNameTexts.splice(removeTextIndex, 1);
        }

    }

    return new Promise(resolve => {
        const returnObject = {
            invoice: invoice,
            serverUrl: serverUrl
        };
        resolve(returnObject);
    })
};


export const sendWithGeneratedAttachment = async (attachmentUrl, attachmentName, invoice, serverUrl, authToken) => {
    console.log("Sending option with generated attachment", attachmentName);
    const removeStr = "data:application/pdf;base64,";
    const url = attachmentUrl.replace(removeStr, "");

    // Change attachmentUrl to blob
    const dataType = "application/pdf";
    const blob = base64StringToBlob(url, dataType);
    const blobName = attachmentName + ".pdf";
    const formData = new FormData();
    formData.append("file", blob);
    formData.append("name", blobName);


    const uploadUrl = "https://icf.heinolasoftwares.fi/uploadBlob";
    const config =  {
        headers : {
            "Content-Type": "multipart/form-data",
            'Access-Control-Allow-Origin': '*',
            "x-access-token": authToken
        }
    };

    const serverConfig =  {
        headers : {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            "x-access-token": authToken
        }
    };

    let attachmentRes = {};
    let invoiceRes = {};
    let errors = {};

    // Upload PDF
    console.log("Uploading PDF, with name: " + blobName, blob, url);
    console.log("Uploading PDF, with headers: ", config.headers);
    attachmentRes = await axios.post(uploadUrl, formData, config)
        .catch(err => {
            console.log("Error while uploading PDF ", err);
            //console.log(err);
            //console.log(err);
            errors = err.response
        });


    console.log("Sending to server...", serverUrl, serverConfig);
    console.log("INVOICE", invoice);
    //SEND INVOICE TO SERVER
    if (attachmentRes) {
        invoiceRes = await axios.post(serverUrl, invoice, serverConfig)
            .catch(err => {
                console.log("Error while sending to server:", err);
                errors = err
            });
    }


    return new Promise((resolve, reject) => {
        if (attachmentRes && invoiceRes) resolve("OK");
        if (errors) reject(errors)
    });
};

export const sendWithCustomOrWithout = async (uploadedAttachmentName, invoice, serverUrl, authToken) => {
    if (uploadedAttachmentName) console.log("Sending method with custom attachment")
    else console.log("Sending method without attachment");

    const serverConfig =  {
        headers : {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            "x-access-token": authToken
        }
    };


    let errors = {};
    console.log("Sending to server...", serverUrl, serverConfig);
    console.log("INVOICE", invoice);

    let response = await axios.post(serverUrl, invoice, serverConfig)
        .catch(err => {
            console.log("Error while sending to server");
            errors = err.response;

        });

    return new Promise((resolve, reject) => {
        if (response) resolve(response);
        else reject(errors);
    })
};
