import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';

import {Scheduler} from 'scheduler';
import MobilityBookingCalendarAdapter from './MobilityBookingCalendarAdapter';

import * as moment from 'moment';

import {getOccurrences} from "../CalendarComponents/OccurrencesComponent";
import CalendarConstraint from "scheduler/lib/constraints/CalendarConstraint";
import CalendarAdapter from "scheduler/lib/adapters/CalendarAdapter";
import TimeRange from "scheduler/lib/TimeRange";
import {RecurrenceFrequency} from "scheduler/lib/SchedulingTypes";
import TestCalendarAdapter from "scheduler/lib/adapters/TestCalendarAdapter";
import RecurrentTestCalendarAdapter from "scheduler/lib/adapters/RecurrentTestCalendarAdapter";


class SchedulerComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    componentDidMount() {
        const {user} = this.props.user;
        this.unsubscribeJobListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('jobs').onSnapshot(this.onJobSnapshot);
    }

    onJobSnapshot = querySnapshot => {
        let events = [];
        const startMoment = moment("20190128T000000");
        const endMoment = moment("20190203T000000");


        querySnapshot.forEach(doc => {
            let job = doc.data();
            job.id = doc.id;

            let newArr = getOccurrences(job, startMoment, endMoment);
            events = events.concat(newArr)
        });

        this.scheduleTester(events, startMoment, endMoment);
    };

    componentWillUnmount() {
        this.unsubscribeJobListener && this.unsubscribeJobListener();
    }

    scheduleTester = (events, starMoment, endMoment) => {


        const eventArr = [];
        events.forEach(event => {
            event.start = moment(event.start).format('YYYYMMDDTHHmmss');
            event.end = moment(event.end).format('YYYYMMDDTHHmmss');
            eventArr.push(event);
        });

        const startDT = moment('20190128T080000');

        const avoidBookingsCalendar = new RecurrentTestCalendarAdapter(
            startDT, moment.duration(2, "hours"),
            {frequency: RecurrenceFrequency.Daily}
        );


        const avoidMorningsCalendar = new RecurrentTestCalendarAdapter(
            moment('20180101T000000'), moment.duration(8, 'hours'),
            {frequency: RecurrenceFrequency.Daily}
        );


        const scheduler = new Scheduler()

            .setTimeout(8000)

            .setMaxIterations(100000)

            .setSolutionNumber(100)

            .setSchedulingInterval(moment.duration(15, "minutes"))

            .setDebugOutputFunction(console.log)

            .addConstraint(new CalendarConstraint(avoidBookingsCalendar, {applyInside: true, unitCost: 100 }))

            .addConstraint(new CalendarConstraint(avoidMorningsCalendar, {applyInside: true, unitCost: 500 }));

        const params = {

            from: starMoment,
            to: endMoment,
            duration: moment.duration(2, "hour")
        };

        const result = scheduler.schedule(params)
            .then(res => {
                console.log(res)
            })
            .catch(err => console.log(err));



        const { minCost, maxCost} = result.reduce(({minCost, maxCost }, solution) => {
            return {
                minCost: Math.min(solution.score, minCost),
                maxCost: Math.max(solution.score, maxCost)
            }
        }, {minCost: Number.MAX_SAFE_INTEGER, maxCost: 0});

        console.log(`Results ${result.length} between ${minCost} and ${maxCost}`)

    };

    render() {
        return (
            <div style={{width: '100%'}}>
                <h1>Scheduler page</h1>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(SchedulerComponent);