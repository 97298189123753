import React, { Component } from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { Button, FormControlLabel, Checkbox } from '@material-ui/core';

import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";

import { connect } from 'react-redux';

import { saveSend } from "../../actions/sendapprovalActions";

import { withTranslation } from "react-i18next";
import { createReport, uploadToFirebaseStorage } from "../reportStoreFunctions";    
import { isEmpty } from "../../validations/isEmpty";
import ReportTypes from "../ReportTypes";

class PDFCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            docRef: {},
            looping: false
        };
    }

    saveToRedux = (pdf) => {
        const customer = this.props.customers.find(item => item.name === this.props.customer);

        this.props.saveSend(customer, this.props.selectedLogs, this.props.company, pdf)
    };

    roundNumber = d => {
        return parseFloat(d).toFixed(2)
    };

    getEmployee = id => {
        const { employees } = this.props;
        const { t } = this.props;

        if (id && employees) {
            const employee = employees.find(employee => employee.id === id);
            return (employee && employee.name ? employee.name : t("No employee"));
        } else {
            return t("No employee");
        }
    };

    getWorkSite = id => {
        const { worksites } = this.props;
        const { t } = this.props;

        if (id && worksites) {
            const worksite = worksites.find(worksite => worksite.id === id);
            return (worksite && worksite.name ? worksite.name : t("No_worksite"));
        } else {
            return t("No_worksite");
        }
    }

    createPdf = worklogs => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { t } = this.props;
        const { docRef } = this.state;
        const ref = {};
        const { user } = this.props.user;
        const { products } = this.props.products;
        const { customer, dateRange } = this.props;
        const body = [];


        console.log("createPdf this.props ", this.props);

        let reportSummary = {
            billableHours: 0,
            materials: [],
            totalMaterials: [],
        };

        const sortedWorklogs = worklogs.sort((a, b) => {
            if (moment(a.startDT).isBefore(b.startDT)) return -1;
            if (moment(a.startDT).isAfter(b.startDT)) return 1;
        });

        const worklogRows = [];

        sortedWorklogs.forEach(worklog => {

            //console.log("createPdf worklog ", worklog);
            if (worklog.previousStatus !== "Cancelled") {
                worklog.employee.forEach(employee => {
                    if (worklog.billing && worklog.billing.billableHoursByTask) {
                        reportSummary.billableHours += worklog.billing.billableHours;
                        worklog.billing.billableHoursByTask && worklog.billing.billableHoursByTask.forEach(task => {
                            const product = products.find(product => product.pNumber === task.pNumber);

                            //const invoiceText = product && product.invoiceText ? product.invoiceText : task.name;
                            const row1 = [
                                {
                                    text: moment(worklog.startDT).locale("fi").format("L"),
                                    alignment: "center",
                                    fontSize: 10
                                },
                                { text: this.getWorkSite(worklog.worksite), alignment: "center", fontSize: 10 },
                                { text: this.getEmployee(employee), alignment: "center", fontSize: 10 },
                                { text: task.name, alignment: "center", fontSize: 10 },
                                { text: this.roundNumber(task.duration / 3600000), alignment: "right", fontSize: 10 },
                                { text: "h", alignment: 'left', fontSize: 10 },
                            ];
                            worklogRows.push(row1)
                        });
                    }
                    /* else {
                                           const row1 = [
                                               {
                                                   text: moment(worklog.startDT).locale("fi").format("L"),
                                                   alignment: "center",
                                                   fontSize: 10
                                               },
                                               {text: this.getEmployee(employee), alignment: "center", fontSize: 10},
                                               {text: "NO BILLABLE TIME PER TASK SET", alignment: "center", fontSize: 10},
                                               {text: "NO BILLABLE TIME PER TASK SET", alignment: "center", fontSize: 10},
                                           ];
                                           worklogRows.push(row1)
                                       }*/
                });
            }
            if (worklog.previousStatus === "Cancelled" && worklog.billing && worklog.billing.billableHoursByTask) {
                const templateTask = worklog.billing.billableHoursByTask[0];

                const row1 = [
                    { text: moment(worklog.startDT).locale("fi").format("L"), alignment: "center", fontSize: 10 },
                    { text: this.getWorkSite(worklog.worksite), alignment: "center", fontSize: 10 },
                    { text: " - ", alignment: "center", fontSize: 10 },
                    { text: templateTask.name, alignment: "center", fontSize: 10 },
                    {
                        text: this.roundNumber((templateTask.duration / 3600000) * worklog.employee.length),
                        alignment: "right",
                        fontSize: 10
                    },
                    { text: "", alignment: 'left', fontSize: 10 },

                ];

                worklogRows.push(row1);
            }

            worklog.materials && worklog.materials.forEach(material => {

                //const templateTask = template && template.tasks.find(tempTask => tempTask.pNumber === material.pNumber);
                const product = products.find(product => product.pNumber === material.pNumber);

                const invoiceText = product && product.invoiceText ? product.invoiceText : material.name;

                const row = [
                    { text: moment(worklog.startDT).locale("fi").format("L"), alignment: "center", fontSize: 10 },
                    { text: this.getWorkSite(worklog.worksite), alignment: "center", fontSize: 10 },
                    { text: " - ", alignment: 'center', fontSize: 10 },
                    { text: invoiceText, alignment: "center", fontSize: 10 },
                    { text: this.roundNumber(material.amount), alignment: 'center', fontSize: 10 },
                    { text: material.unit, alignment: 'left', fontSize: 10 },
                ];
                reportSummary.materials.push(material);
                worklogRows.push(row);
            })
        });

        const workLogTable = {
            fontSize: 10,
            margin: [10, 0, 0, 0],
            table: {
                headerRows: 0,
                widths: [70, 140, 100, 140, 40, 30],
                body: worklogRows
            },
            layout: "noBorders",
        };

        //docRef.content.push(rows);
        docRef.content.push(workLogTable);
        console.log("createPdf docRef ", docRef);

        const summary = this.genereateSummary(reportSummary);
        docRef.content.push(summary);

        //pdfMake.createPdf(docRef).download();
        if (this.props.openInNewTab) pdfMake.createPdf(docRef).open();

        pdfMake.createPdf(docRef).getBase64((dataUrl) => {
            console.log("storeReport dataUrl: ", dataUrl);

            const reportOwner = !isEmpty(customer) ? customer.name : '';
            //this.saveToRedux(dataUrl);

            const recipients = [customer.email];


            //TODO, other default address
            const fileName = t(ReportTypes.CUSTOMER_TIME) + '_' + reportOwner + "_" + t('Between') + ' ' + dateRange[0] + '-' + dateRange[1];

            uploadToFirebaseStorage(user, dataUrl, ReportTypes.CUSTOMER_TIME).then(filestorageSuccess => {
                console.log('succesfully writed pdf to storage', filestorageSuccess)

                createReport(user, ReportTypes.CUSTOMER_TIME, reportOwner, recipients, "email", fileName, dataUrl, filestorageSuccess)
                    .then((report) => {
                        console.log("storeReport success: ", report);
                        this.props.onReportsCreated(true);
                    })
                    .catch((err) => {
                        console.log("storeReport ERROR: ", err);
                        this.props.onReportsCreated(false);
                    })
            });
        });

    };

    genereateSummary = reportSummary => {
        const { t } = this.props;
        const summary = {
            margin: [20, 30, 20, 10],
            table: {
                headerRows: 1,
                widths: ['*', 35, 50],
                body: [
                    ['', '', { text: t('Total'), alignment: "right" }],
                    ['', { text: t('Amount'), alignment: "right" }, { text: t('Unit'), alignment: "right" }],
                    ['', {
                        text: this.roundNumber(reportSummary.billableHours / 3600000),
                        alignment: "right",
                        fontSize: 10
                    }, { text: 'h', alignment: "right" }]
                ],
                alignment: "right",
            },
            layout: "headerLineOnly"
        };

        console.log("createPdf reportSummary materials", reportSummary.materials);
        if (reportSummary.materials) {
            reportSummary.materials.forEach(material => {
                if (material.pNumber) {
                    if (!reportSummary.totalMaterials[material.pNumber]) {
                        reportSummary.totalMaterials[material.pNumber] = {
                            totalAmount: material.amount,
                            name: material.name,
                            unit: material.unit
                        }
                    } else {
                        reportSummary.totalMaterials[material.pNumber].totalAmount += material.amount; //parseFloat(material.amount)
                    }
                } else {
                    console.log("material does not have unit ", material)
                }
            })


            reportSummary.totalMaterials.forEach(material => {
                const row = [{ text: material.name, alignment: "right", fontSize: 10 }, {
                    text: material.totalAmount,
                    alignment: "right"
                }, { text: material.unit, alignment: "right" }]
                summary.table.body.push(row);
                console.log("add materiatype row", row);
            })

        }

        console.log("return summary ", summary);


        return summary;
    }

    generateHeader = worklogs => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { company, customer, dateRange, t } = this.props;
        let headerSize = 14, normalText = 10;
        const body = [];

        this.props.onReportCreate();

        console.log("generateHeader this.props ", this.props);
        console.log("generateHeader dateRange ", dateRange);

        const docRef = {
            content: [
                {
                    margin: [0, 0, 0, 0],
                    table: {
                        headerRows: 1,
                        widths: ['*', '*'],
                        body: [
                            [
                                { text: company.name, alignment: 'left', style: "subheader" },
                                { text: customer.name, alignment: 'right', style: "subheader" },

                            ],
                            [
                                { text: company.streetAddress, alignment: 'left' },
                                { text: customer.streetAddress, alignment: 'right' },
                            ],
                            [
                                { text: company.city + ' ' + company.postCode, alignment: 'left' },
                                { text: customer.city + ' ' + customer.postCode, alignment: 'right' },
                            ]
                        ]
                    },
                    layout: "noBorders",
                },
                [""],
                { text: t("Customer report period") },
                {
                    text: moment(dateRange.selection.startDate).format('L') + ' - ' + moment(dateRange.selection.endDate).format('L'),
                    alignment: 'left'
                },
                {
                    margin: [20, 30, 20, 10],
                    table: {
                        headerRows: 1,
                        widths: [80, 120, 80, 100, 35, 45],
                        body: [
                            [t('Date'), t('Worksite'), t('Employee'), t('Done job'), t('Amount'), t('Unit')],
                        ]
                    },
                    layout: "headerLineOnly"
                }
            ],
            pageMargins: [20, 10, 20, 10],
            styles: {
                subheader: {
                    fontSize: 15,
                    bold: true
                }
            }
        };

        this.setState({
            docRef: docRef
        }, () => this.createPdf(worklogs));

        /*
        setTimeout(() => {
            this.createPdf(worklogs)
        }, 100);*/
    };

    render() {
        return (
            <div>
                <Button
                    variant="outlined"
                    style={{
                        width: 210,
                        marginLeft: 50,
                        marginTop: 10
                    }}
                    onClick={() => this.generateHeader(this.props.selectedLogs)}
                >
                    {this.props.t("Generate_PDF")}
                </Button>
                <FormControlLabel
                    style={{
                        marginLeft: 75,
                        marginTop: 10,
                    }}
                    control={
                        <Checkbox
                            checked={this.props.openInNewTab}
                            onChange={(event) => this.props.onChangeOpenInNewTab(event.target.checked)}
                            value={"checkOpenOInNewTab"}
                            color="primary"
                        />
                    }
                    label={this.props.t("Open in new tab")}
                />
                <div style={{
                    marginLeft: 75,
                    marginTop: 10,
                }}>{this.props.t("Open in new tab tooltip")}</div>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    user: state.user,
    products: state.products
});

export default connect(mapStateToProps, { saveSend })(withTranslation()(PDFCustomer));