import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel} from "@material-ui/core";
import moment from "moment-timezone";

import {withTranslation} from "react-i18next";

import DateDialog from './DateDialog';

class EditDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datePicker: false
        }
    }


    handleDateChange = event => {

        this.props.onDateChange(event, "recurringRange");

        this.setState({
            datePicker: false,
        });
    };

    getTitleText = (mode, selectedJob) => {
        const interval = selectedJob && selectedJob.rrule && selectedJob.rrule.INTERVAL ?  selectedJob.rrule.INTERVAL : 0;

        let title = "";

        if (interval === 0 && !mode) { title = "Edit event"}
        if (interval === 0 && mode) { title = "Delete event"}
        if (interval > 0 && !mode) { title = "Edit recurring event"}
        if (interval > 0 && mode) { title = "Delete recurring event"}
        return title;

    };

    render() {
        const {recurringRule, rrStart, rrEnd, selectedJob, t } = this.props;
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={() => this.props.onClose()}
                    maxWidth="sm"
                    fullWidth
                    disableEnforceFocus
                >
                    <DialogTitle>{t(this.getTitleText(this.props.deleteMode, selectedJob))}</DialogTitle>

                    <DialogContent>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={recurringRule === "once"}
                                    onChange={() => this.props.onChange("once")}
                                    value="once"
                                    color="primary"
                                />
                            }
                            label={t("One time")}
                        />
                        <br/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={recurringRule === "onward"}
                                    onChange={() => this.props.onChange("onward")}
                                    value="onward"
                                    color="primary"
                                />
                            }
                            label={selectedJob && selectedJob.inComingChanges && selectedJob.inComingChanges.length > 0 && 
                                selectedJob.inComingChanges.some(change => moment.tz(change.replaceDT ,selectedJob.TZ).isAfter(moment.tz(selectedJob.startDT,selectedJob.TZ))) ?
                                t("This and onward preserve incoming") : t("This and onward")}
                        />

                        {selectedJob && selectedJob.inComingChanges && selectedJob.inComingChanges.length > 0 &&
                        selectedJob.inComingChanges.some(change => moment.tz(change.replaceDT ,selectedJob.TZ).isAfter(moment.tz(selectedJob.startDT,selectedJob.TZ)))  &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={recurringRule === "onward_override"}
                                    onChange={() => this.props.onChange("onward_override")}
                                    value="onward_override"
                                    color="primary"
                                />
                            }
                            label={t("This and onward override")}
                        />
                        }
                       
                        <br/>

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={recurringRule === "range"}
                                    onChange={() => this.props.onChange("range")}
                                    value="range"
                                    color="primary"
                                />
                            }
                            label={t("Range")}
                        />
                        <br/>

                        {selectedJob?.inComingChanges && selectedJob.inComingChanges.length > 0 &&
                            selectedJob.inComingChanges.some(change => moment.tz(change.replaceDT ,selectedJob.TZ).isAfter(moment.tz(selectedJob.startDT,selectedJob.TZ))) &&
                            <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("Notice_changes_in_future")}</p>
                        }

                        {selectedJob && selectedJob?.disableDT &&
                             <p style={{ margin: 0, paddingTop: 12, paddingBottom: 15 }}>{t("Notice_disabled_future_pre")} 
                                {moment(selectedJob.disableDT).format("L")} {t("Notice_disabled_future_post") }  
                            </p>
                        }
                        
                        {this.props.deleteMode &&
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={recurringRule === "all"}
                                    onChange={() => this.props.onChange("all")}
                                    value="all"
                                    color="primary"
                                />
                            }
                            label={t("Delete all events")}
                        />
                        }
                        <br/>
                        {recurringRule === "range" && <div>
                            <Button onClick={() => this.setState({datePicker: true})}>
                                {rrStart === null && rrEnd === null && t("Select")}
                                {rrStart !== null && rrEnd !== null && moment(rrStart).locale('fi').format('L') + ' - ' + moment(rrEnd).locale('fi').format('L')}
                            </Button>
                        </div>}

                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.props.onClose()}
                        >
                            {t("Cancel")}
                        </Button>

                        {!this.props.deleteMode && <Button
                            variant="outlined"
                            color="primary"
                            style={{
                                marginLeft: 10,
                                marginRight: 50
                            }}
                            onClick={() => this.props.onSave()}
                        >
                            {t("Save")}
                        </Button>}

                        {this.props.deleteMode && <Button
                            variant="outlined"
                            color="primary"
                            style={{
                                marginLeft: 10,
                                marginRight: 50
                            }}
                            onClick={() => this.props.onDelete()}
                        >
                            {t("Save")}
                        </Button>}
                    </DialogActions>
                </Dialog>

                {this.state.datePicker &&
                <DateDialog
                    open={this.state.datePicker}
                    selectType="range"
                    start={this.props.rrStart}
                    end={this.props.rrEnd}
                    onClose={() => this.setState({datePicker: false})}
                    onChange={event => this.handleDateChange(event)}
                />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(EditDialog));