
import {GET_WORKLOGS} from "../actions/types";

const initialState = {
    loading: true,
    worklogs: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_WORKLOGS:
            return {
                worklogs: action.payload,
                loading: false
            };
        default:
            return state;
    }
}
