import React, {useState} from 'react';


import {ClickAwayListener} from "@material-ui/core";
import PropTypes from 'prop-types';
import "./WorklogAC.css";
const WorklogAC = props => {
    const {searchData, listMaxLength, searchValues, listShowField, value, fieldName, placeholder} = props;

    const [menuOpen, setMenuOpen] = useState(null);
    const [searchList, setSearchList] = useState([]);

    const openMenu = event => {
        const current = event.currentTarget;
        setMenuOpen(current.getBoundingClientRect())
    };

    const onSearch = event => {
        const newSearchList = [];
        const searchValue = event.target.value.toLowerCase();
        searchData.forEach(item => {
            const valueToSearch = searchValues.map(value => item[value]).join("");

            if (valueToSearch.toLowerCase().search(searchValue) > -1) newSearchList.push(item)
        });


        setSearchList(newSearchList);
        props.onChange(event.target.value);
    };

    const onValueSelect = item => {

        props.onSelect(item, fieldName);
        // Set timeout because openMenu gets triggered at the same time causing menu to stay open
        setTimeout(() => {
            setMenuOpen(null);
        }, 20);
    };

    return (
        <div className="ac-container" onClick={openMenu} style={props.style}>
            <input placeholder={placeholder} type="text" className="ac-input" value={value} onChange={event => onSearch(event)} />

            {menuOpen !== null && searchList.length > 0 &&
                <ClickAwayListener onClickAway={() => setMenuOpen(null)}>
                    <div className="ac-menu" style={{top: menuOpen.top + menuOpen.height, left: menuOpen.left, width: menuOpen.width}}>
                        {searchList && searchList.map(item => (
                            <div className="ac-listItem" key={item.id} onClick={() => onValueSelect(item)}><p>{item[listShowField]}</p></div>
                        ))}
                    </div>
                </ClickAwayListener>
            }
        </div>
    )
};

WorklogAC.propTypes = {
    searchData: PropTypes.array,
    listMaxLength: PropTypes.number,
    searchValues: PropTypes.array,
    listShowField: PropTypes.string.isRequired,
    onSelect: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.any,
    fieldName: PropTypes.string,
    placeholder: PropTypes.string
};

export default WorklogAC;
