import React, {useState} from 'react';

import {
    TextField,
    MenuItem, Button,
    ClickAwayListener,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core';

import AutocompleteReports from '../AutocompleteReports';
import {withTranslation} from 'react-i18next';
import {DateRange} from 'react-date-range';
import { fi, enGB} from 'date-fns/locale'

import moment from 'moment';
import {connect} from 'react-redux';

const CustomerComponent = props => {
    const {t, i18n} = props;
    const {user} = props.user;

    const [menu, setMenu] = useState(false);
    const [anchor, setAnchor] = useState(null);

    const handleMenu = event => {
        setMenu(true);
        setAnchor(event.getBoundingClientRect());
    };

    const closeMenu = () => {
        setMenu(false);
        setAnchor(null)
    };

    const handleDate = event => {
        console.log("handleDate ", event)
        props.onChange(event, 'date');

        closeMenu();
    };


    return (
        <div>
            <TextField
                variant="outlined"
                label={t("Report type")}
                style={{
                    width: '100%'
                }}
                value={props.reportType}
                onChange={event => props.onChange(event, 'reportType')}
                select
            >
                <MenuItem key="select" value="select">{t("Select type")}</MenuItem>
                /*<MenuItem key="period" value="period">{t("Period bill report")}</MenuItem>*/
                <MenuItem key="ktv" value="ktv">{t("Annual ktv bill report")}</MenuItem>

            </TextField>

            <AutocompleteReports
                searchArray={props.customers}
                field="Customer"
                label={t("Search customers")}
                onSelect={event => props.onChange(event, 'customer')}

            />

            {/*props.selectedCustomer &&
            <Button
                variant="outlined"
                color="primary"
                style={{ width: '100%', marginTop: 10}}
                onClick={() => props.onChange(true, "worksite")}
            >
                {t("Select worksite")} (optional)
            </Button>*/
            }


            <TextField
                variant="outlined"
                label={t("Select range")}
                style={{
                    width: '100%',
                    marginTop: 10
                }}
                value={`${moment(props.dateRange.selection.startDate).locale(user.lang ? user.lang : "fi").format('L')} - ${moment(props.dateRange.selection.endDate).locale(user.lang ? user.lang : "fi").format('L')}`}
                onClick={event => handleMenu(event.currentTarget)}
            />
            {props.errors.date.length > 0 && <p style={{color: '#B00', marginLeft: 60}}>{props.errors.date}</p>}


            {menu &&
            <ClickAwayListener onClickAway={closeMenu}>
                <div
                    style={{
                        position: 'absolute',
                        top: anchor.y + anchor.height,
                        left: anchor.x,
                        zIndex: 10,
                        backgroundColor: '#FFF',
                        width: anchor.width - 2,
                        borderStyle: 'solid',
                        borderColor: '#ccc',
                        borderWidth: 1,
                        borderRadius: 4,
                    }}
                >
                    <div style={{marginLeft: 30, marginTop: 10}}>
                        <DateRange
                            moveRangeOnFirstSelection={false}
                            //eachDayOfInterval={eachDayOfInterval}
                            ranges={[props.dateRange.selection]}
                            style={{
                                width: '100%',
                            }}
                            locale={i18n.language === "fi" ? fi : enGB}
                            onChange={event => handleDate(event)}
                        />
                    </div>


                </div>
            </ClickAwayListener>
            }

            <br/>
            

            <Button
                variant="outlined"
                style={{
                    marginTop: 20,
                    marginLeft: 50
                }}
                onClick={() => props.onReset()}
            >
                {t("Reset")}
            </Button>

            <Button
                variant="outlined"
                color="primary"
                style={{
                    marginLeft: 50,
                    marginTop: 20
                }}
                onClick={() => props.onSearch()}
            >
                {t("Search")}
            </Button>
        </div>
    );
};

const mapsStateToProps = state => ({
    user: state.user
});

export default connect(mapsStateToProps, {})(withTranslation()(CustomerComponent));
