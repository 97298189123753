import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import moment from "moment-timezone";
import "moment/locale/fi";
import "moment/locale/en-gb";
import firebase from 'firebase/app';
import 'firebase/firestore';

import {withTranslation} from "react-i18next";

class ClickDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            selectedWorksite: {}
        };

    }

    componentDidMount() {
        const {user} = this.props.user;
        this.unsubscribeWorksiteListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('worksites').onSnapshot(this.onWorksiteSnapshot);
    }

    onWorksiteSnapshot = querySnapshot => {
        const {selectedJob} = this.props;
        let selectedWorksite = {};

        querySnapshot.forEach(doc => {
            let site = doc.data();
            site.id = doc.id;

            if (site.id === selectedJob.worksite) {
                selectedWorksite = site;
            }
        });

        this.setState({
            selectedWorksite: selectedWorksite,
            dialogOpen: true
        });

    };

    componentWillUnmount() {
        this.unsubscribeWorksiteListener && this.unsubscribeWorksiteListener();
    }

    getIntervalText = (freq, interval) => {
        const {t} = this.props;
        switch (freq) {
            case "DAILY":
                return interval === 1 ? `${t("Every")} ${t("day")}` : `${t("Every")} ${interval} ${t("days")}`;
            case "WEEKLY":
                if (interval === 0) {
                    return t("Does not repeat")
                }
                else {
                    return interval === 1 ? `${t("Every")} ${t("week")}` : `${t("Every")} ${interval} ${t("weeks")}`;
                }
            case "MONTHLY":
                return interval === 1 ? `${t("Every")} ${t("month")}` : `${t("Every")} ${interval} ${t("months")}`;
            default:
                return "?"
        }
    };


    render() {
        const {selectedJob, t} = this.props;
        const {user} = this.props.user;
        return (
            <div>
                <Dialog
                    open={this.state.dialogOpen}
                    maxWidth="sm"
                    fullWidth
                    onClose={() => this.props.onClose()}
                >
                    <DialogTitle>
                        <Grid container>
                            <Grid item xs={12} md={11}>
                                {selectedJob.title}
                            </Grid>
                            <Grid item xs={12} md={1}>
                                <IconButton onClick={() => this.props.onClose()}>
                                    <Close/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent>
                        <div>
                            <p style={{
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>{moment(selectedJob.startDT).locale(user.lang ? user.lang : "fi").format('dddd, MMMM DD')}</p>
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>{moment(selectedJob.startDT).locale('fi').format('LT') + ' - ' + moment(selectedJob.endDT).locale('fi').format('LT')}</p>
                            {selectedJob.rrule && <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0
                            }}>{this.getIntervalText(selectedJob.rrule.FREQ, selectedJob.rrule.INTERVAL)}</p>}
                        </div>

                        <div>
                            <p style={{marginBottom: 0, paddingBottom: 0}}>{t("Customer")}</p>
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>{selectedJob.customerLabel}</p>
                        </div>

                        {this.state.selectedWorksite && this.state.selectedWorksite.name && <div>
                            <p style={{marginBottom: 0, paddingBottom: 0}}>{t("Worksite")}</p>
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>{this.state.selectedWorksite.name}</p>
                            <p style={{
                                fontSize: 12,
                                marginTop: 0,
                                paddingTop: 0,
                                marginBottom: 0,
                                paddingBottom: 0
                            }}>{`${this.state.selectedWorksite.streetAddress}, ${this.state.selectedWorksite.postCode} ${this.state.selectedWorksite.city}`}</p>
                        </div> }

                        <div>
                            <p style={{marginBottom: 0, paddingBottom: 0}}>{t("Employees")}</p>
                            {selectedJob.employees && selectedJob.employees.map(employee => (
                                <p style={{
                                    fontSize: 12,
                                    marginTop: 0,
                                    paddingTop: 0,
                                    marginBottom: 0,
                                    paddingBottom: 0
                                }} key={employee.id}>{employee.name}</p>
                            ))}
                        </div>
                    </DialogContent>

                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => this.props.onDelete()}
                        >
                            {t("Delete")}
                        </Button>

                        <Button
                            variant="outlined"
                            color="secondary"
                            style={{
                                marginRight: '40%',
                                width: 200
                            }}
                            onClick={() => this.props.onCancel()}
                        >
                            {t("Cancel job")}
                        </Button>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.props.onClose()}
                        >
                            {t("Close")}
                        </Button>

                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.props.onEdit()}
                        >
                            {t("Edit")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(ClickDialog));