import React, { Component } from 'react';

import classNames from 'classnames';

import axios from 'axios';
import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";

import { Link, Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFilter,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faEdit,
    faEye,
    faPaperPlane,
    faFilePdf,
    faEnvelopeSquare,
    faEnvelopeOpenText,
    faFileSignature,
    faSignature,
    faMailBulk
} from "@fortawesome/free-solid-svg-icons";
import { Add, Close } from '@material-ui/icons';
import {
    IconButton,
    ClickAwayListener,
    CircularProgress,
    Button,
    Dialog
} from '@material-ui/core';

import {
    UtilityBar,
    ListAreaHeader,
    ListAreaItem,
    ListAreaBody,
    FilterMenu,
    SelectButton,
    ConfirmDialog,
    PreviewDialog,
    RecipientsDialog,
} from "../ListComponents";

import GlobalConfirmationDialog from "../CommonDialogs/GlobalConfirmationDialog";

import { isEmpty } from "../validations/isEmpty";
import ReportTypes from './ReportTypes';
import ReportSettings from './ReportSettings';
import ReportStatus from './ReportStatus';

import { base64StringToBlob } from "blob-util";
//import {createBillAttachment} from "./BillDetailsComponents/PDFAttachment";
//import {authenticateSender, formInvoiceAndUrl} from "./BillDetailsComponents/sendToServer";
//import {validateFBWorklog} from "../validations/worklog";
import { StatusSelection } from "./FilterMenuContents";
import { DataSearch } from "../util/DataSearch";
import { sortList } from '../ListComponents/SortList';

import { Transition, config } from 'react-spring/renderprops';
//import { firestore, functions } from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';

import { storeReport, getReportPdf } from './reportStoreFunctions';

import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import * as jwt from 'jsonwebtoken';

import "./ReportList.css";
import { dateTime } from "../util/StringHelpers";

import { LogMenuContents } from './LogMenuContents';
import RecipientsMenu from '../ListComponents/RecipientsMenu'
import FirebaseHelper from '../FirebaseHelper';

class ReportList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            addNew: false,
            billStatus: "all",
            searchTerm: "",
            reports: [],
            reportsCopy: [],
            selectedReports: [],
            worklogs: [],
            employees: [],
            models: [],
            sortOrder: {
                field: "createdAt",
                dir: "asc"
            },
            filterAnchor: null,
            filterValues: [
                { type: ReportStatus.CREATED, field: "status" },
                { type: ReportStatus.SENT, field: "status" },
            ],
            logInfoAnchor: null,
            logInfo: [],
            selection: false,
            selectAll: false,
            menuActions: [
                { action: "status", methods: [ReportStatus.CREATED, ReportStatus.SENT, ReportStatus.ARCHIVED] },
                { action: "send", methods: ["sendReports", "sendReportsTo"] }
            ],
            confirmDialog: false,
            confirmObj: {
                title: "",
                message: "",
            },
            previewDialog: false,
            recipientsDialog: false,
            sendLoading: false,
            saveComplete: false,
            sending: false,
            selectedReport: {},
            email: {
                pdfName: `report_${moment().locale("fi").format("L")}`,
                message: "",
                subject: "Raportti"
            },
            searchLoading: false,
            searchTermsChanged: false,
            approveReports: [],
        };
    }

    componentDidMount() {
        const { user } = this.props.user;

        this.createNewQueryForFilterValues();

        //this.unsubscribeWorklogListener = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").onSnapshot(querySnap => this.onQuerySnap(querySnap, "worklogs"));
        // TODO Optimize time accordingly
        this.unsubscribeEmployeeListener = firebase.firestore().collection("employees").where("companyID", "==", user.companyID).onSnapshot(querySnap => this.onQuerySnap(querySnap, "employees"));
        this.unsubscribeModelListener = firebase.firestore().collection("company").doc(user.companyID).collection("jobModels").onSnapshot(querySnap => this.onQuerySnap(querySnap, "models"))
        FirebaseHelper.getApproveReportsForCompany(user.companyID).then(approveReports => {
            console.log('approveReports', approveReports)
        })
    }

    createNewQueryForFilterValues = () => {
        const { user } = this.props.user;
        const { filterValues } = this.state;

        const types = [];

        this.setState({ loading: true })

        filterValues.forEach((filterValue, index) => {
            types.push(filterValue.type);
        })

        if (!isEmpty(types)) {
            console.log("createNewQueryForFilterValues types", types)

            if (this.unsubscribeReportListener) this.unsubscribeReportListener();

            this.unsubscribeReportListener = firebase.firestore().collection('company').doc(user.companyID).collection('reports')
                .where("status", "in", types)
                .onSnapshot(this.onReportSnapshot);
        }
    }

    onQuerySnap = (querySnap, dataName) => {
        const dataArr = [];

        querySnap.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;

            if (dataName === "employees") data.name = `${data.firstName} ${data.familyName}`;

            /*if (dataName === "worklogs") {
                const {isValid} = validateFBWorklog(data);
                if (isValid) dataArr.push(data);
            }
            else dataArr.push(data);*/
            dataArr.push(data);
        });
        //console.log("set data name ", dataName);

        this.setState({
            [dataName]: dataArr
        });
    };

    onReportSnapshot = querySnapshot => {
        const { user } = this.props.user;
        const { searchTerm } = this.state;
        const reports = [];

        console.log("onReportSnapshot launched ")

        querySnapshot.forEach(doc => {
            let report = doc.data();
            report.id = doc.id;
            //report = changeIntToString(report, user);
            //console.log('push report status ', report.status)
            reports.push(report)

            /*
            if (!reports.includes((rep) => rep.id === report.id)) {
                reports.push(report)
                console.log('push report ', report)
            } else {
                console.log('duplicate report report ', report.id, report.type)
            }*/
        });

        const sortedReports = reports.sort((a, b) => {
            if (moment(a.createdAt).isBefore(b.createdAt)) return -1;
            if (moment(a.createdAt).isAfter(b.createdAt)) return 1;
        });

        this.setState({
            reports: sortedReports,
            //?
            reportsCopy: JSON.parse(JSON.stringify(sortedReports)),
            //loading: false
        }, () => {
            if (searchTerm) this.searchReports();
            else this.filterReports(null, null)
        });
    };

    componentWillUnmount() {
        this.unsubscribeReportListener && this.unsubscribeReportListener();
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
        this.unsubscribeModelListener && this.unsubscribeModelListener();
    }

    roundNumber = d => {
        return parseFloat(d).toFixed(2);
    };

    onSearchParameterReset = () => {
        const { reportsCopy, reports, filterValues } = this.state;
        this.setState({
            searchTerm: "",
            //do not change filter values?
            reports: reportsCopy,
            //dateRange: [moment().subtract(1, "month").startOf("day").toDate(), moment().endOf("month").toDate()]
        });
    };

    onSearchParameterChange = (searchTerm) => {
        const { searchTermsChanged, reportsCopy, reports } = this.state;

        if (searchTermsChanged && reports.length < reportsCopy.length) { //if start writing again, reset search source to all data
            this.setState({ searchTerm: searchTerm, reports: reportsCopy, searchTermsChanged: false })
        } else {
            this.setState({ searchTerm: searchTerm, searchTermsChanged: true })
        }

    }

    searchReports = () => {
        const { reportsCopy, reports, filterValues, searchTerm } = this.state;
        //const searchTerm = event.target.value;

        const filteredReports = [];
        let filterSource = [];

        //if there already are filters, apply search to filtered reports (reports), If not, use all reports (reportsCopy)
        if (filterValues) {
            filterSource = reports;
        } else {
            filterSource = reportsCopy;
        }

        console.log("SEARCHTERM : ", searchTerm)

        if (filterSource.length > 0 && searchTerm.length > 1) {
            //console.log("FILTERSRC ", filterSource)
            this.setState({ searchLoading: true }, () => {

                filterSource.forEach(report => {
                    let recipientsString = "";
                    if (!isEmpty(report.recipients)) report.recipients.map(recipient => {
                        recipientsString += recipient;
                    })

                    const searchTarget = report.createdBy + report.fileName + report.subject + report.type + recipientsString;

                    if (searchTarget.toLowerCase().search(searchTerm.toLowerCase()) > -1) filteredReports.push(report)
                });

                this.setState({ searchTerm, reports: filteredReports, searchTermsChanged: false },
                    () => this.filterReports())
            })
        }

    };

    /*
    handleSelectButton = action => {
     
        if (action === "Check") this.setState({
            confirmDialog: true,
            sendLoading: true
        }, () => this.massStatusCheck());
     
        else this.setState(prevState => ({selection: !prevState.selection}))
    };*/

    sortReports = (field, parse) => {
        const { sortOrder, reports } = this.state;

        const { newSortOrder, results } = sortList(field, parse, sortOrder, reports);

        this.setState({
            sortOrder: newSortOrder,
            reports: results
        });
    };

    filterReports = (type, field) => {
        const { reportsCopy, reports, sortOrder, filterValues, searchTerm } = this.state;

        this.setState({ loading: true })

        const newFilterValue = { type: type, field: field };

        const reportFilter = new DataSearch();

        const existingValue = filterValues.find(value => value.type === newFilterValue.type && value.field === newFilterValue.field);

        if (type && field && !existingValue) {
            filterValues.push(newFilterValue);
            reportFilter.setSearchData(reportsCopy);
        }
        else if (type !== null && field !== null && existingValue) {
            const removeIndex = filterValues.indexOf(existingValue);
            console.log("REMOVED inDEX OF ", removeIndex);
            filterValues.splice(removeIndex, 1);
            reportFilter.setSearchData(reports);
        } else if (searchTerm) {
            reportFilter.setSearchData(reports);
        } else {
            console.log("ELSE WITHOUT ANY FUNCTION ", type, field, searchTerm, filterValues)
            reportFilter.setSearchData(reports);
        }

        if (filterValues.some(value => value.field === "status")) reportFilter.appendField("status", filterValues.filter(value => value.field === "status").map(value => value.type), "searchValueIncludes");
        let results = reportFilter.executeSearch(sortOrder.dir === "asc" ? "desc" : "asc", sortOrder.field)

        const sendMethods = filterValues.filter(value => value.field === "sendMethod").map(value => value.type);

        if (filterValues.some(value => value.field === "sendMethod")) results = results.filter(item => sendMethods.includes(item.sendingMethod.sending));

        console.log("set filtered results", results, filterValues);

        this.setState({ reports: results, filterValues, loading: false, searchLoading: false, });

    };

    toggleFilterMenu = (event, filterField) => {
        const newAnchor = event.currentTarget ? event.currentTarget.getBoundingClientRect() : null;

        this.setState({ filterAnchor: newAnchor, filterField },
            () => {
                if (newAnchor === null) {
                    console.log("make new query ", filterField);
                    this.createNewQueryForFilterValues()
                }
            })
    };

    arrowDirection = field => {
        const { sortOrder } = this.state;

        if (sortOrder.field === field && sortOrder.dir === "desc") {
            return (
                <FontAwesomeIcon icon={faLongArrowAltUp} style={{ height: 15, width: 15 }} />
            );
        }
        else return <FontAwesomeIcon icon={faLongArrowAltDown} style={{ height: 15, width: 15 }} />
    };

    toggleLogMenu = (event, logField) => {
        console.log("logField ", logField)
        const newAnchor = event.currentTarget ? event.currentTarget.getBoundingClientRect() : null;

        this.setState({ logInfoAnchor: newAnchor, logInfo: logField })
    };

    handleReportSelection = id => {
        const { selectedReports } = this.state;

        if (!selectedReports.includes(id)) selectedReports.push(id);
        else {
            const removeIndex = selectedReports.indexOf(id);
            selectedReports.splice(removeIndex, 1);
        }

        this.setState({ selectedReports });
    };

    handleMassReportSelection = () => {
        let { reports, selectAll } = this.state;

        let selectedReports = [];
        const selectMethod = !selectAll;

        if (!selectMethod) selectedReports = [];
        else {
            selectedReports = reports.length > 0 && reports.map(report => report.id)
        }

        this.setState({
            selectAll: selectMethod,
            selectedReports
        });

    };

    handleMassActionSelect = (action, option) => {
        const { confirmObj, selectedReports } = this.state;
        const { t } = this.props;

        confirmObj.title = t("Attention");
        confirmObj.action = action;
        confirmObj.option = option;

        console.log("handleMassActionSelect ", action, option, selectedReports);

        if (action === "send" && option === "sendReports") confirmObj.message = t("Reports SendToDefault");
        else if (action === "send" && option === "sendReportsTo") confirmObj.message = t("Reports SendTo"); //no used tho
        else confirmObj.message = `${t("ChangeTo")} ${t("Reports")}`;


        if (action === "status") confirmObj.status = `${t("status")}: ${t(option)}`;
        if (action === "sendMethod") confirmObj.status = `${t("sendMethod")}: ${t(option)}`;

        this.setState(prevState => ({
            selectedReport: {},  //unselect one report
            confirmDialog: !(action === "send" && option === "sendReportsTo") ? !prevState.confirmDialog : prevState.confirmDialog,
            recipientsDialog: (action === "send" && option === "sendReportsTo") ? !prevState.recipientsDialog : prevState.recipientsDialog,
            confirmObj
        }));
    };

    onMassActionConfirm = () => {
        const { reportsCopy, confirmObj, selectedReports } = this.state;
        const { user } = this.props.user;

        const batch = firebase.firestore().batch();

        if (confirmObj.action === "send") return this.massSendReports();

        selectedReports.forEach(id => {
            const report = reportsCopy.find(report => report.id === id);

            if (report) {
                if (confirmObj.action === "status") report.status = confirmObj.option;

                if (confirmObj.action === "sendMethod") report.sendMethod = confirmObj.option;

                const reportRef = firebase.firestore().collection("company").doc(user.companyID).collection("reports").doc(report.id);
                batch.set(reportRef, report);
            }
        });


        batch.commit()
            .then(() => {
                this.setState({
                    saveComplete: true,
                    selectedReports: [],
                    selectAll: false
                });
            });

    };

    sendEmail = async (email) => {
        console.log("4 sending email: ", email);

        await firebase.functions().httpsCallable('sendMail')({
            mailTo: email.sendTo,
            pdf: email.pdf,
            msg: email.message,
            pdfName: email.pdfName,
            subject: email.subject
        })
            .then(res => {
                console.log("email sent. ");
                //resolve(res);
            })
            .catch(err => {
                console.log(err)
                //reject(err);
            });
    }


    sendReport = (report, delay = 100) => new Promise((resolve, reject) => {
        let { email } = this.state;
        const { t } = this.props;
        const { user } = this.props.user;

        console.log("1 sendReport ", report, user.name);

        if (report && report.sendMethod === 'email' && report.recipients) {


            const reportType = ReportSettings.find(type => type.name === report.type);

            email.subject = report.email && report.email.subject ? report.email.subject : t(reportType.emailSubject);
            email.message = report.email && report.email.message ? report.email.message : t(reportType.emailMessage);

            //email.pdf = report.pdf;
            email.pdfName = report.fileName;

            getReportPdf(report).then((pdf) => { //this returns "data:application/pdf;base64" at the beginning, need to strip for email

                email.pdf = pdf.replace("data:application/pdf;base64,", "");
                console.log("email pdf ", email.pdf);

                this.setState({
                    sending: true
                });

                if (report.recipients) {
                    let sentCnt = 0;

                    let newEmail = { ...email };
                    console.log("2 Send to recipients: ", report.recipients, newEmail)

                    this.sleep(delay).then(() => {
                        //send to many
                        report.recipients.forEach((recipient, index) => {

                            //trigger email sending
                            //this.sleep(delay * index).then(() => {
                            newEmail.sendTo = recipient;

                            console.log("3 sending to ", newEmail.sendTo);

                            this.sendEmail(newEmail)
                                .then(() => {

                                    sentCnt += 1;

                                    const log = {
                                        "action": ReportStatus.SENT,
                                        "to": newEmail.sendTo,
                                        "at": moment().format("YYYYMMDDTHHmmss")
                                    };
                                    if (!report.log) report.log = [];
                                    report.log.push(log);
                                    report.status = ReportStatus.SENT;

                                    //console.log("2 Send to log ", log)

                                    if (sentCnt == report.recipients.length) {

                                        storeReport(user, report)
                                            .then(() => {
                                                console.log("report stored ", report);
                                                this.setState({
                                                    sending: false,
                                                    emailSent: true,
                                                    email: {},
                                                    previewDialog: false,
                                                })

                                                resolve(report);

                                            })
                                            .catch((err) => {
                                                console.log("Failed to store report ", report, err);

                                                this.setState({
                                                    sending: false,
                                                    emailSent: true,
                                                    email: {},
                                                    previewDialog: false,
                                                })

                                                reject(err)
                                            })

                                    }
                                    //TODO: set and store the status or sentLog to report
                                    //TODO: launch some function to poll sent status of this email??
                                })
                                .catch(err => {
                                    console.log("error sending email ", email, " of count ", sentCnt, err);
                                    //TODO: some error message?
                                    this.setState({
                                        sending: false,
                                        //email: {},
                                        previewDialog: false,
                                    })
                                    reject(err, report)
                                })
                        });
                    })

                }
                else {
                    console.log("NOT AN ARRAY")
                }
            }).catch(err => console.log("error reading pdf ", err))
        } else {
            const err = "report cannot be sent due to lack of required fields "
            console.log(err, report)

            this.setState({
                confirmObj:
                {
                    title: "Virhe ",
                    message: "Raporttia ei voi lähettää koska tiedot puutteelliset",
                    status: "",
                    action: "",
                    option: ""
                },
                confirmDialog: true,
                previewDialog: false,
                recipientsDialog: false,
                sending: false,
            }
            );

            reject(err, report);
        }
    })

    getRandomNumber = () => {
        return Math.floor(Math.random() * 10);
    }

    getRandomCharacter = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return characters.charAt(Math.floor(Math.random() * characters.length));
    }
    generateRandomAuthorizationCode = () => {
        let code = ''
        for (let i = 0; i < 6; i++) {
            if (Math.random() < 0.5) {
                code = code + this.getRandomCharacter();
            } else {
                code = code + this.getRandomNumber();
            }
        }
        return code;
    }

    sendReportToBeElectronicallySigned = (report, delay = 100) => new Promise((resolve, reject) => {
        let { email } = this.state;
        const { t } = this.props;
        const { user } = this.props.user;
        console.log('user', user);
        console.log('report', report);

        if (report && report.sendMethod === 'email' && report.recipients) {
            const reportType = ReportSettings.find(type => type.name === report.type);

            console.log(report);
            // console.log(jwt.sign((report), 'shhh'));

            //TODO add to i18n and change the address to be variable debending on environment
            const token = jwt.sign(({ path: report.storagePath }), 'shhh')
            const message = 'Hei, sinulle on lähetetty raportti sähköistä allekirjoittamista varten. Löydät raportin osoitteesta http://localhost:3000/signreportelectronically/' + token;

            email.subject = report.email && report.email.subject ? report.email.subject : t(reportType.emailSubject);
            email.message = message;

            //email.pdf = report.pdf;
            email.pdfName = report.fileName;

            report.authorizationCode = this.generateRandomAuthorizationCode();

            getReportPdf(report).then((pdf) => {
                //email.pdf = pdf;
                email.pdf = pdf.replace("data:application/pdf;base64,", ""); //strip out the type

                this.setState({
                    sending: true
                });


                if (report.recipients) {
                    let sentCnt = 0;
                    console.log("2 Send to recipients: ", report.recipients)
                    let newEmail = { ...email };

                    this.sleep(delay).then(() => {  
                        //send to many
                        report.recipients.forEach((recipient, index) => {

                            //trigger email sending
                            //this.sleep(delay * index).then(() => {
                            newEmail.sendTo = recipient;


                            console.log("3 sending to ", newEmail.sendTo);
                            console.log('recipients', recipient);

                            this.sendEmail(newEmail)
                                .then(() => {

                                    sentCnt += 1;

                                    const log = {
                                        "action": ReportStatus.SENT,
                                        "to": newEmail.sendTo,
                                        "at": moment().format("YYYYMMDDTHHmmss")
                                    };
                                    if (!report.log) report.log = [];
                                    report.log.push(log);
                                    report.status = ReportStatus.SENT;

                                    //console.log("2 Send to log ", log)

                                    if (sentCnt == report.recipients.length) {

                                        storeReport(user, report)
                                            .then(() => {
                                                console.log("report stored ", report);
                                                this.setState({
                                                    sending: false,
                                                    emailSent: true,
                                                    email: {},
                                                    previewDialog: false,
                                                })
                                                FirebaseHelper.getCustomersWithEmail([newEmail.sendTo]).then(customers => {
                                                    FirebaseHelper.addReportToCustomer(report, customers, user.companyID).then(success => {
                                                        alert('Raportti lähetetty allekirjoitettavaksi!');
                                                        resolve(report);
                                                    })

                                                })
                                                    .catch((err) => {
                                                        console.log("Failed to store report ", report, err);

                                                        this.setState({
                                                            sending: false,
                                                            emailSent: true,
                                                            email: {},
                                                            previewDialog: false,
                                                        })

                                                        reject(err)
                                                    })

                                            })
                                        //TODO: set and store the status or sentLog to report
                                        //TODO: launch some function to poll sent status of this email??
                                    }
                                })
                                .catch(err => {
                                    console.log("error sending email ", email, " of count ", sentCnt, err);
                                    //TODO: some error message?
                                    this.setState({
                                        sending: false,
                                        //email: {},
                                        previewDialog: false,
                                    })
                                    reject(err, report)
                                })
                        });
                    })

                }
                else {
                    console.log("NOT AN ARRAY")
                }
            }).catch(err => console.log("error reading pdf ", err))

        } else {
            const err = "report cannot be sent due to lack of required fields "
            console.log(err, report)

            this.setState({
                confirmObj:
                {
                    title: "Virhe ",
                    message: "Raporttia ei voi lähettää koska tiedot puutteelliset",
                    status: "",
                    action: "",
                    option: ""
                },
                confirmDialog: true,
                previewDialog: false,
                recipientsDialog: false,
                sending: false,
            }
            );

            reject(err, report);
        }
    })


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    massSendReports = async (recipients, subject, message) => {
        const { selectedReports, reportsCopy, worklogs, employees, models } = this.state;
        //const {user} = this.props.user;

        // Authenticate user to invoice server
        //let authToken = await authenticateSender();
        //if (!authToken) return console.log("Something went wrong while authenticating");

        //const batch = firestore().batch();

        console.log("massSendReports  ", recipients);
        let sendTryCnt = 0;

        for (let i = 0; i < selectedReports.length; i++) {
            const reportToSend = reportsCopy.find(report => report.id === selectedReports[i]);
            // Find required data in arrays

            console.log("massSendReports for ", reportToSend.id);
            if (recipients) { //
                console.log("massSendReports to other recipients ", recipients, " DO WE REPLACE?");
                reportToSend.recipients = recipients;
            }
            if (subject) reportToSend.subject = subject;
            if (message) reportToSend.message = message;

            // first make a copy, so that sent report will not get overwritten by next
            const reportCopy = { ...reportToSend }

            //trigger email, different times (not to jam sendgrid), wait more on longer lists
            //this.sleep(500+(100 * (i + 1))).then(() => {
            console.log("call sendReport ", i, reportCopy.recipients, reportCopy.pdfName)
            const delay = 500 + (100 * (i + 1));
            this.sendReport(reportCopy, delay)
                .then(res => {
                    sendTryCnt += 1;
                    console.log("Report sent ", sendTryCnt, res.recipients);
                })
                .catch(err => {
                    sendTryCnt += 1;
                    console.log("Report sending ERROR: ", sendTryCnt, err);
                })

        }
        console.log("mass send reports sent");
        this.setState({
            sending: false,
            confirmDialog: false,
            recipientsDialog: false,
            saveComplete: true,
            sendLoading: false,
            confirmObj: { title: "", message: "", status: "", action: "", option: "" }
        })

    }


    massStatusCheck = async () => {
        const { user } = this.props.user;
        const reportsInQue = this.state.reportsCopy.filter(report => report.status === "sent");
        console.log("reportsInQue", reportsInQue);
        //let authToken = await authenticateSender();
        //if (!authToken) return console.log("Something went wrong while authenticating");

        // Server config for Axios
        /*
        const serverConfig = {
            headers: {
                "Content-Type": "application/json",
                'Access-Control-Allow-Origin': '*',
                "x-access-token": authToken
            }
        };*/

        const batch = firebase.firestore().batch();


        /*
        // If there is no bills with InQue status, return
        if (reportsInQue.length === 0) return this.setState({saveComplete: true});
        for (let i = 0; i < reportsInQue.length; i++) {
            const report =reportsInQue[i];
     
            const serverUrl = `https://icf.heinolasoftwares.fi/queue/getJob/${bill.queID}/state`;
     
            const response = await axios.get(serverUrl, serverConfig);
     
            if (response && response.data && response.data.jobState) {
     
                if (response.data.jobState === "completed") bill.status = "Sent";
                if (response.data.jobState === "failed") bill.status = "Error";
     
                const billRef = firestore().collection("company").doc(user.companyID).collection("bills").doc(bill.id);
                batch.set(billRef, bill);
            }
        }
            */


        batch.commit().then(() => {
            this.setState({
                saveComplete: true,
            });
        });

    };

    updateSelectedReports = (recipients, subject, message) => {
        const { selectedReports, selectedReport, reportsCopy } = this.state;
        const { user } = this.props.user;

        /* if single mail selected, then update all recipients and */
        if (!isEmpty(selectedReport)) {
            let report = selectedReport;

            console.log("updating report recipients from ", report.recipients, " to ", recipients);

            report.recipients = recipients;

            //add email contents
            if (!isEmpty(subject) || !isEmpty(message)) {
                if (!report.email) report.email = {};
                report.email.subject = subject;
                report.email.message = message;
            }

            storeReport(user, report)
                .then(() => {
                    console.log("report stored 1", report);
                })
                .catch((err) => {
                    console.log("Failed to store report ", report, err);
                })

        } else if (selectedReports && selectedReports.length > 0) {

            console.log("adding recipients to selected reports ");

            for (let i = 0; i < selectedReports.length; i++) {

                let report = reportsCopy.find(report => report.id === selectedReports[i]);
                console.log("adding recipients to report ", report);

                if (!report.recipients) report.recipients = [];

                recipients && recipients.forEach((recipient) => {
                    if (!report.recipients.includes(rcpt => rcpt === recipient)) {
                        report.recipients.push()
                        console.log("recipient " + recipient + "  added");
                    } else {
                        console.log("recipient " + recipient + "  alredy exists in ", report.recipients)
                    }
                })

                if (!isEmpty(subject) || !isEmpty(message)) {
                    if (!report.email) report.email = {};
                    report.email.subject = subject;
                    report.email.message = message;
                }

                storeReport(user, report)
                    .then(() => {
                        console.log("report stored 2", report);
                    })
                    .catch((err) => {
                        console.log("Failed to store report ", report, err);
                    })

            }
        }

    }

    renderFilterItem = (filter, field, props) => {
        const { t } = this.props;
        return (
            <div className="filterItem-container" style={props}>
                <p className="filterItem-text">{t(filter)}</p>
                <IconButton style={{ marginLeft: 10, padding: 7 }} onClick={() => this.filterReports(filter, field)}>
                    <Close style={{ height: 10, width: 10 }} />
                </IconButton>
            </div>
        );
    };

    renderStatusIcons = (report) => {

        if (report.status === ReportStatus.CREATED)
            return <div><FontAwesomeIcon icon={faFilePdf} style={{ height: 20, width: 20 }} /></div>
        else if (report.status === ReportStatus.SENT)
            return (<div>
                <FontAwesomeIcon icon={faFilePdf} style={{ height: 20, width: 20 }} />
                <FontAwesomeIcon icon={faEnvelopeSquare} style={{ height: 20, width: 20 }} />
            </div>)
        else if (report.status === ReportStatus.DELIVERED)
            return (<div>
                <FontAwesomeIcon icon={faFilePdf} style={{ height: 20, width: 20 }} />
                <FontAwesomeIcon icon={faEnvelopeSquare} style={{ height: 20, width: 20 }} />
                <FontAwesomeIcon icon={faEnvelopeOpenText} style={{ height: 20, width: 20 }} />
            </div>)
        else if (report.status === ReportStatus.ESIGNED)
            return (<div>
                <FontAwesomeIcon icon={faFilePdf} style={{ height: 20, width: 20 }} />
                <FontAwesomeIcon icon={faEnvelopeSquare} style={{ height: 20, width: 20 }} />
                <FontAwesomeIcon icon={faEnvelopeOpenText} style={{ height: 20, width: 20 }} />
                <FontAwesomeIcon icon={faFileSignature} style={{ height: 20, width: 20 }} />
            </div>)
        return '';
    }

    renderViewSendLogIcon = (sentArr) => {

    }

    renderListItem = report => {
        const { user } = this.props.user;

        console.log("RENDERLISTITEM")
        return (
            /*<Link to={"/reports/edit/" + report.id} className="link" key={report.id}>*/
            <ListAreaBody key={report.id}>
                {/*this.state.selection &&
                <ListAreaItem variant="list" size="checkbox" checked={""} show={this.state.selection}/>*/}
                <ListAreaItem variant="list"
                    size="small">{moment(report.createdAt).locale(user.lang ? user.lang : "fi").format('L')}</ListAreaItem>
                <ListAreaItem variant="list"
                    size="small">{this.props.t(report.type ? report.type : '')}</ListAreaItem>
                <ListAreaItem variant="list"
                    size="small">{this.props.t(report.subject ? report.subject : '')}</ListAreaItem>
                <ListAreaItem variant="list"
                    size="medium"
                    style={{ width: '20%', justifyContent: 'space-between', marginLeft: 5 }}
                    icon={<IconButton style={{ padding: 7, marginRight: 7, justifyContent: 'space-between' }}
                        onClick={() => this.setState((prevState) => ({
                            selectedReport: report,
                            recipientsDialog: !prevState.recipientsDialog
                        }))}>
                        <FontAwesomeIcon icon={faEdit} style={{ height: 20, width: 20 }} />
                    </IconButton>
                    }
                >
                    {report.recipients &&
                        <div style={{ padding: 3, marginRight: 5, overflowY: 'scroll', flexWrap: 'wrap' }}>
                            {report.recipients.map(recipient => `${recipient} `)}
                        </div>
                    }
                    {/* <FontAwesomeIcon icon={faEdit} style={{height: 20, width: 20, paddingLeft: 10}}/> */}
                </ListAreaItem>
                <ListAreaItem variant="list" size="small">{this.props.t(report.sendMethod)}</ListAreaItem>
                <ListAreaItem variant="list" size="small"
                //style={{alignItems: 'flex-start', justifyContent: 'space-between'}}
                /*icon={<IconButton style={{padding: 7, marginLeft: 3}}
                                  onClick={event => this.toggleLogMenu(event, report.log)}>
                    <FontAwesomeIcon icon={faEye} style={{height: 20, width: 20}}/>
                </IconButton>}*/
                >
                    <Button onClick={event => this.toggleLogMenu(event, report.log)}>
                        {this.renderStatusIcons(report)}
                        {/* this.props.t(report.status) */}
                    </Button></ListAreaItem>
                <ListAreaItem variant="list" size="medium">
                    <Button
                        variant="outlined"
                        className="report-list-item-send-button"
                        onClick={() => this.setState((prevState) => ({
                            selectedReport: report,
                            previewDialog: !prevState.previewDialog
                        }))}
                    >
                        {<FontAwesomeIcon icon={faEye} style={{ height: 18, width: 18 }} />}
                        <p style={{ paddingLeft: 2, fontSize: 7 }}>{this.props.t("Preview report")}</p>
                    </Button>

                    <Button
                        variant="outlined"
                        className="report-list-item-send-button"
                        onClick={() => this.sendReport(report)}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} style={{ height: 18, width: 18 }} />
                        {/* <p style={{ paddingLeft: 2, fontSize: 8 }}>{this.props.t("Send report")}</p>*/}
                    </Button>

                    {/** Send for electronical signing - Activate Later!! */}
                    {/* 
                    <Button
                        variant="outlined"
                        className="report-list-item-send-button"
                        onClick={() => this.sendReportToBeElectronicallySigned(report)}
                    >
                        <FontAwesomeIcon icon={faFileSignature} style={{ height: 18, width: 18 }} />
                        <FontAwesomeIcon icon={faPaperPlane} style={{ height: 18, width: 18 }} />
                        {/*<p style={{ paddingLeft: 2, fontSize: 8 }}>{this.props.t("Send eSign")}</p>}
                    </Button>
                */}

                </ListAreaItem>

                {/*
                <ListAreaItem variant="list" size="small"><Button
                    variant="outlined"
                    className="report-list-item-send-button"
                    onClick={() => this.setState((prevState) => ({
                        selectedReport: report,
                        recipientsDialog: !prevState.recipientsDialog
                    }))}
                >
                    {this.props.t("Change recipients")}
                </Button></ListAreaItem>
                */
                }
                {/*
                <ListAreaItem variant="list" size="small"><Button
                    //variant="outlined"
                    className="report-list-item-send-button"
                    onClick={() => this.sendReport(report)}
                >
                    {<FontAwesomeIcon icon={faPaperPlane} style={{height: 35, width: 35}}/>}
                </Button>
                </ListAreaItem>
                 */
                }

                <ListAreaItem variant="list" size="checkbox"
                    checked={this.state.selectedReports.includes(report.id)}
                    show={this.state.selection}
                    onChange={() => this.handleReportSelection(report.id)}
                    style={{ marginRight: 0 }}
                />

            </ListAreaBody >
            /* </Link> */
        );
    };

    render() {
        const { t } = this.props;
        const { searchLoading, searchTerm, searchTermsChanged, loading, reports, filterAnchor, filterField, filterValues, logInfoAnchor, logInfo } = this.state;

        const content = classNames({
            "content": true,
            "disable-scroll": Boolean(filterAnchor) || Boolean(logInfoAnchor)
        });

        console.log("reports len, ", reports.length)
        console.log("reportsCopy len, ", this.state.reportsCopy.length)
        console.log(" searchLoading, searchTerm, searchTermsChanged ", searchLoading, searchTerm, searchTermsChanged)

        //const totalBillsAmount = this.countBillsTotalAmount();

        return (
            <div className={content}>
                <UtilityBar
                    t={t}
                    loading={searchLoading || loading}
                    searchBar
                    searchTerm={searchTerm}
                    triggerSearch={this.searchReports}
                    searchOnChange={event => this.onSearchParameterChange(event.target.value)}
                >

                    <div className="bill-utility">
                        <div className="bill-utility left">
                            <div style={{
                                alignSelf: 'center',
                                width: '10vw',
                                height: 'auto',
                                marginLeft: 5,
                                marginRight: 5,
                                marginTop: '3%',
                                //marginBottom: '3%'
                            }}>
                                {searchLoading && <CircularProgress />}
                                {!searchLoading && !searchTermsChanged && <div className="bill-utility small-font">{t("Filters")}</div>}
                                {!searchLoading && searchTermsChanged && searchTerm.length > 0 && <div className="bill-utility small-font">{t("Enter search info text")}</div>}
                            </div>
                            <Transition
                                items={filterValues}
                                keys={item => item.type + item.field}
                                from={{ transform: "scale(0)" }}
                                enter={{ transform: "scale(1)" }}
                                leave={{ transform: "scale(0)" }}
                                config={config.stiff}
                            >
                                {item => props => this.renderFilterItem(item.type, item.field, props)}
                            </Transition>
                            <div style={{ alignSelf: 'center', height: 'auto', marginTop: '3%', marginLeft: '5%' }}>
                                <div className="bill-utility smaller-font">{t("More Filters Info")}</div>
                            </div>
                        </div>
                        {/*
                            <div className="bill-utility right">

                                <SelectButton
                                    onChange={this.handleSelectButton}
                                    options={["Selection", "Check"]}

                                />
                            </div>
                            */
                        }
                    </div>
                </UtilityBar>

                <ListAreaHeader>
                    {/*this.state.selection &&
                    <ListAreaItem
                        variant="title"
                        size="checkbox"
                        style={{marginLeft: 20, marginRight: 10}}
                        show={this.state.selection}
                        checked={this.state.selectAll}
                        onChange={this.handleMassReportSelection}
                        onSelect={this.handleMassActionSelect}
                        menuActions={this.state.menuActions}
                    />
                    */}

                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }}
                                onClick={() => this.sortReports("createdAt", false)}>
                                {this.arrowDirection("createdAt")}
                            </IconButton>
                        }
                    >
                        {t("Report date")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                    >
                        {t("Type")}

                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                    >
                        {t("Subject")}

                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="large"
                    >
                        {t("Recipients")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }}
                                onClick={event => this.toggleFilterMenu(event, "sendMethod")}>
                                <FontAwesomeIcon icon={faFilter} style={{ height: 15, width: 15 }} />
                            </IconButton>
                        }
                    >
                        {t("Delivery")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }}
                                onClick={event => this.toggleFilterMenu(event, "status")}>
                                <FontAwesomeIcon icon={faFilter} style={{ height: 15, width: 15 }} />
                            </IconButton>
                        }
                    >
                        {t("Status")}
                    </ListAreaItem>

                    {!this.state.selection &&
                        <ListAreaItem
                            variant="title"
                            size="medium"
                        >
                            {t("Report actions")}
                        </ListAreaItem>
                    }

                    <ListAreaItem
                        variant="title"
                        size="checkbox"
                        style={{
                            height: 20,
                            width: 60,
                            marginLeft: 5,
                            marginRight: 5,
                            border: 1,
                            borderColor: 'black'
                        }}
                        show={this.state.selection}
                        checked={this.state.selectAll}
                        onChange={this.handleMassReportSelection}
                        onSelect={this.handleMassActionSelect}
                        menuActions={this.state.menuActions}
                    >
                        {t("Report mass actions")}
                    </ListAreaItem>

                </ListAreaHeader>

                {!loading && reports.length > 0 && reports.map(this.renderListItem)}

                {loading &&
                    <div style={{
                        alignSelf: 'center',
                        width: '50vw',
                        height: 'auto',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginTop: '3%'
                    }}>
                        <CircularProgress />
                    </div>
                }


                {Boolean(filterAnchor) &&
                    <ClickAwayListener onClickAway={() => this.toggleFilterMenu({}, filterField)}>
                        <FilterMenu
                            anchor={filterAnchor}
                        >
                            {filterField === "status" &&
                                <StatusSelection
                                    filterValues={filterValues}
                                    filterReports={this.filterReports}
                                    t={this.props.t}
                                />
                            }

                        </FilterMenu>
                    </ClickAwayListener>
                }

                {Boolean(logInfoAnchor) &&
                    <ClickAwayListener onClickAway={() => this.toggleLogMenu({}, logInfo)}>
                        <RecipientsMenu
                            anchor={logInfoAnchor}
                        >
                            <LogMenuContents
                                logInfo={logInfo}
                                t={this.props.t}
                                user={this.props.user}
                            />
                        </RecipientsMenu>
                    </ClickAwayListener>
                }

                {
                    <ConfirmDialog
                        open={this.state.confirmDialog}
                        onClose={() => this.setState({
                            confirmDialog: false,
                            saveComplete: false,
                            sendLoading: false,
                            confirmObj: { title: "", message: "", status: "", action: "", option: "" }
                        })}
                        onConfirm={() => this.setState({ sendLoading: true }, () => this.onMassActionConfirm())}
                        title={this.state.confirmObj.title}
                        message={this.state.confirmObj.message}
                        status={this.state.confirmObj.status}
                        //loading={this.state.sending}
                        loading={this.state.sendLoading}
                        saveComplete={this.state.saveComplete}
                        comp={this.props.t("Reports")}
                    />
                }

                {this.state.previewDialog &&
                    <PreviewDialog
                        t={this.props.t}
                        user={this.props.user}
                        open={this.state.previewDialog}
                        onClose={() => this.setState({
                            previewDialog: false,
                            //sending: false,
                            //selectedReport: {},
                        })}
                        onSend={(report) => this.setState({ sending: true }, () => this.sendReport(report))}
                        loading={this.state.sending}
                        report={this.state.selectedReport}
                        onChangeRecipients={() => this.setState({ recipientsDialog: true, previewDialog: false, })}
                    //onChangeRecipients={(recipients) => this.updateRecipientsOfSelectedReports(recipients)}
                    />
                }

                {this.state.recipientsDialog &&
                    <RecipientsDialog
                        t={this.props.t}
                        user={this.props.user}
                        open={this.state.recipientsDialog}
                        onClose={() => this.setState({ recipientsDialog: false })}
                        onSend={(report) => this.setState({ sending: true }, () => {
                            this.sendReport(report)
                            this.setState({ recipientsDialog: false })
                        })}
                        onMassSend={(recipients) => this.setState({ sending: true }, () => {
                            recipients ? this.massSendReports(recipients) : this.massSendReports();
                            this.setState({ recipientsDialog: false })
                        })}
                        onSave={(recipients, subject, message) => {
                            this.updateSelectedReports(recipients, subject, message)
                            //this.setState({recipientsDialog: false})
                        }}
                        // loading={this.state.sending}
                        selectedReport={this.state.selectedReport}
                        reports={this.state.reports}
                        selectedReportIDs={this.state.selectedReports}
                    />
                }

                {/*
                <GlobalConfirmationDialog
                    open={this.state.confirmationDialog}
                    onClose={() => this.setState({ confirmationDialog: false})}
                    onConfirm={() => this.setState({ confirmationDialog: false}, () => this.state.confirmationMode === "delete" ? this.onBillDelete() : this.createCompensationBill())}
                    title={t("Attention")}
                    message={this.state.confirmationMode === "delete" ? `${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DeleteActionBill")}?` : `${t("ActionConfirmation")} ${t("CreateAction")} ${t("CreateActionCompensation")}`}
                    mode={this.state.confirmationMode}
                />
                */}

                {/* this.renderEmailSendingDialog() */}
            </div>
        );
    }
}

const
    mapStateToProps = state => ({
        user: state.user,
        company: state.company,
    });

export default connect(mapStateToProps, {})

    (
        withTranslation()

            (
                ReportList
            ))
    ;

