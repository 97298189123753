import React, {Component} from 'react';

import './ListAreaHeader.css'
class ListAreaHeader extends  Component{
    render() {
        return (
            <div className="listAreaHeader-container">
                {this.props.children}
            </div>
        );
    }
}

export default ListAreaHeader;