import React from 'react';

import "./ModulePrice.css";

import {
    Divider
} from '@material-ui/core';

import { withTranslation } from "react-i18next";

const ModulePrice = props => {
    const { employees, t } = props;

    const numberOfModules = {
        management: 0,
        time: 0,
        reports: 0,
        billing: 0,
        keys: 0,
        registry: 0,
        crm: 0,
        spotOn: 0,
    };

    // See if mainUser has management module
    const management = employees.some(user => user.settings.permissions.mainUser && user.settings.permissions.management.admin);

    console.log("management", management);

    const numberOfSubModules = {
        mobileTime: 0
    };

    let totalPrice = 0;

    employees.forEach(employee => {
        if (employee.settings && employee.isActive === "true") {
            const { permissions } = employee.settings;
            // Check mainUser
            if (permissions.mainUser || permissions.management?.admin) {
                if (permissions.management?.admin) {
                    numberOfModules.management += 1;
                    totalPrice += numberOfModules.management === 1 ? 19.90 : 0
                }

                if (permissions.time?.admin) {
                    numberOfModules.time += 1;
                    totalPrice += numberOfModules.time === 1 ? 9.90 : 0

                }

                if (permissions.reports?.admin) {
                    numberOfModules.reports += 1;
                    totalPrice += numberOfModules.reports === 1 ? 9.90 : 0
                }

                if (permissions.billing?.admin) {
                    numberOfModules.billing += 1;
                    totalPrice += numberOfModules.billing === 1 ? 9.90 : 0
                }

                if (permissions.keys?.admin) {
                    numberOfModules.keys += 1;
                    totalPrice += numberOfModules.keys === 1 ? 9.90 : 0
                }


                if (permissions.crm?.admin) {
                    numberOfModules.crm += 1;
                    totalPrice += numberOfModules.crm === 1 ? 9.90 : 0
                }

                if (permissions.spotOn?.admin) {
                    numberOfModules.spotOn += 1;
                    totalPrice += numberOfModules.spotOn === 1 ? 9.90 : 0
                }

                /*
                if (permissions.registry.admin) {
                    numberOfModules.registry += 1;
                    totalPrice += numberOfModules.registry === 1 ? 20 : 10
                }
                 */
            }
            else {

                numberOfSubModules.mobileTime += 1;
                if (numberOfSubModules.mobileTime >= 10) { //discount price to 6,90
                    totalPrice = 6.90 * numberOfSubModules.mobileTime;
                } else {
                    totalPrice = 8.90 * numberOfSubModules.mobileTime;
                }
                

                /*if (management) {
                    numberOfSubModules.mobileTime += 1;
                    totalPrice += 8.90;
                }*/
            }
        }
    });

    const roundTotal = total => {

        return total.toFixed(2);

    };


    return (
        <div>

            {numberOfModules.management > 0 &&
                <div className="modulePrice-container">
                    <p>{t("Management_settings")}</p>
                    <p>{" x " + numberOfModules.management}</p>
                </div>
            }

            {numberOfModules.time > 0 &&
                <div className="modulePrice-container">
                    <p>{t("Time")}</p>
                    <p>{" x " + numberOfModules.time}</p>
                </div>
            }

            {numberOfModules.reports > 0 &&
                <div className="modulePrice-container">
                    <p>{t("Reports")}</p>
                    <p>{" x " + numberOfModules.reports}</p>
                </div>
            }

            {numberOfModules.billing > 0 &&
                <div className="modulePrice-container">
                    <p>{t("Billing")}</p>
                    <p>{" x " + numberOfModules.billing}</p>
                </div>
            }

            {numberOfModules.crm > 0 &&
                <div className="modulePrice-container">
                    <p>{t("CRM module")}</p>
                    <p>{" x " + numberOfModules.keys}</p>
                </div>
            }

            {numberOfModules.keys > 0 &&
                <div className="modulePrice-container">
                    <p>{t("Key module")}</p>
                    <p>{" x " + numberOfModules.keys}</p>
                </div>
            }

            {numberOfModules.spotOn > 0 &&
                <div className="modulePrice-container">
                    <p>{t("SpotOn module")}</p>
                    <p>{" x " + numberOfModules.spotOn}</p>
                </div>
            }

            {/*
            {numberOfModules.registry > 0 &&
                <div className="modulePrice-container">
                    <p>{t("Registry")}</p>
                    <p>{" x " + numberOfModules.registry}</p>
                </div>
            }
            */}

            {numberOfSubModules.mobileTime > 0 &&
                <div className="modulePrice-container">
                    <p>{t("Mobile_devices")}</p>
                    <p>(á {numberOfSubModules.mobileTime >= 10 ? "6.90€" : "8,90€" })</p>
                    <p>{" x " + numberOfSubModules.mobileTime}</p>
                </div>
            }

            <Divider style={{ backgroundColor: '#ccc', width: '82%', marginLeft: '9%', marginTop: 10, marginBottom: 20 }} />

            <div className="modulePrice-container">
                <p>{t("Total")}</p>
                <p>{roundTotal(totalPrice) + "€"}</p>
            </div>


            <p style={{ marginTop: 100, fontSize: 12, marginLeft: '17%' }}>(Käytetyt hinnat ovat vain esimerkki) </p>
        </div>
    )
};

export default withTranslation()(ModulePrice);