import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";

export const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const calculateWorkTime = (workday) => {
    let pausesNotBelongWorktime = 0;

    workday.pauses && workday.pauses.map((pause) => {
        if (!pause.isPayable) pausesNotBelongWorktime += pause.duration;
    })
    if (workday.duration && workday.duration - pausesNotBelongWorktime > 0) {
        return (workday.duration - pausesNotBelongWorktime);
    }
    return workday.duration;
};


export const roundWorkTimeToNearestFull = (timeInMillis, roundRule) => {

    //billableHours = Math.round(billableHours/ 3600000).toString();
    console.log("round this timeInMillis ", timeInMillis)
    let timeInMinutes = timeInMillis/60000.0;
    const quarterHours = Math.ceil(timeInMinutes/15);
    console.log("round quarterHours ", quarterHours)
    const newTimeInHours = quarterHours*4.0;
    console.log("round newTimeInHours ", newTimeInHours)
    
    return newTimeInHours * 3600000;
}

export const durationToHHmm = (d) => {

        d = Number(d) / 1000;  //millis to sec

        if (d > 60) {
            let h = Math.floor(d / 3600);
            let m = Math.floor(d % 3600 / 60);
            let s = Math.floor(d % 3600 % 60);

            let hDisplay = h === 0 ? "" : (h < 10 ? "0" + h + "h " : h + 'h');
            let mDisplay = m === 0 ? "" : (m < 10 ? "0" + m + "min" : m + "min");
            //console.log("secondsToHHMM hDisplay + mDisplay ", hDisplay + mDisplay)
            return hDisplay + mDisplay;

        } else {
            return "--:--"
        }
    
}

export const durationToHours = (duration, lang) => {
    
    return ((duration / 3600000).toFixed(2).replace(".", lang === "fi" ? "," : ".") + "h");
}