import React, {Component} from 'react';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import firebase from 'firebase/app';
import "firebase/firestore";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MapComponent from './util/GoogleMap';
import {Button, Grid, CircularProgress} from '@material-ui/core';

import moment from 'moment';
import "moment/locale/fi";
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";

import {setCustomers} from "./actions/customerActions";
import {setEmployees} from "./actions/employeeActions";
import {tasksToString} from "./util/StringHelpers";
import JobCalList from './JobCardComponents/JobCalList';

    class EmployeeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            employee: {},
            worklogList: [],
            worksites: [],
            worklogs: [],
            jobs: [],
            worklogsLoading: true,
            jobsLoading: true,
        };

    }

    componentDidMount() {
        const {user} = this.props.user;
        const {match: {params}} = this.props;
        const {customers} = this.props.customers;

        const fromDT = moment(moment().subtract(1, "week").toDate()).format("YYYYMMDDTHHmmss");
        const untilDT = moment(moment().add(1, "week").toDate()).format("YYYYMMDDTHHmmss");
        const tomorrow = moment(moment().add(1, "day").toDate()).format("YYYYMMDDTHHmmss");

        const id = params.employeeId;
        if (id) {
            this.unsubscribeEmployeeListener = firebase.firestore().collection('employees').doc(id).onSnapshot(docSnapshot => {
                this.setState({
                    employee: docSnapshot.data(),
                    id: docSnapshot.id,
                    loading: false,
                });
            })

            //console.log("query worklogs for employee id ", id);
            this.unsubscribeWorklogListener =
                firebase.firestore().collection('company').doc(user.companyID).collection("worklogs")
                    .where("employee", "array-contains", id)
                    .where("jobStartDT", ">=", fromDT)
                    .where("jobStartDT", "<=", tomorrow)
                    .orderBy("jobStartDT", "desc") //.limit(20)
                    .onSnapshot(qS => this.onWorklogSnapshot(qS));


            //console.log("query jobs from  " + fromDT + " until " + untilDT);

            this.unsubscribeJobListener =
                firebase.firestore().collection('company').doc(user.companyID).collection("jobs")
                    //.where("employees", "array-contains", id)  //cannot use this where because array contains id and name
                    //.where("startDT", ">", fromDT)   //cannot use this neither, because startDT is for the mother event only
                    //.where("startDT", "<", untilDT)
                    //.orderBy("startDT", "desc")
                    .onSnapshot(qS => this.onJobSnapshot(qS, id));

        }

        if (!customers.length > 0) firebase.firestore().collection("customers").where("companyID", "array-contains", user.companyID).get().then(querySnap => this.onQuerySnap(querySnap, "customers"));

        this.unsubscribeWorksiteListener = firebase.firestore().collection('company').doc(user.companyID).collection('worksites')
        //.where("id", "in", worksiteIds )  //cannot do this, only up to 10 array ids
            .onSnapshot(querySnapshot => this.onWorksiteSnapShot(querySnapshot));
    }


    componentWillUnmount() {

        if (this.unsubscribeEmployeeListener) {
            this.unsubscribeEmployeeListener();
        }
        /*if (this.unsubscribeCustomerListener) {
            this.unsubscribeCustomerListener();
        }*/
        if (this.unsubscribeWorksiteListener) {
            this.unsubscribeWorksiteListener();
        }
        if (this.unsubscribeJobListener) {
            this.unsubscribeJobListener();
        }
        if (this.unsubscribeWorklogListener) {
            this.unsubscribeWorklogListener();
        }
    }

    onQuerySnap = (querySnap, dataName) => {
        const dataArr = [];

        console.log("got query for  ", dataName);
        querySnap.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;
            if (dataName === "employees") data.name = `${data.firstName} ${data.familyName}`;
            dataArr.push(data);
        });

        if (dataName === "customers") this.props.setCustomers(dataArr);
        if (dataName === "employees") this.props.setEmployees(dataArr);
    };

    onJobSnapshot = (querySnapshot, id) => {
        const jobs = [];
        const alljobs = [];

        querySnapshot.forEach(doc => {
            const job = doc.data();
            job.id = doc.id;
            //console.log("job " + job.customerLabel);
            //alljobs.push(job);
            if (job.employees && job.employees.find(employee => employee.id === id)) {
                //console.log("add job of " + job.customerLabel);
                jobs.push(job)
            }
        });

        console.log("got jobs ", jobs);

        this.setState({
            jobs: jobs,
            jobsLoading: false,
        });
    };

    onWorklogSnapshot = (querySnapshot) => {
        const worklogs = [];


        querySnapshot.forEach(doc => {
            const worklog = doc.data();
            worklog.id = doc.id;
            //filter here if not in query
            worklogs.push(worklog)
        });

        //console.log("got worklogs ", worklogs);

        this.setState({
            worklogs: worklogs,
            worklogsLoading: false,
        });

        //this.queryWorksites(worklogs);
    };

    /*
    queryWorksites = (worklogs) => {
        //only get the ones that are needed
        let worksiteIds = [];
        if (worklogs && worklogs.length > 0) {
            worklogs.map(wlog => worksiteIds.push(wlog.worksite));

            console.log("get worksites based on ids", worksiteIds);

            if (this.unsubscribeWorksiteListener) this.unsubscribeWorksiteListener();

            this.unsubscribeWorksiteListener = firebase.firestore().collection('company').doc(this.props.user.user.companyID).collection('worksites')
                //.where("id", "in", worksiteIds )  //cannot do this, only up to 10 array ids
                .onSnapshot(querySnapshot => this.onWorksiteSnapShot(querySnapshot));
        }
    }*/

    onWorksiteSnapShot = (querySnapshot) => {
        let worksites = [];

        querySnapshot.forEach(doc => {
            let worksite = doc.data();
            worksite.id = doc.id;
            worksites.push(worksite);
        });

        //console.log("got worksites ", worksites);

        this.setState({
            worksites: worksites,
            worklogsLoading: false,
        });

    };

    secondsToHms = (d) => {
        d = Number(d);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
        let mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
        let sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay + sDisplay;
    };


    getWorksiteNameForLog = log => {
        const {worksites} = this.state;
        const {t} = this.props;

        const worksite = worksites.find(worksite => worksite.id === log.worksite);

        if (worksite && worksite.name) return worksite.name;
        else if (worksite && worksite.number) return t("Worksite number") + worksite.number;
        else return t("No_worksite")
    }

    getCustomerNameForLog = log => {
        const {customers} = this.props.customers;
        const {t} = this.props;

        const customer = customers.find(customer => customer.id === log.customer);

        if (customer && customer.name) return customer.name;
        else return t("No_worksite")
    }

    generateWorklogItem = log => {
        const {t} = this.props;

        return (
            <div key={log.id}>
                <div className="log-list-item-container">
                    <p className="log-list-item-largerer">{this.getCustomerNameForLog(log)}</p>
                    <p className="log-list-item-largerer">{this.getWorksiteNameForLog(log)}</p>
                    <p className="log-list-item-large">{moment(log.startDT).locale("fi").format('L')}</p>
                    <p className="log-list-item-large">
                        {moment(log.startDT).format('LT') + ' - ' + (log.status !== "Ongoing" ? moment(log.endDT).format('LT') : "")}
                    </p>
                    <p className="log-list-item-large">{tasksToString(log)}</p>
                    <p className="log-list-item-large">{t(log.status)}</p>
                </div>

            </div>
        );

    }


    render() {
        const {employee, worksites, worklogs, customers, jobs, loading, worklogsLoading, jobsLoading} = this.state;
        const {t} = this.props;

        return (
            <div style={{width: '100%'}}>

                {loading &&
                <CircularProgress/>}

                <Grid container spacing={24} style={{marginTop: 24, marginLeft: 24}}>

                    <Grid item xs={12} sm={3}>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                <Paper style={{height: 135,}}>
                                    <div
                                        style={{display: 'flex', backgroundColor: '#2B81C1', padding: 20, height: 135}}>
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            color: '#FFF',
                                            marginTop: 10
                                        }}>
                                            {`${employee.firstName} ${employee.familyName}`}
                                        </div>

                                        <div style={{display: 'flex', flexGrow: 1, justifyContent: 'flex-end'}}>
                                            <Button
                                                style={{backgroundColor: '#FFF', height: 35}}
                                                component={Link}
                                                to={"/registry/employees/edit/" + this.state.id}
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 24}}>
                                <Paper style={{padding: 10}}>
                                    <ListItem>
                                        <ListItemText primary={this.state.employee.phone}/>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem>
                                        <ListItemText primary={this.state.employee.phone}/>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem>
                                        <ListItemText primary={this.state.employee.email}/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={this.state.employee.additionalInfo}/>
                                    </ListItem>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 24}}>
                                <Paper style={{padding: 10}}> Viestit </Paper>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 24}}>
                                <Paper style={{padding: 10}}> Statistiikkaa </Paper>
                            </Grid>

                            {!loading &&
                            <Grid item xs={12} style={{marginTop: 24}}>
                                <Paper style={{height: 300}}>
                                    <MapComponent
                                        setLat={this.state.employee.lat}
                                        setLng={this.state.employee.lng}
                                        loadingElement={<div style={{height: `100%`}}/>}
                                        containerElement={<div style={{height: '100%'}}/>}
                                        mapElement={<div style={{height: '100%'}}/>}
                                    />
                                </Paper>
                            </Grid>
                            }
                        </Grid>
                    </Grid>


                    {/* worklog component */}

                    <Grid item xs={12} sm={4}>
                        {worklogs && !worklogsLoading &&
                        <Grid container direction="column">

                            <Grid item xs={12} style={{marginTop: 0}}>

                                <div style={{height: 100, backgroundColor: '#2B81C1', display: 'flex'}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                        <p style={{
                                            fontSize: 20,
                                            paddingLeft: 15,
                                            paddingTop: 5,
                                            color: '#FFF'
                                        }}>{t("Worklogs")}</p>
                                    </div>

                                </div>

                                <div className="log-list-header">
                                    <p className="log-list-header-large">{t("Customer")}</p>
                                    <p className="log-list-header-large">{t("Worksite")}</p>
                                    <p className="log-list-header-date">{t("Date")}</p>
                                    <p className="log-list-header-large">{t("Time")}</p>
                                    <p className="log-list-header-large">{t("Task")}</p>
                                    <p className="log-list-header-large">{t("Status")}</p>
                                </div>

                                <div className="log-list">
                                    {worklogs && worklogs.length > 0 && worklogs.map(this.generateWorklogItem)}
                                </div>

                            </Grid>
                        </Grid>
                        }
                    </Grid>

                    {!loading &&
                    <Grid item xs={12} sm={4}>
                        <Grid container direction="column">
                            <div style={{height: 100, backgroundColor: '#2B81C1', display: 'flex'}}>
                                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <p style={{
                                        fontSize: 20,
                                        paddingLeft: 15,
                                        paddingTop: 5,
                                        color: '#FFF'
                                    }}>{t("Calendar")}</p>
                                </div>

                            </div>
                            <Grid item xs={12}>
                                <Paper style={{height: '100%', padding: 5}}>
                                    {!worklogsLoading && !jobsLoading &&
                                    <JobCalList
                                        jobs={jobs}
                                        worksites={worksites}
                                        worklogs={worklogs}
                                        customers={customers}
                                        height={610}
                                    />
                                    }
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 24}}>
                                {/*<Paper style={{padding: 5, textAlign: 'Left'}}> Customer settings </Paper>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                    }

                {/*
                    <Grid item xs={12} sm={4}>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                <Paper style={{height: 300}}>
                                    <MapComponent
                                        setLat={this.state.employee.lat}
                                        setLng={this.state.employee.lng}
                                        loadingElement={<div style={{height: `100%`}}/>}
                                        containerElement={<div style={{height: '100%'}}/>}
                                        mapElement={<div style={{height: '100%'}}/>}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 24}}>
                                <Paper style={{padding: 10}}>

                                </Paper>

                            </Grid>
                            <Grid item xs={12} style={{marginTop: 24}}>
                                <Paper style={{padding: 10}}> Employee settings </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    */}
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    customers: state.customers,
    employees: state.employees,
});

//export default connect(mapStateToProps, {})(withTranslation()(EmployeeDetails));

export default withRouter(connect(mapStateToProps, {
    setCustomers,
    setEmployees,
})(withTranslation()(EmployeeDetails)));
