import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {connect} from 'react-redux';

import {Link} from "react-router-dom";

import {
    ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';

import {
    AccountCircle
} from "@material-ui/icons";

class EmployeeListGenerate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: []
        };

    }

    componentDidMount() {
        const {user} = this.props.user;
        this.unsubscribeEmployeeListener =
            firebase.firestore().collection('employees')
                .where("companyID", "==", user.companyID)
                .onSnapshot(this.onEmployeeSnapshot);
    }

    onEmployeeSnapshot = querySnapshot => {
        const employees = [];

        querySnapshot.forEach(doc => {
            const employee = doc.data();
            employee.id = doc.id;
            employees.push(employee);
        });

        this.setState({
            employees: employees
        });
    };

    componentWillUnmount() {
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
    }

    generate = (items) => {
        return items.map(item => {
            return React.cloneElement(
                <Link to={"/registry/employees/edit/" + item.id} style={{textDecoration: 'none'}} key={item.id}>
                    <ListItem button style={{height: '100%'}}>
                        <ListItemIcon>
                            <AccountCircle/>
                        </ListItemIcon>
                        <ListItemText
                            primary={`${item.firstName} ${item.familyName}`}
                        />
                    </ListItem>
                </Link>

            )
        })
    };

    render() {
        return(
            <div style={{width: '100%'}}>
                {this.generate(this.state.employees)}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(EmployeeListGenerate)