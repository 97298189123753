import React from "react";

import { TextField, Paper, Button, MenuItem } from "@material-ui/core";

import { withTranslation } from "react-i18next";

const CompanySettings = (props) => {
  const { t } = props;

  return (
    <div style={{ padding: 30, display: "flex", flexDirection: "row" }}>
      <div style={{ flexDirection: "column", display: "flex" }}>
        <h3>Contacts</h3>

        <TextField
          variant="outlined"
          label={t("Name")}
          style={{
            width: 300,
          }}
          value={props.company.name || ""}
          onChange={(event) => props.onChange(event, "name")}
        />

        <TextField
          variant="outlined"
          label={t("Street address")}
          style={{
            width: 300,
            marginTop: 10,
          }}
          value={props.company.streetAddress || ""}
          onChange={(event) => props.onChange(event, "streetAddress")}
        />

        <TextField
          variant="outlined"
          label={t("City")}
          style={{
            width: 300,
            marginTop: 10,
          }}
          value={props.company.city || ""}
          onChange={(event) => props.onChange(event, "city")}
        />

        <TextField
          variant="outlined"
          label={t("Post code")}
          style={{
            width: 300,
            marginTop: 10,
          }}
          value={props.company.postCode || ""}
          onChange={(event) => props.onChange(event, "postCode")}
        />

        <TextField
          variant="outlined"
          label={t("Country")}
          style={{
            width: 300,
            marginTop: 10,
          }}
          value={props.company.country || ""}
          onChange={(event) => props.onChange(event, "country")}
        />

        <TextField
          variant="outlined"
          label={t("Email")}
          style={{
            width: 300,
            marginTop: 10,
          }}
          value={props.company.email || ""}
          onChange={(event) => props.onChange(event, "email")}
        />

        <TextField
          variant="outlined"
          label={t("Phone")}
          style={{
            width: 300,
            marginTop: 10,
          }}
          value={props.company.phone || ""}
          onChange={(event) => props.onChange(event, "phone")}
        />

        <TextField
          variant="outlined"
          label={t("Line of business")}
          style={{
            width: 300,
            marginTop: 10,
          }}
          value={props.company.companyField || ""}
          onChange={(event) => props.onChange(event, "companyField")}
        />

        <TextField
          variant="outlined"
          label={t("Company ID")}
          style={{
            width: 300,
            marginTop: 10,
          }}
          value={props.company.yCode || ""}
          onChange={(event) => props.onChange(event, "yCode")}
        />
      </div>

      <div style={{ display: "flex", flexDirection: "column", marginLeft: 50 }}>
        <h3>Billing</h3>
        <TextField
          variant="outlined"
          label={t("Bank name")}
          style={{
            width: 300,
          }}
          value={props.company.bankName || ""}
          onChange={(event) => props.onChange(event, "bankName")}
          select
        >
          <MenuItem value="Aktia">{"Aktia"}</MenuItem>
          <MenuItem value="Danske bank">{"Danske bank"}</MenuItem>
          <MenuItem value="Handelsbanken">{"Handelsbanken"}</MenuItem>
          <MenuItem value="Nordea">{"Nordea"}</MenuItem>
          <MenuItem value="Säästöpankki">{"Säästöpankki"}</MenuItem>
          <MenuItem value="OmaSP">{"OmaSP"}</MenuItem>
          <MenuItem value="Osuuspankki">{"Osuuspankki"}</MenuItem>
          <MenuItem value="S-Pankki">{"S-Pankki"}</MenuItem>
          <MenuItem value="Ålandsbanken">{"Ålandsbanken"}</MenuItem>
        </TextField>

        <TextField
          variant="outlined"
          label={t("Account number") + " (IBAN)"}
          style={{
            width: 300,
            marginTop: 10,
          }}
          value={props.company.accountNumber || ""}
          onChange={(event) => props.onChange(event, "accountNumber")}
        />

        <TextField
          variant="outlined"
          label={t("Bank Identification") + " (BIC)"}
          style={{
            width: 300,
            marginTop: 10,
          }}
          value={props.company.BIC || ""}
          onChange={(event) => props.onChange(event, "BIC")}
        />
      </div>

      <Paper className="settings-footer">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => props.onSave(props.company)}
          /*onClick={(() => this.setState({saving: true}),
                            () => props.onSave(props.company,
                                () => this.setState({
                                    saving: false,
                                    unsavedChanges: false
                                }))
                    )}*/
        >
          {t("Save")}
        </Button>
      </Paper>
    </div>
  );
};

export default withTranslation()(CompanySettings);
