import React, {Component} from 'react';

import {
    Dialog,
    DialogActions,
    DialogContent,
    Button
} from '@material-ui/core';

import {withTranslation} from "react-i18next";

class ErrorDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: true
        };
    }


    render() {
        const {t} = this.props;
        return (
            <Dialog
                open={this.state.dialogOpen}
                onClose={() => this.props.onClose()}
                maxWidth="xs"
                fullWidth
            >
                <DialogContent>
                    <p>{t("Error_dialog_msg")}</p>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => this.props.onClose()}
                    >
                        {t("Close")}
                    </Button>
                </DialogActions>

            </Dialog>
        );
    }
}



export default withTranslation()(ErrorDialog);