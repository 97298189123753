import React from 'react';

const styles = props => {
    return {
        container: {
            width: '100%',
            height: props.height ? props.height : '100%',
            textAlign: 'center',
            backgroundColor: 'yellow',
        }
    }
}

export default function (props) {
    console.log(styles(props))
    return (
        <div style={styles(props).container}>
            <object data={`${props.pdf}`} width="100%" height={'100%'}
                style={{ paddingTop: 0 }} />
        </div>
    )
}