import React from 'react';

import { withTranslation } from "react-i18next";

import {
    Collapse,
} from '@material-ui/core';

import {
    Done,
} from '@material-ui/icons'

import ModuleUserPermissions from './ModuleUserPermissions';

import "./ModuleUserListItem.css";

const ModuleUserListItem = props => {
    const { employee, userOpen, selectedUser } = props;

    const timeSubPermissionsMobile = ["autoJob", "reportPartner", "addJob", "addMaterials", "addCustomer"];
    const timeSubPermissionsWeb = ["ownReports", "modifyWorklogs"];

    const keySubPermissionsMobile = ["registerKey", "returnCustomer"];
    const keySubPermissionsWeb = ["manageKeys"];

    const checkMainUser = () => {
        return employee.settings.permissions.mainUser ? <Done style={{ height: 20, width: 20 }} /> : ""
    };

    const checkMark = field => {

        const fieldArr = ["jobList", "createAndEdit", "autoJob", "reportPartner", "addJob", "addMaterials", "addCustomer", "ownReports", "modifyWorklogs", "timeReports", "billReports", "workShifts", "manageBills", "registerKey", "returnCustomer", "manageKeys", "jobModels", "jobTemplates", "products", "customers", "vehicles", "email"];

        console.log(employee.settings)
        console.log(field);
        const setting = employee.settings.permissions[field];

        if (!!setting && fieldArr.some(opt => setting[opt])) {
            return <Done style={{ height: 20, width: 20 }} />
        }
        else {
            return <div></div>;
        }
    };

    const onPermissionChange = (event, employee, setting, field) => {
        if (setting === "mainUser") {

            employee.settings.permissions.mainUser = event.target.value !== "true";

        }

        else if (field === "admin") {
            if (!employee.settings.permissions[setting][field]) {  //if admin key is not exist, happens when adding new settings
                employee.settings.permissions[setting][field] = event.target.value !== "true"
            }
            // Checking admin will change all subModules to true
            const settingObj = Object.keys(employee.settings.permissions[setting]);
            settingObj.forEach(key => {
                employee.settings.permissions[setting][key] = event.target.value !== "true";
            });
        }

        else {
            if (!employee.settings.permissions[setting]) {
                employee.settings.permissions[setting] = {}
            }
            console.log('employee permissions', employee.settings.permissions)
            employee.settings.permissions[setting][field] = event.target.value !== "true";

            // Categories keys and time have web and mobile settings
            // If value under mobile or web gets changed -> change mobile or web true as well
            if (setting === "time" || setting === "keys") {
                if (setting === "time") {
                    employee.settings.permissions.time.mobileApplication = timeSubPermissionsMobile.some(setting => employee.settings.permissions.time[setting]);
                    employee.settings.permissions.time.webApplication = timeSubPermissionsWeb.some(setting => employee.settings.permissions.time[setting]);
                }
                else {
                    employee.settings.permissions.keys.mobileApplication = keySubPermissionsMobile.some(setting => employee.settings.permissions.keys[setting]);
                    employee.settings.permissions.keys.webApplication = keySubPermissionsWeb.some(setting => employee.settings.permissions.keys[setting]);
                }
            }

            // Send updated value back to ModuleSettings
        }
        props.onChange(employee);
    };

    const onPermissionCategoryPlatform = (employee, setting, platform) => {
        if (setting === "time") {
            if (platform === "mobile") {
                const direction = !employee.settings.permissions.time.mobileApplication;
                timeSubPermissionsMobile.forEach(setting => {
                    employee.settings.permissions.time[setting] = direction;
                });

                employee.settings.permissions.time.mobileApplication = direction;
            }
            else {
                const direction = !employee.settings.permissions.time.webApplication;
                timeSubPermissionsWeb.forEach(setting => {
                    employee.settings.permissions.time[setting] = direction;
                });

                employee.settings.permissions.time.webApplication = direction;
            }
        }
        else {
            if (platform === "mobile") {
                const direction = !employee.settings.permissions.keys.mobileApplication;
                keySubPermissionsMobile.forEach(setting => {
                    employee.settings.permissions.keys[setting] = direction;
                });

                employee.settings.permissions.keys.mobileApplication = direction;
            }
            else {
                const direction = !employee.settings.permissions.keys.webApplication;
                keySubPermissionsWeb.forEach(setting => {
                    employee.settings.permissions.keys[setting] = direction;
                });

                employee.settings.permissions.keys.webApplication = direction;
            }
        }

        props.onChange(employee);
    };

    return (
        <div>
            <div className={userOpen === employee.id ? "list-item-container-noBorder" : " list-item-container"} onClick={() => props.openUser(employee)}>
                <p>{employee.name}</p>
                <p className="list-item-center">{checkMainUser()}</p>
                <p className="list-item-center">{checkMark("management")}</p>
                <p className="list-item-center">{checkMark("time")}</p>
                <p className="list-item-center">{checkMark("reports")}</p>
                <p className="list-item-center">{checkMark("billing")}</p>
                <p className="list-item-center">{checkMark("crm")}</p>
                <p className="list-item-center">{checkMark("keys")}</p>
                <p className="list-item-center">{checkMark("spotOn")}</p>
                <p className="list-item-center">{checkMark("registry")}</p>
                
            </div>

            <Collapse in={userOpen === employee.id}>

                <ModuleUserPermissions
                    selectedUser={selectedUser}
                    openItem={props.openItem}
                    itemOpen={props.itemOpen}
                    onCategoryChange={onPermissionCategoryPlatform}
                    onChange={onPermissionChange}
                />

            </Collapse>

        </div>

    )
};

export default withTranslation()(ModuleUserListItem);