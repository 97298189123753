import React, {Component} from 'react';

import {Checkbox, ClickAwayListener} from "@material-ui/core";
import {withTranslation} from "react-i18next";
import {withStyles} from "@material-ui/styles";

import ActionMenu from "./ActionMenu";
import CheckboxWithSelect from "./CheckboxWithSelect";
import classNames from 'classnames';

import "./ListAreaItem.css";
const CustomCheckbox = withStyles({
    root: {
        color: "#000",
        '&$checked': {
            color: "#000"
        },
    },
    checked: {},
})(props => <Checkbox color="default" {...props} />);

class ListAreaItem extends Component{
    constructor(props) {
        super(props);
        this.state = {
            anchor: null
        }
    }

    handleClick = event => {
        const currentTarget = event.currentTarget;
        this.setState({ anchor: currentTarget.getBoundingClientRect()})
    };



    render() {
        const {anchor} = this.state;
        const {show} = this.props;

        const listClass = classNames({
            "listAreaItem": true,
            "listAreaItem smallItem": this.props.size === "small",
            "listAreaItem mediumItem": this.props.size === "medium",
            "listAreaItem largeItem": this.props.size === "large",
            "listAreaItem fullWidth": this.props.size === "fullWidth",
            "listAreaItem checkBox": this.props.size === "checkbox",
            "check-show": this.props.size === "checkbox" && show,
        });

        return (
            <div className={listClass} style={{marginLeft: this.props.icon ? 20 : 0, ...this.props.style}}>
                {this.props.children}

                {this.props.size === "checkbox" && this.props.variant === "list" &&
                    <CustomCheckbox
                        style={{...this.props.style, padding: 0, margin: 0}}
                        checked={this.props.checked}
                        onChange={this.props.onChange}
                    />
                }

                {this.props.size === "checkbox" && this.props.variant === "title" &&
                    <CheckboxWithSelect
                        checked={this.props.checked}
                        onChange={this.props.onChange}
                        onClick={this.handleClick}
                    />
                }

                {this.props.icon &&
                    <div className="item-icon">
                        {this.props.icon}
                    </div>
                }

                {Boolean(anchor) &&
                    <ActionMenu
                        anchor={this.state.anchor}
                        menuActions={this.props.menuActions}
                        onClickAway={() => this.setState({ anchor: null })}
                        onSelect={this.props.onSelect}
                        t={this.props.t}
                    />
                }

            </div>
        );
    }
}

export default withTranslation()(ListAreaItem);
