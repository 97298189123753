
export default {
    translation: {
        //Navigaatio + HomeDetails
        "Mobility": "Helppotyö",
        "Sign in": "Kirjaudu",
        "Register": "Rekisteröidy",
        "Dashboard": "Ohjausnäkymä",
        "Management": "Hallinta",
        "Calendar": "Kalenteri",
        "Jobcards": "Työkortit",
        "Worklogs": "Työlokit",
        "Schedule": "Etsi aikoja",
        "Billing": "Laskutus",
        "Create bills": "Luo laskuja",
        "Reports": "Raportit",
        "Created reports": "Luodut",
        "Time reports": "Aikaraportit",
        "Bill reports": "Laskuraportit",
        "Registry": "Rekisteri",
        "Job models": "Työmallit",
        "Job templates": "Työpohjat",
        "Customers": "Asiakkaat",
        "Employees": "Käyttäjät",
        "Products": "Tuotteet",
        "Key": "Avain",
        "Keys": "Avaimet",
        "Settings": "Asetukset",
        "Log out": "Kirjaudu ulos",
        "Audit": "Toimintaloki",
        "Created bills": "Luodut",
        "CRM": "CRM",
        "SpotOn": "Ajot",
        "Recipients": "Vastaanottaja(t)",

        //register
        "Enter code": "Anna koodi",
        "Create company": "Lisää yritys",
        "Create user": "Lisää pääkäyttäjä",
        "Create sample data": "Lisää esimerkki data",
        "Validate": "Vahvista", 
        "Code": "Koodi",
        
        "Back": "Takaisin",
        "Next": "Seuraava",
        "Save": "Tallenna",
        "Company name": "Yrityksen nimi",
        "Company address": "Katuosoite",
        "Company city": "Kaupunki",
        "Company postCode": "Postinumero",
        "Company phone": "Puhelin",
        "Company email": "Sähköposti",
        "Company line of business": "Toimiala",
        "Company ID": "Y-Tunnus",

        "First name": "Etunimi",
        "Family name": "Sukunimi",
        "Address": "Katuosoite",
        "City": "Kaupunki",
        "PostCode": "Postinumero",
        
        "Repeat password": "Anna salasana uudelleen",
        "Copy email from company": "Kopioi sähköposti yrityksen tiedoista",
        "Copy phone from company": "Kopioi puhelinnumero yrityksen tiedoista",
        "Copy address from company": "Kopioi osoitetiedot yrityksen tiedoista",
        
        "Register create example products": "Luo esimerkkituotteet ja työpohjat yritykselle. Voit muokata näitä tai poistaa myöhemmin.",
        "Register max example products": "Max 10 tuotetta sallittu tässä kohdin - voit myöhemmin lisätä tai poistaa niitä tuoterekisteristä",
        "Register add example product" : "Lisää esimerkki tuote",
        "Register max example jobModels": "Max 3 työmallia sallittu tässä kohdin - voit myöhemmin lisätä tai poistaa niitä työmallit-rekisteristä",
        "Register add example jobModel" : "Lisää esimerkki työmalli",
        "Register remove example data": "Poista esimerkkituotteet",

        //Nappulat + textFieldit + random
        "Search": "Etsi",
        "Show": "Näytä",
        "Sort": "Järjestä",
        "Add new": "Lisää uusi",
        "Toggle select": "Valinta tila",
        "Import": "Tuo",
        "Active": "Aktivoi",
        "Active_sub": "Aktiivinen",
        "Inactive": "Poista käytöstä",
        "Inactive_sub": "Pois käytöstä",
        "Delete": "Poista",
        "All": "Kaikki",
        "Edit": "Muokkaa",
        "Back": "Takaisin",
        "Add": "Lisää",
        "Worksites": "Työkohteet",
        "Home": "Koti",
        "Work": "Työ",
        "Phone": "Puhelin (työ/koti)",
        "Phone Work Short": " (työ)",
        "Phone Home Short": " (koti)",
        "Email": "Sähköposti",
        "Default": "Oletus",
        "Yes": "Kyllä",
        "No": "Ei",
        "Save": "Tallenna",
        "Cancel": "Peruuta",
        "Reset": "Nollaa",
        "Confirm": "Vahvista",
        "Close": "Sulje",
        // Customer Editor (almost everything is just fields)
        "Name": "Nimi",
        "Street address": "Katuosoite",
        "Street address ext": "Osoitteen tarkenne",
        "City": "Kaupunki",
        "Post code": "Postinumero",
        "Country": "Valtio",
        "Website": "Web-sivusto",
        "Additional info": "Lisätieto",
        "Customer number": "Asiakasnumero",
        "Add customer": "Lisää asiakas",
        "Edit customer": "Muokkaa asiakastietoja",
        "Use_worksite_ce": "Lisää työkohde samoilla tiedoilla",
        "Add_worksite_ce": "Lisää erillinen työkohde",
        "TimeToPay": "Maksuaika / päivää",
        "BillingMethod": "Laskutustapa",
        "CustomerReference": "Asiakkaan viite",
        "OurReference": "Viitteemme",
        "OperatorIdentifier": "Operaattoritunnus",
        "E-InvoiceAddress": "Verkkolaskutusosoite",
        "ElectricAPIX": "Sähköinen / APIX",
        "BillingDetails": "Laskutustiedot",
        "CompanyName": "Yrityksen nimi",
        "References": "Viitteet",
        "Y": "K",
        "N": "E",
        "Actives": "Aktiiviset",
        "Deactivated": "Pois käytöstä",
        "ImportingCustomers": "Asiakkaiden tuonti kestää noin",
        "WorksiteCoord": "Työkohteille haetaan koordinaatit",
        "Customer_bill_delivery_method": "Laskun toimitustapa (Laskutusmaksun peruste)",
        "Customer_bill_delivery_method_einvoice": "eLasku tai Verkkolasku",
        "Customer_bill_delivery_method_netposti": "NetPosti",
        "Customer_bill_delivery_method_email": "Sähköpostilasku",
        "Customer_bill_delivery_method_confirmed_email": "Varmennettu sähköpostilasku",
        "Customer_bill_delivery_method_paper_bill": "Paperilasku",

        //Employee Editor
        "Add employee": "Lisää käyttäjä",
        "Edit employee": "Muokkaa käyttäjää",
        // Worksite / dialog / customer editor fields (Just fields basically)
        "New worksite": "Uusi työkohde",
        "Edit worksite": "Muokkaa työkohdetta",
        "Worksite name": "Nimi",
        "Worksite number": "Numero",
        "Worksite address": "Osoite",
        "Instructions": "Ohjeet",
        "PrivateCustomer": "Yksityisasiakas",
        "CompanyCustomer": "Yritysasiakas",
        // Worklogs
        "Search customers": "Etsi asiakas",
        "Search employees": "Etsi käyttäjä",
        "Status": "Tila",
        "notReported": "Ei raportoitu",
        "all": "Kaikki",
        "Not reported": "Ei raportoitu",
        "Cancelled": "Peruutettu",
        "Ongoing": "Käynnissä",
        "Finished": "Lopetettu",
        "Submitted": "Toimitettu",
        "Confirmed": "Hyväksytty",
        "Billed": "Laskutettu",
        "Archived": "Arkistoitu",
        "Select": "Valitse",
        "Customer": "Asiakas",
        "Worksite": "Työkohde",
        "Employee": "Käyttäjä",
        "Date": "Päivä",
        "Time": "Työkirjaus",
        "Billable hours": "Laskutettava aika",
        "Total hours": "Kokonaisaika",
        "Billable per task": "Lisää tehtävä",
        "Check result": "Tarkistus",
        "No_Customer": "Ei asiakasta ",
        "Scheduled": "Aikataulutettu",
        "Work time": "Työaika",
        "Worklog previous status": "Tila ennen laskutusta",
        

        // worklog checks
        "Billable sums mismatch": "Ero summissa",
        "Billable time per task missing": "Tehtävä puuttuu",
        "Billable task unit missing": "Ajan yksikkö puuttuu",
        "Billable task unit without duration": "Tehtävä ilman kestoa",
        "No employee": "Ei käyttäjää",
        "No_task_selected":"ei tehtävää",
        //
        "Change status": "Muuta tilaa",
        "Number_of_selected": "Valittujen lkm ",
        "Change_message": "Muuta kaikkien valittujen tila",
        "Edit worklog": "Muokkaa lokia",
        "Add worklog": "Lisää loki",
        "Materials": "Materiaalit",
        "Assigned_emp": "Käyttäjä(t)",
        "By task": "Toiminnottain",
        "Billable_missing": "Laskutettava aika toiminnottain puuttuu",
        "Customer template": "Asiakkaan työpohja",
        "No template": "Ei työpohjaa",
        "Add_task_material": "Lisää toiminto / materiaali",
        "ADD": "LISÄÄ",
        "Start address": "Aloitusosoite",
        "End address": "Lopetusosoite",
        "Images": "Kuvat",
        "additionalDescription": "Lisäkuvaus",
        "Show_cancelled": "Näytä peruutetut",
        "DeleteJob": "Poista työ",
        "Saved_succesfully": "Tallennettu onnistuneesti",
        "Worklogs count": "Työlokien määrä",
        "Worklog total duration": "Työaika yhteensä",
        "Worklog total billable": "Laskutettava / maksettava aika yhteensä",
        "Customer_name": "Asiakkaan nimi:",
        "Worksite_name": "Työkohde:",
        "Worksite_address": "Kohteen osoite:",

        //WorkDay
        "Employee_name": "Työntekijä",
        "WorkDays": "Työpäivät",
        "Employee": "Käyttäjä",
        "Date": "Päivämäärä",
        "DayStartTime": "Aloitusaika",
        "DayEndTime": "Lopetusaika",
        "DayTimeSpan": "Aikaväli",
        "DayStartLocation": "Aloitus sijainti",
        "DayEndLocation": "Lopetus sijainti",
        "Pauses summary": "Tauot (lkm (min))",
        "Work time": "Työaika",
        "Billable hours": "Laskutettava aika",
        "Billable time": "Laskutettava aika",
        "Payable time": "Maksetettava aika",
        "End": "Lopeta",
        "Workday time until now ongoing": "Käynnissä ",
        "Total time": "Kokonaiskesto",
        "Total working time": "Kokonaisaika",
        "Pauses": "Tauot", 
        "Normal time": "Päivätyö", 
        "Evening time": "Iltatyö",
        "Night time": "Yötyö",
        "Saturday short": "La",
        "Sunday short": "Su",



        // Billing
        "Add_bill": "Uusi lasku",
        "Template": "Työpohja",
        "Task": "Toiminto",
        "Product": "Tuote",
        "Unit": "Yksikkö",
        "Bill": "Lasku",
        "No task": "Ei laskutettavaa tuotetta",
        "Amount": "Määrä",
        "Select product": "Valitse tuote",
        "Search product": "Etsi tuote",
        "All_products": "Kaikki tuotteet",
        "BillsSavedSuccess": "Laskut luotiin onnistuneesti",
        "BillsSavedFailure": "Laskujen luomisessa virhe",
        "BillsGenerating": "Luodaan laskuja",

        "Bills count": "Laskujen määrä",
        "Bills sum": "Laskujen summa",
        "Bill_date": "Laskun päivä",
        "Billable cancellation": "Myöhäinen peruutus tai peruuttamaton käynti",
        "Billable hours not found": "Laskutettavia tunteja ei löytynyt. Huomaathan että töiden on oltava Hyväksytty -tilassa",
        "errCreateBillProductRow": "Virhe laskurivien luonnissa",
        "onCustomer": "Asiakkaalle",
        "errCreateBillProductRow": "Virhe laskurivien luonnissa",
        "errProduct not found": "Tuotetta ei löytynyt numerolla",

        //reports
        "GoToReportList": "Raporttilistaan",
        "Filters": "Suodattimet",
        "Enter search text": "Kirjoita haku",
        "Enter search info text": "paina enter hakeaksesi",
        "More Filters Info":"Lisää suodattimia kenttien otsikoissa",
        // TimeReports
        "Report type": "Tyyppi",
        "Select type": "Valitse tyyppi",
        "Period": "Jakso",
        "Worktime": "Työaika",
        "Approval": "Vahvistus",
        "Select period": "Valitse jakso",
        "Customer report": "Asiakas",
        "Customer report period": "Asiakasraportti aikavälille: ",
        "Employee report period": "Työntekijän tuntiraportti",
        "Employee report worktime": "Työntekijän työaikaraportti",
        "Weeks": "Viikot",
        "Week": "Viikko",
        "Error_date": "Valitse aikajakso ennen jatkamista",
        "Send to": "Vastaanottaja",
        "Pdf name": "Pdf nimi",
        "Email title": "Otsikko",
        "Message": "Viesti",
        "Send": "Lähetä",
        "Work time": "Työaika",
        "Hour": "Tunti",
        "Customer": "Asiakas",
        "Select range": "Valitse aikaväli",
        "ReportDescription": "Raportin rivien kuvaus",
        "FromTasks": "Toimintojen nimistä",
        "FromDescription": "Lokin lisäkuvauksesta",
        "Select worksite": "Valitse työkohde",
        "No worksite": "Ei kohdetta",
        "Select period": "Valitse jakso",
        "ByBillableTime": "Laskutettavan ajan mukaan",
        "ByExactTime": "Tarkan ajan mukaan",
        "Unreported task": "Raportoimaton tehtävä",


        "Report advice some hours not submitted": "Huom! Osaa jakson töistä ei ole toimitettu. He eivät sisälly raporttiin",
        "Report advice hours submitted": "Huomaathan että raportoitavien töiden on oltava toimitettu tilassa",
        "Report advice hours confirmed": "Huomaathan että raportoitavien töiden on oltava Hyväksytty -tilassa",

        "Billing advice hours confirmed": "Huomaathan että laskutettavien töiden on oltava Hyväksytty -tilassa",
        "Billing advice some hours not confirmed": "Huom! Osaa jakson töistä ei ole vahvistettu. He eivät sisälly laskutukseen",


        // BillReports
        "Period bill report": "Laskulista",
        "Annual ktv bill report": "Kotitalousvähennys",
        "Bill amount": "Yhteensä",
        "Bill vat": "ALV",
        "Bill amount without vat": "Veroton",
        "Bill work charge amount": "Työn osuus",
        "KTV material fees amount": "Materiaalit",
        "KTV billing fees amount": "Laskutuslisät",
        "KTV report period": "Kotitalousvähennys aikaväliltä",

        "KTV Total": "Laskujen summa yhteensä:",
        "KTV totalBillingFees": "Laskutus tai rahtimaksut yhteensä:",
        "KTV totalMaterialFees": "Materiaalit yhteensä:",
        "KTV totalLaborFees": "Kotitalousvähennykseen oikeuttava työn osuus yhteensä:",

        //Period bill
        "Bill Recipient": "Saaja",
        "Bill status": "Tila",
        "Bill Total": "Laskujen summa yhteensä:",

        "Bill vat total": "Arvonlisävero yhteensä:",
        "Bill total amount without vat": "Yhteensä ilman arvonlisäveroa:",

        // Report listing and sending
        "Send report": "Lähetä",
        "Send eSign": "Allekirjoituta",
        "Preview report": "Katsele",
        "Report actions": "Toiminnot",
        "Report mass actions": "Massatoiminnot",
        "Change recipients": "Muuta vastaanottajia",
        "Selected recipients": "Valitut vastaanottajat",

        "Reports SendToDefault": "Olet lähettämässä kaikki valitut raportit oletusosoitteeseen",
        "Reports SendTo": "Olet lähettämässä kaikki valitut raportit ",
        "eSigned": "Sähköisesti varmennettu",

        "Open in new tab": "Avaa välilehdellä",
        "Open in new tab tooltip": "Lisäksi raportti luodaan ja tallennetaan raportit listalle ",


        "DEFAULT_REPORT_EMAIL_SUBJECT": "Raportti",
        "DEFAULT_REPORT_EMAIL_MESSAGE": "Hyvä vastaanottaja, ohessa raporttinne",
        // report types
        "HOUR_WAGE": "Tuntiraportti",
        "HOUR_WAGE_emailSubject": "Tuntiraportti",
        "HOUR_WAGE_emailMessage": "Hyvä vastaanottaja, liitteenä tuntiraporttinne",

        "PERIOD_FIXED": "Jaksoraportti",
        "PERIOD_FIXED_emailSubject": "Jaksoraportti",
        "PERIOD_FIXED_emailMessage": "Hyvä vastaanottaja, liitteenä jaksoraporttinne",

        "WORKTIME_PERIOD": "Työaikaraportti",
        "WORKTIME_PERIOD_emailSubject": "Työaikaraportti",
        "WORKTIME_PERIOD_emailMessage": "Hyvä vastaanottaja, liitteenä Työaikaraporttinte",


        "CUSTOMER_TIME": "Asiakasraportti",
        "CUSTOMER_TIME_emailSubject": "Asiakasraporttinne",
        "CUSTOMER_TIME_emailMessage": "Hyvä vastaanottaja, liitteenä aqsiakasraporttinne",

        "APPROVAL": "Vahvistusraportti",
        "APPROVAL_emailSubject": "Vahvistusraportti",
        "APPROVAL_emailMessage": "Hyvä vastaanottaja, liitteenä vahvistusraporttinne",

        "KTV": "Kotitalousvähennys",
        "KTV_emailSubject": "Kotitalousvähennysraporttinne",
        "KTV_emailMessage": "Hyvä vastaanottaja, liitteenä kotitalousvähennysraporttinne",

        "BILLS": "Laskuraportti",
        "BILLS_emailSubject": "Laskuraporttinne",
        "BILLS_emailMessage": "Hyvä vastaanottaja, liitteenä laskuraporttinne",

        "WORKLIST": "Työvuorolista",
        "WORKLIST_emailSubject": "Työvuorot",
        "WORKLIST_emailMessage": "Hyvä vastaanottaja, liitteenä työvuorolista",

        "Report page header title": "Sivu:",
        "Report page Company_id title": "Y-tunnus: ",

        //workshifts
        "Workshifts": "Työvuorot",
        "Workshift_weeks": "Työvuorot vkot:",
        "Workshift report created": "Luotu: ",
        "Workshift_report_partner_in_short": "/pari:",
        "Workdays count":"työpäivien lkm",

        //JobModels
        "Model name": "Mallin nimi",
        "Notes": "Kuvaus",
        "Add row": "Lisää rivi",
        "Delete model": "Poista malli",
        "Product number": "Tuotenumero",
        "Invoice text": "Laskun teksti",
        "Description": "Kuvaus",
        "Edit tasks": "Lisää tehtäviä",
        "Task_hint": "VIHJE: Lisää uusi tehtävä painamalla Enter",
        "Add task": "Lisää tehtävä",
        "Require material confirmation": "Vaadi materiaalin vahvistaminen",
        "Require": "Vaadi",
        "DefaultAmount": "Vakio määrä",
        // JobTemplates
        "Copy from model": "Kopio malli",
        "Select customers": "Valitse asiakkaita",
        "Template name": "Pohjan nimi",
        "Delete template": "Poista pohja",
        "Work number": "Työnumero",
        //Products
        "Billable": "Laskutettava",
        "Generate bill": "Autom. Laskutus",
        "Services": "Palvelut",
        "Add product": "Lisää tuote",
        "Edit product": "Muokkaa tuotetta",
        "Buy in price": "Sisäänostohinta",
        "Sell price": "Myyntihinta",
        "VAT": "ALV",
        "TODO list": "Tehtävälista",
        "Service": "Palvelu",
        "Material": "Materiaali",
        "Type": "Tyyppi",
        "Checkpoint": "Tallennuspiste",
        "Generate": "Generoi",
        "Print QR code": "Tulosta QR-koodi",
        "Title_opt": "Otsikko (Vapaaehtoinen)",
        "Msg_opt": "Teksti (Vapaaehtoinen)",
        "Travel": "Matka",
        "ProductNumber_short": "Tuote#",
        "ProductActive": "Aktiivinen",
        // Keys
        "Add key": "Lisää avain",
        "Edit key": "Muokkaa avainta",
        "Number": "Numero",
        "Holder": "Hallussa",
        "Received": "Vastaanotettu",
        "Returned": "Palautettu",
        "Info": "Tietoa",
        "Locker": "Kaappi",
        // Employees / users
        "Subcontractors": "Alihankkijat",
        "Subcontractor": "Alihankkija",
        "Personal information": "Henkilötiedot",
        "First name": "Etunimi",
        "Family name": "Sukunimi",
        "Contact information": "Yhteystiedot",
        "Home phone": "Puhelin (koti)",
        "Work phone": "Puhelin (työ)",
        "Company employee": "Yrityksen työntekijä",
        "Other": "Muut",
        "Company ID": "Y-tunnus",
        "Vehicles": "Autot",
        "plate number": "Rekisterinumero",
        "make": "Valmistaja",
        "model": "Malli",
        // Settings and what not
        "Settings work periods": "Työnajan jaksotuskaudet",
        "Settings period advice text": "Työn jaksoraportoinnissa käytettävä työajan tasoittumis jakso jonka puitteissa työaika ja ylityöt lasketaan. Jakson pituus voi olla max 4 viikkoa, järjestäytyneillä työnantajilla max 4kk.",
        "Settings period advice text cont": "Valitse haluamasi pituinen aloitusjatko kalenterista. Järjestelmä toistaa valitun pituista jaksoa johon raportointi perustuu.",
        "Start wk": "Aloitus vko",
        "End wk": "Lopetus vko",
        "Language": "Kieli",
        "Finnish": "Suomi",
        "English": "Englanti",
        "setting_week": "Viikko",
        "setting_weeks": "Viikkoa",
        "Work_deadline": "Töiden vahvistamisen määräaika",
        "Management start": "Toiminnanohjauksen aloitus",
        "Default_emp": "Optimaalinen työntekijöiden määrä",
        "Billable time rounding": "Laskutettavan ajan pyöristys",
        "starting_15": "Alkava 15min",
        "starting_30": "Alkava 30min",
        "starting_1": "Alkava 1h",
        "Bank name": "Pankin nimi",
        "Account number": "Tilinumero",
        "Bank Identification": "Pankin tunniste",
        //Calendar
        "Today": "Tänään",
        "Next_c": "Seur.",
        "Prev_c": "Edel.",
        "Show only this": "Näytä vain tämä",
        "Color": "Väri",
        "all-day": "koko päivä",
        //CancelDialog
        "Cancel job": "Peruuta työ",
        "Reason": "Syy",
        "Billing amount": "Laskutuksen suuruus",
        "Own notes": "Omat merkinnät",
        "No billing": "Ei veloitusta",
        "Every": "Joka",
        "day": "päivä",
        "days": "päivää",
        "week": "viikko",
        "weeks": "viikko",
        "month": "kuukausi",
        "months": "kuukausi",
        "Does not repeat": "Ei toistuvuutta",
        "Worksite": "Työkohde",
        "Edit event": "Muokkaa työtä",
        "Delete event": "Poista työ",
        "Edit recurring event": "Muokkaa toistuvaa työtä",
        "Delete recurring event": "Poista toistuva työ",
        "One time": "Tämä kerta",
        "This and onward": "Tämä ja jatkossa",
        "This and onward preserve incoming": "Tämä ja jatkossa (säilytä tulevat kertamuutokset)",
        "This and onward override": "Tämä ja jatkossa (poista tulevat kertamuutokset)",
        "Notice_disabled_future_pre": "Olet muuttanut työtä ",
        "Notice_disabled_future_post": " alkaen jatkuvaluonteisesti. Muutokset tähän työhön tulevat voimaan vain siihen saakka",
        "Notice_changes_in_future": "Olet muuttanut tätä toistuvaa työtä tulevaisuudessa. Jos olet tekemässä muutosta tulevaisuuteen, valitse mitä tuleville muutoksille tehdään.",
        "Range": "Aikaväli",
        "Delete all events": "Poista kaikki",
        "Error_dialog_msg": "Valitse muokkaus sääntö",
        // Fullscreen dialog
        "Edit job": "Muokkaa työtä",
        "Add job": "Lisää työ",
        "Repeats": "Toistuvuus",
        "Daily": "Joka päivä",
        "Weekly on": "Joka",
        "Every two weeks": "Joka toinen viikko",
        "Custom": "Mukautettu",
        "Job model": "Työmalli",
        "Create_template": "Luo työpohja asiakkaalle",
        "No_customer": "Asiakasta ei valittu",
        "Tasks": "Toiminnot",
        "Extra work": "Pyydetyt lisätehtävät",
        "Extra work tip": "Lisää pyydetyt lisätehtävät painamalla enter",
        "Title": "Otsikko",
        "Additional info": "Lisätieto",
        "Repeats every": "Toistuu joka",
        "Repeat on": "Viikonpäivät",
        "Recurrence_Mo": "M",
        "Recurrence_Tu": "T",
        "Recurrence_We": "K",
        "Recurrence_To": "T",
        "Recurrence_Fr": "P",
        "Recurrence_Sa": "L",
        "Recurrence_Su": "S",
        "Recurrence_End": "Loppuu",
        "Recurrecne_Never": "Ei koskaan",
        "Until": "Kunnes",
        "Repeat_Occ": "Kun on ollut",
        "occurrences": "toistokertaa",
        "max_employees": "Optimi määrä työntekijöitä",
        "On time start": "Aloitus sovittuna aikana",
        "End ontime": "Työn oltava tehty sovittuun aikaan mennessä",
        "Once impact": "Työtä muutettu tällä kerralla",
        "Onwards impact": "Työtä muutettu toistaiseksi ",
        "Onwards impact after text": " alkaen eteenpäin",
        "Range impact": "Työtä muutettu aikavälillä",
        "RecurringRuleForwardChange": "Toistuvuutta muutettu toistaiseksi",
        "ModifiedBy": "Muokkaaja: ",
        "CreatedBy": "Luoja: ",
        //PDFS
        "Monday": "Maanantai",
        "Generate_PDF": "Luo Raportti",
        "Tuesday": "Tiistai",
        "Wednesday": "Keskiviikko",
        "Thursday": "Torstai",
        "Friday": "Perjantai",
        "Saturday": "Lauantai",
        "Sunday": "Sunnuntai",
        "Summary": "Yhteenveto",
        "Evening": "Ilta",
        "Overtime": "Ylityö",
        "Total": "Yhteensä",
        "Period summary text": "Yhteenveto jakson työstä",
        "Period evening": "Iltatyö yhteensä",
        "Period overtime": "Ylityö yhteensä",
        "Period normaltime": "Normaali työaika yhteensä",
        "Period total": "Työ yhteensä",
        "Hour_PH": "Jaksoraportti",
        "Day_start": "Aloitus",
        "Day_end": "Lopetus",
        "Day_duration": "Päivän kesto",
        "Day_pauses": "Tauot/siirtymät",
        "Day_overtime": "Ylityö",
        "Day_evening": "Iltatyö",
        "Day_total": "Työaika",
        
        "No_billable_task": "Työlokissa ei laskutettuja tehtäviä",

        "WEEKS": "VIIKKO",
        "Signature": "Allekirjoitus",
        "Day": "Päivä",
        "Done job": "Tehty työ",
        "Sum": "yht",
        "Mo": "Ma",
        "Tu": "Ti",
        "We": "Ke",
        "Th": "To",
        "Fr": "Pe",
        "Sa": "La",
        "Su": "Su",
        // Bills
        "Created": "Luotu",
        "Customer name": "Asiakkaan nimi",
        "Delivery": "Lähetystapa",
        "Bill date": "Laskun pvm",
        "Sent": "Lähetetty",
        "Error":"Virhe",
        "InQue": "Jonossa",
        "Bill number": "Laskun#",
        "Bill information": "Laskun tiedot",
        "Credit_bill": "Hyvityslasku",
        "Time to pay": "Maksuaika",
        "Delivery terms": "Toimitusehdot",
        "Delivery type": "Toimitustapa",
        "Your order number": "Tilausnumeronne",
        "Reference number": "Viitenumero",
        "Bill date2": "Laskun päivä",
        "Due date": "Eräpäivä",
        "Delivery date": "Toimituspäivä",
        "Our reference": "Viitteemme",
        "Your reference": "Viitteenne",
        "Currency": "Valuutta",
        "VAT prices": "Verolliset hinnat",
        "Address+AI": "Osoitteet ja lisätiedot",
        "Billing address": "Laskutusosoite",
        "Postal code": "Postinro",
        "Address": "Osoite",
        "Delivery address": "Toimitusosoite",
        "Same+Delivery": "Sama kuin laskutusosoite",
        "Bill text": "Laskunteksti",
        "Notice": "Huomautus",
        "Bill+Product": "Tuotenro",
        "Ordered": "Tilattu",
        "Price": "Hinta",
        "Discount": "Alennus",
        "Margin": "Kate",
        "Account": "Tili",
        "Vat": "ALV",
        "Added_material": "Lisätty materiaali",
        "Summary_attachments": "Yhteenveto ja liitteet",
        "Freight_charge": "Rahtimaksu",
        "Billing_charge": "Laskutusmaksu",
        "Billing_charges": "Laskutusmaksut",
        "Billing_charge_depends_on_delivery_method": "Laskun toimitustapakohtainen laskutusmaksu",
        "Billing_charge_einvoice": "Verkkolasku tai eLasku",
        "Billing_charge_email": "Sähköpostilasku",
        "Billing_charge_confirmed_email": "Varmennettu sähköpostilasku",
        "Billing_charge_paper_bill": "Paperilasku",
        "Billing_charge_netposti_bill": "Netposti",
        "Billing_charge_unit": "€ (+ALV)",
        "Billing_charge_info": "Lisää laskun oletuslaskutusmaksu ja rahtimaksu. Voit myös valita sähköisessä laskutuksessa toimitustapakohtaisen laskutusmaksun.",
        "Billing_charge_depends_on_delivery_method_info": "Jos teet kuluttajalaskuja ja käytät sähköistä lähetystapaa, asiakkaasi pystyy itse valitsemaan toimitustavaksi paperikirjeen sijaan eLaskun tai Netpostin. Tämä tieto päivitetään laskuja lähetettäessä ja tulee voimaan seuraavalla laskutuskerralla kun uusi vastaaottotapa on aktivoitu. Muussa tapauksessa tämä voidaan päivittää asiakaskohtaisesti asiakkaan tiedoissa. Huomaathan että ensimmäinen lasku lähtee aina paperisena (APIX emailina) jonka jälkeen asiakas pystyy aktivoimaan eLaskun verkkopankissaan.",
        "Bill_discount": "Laskun alennus (%)",
        "Upload_attachment": "Lataa liite",
        "Show_attachment": "Näytä liite",
        "Total_we_vat": "Yht. ilman ALV",
        "Payable": "Maksettava",
        "Create": "Luo",
        "CreateCompensation": "Luo hyvityslasku",
        "Paid": "Maksettu",
        "created": "Luotu",
        "sent": "Lähetetty",
        "paid": "Maksettu",
        "archived": "Arkistoitu",
        "error" :"Virhe",
        "Selection": "Valinta",
        "SendAttachment": "Lähetä liite laskun mukana",
        "SendAttachmentSeparateEmail": "Lähetä liite sähköpostilla",
        "Send_attachment_email_button": "Lähetä sähköpostiliite",
        "TimeReportName": "Aikaraportti",
        "Email / APIX": "Sähköposti / APIX",
        "email / apix": "Sähköposti / APIX",
        "Manual": "Manuaali",
        "manual": "Manuaali",
        "Tasks": "Tehtävät",
        // Settings
        "Category": "Kategoria",
        "Common": "Yleiset",
        "Job": "Työ",
        "Company": "Yritys",
        "Modules": "Moduulit & käyttäjät",
        "User": "Käyttäjä",
        "Serial numbers": "Numerosarjat",
        "Sales invoice": "Myyntilasku",
        "Days": "Päivää",
        "Sending": "Lähetys",
        "Send method": "Lähetystapa(oletus)",
        "Talenom credentials": "Talenom-tunnukset",
        "Username": "Käyttäjänimi",
        "Password": "Salasana",
        //netvisor
        "Netvisor credentials": "Netvisor-tunnukset",
        "customerId": "Netvisor asiakastunnus ",
        "cid": "Y-tunnus",
        "privateKey": "Netvisor salainen avain",
        "Netvisor billingFees": "Laskutuslisät Netvisorissa",
        "Netvisor billingFees advice": "Jos laskutuslisiä käytetään, huomaa että laskutusmaksulle ja rahdille pitää olla omat tuotteet Netvisorissa",
        "netvisorBillingFeeProductNumber": "Netvisor laskutusmaksun  tuotenumero",
        "netvisorBillingFreightProductNumber": "Netvisor rahtimaksun tuotenumero",
        "netvisorBillingFeeAndFreightProductNumberAdvice": "Katso ohjeesta miten löydät Netvisor tuotenumeron. Huom. Tuotenumero EI Netvisorissa ole sama asia kuin tuotekoodi",
        

        //...netvisor
        "Texts": "Tekstit",
        "Email document": "Sähköpostin teksti",
        "Sender's name": "Lähettäjän nimi",
        "Sender": "Lähettäjä",
        "Subject": "Aihe",
        "Line of business": "Toimiala",
        "Main user": "Pääkäyttäjä",
        "Management_settings": "Toiminnanohjaus",
        "Administration": "Hallinto",
        "MainUser_CB": "Järjestelmän pääkäyttäjä",
        "Management_admin": "Toiminnanohjauksen ylläpitäjä",
        "Time_admin": "Työkirjauksen ylläpitäjä",
        "Reports_admin": "Raporttien ylläpitäjä",
        "Billing_admin": "Laskutuksen ylläpitäjä",
        "Keys_admin": "Avainten ylläpitäjä",
        "Registry_admin": "Rekisterin ylläpitäjä",
        "Crm_admin": "CRM ylläpitäjä",
        "CRM module": "CRM moduuli",
        "SpotOn_admin": "Ajoneuvojen ylläpitäjä",
        "Mobile application": "Mobiiliapplikaatio",
        "Automatic_tracking": "Automaattinen työnseuranta / kirjaus",
        "Log for partner": "Kirjaa aikaa parille",
        "Add new job": "Uuden työn lisääminen",
        "Add_materials": "Materiaalien lisääminen",
        "Add_customer": "Asiakkaan lisääminen",
        "Web application": "Webliittymä",
        "Check own reports": "Omien raporttien tarkastelu",
        "Edit_worklogs": "Työlokien muokkaus",
        "Manage_bills": "Hallitse laskuja",
        "Key module": "Avain moduuli",
        "SpotOn module": "Ajoneuvo moduuli",
        "Add_key_system": "Lisää avain järjestelmään",
        "Return_key_customer": "Palauta avain asiakkaalle",
        "Manage_keys": "Hallitse avaimia",
        "Mobile_devices": "Mobiililaitteet",
        "Print": "Tulostus",
        "Payment tracking": "Maksuvalvonta",
        "Payment_tracking_manual": "Manuaali",
        "Lahirahoitus": "Lähirahoitus",
        "Thirdparty": "Ulkoistettu",
        "Apix credentials": "Apix-tunnukset",
        "Apix_activation_text": "Käyttääksesi sähköisen laskutuksen palveluja, sinun pitää luoda Apix tunnukset ja syötää saamasi avaimet alla oleviin kenttiin",
        "Apix_activation_text_cont": "Lisätietoa: info@helppotyo.fi",
        "ApixTransferID": "Apix TransferID",
        "ApixTransferKey": "Apix TransferKey",
        "Accounting": "Kirjanpito",
        "Accountant": "Kirjanpitäjä",
        "Accounting email": "Kirjanpidon sähköposti",
        "Accounting_info_text": "Lisää kirjanpidon sähköpostiosoite oheen kirjanpitokopion lähettämiseksi sähköpostitse laskun lähetyksen yhteydessä",
        "None": "Ei",
        "talenom": "Talenom API",
        "netvisor": "Netvisor API",
        "Talenom": "Talenom API",
        "Netvisor": "Netvisor API",
        "lahirahoitus": "Lähirahoitus",
        "apix": "Sähköinen lasku (APIX)",
        "apix invoicing": "Sähköinen laskutus (APIX)",
        "no apix invoicing": "Aktivoi sähköinen laskutus saadaksesi lisää laskutustapoja",
        "print": "Tulostus",
        "email": "Sähköposti",
        "Workday start": "Työpäivän aloitus",
        "At the office": "Yrityksen toimistolta",
        "At first customer": "Ensimmäiseltä asiakkaalta",
        "Transfers": "Siirtymät",
        "Are paid": "Maksetaan",
        "Are not paid": "Ei makseta",
        "View jobs": "Tarkastele töitä",
        "Create_edit": "Luo ja muokkaa töitä",
        "Accountant email": "Kirjanpitäjän sähköposti",
        "Billing_fees": "Laskutuksen maksut",
        "Report texts": "Raportin tekstit",
        "FreeText": "Vapaa kuvaus",
        "Bill_logo": "Laskun logo",
        "Bill_logo_instruction": "Selaa tai vedä laskun logo (116x116px) tähän",
        //Bill timereport settings
        "Timereport sending": "Aikaraportin lähetys",
        "Send timereport as bill attachment": "Lähetä aikaraportti laskun liitteenä",
        "Send timereport as bill attachment description": "tämä ei välttämättä mene verkkopankkeihin",
        "Send timereport as separate email":"Lähetä aikaraportti erikseen sähköpostilla",
        "Send timereport as separate email description":"tämä menee erillisenä sähköpostina asiakkaalle",

        // Tutorials
        // JobModels
        "modelTitle1": "Toiminnon nimi",
        "modelMsg1": "Työmallissa toiminnon nimeen voi laittaa sisäiseen käyttöön tarkoitetun termin, jotä käytetään työn ohajuksessa ja kirjauksessa. Tämä ei näy asiakkaalle taikka laskulla. Tämä voi olla esim maalaus, rappaus, pesu, tyhjennys yms",
        "modelTitle2": "Tuotenumero",
        "modelMsg2": "Tuotenumerolla valitaan toimintoa vastaava laskutettava tuote. Tuote voi olla laskutettavaa aikaa, tuntikirjaus, materiaali, kilometri tai todennuspiste. Samaa tuotetta voidaan käyttää eri toiminnoille, esim toiminnoille siivous, silitys ja mattojen tamppaus, voidaan käyttää tuotetta \"Kodintoitotyöt Helsinki\"",
        "modelTitle3": "Laskun teksti",
        "modelMsg3": "Laskun teksti näytetään asiakkaan laskusssa. Se voidaan muokata työmallissa sekä työpohjassa yleiseksi koskien työmallin asiakasryhmää, esim \"korjaustyö Helsinki\". Tämä voidaan työpohjassa vielä muokata asiakaskohtaiseksi, esim \"korjaustyö Helsingin Yrityshautomo Oy\". Oletuksena laskun teksti perustuu tuotteessa olevaan laskun tekstiin.",
        "modelTitle4": "Toiminnon kuvaus",
        "modelMsg4": "Toiminnon kuvaukseen voit laittaa toimintoon liittyviä muistiinpanoja tai liseäselvityksiä. Nämä ovat sisäiseen käyttöön eivätkä näy asiakkaille eikä raporteissa.",
        "modelTitle5": "Yksikkö",
        "modelMsg5": "Kirjattavan toiminnon yksikkö riippuu tuotteesta. Yksikkö voidaan vapaasti määritellä, mutta yleensä esim h,kpl,l,m3 jne... ",
        "modelTitle6": "Lisää",
        "modelMsg6": "Lisäys painikkeestä pääsee määrittelemään toiminntoon kuuluvia pienempis tehtäviä jotka tekijän on kuitattava tehdyksi, esim kukkien kastelu, saranoiden voitelu. Lisäksi materiaali ja kilometrityyppisiin toimintoihin voidaan valita oletukset sekä se vaaditaanko tuote aina, esim sovitut kilometrit 50 tai tonni hiekotussepeliä lisättävä aina",
        // Tooltips
        "Tooltip_eInvoice": "Sähköinen lasku pitää sisällään tulostus- sekä postituspalvelun, eLaskun, netpostin ja verkkolaskun",
        // AuditLog
        "Started_work": "aloitti työn",
        "Finished_work": "lopetti työn",
        "No_worksite": "Ei työkohdetta",
        "Delete_work": "poisti työlokin",
        "Added_materials": "lisäsi materiaalin",
        "Changed_task": "vaihtoi toimintoa",
        //CRM email
        "Title_required": "Otsikko on pakollinen",
        "Message_required": "Viesti on pakollinen",
        "Sender_required": "Lähettäjän sähköposti on pakollinen",
        "Invalid_email": "Viallinen sähköpostiosoite",
        "Attachment_required": "Liitteen nimi on pakollinen",
        "SelectedCustomers_required": "Valitse vähintään yksi asiakas",
        "Emails_sent": "Sähköpostit lähetetty",
        //ListItem
        "Back": "Takaisin",
        "status": "Tila",
        "sendMethod": "Oletuslähetystapa",
        "Attention": "Huomio!",
        "ChangeTo": "Olet muuttamassa kaikkien valituiden",
        "SendTo": "Olet lähettämässä kaikki valitut laskut",
        "Bills": "laskujen",
        "active": "Aktiivinen",
        "type": "Tyyppi",
        "material": "Materiaali",
        "service": "Palvelu",
        "checkpoint": "Tallennuspiste",
        "travel": "Matka",
        "ProductsOwn": "tuotteiden",
        "BillsOwn": "Laskut",
        "Addresses": "Osoitteet",
        "customers": "asiakkaiden",
        "Complete": "Valmis",
        "send": "Lähetä",
        "sendReports": "Lähetä valitut",
        "sendReportsTo": "Lähetä osoitteeseen...",
        // Validation
        "CID": "Virhe tallennuksessa",
        "CN_required": "Vaadittu arvo",
        "CN_duplicate": "Asiakasnumero on jo olemassa",
        "NameRequired": "Vaadittu arvo",
        "InvalidPhone": "Virheellinen puhelinnumero",
        "InvalidEmail": "Virheellinen sähköposti",
        "AddressRequired": "Osoite pitää antaa",
        "JM_NameRequired": "Mallin nimi vaaditaan",
        "JM_TaskNameRequired": "Toiminnon nimet vaaditaan",
        "JM_PnumRequired": "Tuotenumerot vaaditaan",
        "JM_unitRequired": "Yksiköt vaaditaan",
        "JM_TasksRequired": "Tehtävät vaaditaan",
        "JM_Required": "Vaadittu arvo",
        //GloablDialog
        "ActionConfirmation": "Oletko varma että haluat",
        "DeleteAction": "poistaa",
        "CreateAction": "luoda",
        "DiscardAction": "hylätä",
        "EndAction": "lopettaa",
        "DeleteActionEmployee": "käyttäjän",
        "DeleteActionCustomer": "asiakkaan",
        "DeleteActionJobModel": "työmallin",
        "DeleteActionJobTemplate": "työpohjan",
        "DeleteActionKey": "avaimen",
        "DeleteActionProduct": "tuotteen",
        "DeleteActionWorklog": "työlokin",
        "ActionWorkday": "työpäivän",
        "DiscardActionJob": "työn",
        "DeleteActionBill": "laskun",
        "CreateActionCompensation": "hyvityslaskun",
        //
        "ActionCannotRollback": "Toimintoa ei voi perua",
        //Toast messages
        "No bills created": "laskuja ei luotu",
        "Sending timereport by email succesful": "Aikaraportti lähetetty sähköpostiin",


        //errors
        "errProductNotFound": "Tuotetta ei löydy",
        "errProductNumberNotFound": "Tuotenumeroa ei ole määritelty",
        "errProductNumber": "Tuotenumero: ",
        "errBillNumber": "laskussa nro",
        "errBillNumberError": "virheteksti",
        "errCustomerNotFound": "Asiakasta ei löydy",
        "errCustomerID": "Asiakkaan id",
        "errEmployeeNotFound": "Työntekijää ei löydy",
        "errEmployeeID": "Työntekijän id",
        "errGetCustomerTemplateForInvoice": "Virhe työpohjaa haettaessa",
        
        //bill sendin errors
        "uploadedToQueue": "Lasku lähetetty jonoon",
        "deliveredFromQueue": "Lasku lähetetty jonosta",
        "failedFromQueue": "Lähetysvirhe jonossa",
        "Sending failed": "Lähetysvirhe",
        "errEmptyError": "Tyhjä virheilmoitus",
        "errStatus": "Rajapintavirhe",
        "errNetworkError": "Verkkovirhe, yrittä myöhemmin uudelleen",
        "errUploadAttachment": "Virhe laskun liitteen lataamisessa",
        "errUploadQueue": "Jonoon lähetyksessä virhe",
        "errBillQueueEmptyResponse": "Laskutuspalvelimen virhe",
        "errBillQueueEmptyResponseDescription": "Laskutusserverin vastaus tyhjä tai tuntematon. Tarkasta laskun läpimeno laskutusrajapinnasta",
        "errBillQueueBatchCommit": "Tietokantavirhe",
        "errBillQueueMassStatusCheckAuthError": "Todennusvirhe",
        "errBillQueueMassStatusCheckAuthErrorDescription" : "Todennusvirhe, yritä myöhemmin uudelleen. Mikäli ongelma toistuu ole yhteydessä tukeen",
        
        "IdentifiedIssues": "Mahdolliset virheen syyt: ",
        "FullOriginalError": "Alkuperäinen virheilmoitus",
        "errMissingBuyerEmail": "Laskun vastaanottajan sähköposti puuttuu tai on virheellinen",
        "errMissingBuyerPostalCode": "Laskun vastaanottajan postinumero puuttuu tai on virheellinen",
        "errMissingBuyerCity": "Laskun vastaanottajan osoitteen kaupunki puuttuu  tai on virheellinen",
        "Laskun kuva(PDF) puuttuu": "Laskun liite ei mennyt oikein. Tarkista onko lähettäjän nimessä erikoismerkkejä. Muussa tapauksessa lähetä lasku ilman liitettä tai ota yhteys tukeen.",
        
        //
        "errNetvisorProductNotfound": "Tuotetta ei löydy kirjanpitorajapinnasta. Tarkista että tuotenumero vastaa Netvisorin tuotenumeroa. Huom! Netvisorin numero ei ole sama kuin tuotekoodi (katso ohje miten tuotenumero löytyy).",
        "errNetvisorCustomerNotfound": "Asiakasta ei löydy kirjanpitorajapinnasta. Tarkista että asiakasnumero Netvisorin asiakas tuotenumeroa.",

        //timereportt sending 
        "Email sending failed": "Virhe laskun liitteen lähettämisessä",
        "Email sending format error": "Laskun liitteen lähetys virhe. Sähköpostin muotovirhe: ",
        "Email sending failed desc": "Laskun liitettä ei voitu lähettää sähköpostilla, virhe: ",
    }
}
