import React, {useState} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField
} from "@material-ui/core";

import {withTranslation} from "react-i18next";
import "./ProductSelectDialog.css";
const ProductSelectDialog = props => {
    const {t} = props;

    const [searchTerm, setSearchTerm] = useState("");
    const [searchTemplateList, setSearchTemplateList] = useState([]);
    const [searchList, setSearchList] = useState([]);

    const searchProducts = event => {
        const searchWithValue = event.target.value;

        const productList = [];
        const templateList = [];

        props.products && props.products.forEach(product => {
            const searchValue = `${product.pNumber} ${product.name}`;

            if (searchValue.toLowerCase().search(searchWithValue.toLowerCase()) > -1) productList.push(product);
        });

        props.customerTemplates && props.customerTemplates.forEach(template => {
            const newTemplate = {
                name: template.name,
                tasks: [],
                id: template.id
            };

            template.tasks && template.tasks.forEach(task => {
                const searchValue = `${task.pNumber} ${task.name}`;

                if (searchValue.toLowerCase().search(searchWithValue.toLowerCase()) > -1) newTemplate.tasks.push(task);

            });
            if (template.tasks.length > 0) templateList.push(newTemplate)
        });

        setSearchTerm(searchWithValue);
        setSearchTemplateList(templateList);
        setSearchList(productList);

    };

    const renderCustomerTemplates = template => {

        return (
            <div key={template.id}>
                <h4>{template.name}</h4>
                {template.tasks && template.tasks.map(task => (
                    <div className="product-item" key={template.name + task.name} onClick={() => props.onProductSelect(task)}>
                        <p style={{width: '20%', textAlign: 'center'}}>{task.pNumber}</p>
                        <p style={{width: '80%', textAlign: 'center'}}>{task.name}</p>
                    </div>
                ))}
            </div>
        );
    };

    const renderItem = item => {
        return (
            <div className="product-item" key={item.id} onClick={() => props.onProductSelect(item)}>
                <p style={{width: '20%', textAlign: 'center'}}>{item.pNumber}</p>
                <p style={{width: '80%', textAlign: 'center'}}>{item.name}</p>
            </div>
        );
    };

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle style={{textAlign: 'center'}}>{t("Select product")}</DialogTitle>
            <DialogContent>
                <TextField
                    variant="outlined"
                    label={t("Search product")}
                    style={{
                        marginTop: 10,
                        textAlign: 'center'
                    }}
                    value={searchTerm}
                    onChange={event => searchProducts(event)}
                    fullWidth
                />

                <div style={{height: 400, overFlow: "hidden", width: '100%'}}>
                    {searchTerm.length === 0 && props.customerTemplates.map(renderCustomerTemplates)}
                    {searchTerm.length > 0 && searchTemplateList.map(renderCustomerTemplates)}

                    <h4>{t("All_products")}</h4>
                    {/*
                    <div style={{display: 'flex', width: '100%'}}>
                        <p style={{width: '20%', textAlign: 'center'}}>{t("Number")}</p>
                        <p style={{width: '80%', textAlign: 'center'}}>{t("Name")}</p>
                    </div>
                    */}
                    {searchTerm.length === 0 && props.products.map(renderItem)}
                    {searchTerm.length > 0 && searchList.map(renderItem)}
                </div>

            </DialogContent>

            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={props.onClose}
                >
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default withTranslation()(ProductSelectDialog);