import React, { Component } from 'react';
import { connect } from 'react-redux';

import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/functions";

import {
    Paper,
    TextField,
    MenuItem,
    Button,
    Tabs,
    Tab, Typography, Dialog, CircularProgress, DialogTitle, DialogContent,
} from '@material-ui/core';

import PeriodComponent from "./PeriodComponent";
import ApprovalComponent from "./ApprovalComponent";
import SearchListComponent from './SearchListComponent';
import SendApproval from './SendApproval';


import { DataSearch } from "../../util/DataSearch";
import { searchPeriod, searchApproval } from "./searchFunctions";
import { validateFBWorklog } from "../../validations/worklog";
import PDFFixedPeriodWorktime from './PDFFixedPeriodWorktime';
import PDFApproval from './PDFApproval';
//import PDFHourWage from "./PDFHourWage";
import PDFHourWage2 from "./PDFHourWage2";
import PDFCustomer from "./PDFCustomer";
import PDFWorkdays from './PDFWorkdays';

import { withTranslation } from 'react-i18next';
import { withRouter, Link, } from "react-router-dom";

import moment from 'moment';
import "moment/locale/fi";
import PropTypes from "prop-types";
import WorksiteDialog from "./WorksiteDialog";

import { resetSend } from "../../actions/sendapprovalActions";
import { setEmployees } from "../../actions/employeeActions";

import HourComponent from "./HourComponent";
import WorktimeComponent from "./WorktimeComponent";
import HourListComponent from "./HourListComponent";
import CustomerComponent from "./CustomerComponent";
import WorkdaysListComponent from "./WorkdaysListComponent"

import { isEmpty } from "../../validations/isEmpty";


function TabContainer(props) {
    return (
        <Typography component="div">
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};


class TimeReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: [],
            selectedEmployee: "",
            customers: [],
            selectedCustomer: "",
            worksites: [],
            selectedWorksite: "",
            worklogs: [],
            workdays: [],
            company: {},
            jobModels: [],
            customerModel: {},
            selectedPeriod: "select",
            reportType: "select",
            dateRange: {
                selection: {
                    startDate: moment().subtract(1, "month").startOf("day").toDate(),
                    endDate: moment().toDate(),
                    key: 'selection'
                }
            },

            dateChanged: false,
            worksiteDialog: false,
            tabValue: 0,
            resetPeriod: "gotPeriod",
            resetApproval: "gotApproval",
            resetArray: "gotArray",
            resetHour: "gotHour",
            searchArray: [],
            searchObj: {},
            errors: {
                period: "",
                date: "",
                customer: "",
            },
            email: {
                pdfName: `vahvistettavat_tunnit_${moment().locale("fi").format("L")}`,
                message: "",
                subject: "Tuntivahvistus"
            },
            emailLoading: false,
            emailSent: false,
            descriptionField: "task",
            reportsLoading: false,
            openInNewTab: false,
            workdaysLoading: false,
            loading: true,
        };
    }

    componentDidMount() {
        const { user } = this.props.user;
        const { employees } = this.props.employees;

        console.log("empl ", employees)

        if (isEmpty(employees)) {
            firebase.firestore().collection('employees').where("companyID", "==", user.companyID).get().then((querySnap) => this.onEmployeeSnapshot(querySnap));
        } else { 
            this.setState({ employees: employees, loading: false }) }
        //TODO others

        this.unsubscribeCompanyListener =
            firebase.firestore().collection('company').doc(user.companyID).onSnapshot(this.onCompanySnapshot);

        this.unsubscribeCustomerListener =
            firebase.firestore().collection('customers')
                .where("companyID", "array-contains", user.companyID)
                .onSnapshot(this.onCustomerSnapshot);

        this.unsubscribeWorksiteListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('worksites').onSnapshot(this.onWorksiteSnapshot);

        this.unsubscribeWorklogListener =
            firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').onSnapshot(this.onWorklogSnapshot);

        this.unsubscribeModelListener =
            firebase.firestore().collection("company").doc(user.companyID).collection("jobModels").onSnapshot(this.onModelSnapshot);


        //this.queryWorkDays();

    }

    onEmployeeSnapshot = querySnapshot => {
        const employees = [];
        querySnapshot.forEach(doc => {
            let employee = doc.data();

            employee.id = doc.id;
            if (!employee.isDeleted || employee.isActive !== "false") {
                employee.name = `${employee.firstName} ${employee.familyName}`;
                employees.push(employee)
            }
        });

        this.setState({
            employees: employees,
            loading: false,
        });
    };

    onCompanySnapshot = docSnapshot => {
        const company = docSnapshot.data();
        this.setState({
            company: company
        });
    };

    onCustomerSnapshot = querySnapshot => {
        const customers = [];

        querySnapshot.forEach(doc => {
            const customer = doc.data();
            customer.id = doc.id;
            customers.push(customer);
        });

        this.setState({
            customers: customers
        });
    };

    onWorksiteSnapshot = querySnapshot => {
        const worksites = [];

        querySnapshot.forEach(doc => {
            let worksite = doc.data();
            worksite.id = doc.id;
            worksites.push(worksite);
        });

        this.setState({
            worksites: worksites
        });
    };

    onWorklogSnapshot = querySnapshot => {
        const worklogs = [];

        querySnapshot.forEach(doc => {
            let worklog = doc.data();
            worklog.id = doc.id;

            const { errors, isValid } = validateFBWorklog(worklog);

            if (worklog.status !== "Discarded" && isValid) worklogs.push(worklog);
        });

        this.setState({
            worklogs: worklogs
        });
    };


    sleep = (milliseconds) => {
        const date = Date.now();
        let currentDate = null;
        do {
            setTimeout(() => {
                currentDate = Date.now();
            }, 100);
        } while (currentDate - date < milliseconds);
    }


    queryWorkDays = () => {


        const { dateRange, queryFromValue, employees } = this.state;

        let newQueryFromValue = { ...queryFromValue };

        let startValue = dateRange && dateRange.selection.startDate ? moment(dateRange.selection.startDate).format("YYYYMMDD") : queryFromValue;

        // query INITIAL_DB_QUERY_DAYS_BACK unlessa start date is further back
        if (startValue && moment(startValue) < moment(queryFromValue)) {
            newQueryFromValue = startValue;
        }

        console.log("DB query WorkDays startValue ", startValue, employees.length);

        this.setState({ workdaysLoading: true, queryFromValue: newQueryFromValue, wdQueryTimerStart: moment() });

        const promises = [];

        if (employees && startValue) {
            employees.forEach((employee, index) => {
                    console.log("get days for employee ", employee.name)
                    let emplquery = this.queryOneEmployeeWorkday(startValue, employee.id)
                    promises.push(emplquery);
                    /*.then(() => {
                        console.log("GOT days for ONE employee ", employee.name)
                        employeeProcessing = false;
                    })
                    .catch((error) => {
                        console.log("ERROR getting workdays for ONE employee ", employee.name, error)
                        employeeProcessing = false;
                    })*/
                
            })

            Promise.all(promises).then((values) => {
                console.log("resolved values ", values);
                this.setState({ workdaysLoading: false, })
            }).catch((error) => {
                console.log("error  ", error);
                this.setState({ workdaysLoading: false, })
            })

        } else {
            console.log("ERR NO EMPLOYEES available")
            this.setState({ workdaysLoading: false })
        }
    }

    queryOneEmployeeWorkday = (startValue, emplId) => new Promise((resolve, reject) => {
        const { user } = this.props.user;

        firebase.firestore().collection('company').doc(user.companyID).collection("workdays").doc(emplId).collection("worktimes")
            .where("date", ">=", startValue)
            .get()
            .then(querySnap =>
                this.onWorkDayQuerySnap(querySnap, emplId)
                    .then(() => {
                        
                        resolve();
                    })
                    .catch((error) => {
                        reject(error)
                    })
            );
    })

    onWorkDayQuerySnap = (querySnap, emplId) => new Promise((resolve, reject) => {
        const { wdQueryTimerStart, employees } = this.state;
        let workdays = [...this.state.workdays];

        try {
            querySnap.forEach(doc => {

                let workday = doc.data();
                workday.id = doc.id;
                workday.employee = emplId;

                if (!workday.status) { //make up status if not set
                    let status = "Unknown";
                    if (workday.startDT && workday.endDT) {
                        status = "Finished" //TODO: do general component checking status
                    } else if (workday.startDT && !workday.endDT) {
                        status = "Ongoing";
                    }
                    workday.status = status;
                }
                console.log("GOT WORKDAY DATA : ", workday)

                const exists = workdays.includes((wDay => wDay.id === workday.id)); //TODO: checks
                if (!exists) workdays.push(workday);
                //else { console.log("workDay not valid!!  ", workday) }
                //workdays.push(workday);
            });

            this.setState({ workdays: workdays }, () => resolve());

        } catch (error) {
            reject(error)
        }
    });

    onModelSnapshot = querySnapshot => {
        const jobModels = [];

        querySnapshot.forEach(doc => {
            const model = doc.data();
            model.id = doc.id;

            jobModels.push(model);
        });

        this.setState({
            jobModels
        });
    };

    componentWillUnmount() {
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
        this.unsubscribeCompanyListener && this.unsubscribeCompanyListener();
        this.unsubscribeCustomerListener && this.unsubscribeCustomerListener();
        this.unsubscribeWorksiteListener && this.unsubscribeWorksiteListener();
        this.unsubscribeWorklogListener && this.unsubscribeWorklogListener();
    }

    fieldChangeHandler = (event, field) => {
        const { errors } = this.state;
        if (field === "employee") {
            console.log("chagne employeesto ", event)
            this.setState({ selectedEmployee: event })
        }
        if (field === "customer") {
            errors.customer = "";  //clean customer selection error
            this.setState({ selectedCustomer: event, errors: errors })
        }
        if (field === "worksite" && this.state.selectedCustomer.length > 0) {
            this.setState({ worksiteDialog: true })
        }
        if (field === "worksiteSelect") {
            this.setState({ selectedWorksite: event, worksiteDialog: false })
        }
        if (field === "selectedPeriod") {
            errors.period = "";
            console.log("set selected period ", event.target.value)
            this.setState({ selectedPeriod: event.target.value, errors: errors })
        }
        if (field === "tabValue") {
            this.setState({ tabValue: event })
        }
        if (field === "reportType") {
            console.log("chagne reportType ", event);

            this.setState({ reportType: event.target.value })
        }
        if (field === "descriptionField") {
            this.setState({ descriptionField: event.target.value });
        }
        if (field === "date") {
            errors.date = "";
            const dateRange = event;
            console.log("set selected dateRange 1", dateRange)
            dateRange.selection.endDate = moment(dateRange.selection.endDate).endOf("day").toDate();
            console.log("set selected dateRange 2", dateRange)

            this.setState({
                dateRange,
                errors: errors,
                dateChanged: true
            });
        }
    };

    getEmployee = id => {
        const { employees } = this.state;

        const employee = employees.find(emp => emp.id === id);

        return employee;
    };

    getEmployeeName = id => {
        const { employees } = this.state;

        const employee = employees.find(emp => emp.id === id);

        if (employee) {
            return employee.name;
        }
        else {
            return "Couldn't find name";
        }
    };

    getCustomerName = id => {
        const { customers } = this.state;

        const customer = customers.find(customer => customer.id === id);

        if (customer) {
            return customer.name;
        }
        else {
            return "Couldn't find name"
        }

    };

    getWorksiteName = id => {
        const { worksites } = this.state;

        const worksite = worksites.find(worksite => worksite.id === id);

        if (worksite) {
            return worksite.name;
        }
        else {
            return this.props.t("No worksite");
        }

    };

    searchPeriod = () => {
        const { worklogs, employees, selectedEmployee, selectedPeriod, errors, selectedCustomer, reportType } = this.state;
        if (selectedPeriod !== "select") {
            const searchArray = searchPeriod(worklogs, employees, selectedEmployee, selectedPeriod);

            const sortList = searchArray.sort((a, b) => {
                if (a.startDT < b.startDT) return -1;
                if (a.startDT > b.startDT) return 1;
            });

            this.setState({
                searchArray: sortList,
                resetArray: this.state.resetArray === "gotArray" ? "noArray" : "gotArray",
            });
        }

        if (selectedPeriod === "select") {
            errors.period = this.props.t("Select period");
            this.setState({
                errors: errors
            });
        }

        /*if (reportType === "approval") {
            if (isEmpty(selectedCustomer)) {
                errors.customer = this.props.t("Select customer");
            }
            this.setState({
                errors: errors
            });
        }*/

    };

    searchApproval = () => {
        const { worklogs, employees, customers, selectedEmployee, selectedCustomer, dateChanged, dateRange, selectedWorksite, errors } = this.state;

        if (dateChanged) {

            const customer = customers.find(customer => customer.name === selectedCustomer);
            const employee = employees.find(employee => employee.name === selectedEmployee);

            const worklogSearch = new DataSearch();
            worklogSearch.setSearchData(worklogs);
            if (customer) worklogSearch.appendField("customer", customer.id, "isEqual");
            if (employee) worklogSearch.appendField("employee", employee.id, "includes");
            if (selectedWorksite) worklogSearch.appendField("worksite", selectedWorksite.id, "isEqual");
            if (dateChanged) worklogSearch.appendField("startDT", dateRange, "between");
            const results = worklogSearch.executeSearch("desc", "startDT");

            // Find customer Model by going trough results
            let customerModel = {};
            if (results && results.length > 0) {
                const logWithTemplateID = results.find(log => log.templateID && log.templateID.length > 0);

                // If there is one log with templateID find model
                if (logWithTemplateID) {
                    const foundModel = this.state.jobModels.find(template => template.id === logWithTemplateID.templateID);
                    if (foundModel) customerModel = foundModel;
                }
            }

            this.setState({
                searchArray: results,
                customerModel,
                customer,
            });


        }
        else {
            errors.date = this.props.t("Error_date");
            this.setState({
                errors: errors
            });
        }
    };
    /*
    searchWorkDays = () => {
        const { selectedEmployee, dateRange, dateChanged, selectedStatus, employees, workDays, worklogs } = this.state;

        //const customer = customers.find(customer => customer.name === searchCustomer);
        const employee = employees.find(employee => employee.name === searchEmployee);

        const workdaySearch = new DataSearch();

        workdaySearch.setSearchData(workDays);

        if (selectedEmployee) workdaySearch.appendField("employee", selectedEmployee.id, "includes");
        if (selectedStatus !== "all") workdaySearch.appendField("status", selectedStatus, "isEqual");

        console.log("filterWorkDAYs : dateRange " + moment(dateRange.selection.startDate).format("YYYYMMDDTHHmmss") + " - " + moment(dateRange.selection.endDate).format("YYYYMMDDTHHmmss"));

        if (dateChanged) {
            /*const dateObj = {
                selection: {
                    startDate: moment(dateRange.selection.startDate).format("YYYYMMDDTHHmmss"),
                    endDate: moment(dateRange.selection.endDate).format("YYYYMMDDTHHmmss"),
                }
            }; //
            

            workdaySearch.appendField("date", dateRange, "between");
        }

        //if (!showCancelled && selectedStatus === "all" && selectedStatus !== "notReported") worklogSearch.appendField("status", "Cancelled", "notEqual");

        const results = workdaySearch.executeSearch(this.state.sortDir, "date");

        this.setState({
            searchArray: results,
            dateRange: dateRange,
            dateChanged: dateChanged,
        });

    }
    */

    changeEmail = (event, field) => {
        const { email } = this.state;

        email[field] = event.target.value;

        this.setState({
            email: email
        });
    };

    sendEmail = () => {
        const { pdf } = this.props.approval;
        const { email } = this.state;
        this.setState({
            emailLoading: true
        });

        console.log("sending email: ", email);
        firebase.functions().httpsCallable('sendMail')({
            mailTo: email.sendTo,
            pdf: pdf,
            msg: email.message,
            pdfName: email.pdfName,
            subject: email.subject
        })
            .then(res => {
                this.props.resetSend();
                this.setState({
                    emailSent: true,
                    tabValue: 0,
                    email: {}
                });
            })
            .catch(err => {
                console.log(err)

            });
    };

    resetParameters = () => {
        this.setState({
            selectedCustomer: "",
            selectedEmployee: "",
            reportType: "select",
            selectedPeriod: "select",
            selectedWorksite: {},
            dateRange: {
                selection: {
                    startDate: moment().toDate(),
                    endDate: moment().toDate(),
                    key: 'selection'
                }
            },
            dateChanged: false,
            tabValue: 0,
            resetPeriod: this.state.resetPeriod === "gotPeriod" ? "noPeriod" : "gotPeriod",
            resetApproval: this.state.resetApproval === "gotApproval" ? "noApproval" : "gotApproval",
            searchArray: [],
            searchObj: [],
            resetHour: this.state.resetHour === "gotHour" ? "noHour" : "gotHour",
            resetArray: this.state.resetArray === "gotArray" ? "noArray" : "gotArray",
            errors: {
                period: "",
                date: ""
            }
        });
    };

    renderEmailDialog = () => {
        if (this.state.emailLoading) {
            return (
                <Dialog
                    open={this.state.emailLoading}
                    onClose={() => this.setState({ emailLoading: false })}
                    maxWidth="xs"
                >
                    {!this.state.emailSent && <CircularProgress />}
                    {this.state.emailSent && <DialogTitle>Email sent!</DialogTitle>}
                    {this.state.emailSent && <DialogContent>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.setState({ emailLoading: false })}
                            style={{
                                marginLeft: 10
                            }}
                        >
                            Close
                        </Button>
                    </DialogContent>}
                </Dialog>
            );
        }
    };

    renderReportsCreateDialog = () => {
        if (this.state.reportsLoading) {
            return (
                <Dialog
                    open={this.state.reportsLoading}
                    //onClose={() => this.setState({ reportsLoading: false })}
                    maxWidth="xs"

                >
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
            );
        }
    };

    renderWorkdaysLoadingDialog = () => {
        if (this.state.workdaysLoading) {
            return (
                <Dialog
                    open={this.state.workdaysLoading}
                    //onClose={() => this.setState({ reportsLoading: false })}
                    maxWidth="xs"
                >
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
            );
        }
    };

    renderLoadingDialog = () => {
        if (this.state.loading) {
            return (
                <Dialog
                    open={this.state.loading}
                    //onClose={() => this.setState({ reportsLoading: false })}
                    maxWidth="xs"
                >
                    <DialogContent>
                        <CircularProgress />
                    </DialogContent>
                </Dialog>
            );
        }
    }

    render() {
        const { t } = this.props;


        return (
            <div style={{ width: '100%', display: 'flex', marginLeft: 20, marginTop: 20 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', width: 350 }}>
                    <Paper style={{ width: '100%', padding: 10, height: 'auto', overflowY: 'auto' }}>


                        {this.state.reportType !== "approval" && this.state.reportType !== "customer" &&
                            <TextField
                                variant="outlined"
                                label={t("Report type")}
                                style={{
                                    width: '100%'
                                }}
                                value={this.state.reportType}
                                onChange={event => this.setState({ reportType: event.target.value }, () => {
                                    if (this.state.reportType === "worktime") this.queryWorkDays();  //do this query only if..
                                })}
                                select
                            >
                                <MenuItem key="select" value="select">{t("Select type")}</MenuItem>
                                <MenuItem key="period" value="period">{t("Period")}</MenuItem>
                                <MenuItem key="worktime" value="worktime">{t("Worktime")}</MenuItem>
                                <MenuItem key="hour" value="hour">{t("Hour")}</MenuItem>
                                <MenuItem key="customer" value="customer">{t("Customer report")}</MenuItem>
                                <MenuItem key="approval" value="approval">{t("Approval")}</MenuItem>
                            </TextField>}


                        {this.state.reportType === "period" &&
                            <div>
                                <PeriodComponent
                                    employees={this.state.employees}
                                    company={this.state.company}
                                    selectedPeriod={this.state.selectedPeriod}
                                    onChange={this.fieldChangeHandler}
                                    errors={this.state.errors}
                                    onReset={this.resetParameters}
                                    onSearch={this.searchPeriod}
                                    key={this.state.resetPeriod}
                                />

                                {this.state.searchArray.length > 0 &&
                                    <PDFFixedPeriodWorktime
                                        start={this.state.dateRange.selection.startDate}
                                        end={this.state.dateRange.selection.endDate}
                                        customers={this.state.customers}
                                        selectedLogs={this.state.searchArray}
                                        employeeLabel={this.state.selectedEmployee.length > 0 ? this.state.selectedEmployee : "All"}
                                        company={this.state.company}
                                        onReportCreate={() => this.setState({ reportsLoading: true })}
                                        onReportsCreated={() => this.setState({ reportsLoading: false })}
                                        onReportsCreateError={() => this.setState({ reportsLoading: false })}
                                        openInNewTab={this.state.openInNewTab}
                                        onChangeOpenInNewTab={(checked) => this.setState({ openInNewTab: checked })}
                                    />
                                }

                                <div>
                                    <div style={{ width: '95%', height: '30vh', overflow: 'auto', padding: 20, fontSize: 12 }}>
                                        <div style={{ padding: 5, marginBottom: 20, fontSize: 13 }}>
                                            Jaksoraportti:
                                    </div>
                                        <div style={{ padding: 5 }}>
                                            Jaksoraportti tuottaa työaikaraportin palkantasaantumisjaksolle.  Jaksot ovat kiinteitä ja määritellään yrityksen asetuksissa.
                                            Ylityöt lasketaan ja tasataan koko jaksolle.
                                    </div>
                                        <div style={{ padding: 5 }}>
                                            Huomaathan että työlokien on oltava toimitettu tilassa.
                                    </div>
                                        <div style={{ padding: 5 }}>
                                            Valitse haluttu jakso ja työntekijä jolle haluat raportin ja klikkaa Etsi. Vasemmalla näkyy listaus töistä jotka ovat tulossa raportille.
                                            Paina Luo Raportti ja PDF dokumentti avautuu uuteen selainikkunaan. Raportin voi tallentaa tai tulostaa.
                                    </div>
                                        <div style={{ padding: 5 }}>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                        {this.state.reportType === "hour" &&
                            <div>
                                <HourComponent
                                    employees={this.state.employees}
                                    onChange={this.fieldChangeHandler}
                                    worklogs={this.state.worklogs}
                                    onReset={this.resetParameters}
                                    dateRange={this.state.dateRange}
                                    onSearch={searchObj => this.setState({ searchObj })}
                                    key={this.state.resetHour}
                                />

                                {Object.keys(this.state.searchObj).length > 0 &&
                                    <PDFHourWage2
                                        getCustomer={this.getCustomerName}
                                        getEmployee={this.getEmployee}
                                        getWorksite={this.getWorksiteName}
                                        searchObj={this.state.searchObj}
                                        company={this.state.company}
                                        onReportCreate={() => this.setState({ reportsLoading: true })}
                                        onReportsCreated={() => this.setState({ reportsLoading: false })}
                                        onReportsCreateError={() => this.setState({ reportsLoading: false })}
                                        openInNewTab={this.state.openInNewTab}
                                        onChangeOpenInNewTab={(checked) => this.setState({ openInNewTab: checked })}
                                    />
                                }

                                <div>
                                    <div style={{ width: '95%', height: '30vh', overflow: 'auto', padding: 20, fontSize: 12 }}>
                                        <div style={{ padding: 5, marginBottom: 20, fontSize: 13 }}>
                                            Työntekijän aikaraportti
                                    </div>
                                        <div style={{ padding: 5 }}>
                                            Aikaraportti raportoi työntekijöiden tunnit tai tarkan työajan aikavälille
                                    </div>
                                        <div style={{ padding: 5 }}>

                                        </div>
                                        <div style={{ padding: 5 }}>

                                        </div>
                                        <div style={{ padding: 5 }}>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        }

                        {this.state.reportType === "worktime" &&
                            <div>
                                <WorktimeComponent
                                    company={this.state.company}
                                    selectedEmployee={this.state.selectedEmployee}
                                    employees={this.state.employees}
                                    onChange={this.fieldChangeHandler}
                                    worklogs={this.state.worklogs}
                                    workdays={this.state.workdays}
                                    onReset={this.resetParameters}
                                    dateRange={this.state.dateRange}
                                    onSearch={searchObj => this.setState({ searchObj })}
                                    key={this.state.resetHour}
                                    onWorkDaysProcessing={() => this.setState({ workdaysLoading: true })}
                                    onWorkDaysProcessed={() => this.setState({ workdaysLoading: false })}
                                />

                                {Object.keys(this.state.searchObj).length > 0 &&

                                    <PDFWorkdays
                                        getEmployee={this.getEmployee}
                                        searchObj={this.state.searchObj}
                                        company={this.state.company}
                                        onReportCreate={() => this.setState({ reportsLoading: true })}
                                        onReportsCreated={() => this.setState({ reportsLoading: false })}
                                        onReportsCreateError={() => this.setState({ reportsLoading: false })}
                                        openInNewTab={this.state.openInNewTab}
                                        onChangeOpenInNewTab={(checked) => this.setState({ openInNewTab: checked })}

                                    />
                                }

                                <div>
                                    <div style={{ width: '95%', height: '30vh', overflow: 'auto', padding: 20, fontSize: 12 }}>
                                        <div style={{ padding: 5, marginBottom: 20, fontSize: 13 }}>
                                            Työntekijän työaikaraportti
                                    </div>
                                        <div style={{ padding: 5 }}>
                                            Työaikaraportti raportoi työntekijöiden työajan aikavälille
                                    </div>
                                        <div style={{ padding: 5 }}>

                                        </div>
                                        <div style={{ padding: 5 }}>

                                        </div>
                                        <div style={{ padding: 5 }}>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        }

                        {this.state.reportType === "approval" &&
                            <div>
                                <Tabs
                                    value={this.state.tabValue}
                                    onChange={(event, value) => this.setState({ tabValue: value })}
                                    variant="fullWidth"
                                    TabIndicatorProps={{
                                        style: { backgroundColor: '#ccc' }
                                    }}
                                >
                                    <Tab label={t("Search")} />
                                    <Tab label={t("Send report")} />
                                </Tabs>
                                <br />
                                {this.state.tabValue === 0 &&
                                    <TabContainer>
                                        <ApprovalComponent
                                            reportType={this.state.reportType}
                                            employees={this.state.employees}
                                            customers={this.state.customers}
                                            selectedCustomer={this.state.selectedCustomer}
                                            onChange={this.fieldChangeHandler}
                                            dateRange={this.state.dateRange}
                                            tabValue={this.state.tabValue}
                                            key={this.state.resetApproval}
                                            onReset={this.resetParameters}
                                            onSearch={this.searchApproval}
                                            errors={this.state.errors}
                                            descriptionField={this.state.descriptionField}
                                        />
                                        {this.state.searchArray.length > 0 &&
                                            <PDFApproval
                                                start={this.state.dateRange.selection.startDate}
                                                end={this.state.dateRange.selection.endDate}
                                                customer={this.state.selectedCustomer}
                                                customers={this.state.customers}
                                                selectedLogs={this.state.searchArray}
                                                selectedEmployee={this.state.selectedEmployee}
                                                employees={this.state.employees}
                                                company={this.state.company}
                                                selectedWorksite={this.state.selectedWorksite}
                                                customerModel={this.state.customerModel}
                                                descriptionField={this.state.descriptionField}
                                                onReportCreate={() => this.setState({ reportsLoading: true })}
                                                onReportsCreated={() => this.setState({ reportsLoading: false })}
                                                onReportsCreateError={() => this.setState({ reportsLoading: false })}
                                                openInNewTab={this.state.openInNewTab}
                                                onChangeOpenInNewTab={(checked) => this.setState({ openInNewTab: checked })}
                                                initialEmail={this.props.approval.customer.email ? this.props.approval.customer.email : ""}
                                            />
                                        }

                                    </TabContainer>
                                }
                                {this.state.tabValue === 1 &&
                                    <TabContainer>
                                        <SendApproval
                                            gotEmail={event => this.setState({ sendTo: event })}
                                            onChange={this.changeEmail}
                                            initialEmail={this.props.approval.customer}
                                            email={this.state.email}
                                            sendEmail={this.sendEmail}
                                        />
                                    </TabContainer>
                                }

                                <div>
                                    <div style={{ width: '95%', height: '30vh', overflow: 'auto', padding: 20, fontSize: 12 }}>
                                        <div style={{ padding: 5, marginBottom: 20, fontSize: 13 }}>
                                            Vahvistus raportti
                                    </div>
                                        <div style={{ padding: 5 }}>
                                            Vahvistusraportti raportoi työkohdekohtaiset työt aikavälille Työmaakortti-tyyliin
                                    </div>
                                        <div style={{ padding: 5 }}>
                                            Raportti voidaan lähettää työkohteen vastuuhenkilölle allekirjoitettavaksi tai
                                            sähköisesti hyväksyttäväksi.
                                    </div>
                                        <div style={{ padding: 5 }}>

                                        </div>
                                        <div style={{ padding: 5 }}>
                                            Käyttö edellyttää laajan raportointilisenssin.
                                    </div>
                                    </div>
                                </div>

                            </div>
                        }

                        {this.state.reportType === "customer" &&
                            <div>
                                <Tabs
                                    value={this.state.tabValue}
                                    onChange={(event, value) => this.setState({ tabValue: value })}
                                    variant="fullWidth"
                                    TabIndicatorProps={{
                                        style: { backgroundColor: '#ccc' }
                                    }}
                                >
                                    <Tab label={t("Search")} />
                                    <Tab label={t("Send report")} />
                                </Tabs>
                                <br />
                                {this.state.tabValue === 0 &&
                                    <TabContainer>
                                        <CustomerComponent
                                            reportType={this.state.reportType}
                                            employees={this.state.employees}
                                            customers={this.state.customers}
                                            selectedCustomer={this.state.selectedCustomer}
                                            onChange={this.fieldChangeHandler}
                                            dateRange={this.state.dateRange}
                                            tabValue={this.state.tabValue}
                                            key={this.state.resetApproval}
                                            onReset={this.resetParameters}
                                            onSearch={this.searchApproval}
                                            errors={this.state.errors}
                                            descriptionField={this.state.descriptionField}
                                        />
                                        {this.state.searchArray.length > 0 && this.state.customer &&
                                            <PDFCustomer
                                                start={this.state.dateRange.selection.startDate}
                                                end={this.state.dateRange.selection.endDate}
                                                selectedCustomer={this.state.selectedCustomer}
                                                customer={this.state.customer}
                                                customers={this.state.customers}
                                                selectedLogs={this.state.searchArray}
                                                selectedEmployee={this.state.selectedEmployee}
                                                employees={this.state.employees}
                                                company={this.state.company}
                                                selectedWorksite={this.state.selectedWorksite}
                                                worksites={this.state.worksites}
                                                customerModel={this.state.customerModel}
                                                descriptionField={this.state.descriptionField}
                                                dateRange={this.state.dateRange}
                                                onReportCreate={() => this.setState({ reportsLoading: true })}
                                                onReportsCreated={() => this.setState({ reportsLoading: false })}
                                                onReportsCreateError={() => this.setState({ reportsLoading: false })}
                                                openInNewTab={this.state.openInNewTab}
                                                onChangeOpenInNewTab={(checked) => this.setState({ openInNewTab: checked })}
                                            />
                                        }
                                    </TabContainer>
                                }

                                {this.state.tabValue === 1 &&
                                    <TabContainer>
                                        <SendApproval
                                            gotEmail={event => this.setState({ sendTo: event })}
                                            onChange={this.changeEmail}
                                            initialEmail={this.props.approval.customer}
                                            email={this.state.email}
                                            sendEmail={this.sendEmail}
                                        />
                                    </TabContainer>
                                }

                            </div>
                        }

                        {/* info texts */}

                        {this.state.reportType === "select" &&
                            <div>
                                <div style={{ width: '95%', height: '30vh', overflow: 'auto', padding: 20, fontSize: 12 }}>
                                    <div style={{ padding: 5, marginBottom: 20, fontSize: 13 }}>
                                        Valitse aikaraportin tyyppi:
                                </div>
                                    <div style={{ padding: 5 }}>
                                        Jaksoraportti tuottaa työaikaraportin palkantasaantumisjaksolle. Jaksot
                                        määritellään yrityksen asetuksissa. Ylityöt lasketaan ja tasataan koko jaksolle.
                                </div>
                                    <div style={{ padding: 5 }}>
                                        Tuntiraportti raportoi työntekijöiden tunnit tai tarkan työajan aikavälille
                                </div>
                                    <div style={{ padding: 5 }}>
                                        Asiakasraportti raportoi asikas- ja kohde kohtaiset työtunnit. Lisäksi lisätyt
                                        materiaalit.
                                </div>
                                    <div style={{ padding: 5 }}>
                                        Vahvistusraportti / työmaakortti työkohdekohtaiset työt aikavälille. Raportti
                                        voidaan lähettää työkohteen vastuuhenkilölle allekirjoitettavaksi tai
                                        sähköisesti hyväksyttäväksi.
                                </div>
                                </div>
                            </div>
                        }

                        {
                            <div style={{ width: '95%', height: '20', overflow: 'auto', padding: 20, fontSize: 12 }}>
                                <Link to="/reports" >
                                    {this.props.t("GoToReportList")}
                                </Link>
                            </div>
                        }

                    </Paper>


                </div>

                <div style={{ flexGrow: 1 }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 20, marginRight: 20 }}>
                        <Paper style={{ width: '100%', height: '85vh', overflow: 'auto' }}>
                            {/* hour type search */}
                            {this.state.reportType === "hour" &&
                                <HourListComponent
                                    searchObj={this.state.searchObj}
                                    employees={this.state.employee}
                                    getEmployee={this.getEmployeeName}
                                    getCustomer={this.getCustomerName}
                                    getWorksite={this.getWorksiteName}
                                />
                            }

                            {this.state.reportType === "worktime" &&
                                <WorkdaysListComponent
                                    t={t}
                                    searchObj={this.state.searchObj}
                                    employees={this.state.employee}
                                    getEmployee={this.getEmployeeName}
                                    getWorksite={this.getWorksiteName}

                                />
                            }
                            {/* hour type search, Approval, Customer etc... */}
                            {this.state.reportType !== "hour" && this.state.reportType !== "worktime" &&
                                <SearchListComponent
                                    searchArray={this.state.searchArray}
                                    findCustomer={this.getCustomerName}
                                    findEmployee={this.getEmployeeName}
                                    getWorksite={this.getWorksiteName}
                                    key={this.state.resetArray}
                                />

                            }

                        </Paper>
                    </div>
                </div>

                {this.state.worksiteDialog &&
                    <WorksiteDialog
                        onClose={() => this.setState({ worksiteDialog: false })}
                        onSelect={this.fieldChangeHandler}
                        selectedCustomer={this.state.selectedCustomer}
                        customers={this.state.customers}
                        worksites={this.state.worksites}
                    />
                }
                {this.renderLoadingDialog()}
                {this.renderEmailDialog()}
                {this.renderReportsCreateDialog()}
                {this.renderWorkdaysLoadingDialog()}


            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    employees: state.employees,
    approval: state.approval
});

export default withRouter(connect(mapStateToProps, { resetSend, setEmployees, })(withTranslation()(TimeReports)));