import React, {useState} from 'react';

import {
    Fab,
    IconButton,
    TextField
} from '@material-ui/core';

import {Delete, Add} from "@material-ui/icons";

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";

import {changeToDot} from "./BillNumberHelpers";
import ProductSelectDialog from "./ProductSelectDialog";

import "./BillDetailsProductRows.css";
const BillDetailsProductRows = props => {
    const {rows} = props.bill.billObj;
    const {user} = props.user;
    const {t} = props;

    const [productDialog, setProductDialog] = useState(false);
    const [rowIndex, setRowIndex] = useState(null);

    const openProductDialog = (field, index) => {
        if (field === "name" || field === "pNumber") {
            setProductDialog(true);
            setRowIndex(index);
        }
    };

    const onProductSelect = product => {
        props.addProductRow(product, rowIndex);
        setProductDialog(false);
    };



    const renderClickable = (value, index, field) => {
        // TODO add hover to div
        if (!value) return <div style={{ width: '100%', height: '100%', color: '#FFF', padding: 5}} onClick={() => openProductDialog(field, index)}>nimi</div>;
        else return <p className={field === "name" ? "productrows-text-large" : "productrows-text-small" }>{value}</p>
    };

    const renderEditableField = (value, index, field) => {
        // Check if row is for compensation and return empty string
        if (props.bill.billObj.rows[index].compensationInfo) return "";

        let val = props.bill.billObj.rows[index][field];
        if (!value && field === "discount") val = 0;
        if (field === "discount" && typeof value !== "string") val = val * 100;

        //if (field === "quantity" && typeof val === "number") val = val.toFixed(2);

        return (
            <TextField
                InputProps={{
                    disableUnderline: true,
                    style: {
                        fontSize: 14,
                    },
                    endAdornment: field === "discount" ? <p style={{fontSize: 16, margin:0, padding:0, marginRight: 10}}>%</p> : ""
                }}
                inputProps={{
                    style: {textAlign: field === "name" || field === "invoiceText" ? "left" : 'center'}
                }}
                style={{
                    width: '100%',
                    marginTop: 15,
                    marginLeft: field === "name" || field === "invoiceText" ? 10 : 0,
                }}
                value={val}
                onChange={event => props.changeRows(event, index, field)}
            />
        );
    };

    const priceRow = (row) => {
        if (row.compensationInfo) return "";

        // Helper function to ensure the decimal point is correctly formatted
        const formatDecimal = (value, lang) => {
            const replaceValue = lang === "fi" ? "," : ".";
            return value.toFixed(2).replace(".", replaceValue);
        };

        if (props.priceWithVat) {
            const priceWithVat = parseFloat(changeToDot(row.price)) * (1 + row.alv);
            return formatDecimal(priceWithVat, user && user.lang);
        } else {
            return row.price;
        }
    };

    const rowTotal = (row) => {
        if (row.compensationInfo) return "";

        // Helper function to ensure decimal formatting based on language
        const formatDecimal = (value, lang) => {
            const replaceValue = lang === "fi" ? "," : ".";
            return value.toFixed(2).replace(".", replaceValue);
        };

        // Get user language
        const lang = user && user.lang ? user.lang : "en";

        // Convert price to a number, considering VAT if applicable
        const price = parseFloat(changeToDot(row.price)) * (props.priceWithVat ? (1 + row.alv) : 1);

        // Convert quantity to a number
        const quantity = parseFloat(changeToDot(row.quantity));

        // Calculate total price with quantity
        const withQuantity = price * quantity;

        // Apply discount if any
        const withDiscount = row.discount > 0 ? withQuantity * (1 - row.discount) : withQuantity;

        // Format the final total
        return formatDecimal(withDiscount, lang);
    };

    const renderRow = (row, index) => {
        return (
            <div className="productrows-table-row" key={row.name + index}>
                <div className="productrows-table-cell-small">{renderClickable(row.pNumber, index, "pNumber")}</div>
                <div className="productrows-table-cell-large">{renderClickable(row ? row.name : "", index, "name")}</div>

                <div className="productrows-table-cell-large">{renderEditableField(row ? row.invoiceText : "", index, "invoiceText")}</div>
                <div className="productrows-table-cell-small"><p className="productrows-text-small">{row.unit}</p></div>
                <div className="productrows-table-cell-small">{renderEditableField(row.quantity, index, "quantity")}</div>
                <div className="productrows-table-cell-small"><p className="productrows-text-small">{priceRow(row)}</p></div>
                <div className="productrows-table-cell-small">{renderEditableField(row.discount, index, "discount")}</div>
                <div className="productrows-table-cell-small"><p className="productrows-text-small">{rowTotal(row)}</p></div>
                <div className="productrows-table-cell-small"><p className="productrows-text-large">{row.compensationInfo ? "" : `${row.alv * 100} %`}</p></div>
                <div className="productrows-table-cell-small">
                    <IconButton style={{marginTop: 5}} onClick={() => props.deleteRow(index)}>
                        <Delete />
                    </IconButton>
                </div>
            </div>
        )
    };

    return (
        <div>

            <div className="productrows-table">
                <div className="productrows-table-head">
                    <div className="productrows-table-cell-small"><p className="productrows-text-small">{t("Bill+Product")}</p></div>
                    <div className="productrows-table-cell-large"><p className="productrows-text-large">{t("Name")}</p></div>
                    <div className="productrows-table-cell-large"><p className="productrows-text-large">{t("Invoice text")}</p></div>
                    <div className="productrows-table-cell-small"><p className="productrows-text-small">{t("Unit")}</p></div>
                    <div className="productrows-table-cell-small"><p className="productrows-text-small">{t("Ordered")}</p></div>
                    <div className="productrows-table-cell-small"><p className="productrows-text-small">{t("Price")}</p></div>
                    <div className="productrows-table-cell-small"><p className="productrows-text-small">{t("Discount")}</p></div>
                    <div className="productrows-table-cell-small"><p className="productrows-text-small">{t("Total")}</p></div>
                    <div className="productrows-table-cell-small"><p className="productrows-text-small">{t("Vat")}</p></div>
                    <div className="productrows-table-cell-small"><p className="productrows-text-small">{""}</p></div>
                </div>
                {props.bill.billObj.rows.map(renderRow)}
            </div>

            {props.bill  &&
                <Fab
                    variant="extended"
                    size="small"
                    style={{color: '#FFF', backgroundColor: "#1770B2", marginTop: 10, marginLeft: 15}}
                    onClick={() => openProductDialog("name", rows.length)}
                >
                    <Add />
                    Add
                </Fab>
            }


            {productDialog &&
                <ProductSelectDialog
                    open={productDialog}
                    onClose={() => setProductDialog(false)}
                    rowIndex={rowIndex}
                    onProductSelect={onProductSelect}
                    products={props.products}
                    customerTemplates={props.customerTemplates}
                />
            }
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps,{})(withTranslation()(BillDetailsProductRows));


