import i18n from 'i18next';

import {initReactI18next} from 'react-i18next';

import fi from './locales/fi';
import en from './locales/en';

const resources = {
    en: en,
    fi: fi
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "fi",
        keySeparator: false,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;