import React from 'react';


import {
    TextField,
    Button
} from '@material-ui/core';

import {withTranslation} from 'react-i18next';

const SendApproval = props => {
    const {t} = props;

    console.log("SendApproval props  ", props);
    const email = props.email.sendTo && props.email.sendTo.length > 0 ?  props.email.sendTo : (props.initialEmail && props.initialEmail.email ? props.initialEmail.email : '');
    console.log("SendApproval email ", email);


    return (
        <div>
            <TextField
                variant="outlined"
                label={t("Send to")}
                style={{
                    width: '100%',
                    marginTop: 10
                }}
                value={email || ""}
                onChange={event => props.onChange(event, "sendTo")}
            />


            <TextField
                variant="outlined"
                label={t("Pdf name")}
                style={{
                    width: '100%',
                    marginTop: 10
                }}
                value={props.email.pdfName || ""}
                onChange={event => props.onChange(event, "pdfName")}
            />

            <TextField
                variant="outlined"
                label={t("Email title")}
                style={{
                    width: '100%',
                    marginTop: 10
                }}
                value={props.email.subject || ""}
                onChange={event => props.onChange(event, "title")}
            />

            <TextField
                variant="outlined"
                label={t("Message")}
                style={{
                    width: '100%',
                    marginTop: 10
                }}
                multiline
                rows={5}
                rowsMax={5}
                value={props.email.message || ""}
                onChange={event => props.onChange(event, "message")}
            />

            <Button
                variant="outlined"
                color="primary"
                style={{
                    marginLeft: '40%',
                    marginTop: 20
                }}
                onClick={() => props.sendEmail()}
            >
                {t("Send")}
            </Button>
        </div>
    );
};

export default withTranslation()(SendApproval);