import {checkNested} from "../../validations/isEmpty";
/*
*
* Step 1: Transform all required values to string when we get bill from dataBase
* Step 2: Determine based user lang if we use dot or comma
* Step 3: When counting values transform them to be separated with dot
* Step 4: Change required values back to int when saving to FB
*
* */

export const changeToDot = value => {
    const regEx = /^[,][0-9]{0,2}$/;

    if (!value) return "0.00";

    if (typeof value !== "string") return (value).toFixed(2);

    if (regEx.test(value)) return "00";

    return value.replace(",", ".");
};

export const changeToComma = value => {
    if (!value) return "0,00";

    if (typeof value !== "string") return (value).toFixed(2).replace(".", ",");

    return value.replace(".", ",");
};

export const changeIntToString = (bill, user) => {

    const replaceWithValue = user && user.lang === "fi" ? "," : ".";

    // Check billingFees
    if (!checkNested(bill, "billObj", "billingFees")) bill.billObj.billingFees = {freight: 0, billing: 0};

    bill.billObj.billingFees.freight = parseFloat(changeToDot(bill.billObj.billingFees.freight)).toFixed(2).replace(".", replaceWithValue);
    bill.billObj.billingFees.billing = parseFloat(changeToDot(bill.billObj.billingFees.billing)).toFixed(2).replace(".", replaceWithValue);

    // Go through all rows
    bill.billObj.rows.forEach(row => {
        row.quantity = parseFloat(changeToDot(row.quantity)).toFixed(2).replace(".", replaceWithValue);
        row.price = parseFloat(changeToDot(row.price)).toFixed(2).replace(".", replaceWithValue);
    });

    return bill;
};

export const changeStingToInt = bill => {

    // Change billing fees
    bill.billObj.billingFees.freight = parseFloat(changeToDot(bill.billObj.billingFees.freight));
    bill.billObj.billingFees.billing = parseFloat(changeToDot(bill.billObj.billingFees.billing));

    // Go through rows
    bill.billObj.rows.forEach(row => {
        row.quantity = parseFloat(changeToDot(row.quantity));
        row.price = parseFloat(changeToDot(row.price));
    });

    return bill;
};
