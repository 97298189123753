const ReportTypes = {
    HOUR_WAGE: "HOUR_WAGE",
    PERIOD_FIXED: "PERIOD_FIXED",
    WORKTIME_PERIOD: "WORKTIME_PERIOD",
    APPROVAL: "APPROVAL",
    CUSTOMER_TIME: "CUSTOMER_TIME",
    CUSTOMER_BILLS: "CUSTOMER_BILLS",
    BILLS: "BILLS",
    KTV: "KTV",
    WORKLIST: "WORKLIST",
};
export default ReportTypes;