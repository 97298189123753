import React, { Component } from "react";
import "./App.css";
import firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/auth";
import { Button, Paper, TextField } from "@material-ui/core";
import SignedIn from "./SignedIn";
import { Provider } from "react-redux";
import store from "./store";
import logo from './img/logo.jpg';
import keycode from 'keycode';
import RegisterLanding from "./RegisterComponent/RegisterLanding";
import RegisterVehicle from './RegisterVehicle';
import { getUser2 } from "./actions/userActions";
import { withRouter, Route, useLocation } from 'react-router-dom';
import SignReportElectronically from './ReportComponents/SignElectronically';

class App extends Component {
  constructor(props) {
    /* Properties */

    super(props);

    /* State variables */

    this.state = {
      isSignedIn: false,
      register: false,
      loading: true,
      username: "",
      password: ""
    };

    /* Member variables */
    //this.wrapper = React.createRef();

    this.stompClient = null;
  }

  componentDidMount() {
    this.unregisterAuthObserver = firebase
      .auth()
      //.onAuthStateChanged(user => this.setState({ isSignedIn: !!user, loading: false }));
      .onAuthStateChanged(user => {
        if (user) {
          this.unsubscribeEmployeeListener = firebase.firestore().collection("employees").doc(user.uid).onSnapshot(docSnap => {
            if (docSnap.exists) {
              const employee = docSnap.data();
              employee.id = docSnap.id;
              // SET EMPLOYEE TO REDUX
              store.dispatch(getUser2(employee));

              // Move language check here?
              // Move other redux functions from SignedIn?
            }
          });
          this.setState({ isSignedIn: !!user, loading: false })
        }
        else {
          this.setState({ loading: false, isSignedIn: false })
        }

      });

  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
    this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
  }

  logIn = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.username, this.state.password)
      .catch(function (error) {
        console.log(error);
      });
  };

  handleKeyDown = (event) => {
    if (keycode(event) === "enter") {
      this.logIn()
    }
  };

  render() {

    console.log('location', this.props.location)
    if (this.props.location.pathname?.includes('signreportelectronically')) {
      return (
        <div style={{ height: '100vh' }}>
          <Route path="/signreportelectronically/:jwt" component={SignReportElectronically} />
        </div>
      )
    }
    //console.log('this happens for some reason')
    if (!this.state.isSignedIn && !this.state.register) {
      return (
        <Provider store={store}>
          <div style={{ margin: "auto", width: 500, marginTop: "15%" }}>
            {!this.state.loading &&
              <Paper style={{ padding: "20px" }}>
                <img src={logo} style={{ width: 200, height: 140, padding: 20, alignSelf: 'center', marginLeft: 100 }} alt={"Mobility"} />
                <br />
                <h1
                  style={{ paddingLeft: "20px", paddingRight: "20px", margin: 0, marginLeft: 140 }}
                >
                  Helppotyö
              </h1>
                <div>
                  <TextField
                    variant="outlined"
                    id="username"
                    label="Username"
                    autoComplete="username"
                    value={this.state.username}
                    onChange={event =>
                      this.setState({ username: event.target.value })
                    }
                    style={{ margin: "10px", width: 450, marginTop: 20 }}
                  />
                  <TextField
                    variant="outlined"
                    id="password"
                    label="Password"
                    type="password"
                    autoComplete="current-password"
                    value={this.state.password}
                    onChange={event =>
                      this.setState({ password: event.target.value })
                    }
                    style={{ margin: "10px", width: 450 }}
                    InputProps={{
                      onKeyDown: this.handleKeyDown
                    }}
                  />
                </div>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={this.logIn}
                  style={{ marginLeft: "130px", marginRight: "20px", marginTop: 20, width: 180 }}
                >
                 Kirjaudu
              </Button>


                <p className={"register"} onClick={() => this.setState({ register: true })}>Rekisteröidy</p>

              </Paper>}
          </div>
          
        </Provider>
      );
    }

    if (!this.state.isSignedIn && this.state.register) {
      return (
        <Provider store={store}>
          <RegisterLanding
            onRegister={() => this.setState({ register: false })}
          />
        </Provider>
      );
    }

    //TODO Remove these
    //console.log("PROPS ", this.props);
    if (false && this.props.location && this.props.location.pathname === 'registervehicle') {
      return <Route path="/registervehicle/:jwt" component={RegisterVehicle} />
    }


    return (
      <Provider store={store}>
        <SignedIn />
      </Provider>
    );
  }
}

export default withRouter(App);
