import {isEmpty} from "./isEmpty";
import Validator from 'validator';

export const validateCrmEmail = email => {
    const errors = {};

    email.title = !isEmpty(email.title) ? email.title : "";
    email.message = !isEmpty(email.message) ? email.message : "";
    email.emailAddress = !isEmpty(email.emailAddress) ? email.emailAddress : "";
    email.pdfName = !isEmpty(email.pdfName) ? email.pdfName : "";


    if (Validator.isEmpty(email.title)) {
        errors.title = "Title_required";
    }

    if (Validator.isEmpty(email.message)) {
        errors.message = "Message_required";
    }

    if (!Validator.isEmail(email.emailAddress)) {
        errors.emailAddress = "Invalid_email";
    }

    if (Validator.isEmpty(email.emailAddress)) {
        errors.emailAddress = "Sender_required";
    }

    if (email.useAttachment) {
        if (Validator.isEmpty(email.pdfName)) {
            errors.pdfName = "Attachment_required";
        }
    }

    if (email.selectedCustomers && email.selectedCustomers.length === 0) {
        errors.selectedCustomers = "SelectedCustomers_required";
    }

    return {
        errors,
        isValid: isEmpty(errors)
    }
};