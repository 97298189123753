import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/auth";
import * as jsonwebtoken from 'jsonwebtoken';

export default function (props) {
    const [spotOnUser, setSpotOnUser] = useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (error) {
            alert(error);
            window.ReactNativeWebView.postMessage(JSON.stringify({ error }));
        } else {
            setSpotOnUser(jsonwebtoken.decode(jwt));
        }
        return () => {
        };
    }, [error]);

    const { jwt } = useParams();

    const getMobilityUserAndAddSpotonUser = (spotOnUser) => {
        firebase.firestore()
            .collection('spoton_users')
            .where('mobilityUserId', '==', spotOnUser.mobilityUserId)
            .where('spotOnUserId', '==', spotOnUser.spotOnUserId)
            .get()
            .then(querySnapshot => {
                if (querySnapshot.size === 0) {
                    addSpotOnUser(spotOnUser)
                } else {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ mobilityUserId: firebase.auth().currentUser.uid, message }));
                }
            })
    }

    const addSpotOnUser = (spotOnUser) => {
        firebase
            .firestore()
            .collection('spoton_users')
            .add(spotOnUser)
            .then(() => {
                window.ReactNativeWebView.postMessage(JSON.stringify({ mobilityUserId: firebase.auth().currentUser.uid, message }));
            });
    }

    if (spotOnUser && !error) {
        if (spotOnUser.mobilityUserId && spotOnUser.mobilityUserId !== firebase.auth().currentUser.uid) {
            setError('SpotOn user already registered with different mobility user');
        } else if (spotOnUser.mobilityUserId && spotOnUser.mobilityUserId === firebase.auth().currentUser.uid) {
            setError('1. SpotOn user already registered')
        } else if (!spotOnUser.mobilityUserId) {
            const newSpotOnUser = { ...spotOnUser, mobilityUserId: firebase.auth().currentUser.uid };
            getMobilityUserAndAddSpotonUser(newSpotOnUser);
        } else {
            setError('This shouldn\'t happen');
        }
    }

    return (
        <div>
            <div>{message}</div>

        </div>
    )
}