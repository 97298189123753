import {Checkbox, FormControlLabel} from "@material-ui/core";
import React from "react";

const FilterMenuContents = props => {
    const {t, filterField, filterTypes, filterBillable, filterActive, filterValues} = props;

    const renderType = () => {
        return (
            <div style={{marginLeft: 5, marginTop: 0}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!filterValues.find(value => value.type === "false" && value.field === "type")}
                            onChange={() => props.filterProducts("false", "type")}
                            style={{color: "#1770B2"}}
                        />
                    }
                    label={t("Service")}
                />
                <br/>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!filterValues.find(value => value.type === "true" && value.field === "type")}
                            onChange={() => props.filterProducts("true", "type")}
                            style={{color: "#1770B2"}}
                        />
                    }
                    label={t("Material")}
                />
                <br/>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!filterValues.find(value => value.type === "travel" && value.field === "type")}
                            onChange={() => props.filterProducts("travel", "type")}
                            style={{color: "#1770B2"}}
                        />
                    }
                    label={t("Travel")}
                />
                <br/>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!filterValues.find(value => value.type === "checkpoint" && value.field === "type")}
                            onChange={() => props.filterProducts("checkpoint", "type")}
                            style={{color: "#1770B2"}}
                        />
                    }
                    label={t("Checkpoint")}
                />

            </div>
        );
    };


    const renderBillable = () => {
        return (
            <div style={{marginLeft: 5, marginTop: 0}}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!filterValues.find(value => value.type === "true" && value.field === "billable")}
                            onChange={() => props.filterProducts("true", "billable")}
                            style={{color: "#1770B2"}}
                        />
                    }
                    label={t("Yes")}
                />
                <br/>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!!filterValues.find(value => value.type === "false" && value.field === "billable")}
                            onChange={() => props.filterProducts("false", "billable")}
                            style={{color: "#1770B2"}}
                        />
                    }
                    label={t("No")}
                />
            </div>
        );
    };


    const renderActive = () => {
        return (
            <div>
                <div style={{marginLeft: 5, marginTop: 0}}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!filterValues.find(value => value.type === "true" && value.field === "active")}
                                onChange={() => props.filterProducts("true", "active")}
                                style={{color: "#1770B2"}}
                            />
                        }
                        label={t("Yes")}
                    />
                    <br/>

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!filterValues.find(value => value.type === "false" && value.field === "active")}
                                onChange={() => props.filterProducts("false", "active")}
                                style={{color: "#1770B2"}}
                            />
                        }
                        label={t("No")}
                    />
                </div>
            </div>
        );
    };




    return (
        <div>
            {filterField === "type" && renderType()}
            {filterField === "billable" && renderBillable()}
            {filterField === "active" && renderActive()}
        </div>
    )
};

export default FilterMenuContents;


