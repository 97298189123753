import {isEmpty} from "./isEmpty";
import Validator from 'validator';


export const validateJobModelProductArray = (productArray, id, field) => {
    let errors = {};

    
    if (productArray && productArray !== "undefined") {
        if (typeof productArray[id][field] == "undefined") {
            if (field === 'unit' || field === 'pNumber' || field === 'name' || field === 'tasksname')
                errors[field] = "JM_Required";

        } else if (field === 'unit' && Validator.isEmpty(productArray[id][field])) {
            errors.unit = "JM_unitRequired";
        }
    }
    console.log("validateJobModelProductArray productArray ", productArray, id, field, errors);
    return {
        errors,
        isValid: isEmpty(errors)
    }
}

export const validateJobModel = (_model, productArray) => {
    let errors = {};

    let model = _model;

    console.log("validate model 1 ", model);

    if (!isEmpty(model)) {

        if (!model.name || Validator.isEmpty(model.name)) {
            errors.name = "JM_NameRequired";
        }

        if (model.tasks) {
            model.tasks.map((task, index) => {
                console.log("validate task 1", task);
                if (!task.name) {
                    errors.tasksname = "JM_TaskNameRequired";
                } else if (task.name && Validator.isEmpty(task.name)) {
                    errors.tasksname = "JM_TaskNameRequired";
                }
                if (!task.unit) {
                    errors.tasksunit = "JM_unitRequired";
                } else if (task.unit && Validator.isEmpty(task.unit)) {
                    errors.tasksunit = "JM_unitRequired";
                }
            })
        }
    } else {
        console.log("NO MODEL CREATED YET")
        errors.name = "JM_NameRequired";
        errors.tasksname = "JM_TaskNameRequired";
        errors.tasksunit = "JM_unitRequired";
    }

    console.log("validateJobModel errors? ", errors)

    return {
        errors,
        isValid: isEmpty(errors)
    }
};

export const validateJobTemplate = (model, productArray) => {
    let errors = {};

    if (!model.name || (model.name && Validator.isEmpty(model.name))) {
        errors.name = "JM_NameRequired";
    }

    //same code than savehandler
    let tasks = [];

    if (productArray && Array.isArray(productArray)) {
        productArray.map(product => {
            if (product.pNumber) {
                tasks.push(product)
            }
        });
        model.tasks = tasks;
    }

    console.log("validate TEMPLATE ", model);

    if (model.tasks && model.tasks.length > 0) {
        model.tasks.map((task, index) => {
            console.log("validate task 2", task);
            if (!task.name || task.name && Validator.isEmpty(task.name)) {
                errors.tasksname = "JM_TaskNameRequired";
            }
            if (!task.unit || task.unit && Validator.isEmpty(task.unit)) {
                errors.tasksunit = "JM_unitRequired";
            }
            if (!task.pNumber) {
                errors.taskpnumber = "JM_PnumRequired";
            }
        })
    } else {
        errors.tasks = "JM_TasksRequired";
    }

    console.log("return errors ", errors)
    return {
        errors,
        isValid: isEmpty(errors)
    }
};
