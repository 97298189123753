export const solveBillingErrorMessage = (sendingMethod, error, t) => {
  const identifiedIssues = [];

  console.log("solveBillingErrorMessage 1: ", error.toString());

  if (!error) {
    identifiedIssues.push(t("errEmptyError"));
  } else {
    if (error.data && error.status) {
      identifiedIssues.push(
        `${error.data}: ${error.status} ${error.statusText}`
      );
    } else {
      const errori = error.toString().toLowerCase();

      if (errori.includes("network error")) {
        identifiedIssues.push(t("errNetworkError"));
      }

      if (errori.includes("Request failed with status code")) {
        console.log("solveBillingErrorMessage 1.1: ", identifiedIssues);
        identifiedIssues.push(`${t("errStatus")} ${errori}`);
      }

      console.log("solveBillingErrorMessage 2: ", identifiedIssues);

      //TODO identify some errors
      //TODO mieti pitääkö kanavaa (sendingMethod) tässä miettiä  muuten kuin virheviestin selkeyttämiseksi

      if (sendingMethod?.includes("apix")) {
        console.log("solveBillingErrorMessage 3 apix: ", identifiedIssues);

        if (errori.includes("email")) {
          identifiedIssues.push(t("errMissingBuyerEmail"));
        }
        if (errori?.includes("postalcode")) {
          identifiedIssues.push(t("errMissingBuyerPostalCode"));
        }
        if (errori?.includes("city")) {
          identifiedIssues.push(t("errMissingBuyerCity"));
        }

        if (errori.includes("laskun kuva(pdf) puuttuu")) {
          identifiedIssues.push(t("errMissingAppendix"));
        }

        console.log(
          "solveBillingErrorMessage identifiedIssues: ",
          identifiedIssues
        );
      } 
      // Tää on vähän duplikaaattia mutta kun ei tiedä vieä missä muorossa ICF:ltä nää tulee
      if (sendingMethod === "netvisor") {
        /*INVALID_DATA :: Tiedon muoto virheellinen:. Product not found: *Tuotenumero*
          INVALID_DATA :: Tiedon muoto virheellinen:. Customer not found: *Asiakasnumero*
          DUPLICATE_DATA :: Päällekkäistä dataa. Annetulla numerolla löytyy jo lasku/tilaus, numero: *laskunumero*
          PERIOD_LOCK :: Laskupäivä: Ei voi tallentaa laskua lukitulle kaudelle tai tilikaudelle, jota ei ole luotu (Tämä on Netvisorin kirjanpitoasetusten “virhe” ja varmistus, mistä pitää olla yhteydessä kirjanpitäjään)”
        */
        if (errori?.includes("product not found")) {
          identifiedIssues.push(t("errNetvisorProductNotfound"));
        }
        if (errori?.includes("customer not found")) {
          identifiedIssues.push(t("errNetvisorCustomerNotfound"));
        }
        if (errori?.includes("duplicate data")) {
          identifiedIssues.push(errori);
        }
        if (errori?.includes("period lock")) {
          identifiedIssues.push(errori);
        }
      }
    }
  }

  const errorMsg =
    identifiedIssues.length > 0
      ? `${t("IdentifiedIssues")} ${identifiedIssues.flat()}`
      : `${t("FullOriginalError")}: ${error}`;

  console.log("solveBillingErrorMessage SOLVED: ", errorMsg);

  return errorMsg;
};
