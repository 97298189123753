import React, {Component} from 'react';

import {
    Button,
    TextField,
    MenuItem,
    IconButton,
    CircularProgress,
    Checkbox,
    Grid,
    Paper,
} from "@material-ui/core";

import {
    Delete
} from "@material-ui/icons";


import {changeBillableToString, changeBillableToInt} from "./WorklogHelpers";
import {setModels} from "../actions/modelActions";
import {setCustomers} from "../actions/customerActions";
import {setEmployees} from "../actions/employeeActions";

import WorklogAC from "./WorklogAC";
import TimeButtonComponent from "./TimeButtonComponent";
import DateDialog from '../JobCalendarComponents/DateDialog';
import TimeDialog from '../JobCalendarComponents/TimePicker'
import TaskDialog from "./TaskDialog";
import ImageDialog from '../ImageDialog';
import GlobalConfirmationDialog from "../CommonDialogs/GlobalConfirmationDialog";

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";

//import {default as firebase, firestore} from "firebase";
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import moment from "moment-timezone";
import "moment/locale/fi";
import "moment/locale/en-gb";
import MapComponent from '../util/GoogleMap';
import "./WorklogEditor.css";

class WorklogEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            worklog: [],
            datePicker: false,
            timePicker: false,
            taskDialog: false,
            DTValue: "",
            pickField: "",
            statusArray: ["Ongoing", "Finished", "Submitted", "Confirmed", "Billed", "Cancelled", "Archived"],
            searchEmployee: "",
            confirmationDialog: false,
            sortDirection: "asc",
            saving: false,
            unsavedChanges: false,
            job: {}
        }
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        const {user} = this.props.user;
        const {customers} = this.props.customers;
        const {worksites} = this.props.worksites;
        const {employees} = this.props.employees;
        const {models} = this.props.models;

        console.log("WorklogEditor params: ", params);

        /* just in case this does not come from props */
        if (!customers.length > 0) firebase.firestore().collection("customers").where("companyID", "array-contains", user.companyID).get().then(querySnap => this.onQuerySnap(querySnap, "customers"));
        if (!employees.length > 0) firebase.firestore().collection("employees").where("companyID", "==", user.companyID).get().then(querySnap => this.onQuerySnap(querySnap, "employees"));
        if (!worksites.length > 0) firebase.firestore().collection("company").doc(user.companyID).collection("worksites").get().then(querySnap => this.onQuerySnap(querySnap, "worksites"));
        if (!models.length > 0) firebase.firestore().collection("company").doc(user.companyID).collection("jobModels").get().then(querySnap => this.onQuerySnap(querySnap, "models"));


        if (params.wlID) {
            this.unsubWorklog = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").doc(params.wlID).onSnapshot(docSnap => {
                if (docSnap.exists) {
                    let worklog = docSnap.data();
                    worklog.id = docSnap.id;

                    worklog = changeBillableToString(worklog);
                    console.log("WL", worklog);

                    const customerWorksites = this.findCustomerWorksites(worklog.customer);

                    this.setState({loading: false, worklog, customerWorksites});

                    this.getJobByID(worklog.jobID);

                }
            })
        }

        if (params.jobID && !params.wlID) {
            //this is unreported occurrence, passed job id from url
            // need to calc occurrence again to get proper dates, not just start of recurrence
            firebase.firestore().collection("company").doc(user.companyID).collection("jobs").doc(params.jobID).get()
                .then(doc => {
                    if (doc.exists) {
                        let job = doc.data();
                        job.id = doc.id;

                        const worklog = this.generateNewFromJob(job, params.startDT, params.endDT);
                        const customerWorksites = this.findCustomerWorksites(job.customer);

                        this.setState({loading: false, worklog, customerWorksites});
                    } else { this.setState({loading: false} ) }
                })
        }
    }

    getJobByID = id => {
        const {user} = this.props.user;

        if (id) {
            this.setState({loading: true});

            firebase.firestore().collection("company").doc(user.companyID).collection("jobs").doc(id).get()
                .then(doc => {
                    if (doc.exists) {
                        let job = doc.data();
                        job.id = doc.id;

                        this.setState({loading: false, job});
                    }
                    else { this.setState({loading: false }) }
                })
        }
    }


    generateNewFromJob = (job, startDT, endDT) => {
        const {user} = this.props.user;

        const newLog = {};

        newLog.startDT = startDT ? moment(startDT).format('YYYYMMDDTHHmmss') : moment(job.startDT).format('YYYYMMDDTHHmmss');
        newLog.endDT = endDT ? moment(endDT).format('YYYYMMDDTHHmmss') : moment(job.endDT).format('YYYYMMDDTHHmmss');
        newLog.jobStartDT = startDT ? moment(startDT).format('YYYYMMDDTHHmmss') : moment(job.startDT).format('YYYYMMDDTHHmmss');
        newLog.duration = moment(job.endDT) - moment(job.startDT);
        newLog.jobID = job?.id;
        newLog.customer = job?.customer;
        newLog.TZ = job?.TZ;
        newLog.status = "Finished";

        if (job.templateID) newLog.templateID = job.templateID;

        newLog.billing = {
            billableHours: (newLog.duration / 3600000).toFixed(2)
        };

        const empArr = [];
        job.employees && job.employees.forEach(emp => {
            empArr.push(emp.id)
        });

        newLog.employee = empArr;
        newLog.generated = true;
        newLog.generatedBy = user?.name | "unknown";
        newLog.generatedDT = moment().format('YYYYMMDDTHHmmss');
        newLog.OS = "web";

        console.log("generateNewFromJob: ", newLog);

        return newLog;
    };

    componentWillUnmount() {
        this.unsubWorklog && this.unsubWorklog();
    }

    onQuerySnap = (querySnap, dataName) => {
        const dataArr = [];

        querySnap.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;
            if (dataName === "employees") data.name = `${data.firstName} ${data.familyName}`;
            dataArr.push(data);
        });

        if (dataName === "customers") this.props.setCustomers(dataArr);
        if (dataName === "employees") this.props.setEmployees(dataArr);
        if (dataName === "models") this.props.setModels(dataArr);
        //if (dataName === "worksites") this.props.setWorksites(dataArr);
    };

    findCustomerWorksites = (customer) => {
        const {worksites} = this.props.worksites;

        let customerWorksites = [];

        if (customer && worksites) {
            customerWorksites = worksites.filter(site => site.customer === customer);
            console.log("found customerWorksites: ", customerWorksites)
        }
        return customerWorksites;
    }
    x
    getEmployeeName = id => {
        const {employees} = this.props.employees;

        const employee = employees.find(employee => employee.id === id);
        if (employee) return employee.name;
        else return " - ";
    };

    onWorklogUpdate = worklog => {
        this.setState({worklog, taskDialog: false, unsavedChanges: true})
    };

    onDateChange = (event, field) => {
        const {worklog} = this.state;

        const time = moment(worklog[field]).format("HH:mm:ss");

        const date = moment(event.selection.startDate).format("YYYY-MM-DD");

        worklog[field] = moment(`${date}T${time}`).format("YYYYMMDDTHHmmss");

        if (field === "startDT") {
            // if editing job start date, should also change jobStart & end DATE, so that will match to the unreported search
            worklog.jobStartDT = moment(`${date}T${time}`).format("YYYYMMDDTHHmmss");
            const endTime = moment(worklog.endDT).format("HH:mm:ss");  
            worklog.endDT = moment(`${date}T${endTime}`).format("YYYYMMDDTHHmmss");
        }

        this.setState({worklog, datePicker: false, unsavedChanges: true});
    };

    onTimeChange = (event, field) => {
        const {worklog} = this.state;

        const duration = moment(worklog.endDT).diff(worklog.startDT, "milliseconds");

        const date = moment(worklog[field]).format("YYYY-MM-DD");

        const time = moment(event).format("HH:mm:ss");

        worklog[field] = moment(`${date}T${time}`).format("YYYYMMDDTHHmmss");

        if (field === "startDT") {
            const newEndTime = moment(event) + moment(duration);
            const endTime = moment(newEndTime).format("HH:mm:ss");
            worklog.endDT = moment(`${date}T${endTime}`).format("YYYYMMDDTHHmmss");
        }

        if (field === "endDT") {
            worklog.billing ? worklog.billing.billableHours = (duration / 3600000).toFixed(2) : worklog.billing = {billableHours: (duration / 3600000).toFixed(2)}
        }

        this.setState({worklog, timePicker: false, unsavedChanges: true})
    };

    onStatusChange = (event) => {
        const {worklog} = this.state;
        worklog.status = event.target.value;
        this.setState({worklog, unsavedChanges: true});
    };

    onEmployeeSelect = event => {
        const {worklog} = this.state;
        const {employees} = this.props.employees;

        if (!worklog.employee.includes(event.id)) worklog.employee.push(event.id);
        else {
            const removeIndex = worklog.employee.indexOf(event.id);
            worklog.employee.splice(removeIndex, 1);
        }

        this.setState({worklog, searchEmployee: "", unsavedChanges: true})
    };

    onBillableTimeChange = (value, field, index) => {
        const {worklog} = this.state;

        const regex = /^[0-9]{0,3}[.0-9]{0,3}$/;
        const noSpace = value.replace(/\s+/g, '');


        if (field === "billableHours") {
            if (regex.test(noSpace) || value.length === 0) worklog.billing.billableHours = noSpace;
        }
        else {
            if (regex.test(noSpace) || value.length === 0) worklog.billing.billableHoursByTask[index].duration = noSpace;
        }

        this.setState({worklog, unsavedChanges: true});
    };

    onTaskDelete = index => {
        const {worklog} = this.state;
        worklog.billing.billableHoursByTask.splice(index, 1);
        this.setState({worklog, unsavedChanges: true});
    };

    onPauseTimeChange = (value, index) => {
        const {worklog} = this.state;

        const regex = /^[0-9]{0,3}[.0-9]{0,3}$/;
        const noSpace = value.replace(/\s+/g, '');

        if (regex.test(noSpace) || value.length === 0) worklog.pauses[index].duration = noSpace;

        this.setState({worklog, unsavedChanges: true});
    }

    onPauseDelete = index => {
        const {worklog} = this.state;
        worklog.pauses.splice(index, 1);
        this.setState({worklog, unsavedChanges: true});
    };


    onAdditionalDescriptionChange = (event) => {
        const {worklog} = this.state;
        worklog.additionalDescription = event.target.value;
        this.setState({worklog, unsavedChanges: true});
    };

    onMaterialAmountChange = (event, index) => {
        const {worklog} = this.state;

        worklog.materials[index].amount = event.target.value;

        this.setState({worklog, unsavedChanges: true});
    };

    onMaterialDelete = index => {
        const {worklog} = this.state;

        worklog.materials.splice(index, 1);

        this.setState({worklog, unsavedChanges: true});
    };

    onWorklogDiscard = () => {
        const {selectedJob} = this.props;
        const {user, userID} = this.props.user;

        const emptyWorklog = {
            startDT: moment(selectedJob.startDT).format("YYYYMMDDTHHmmss"),
            jobStartDT: moment(selectedJob.startDT).format("YYYYMMDDTHHmmss"),
            jobID: selectedJob.id,
            status: "Discarded",
            TZ: selectedJob.TZ,
            employee: [userID]
        };

        firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").add(emptyWorklog)
            .then(() => {
                this.props.history.goBack();
            });
    };

    onWorklogDelete = () => {
        const {worklog} = this.state;
        const {user} = this.props.user;

        firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").doc(worklog.id).delete();
        this.props.history.goBack();
    };

    onWorklogSave = () => {
        const worklog = changeBillableToInt(this.state.worklog);
        const {user} = this.props.user;

        this.setState({saving: true})
        if (worklog.id) firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").doc(worklog.id).set(worklog)
            .then(() => {
                this.setState({saving: false, unsavedChanges: false}, this.props.history.goBack())
            });  //TODO: catch errors
        else firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").add(worklog)
            .then(() => {
                this.setState({saving: false, unsavedChanges: false,}, this.props.history.goBack())
            });

    };

    deleteImage = index => {
        const worklog = changeBillableToInt(this.state.worklog);
        const {user} = this.props.user;
        const {worksites} = this.props.worksites;

        const imageRef = firebase.storage().refFromURL(worklog.images[index].image_url);

        let worksite = {};
        let imageIndex = null;

        worksites.forEach(site => {
            if (site.customer === worklog.customer && site.images) {
                const image = site.images.find(image => image.date === worklog.images[index].date);
                if (image) {
                    worksite = site;
                    imageIndex = worksite.images.indexOf(image);
                }
            }
        });


        imageRef.delete()
            .then(() => {
                worklog.images.splice(index, 1);
                worklog.startDT = moment(worklog.startDT).format("YYYYMMDDTHHmmss");
                worklog.endDT = moment(worklog.endDT).format("YYYYMMDDTHHmmss");
                console.log(worklog)
                firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').doc(worklog.id).set(worklog)
                    .then(() => {
                        let backToStr = JSON.parse(JSON.stringify(worklog));
                        backToStr = changeBillableToString(backToStr);

                        this.setState({
                            worklog: backToStr
                        });
                    });

                // Delete reference from worksites aswell
                if (worksite && worksite.images) {
                    worksite.images.splice(imageIndex, 1);
                    firebase.firestore().collection('company').doc(user.companyID).collection('worksites').doc(worksite.id).set(worksite);
                }
            });

    };

    secondsToHms = (d) => {
        d = Number(d);
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hDisplay = h > 0 ? h + (h === 1 ? " h, " : " h ") : "";
        let mDisplay = m > 0 ? m + (m === 1 ? " min, " : " min ") : "";
        let sDisplay = s > 0 ? s + (s === 1 ? " sec" : " sec") : "";
        return hDisplay + mDisplay + sDisplay;
    };


    worksiteHandler = event => {
        const {worklog} = this.state;

        //console.log("worksiteHandler ", event.target.value);

        //check needed?
        //const {customerWorksites} = this.state;
        //const worksite = customerWorksites.find(site => site.id === event.target.value)
        //if (worksite)  worklog.worksite = event.target.value;

        worklog.worksite = event.target.value;

        this.setState({worklog, unsavedChanges: true});
    };

    checkboxHandler = (value, field, index) => {
        const {worklog} = this.state;

        console.log("worklog before checkboxHandler ", worklog)
        console.log("checkboxHandler ", value, field, index)


        if (field === "pauses") {
            worklog[field][index].isPayable = value;

        }

        console.log("worklog after checkboxHandler ", worklog)
        this.setState({
            worklog: worklog,
            unsavedChanges: true
        });

    };

    todoHandler = (value, field) => {
        const {worklog} = this.state;

        console.log("worklog before todoHandler ", worklog)
        console.log("todoHandler ", value, field)

        if (field === "normalTodo") {
            let ind = worklog.normalTodo.findIndex(todo => todo === value);
            if (ind != -1) {
                worklog.normalTodo.splice(ind, 1);
            } else {
                worklog.normalTodo.push(value)
            }
        }

        if (field === "extraTodo") {
            let ind = worklog.extraTodo.findIndex(todo => todo === value);
            if (ind != -1) {
                worklog.extraTodo.splice(ind, 1);
            } else {
                worklog.extraTodo.push(value)
            }
        }


        console.log("worklog after todoHandler ", worklog)
        this.setState({
            worklog: worklog,
            unsavedChanges: true
        });

    };


    render() {
        const {loading, worklog, saving, unsavedChanges, job} = this.state;
        const {employees} = this.props.employees;
        const {customers} = this.props.customers;
        let {worksites} = this.props.worksites;
        const {t} = this.props;

        const {customerWorksites} = this.state;

        if (customerWorksites && customerWorksites.length > 0) {
            worksites = customerWorksites;
        } else {
            const cws = this.findCustomerWorksites(worklog);
            if (cws && cws.length > 0) {
                worksites = customerWorksites;
            }
        }

        const customer = customers.find(customer => customer.id === worklog.customer);
        const worksite = worksites.find(worksite => worksite.id === worklog.worksite);

        console.log("worksite found ", worksite);
        console.log("job found ", job);
        console.log("worklog   ", worklog);

        if (loading) return <div></div>
        else return (
            <div className="we-container">
                <div className="we-titlebar">
                    <h3>Tiedot</h3>
                </div>

                <div className="we-row-container we-h2">
                    <div className="we-column-container we-3 we-brd">
                        <div className="we-center-item">
                            <div className="we-center-left">{t("Customer_name")}</div>
                            <div className="we-center-right">{customer ? customer.name : ""}</div>
                        </div>
                        <div className="we-center-item">
                            <div className="we-center-left">{t("Worksite_name")}</div>
                            <div className="we-center-right">
                                <TextField
                                    variant="outlined"
                                    label={t("Worksite")}
                                    style={{
                                        width: 315,
                                        marginRight: 10,
                                        marginBottom: 5,
                                    }}
                                    select
                                    value={worksite ? worksite.id : "" || ""}
                                    onChange={event => this.worksiteHandler(event)}
                                >
                                    {!customer &&
                                    <MenuItem value={"noCustomer"} key={"noCustomer"}>{t("No_customer")}</MenuItem>}
                                    {worksites && worksites.map(site => (
                                        <MenuItem value={site.id} key={site.id}>{site.name}</MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        </div>

                        {/*
                        <div className="we-center-item">
                            <div className="we-center-left">{t("Worksite_name")}</div>
                            <div className="we-center-right">{worksite ? worksite.name : " - "}</div>
                        </div> */}

                        <div className="we-center-item">
                            <div className="we-center-left">{t("Worksite_address")}</div>
                            <div
                                className="we-center-right">{worksite ? (worksite.streetAddress + ', ' + worksite.postCode + ' ' + worksite.city) : " - "}</div>
                        </div>

                    </div>

                    <div className="we-column-container we-3 we-brd">
                        <div className="we-item-buttons">
                            <Button onClick={() => this.setState({
                                DTValue: moment(worklog.startDT).toDate(),
                                pickField: "startDT",
                                datePicker: true
                            })}>
                                {moment(worklog.startDT).locale("fi").format('L')}
                            </Button>
                            <Button onClick={() => this.setState({
                                DTValue: moment(worklog.startDT).toDate(),
                                pickField: "startDT",
                                timePicker: true
                            })}>
                                {moment(worklog.startDT).format('LT')}
                            </Button>

                            {" - "}

                            <Button onClick={() => this.setState({
                                DTValue: moment(worklog.endDT).toDate(),
                                pickField: "endDT",
                                datePicker: true
                            })}>
                                {moment(worklog.endDT).locale("fi").format('L')}
                            </Button>

                            <Button onClick={() => this.setState({
                                DTValue: moment(worklog.endDT).toDate(),
                                pickField: "endDT",
                                timePicker: true
                            })}>
                                {moment(worklog.endDT).format('LT')}
                            </Button>

                            <div className="we-center-item">
                                <div className="we-center-left">{t("Work time")}</div>
                                <div
                                    className="we-center-right">{worklog.startDT && worklog.endDT ? this.secondsToHms((moment(worklog.endDT) - moment(worklog.startDT)) / 1000) : ""}
                                </div>
                            </div>

                        </div>

                        <div className="we-center-item">
                            <div className="we-center-left">
                                <TextField
                                    variant="outlined"
                                    value={worklog.status || ""}
                                    InputProps={{style: {height: 40}}}
                                    label={this.props.t("Status")}
                                    onChange={this.onStatusChange}
                                    style={{width: '50%'}}
                                    //error={worklog.status === "" || worklog.status === "Select" || !this.state.statusArray.find(worklog.status)}
                                    select
                                >
                                    {this.state.statusArray.map(item => (
                                        <MenuItem key={item} value={item}>{this.props.t(item)}</MenuItem>
                                    ))}

                                </TextField>
                            </div>

                            {worklog.previousStatus &&
                            <div style={{paddingTop: 15}} className="we-center-right">
                                <div className="we-center-right">
                                    <p>{t("Worklog previous status")}: </p>
                                    <p>{t(worklog.previousStatus)}</p>
                                </div>
                                <div className="we-center-right">
                                    <p>Veloitus: </p>
                                    <p>{worklog.billingPercent ? worklog.billingPercent * 100 : 100} %</p>
                                </div>
                            </div>
                            }
                        </div>

                    </div>

                    <div className="we-column-container we-3">
                        <div className="we-center-item">
                            <div className="we-center-left">Aloitusosoite:</div>
                            <div className="we-center-right">{worklog.startAddress ? worklog.startAddress : ""}</div>
                        </div>

                        <div className="we-center-item">
                            <div className="we-center-left">Lopetusosoite:</div>
                            <div className="we-center-right">{worklog.endAddress ? worklog.endAddress : ""}</div>
                        </div>


                        {worklog.generated &&
                        <div className="we-center-item">
                            <div className="we-center-left">Lisätty jälkikäteen:</div>
                            <div className="we-center-right">Ei sijaintiin perustuvaa todennusta</div>
                        </div>
                        }

                    </div>
                </div>

                <div className="we-titlebar">
                    <h3>Työntekijät & toiminnot</h3>
                </div>

                <div className="we-row-container we-h3">
                    <div className="we-column-container we-3 we-brd">
                        <div style={{
                            width: '80%',
                            display: 'flex',
                            borderBottomColor: '#1770B2',
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            marginBottom: 10
                        }}>
                            <p style={{width: '30%'}}>{""}</p>
                            <p style={{width: '30%', textAlign: 'center'}}>{t("Employees")}</p>
                            <p style={{width: '30%', textAlign: 'center'}}>{t("Delete")}</p>
                            <p style={{width: '10%', textAlign: 'center'}}></p>
                        </div>

                        <WorklogAC
                            searchValues={["name"]}
                            listShowField={"name"}
                            searchData={employees}
                            listMaxLength={5}
                            onSelect={this.onEmployeeSelect}
                            onChange={event => this.setState({searchEmployee: event})}
                            value={this.state.searchEmployee || ""}
                            fieldName={"searchEmployee"}
                            placeholder={this.props.t("Employee")}
                            style={{width: 325}}
                        />
                        {worklog.employee && worklog.employee.map(employee => (
                            <div className="we-item-delete" key={employee}>
                                <p>{this.getEmployeeName(employee)}</p>
                                <IconButton onClick={() => this.onEmployeeSelect({id: employee})}>
                                    <Delete/>
                                </IconButton>
                            </div>
                        ))}
                    </div>
                    <div className="we-column-container we-3 we-brd">

                        <div style={{
                            width: '80%',
                            display: 'flex',
                            borderBottomColor: '#1770B2',
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            marginBottom: 10
                        }}>
                            <p style={{width: '30%'}}>{t("Total hours")}</p>
                            <p style={{width: '30%', textAlign: 'center'}}></p>
                            <p style={{width: '30%', textAlign: 'center'}}>{t("Delete")}</p>
                            <p style={{width: '10%', textAlign: 'center'}}></p>
                        </div>
                        <div className="we-billable-hours">
                            <p style={{paddingTop: 8}} className="billable-end">{this.props.t("Billable hours")}</p>
                            <div className="billable-start">
                                <TimeButtonComponent
                                    value={worklog.billing.billableHours}
                                    onChange={this.onBillableTimeChange}
                                    field="billableHours"
                                />
                            </div>
                            <div className="billable-end">
                                <p>{""}</p>

                            </div>
                        </div>


                        {worklog.billing && worklog.billing.billableHoursByTask && worklog.billing.billableHoursByTask.map((task, index) => (
                            <div key={index} className="we-billable-hours">
                                <p style={{paddingTop: 8}} className="billable-end">{task.name}</p>
                                <div className="billable-start">
                                    <TimeButtonComponent
                                        value={task.duration}
                                        onChange={this.onBillableTimeChange}
                                        field="billableHoursByTask"
                                        index={index}

                                    />
                                </div>
                                <div className="billable-end">
                                    <IconButton style={{height: 15, width: 15, padding: 5, margin: 0}}
                                                disableRipple={true} onClick={() => this.onTaskDelete(index)}>
                                        <Delete/>
                                    </IconButton>
                                </div>
                            </div>
                        ))}


                        <div className="we-billable-hours">
                            <p style={{paddingTop: 8}} className="billable-end">{this.props.t("Billable per task")}</p>
                            <div className="billable-start">

                                <Button
                                    variant="contained"
                                    size={"small"}
                                    style={{width: 155, backgroundColor: '#1770B2', color: '#FFF', height: 30}}
                                    onClick={() => this.setState({taskDialog: true})}
                                >
                                    {this.props.t("ADD")}
                                </Button>
                            </div>
                            <p className="billable-end">{""}</p>
                        </div>


                        {worklog.pauses &&
                        <div className="we-column-container">
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                borderBottomColor: '#1770B2',
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid",
                                marginBottom: 10,
                                marginTop: 20,

                            }}>
                                <p style={{width: '50%', textAlign: 'center'}}>{t("Pauses")}</p>
                                <p style={{width: '25%', textAlign: 'right'}}>{t("Payable")}</p>
                                <p style={{width: '25%', textAlign: 'right'}}>{t("Delete")}</p>
                            </div>
                            {worklog.pauses.map((pause, index) => (
                                <div key={index} className="we-billable-hours">
                                    <p style={{paddingTop: 8}} className="billable-end">Tauko</p>
                                    <div className="billable-start">
                                        <div>
                                            {this.secondsToHms(pause.duration / 1000)}
                                        </div>
                                    </div>
                                    <Checkbox
                                        checked={pause.isPayable}
                                        onChange={() => this.checkboxHandler(!pause.isPayable, "pauses", index)}
                                        style={{
                                            color: '#1770B2'
                                        }}
                                    />
                                    <div className="billable-end">
                                        <IconButton style={{height: 15, width: 15, padding: 5, margin: 0}}
                                                    disableRipple={true} onClick={() => this.onPauseDelete(index)}>
                                            <Delete/>
                                        </IconButton>
                                    </div>
                                </div>

                            ))}
                        </div>
                        }

                        {worklog &&
                        <div className="we-column-container">
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                borderBottomColor: '#1770B2',
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid",
                                marginBottom: 10,
                                marginTop: 20,

                            }}/>

                            <div className="we-billable-hours">
                                <TextField
                                    variant="outlined"
                                    value={worklog.additionalDescription || ""}
                                    InputProps={{style: {height: 40}}}
                                    label="Lisäkommentti"
                                    onChange={this.onAdditionalDescriptionChange}
                                    style={{width: 315}}

                                />
                            </div>
                        </div>
                        }
                    </div>

                    <div className="we-column-container we-3">

                        <div style={{
                            width: '80%',
                            display: 'flex',
                            borderBottomColor: '#1770B2',
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            marginBottom: 10
                        }}>
                            <p style={{width: '60%', textAlign: 'center'}}>Kuitattavat tehtävät</p>
                            <p style={{width: '40%', textAlign: 'left'}}>Kuitattu</p>

                        </div>

                        <div className="we-task-todo-column-container">
                            <p>Tehtävät</p>
                            {job && job.tasks &&
                            job.tasks.map((task, index) => (
                                task.todo ? (task.todo.map((taskTodo, index) => (
                                    <div className="we-task-todo-column" key={index}>
                                        <p style={{width: '70%', textAlign: 'left', paddingLeft: 10}}>{taskTodo}</p>
                                        <Checkbox
                                            checked={(worklog.normalTodo && worklog.normalTodo.find(doneTask => doneTask === taskTodo)) ? true : false}   //check box if there is done task in worklog
                                            onChange={() => this.todoHandler(taskTodo, "normalTodo")} // toggle its value
                                            style={{
                                                color: '#1770B2'
                                            }}
                                        />
                                    </div>))) : null))
                            }
                        </div>

                        <div className="we-task-todo-column-container">
                            <p>Lisätehtävät</p>
                            {job && job.extraTodo &&
                            job.extraTodo.map((extraTodo, index) => (
                                <div className="we-task-todo-column" key={index}>
                                    <p style={{width: '70%', textAlign: 'left', paddingLeft: 10}}>{extraTodo}</p>
                                    <Checkbox
                                        checked={(worklog.extraTodo && worklog.extraTodo.find(doneTask => doneTask === extraTodo)) ? true : false}   //check box if there is done task in worklog
                                        onChange={() => this.todoHandler(extraTodo, "extraTodo")} // toggle its value
                                        style={{
                                            color: '#1770B2'
                                        }}
                                    />
                                </div>))
                            }
                        </div>
                    </div>


                </div>

                <div className="we-titlebar">
                    <h3>Materiaalit & kuvat</h3>
                </div>

                <div className="we-row-container we-h3">

                    <div className="we-column-container we-3 we-brd">
                        <div style={{
                            width: '80%',
                            display: 'flex',
                            borderBottomColor: '#1770B2',
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            marginBottom: 10
                        }}>
                            <p style={{width: '50%', textAlign: 'center'}}>{t("Materials")}</p>
                            <p style={{width: '20%', textAlign: 'center'}}>{t("Amount")}</p>
                            <p style={{width: '20%', textAlign: 'left'}}>{t("Delete")}</p>
                            <p style={{width: '10%'}}></p>
                        </div>
                        {worklog.materials && worklog.materials.map((material, index) => (
                            <div className="we-item-delete" key={material.name}>
                                <p>{material.name}</p>

                                <p>{material.unit}</p>

                                <TextField
                                    value={worklog.materials[index].amount}
                                    onChange={event => this.onMaterialAmountChange(event, index)}
                                    style={{
                                        width: '20%',
                                        marginTop: 8
                                    }}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    inputProps={{
                                        style: {
                                            textAlign: 'center',
                                        }
                                    }}
                                />

                                <IconButton onClick={() => this.onMaterialDelete(index)}>
                                    <Delete/>
                                </IconButton>
                            </div>

                        ))}

                        <div className="we-column-container we-2">
                            <Button
                                variant="contained"
                                size={"small"}
                                style={{width: 155, backgroundColor: '#1770B2', color: '#FFF', height: 30}}
                                onClick={() => this.setState({taskDialog: true})}
                            >
                                {this.props.t("ADD")}
                            </Button>
                        </div>

                    </div>

                    <div className="we-column-container we-3 we-brd">

                        <div style={{
                            width: '80%',
                            display: 'flex',
                            borderBottomColor: '#1770B2',
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            marginBottom: 10
                        }}>
                            <p style={{width: '30%', textAlign: 'center'}}>{t("Images")}</p>
                            <p style={{width: '30%', textAlign: 'center'}}>{t("Category")}</p>
                            <p style={{width: '30%', textAlign: 'center'}}>{t("Description")}</p>
                            <p style={{width: '10%'}}>{""}</p>
                        </div>
                        {worklog.images && worklog.images.map((image, index) => (
                            <div key={image.description + index} style={{width: '80%', display: 'flex'}}>
                                <div style={{width: '30%'}}
                                     onClick={() => this.setState({
                                         imageDialog: true,
                                         dummyUrl: image.image_url
                                     })}>
                                    <img src={image.image_url} style={{width: 50, height: 50}}
                                         alt={image.description}/>
                                </div>
                                <p style={{width: '30%', textAlign: 'center'}}>{image.category}</p>
                                <p style={{width: '30%', textAlign: 'center'}}>{image.description}</p>
                                <IconButton style={{height: 40, width: 40, marginTop: 2}}
                                            onClick={() => this.deleteImage(index)}>
                                    <Delete style={{fontSize: 20}}/>
                                </IconButton>
                            </div>
                        ))}
                    </div>


                    <div className="we-column-container we-3">

                        <div style={{
                            width: '80%',
                            display: 'flex',
                            borderBottomColor: '#1770B2',
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            marginBottom: 10
                        }}>

                            <p style={{width: '30%', textAlign: 'left'}}>{t("Additional information")}</p>
                            <p style={{width: '30%', textAlign: 'left'}}></p>
                        </div>

                        <div className="we-additional-info">
                            <p style={{width: '30%', textAlign: 'left'}}>Last modification time</p>
                            <p style={{
                                width: '30%',
                                textAlign: 'left'
                            }}>{worklog.modified && worklog.modified.time ? moment(worklog.modified.time).locale("fi").format('lll') : '-'}</p>
                        </div>

                        <div className="we-additional-info">
                            <p style={{width: '30%', textAlign: 'left'}}>Modified by</p>
                            <p style={{
                                width: '30%',
                                textAlign: 'left'
                            }}>{worklog.modified && worklog.modified.user ? this.getEmployeeName(worklog.modified.user) : '-'}</p>
                        </div>

                        <div className="we-additional-info">
                            <p style={{width: '30%', textAlign: 'left'}}>OS</p>
                            <p style={{width: '30%', textAlign: 'left'}}>{worklog.OS}</p>
                        </div>

                        <div className="we-additional-info">
                            <p style={{width: '30%', textAlign: 'left'}}>Version</p>
                            <p style={{width: '30%', textAlign: 'left'}}>{worklog.AppVersion}</p>
                        </div>

                    </div>

                </div>

                {!loading && worklog &&
                <Grid item xs={12} style={{marginTop: 24}}>
                    <Paper style={{height: 400, width: 400}}>
                        <MapComponent
                            type={"WorklogLocations"}
                            employees={[{
                                id: "start",
                                lat: worklog.startCoordinates && worklog.startCoordinates.latitude ? worklog.startCoordinates.latitude : 0,
                                lng: worklog.startCoordinates && worklog.startCoordinates.longitude ? worklog.startCoordinates.longitude : 0,
                            }, {
                                id: "end",
                                lat: worklog.endCoordinates && worklog.endCoordinates.latitude ? worklog.endCoordinates.latitude : 0,
                                lng: worklog.endCoordinates && worklog.endCoordinates.longitude ? worklog.endCoordinates.longitude : 0,
                            }]}
                            loadingElement={<div style={{height: `100%`}}/>}
                            containerElement={<div style={{height: '100%'}}/>}
                            mapElement={<div style={{height: '100%'}}/>}
                        />
                    </Paper>
                </Grid>
                }

                <div className="we-button-row">
                    {worklog.id &&
                    <Button
                        variant="contained"
                        style={{backgroundColor: '#b22717', color: '#FFF', marginRight: 10}}
                        onClick={() => this.setState({confirmationDialog: true})}
                    >
                        {this.props.t("Delete")}
                    </Button>
                    }

                    <Button
                        variant="contained"
                        style={{backgroundColor: '#1770B2', color: '#FFF', marginRight: 10}}
                        onClick={() => this.props.history.goBack()}
                    >
                        {this.props.t("Cancel")}
                    </Button>

                    {unsavedChanges &&
                    <Button
                        variant="contained"
                        style={{backgroundColor: '#1770B2', color: '#FFF'}}
                        onClick={this.onWorklogSave}
                    >
                        {saving &&
                        <div style={{
                            alignSelf: 'right',
                            width: 'auto',
                            height: 'auto',
                        }}>
                            <CircularProgress/>
                        </div>
                        }
                        {this.props.t("Save")}
                    </Button>
                    }
                </div>


                {this.state.datePicker &&
                <DateDialog
                    open={this.state.datePicker}
                    selectType={"single"}
                    value={this.state.DTValue}
                    onClose={() => this.setState({datePicker: false})}
                    onChange={event => this.onDateChange(event, this.state.pickField)}
                />
                }

                {this.state.timePicker &&
                <TimeDialog
                    onChange={event => this.onTimeChange(event, this.state.pickField)}
                    onClose={() => this.setState({timePicker: false})}
                    value={this.state.DTValue}
                />
                }
                {this.state.taskDialog &&
                <TaskDialog
                    open={this.state.taskDialog}
                    onClose={() => this.setState({taskDialog: false})}
                    selectedLog={worklog}
                    models={this.props.models.models}
                    customers={customers}
                    addTasks={this.onWorklogUpdate}
                />
                }

                {this.state.imageDialog &&
                <ImageDialog
                    open={this.state.imageDialog}
                    onClose={() => this.setState({imageDialog: false})}
                    image={this.state.dummyUrl}
                />
                }

                {this.state.confirmationDialog &&
                <GlobalConfirmationDialog
                    open={this.state.confirmationDialog}
                    onClose={() => this.setState({confirmationDialog: false})}
                    onConfirm={() => this.setState({confirmationDialog: false}, () => worklog.id ? this.onWorklogDelete() : this.onWorklogDiscard())}
                    title={this.props.t("Attention")}
                    message={worklog.id ? `${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DeleteActionWorklog")}` : `${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DiscardActionJob")}?`}
                />
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    customers: state.customers,
    employees: state.employees,
    worksites: state.worksites,
    models: state.models,
});

export default connect(mapStateToProps, {setCustomers, setEmployees, setModels})(withTranslation()(WorklogEditor));
