import {SAVE_SEND, RESET_SEND} from "../actions/types";

const initialState = {
    customer: {},
    worklogs: [],
    company: {},
    pdf: ""
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE_SEND: {
            return {
                customer: action.customer,
                worklogs: action.worklogs,
                company: action.company,
                pdf: action.pdf
            }
        }
        case RESET_SEND:
            return {
                customer: {},
                worklogs: [],
                company: {},
                pdf: ""
            };
        default:
            return state;
    }
}