import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    MenuItem
} from '@material-ui/core';

import moment from 'moment-timezone';

import {withTranslation} from "react-i18next";

class CancelDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            worklog: {}
        };
    }

    componentWillMount() {
        this.setState({
            dialogOpen: true
        });
    }

    handleChange = (event, field) => {
        const {worklog} = this.state;

        field === "billingPercent" ? worklog[field] = parseInt(event.target.value) : worklog[field] = event.target.value;

        this.setState({
            worklog: worklog
        });
    };

    handleSave = () => {
        let {worklog} = this.state;
        let {selectedJob} = this.props;
        const {user} = this.props.user;

        let employeeArr = [];
        if (selectedJob.employees) {
            selectedJob.employees.forEach(employee => {
                employeeArr.push(employee.id)
            });
        }

        worklog.employee = employeeArr;
        worklog.jobID = selectedJob.id;
        worklog.TZ = selectedJob.TZ;
        worklog.startDT = moment(selectedJob.startDT).format('YYYYMMDDTHHmmss');
        worklog.endDT = moment(selectedJob.endDT).format('YYYYMMDDTHHmmss');
        worklog.title = selectedJob.title;
        worklog.customer = selectedJob.customer;
        worklog.jobStartDT = moment(selectedJob.startDT).format('YYYYMMDDTHHmmss');
        worklog.status = "Cancelled";
        worklog.billing = {
            billableHours: moment(selectedJob.endDT) - moment(selectedJob.startDT)
        };

        firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').add(worklog);

        this.props.onClose();
    };

    render() {
        const {worklog} = this.state;
        const {t} = this.props;
        return (
            <div>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.props.onClose()}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>{t("Cancel job")}</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            label={t("Reason")}
                            value={worklog.reason || ""}
                            onChange={event => this.handleChange(event, 'reason')}
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                        />

                        <TextField
                            variant="outlined"
                            label={t("Billing amount")}
                            value={worklog.billingPercent || ""}
                            onChange={event => this.handleChange(event, 'billingPercent')}
                            select
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                        >
                            <MenuItem key={100} value={1}>100%</MenuItem>
                            <MenuItem key={75} value={0.75}>75%</MenuItem>
                            <MenuItem key={50} value={0.50}>50%</MenuItem>
                            <MenuItem key={0} value={0}>{t("No billing")}</MenuItem>
                        </TextField>

                        <TextField
                            variant="outlined"
                            label={t("Invoice text")}
                            value={worklog.invoiceText || ""}
                            onChange={event => this.handleChange(event, 'invoiceText')}
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                        />

                        <TextField
                            variant="outlined"
                            label={t("Own notes")}
                            value={worklog.notes || ""}
                            onChange={event => this.handleChange(event, 'notes')}
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => this.props.onClose()}
                        >
                            {t("Close")}
                        </Button>

                        <Button
                            variant="outlined"
                            onClick={() => this.handleSave()}
                            style={{
                                marginRight: 20
                            }}
                        >
                            {t("Save")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(CancelDialog));