import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button
} from '@material-ui/core';
const CompleteDialog = props => {


    return(
        <Dialog
            open={props.dialogOpen}
            onClose={() => props.onClose()}
            maxWidth={"sm"}
        >
            <DialogTitle style={{marginLeft: 20}}>Register complete!</DialogTitle>
            <DialogContent>
                You can now proceed to log in
                <br/>
                <Button
                    onClick={() => props.onClose()}
                    variant="outlined"
                    color="primary"
                    style={{marginTop: 20, marginLeft: 60}}
                >
                    Proceed
                </Button>
            </DialogContent>
        </Dialog>
    );
};

export default CompleteDialog;