import React, {Component} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    ListItem,
    ListItemText
} from '@material-ui/core';

import {withTranslation} from "react-i18next";


class WorksiteDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            worksites: []
        }
    }

    componentDidMount() {
        const {selectedCustomer, customers, worksites} = this.props;

        const customerSites = [];

        if (selectedCustomer && selectedCustomer.length > 0) {
            const customer = customers.find(item => item.name === selectedCustomer);

            worksites && worksites.forEach(site => {
                if (site.customer === customer.id) {
                    customerSites.push(site);
                }
            });
            console.log(customerSites)
            this.setState({
                worksites: customerSites,
                dialogOpen: true
            });
        }
    }

    render() {
        return(
            <div>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.props.onClose()}
                >
                    <DialogTitle>{this.props.t("Select worksite")}</DialogTitle>
                    <DialogContent>
                        {this.state.worksites && this.state.worksites.map(site => (
                            <ListItem
                                style={{
                                    width: '100%'
                                }}
                                button
                                key={site.id}
                                onClick={() => this.props.onSelect(site, "worksiteSelect")}
                            >
                                <ListItemText
                                    primary={site.name}
                                    style={{
                                        width: '100%'
                                    }}
                                />
                            </ListItem>
                        ))}
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withTranslation()(WorksiteDialog);