import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    CircularProgress
} from '@material-ui/core';

import CircularLoader from "../util/CircularLoader";

import {withTranslation} from "react-i18next";

const ConfirmDialog = props => {
    const {title, message, status, loading, saveComplete, t} = props;

    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
        >
            {!loading && <DialogTitle style={{textAlign: 'center'}}>{title}</DialogTitle>}
            <DialogContent>
                {!loading && <div style={{width: '100%', textAlign: 'center'}}>
                    <p>{message}</p>
                    <p style={{marginTop: 15, fontSize: 16, fontWeight: 'bold'}}>{status}</p>
                </div>}

                 <CircularLoader loading={loading && !saveComplete}/>
                {loading && saveComplete && <p style={{fontSize: 16}}>{`Valmis`}</p>}

            </DialogContent>
            <DialogActions style={{justifyContent: "space-between", marginTop: 15}}>
                {!loading &&<Button
                    onClick={props.onClose}
                    variant="contained"
                    style={{width: '49%'}}
                >
                    {t("Cancel")}
                </Button>}

                {!loading && <Button
                    variant="contained"
                    onClick={props.onConfirm}
                    style={{backgroundColor: '#1770B2', color: '#FFF', width: '49%'}}
                >
                    {t("Confirm")}
                </Button>}

                {loading && saveComplete &&
                    <Button
                        variant="contained"
                        style={{width: '100%'}}
                        onClick={props.onClose}
                    >
                        {t("Close")}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default withTranslation()(ConfirmDialog);
