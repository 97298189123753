import React, { Component } from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";

import { Button, FormControlLabel, Checkbox } from '@material-ui/core';
import moment from "moment";
import "moment/locale/fi";

import { withTranslation } from "react-i18next";
import { createReport, uploadToFirebaseStorage } from "../reportStoreFunctions";
import ReportTypes from "../ReportTypes";
import ReportSettings from "../ReportSettings";
import { isEmpty } from "../../validations/isEmpty";
import { connect } from "react-redux";

class PDFFixedPeriodWorktime extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loopCounter: 0,
            docRef: {}
        }
    }


    secondsToHms = (d) => {
        d = Number(d);
        if (d === 0) {
            return "0h"
        }
        let h = Math.floor(d / 3600);
        let m = Math.floor(d % 3600 / 60);
        let s = Math.floor(d % 3600 % 60);

        let hDisplay = h > 0 ? h + (h === 1 ? "h " : "h ") : "";
        let mDisplay = m > 0 ? m + (m === 1 ? "min " : "min ") : "";

        return hDisplay + mDisplay;
    };

    findCustomerName = (id) => {
        let customerLabel = null;
        this.props.customers.map(customer => {
            if (customer.id === id) {
                customerLabel = customer.name
            }
        });
        return customerLabel;
    };

    changePage = (week) => {

        return true; // just page per week
        /*
        if (week.Monday.length > 5) {
            return true
        }
        else if (week.Tuesday.length > 5) {
            return true
        }
        else if (week.Wednesday.length > 5) {
            return true
        }
        else if (week.Thursday.length > 5) {
            return true
        }
        else if (week.Friday.length > 5) {
            return true
        }
        else if (week.Saturday.length > 5) {
            return true
        }
        else if (week.Sunday.length > 5) {
            return true
        }
        else {
            return false
        }*/
    };

    getWorklogDuration = (worklog) => {
        let pauseDuration = 0;

        //deduct pause times from worktime
        if (worklog.pauses) {
            worklog.pauses.forEach(pause => {
                pauseDuration += pause.duration;
            })
            console.log("got total pause time of ", pauseDuration / 1000 + " for worklog " + worklog.id);
        }
        return (worklog.duration ? worklog.duration : 0) - pauseDuration;
    }

    createPdf = (data) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        let { docRef } = this.state;
        const { user } = this.props.user;

        const ref = {};

        const refDailyWorkTimes = {};

        const weekNumbers = [];

        let periodTime = 0;
        let periodOverTime = 0;
        let periodEveningTime = 0;


        console.log("docRef pagesize ", docRef.pageSize);


        // Sort data so every week has its own object, that holds jobs per weekday
        data && data.forEach(worklog => {
            //cancelled jobs are not included in worktime, even if they are paid
            if (worklog.status !== "Cancelled" && worklog.previousStatus !== "Cancelled") {

                const itemWeek = moment(worklog.startDT).format('w');
                const itemDay = moment(worklog.startDT).format('dddd');
                if (ref[itemWeek]) {
                    const day = [this.findCustomerName(worklog.customer), moment(worklog.startDT).format('LT') + ' - ' + moment(worklog.endDT).format('LT') + (worklog.generated ? " (Generated)" : "")];
                    ref[itemWeek][itemDay].push(day);

                    //if no workday start has not been initilised, start workday by first worklog. Also, update dayend time after every worklog
                    if (!refDailyWorkTimes[itemWeek][itemDay].dayStart && worklog.startDT) {
                        refDailyWorkTimes[itemWeek][itemDay] = {
                            dayStart: worklog.startDT,
                            dayEnd: worklog.endDT,
                            dayLength: '',
                            dayPauses: '',
                            eveningTime: 0,
                            overTime: 0,
                            totalWorkTime: 0
                        }
                    } else {
                        console.log("worklog has no start for : ", worklog);
                    }

                    //fix if found earlier start time
                    if (refDailyWorkTimes[itemWeek][itemDay].dayStart && moment(refDailyWorkTimes[itemWeek][itemDay].dayStart) > moment(worklog.startDT)) {
                        refDailyWorkTimes[itemWeek][itemDay].dayStart = worklog.startDT;
                    }

                    //fix if found later end time
                    if (refDailyWorkTimes[itemWeek][itemDay].dayEnd && moment(refDailyWorkTimes[itemWeek][itemDay].dayEnd) < moment(worklog.endDT)) {
                        refDailyWorkTimes[itemWeek][itemDay].dayEnd = worklog.endDT;
                    }

                    if (worklog.eveningDuration) {
                        ref[itemWeek].weekEvenings += worklog.eveningDuration; //TODO RETHINK - CHECK!
                        refDailyWorkTimes[itemWeek][itemDay].eveningTime += worklog.eveningDuration;
                    }

                    if (worklog.duration) {
                        let workDuration = this.getWorklogDuration(worklog);

                        ref[itemWeek].weekDuration += workDuration;
                        refDailyWorkTimes[itemWeek][itemDay].totalWorkTime += workDuration;

                        if (refDailyWorkTimes[itemWeek][itemDay].totalWorkTime > (7.5 * 3600 * 1000)) {
                            const overTime = refDailyWorkTimes[itemWeek][itemDay].totalWorkTime - (7.5 * 3600 * 1000);
                            refDailyWorkTimes[itemWeek][itemDay].overTime = overTime;
                            console.log("itemWeek][itemDay] has daily overtime : ", overTime)
                        }
                    } else {
                        console.log("worklog has no duration: ", worklog);

                    }
                }

                else { //first time every week create new array
                    ref[itemWeek] = {
                        Monday: [],
                        Tuesday: [],
                        Wednesday: [],
                        Thursday: [],
                        Friday: [],
                        Saturday: [],
                        Sunday: [],
                        weekDuration: 0,
                        weekEvenings: 0,
                    };
                    weekNumbers.push(itemWeek);

                    refDailyWorkTimes[itemWeek] = {
                        Monday: {
                            dayStart: null,
                            dayEnd: null,
                            dayLength: '',
                            dayPauses: '',
                            eveningTime: 0,
                            overTime: 0,
                            totalWorkTime: 0
                        },
                        Tuesday: {
                            dayStart: null,
                            dayEnd: null,
                            dayLength: '',
                            dayPauses: '',
                            eveningTime: 0,
                            overTime: 0,
                            totalWorkTime: 0
                        },
                        Wednesday: {
                            dayStart: null,
                            dayEnd: null,
                            dayLength: '',
                            dayPauses: '',
                            eveningTime: 0,
                            overTime: 0,
                            totalWorkTime: 0
                        },
                        Thursday: {
                            dayStart: null,
                            dayEnd: null,
                            dayLength: '',
                            dayPauses: '',
                            eveningTime: 0,
                            overTime: 0,
                            totalWorkTime: 0
                        },
                        Friday: {
                            dayStart: null,
                            dayEnd: null,
                            dayLength: '',
                            dayPauses: '',
                            eveningTime: 0,
                            overTime: 0,
                            totalWorkTime: 0
                        },
                        Saturday: {
                            dayStart: null,
                            dayEnd: null,
                            dayLength: '',
                            dayPauses: '',
                            eveningTime: 0,
                            overTime: 0,
                            totalWorkTime: 0
                        },
                        Sunday: {
                            dayStart: null,
                            dayEnd: null,
                            dayLength: '',
                            dayPauses: '',
                            eveningTime: 0,
                            overTime: 0,
                            totalWorkTime: 0
                        },
                    };

                    const day = [this.findCustomerName(worklog.customer), moment(worklog.startDT).format('LT') + ' - ' + moment(worklog.endDT).format('LT')];
                    ref[itemWeek][itemDay].push(day)

                    if (worklog.duration) {

                        let workDuration = this.getWorklogDuration(worklog);

                        ref[itemWeek].weekDuration += workDuration;
                        refDailyWorkTimes[itemWeek][itemDay].totalWorkTime += workDuration;

                        if (refDailyWorkTimes[itemWeek][itemDay].totalWorkTime > (7.5 * 3600 * 1000)) {
                            const overTime = refDailyWorkTimes[itemWeek][itemDay].totalWorkTime - (7.5 * 3600 * 1000);
                            refDailyWorkTimes[itemWeek][itemDay].overTime = overTime;
                            console.log("itemWeek][itemDay] has daily overtime : ", overTime)
                        }
                    } else {
                        console.log("worklog has no duration: ", worklog)
                    }

                    if (worklog.eveningDuration) {
                        ref[itemWeek].weekEvenings += worklog.eveningDuration
                        refDailyWorkTimes[itemWeek][itemDay].eveningTime += worklog.eveningDuration
                    }

                    //TODO: init here if we have workdaystart in other table - use settings, workDayStartsAtOffice or -FirstClient
                    // for now, just take first day start time as day start
                    if (worklog.startDT) {
                        refDailyWorkTimes[itemWeek][itemDay] = {
                            dayStart: worklog.startDT,
                            dayEnd: worklog.endDT,
                            dayLength: '',
                            dayPauses: '',
                            eveningTime: 0,
                            overTime: 0,
                            totalWorkTime: 0
                        }
                    } else {
                        console.log("worklog has no start for : ", worklog)
                    }

                }
            }
        });

        // Form pdf
        const { t } = this.props;

        weekNumbers.forEach(week => {

            let pageChange = "";
            const weeklength = this.changePage(ref[week]);

            if (week === weekNumbers[0] || weeklength) {
                pageChange = "before";
            }

            // Add function that handles checking if page needs to be changed
            // if week is the first in array for front page and stuff

            // Form "header"
            const weekHeader = {
                pageBreak: pageChange,
                margin: [0, 10, 0, 0],
                table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: [
                        [{ text: t("week") + ": " + week }]
                    ]
                }
            };


            // Count summary here, if function call is inside pdf obj, it might not get the value
            const weekTimeStr = this.secondsToHms(ref[week].weekDuration / 1000);
            const weekEvening = this.secondsToHms(ref[week].weekEvenings / 1000);

            periodTime += ref[week].weekDuration;
            periodEveningTime += ref[week].weekEvenings;

            let overTimeStr = "0h";
            let overTime = 0;
            let underTime = 0;

            if (ref[week].weekDuration > (37.5 * 3600000)) {
                overTime = (Math.abs((37.5 * 3600000) - ref[week].weekDuration));
                periodOverTime += overTime;
                overTimeStr = this.secondsToHms(overTime / 1000);
            } else if (ref[week].weekDuration < (37.5 * 3600000)) {
                underTime = (Math.abs(ref[week].weekDuration - (37.5 * 3600000)));
                console.log("week undertime = ", underTime);
                periodOverTime -= underTime;
                overTimeStr = '-' + this.secondsToHms(underTime / 1000);
            }

            // Then form table that holds the data
            const weekBody = {
                fontSize: 10,
                table: {
                    headerRows: 1,
                    widths: [100, 100, 100, 100, 100, 100, 60, 'auto'],
                    body: [
                        [t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday'), t('Sunday'), t('Summary')],
                        [
                            {
                                stack: [
                                    {
                                        ol: ref[week].Monday
                                    }
                                ]
                            },
                            {
                                stack: [
                                    {
                                        ol: ref[week].Tuesday
                                    }
                                ]
                            },
                            {
                                stack: [
                                    {
                                        ol: ref[week].Wednesday
                                    }
                                ]
                            },
                            {
                                stack: [
                                    {
                                        ol: ref[week].Thursday
                                    }
                                ]
                            },
                            {
                                stack: [
                                    {
                                        ol: ref[week].Friday
                                    }
                                ]
                            },
                            {
                                stack: [
                                    {
                                        ol: ref[week].Saturday
                                    }
                                ]
                            },
                            {
                                stack: [
                                    {
                                        ol: ref[week].Sunday
                                    }
                                ]
                            },
                            {
                                stack: [
                                    {
                                        ul: [
                                            { text: t("Evening") + ": \n" + weekEvening },
                                            { text: t("Overtime") + ": \n" + overTimeStr },
                                            { text: t("Total") + ": \n" + weekTimeStr }
                                        ]
                                    }
                                ]
                            }
                        ]

                    ]
                }
            };

            //sum up daily totals to avoid mess in PDF fields
            if (refDailyWorkTimes[week].Monday.dayStart && refDailyWorkTimes[week].Monday.dayEnd) {
                refDailyWorkTimes[week].Monday.dayLength = this.secondsToHms((moment(refDailyWorkTimes[week].Monday.dayEnd) - moment(refDailyWorkTimes[week].Monday.dayStart)) / 1000);
                refDailyWorkTimes[week].Monday.dayPauses = this.secondsToHms(((moment(refDailyWorkTimes[week].Monday.dayEnd) - moment(refDailyWorkTimes[week].Monday.dayStart)) - refDailyWorkTimes[week].Monday.totalWorkTime) / 1000);
            }

            if (refDailyWorkTimes[week].Tuesday.dayStart && refDailyWorkTimes[week].Tuesday.dayEnd) {
                refDailyWorkTimes[week].Tuesday.dayLength = this.secondsToHms((moment(refDailyWorkTimes[week].Tuesday.dayEnd) - moment(refDailyWorkTimes[week].Tuesday.dayStart)) / 1000);
                refDailyWorkTimes[week].Tuesday.dayPauses = this.secondsToHms(((moment(refDailyWorkTimes[week].Tuesday.dayEnd) - moment(refDailyWorkTimes[week].Tuesday.dayStart)) - refDailyWorkTimes[week].Tuesday.totalWorkTime) / 1000);
            }

            if (refDailyWorkTimes[week].Wednesday.dayStart && refDailyWorkTimes[week].Wednesday.dayEnd) {
                refDailyWorkTimes[week].Wednesday.dayLength = this.secondsToHms((moment(refDailyWorkTimes[week].Wednesday.dayEnd) - moment(refDailyWorkTimes[week].Wednesday.dayStart)) / 1000);
                refDailyWorkTimes[week].Wednesday.dayPauses = this.secondsToHms(((moment(refDailyWorkTimes[week].Wednesday.dayEnd) - moment(refDailyWorkTimes[week].Wednesday.dayStart)) - refDailyWorkTimes[week].Wednesday.totalWorkTime) / 1000);
            }

            if (refDailyWorkTimes[week].Thursday.dayStart && refDailyWorkTimes[week].Thursday.dayEnd) {
                refDailyWorkTimes[week].Thursday.dayLength = this.secondsToHms((moment(refDailyWorkTimes[week].Thursday.dayEnd) - moment(refDailyWorkTimes[week].Thursday.dayStart)) / 1000);
                refDailyWorkTimes[week].Thursday.dayPauses = this.secondsToHms(((moment(refDailyWorkTimes[week].Thursday.dayEnd) - moment(refDailyWorkTimes[week].Thursday.dayStart)) - refDailyWorkTimes[week].Thursday.totalWorkTime) / 1000);
            }

            if (refDailyWorkTimes[week].Friday.dayStart && refDailyWorkTimes[week].Friday.dayEnd) {
                refDailyWorkTimes[week].Friday.dayLength = this.secondsToHms((moment(refDailyWorkTimes[week].Friday.dayEnd) - moment(refDailyWorkTimes[week].Friday.dayStart)) / 1000);
                refDailyWorkTimes[week].Friday.dayPauses = this.secondsToHms(((moment(refDailyWorkTimes[week].Friday.dayEnd) - moment(refDailyWorkTimes[week].Friday.dayStart)) - refDailyWorkTimes[week].Friday.totalWorkTime) / 1000);
            }

            if (refDailyWorkTimes[week].Saturday.dayStart && refDailyWorkTimes[week].Saturday.dayEnd) {
                refDailyWorkTimes[week].Saturday.dayLength = this.secondsToHms((moment(refDailyWorkTimes[week].Saturday.dayEnd) - moment(refDailyWorkTimes[week].Saturday.dayStart)) / 1000);
                refDailyWorkTimes[week].Saturday.dayPauses = this.secondsToHms(((moment(refDailyWorkTimes[week].Saturday.dayEnd) - moment(refDailyWorkTimes[week].Saturday.dayStart)) - refDailyWorkTimes[week].Saturday.totalWorkTime) / 1000);
                console.log("refDailyWorkTimes pauses : ", this.secondsToHms(refDailyWorkTimes[week].Saturday.dayPauses / 1000))
            }

            if (refDailyWorkTimes[week].Sunday.dayStart && refDailyWorkTimes[week].Sunday.dayEnd) {
                refDailyWorkTimes[week].Sunday.dayLength = this.secondsToHms((moment(refDailyWorkTimes[week].Sunday.dayEnd) - moment(refDailyWorkTimes[week].Sunday.dayStart)) / 1000);
                refDailyWorkTimes[week].Sunday.dayPauses = this.secondsToHms(((moment(refDailyWorkTimes[week].Sunday.dayEnd) - moment(refDailyWorkTimes[week].Sunday.dayStart)) - refDailyWorkTimes[week].Sunday.totalWorkTime) / 1000);
            }

            console.log("refDailyWorkTimes[week].Monday", refDailyWorkTimes[week].Monday);
            console.log("refDailyWorkTimes[week].Tuesday", refDailyWorkTimes[week].Tuesday);

            const weekDayTotals = {
                fontSize: 10,
                table: {
                    headerRows: 1,
                    widths: [100, 100, 100, 100, 100, 100, 60],
                    body: [
                        [
                            {
                                stack: [
                                    { text: refDailyWorkTimes[week].Monday.dayStart ? `${t("Day_start")}: ${moment(refDailyWorkTimes[week].Monday.dayStart).format("LT")}` : '' },
                                    { text: refDailyWorkTimes[week].Monday.dayStart ? `${t("Day_end")}: ${moment(refDailyWorkTimes[week].Monday.dayEnd).format("LT")}` : '' },
                                    { text: `${t("Day_duration")}: ${refDailyWorkTimes[week].Monday.dayLength}` },
                                    { text: `${t("Day_pauses")}: ${refDailyWorkTimes[week].Monday.dayPauses}` },
                                    { text: `${t("Day_evening")}: ${this.secondsToHms(refDailyWorkTimes[week].Monday.eveningTime / 1000)}` },
                                    { text: `${t("Day_overtime")}: ${this.secondsToHms(refDailyWorkTimes[week].Monday.overTime / 1000)}` },
                                    { text: `${t("Day_total")}: ${this.secondsToHms(refDailyWorkTimes[week].Monday.totalWorkTime / 1000)}` },
                                ]
                            },
                            {
                                stack: [
                                    { text: refDailyWorkTimes[week].Tuesday.dayStart ? `${t("Day_start")}: ${moment(refDailyWorkTimes[week].Tuesday.dayStart).format("LT")}` : '' },
                                    { text: refDailyWorkTimes[week].Tuesday.dayStart ? `${t("Day_end")}: ${moment(refDailyWorkTimes[week].Tuesday.dayEnd).format("LT")}` : '' },
                                    { text: `${t("Day_duration")}: ${refDailyWorkTimes[week].Tuesday.dayLength}` },
                                    { text: `${t("Day_pauses")}: ${refDailyWorkTimes[week].Tuesday.dayPauses}` },
                                    { text: `${t("Day_evening")}: ${this.secondsToHms(refDailyWorkTimes[week].Tuesday.eveningTime / 1000)}` },
                                    { text: `${t("Day_overtime")}: ${this.secondsToHms(refDailyWorkTimes[week].Tuesday.overTime / 1000)}` },
                                    { text: `${t("Day_total")}: ${this.secondsToHms(refDailyWorkTimes[week].Tuesday.totalWorkTime / 1000)}` },
                                ]
                            },
                            {
                                stack: [
                                    { text: refDailyWorkTimes[week].Wednesday.dayStart ? `${t("Day_start")}: ${moment(refDailyWorkTimes[week].Wednesday.dayStart).format("LT")}` : '' },
                                    { text: refDailyWorkTimes[week].Wednesday.dayStart ? `${t("Day_end")}: ${moment(refDailyWorkTimes[week].Wednesday.dayEnd).format("LT")}` : '' },
                                    { text: `${t("Day_duration")}: ${refDailyWorkTimes[week].Wednesday.dayLength}` },
                                    { text: `${t("Day_pauses")}: ${refDailyWorkTimes[week].Wednesday.dayPauses}` },
                                    { text: `${t("Day_evening")}: ${this.secondsToHms(refDailyWorkTimes[week].Wednesday.eveningTime / 1000)}` },
                                    { text: `${t("Day_overtime")}: ${this.secondsToHms(refDailyWorkTimes[week].Wednesday.overTime / 1000)}` },
                                    { text: `${t("Day_total")}: ${this.secondsToHms(refDailyWorkTimes[week].Wednesday.totalWorkTime / 1000)}` },
                                ]
                            },
                            {
                                stack: [
                                    { text: refDailyWorkTimes[week].Thursday.dayStart ? `${t("Day_start")}: ${moment(refDailyWorkTimes[week].Thursday.dayStart).format("LT")}` : '' },
                                    { text: refDailyWorkTimes[week].Thursday.dayStart ? `${t("Day_end")}: ${moment(refDailyWorkTimes[week].Thursday.dayEnd).format("LT")}` : '' },
                                    { text: `${t("Day_duration")}: ${refDailyWorkTimes[week].Thursday.dayLength}` },
                                    { text: `${t("Day_pauses")}: ${refDailyWorkTimes[week].Thursday.dayPauses}` },
                                    { text: `${t("Day_evening")}: ${this.secondsToHms(refDailyWorkTimes[week].Thursday.eveningTime / 1000)}` },
                                    { text: `${t("Day_overtime")}: ${this.secondsToHms(refDailyWorkTimes[week].Thursday.overTime / 1000)}` },
                                    { text: `${t("Day_total")}: ${this.secondsToHms(refDailyWorkTimes[week].Thursday.totalWorkTime / 1000)}` },
                                ]
                            },
                            {
                                stack: [
                                    { text: refDailyWorkTimes[week].Friday.dayStart ? `${t("Day_start")}: ${moment(refDailyWorkTimes[week].Friday.dayStart).format("LT")}` : '' },
                                    { text: refDailyWorkTimes[week].Friday.dayStart ? `${t("Day_end")}: ${moment(refDailyWorkTimes[week].Friday.dayEnd).format("LT")}` : '' },
                                    { text: `${t("Day_duration")}: ${refDailyWorkTimes[week].Friday.dayLength}` },
                                    { text: `${t("Day_pauses")}: ${refDailyWorkTimes[week].Friday.dayPauses}` },
                                    { text: `${t("Day_evening")}: ${this.secondsToHms(refDailyWorkTimes[week].Friday.eveningTime / 1000)}` },
                                    { text: `${t("Day_overtime")}: ${this.secondsToHms(refDailyWorkTimes[week].Friday.overTime / 1000)}` },
                                    { text: `${t("Day_total")}: ${this.secondsToHms(refDailyWorkTimes[week].Friday.totalWorkTime / 1000)}` },
                                ]
                            },
                            {
                                stack: [
                                    { text: refDailyWorkTimes[week].Saturday.dayStart ? `${t("Day_start")}: ${moment(refDailyWorkTimes[week].Saturday.dayStart).format("LT")}` : '' },
                                    { text: refDailyWorkTimes[week].Saturday.dayStart ? `${t("Day_end")}: ${moment(refDailyWorkTimes[week].Saturday.dayEnd).format("LT")}` : '' },
                                    { text: `${t("Day_duration")}: ${refDailyWorkTimes[week].Saturday.dayLength}` },
                                    { text: `${t("Day_pauses")}: ${refDailyWorkTimes[week].Saturday.dayPauses}` },
                                    { text: `${t("Day_evening")}: ${this.secondsToHms(refDailyWorkTimes[week].Saturday.eveningTime / 1000)}` },
                                    { text: `${t("Day_overtime")}: ${this.secondsToHms(refDailyWorkTimes[week].Saturday.overTime / 1000)}` },
                                    { text: `${t("Day_total")}: ${this.secondsToHms(refDailyWorkTimes[week].Saturday.totalWorkTime / 1000)}` },
                                ]
                            },
                            {
                                stack: [
                                    { text: refDailyWorkTimes[week].Sunday.dayStart ? `${t("Day_start")}: ${moment(refDailyWorkTimes[week].Sunday.dayStart).format("LT")}` : '' },
                                    { text: refDailyWorkTimes[week].Sunday.dayStart ? `${t("Day_end")}: ${moment(refDailyWorkTimes[week].Sunday.dayEnd).format("LT")}` : '' },
                                    { text: `${t("Day_duration")}: ${refDailyWorkTimes[week].Sunday.dayLength}` },
                                    { text: `${t("Day_pauses")}: ${refDailyWorkTimes[week].Sunday.dayPauses}` },
                                    { text: `${t("Day_evening")}: ${this.secondsToHms(refDailyWorkTimes[week].Sunday.eveningTime / 1000)}` },
                                    { text: `${t("Day_overtime")}: ${this.secondsToHms(refDailyWorkTimes[week].Sunday.overTime / 1000)}` },
                                    { text: `${t("Day_total")}: ${this.secondsToHms(refDailyWorkTimes[week].Sunday.totalWorkTime / 1000)}` },
                                ]
                            },


                        ]

                    ]
                }
            };

            console.log("weekDayTotals", weekDayTotals);

            console.log("pdfMake ", pdfMake);

            // Push them to the docRef.content
            docRef.content.push(weekHeader);
            docRef.content.push(weekBody);
            docRef.content.push(weekDayTotals);

        });

        const periodSummary = {
            pageBreak: "before",
            margin: [0, 10, 0, 0],
            table: {
                headerRows: 1,
                widths: [100, 100],
                body: [
                    [{ text: t("Period summary text") }, ''],
                    [{ text: t("Period total") }, { text: this.secondsToHms(periodTime / 1000) }],
                    [{ text: t("Period normaltime") }, { text: this.secondsToHms((periodTime - periodEveningTime - periodOverTime) / 1000) }],
                    [{ text: t("Period evening") }, { text: this.secondsToHms(periodEveningTime / 1000) }],
                    [{ text: t("Period overtime") }, { text: this.secondsToHms(periodOverTime / 1000) }],

                ]
            }
        };

        docRef.content.push(periodSummary);

        if (this.props.openInNewTab) pdfMake.createPdf(docRef).open();

        pdfMake.createPdf(docRef).getBase64((dataUrl) => {

            //const startWeek = Object.keys(searchObj[key]["normalByWeek"])[0];
            //const endWeek = Object.keys(searchObj[key]["normalByWeek"])[Object.keys(searchObj[key]["normalByWeek"]).length - 1];
            const reportOwner = !isEmpty(this.props.employeeLabel) ? this.props.employeeLabel : '';

            const recipients = [employee.email ? employee.email : ''];
            //TODO, other default address
            const fileName = t(ReportTypes.PERIOD_FIXED) + '_' + reportOwner + "_" + t('Weeks') + ' ' + this.state.startWeek + '-' + this.state.endWeek;

            uploadToFirebaseStorage(user, dataUrl, ReportTypes.PERIOD_FIXED).then(filestorageSuccess => {
                console.log('succesfully writed pdf to storage', filestorageSuccess)

                createReport(user, ReportTypes.PERIOD_FIXED, reportOwner, recipients, "email", fileName, dataUrl, filestorageSuccess)
                    .then((report) => {
                        console.log("storeReport success: ", report);
                        this.props.onReportsCreated(true);
                    })
                    .catch((err) => {
                        console.log("storeReport ERROR: ", err);
                        this.props.onReportsCreated(false);
                    })
            });
        });

    };


    generateHeader = (data) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { t } = this.props;
        //Sort to time order, so we get a starting day
        data.sort(function (a, b) {
            if (a.startDT < b.startDT) return -1;
            if (a.startDT > b.startDT) return 1;
        });

        let start = moment().format('L');
        let end = moment().format('L');
        let startWeek = 0;
        let endWeek = 0;

        if (data) {
            start = moment(data[0].startDT).locale("fi").format('L');
            end = moment(data[data.length - 1].startDT).locale("fi").format('L');
            startWeek = moment(data[0].startDT).locale("fi").format('W');
            endWeek = moment(data[data.length - 1].startDT).locale("fi").format('W');
        }


        let docRef = {
            content: [
                {
                    margin: [20, 20, 20, 20],
                    table: {
                        headerRows: 1,
                        widths: ['*'],
                        body: [
                            [
                                {
                                    margin: [10, 10, 10, 10],
                                    table: {
                                        headerRows: 1,
                                        widths: ['*', '*'],
                                        body: [
                                            [this.props.company.name, this.props.employeeLabel],
                                            ["", ""],
                                            [t("Hour_PH"), t("Week") + ' ' + startWeek + ' - ' + t("Week") + endWeek],
                                            ["", ""]
                                        ]
                                    },
                                    layout: 'noBorders'
                                },
                            ]
                        ]
                    }
                },
            ],
            pageMargins: [10, 10, 10, 10],
            pageOrientation: 'landscape',
            pageSize: 'A4',
        };


        this.setState({
            docRef: docRef,
            startWeek: startWeek,
            endWeek: endWeek,

        }, this.createPdf(data));
        /*
        setTimeout(() => {
            this.createPdf(data)
        }, 100);*/

    };

    generateReports = () => {
        //pdfMake.vfs = pdfFonts.pdfMake.vfs;
        //const {t} = this.props;
        //const {docRef} = this.state;
        //const ref = {};
        //const {user} = this.props.user;
        //const body = [];

        console.log("createPdf this.props ", this.props);


        console.log("createPdf data ", this.props.selectedLogs);

        this.props.onReportCreate()


        this.generateHeader(this.props.selectedLogs)  //created new docRef, new doc for each employee

        // this.sleep(500).then(this.createPdf(this.props.selectedLogs)) // one employee report


    }


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    render() {
        return (
            <div>
                <Button
                    variant="outlined" color="secondary"
                    style={{
                        width: 210,
                        marginLeft: 50,
                        marginTop: 10
                    }}
                    onClick={() => this.generateReports()}
                >
                    {this.props.t("Generate_PDF")}
                </Button>
                <FormControlLabel
                    style={{
                        marginLeft: 75,
                        marginTop: 10,
                    }}
                    control={
                        <Checkbox
                            checked={this.props.openInNewTab}
                            onChange={(event) => this.props.onChangeOpenInNewTab(event.target.checked)}
                            value={"checkOpenOInNewTab"}
                            color="primary"
                        />
                    }
                    label={this.props.t("Open in new tab")}
                />
                <div style={{
                    marginLeft: 75,
                    marginTop: 10,
                }}>{this.props.t("Open in new tab tooltip")}</div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(PDFFixedPeriodWorktime));