import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    MenuItem
} from '@material-ui/core';

import moment from 'moment-timezone';

import {withTranslation} from "react-i18next";

class CancelDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            worklog: {
                billingPercent: 1
            }
        };
    }

    componentDidMount() {
        this.setState({
            dialogOpen: true
        });
    }

    handleChange = (event, field) => {
        const {worklog} = this.state;
        console.log(event.target.value);
        worklog[field] = event.target.value;

        this.setState({
            worklog: worklog
        });
    };

    handleSave = () => {
        let {worklog} = this.state;
        let {selectedJob} = this.props;
        const {user} = this.props.user;

        let employeeArr = [];
        if (selectedJob.employees) {
            selectedJob.employees.forEach(employee => {
                employeeArr.push(employee.id)
            });
        }

        worklog.employee = employeeArr;
        worklog.jobID = selectedJob.id;
        worklog.TZ = selectedJob.TZ;
        worklog.startDT = moment(selectedJob.startDT).format('YYYYMMDDTHHmmss');
        worklog.endDT = moment(selectedJob.endDT).format('YYYYMMDDTHHmmss');
        worklog.title = selectedJob.title;
        worklog.customer = selectedJob.customer;
        worklog.jobStartDT = moment(selectedJob.startDT).format('YYYYMMDDTHHmmss');
        worklog.status = "Cancelled";

        const jobDuration = moment(selectedJob.endDT) - moment(selectedJob.startDT);

        //divide cancellation to all assigned tasks, this is needed for billing
        if (worklog.billingPercent > 0) {
            let tasks = [];
            if (selectedJob.tasks) {
                selectedJob.tasks.forEach((task, index) => {
                    const tsk = {
                        duration: jobDuration / selectedJob.tasks.length,
                        name: selectedJob.tasks[index].name,
                        pNumber: selectedJob.tasks[index].pNumber,
                    };
                    tasks.push(tsk);
                })
            }
            worklog.billing = {
                billableHours: jobDuration,
                billableHoursByTask: tasks,
            };

            console.log("formed cancellation billing object: ", worklog.billing);
        }

        if (selectedJob.templateID) worklog.templateID = selectedJob.templateID;

        firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').add(worklog);

        this.props.onClose();
    };

    render() {
        const {worklog} = this.state;
        const {t} = this.props;
        return (
            <div>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.props.onClose()}
                    maxWidth="sm"
                    fullWidth
                >
                    <DialogTitle>{t("Cancel job")}</DialogTitle>
                    <DialogContent>
                        <TextField
                            variant="outlined"
                            label={t("Reason")}
                            value={worklog.reason || ""}
                            onChange={event => this.handleChange(event, 'reason')}
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                        />

                        <TextField
                            variant="outlined"
                            label={t("Billing amount")}
                            value={worklog.billingPercent}
                            onChange={event => this.handleChange(event, 'billingPercent')}
                            select
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                        >
                            <MenuItem value={1}>100%</MenuItem>
                            <MenuItem value={0.75}>75%</MenuItem>
                            <MenuItem value={0.5}>50%</MenuItem>
                            <MenuItem value={0}>{t("No billing")}</MenuItem>
                        </TextField>

                        <TextField
                            variant="outlined"
                            label={t("Invoice text")}
                            value={worklog.invoiceText || ""}
                            onChange={event => this.handleChange(event, 'invoiceText')}
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                        />

                        <TextField
                            variant="outlined"
                            label={t("Own notes")}
                            value={worklog.notes || ""}
                            onChange={event => this.handleChange(event, 'notes')}
                            style={{
                                width: '100%',
                                marginTop: 10
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => this.props.onClose()}
                        >
                            {t("Close")}
                        </Button>

                        <Button
                            variant="outlined"
                            onClick={() => this.handleSave()}
                            style={{
                                marginRight: 20
                            }}
                        >
                            {t("Save")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(CancelDialog));