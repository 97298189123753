import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
//import { firestore, functions } from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
//import 'firebase/functions';

import {
    IconButton,
    ClickAwayListener,
    CircularProgress,
    Button,
    Dialog
} from '@material-ui/core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFilter,
    faLongArrowAltDown,
    faLongArrowAltUp,
    faEdit,
    faEye,
    faPaperPlane,
    faFilePdf,
    faEnvelopeSquare,
    faEnvelopeOpenText,
    faFileSignature,
    faSignature,
    faMailBulk
} from "@fortawesome/free-solid-svg-icons";
import {
    UtilityBar,
    ListAreaHeader,
    ListAreaItem,
    ListAreaBody,
    FilterMenu,
    SelectButton,
    ConfirmDialog,
    PreviewDialog,
    RecipientsDialog,
} from "../ListComponents";

export default function (props) {
    const defaultSortOrder = { field: 'temp', dir: 'desc' };
    const [sortOrder, setSortOrder] = useState(defaultSortOrder);
    const [selection, setSelection] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    const [vehicles, setVehicles] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const vehicles = firebase.firestore().collection('spoton_vehicles').onSnapshot(onSnapshot)
    }, [])

    const onSnapshot = (querySnapshot) => {
        const vehicles = [];
        querySnapshot.docs.forEach(doc => {
            const vehicle = doc.data();
            vehicle.mobilityId = doc.id;
            vehicles.push(vehicle);
        })
        setVehicles(vehicles);
    }

    const arrowDirection = field => {
        if (sortOrder.field === field && sortOrder.dir === 'desc') {
            return (
                <FontAwesomeIcon icon={faLongArrowAltUp} style={{ height: 15, width: 15 }} />
            );
        }
        else return <FontAwesomeIcon icon={faLongArrowAltDown} style={{ height: 15, width: 15 }} />
    };

    const renderListItem = item => {
        console.log(item);
        return (
            <ListAreaBody key={item.mobilityId}>
                <ListAreaItem variant="list" size="small">{item.plateNumber}</ListAreaItem>
                <ListAreaItem variant="list" size="small">{item.make}</ListAreaItem>
                <ListAreaItem variant="list" size="small">{item.model}</ListAreaItem>
            </ListAreaBody>
        )
    }

    const content = classNames({
        "content": true,
        // "disable-scroll": Boolean(filterAnchor) || Boolean(logInfoAnchor)
    });

    return (
        <div className={content}>
            <ListAreaHeader>
                <ListAreaItem
                    variant="title"
                    size="small"
                    icon={
                        <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }}
                            onClick={() => this.sortReports("createdAt", false)}>
                            {arrowDirection("createdAt")}
                        </IconButton>
                    }
                >
                    {t("plate number")}
                </ListAreaItem>

                <ListAreaItem
                    variant="title"
                    size="small"
                >
                    {t("make")}

                </ListAreaItem>

                <ListAreaItem
                    variant="title"
                    size="small"
                >
                    {t("model")}

                </ListAreaItem>

            </ListAreaHeader>

            { vehicles.map(renderListItem)}
        </div>
    )
}