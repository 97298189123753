import React, {Component} from 'react';
import {
    TextField
} from '@material-ui/core';

const CreateCompany = props =>  {

    const {newCompany, errors, t} = props;

    const getErr = field => {
        if (errors.newCompany[field] && errors.newCompany[field].length > 0) {
            return true;
        }
    };

    return (
        <div>

            <TextField
                variant="outlined"
                label={t("Company name")}
                value={newCompany.name || ""}
                onChange={event => props.onChange(event, 'name')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newCompany.name}
                error={getErr("name")}
            />
            <br/>

            <TextField
                variant="outlined"
                label={t("Company address")}
                value={newCompany.streetAddress || ""}
                onChange={event => props.onChange(event, 'streetAddress')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newCompany.streetAddress}
                error={getErr("streetAddress")}
            />
            <br/>

            <TextField
                variant="outlined"
                label={t("Company city")}
                value={newCompany.city || ""}
                onChange={event => props.onChange(event, 'city')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newCompany.city}
                error={getErr("city")}
            />
            <br/>


            <TextField
                variant="outlined"
                label={t("Company postCode")}
                value={newCompany.postCode || ""}
                onChange={event => props.onChange(event, 'postCode')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newCompany.postCode}
                error={getErr("postCode")}
            />
            <br/>

            <TextField
                variant="outlined"
                label={t("Company email")}
                value={newCompany.email || ""}
                onChange={event => props.onChange(event, 'email')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newCompany.email}
                error={getErr("email")}
            />
            <br/>

            <TextField
                variant="outlined"
                label={t("Company phone")}
                value={newCompany.phone || ""}
                onChange={event => props.onChange(event, 'phone')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newCompany.phone}
                error={getErr("phone")}
            />
            <br/>

            <TextField
                variant="outlined"
                label={t("Company line of business")}
                value={newCompany.business || ""}
                onChange={event => props.onChange(event, 'business')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newCompany.business}
                error={getErr("business")}
            />
            <br/>

            <TextField
                variant="outlined"
                label={t("Company ID")}
                value={newCompany.yCode || ""}
                onChange={event => props.onChange(event, 'yCode')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newCompany.yCode}
                error={getErr("yCode")}
            />



        </div>
    );

};



export default CreateCompany;