import {SAVE_SEND, RESET_SEND} from "./types";

export const saveSend = (customer, bills, company, pdf) => {
    return {
        type: SAVE_SEND,
        customer: customer,
        bills: bills,
        company: company,
        pdf: pdf
    }
};

export const resetSend = () => {
    return {
        type: RESET_SEND
    }
};