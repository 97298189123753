import {GET_WORKSITES} from "./types";

import firebase from 'firebase/app';
import 'firebase/firestore';

export const getWorksites = (companyID) => dispatch => {
    firebase.firestore().collection('company').doc(companyID).collection('worksites').onSnapshot(querySnapshot => {
        const worksites = [];

        querySnapshot.forEach(doc => {
            const worksite = doc.data();
            worksite.id = doc.id;
            worksites.push(worksite);
        });

        dispatch({
            type: GET_WORKSITES,
            payload: worksites
        });
    });
};