import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as firebase from 'firebase';
import {TimePicker} from "material-ui-time-picker";
import {Button, Dialog, DialogActions} from "@material-ui/core";

import {withTranslation} from "react-i18next";

class TimeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            time: ""
        };
    }

    componentWillMount() {
        this.setState({
            time: this.props.value,
            dialogOpen: true
        });
    }

    handleTimeChange = (event) => {
        this.setState({
            time: event
        });
    };

    handleTimeConfirm = () => {
        const {time} = this.state;
        this.props.onChange(time);
    };

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.props.onClose()}
                    maxWidth="sm"
                >
                    <TimePicker mode="24h" onChange={event => this.handleTimeChange(event)} value={this.state.time}/>
                    <DialogActions>
                        <Button onClick={() => this.props.onClose()}>
                            {this.props.t("Cancel")}
                        </Button>

                        <Button onClick={() => this.handleTimeConfirm()}>
                            {this.props.t("Save")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(TimeDialog));