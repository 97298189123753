import React, {Component} from 'react';

import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

//import enGb from 'react-date-range/dist/locale/en-GB';
//import fi from 'react-date-range/dist/locale/fi';
import { fi, enGB} from 'date-fns/locale'

import {connect} from 'react-redux';
const DateComponent = props => {
    const {user} = props.user;

    return (
        <div style={{paddingLeft: 10, paddingRight: 10, marginTop: 10}}>
            <DateRange
                onChange={event => props.onSelect(event, 'dateRange')}
                moveRangeOnFirstSelection={false}
                ranges={[props.dateRange.selection]}
                style={{
                    width: 240,

                }}
                locale={user.lang && user.lang === "en-gb" ? enGB : fi}

            />
        </div>
    );

};

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps,{})(DateComponent);