import React, {Component} from 'react';
import firebase from 'firebase/app';
import "firebase/firestore";

import MapComponent from '../util/GoogleMap';

import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import axios from 'axios';
import {Link, withRouter} from "react-router-dom";

import {
    Grid,
    Button,
    CircularProgress,
    Paper,
    Divider,
    ListItemText,
    ListItem,
    TextField,
    MenuItem
} from '@material-ui/core';

import {connect} from 'react-redux';

import WorksiteDialog from '../JobCardComponents/WorksiteDialog';
import JobCalList from '../JobCardComponents/JobCalList';
import ToolTip from "../util/ToolTip";
import moment from "moment-timezone";
import './CustomerDetails.css';
import {employeesToString, tasksToString} from "../util/StringHelpers";
import {validateWorksite} from "../validations/worksite";


function TabContainer(props) {
    return (
        <div>
            {props.children}
        </div>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const geoApi = require('../config/keys').geoApi;

class CustomerDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            customer: {},
            jobs: [],
            value: 0,
            loading: true,
            jobLoading: true,
            worklogsLoading: true,
            worksiteDialog: false,
            worksites: [],
            sitesWithCoords: [],
            selectedWorksite: {},
            changedSiteID: "",
            id: "",
            texts: {
                add: this.props.t("ADD")
            },
            tooltip: null,
            tooltipMessage: "",
            mapCenter: {
                lat: 59.95,
                lng: 30.33
            },
        };

    }

    componentDidMount() {
        const {user} = this.props.user;
        const {match: {params}} = this.props;

        if (params.customerId) {
            const id = params.customerId;

            this.unsubscribeCustomerListener = firebase.firestore().collection('customers').doc(id).onSnapshot(docSnapshot => {
                let customer = docSnapshot.data();
                customer.id = docSnapshot.id;
                this.setState({
                    customer: customer,
                    id: docSnapshot.id,
                });
            });

            //TODO: can we limit the search to customer's worksites
            this.unsubscribeWorksiteListener = firebase.firestore().collection('company').doc(this.props.user.user.companyID).collection('worksites')
                .where("customer", "==", id)
                .onSnapshot(querySnapshot => this.onWorksiteSnapShot(querySnapshot, id));

            this.unsubscribeJobListener =
                firebase.firestore().collection('company').doc(user.companyID).collection("jobs")
                    .where("customer", "==", id)
                    .onSnapshot(qS => this.onJobSnapshot(qS, id));

            this.unsubscribeWorklogListener =
                firebase.firestore().collection('company').doc(user.companyID).collection("worklogs")
                    .where("customer", "==", id)
                    .orderBy("jobStartDT", "desc").limit(25)
                    .onSnapshot(qS => this.onWorklogSnapshot(qS, id));

            //TODO find employees or get from redux
            firebase.firestore().collection("employees").where("companyID", "==", user.companyID).get().then(querySnap => this.onEmployeeSnapshot(querySnap));

        }
    }

    onJobSnapshot = (querySnapshot, id) => {
        const jobs = [];

        querySnapshot.forEach(doc => {
            const job = doc.data();
            job.id = doc.id;

            if (job.customer === id) {
                jobs.push(job)
            }
        });

        this.setState({
            jobs: jobs,
            jobsLoading: false,
        });
    };

    onWorklogSnapshot = (querySnapshot, id) => {
        const worklogs = [];

        querySnapshot.forEach(doc => {
            const worklog = doc.data();
            worklog.id = doc.id;

            if (worklog.customer === id) {
                worklogs.push(worklog)
            }
        });

        this.setState({
            worklogs: worklogs,
            worklogsLoading: false,
        });
    };


    onEmployeeSnapshot = (querySnapshot) => {
        const employees = [];

        if (querySnapshot) {
            //miksi tämä haku jossa ei ole varsinainen snapshotti, täytyy kaivaa tuolta .doc. - alta tuo array?
            querySnapshot.docs.forEach(doc => {
                const employee = doc.data();
                employee.id = doc.id;
                employee.name = `${employee.firstName.charAt(0)}.${employee.familyName}`; //shorten a bit
                employees.push(employee);
            });
        }
        this.setState({
            employees: employees,
            loading: false,
        });
    };

    onWorksiteSnapShot = (querySnapshot, id) => {
        let worksites = [];
        const sitesWithCoords = [];

        querySnapshot.forEach(doc => {
            let worksite = doc.data();
            worksite.id = doc.id;

            if (worksite.customer === id) {
                worksites.push(worksite);

                if (worksite.lng && worksite.lat) {
                    sitesWithCoords.push(worksite);
                }
            }
        });

        const defaultCenter = sitesWithCoords.length > 0 ? sitesWithCoords[0] : null;

        this.setState({
            worksites: worksites,
            sitesWithCoords: sitesWithCoords,
            defaultCenter: defaultCenter,
        });
    };

    componentWillUnmount() {
        if (this.unsubscribeCustomerListener) {
            this.unsubscribeCustomerListener();
        }
        if (this.unsubscribeWorksiteListener) {
            this.unsubscribeWorksiteListener();
        }
        if (this.unsubscribeJobListener) {
            this.unsubscribeJobListener();
        }
        if (this.unsubscribeWorklogListener) {
            this.unsubscribeWorklogListener();
        }
        /*if (this.unsubscribeEmployeeListener) {
            this.unsubscribeEmployeeListener();
        }*/
    }

    handleChange = (event, value) => {
        this.setState({value});
    };



    handleWorksiteFields = (event, field) => {
        let {selectedWorksite, changedSiteID} = this.state;

        console.log("handleWorksiteFields, ", field)

        if (selectedWorksite.isDefault === "true" && event.target.value === "true") {
            selectedWorksite.isDefault = "false"
        }
        else {
            selectedWorksite[field] = event.target.value;
        }

        this.setState({
            selectedWorksite: selectedWorksite,
            changedSiteID: changedSiteID
        });

    };

    handleBillingFields = (event, field) => {
        const {customer} = this.state;

        if (!customer.billingSettings) customer.billingSettings = {};

        if (field === "timeToPay") customer.billingSettings[field] = parseFloat(event.target.value);
        else customer.billingSettings[field] = event.target.value;

        this.setState({customer});
    };

    getCoordinates = (site) => {
        const {user} = this.props.user;

        if (site.streetAddress && site.city) {

            let street = site.streetAddress.split(' ').join('+');
            let city = site.city;

            let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${street},+${city}&key=${geoApi}`;

            axios
                .get(url)
                .then(resp => {
                    let location = resp.data.results[0].geometry.location;
                    site.lat = location.lat;
                    site.lng = location.lng;

                    firebase.firestore().collection('company').doc(user.companyID).collection('worksites').add(site);

                })
                .catch(err => {
                    firebase.firestore().collection('company').doc(user.companyID).collection('worksites').add(site);
                    console.log(err)
                });
        }
    };

    saveBilling = () => {
        const {customer} = this.state;

        firebase.firestore().collection("customers").doc(customer.id).set(customer)
            .then(() => console.log("saveSuccess"));
    };

    handleSave = () => {
        const {selectedWorksite, worksites} = this.state;
        const {user} = this.props.user;

        // Set customer field for worksite
        selectedWorksite.customer = this.state.id;

        // Check if default worksite was changed
        if (selectedWorksite.isDefault === "true") {
            if (worksites && worksites.length > 0) {
                worksites.map(site => {
                    if (site.id !== selectedWorksite.id) {

                        if (site.isDefault === "true") {
                            site.isDefault = "false";

                            firebase.firestore().collection('company').doc(user.companyID).collection('worksites').doc(site.id).set(site);
                        }
                    }
                });
            }
        }

        // Update if we have ID
        if (selectedWorksite.id) {
            firebase.firestore().collection('company').doc(user.companyID).collection('worksites').doc(selectedWorksite.id).set(selectedWorksite);
        }
        else {
            this.getCoordinates(selectedWorksite);
        }

        this.setState({
            worksiteDialog: !this.state.worksiteDialog,
            selectedWorksite: {},
        });
    };

    handleDelete = site => {
        const {worksites} = this.state;

        // If Deleted site is Default, loop and set another one
        if (site.isDefault === "true" && worksites && worksites.length > 1) {
            // Use variable to set only one
            let counter = 0;
            worksites.map(worksite => {
                if (worksite.id !== site.id && counter === 0) {
                    worksite.isDefault = "true";

                    firebase.firestore().collection('company').doc(this.props.user.user.companyID).collection('worksites').doc(worksite.id).set(worksite);

                    counter++;
                }
            });
        }

        firebase.firestore().collection('company').doc(this.props.user.user.companyID).collection('worksites').doc(site.id).delete();

        this.setState({
            worksiteDialog: !this.state.worksiteDialog,
            selectedWorksite: {},
        });

    };

    getTasks = log => {
        const taskStringArray = [];

        if (log.billing && log.billing.billableHoursByTask) {
            log.billing.billableHoursByTask.forEach(task => {
                if (task.duration > 0 && !taskStringArray.includes(task.name)) taskStringArray.push(task.name);
            });
        }

        return taskStringArray.join(", ");
    };

    /*
    getEmployeesForLog = log => {
        const {employees} = this.state;

            console.log("getEmployee for log ", log);
        console.log("getEmployee employees ", employees);

        const ids = log.employee;
        console.log("WL IDS " + ids );

        if (!employees) return "Couldn't find name";

        if (Array.isArray(ids)) {
            const nameArray = [];

            ids && ids.forEach(id => {
                console.log("WL EMPL ID " + id );
                const employee = employees.find(employee => employee.id === id);
                console.log("employee ", employee);
                console.log( "nameArray 1", nameArray);
                if (employee && !nameArray.includes(employee.name)) nameArray.push(employee.name)
                console.log( "nameArray 2", nameArray);
            });

            return nameArray.join(", ");
        }
        else {
            const employee = employees.find(emp => emp.id === ids);

            return (employee !== "undefined" || employee !== undefined) ? employee.name : "Couldn't find name"
        }

    };*/

    getWorksiteNameForLog = log => {
        const {worksites} = this.state;
        const {customer} = this.state;
        const {t} = this.props;

        const worksite = worksites.find(worksite => worksite.id === log.worksite);

        if (worksite && worksite.name) return worksite.name;
        else if (worksite && worksite.number) return t("Worksite number") + worksite.number;
        else return (customer.companyName ? customer.companyName : customer.name)

    }

    generateWorklogItem = log => {
        const {t} = this.props;
        const {employees} = this.state;

        console.log('generateWorklogItem ', log )
        return (
            <div key={log.id}>
                <div className="log-list-item-container">
                    <p className="log-list-item-largerer">{this.getWorksiteNameForLog(log)}</p>
                    <p className="log-list-item-large">{employeesToString(log.employee, employees)}</p>
                    <p className="log-list-item-large">{moment(log.startDT).locale("fi").format('L')}</p>
                    <p className="log-list-item-large">
                        {moment(log.startDT).format('LT') + ' - ' + (log.status !== "Ongoing" ? moment(log.endDT).format('LT') : "")}
                    </p>
                    <p className="log-list-item-large">{tasksToString(log)}</p>
                    <p className="log-list-item-large">{t(log.status)}</p>
                </div>

            </div>
        );

    }


    render() {
        const {customer, tooltip, defaultCenter, worksites, sitesWithCoords, worklogs, loading, jobsLoading, worklogsLoading} = this.state;
        if (!customer.billingSettings) customer.billingSettings = {};
        const {t} = this.props;

        const open = Boolean(tooltip);

        return (
            <div style={{width: '100%',}}>

                {loading &&
                <CircularProgress/>}

                <Grid container spacing={24} style={{padding: 20}}>

                    <Grid item xs={12} sm={3}>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                <Paper style={{height: '100%'}}>
                                    {this.state.customer.companyName &&
                                    <ListItem style={{backgroundColor: '#2B81C1', paddingBottom: 0}}>
                                        <ListItemText primary={this.state.customer.companyName}
                                                      style={{color: '#FFF'}}
                                                      disableTypography
                                        />

                                    </ListItem>
                                    }
                                    <ListItem style={{backgroundColor: '#2B81C1', paddingBottom: 0}}>
                                        <ListItemText primary={this.state.customer.name}
                                                      style={{color: '#FFF'}}
                                                      disableTypography
                                        />
                                        {/*
                                        <Button
                                            size="small"
                                            style={{backgroundColor: '#FFF', marginRight: 5}}
                                            onClick={() => this.props.history.goBack()}
                                        >
                                            {t("Back")}
                                        </Button>
                                        */}
                                        <Button
                                            size="small"
                                            style={{backgroundColor: '#FFF'}}
                                            component={Link}
                                            to={"/registry/customers/edit/" + this.state.id}
                                        >
                                            {t("Edit")}
                                        </Button>
                                    </ListItem>
                                    <ListItem style={{backgroundColor: '#2B81C1', paddingTop: 0}}>
                                        <ListItemText secondary={this.state.customer.streetAddress}
                                                      style={{color: '#FFF'}}
                                                      disableTypography/>
                                    </ListItem>
                                    <ListItem style={{backgroundColor: '#2B81C1', paddingTop: 0}}>
                                        <ListItemText secondary={this.state.customer.streetAddressExt}
                                                      style={{color: '#FFF'}}
                                                      disableTypography/>
                                    </ListItem>
                                    <ListItem style={{backgroundColor: '#2B81C1', paddingTop: 0}}>
                                        <ListItemText
                                            secondary={this.state.customer.postCode + ', ' + this.state.customer.city}
                                            style={{color: '#FFF'}}
                                            disableTypography/>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText primary={customer.phone ? customer.phone : t("Phone")}
                                                      secondary={t("Home")}/>
                                    </ListItem>

                                    <ListItem>
                                        <ListItemText primary={customer.phone ? customer.phone : t("Phone")}
                                                      secondary={t("Work")}/>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem style={{overflow: 'hidden'}}>
                                        <ListItemText primary={customer.email ? customer.email : t("Email")}
                                                      secondary={t("Email")}
                                                      style={{
                                                          overflow: 'auto',
                                                          paddingBottom: 10
                                                      }}
                                        />
                                    </ListItem>


                                </Paper>
                            </Grid>

                            <Grid item xs={12}>
                                <Paper>
                                    <div style={{height: 100, backgroundColor: '#2B81C1', display: 'flex'}}>
                                        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                            <p style={{
                                                fontSize: 20,
                                                paddingLeft: 15,
                                                paddingTop: 5,
                                                color: '#FFF'
                                            }}>{t("Worksites")}</p>
                                        </div>
                                        <div style={{flexGrow: 1}}>
                                            <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: 20}}>
                                                <Button
                                                    style={{backgroundColor: '#FFF', marginTop: 15}}
                                                    onClick={() => this.setState({
                                                        worksiteDialog: !this.state.worksiteDialog,
                                                        editMode: false
                                                    })}
                                                >
                                                    {t("Add")}
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                    <div style={{
                                        height: 40 * (1 + (worksites.length ? worksites.length : 0)),
                                        overflow: 'auto'
                                    }}>
                                        {worksites && worksites.map(site => (
                                            <ListItem key={site.id} button onClick={() => this.setState({
                                                worksiteDialog: true,
                                                selectedWorksite: {...site},
                                                editMode: true
                                            })}>
                                                <ListItemText
                                                    primary={site.name + ' - ' + site.streetAddress + ', ' + site.postCode + ' ' + site.city}
                                                    secondary={site.isDefault === "true" ? t("Default") : " "}
                                                />
                                            </ListItem>
                                        ))}
                                    </div>
                                </Paper>
                            </Grid>

                            {!loading &&
                            <Grid item xs={12} style={{marginTop: 24}}>
                                <Paper style={{height: 300}}>
                                    <MapComponent
                                        worksites={sitesWithCoords}
                                        type={"CustomerWorksites"}
                                        loadingElement={<div style={{height: `100%`}}/>}
                                        containerElement={<div style={{height: '100%'}}/>}
                                        mapElement={<div style={{height: '100%'}}/>}
                                    />
                                </Paper>
                            </Grid>
                            }
                            <Grid item xs={12} style={{marginTop: 24}}>
                                {/*<Paper style={{padding: 5, textAlign: 'Left'}}> Chat Box </Paper>*/}
                            </Grid>
                        </Grid>
                    </Grid>


                    {/* worklog component */}

                    {!worklogsLoading && !loading &&
                    <Grid item xs={12} sm={4}>
                        <Grid container direction="column">

                            <Grid item xs={12} style={{marginTop: 0}}>

                                <div style={{height: 100, backgroundColor: '#2B81C1', display: 'flex'}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                        <p style={{
                                            fontSize: 20,
                                            paddingLeft: 15,
                                            paddingTop: 5,
                                            color: '#FFF'
                                        }}>{t("Worklogs")}</p>
                                    </div>

                                </div>

                                <div className="log-list-header">
                                    <p className="log-list-header-large">{t("Worksite")}</p>
                                    <p className="log-list-header-large">{t("Employee")}</p>
                                    <p className="log-list-header-date">{t("Date")}</p>
                                    <p className="log-list-header-large">{t("Time")}</p>
                                    <p className="log-list-header-large">{t("Task")}</p>
                                    <p className="log-list-header-large">{t("Status")}</p>
                                </div>

                                <div className="log-list">
                                    {worklogs && worklogs.length > 0 && worklogs.map(this.generateWorklogItem)}
                                </div>

                            </Grid>
                        </Grid>
                    </Grid>
                    }


                    {!loading && !jobsLoading &&
                    <Grid item xs={12} sm={4}>
                        <Grid container direction="column">
                            <div style={{height: 100, backgroundColor: '#2B81C1', display: 'flex'}}>
                                <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                                    <p style={{
                                        fontSize: 20,
                                        paddingLeft: 15,
                                        paddingTop: 5,
                                        color: '#FFF'
                                    }}>{t("Calendar")}</p>
                                </div>

                            </div>
                            <Grid item xs={12}>
                                <Paper style={{height: '100%', padding: 5}}>
                                    {this.state.id &&
                                    <JobCalList
                                        jobs={this.state.jobs}
                                        worksites={this.state.worksites}
                                        worklogs={this.state.worklogs}
                                        customer={this.state.id}
                                        height={610}
                                    />
                                    }
                                </Paper>
                            </Grid>
                            <Grid item xs={12} style={{marginTop: 24}}>
                                {/*<Paper style={{padding: 5, textAlign: 'Left'}}> Customer settings </Paper>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                    }

                </Grid>


                <div className="customer-edit-buttons">
                    <Button
                        variant="contained"
                        style={{color: '#FFF', backgroundColor: '#1770B2', marginRight: 10}}
                        onClick={() => this.props.history.goBack()}
                    >
                        {t("Back")}
                    </Button>

                </div>

                {this.state.worksiteDialog &&
                <WorksiteDialog
                    open={this.state.worksiteDialog}
                    onClose={() => this.setState({worksiteDialog: false, selectedWorksite: {}})}
                    editMode={this.state.editMode}
                    selectedWorksite={this.state.selectedWorksite}
                    onChange={this.handleWorksiteFields}
                    onDelete={this.handleDelete}
                    onSave={this.handleSave}
                    onValidate={validateWorksite}
                />
                }

                {open &&
                <ToolTip
                    anchor={this.state.tooltip}
                    message={t(this.state.tooltipMessage)}
                    position="right"
                />
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps, {})(withTranslation()(CustomerDetails));
