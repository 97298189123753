import React from 'react';
import {
    TextField, Button,
} from '@material-ui/core';

const CreateUser = props => {

    const { newUser, newCompany, errors, t } = props;

    const getErr = field => {
        if (errors.newUser[field] && errors.newUser[field].length > 0) {
            return true;
        }
    };

    return (
        <div>
            <TextField
                variant="outlined"
                label={t("First name")}
                value={newUser.firstName || ""}
                onChange={event => props.onChange(event, 'firstName')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newUser.firstName}
                error={getErr("firstName")}
            />
            <br />

            <TextField
                variant="outlined"
                label={t("Family name")}
                value={newUser.familyName || ""}
                onChange={event => props.onChange(event, 'familyName')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newUser.familyName}
                error={getErr("familyName")}
            />
            <br />


            <Button style={{ marginTop: 10, marginLeft: 10, color: "grey", fontSize: 11, fontStyle: 'italic', }} onClick={() => {
                props.onDirectChange(newCompany.streetAddress, 'streetAddress')
                props.onDirectChange(newCompany.city, 'city')
                props.onDirectChange(newCompany.postCode, 'postCode')
            }} >{t("Copy address from company")}</Button>
            <br />

            <TextField
                variant="outlined"
                label={t("Address")}
                value={newUser.streetAddress || ""}
                onChange={event => props.onChange(event, 'streetAddress')}
                style={{
                    marginTop: 5,
                    width: 300
                }}
                helperText={errors.newUser.streetAddress}
                error={getErr("streetAddress")}
            />

            <br />

            <TextField
                variant="outlined"
                label="City"
                value={newUser.city || ""}
                onChange={event => props.onChange(event, 'city')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newUser.city}
                error={getErr("city")}
            />
            <br />

            <TextField
                variant="outlined"
                label={t("Post code")}
                value={newUser.postCode || ""}
                onChange={event => props.onChange(event, 'postCode')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newUser.postCode}
                error={getErr("postCode")}
            />
            <br />

            <Button style={{ marginTop: 10, marginLeft: 10, color: "grey", fontSize: 11, fontStyle: 'italic', }}
                onClick={() => props.onDirectChange(newCompany.email, 'email')} >{t("Copy email from company")}
                </Button>
            <br />
            <TextField
                variant="outlined"
                label={t("Email")}
                value={newUser.email || ""}
                onChange={event => props.onChange(event, 'email')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newUser.email}
                error={getErr("email")}
            />

            <br />

            <Button style={{ marginTop: 10, marginLeft: 10, color: "grey", fontSize: 11, fontStyle: 'italic', }}
                onClick={() => props.onDirectChange(newCompany.phone, 'phone')} >{t("Copy phone from company")}
            </Button>
            <br />
            <TextField
                variant="outlined"
                label={t("Phone")}
                value={newUser.phone || ""}
                onChange={event => props.onChange(event, 'phone')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                helperText={errors.newUser.phone}
                error={getErr("phone")}
            />

            <br />
            <TextField
                variant="outlined"
                label={t("Password")}
                value={newUser.password || ""}
                onChange={event => props.onChange(event, 'password')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                type="password"
                helperText={errors.newUser.password}
                error={getErr("password")}
            />
            <br />


            <TextField
                variant="outlined"
                label={t("Repeat password")}
                value={newUser.repeatPassword || ""}
                onChange={event => props.onChange(event, 'repeatPassword')}
                style={{
                    marginTop: 10,
                    width: 300
                }}
                type="password"
                helperText={errors.newUser.repeatPassword}
                error={getErr("repeatPassword")}
            />
        </div >
    );
};

export default CreateUser;