import React, { Component } from 'react';

// Component imports
import UserList from "./EmployeeComponents/UserList";
import CustomerList from "./CustomerComponents/CustomerList";
import EmployeeEditor from "./EmployeeEditor";
import CustomerEditor from './CustomerComponents/CustomerEditor'
import CustomerDetails from "./CustomerComponents/CustomerDetails";
import EmployeeDetails from "./EmployeeDetails";
import ProductList from "./ProductComponents/ProductList";
import ProductEditor from "./ProductEditor";
import HomeDetails from "./HomeDetails";
import KeyList from './KeyComponents/Keylist';
import KeyListEditor from "./KeyComponents/KeyListEditor";
import Calendar from './CalendarComponents/Calendar';
import JobModelList from './TaskComponents/JobModelList';
import JobModelEditor from "./TaskComponents/JobModelEditor";
import JobTemplateList from "./TaskComponents/JobTemplateList";
import JobTemplateEditor from "./TaskComponents/JobTemplateEditor";
import SchedulerComponent from './SchedulerComponents/SchedulerComponent';
import WorklogEditor from "./WorkLogComponents/WorklogEditor";
import Settingss from "./SettingsComponents/Settings";
import TimeReports from './ReportComponents/TimeReports/TimeReports';
import BillReports from './ReportComponents/BillReports/BillReports';
import ReportList from './ReportComponents/ReportList';
import BillingLanding from "./BillingComponents/BillingLanding";
import BillList from './BillingComponents/BillsList';
import BillDetails from './BillingComponents/BillDetails';
import EmailComponent from "./CRM/EmailComponent";
import SuperUser from "./SuperUser";
import RegisterVehicle from './RegisterVehicle';
import ConnectSpoton from './ConnectSpoton';
import SignReportElectronically from './ReportComponents/SignElectronically';
import VehicleList from './SpotOnComponents/VehicleList';
import WorklogsLanding2 from "./WorkLogComponents/WorklogsLanding2";
//import WorkLogsLanding from "./WorkLogComponents/WorkLogsLanding";
import WorkDaysLanding from "./WorkDayComponents/WorkDaysLanding";
import WorkdayEditor from "./WorkDayComponents/WorkdayEditor";
import Workshifts from "./ReportComponents/WorkReports/Workshifts";


import {
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    Divider,
    Collapse
} from "@material-ui/core";
import logo from './img/logo.jpg';

import {
    Menu,
    ChevronLeft,
    Computer,
    Schedule,
    AccessibleForward,
    KeyboardTab,
    Settings,
    ExpandLess,
    ExpandMore,
    AllOut,
    CheckBox,
    CheckBoxOutlineBlank,
} from '@material-ui/icons';


import { Route, Link, withRouter, Switch, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { getUser, resetCurrentUser } from "./actions/userActions";
import { getProducts } from "./actions/productActions";
import { getWorksites } from "./actions/worksiteActions";
import { getCompany } from "./actions/companyActions";
import { checkPermissions, checkManagementPermissions, checkRedirect } from "./SignInPermissions";
import firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/auth";

import { withTranslation } from 'react-i18next';
import CalendarWrapper from "./JobCalendarComponents/CalendarWrapper";


class SignedIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Dashboard",
            menu: true,
            hideMenu: false,
            expansion: "",
            gotUser: false,
            loading: true,
            lang: "",
            listItems: [],
            isSuperUser: false,
        };
    }

    componentDidMount() {
        const { user } = this.props.user;
        console.log("ComponentDidMount ", user)
        //this.props.getUser();
    }

    // This update function makes sure that when we have user object, language is set right
    // Also useful when user changes language in settings -> triggers language change instantly
    componentDidUpdate() {
        const { user } = this.props.user;
        console.log("componentDidUpdate ", user)
        
        if ((user && user.firstName && !this.state.gotUser) || user.lang !== this.state.lang) {

            let lang = "";
            if (user.lang) {
                this.props.i18n.changeLanguage(user.lang);
                lang = user.lang;
            }
            else {
                this.props.i18n.changeLanguage("fi");
                lang = user.lang
            }

            // Check permissions
            let listItems = [];
            if (user && user.settings) listItems = checkPermissions(user);

            // Also update title
            const title = this.getTitle(this.props.location.pathname);

            // Trigger other Redux functions, by getting companyID from user we need only one firebase query
            this.props.getProducts(user.companyID);
            this.props.getWorksites(user.companyID);
            this.props.getCompany(user.companyID);
            this.getDataFromUsersTable(user);

            this.setState({
                gotUser: true,
                loading: false,
                lang: lang,
                title: title,
                listItems: listItems
            });
        }
    }

    getTitle = (path) => {
        if (path.search("calendar") > -1) return "Calendar";
        else if (path.search("jobcards") > -1) return "Jobcards";
        else if (path.search("worklogs") > -1) return "Worklogs";
        else if (path.search("schedule") > -1) return "Schedule";
        else if (path.search("workdays") > -1) return "WorkDays";
        else if (path.search("reports") > -1) return "Created reports";
        else if (path.search("timereports") > -1) return "Time reports";
        else if (path.search("billreports") > -1) return "Bill reports";
        else if (path.search("workshifts") > -1) return "Workshift reports";
        else if (path.search("billing") > -1) return "Create bills";
        else if (path.search("bills") > -1) return "Created bills";
        else if (path.search("jobmodels") > -1) return "Job models";
        else if (path.search("jobtemplates") > -1) return "Job templates";
        else if (path.search("products") > -1) return "Products";
        else if (path.search("keys") > -1) return "Keys";
        else if (path.search("customers") > -1) return "Customers";
        else if (path.search("employees") > -1) return "Employees";
        else if (path.search("settings") > -1) return "Settings";
        else if (path.search("crm") > -1) return "CRM email";
        else if (path.search("spotOn") > -1) return "SpotOn";
        else if (path.search("superuser") > -1) return "Super";
        else {
            return "Dashboard"
        }
    };

    getDataFromUsersTable = user => {
        firebase.firestore().collection("users").doc(user.id).get().then(docSnap => {
            if (docSnap.exists) {
                const user = docSnap.data();
                if (user.privileges && user.privileges[0] === "SuperUser") this.setState({ isSuperUser: true })
            }
        })
    };

    menuClickHandler = () => {
        this.setState((prevState) => {
            return { menu: !prevState.menu, expansion: "" }
        });
    };

    menuHideClickHandler = () => {
        this.setState((prevState) => {
            //TODO: store to user settings
            console.log('menuHideClickHandler prevState ', prevState)
            return { hideMenu: !prevState.hideMenu, expansion: "" }
        });
    };


    iconClickHandler = (item) => {
        let { menu, expansion } = this.state;

        let set = false;

        if (!menu) {
            menu = true;
        }

        if (expansion !== item) {
            expansion = item;
            set = true;
        }

        if (expansion === item && !set) {
            expansion = "";
        }

        this.setState({
            menu: menu,
            expansion: expansion
        });
    };

    navigateHandler = (path) => {
        //hide menu if hiding is set elseo don't hide
        this.setState({
            title: path,
            menu: this.state.hideMenu ? false : true,
            expansion: ""
        });
    };

    logOut = () => {

        firebase.auth().signOut()
            .catch(function (error) {
                console.log(error);
            });

        setTimeout(() => this.props.resetCurrentUser(), 1000);
    };


    render() {
        const { menu, expansion, hideMenu } = this.state;
        const { user } = this.props.user;
        const { t } = this.props;
        const { company } = this.props.company;
        

        return (
            <div style={{ height: '100vh', overflowY: 'hidden' }}>


                {!this.state.loading &&
                    <div>
                        <div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100vw',
                                height: 60,
                                backgroundColor: '#1770B2'
                            }}>
                                <IconButton
                                    style={{ color: '#FFF', height: 40, width: 40, padding: 0, marginLeft: 5, marginTop: 10 }}
                                    onClick={() => this.menuClickHandler()}>
                                    <Menu />
                                </IconButton>
                                <h3 style={{ marginLeft: 20, color: '#FFF' }}>{t(this.state.title)} - { company.name ? company.name : '' } </h3>
                            </div>
                            <div style={{ display: 'flex' }}>

                                <br />


                                <div style={{
                                    flexShrink: 0,
                                    overflowX: 'hidden',
                                    overflowY: 'auto',
                                    width: menu ? 240 : 50,
                                    height: '90vh',
                                    borderWidth: 0,
                                    borderRightWidth: 0.25,
                                    borderColor: '#ccc',
                                    borderStyle: 'solid'
                                }}>
                                    {menu &&
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-start',
                                            whiteSpace: 'nowrap',
                                            width: menu ? 240 : 50
                                        }}>
                                            <div style={{ width: 40, height: 50, padding: 0, marginLeft: 5, marginTop: 5 }}>
                                                <IconButton
                                                    onClick={() => this.menuHideClickHandler()}>
                                                    {hideMenu && <CheckBox />}
                                                    {!hideMenu && <CheckBoxOutlineBlank />}
                                                </IconButton>
                                                <p style={{ fontSize: 12, marginLeft: 15 }}>Piilota automaattisesti</p>

                                            </div>
                                            <IconButton
                                                style={{ width: 40, height: 50, padding: 0, marginLeft: '60%', marginTop: 5 }}
                                                onClick={() => this.menuClickHandler()}>
                                                <ChevronLeft />
                                            </IconButton>


                                        </div>
                                    }


                                    {menu &&
                                        <img src={logo} style={{ width: 200, height: 140, padding: 20 }} alt={t("Mobility")} />}
                                    <br />
                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        whiteSpace: 'nowrap',
                                        width: menu ? 240 : 50
                                    }}>
                                        <List style={{
                                            marginTop: menu ? 0 : 211,
                                            width: '100%',
                                            overflowX: 'hidden',
                                            marginRight: 5
                                        }}>


                                            {/* If user is Main user or Management admin -> Show dashboard*/}
                                            {user && user.settings && checkManagementPermissions(user) &&
                                                <div>
                                                    <Link to="/" onClick={() => this.navigateHandler("Dashboard")}
                                                        style={{ textDecoration: 'none', color: '#000' }}>
                                                        <ListItem button>
                                                            <ListItemIcon>
                                                                <Computer />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={t("Dashboard")}
                                                            />
                                                        </ListItem>
                                                    </Link>
                                                </div>
                                            }
                                            {user && user.settings && checkManagementPermissions(user) &&
                                                <Divider
                                                    style={{
                                                        width: '100%'
                                                    }}
                                                />
                                            }

                                            {this.state.listItems.map(item => {

                                                return (
                                                    <div key={item.name}>
                                                        <ListItem onClick={() => this.iconClickHandler(item.name)} button>
                                                            <ListItemIcon>
                                                                {item.icon}
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={t(item.name)}
                                                            />
                                                            {expansion === item.name ? <ExpandLess /> : <ExpandMore />}
                                                        </ListItem>

                                                        <Collapse in={expansion === item.name}>
                                                            <List style={{ marginLeft: 70 }}>
                                                                {item.items && item.items.map(link => (
                                                                    <Link to={link.path}
                                                                        style={{ textDecoration: 'none', color: '#000' }}
                                                                        onClick={() => this.navigateHandler(link.name)}
                                                                        key={link.name}>
                                                                        <ListItem button>
                                                                            <ListItemText primary={t(link.name)} />
                                                                        </ListItem>
                                                                    </Link>
                                                                ))}
                                                            </List>
                                                        </Collapse>

                                                        <Divider
                                                            style={{
                                                                width: '100%'
                                                            }}
                                                        />
                                                    </div>
                                                )
                                            })}

                                            <div>
                                                <Link to="/settings" onClick={() => this.navigateHandler("Settings")}
                                                    style={{ textDecoration: 'none', color: '#000' }}>
                                                    <ListItem button>
                                                        <ListItemIcon>
                                                            <Settings />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            primary={t("Settings")}
                                                        />
                                                    </ListItem>
                                                </Link>
                                            </div>

                                            {this.state.isSuperUser &&
                                                <React.Fragment>
                                                    <Divider
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    />

                                                    <Link to={"/superuser"} onClick={() => this.navigateHandler("Super")}
                                                        style={{ textDecoration: 'none', color: '#000' }}>
                                                        <ListItem button onClick={() => ""}>
                                                            <ListItemIcon>
                                                                <AccessibleForward />
                                                            </ListItemIcon>
                                                            <ListItemText primary={"Supah"} />
                                                        </ListItem>
                                                    </Link>

                                                </React.Fragment>
                                            }


                                            <Divider
                                                style={{
                                                    width: '100%'
                                                }}
                                            />

                                            <ListItem button onClick={() => this.logOut()}>
                                                <ListItemIcon>
                                                    <KeyboardTab />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={t("Log out")}
                                                />
                                            </ListItem>

                                            <Divider
                                                style={{
                                                    width: '100%'
                                                }}
                                            />

                                        </List>


                                    </div>

                                </div>


                                <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-start', height: '90vh' }}>
                                        {/*
                            <UIView/>
                            */}

                                        {/*If user is main user or management admin, allow movement to dashboard*/}

                                        <Route path="/" exact render={() => (
                                            user && user.settings && !checkManagementPermissions(user) ?
                                                (<Redirect to={checkRedirect(this.state.listItems)} />) : (<HomeDetails />)
                                        )} />


                                        {/* Management routes */}
                                        <Switch>
                                            <Route path="/management/calendar" component={CalendarWrapper} />
                                            <Route path="/management/jobcards" component={CustomerList} />
                                            {/* ----- Vaihda WorklogsLanding2 => WorkLogsLanding käyttääksesi vanhaa versiota ------ */}
                                            {/* ----- Vaihda WorklogsLanding2 => WorklogsLanding2 käyttääksesi uutta versiota ------ */}
                                            <Route path="/management/worklogs" exact component={WorklogsLanding2} />
                                            <Route path="/management/worklogs/edit/:wlID" component={WorklogEditor} />
                                            <Route path="/management/worklogs/add/:jobID,:startDT,:endDT"
                                                component={WorklogEditor} />
                                            {/* -------- <Route path="/management/schedule" component={SchedulerComponent}/> ----*/}
                                            <Route path="/management/workdays" exact component={WorkDaysLanding} />
                                            <Route path="/management/workdays/edit/:wdayID,:emplID" component={WorkdayEditor} />
                                        </Switch>

                                        {/* report routes */}
                                        <Route path="/reports" exact component={ReportList} />
                                        <Route path="/reports/timereports" component={TimeReports} />
                                        <Route path="/reports/billreports" component={BillReports} />
                                        <Route path="/reports/workshifts" component={Workshifts} />

                                        {/* Billing routes */}
                                        <Switch>
                                            <Route path="/billing" component={BillingLanding} />
                                            <Route path="/bills" exact component={BillList} />
                                            <Route path="/bills/add" component={BillDetails} />
                                            <Route path="/bills/edit/:billId" component={BillDetails} />
                                        </Switch>

                                        {/* Registry routes*/}
                                        <Switch>
                                            <Route path="/registry/jobmodels" exact component={JobModelList} />
                                            <Route path="/registry/jobmodels/add" component={JobModelEditor} />
                                            <Route path="/registry/jobmodels/edit/:modelId" component={JobModelEditor} />
                                        </Switch>

                                        <Switch>
                                            <Route path="/registry/jobtemplates" exact component={JobTemplateList} />
                                            <Route path="/registry/jobtemplates/add" component={JobTemplateEditor} />
                                            <Route path="/registry/jobtemplates/edit/:templateId"
                                                component={JobTemplateEditor} />
                                        </Switch>

                                        <Switch>
                                            <Route path="/registry/products" exact component={ProductList} />
                                            <Route path="/registry/products/add" component={ProductEditor} />
                                            <Route path="/registry/products/edit/:productId" component={ProductEditor} />
                                        </Switch>

                                        <Switch>
                                            <Route path="/registry/keys" exact component={KeyList} />
                                            <Route path="/registry/keys/add" component={KeyListEditor} />
                                            <Route path="/registry/keys/edit/:keyId" component={KeyListEditor} />
                                        </Switch>

                                        <Switch>
                                            <Route path="/registry/customers" exact component={CustomerList} />
                                            <Route path="/registry/customers/details/:customerId"
                                                component={CustomerDetails} />
                                            <Route path="/registry/customers/add" component={CustomerEditor} />
                                            <Route path="/registry/customers/edit/:customerId" component={CustomerEditor} />
                                        </Switch>

                                        <Switch>
                                            <Route path="/registry/employees" exact component={UserList} />
                                            <Route path="/registry/employees/details/:employeeId"
                                                component={EmployeeDetails} />
                                            <Route path="/registry/employees/add" component={EmployeeEditor} />
                                            <Route path="/registry/employees/edit/:employeeId" component={EmployeeEditor} />
                                        </Switch>

                                        <Switch>
                                            <Route path="/spoton/vehicles" exact component={VehicleList} />
                                        </Switch>

                                        <Route path="/registervehicle/:jwt" component={RegisterVehicle} />
                                        <Route path="/connectspoton/:jwt" component={ConnectSpoton} />
                                        <Route path="/signreportelectronically/:jwt" component={SignReportElectronically} />

                                        {/* CRM routes*/}
                                        <Route path="/crm/email" component={EmailComponent} />

                                        {/* Super route*/}
                                        {this.state.isSuperUser &&
                                            <Route path="/superuser" component={SuperUser} />
                                        }

                                        {/* Setting routes*/}
                                        <Route path="/settings" component={Settingss} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    company: state.company
});

export default withRouter(connect(mapStateToProps, {
    getUser,
    getProducts,
    getWorksites,
    resetCurrentUser,
    getCompany
})(withTranslation()(SignedIn)));
