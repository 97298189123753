import {GET_PRODUCTS} from "./types";
import firebase from 'firebase/app';
import 'firebase/firestore';

export const getProducts = companyID => dispatch => {
    firebase.firestore().collection('company').doc(companyID).collection('products').onSnapshot(querySnapshot => {
        const products = [];

        querySnapshot.forEach(doc => {
            const product = doc.data();
            product.id = doc.id;
            products.push(product);
        });

        dispatch({
            type: GET_PRODUCTS,
            payload: products
        });
    });
};