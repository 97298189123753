import React, {Component} from 'react';

//import {firestore, auth} from 'firebase';

import firebase from 'firebase/app';
import 'firebase/firestore';
import "firebase/auth";

import {
    TextField,
    MenuItem
} from "@material-ui/core";

class SuperUser extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            companies: [],
            user: {},
            loggedInAs: "",
        }
    }

    componentDidMount() {

        this.unsubscribeCompaniesListener = firebase.firestore().collection("company").onSnapshot(querySnap => {
            const companies = [];
            querySnap.forEach(doc => {
                const company = doc.data();
                company.id = doc.id;
                companies.push(company);
            });
            this.setState({ companies }, () => this.getLoggedInCompany());
        });
    }

    componentWillUnmount() {
        this.unsubscribeCompaniesListener && this.unsubscribeCompaniesListener();
        this.unsubscribeUserListener && this.unsubscribeUserListener();
    }

    getLoggedInCompany = () => {
        const {companies} = this.state;
        const uid = auth().currentUser.uid;

        if (uid) {
            this.unsubscribeUserListener = firebase.firestore().collection("employees").doc(uid).onSnapshot(docSnap => {
                const user = docSnap.data();
                user.id = docSnap.id;

                let loggedInAs = "";
                const currentCompany  =companies.find(company => company.id === user.companyID);
                if (currentCompany) loggedInAs = currentCompany.id;

                this.setState({ loading: false, user, loggedInAs });
            });
        }
    };

    onCompanySelect = event => {
        const {user} = this.state;

        user.companyID = event.target.value;

        firebase.firestore().collection("employees").doc(user.id).set(user)
            .catch(err => console.log(err))
    };

    render() {
        const {loading, companies, loggedInAs} = this.state;
        if (loading) return <div></div>;
        else return (
            <div style={{width: '100%', height: '100%', padding: 20}}>
                <h3>Kirjautuneena yritykseen</h3>
                <TextField
                    variant="outlined"
                    value={loggedInAs || ""}
                    style={{width: 300}}
                    onChange={this.onCompanySelect}
                    select
                >
                    {companies && companies.map(company => (
                        <MenuItem value={company.id} key={company.id}>{company.name}</MenuItem>
                    ))}
                </TextField>
            </div>
        );
    }
}

export default SuperUser;
