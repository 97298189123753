import React, { Component } from 'react';

import {
    UtilityBar,
    SelectButton,
    ListAreaHeader,
    ListAreaBody,
    ListAreaItem,
    FilterMenu,
    ConfirmDialog
} from '../ListComponents';

import {
    Button
} from '@material-ui/core';

import ImportDialog from "./ImportDialog";

import firebase from 'firebase/app';
import 'firebase/firestore';
import { DataSearch } from "../util/DataSearch";
import { Link, Redirect } from 'react-router-dom';
import { Transition, config } from "react-spring/renderprops-universal";
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";


import {
    IconButton,
    ClickAwayListener,
    FormControlLabel,
    Checkbox
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltDown, faLongArrowAltUp, faFilter } from "@fortawesome/free-solid-svg-icons";
import "./CustomerList.css";
class CustomerList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            customers: [],
            customerCopy: [],
            selectedCustomers: [],
            searchTerm: "",
            sortOrder: { dir: 'desc', field: "customerNumber" },
            filterAnchor: null,
            filterField: "",
            filterValues: [{ type: "true", field: "active" }],
            selection: false,
            selectAll: false,
            menuActions: [
                { action: "active", methods: ["Yes", "No"] }
            ],
            addNew: false,
            confirmObj: {},
            confirmDialog: false,
            saveLoading: false,
            saveComplete: false,
            importDialog: false
        }
    }

    componentDidMount() {
        const { user } = this.props.user;
        this.unsubscribeCustomerListener = firebase.firestore().collection("customers")
            .where("companyID", "array-contains", user.companyID).onSnapshot(querySnap => {
                const customers = [];

                querySnap.forEach(doc => {
                    const customer = doc.data();
                    customer.id = doc.id;
                    customer.active = customer.active.toString();
                    if (!customer.isDeleted) customers.push(customer);
                });

                this.setState({
                    loading: false,
                    customers,
                    customerCopy: JSON.parse(JSON.stringify(customers))
                }, () => {
                    this.filterCustomers(null, null)
                });
            })
    }

    componentWillUnmount() {
        this.unsubscribeCustomerListener && this.unsubscribeCustomerListener();
    }

    searchCustomers = event => {
        const searchTerm = event.target.value;

        const customers = [];

        this.state.customerCopy.forEach(customer => {
            const searchTarget = customer.name + customer.customerNumber;

            if (searchTarget.toLowerCase().search(searchTerm.toLowerCase()) > -1) customers.push(customer)
        });

        this.setState({ customers, searchTerm });
    };

    sortCustomers = (field, parse) => {
        let { sortOrder, customers } = this.state;

        let direction = "";

        if (sortOrder.field === field) direction = sortOrder.dir === "asc" ? "desc" : "asc";
        else direction = "desc"

        const result = customers.sort((a, b) => {
            let aField = parse ? parseFloat(a[field]) : a[field];
            let bField = parse ? parseFloat(b[field]) : b[field];

            if (parse && isNaN(aField)) aField = 0;
            if (parse && isNaN(bField)) bField = 0;

            if (direction === "asc" ? aField > bField : aField < bField) return -1;
            if (direction === "asc" ? aField < bField : aField > bField) return 1;
        });

        sortOrder.dir = direction;
        sortOrder.field = field;

        this.setState({ sortOrder, customers: result });
    };

    filterCustomers = (type, field) => {
        const { customerCopy, sortOrder, filterValues } = this.state;

        const newFilterValue = { type: type, field: field };

        const existingFilterValue = filterValues.find(value => value.type === newFilterValue.type && value.field === newFilterValue.field);

        if (type && field && !existingFilterValue) filterValues.push(newFilterValue);
        else if (type !== null && field !== null) {
            const removeIndex = filterValues.indexOf(existingFilterValue);
            filterValues.splice(removeIndex, 1);
        }

        const customerFilter = new DataSearch();
        customerFilter.setSearchData(customerCopy);
        if (filterValues.some(value => value.field === "active")) customerFilter.appendField("active", filterValues.filter(value => value.field === "active").map(value => value.type), "searchValueIncludes");

        const results = customerFilter.executeSearch(sortOrder.dir === "asc" ? "desc" : "asc", sortOrder.field);

        this.setState({
            customers: results,
            filterValues
        });
    };

    handleSelectionButton = action => {
        if (action === "Add") {
            this.props.history.push("/registry/customers");
            this.setState({ addNew: true });
        }
        else if (action === "Import") this.setState({ importDialog: true });
        else this.setState(prevState => ({ selection: !prevState.selection }))
    };

    handleCustomerSelection = id => {
        const { selectedCustomers } = this.state;

        if (!selectedCustomers.includes(id)) selectedCustomers.push(id);
        else {
            const removeIndex = selectedCustomers.indexOf(id);
            selectedCustomers.splice(removeIndex, 1);
        }

        this.setState({ selectedCustomers });
    };

    handleMassCustomerSelection = () => {
        const { selectAll, customers } = this.state;

        let selectedCustomers = [];

        if (selectAll) selectedCustomers = [];
        else selectedCustomers = customers.map(customer => customer.id);

        this.setState(prevState => ({ selectedCustomers, selectAll: !prevState.selectAll }));
    };

    handleMassActionSelect = (action, option) => {
        const { confirmObj } = this.state;
        const { t } = this.props;

        confirmObj.title = t("Attention");
        confirmObj.action = action;
        confirmObj.option = option;
        confirmObj.message = `${t("ChangeTo")} ${t("customers")}`

        if (action === "active") confirmObj.status = `${t("active")}: ${t(option)}`;

        this.setState({ confirmObj, confirmDialog: true });
    };

    onMassActionConfirm = () => {
        const { confirmObj, selectedCustomers, customerCopy } = this.state;

        const batch = firebase.firestore().batch();

        selectedCustomers.forEach(id => {
            const customer = customerCopy.find(customer => customer.id === id);

            if (customer) {
                if (confirmObj.action === "active") customer.active = confirmObj.option === "Yes";
            }

            const customerRef = firebase.firestore().collection("customers").doc(customer.id);
            batch.set(customerRef, customer);
        });

        batch.commit().then(() => {
            this.setState({
                saveComplete: true,
                selectedCustomers: [],
                selectedAll: false
            });
        });
    };

    arrowDirection = field => {
        const { sortOrder } = this.state;

        if (sortOrder.field === field && sortOrder.dir === "desc") {
            return (
                <FontAwesomeIcon icon={faLongArrowAltUp} style={{ height: 15, width: 15 }} />
            )
        }
        else return <FontAwesomeIcon icon={faLongArrowAltDown} style={{ height: 15, width: 15 }} />
    };

    toggleFilterMenu = (event, filterField) => {
        const newAnchor = event.currentTarget ? event.currentTarget.getBoundingClientRect() : null;
        this.setState({ filterAnchor: newAnchor, filterField })
    };

    renderFilterItem = (filter, field, props) => {
        const { t } = this.props;
        let value = "";

        if (field === "active") {
            if (filter === "true") value = t("Actives");
            else value = t("Deactivated");
        }
        else value = t(filter);

        return (
            <div className="filterItem-container" style={props}>
                <p className="filterItem-text">{value}</p>
                <IconButton style={{ marginLeft: 10, padding: 7 }} onClick={() => this.filterCustomers(filter, field)}>
                    <Close style={{ height: 10, width: 10 }} />
                </IconButton>
            </div>
        );
    };

    renderListItem = customer => {
        const { t } = this.props;

        if (this.state.selection) {
            return (
                <ListAreaBody key={customer.id}>
                    {this.state.selection && <ListAreaItem variant="list" size="checkbox" checked={this.state.selectedCustomers.includes(customer.id)} show={this.state.selection} onChange={() => this.handleCustomerSelection(customer.id)} />}
                    <ListAreaItem variant="list" size="small">{customer.customerNumber}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{customer.name}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{customer.companyName}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{customer.city}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{customer.postCode}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{customer.active === "true" ? t("Y") : t("N")}</ListAreaItem>
                </ListAreaBody>
            );
        }
        return (
            <Link key={customer.id} to={`/registry/customers/details/${customer.id}`} className="customer-link">
                <ListAreaBody>
                    {this.state.selection && <ListAreaItem variant="list" size="checkbox" checked={""} show={this.state.selection} />}
                    <ListAreaItem variant="list" size="small">{customer.customerNumber}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{customer.name}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{customer.companyName}</ListAreaItem>
                    <ListAreaItem variant="list" size="large">{customer.city}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{customer.postCode}</ListAreaItem>
                    <ListAreaItem variant="list" size="small">{customer.active === "true" ? t("Y") : t("N")}</ListAreaItem>
                </ListAreaBody>
            </Link>

        );
    };

    render() {
        const { searchTerm, filterValues, filterAnchor } = this.state;
        const { t } = this.props;
        return (
            <div className="customer-content">
                <UtilityBar
                    t={t}
                    searchBar
                    searchTerm={searchTerm}
                    searchOnChange={event => this.searchCustomers(event)}
                >
                    <div className="customer-utility">
                        <div className="customer-utility left">
                            <Transition
                                items={filterValues}
                                keys={item => item.type + item.field}
                                from={{ transform: "scale(0)" }}
                                enter={{ transform: "scale(1)" }}
                                leave={{ transform: "scale(0)" }}
                                config={config.stiff}
                            >
                                {item => props => this.renderFilterItem(item.type, item.field, props)}
                            </Transition>
                        </div>
                        <div className="customer-utility right">
                            <SelectButton
                                onChange={this.handleSelectionButton}
                                options={["Add", "Selection", "Import"]}
                            />
                        </div>
                    </div>
                </UtilityBar>

                <ListAreaHeader>
                    {this.state.selection &&
                        <ListAreaItem
                            variant="title"
                            size="checkbox"
                            show={this.state.selection}
                            checked={this.state.selectAll}
                            onChange={this.handleMassCustomerSelection}
                            onSelect={this.handleMassActionSelect}
                            menuActions={this.state.menuActions}
                        />
                    }

                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortCustomers("customerNumber", true)}>
                                {this.arrowDirection('customerNumber')}
                            </IconButton>
                        }
                    >
                        {`${t("Customer")}#`}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="large"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortCustomers("name", false)}>
                                {this.arrowDirection('name')}
                            </IconButton>
                        }
                    >
                        {t("Name")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="large"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortCustomers("companyName", false)}>
                                {this.arrowDirection('companyName')}
                            </IconButton>
                        }
                    >
                        {t("Company")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="large"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortCustomers("city", false)}>
                                {this.arrowDirection('city')}
                            </IconButton>
                        }
                    >
                        {t("City")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={() => this.sortCustomers("postCode", true)}>
                                {this.arrowDirection('postCode')}
                            </IconButton>
                        }
                    >
                        {t("Postal code")}
                    </ListAreaItem>

                    <ListAreaItem
                        variant="title"
                        size="small"
                        icon={
                            <IconButton style={{ padding: 7, margin: 0, marginLeft: 3 }} onClick={event => this.toggleFilterMenu(event, "active")}>
                                <FontAwesomeIcon icon={faFilter} style={{ height: 15, width: 15 }} />
                            </IconButton>
                        }
                    >
                        {t("Active_sub")}
                    </ListAreaItem>

                </ListAreaHeader>

                {this.state.customers.map(this.renderListItem)}

                <div style={{ height: 80 }} />

                {Boolean(filterAnchor) &&
                    <ClickAwayListener onClickAway={() => this.toggleFilterMenu({}, this.state.filterField)}>
                        <FilterMenu
                            anchor={filterAnchor}
                        >
                            <div style={{ marginLeft: 5, marginTop: 0 }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!filterValues.find(value => value.type === "true" && value.field === "active")}
                                            onChange={() => this.filterCustomers("true", "active")}
                                            style={{ color: "#1770B2" }}
                                        />
                                    }
                                    label={t("Yes")}
                                />
                                <br />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={!!filterValues.find(value => value.type === "false" && value.field === "active")}
                                            onChange={() => this.filterCustomers("false", "active")}
                                            style={{ color: "#1770B2" }}
                                        />
                                    }
                                    label={t("No")}
                                />
                            </div>
                        </FilterMenu>
                    </ClickAwayListener>
                }


                {this.state.confirmDialog &&
                    <ConfirmDialog
                        open={this.state.confirmDialog}
                        onClose={() => this.setState({ confirmDialog: false, saveComplete: false, sendLoading: false, confirmObj: { title: "", message: "", status: "", action: "", option: "" } })}
                        onConfirm={() => this.setState({ sendLoading: true }, () => this.onMassActionConfirm())}
                        title={this.state.confirmObj.title}
                        message={this.state.confirmObj.message}
                        status={this.state.confirmObj.status}
                        loading={this.state.saveLoading}
                        saveComplete={this.state.saveComplete}
                        comp={this.props.t("Customers")}
                    />
                }

                {this.state.importDialog &&
                    <ImportDialog
                        open={this.state.importDialog}
                        onClose={() => this.setState({ importDialog: false })}
                        customers={this.state.customers}
                    />
                }

                {this.state.addNew &&
                    <Redirect to={"/registry/customers/add"} />
                }
            </div>
        );
    }

}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(CustomerList));
