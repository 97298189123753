import React, {Component} from 'react';

import {
    Checkbox,
    IconButton,
    MenuItem,
    ClickAwayListener
} from '@material-ui/core';

import {
    MoreVert
} from '@material-ui/icons';

import firebase from 'firebase/app';
import 'firebase/firestore';

import {withTranslation} from "react-i18next";
import ColorDialog from '../ColorDialog';

class EmployeeListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuAnchor: null,
            selectedEmployee: "",
            colorDialog: false,
        }


    }

    handleMenu = (event, employee) => {

        const menuAnchor = event.currentTarget.getBoundingClientRect();

        this.setState({
            menuAnchor,
            selectedEmployee: employee
        });
    };


    handleEmployeeSelect = () => {
        const {selectedEmployee} = this.state;
        this.setState({menuAnchor: null});

        this.props.onSelect(selectedEmployee.id, true)
    };

    handleColorSave = color => {
        const {selectedEmployee} = this.state;

        typeof color === "object" ? selectedEmployee.color = color.hex : selectedEmployee.color = color;

        firebase.firestore().collection('employees').doc(selectedEmployee.id).set(selectedEmployee);

        this.setState({
            colorDialog: false
        });
    };


    render() {
        const {employees, selectedEmployees} = this.props;
        const {menuAnchor} = this.state;
        const menuOpen = Boolean(menuAnchor);

        return (
            <div style={{paddingLeft: 20, paddingRight: 20}}>
                {employees && employees.map(employee => (
                    <div style={{display: 'flex', flexDirection: 'row'}} key={employee.id}>
                        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
                            <Checkbox
                                checked={selectedEmployees.includes(employee.id)}
                                onChange={() => this.props.onSelect(employee.id)}
                                style={{
                                    color: employee.color ? employee.color : "#ccc"
                                }}
                            />
                            <p style={{paddingTop: 17}}>{employee.name}</p>
                        </div>

                        <div style={{display: 'flex', flexGrow:1, justifyContent: 'flex-end'}}>
                            <IconButton onClick={event => this.handleMenu(event, employee)}>
                                <MoreVert />
                            </IconButton>
                        </div>
                    </div>
                ))}

                {menuOpen &&
                    <ClickAwayListener onClickAway={() => this.setState({menuAnchor: null})}>
                        <div style={{
                            position: 'absolute',
                            zIndex: 2,
                            backgroundColor: '#FFF',
                            left: menuAnchor.x - 150,
                            top: menuAnchor.y,
                            width: 150,
                            borderWidth: 0.5,
                            borderColor: '#ccc',
                            borderStyle: 'solid',
                            fontSize: 12
                        }}>
                            <MenuItem divider onClick={() => this.handleEmployeeSelect()}>{this.props.t("Show only this")}</MenuItem>
                            <MenuItem onClick={() => this.setState({colorDialog: true, menuAnchor: null})}>{this.props.t("Color")}</MenuItem>
                        </div>
                    </ClickAwayListener>
                }

                {this.state.colorDialog &&
                    <ColorDialog
                        open={this.state.colorDialog}
                        onCancel={() => this.setState({colorDialog: false})}
                        onSave={this.handleColorSave}
                        defaultColor={this.state.selectedEmployee.color ? this.state.selectedEmployee.color : "#ccc" }
                    />
                }
            </div>
        );
    }
}


export default withTranslation()(EmployeeListComponent);
