import React, {Component} from 'react';
import {connect} from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    TextField,
    MenuItem,
    Tabs,
    Tab,
    Typography,
    Divider,
    IconButton
} from '@material-ui/core';

import {Delete} from '@material-ui/icons';

import {withTranslation} from 'react-i18next';

import moment from 'moment-timezone';
import "moment/locale/fi";

import GlobalConfirmationDialog from "../CommonDialogs/GlobalConfirmationDialog";
import TimeButtonComponent from './TimeButtonComponent';
import TaskDialog from './TaskDialog';
import DateDialog from '../CalendarComponents/DateDialog';
import TimeDialog from '../CalendarComponents/TimeDialog';
import ImageDialog from '../ImageDialog';
import {changeBillableToString, changeBillableToInt} from "./WorklogHelpers";
import PropTypes from "prop-types";

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: 10 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};


class EditLogDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            selectedLog: {},
            datePicker: false,
            timePicker: false,
            pickField: "",
            value: "",
            tabValue: 0,
            taskDialog: false,
            imageDialog: false,
            dummyUrl: "",
            confirmationDialog: false,
        };
    }

    componentDidMount() {

        let selectedLog = JSON.parse(JSON.stringify(this.props.selectedLog));
        selectedLog.startDT = moment(selectedLog.startDT).toDate();
        selectedLog.endDT = moment(selectedLog.endDT).toDate();

        selectedLog = changeBillableToString(selectedLog);

        this.setState({
            selectedLog: selectedLog,
            dialogOpen: true
        });
    }

    updateLog = (selectedLog) => {

        this.setState({
            selectedLog: selectedLog,
            taskDialog: false
        });
    };

    getEmployee = (id) => {
        const {employees} = this.props;

        const employee = employees.find(item => item.id === id);

        return employee.name
    };

    handleDateChange = (event, field) => {
        const {selectedLog} = this.state;

        const time = moment(selectedLog[field]).format("HH:mm:ss");

        const date = moment(event.selection.startDate).format("YYYY-MM-DD");

        selectedLog[field] = moment(date + 'T' + time).toDate();

        if (field === "startDT") {

            const endTime = moment(selectedLog.endDT).format("HH:mm:ss");

            selectedLog.endDT = moment(date + 'T' + endTime);
        }

        this.setState({
            selectedLog: selectedLog,
            datePicker: false
        });
    };

    handleTimeChange = (event, field) => {
        const {selectedLog} = this.state;

        const duration = selectedLog.endDT - selectedLog.startDT;

        const date = moment(selectedLog.startDT).format("YYYY-MM-DD");

        const time = moment(event).format("HH:mm:ss");

        selectedLog[field] = moment(date + 'T' + time).toDate();

        if (field === "startDT") {

            const endDate = moment(moment(event) + moment(duration)).format("HH:mm:ss");
            selectedLog.endDT = moment(date + 'T' + endDate).toDate();
        }

        if (field === "endDT") {
            const duration = selectedLog.endDT - selectedLog.startDT;
            selectedLog.billing ? selectedLog.billing.billableHours = (duration / 3600000).toFixed(2) : selectedLog.billing = {billableHours: (duration / 3600000).toFixed(2)}
        }

        this.setState({
            selectedLog: selectedLog,
            timePicker: false
        });
    };


    handleChange = (event, field) => {
        const {selectedLog} = this.state;

        if (field === "billableHours") {
            if (!selectedLog.billing) {
                selectedLog.billing = {
                    billableHours: parseInt(event.target.value) * 3600000
                }
            }
            else {
                selectedLog.billing.billableHours = parseInt(event.target.value) * 3600000
            }
        }
        else {

            selectedLog[field] = event.target.value;
        }

        this.setState({
            selectedLog: selectedLog
        });
    };

    handleBillableTimes = (value, field, index) => {
        const {selectedLog} = this.state;

        const regex = /^[0-9]{0,3}[.0-9]{0,3}$/;
        const noSpace = value.replace(/\s+/g, '');



        if (field === "billableHours") {
            if (regex.test(noSpace) || value.length === 0) selectedLog.billing.billableHours = noSpace;
        }
        else {
            if (regex.test(noSpace) || value.length === 0) selectedLog.billing.billableHoursByTask[index].duration = noSpace;
        }

        this.setState({
            selectedLog: selectedLog
        });
    };

    changeMaterialAmount = (event, index) => {
        const {selectedLog} = this.state;

        selectedLog.materials[index].amount = event.target.value;

        this.setState({ selectedLog });
    };

    deleteMaterial = index => {
        const {selectedLog} = this.state;

        selectedLog.materials.splice(index, 1);

        this.setState({ selectedLog });
    };

    deleteImage = index => {
        const selectedLog = changeBillableToInt(this.state.selectedLog);
        const {user} = this.props.user;
        const {worksites} = this.props.worksites;

        const imageRef = firebase.storage().refFromURL(selectedLog.images[index].image_url);

        let worksite = {};
        let imageIndex = null;

        worksites.forEach(site => {
            if (site.customer === selectedLog.customer && site.images) {
                const image = site.images.find(image => image.date === selectedLog.images[index].date);
                if (image) {
                    worksite = site;
                    imageIndex = worksite.images.indexOf(image);
                }
            }
        });


        imageRef.delete()
            .then(() => {
                selectedLog.images.splice(index, 1);
                selectedLog.startDT = moment(selectedLog.startDT).format("YYYYMMDDTHHmmss");
                selectedLog.endDT = moment(selectedLog.endDT).format("YYYYMMDDTHHmmss");
                console.log(selectedLog)
                firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').doc(selectedLog.id).set(selectedLog)
                    .then(() => {
                        let backToStr = JSON.parse(JSON.stringify(selectedLog));
                        backToStr = changeBillableToString(backToStr);

                        this.setState({
                            selectedLog: backToStr
                        });
                    });

                // Delete reference from worksites aswell
                if (worksite && worksite.images) {
                    worksite.images.splice(imageIndex, 1);
                    firebase.firestore().collection('company').doc(user.companyID).collection('worksites').doc(worksite.id).set(worksite);
                }
            });

    };

    deleteWorklogHandler = () => {
        const {selectedLog} = this.state;
        const {user} = this.props.user;

        firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").doc(selectedLog.id).delete();

        this.props.onClose();
    };

    discardJob = () => {
        const {selectedJob} = this.props;
        const {user, userID} = this.props.user;

        const emptyWorklog = {
            startDT: moment(selectedJob.startDT).format("YYYYMMDDTHHmmss"),
            jobStartDT: moment(selectedJob.startDT).format("YYYYMMDDTHHmmss"),
            jobID: selectedJob.id,
            status: "Discarded",
            TZ: selectedJob.TZ,
            employee: [userID]
        };

        firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").add(emptyWorklog)
            .then(() => {
                this.props.onClose();
            });
    };

    saveHandler = () => {
        const selectedLog = changeBillableToInt(this.state.selectedLog);
        const {user} = this.props.user;

        selectedLog.startDT = moment(selectedLog.startDT).format('YYYYMMDDTHHmmss');
        selectedLog.endDT = moment(selectedLog.endDT).format('YYYYMMDDTHHmmss');

        if (!selectedLog.billing) {
            selectedLog.billing = {
                billableHours: moment(selectedLog.endDT) - moment(selectedLog.startDT)
            }
        }

        if (selectedLog.id) {
            firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').doc(selectedLog.id).set(selectedLog);
        }
        else {
            const docRef = firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').doc();
            firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').doc(docRef.id).set(selectedLog);

        }

        this.props.onClose();
    };

    render() {
        const {selectedLog} = this.state;
        const {t} = this.props;
        return (
            <div>
                {this.state.dialogOpen &&
                    <Dialog
                        open={this.state.dialogOpen}
                        onClose={() => this.props.onClose()}
                        maxWidth="sm"
                        fullWidth
                    >
                        {this.props.editMode && <DialogTitle>{t("Edit worklog")}</DialogTitle>}
                        {!this.props.editMode && <DialogTitle>{t("Add worklog")}</DialogTitle>}
                        <DialogContent>

                            <div>
                                <Button onClick={() => this.setState({value: selectedLog.startDT, pickField: "startDT", datePicker: true})}>
                                    {moment(selectedLog.startDT).locale("fi").format('L')}
                                </Button>
                                <Button onClick={() => this.setState({value: selectedLog.startDT, pickField: "startDT", timePicker: true})}>
                                    {moment(selectedLog.startDT).format('LT')}
                                </Button>

                                {" - "}

                                <Button onClick={() => this.setState({value: selectedLog.endDT, pickField: "endDT", datePicker: true})}>
                                    {moment(selectedLog.endDT).locale("fi").format('L')}
                                </Button>

                                <Button onClick={() => this.setState({value: selectedLog.endDT, pickField: "endDT", timePicker: true})}>
                                    {moment(selectedLog.endDT).format('LT')}
                                </Button>
                            </div>

                            <Divider style={{width: '57%', backgroundColor: '#1770B2'}} />
                            <br/>

                            <TextField
                                variant="outlined"
                                label={t("Status")}
                                style={{
                                    width: '46%',
                                    marginLeft: 3
                                }}
                                InputProps={{
                                    style: {
                                        height: 40
                                    }
                                }}
                                value={selectedLog.status || ""}
                                onChange={event => this.handleChange(event, 'status')}
                                select
                            >
                                <MenuItem value="Finished" key="finished">{t("Finished")}</MenuItem>
                                <MenuItem value="Submitted" key="submitted">{t("Submitted")}</MenuItem>
                                <MenuItem value="Confirmed" key="confirmed">{t("Confirmed")}</MenuItem>
                                <MenuItem value="Billed" key="billed">{t("Billed")}</MenuItem>
                                <MenuItem value="Archived" key="archived">{t("Archived")}</MenuItem>
                                <MenuItem value="Cancelled" key="cancelled">{t("Cancelled")}</MenuItem>
                            </TextField>

                            <br/>

                            {/* Start and end Address */}
                            <div style={{marginTop: 10, width: '46%', display: 'flex', justifyContent: 'space-between'}}>
                                <p style={{fontSize: 14, margin: 0, paddingLeft: 5}}>{t("Start address")}:</p>
                                <p style={{fontSize: 14, paddingRight: 13, margin: 0}}>{selectedLog.startAddress}</p>
                            </div>

                            <br/>

                            <div style={{width: '46%', display: 'flex', justifyContent: 'space-between'}}>
                                <p style={{fontSize: 14, margin: 0, paddingLeft: 5}}>{t("End address")}:</p>
                                <p style={{fontSize: 14, paddingRight: 13, margin: 0}}>{selectedLog.endAddress}</p>
                            </div>



                            <br/>

                            <div style={{width: '100%',}}>
                                <p style={{fontSize: 15, fontWeight: 'bold', padding: 0, marginTop: 10, marginBottom: 10}}>{t("Employees")}</p>
                                <div style={{display: 'flex'}}>
                                {selectedLog.employee && selectedLog.employee.map((employee, index) => (
                                    <p style={{marginLeft: index > 0 ? 10 : 0, fontSize: 14, marginTop: 0, marginBottom: 0, paddingTop: 0, paddingBottom: 0}} key={employee}>{this.getEmployee(employee)}</p>
                                ))}
                                </div>
                            </div>

                            <br/>

                            <TextField
                                variant="outlined"
                                InputProps={{
                                    style: { height: 40 }
                                }}
                                InputLabelProps={{
                                    style: {
                                        margin:0,
                                        padding: 0
                                    }
                                }}
                                style={{ width: '100%'}}
                                placeholder={t("additionalDescription")}
                                value={selectedLog.additionalDescription}
                                onChange={event => {this.handleChange(event, "additionalDescription")}}
                            />

                            <br/>


                            <div style={{width: '100%', marginTop: 10, backgroundColor: '#1770B2', borderRadius: 4}}>
                                <Tabs
                                    value={this.state.tabValue}
                                    style={{height: 40, color: '#FFF'}}
                                    TabIndicatorProps={{
                                        style: {backgroundColor: '#FFF'}
                                    }}
                                    variant={"fullWidth"}
                                    onChange={(event, value) => this.setState({tabValue: value}) }
                                >
                                    <Tab label={t("Time")} />
                                    <Tab label={t("Materials")}/>
                                    <Tab label={t("Images")}/>
                                </Tabs>
                            </div>

                            <br/>
                            <div style={{width: '100%', overflow: 'auto', height: 200}}>
                                {this.state.tabValue === 0 &&
                                    <TabContainer>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '60%'}}>
                                            <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
                                                <p style={{margin: 0, paddingTop: 3, marginRight: 10}}>{t("Billable hours")}: </p>
                                                <TimeButtonComponent
                                                    value={selectedLog.billing.billableHours}
                                                    onChange={this.handleBillableTimes}
                                                    field="billableHours"
                                                />
                                            </div>
                                            <div className="task-list-item-container">
                                                <p style={{marginBottom: 0}}>{t("By task")}</p>
                                                <p className="p-button" onClick={() => this.setState({taskDialog: true})}>{t("ADD")}</p>
                                            </div>
                                            <Divider style={{width: '100%', backgroundColor: '#1770B2'}} />

                                            {selectedLog.billing && selectedLog.billing.billableHoursByTask && selectedLog.billing.billableHoursByTask.map((item, index) => (
                                                <div key={index} style={{display: 'flex', width: '100%', justifyContent: 'space-between', marginTop: 10}}>
                                                    <p style={{margin: 0, paddingTop: 3, marginRight: 10}}>{`${item.name}: `}</p>
                                                    <TimeButtonComponent
                                                        value={item.duration}
                                                        onChange={this.handleBillableTimes}
                                                        field="billableHoursByTask"
                                                        index={index}
                                                    />
                                                </div>
                                            ))}

                                            {selectedLog.billing && !selectedLog.billing.billableHoursByTask &&
                                                <p style={{fontWeight: 'bold'}}>{t("Billable_missing")}</p>
                                            }
                                        </div>


                                    </TabContainer>
                                }

                                {this.state.tabValue === 1 &&
                                    <TabContainer>
                                        <div style={{display: 'flex', justifyContent: 'space-between', width: '70%', paddingLeft: 10, paddingRight: 10, borderBottomWidth: 1, borderBottomColor: "#1770B2", borderBottomStyle: "solid"}}>
                                            <p style={{width: '40%'}}>{t("Name")}</p>
                                            <p style={{width: '20%', textAlign: 'center'}}>{t("Unit")}</p>
                                            <p style={{width: '20%', textAlign: 'center'}}>{t("Amount")}</p>
                                            <p className="p-button" onClick={() => this.setState({taskDialog: true})}>{t("ADD")}</p>
                                        </div>

                                        {selectedLog.materials && selectedLog.materials.map((material, index) => (
                                            <div style={{display: 'flex', justifyContent: 'space-between', width: '70%', paddingLeft: 10}} key={material.name}>
                                                <p style={{width: '40%'}}>{material.name}</p>
                                                <p style={{width: '20%', textAlign: 'center'}}>{material.unit}</p>
                                                <TextField
                                                    value={selectedLog.materials[index].amount}
                                                    onChange={event => this.changeMaterialAmount(event, index)}
                                                    style={{
                                                        width: '20%',
                                                        marginTop: 8
                                                    }}
                                                    InputProps={{
                                                        disableUnderline: true,
                                                    }}
                                                    inputProps={{
                                                        style: {
                                                            textAlign: 'center',
                                                        }
                                                    }}
                                                />
                                                <IconButton style={{height: 40, width: 40}} onClick={() => this.deleteMaterial(index)}>
                                                    <Delete style={{fontSize: 20}}/>
                                                </IconButton>
                                            </div>

                                        ))}
                                    </TabContainer>
                                }

                                {this.state.tabValue === 2 &&
                                    <TabContainer>
                                        <div style={{width: '80%', display: 'flex', borderBottomColor: '#1770B2', borderBottomWidth: 1, borderBottomStyle: "solid", marginBottom: 10}}>
                                            <p style={{width: '30%'}}>{""}</p>
                                            <p style={{width: '30%', textAlign: 'center'}}>{t("Category")}</p>
                                            <p style={{width: '30%', textAlign: 'center'}}>{t("Description")}</p>
                                            <p style={{width: '10%'}}>{""}</p>
                                        </div>
                                        {selectedLog.images && selectedLog.images.map((image, index) => (
                                            <div key={image.description + index} style={{width: '80%', display: 'flex'}}>
                                                <div style={{width: '30%'}} onClick={() => this.setState({imageDialog: true, dummyUrl: image.image_url})}>
                                                    <img src={image.image_url} style={{width: 50, height: 50}} alt={image.description}/>
                                                </div>
                                                <p style={{width: '30%', textAlign: 'center'}}>{image.category}</p>
                                                <p style={{width: '30%', textAlign: 'center'}}>{image.description}</p>
                                                <IconButton style={{height: 40, width: 40, marginTop: 2}} onClick={() => this.deleteImage(index)}>
                                                    <Delete style={{fontSize: 20}}/>
                                                </IconButton>
                                            </div>
                                        ))}
                                    </TabContainer>
                                }
                            </div>


                        </DialogContent>
                        <DialogActions style={{ display: 'flex', justifyContent: 'flex-start'}}>
                            {selectedLog.id &&
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => this.setState({ confirmationDialog: true })}
                                    style={{marginLeft: 20}}
                                >
                                    {t("Delete")}
                                </Button>
                            }

                            {!selectedLog.id &&
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => this.setState({ confirmationDialog: true })}
                                    style={{marginLeft: 20}}
                                >
                                    {t("DeleteJob")}
                                </Button>
                            }

                            <div style={{display: 'flex', flexGrow: 1, justifyContent: 'flex-end'}}>
                                <Button
                                    variant="outlined"
                                    onClick={() => this.props.onClose()}
                                    style={{ marginRight: 10}}
                                >
                                    {t("Close")}
                                </Button>

                                <Button
                                    variant="outlined"
                                    onClick={() => this.saveHandler()}
                                    style={{marginRight: 20}}
                                    color="primary"
                                >
                                    {(this.props.editMode ? t("Save") : t("Add"))}
                                </Button>
                            </div>

                        </DialogActions>
                    </Dialog>
                }
                {this.state.datePicker &&
                    <DateDialog
                        selectType="date"
                        onChange={event => this.handleDateChange(event, this.state.pickField)}
                        onClose={() => this.setState({datePicker: false})}
                        value={this.state.value}
                    />
                }

                {this.state.timePicker &&
                    <TimeDialog
                        onChange={event => this.handleTimeChange(event, this.state.pickField)}
                        onClose={() => this.setState({timePicker: false})}
                        value={this.state.value}
                    />
                }

                {this.state.taskDialog &&
                    <TaskDialog
                        open={this.state.taskDialog}
                        onClose={() => this.setState({taskDialog: false})}
                        selectedLog={this.state.selectedLog}
                        selectedJob={this.props.selectedJob}
                        models={this.props.models}
                        customers={this.props.customers}
                        addTasks={this.updateLog}
                    />
                }

                {this.state.imageDialog &&
                    <ImageDialog
                        open={this.state.imageDialog}
                        onClose={() => this.setState({imageDialog: false})}
                        image={this.state.dummyUrl}
                    />
                }

                <GlobalConfirmationDialog
                    open={this.state.confirmationDialog}
                    onClose={() => this.setState({ confirmationDialog: false })}
                    onConfirm={() => this.setState({ confirmationDialog: false}, () => selectedLog.id ? this.deleteWorklogHandler() : this.discardJob())}
                    title={t("Attention")}
                    message={selectedLog.id ? `${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DeleteActionWorklog")}` : `${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DiscardActionJob")}?`}
                />
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    user: state.user,
    worksites: state.worksites,
});

export default connect(mapStateToProps, {})(withTranslation()(EditLogDialog));
