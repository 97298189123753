import React, {Component} from 'react';

import "./RecipientsMenu.css";

class RecipientsMenu extends Component {

    render() {
        const {anchor} = this.props;
        return (
            <div className="recipientsMenu"
                 style={{top: (anchor.top + anchor.height) + 5, left: anchor.left - (100 - anchor.width / 2)}}>
                <div className="recipientsMenu-container">
                    <div className="recipientsMenu-notch">

                    </div>

                    <div className="recipientsMenu-content">
                        {this.props.children}
                    </div>
                </div>

            </div>
        );
    }
}

export default RecipientsMenu;
