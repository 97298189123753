import {GET_USER, USER_LOADING, COLLAPSE_MENU, RESET_USER} from "../actions/types";

const initialState = {
    user: {},
    userID: "",
    loading: true,
    menu: true
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                user: action.payload,
                userID: action.id,
                loading: false
            };
        case RESET_USER:
            return {
                user: {},
                userID: "",
                loading: true
            };
        case USER_LOADING:
            return {
                ...state,
                loading: true
            };
        case COLLAPSE_MENU:
            console.log(state.menu);
            return {
                ...state,
                menu: !state.menu
            };
        default:
            return state;
    }
}