export default {
    translation: {
        "Mobility": "Mobility",
        "Sign in": "Sign in",
        "Register": "Register",
        "Dashboard": "Dashboard",
        "Management": "Management",
        "Calendar": "Calendar",
        "Jobcards": "Jobcards",
        "Worklogs": "Worklogs",
        "Schedule": "Schedule",
        "Billing": "Billing",
        "Create bills": "Create bills",
        "Reports": "Reports",
        "Created reports": "Created",
        "Time reports": "Time reports",
        "Bill reports": "Bill report",
        "Customer report": "Customer",
        "Customer report period": "Customer report for period: ",
        "Employee report period": "Employee report",
        "Employee report worktime": "Employee worktime report",
        "Weeks": "Weeks",
        "Week": "Week",
        "Registry": "Registry",
        "Job models": "Job models",
        "Job templates": "Job templates",
        "Customers": "Customers",
        "Employees": "Employees",
        "Products": "Products",
        "Key": "Key",
        "Keys": "Keys",
        "Settings": "Settings",
        "Log out": "Log out",
        "Audit": "Audit logs",
        "Created bills": "Created bills",
        "CRM": "CRM",
        "SpotOn": "Drives",
        "Recipients": "Recipients",
        //Nappulat + textFieldit + random
        "Search": "Search",
        "Show": "Show",
        "Sort": "Sort",
        "Add new": "Add new",
        "Toggle select": "Toggle select",
        "Import": "Import",
        "Active": "Activate",
        "Active_sub": "Active",
        "Inactive": "Inactive",
        "Inactive_sub": "Inactive",
        "Delete": "Delete",
        "All": "All",
        "Edit": "Edit",
        "Back": "Back",
        "Add": "Add",
        "Worksites": "Worksites",
        "Home": "Home",
        "Work": "Work",
        "Phone": "Phone (work/home)",
        "Phone Work Short": " (work)",
        "Phone Home Short": " (home)",
        "Email": "Email",
        "Default": "Default",
        "Yes": "Yes",
        "No": "No",
        "Save": "Save",
        "Cancel": "Cancel",
        "Reset": "Reset",
        "Confirm": "Confirm",
        "Close": "Close",
        // Customer Editor (almost everything is just fields)
        "Name": "Name",
        "Street address": "Street address",
        "Street address ext": "Address ext",
        "City": "City",
        "Post code": "Post code",
        "Country": "Country",
        "Website": "Web-Website",
        "Additional info": "Additional info",
        "Customer number": "Customer number",
        "Add customer": "Add customer",
        "Edit customer": "Edit customer",
        "Use_worksite_ce": "Add worksite with billing information",
        "Add_worksite_ce": "Add seperate worksite",
        "TimeToPay": "Time to pay / days",
        "BillingMethod": "Billing method",
        "CustomerReference": "Customer reference",
        "OurReference": "Our reference",
        "OperatorIdentifier": "Operator identifier",
        "E-InvoiceAddress": "E-invoice address",
        "ElectricAPIX": "E-invoice / APIX",
        "BillingDetails": "Billing details",
        "CompanyName": "Company name",
        "PrivateCustomer": "Private customer",
        "CompanyCustomer": "Company customer",
        "References": "References",
        "Y": "Y",
        "N": "N",
        "Actives": "Active",
        "Deactivated": "Deactivated",
        "ImportingCustomers": "Importing customers will take about",
        "WorksiteCoord": "Getting coordinates for worksites",
        "Customer_bill_delivery_method": "electronic bill delivery method",
        "Customer_bill_delivery_method_einvoice": "eBill or eInvoice",
        "Customer_bill_delivery_method_netposti": "NetPosti",
        "Customer_bill_delivery_method_email": "Email bill",
        "Customer_bill_delivery_method_confirmed_email": "Confirmed email bill",
        "Customer_bill_delivery_method_paper_bill": "Paper bill",

        //Employee Editor
        "Add employee": "Add employee",
        "Edit employee": "Edit employee",
        // Worksite / dialog / customer editor fields (Just fields basically)
        "New worksite": "New worksite",
        "Edit worksite": "Edit worksite",
        "Worksite name": "Name",
        "Worksite number": "Number",
        "Worksite address": "Address",
        "Instructions": "Instructions",
        // Worklogs
        "Search customers": "Search customers",
        "Search employees": "Search employees",
        "Status": "Status",
        "notReported": "Not reported",
        "all": "All",
        "Not reported": "Not reported",
        "Cancelled": "Cancelled",
        "Ongoing": "Ongoing",
        "Finished": "Finished",
        "Submitted": "Submitted",
        "Confirmed": "Confirmed",
        "Billed": "Billed",
        "Archived": "Archived",
        "Select": "Select",
        "Customer": "Customer",
        "Worksite": "Worksite",
        "Employee": "Employee",
        "Date": "Date",
        "Time": "Log work",
        "Total hours": "Total Hours",
        "Billable hours": "Billable Hours",
        "Billable per task": "Add time/task",
        "Check result": "Check result",
        "No_Customer": "No customer",
        "Worklogs count": "Number of worklogs",
        "Worklog total duration": "Total worktime",
        "Worklog total billable": "Total billable / payable time",
        "Scheduled": "Scheduled at",
        "Work time": "Work time",
        "Worklog previous status": "Status before billing",


        //Worklog checks
        "Billable sums mismatch": "Sums Mismatch",
        "Billable time per task missing": "Missing task",
        "Billable task unit missing": "No duration unit",
        "Billable task unit without duration": "No duration",
        "No employee": "No employee",
        "No_task_selected":"no task",
        
        //
        "Change status": "Change status",
        "Number_of_selected": "Number of selected",
        "Change_message": "Change all selected to",
        "Edit worklog": "Edit worklog",
        "Add worklog": "Add worklog",
        "Materials": "Materials",
        "Assigned_emp": "Assigned employee(s)",
        "By task": "By task",
        "Billable_missing": "Billable time per task is missing",
        "Customer template": "Customer template",
        "No template": "No template",
        "Add_task_material": "Add task / material",
        "ADD": "ADD",
        "Start address": "Start address",
        "End address": "End address",
        "Images": "Images",
        "additionalDescription": "Additional description",
        "Show_cancelled": "Show cancelled",
        "DeleteJob": "Delete job",
        "Saved_successfully": "Saved successfully",
        "Worklogs count": "Number of worklogs",
        "Worklog total time": "Total time",
        "Worklog total billable": "Total billable time",
        "Customer_name": "Customer name:",
        "Worksite_name": "Worksite:",
        "Worksite_address": "Worksite address:",

        //WorkDay
        "Employee_name": "Employee",
        "WorkDays": "Workdays",
        "Employee": "Employee",
        "Date": "Date",
        "DayStartTime": "Day start time",
        "DayEndTime": "Day end time",
        "DayStartLocation": "Start location",
        "DayEndLocation": "End location",
        "Pauses summary": "Pauses (count (min))",
        "Work time": "Worktime",
        "Billable hours": "Billable time",
        "Billable time": "Billable time",
        "Payable time": "Payable time",
        "End": "End",
        "Total time": "Total time",
        "Pauses": "Pauses", 
        "Normal time": "Normal time", 
        "Evening time": "Evening time",
        "Night time": "Night time",
        "Saturday": "Saturday",
        "Sunday":"Sunday",


        // Billing
        "Add_bill": "New bill",
        "Template": "Template",
        "Task": "Task",
        "Product": "Product",
        "Unit": "Unit",
        "Bill": "Bill",
        "No task": "No billable product",
        "Amount": "Amount",
        "Create": "Create",
        "Select product": "Select product",
        "Search product": "Select product",
        "All_products": "Kaikki tuotteet",
        "BillsSavedSuccess": "Bills created succesfully",
        "BillsSavedFailure": "Bills creation failed",
        "BillsGenerating": "Generating bills",
        "Bills count": "Number of bills",
        "Bills sum": "Sum of bills",
        "Bill_date": "Bill date",
        "Billable cancellation": "Late cancellation or other charged cancellation",
        "Billable hours not found": "Could not find billable hours. Notice, that the worklogs need to be in Confirmed -state",
        "errCreateBillProductRow": "Error in bill row creation",
        "onCustomer": "on customer",
        "errCreateBillProductRow": "Error in bill row creation",
        "errProduct not found": "Product not found",

        //reports
        "GoToReportList": "Reports list",
        "Filters": "Filters",
        "Enter search text": "Enter search text",
        "Enter search info text": "press Enter to search",
        "More Filters Info":"More filters at column headers",
        // TimeReports
        "Report type": "Report type",
        "Select type": "Select type",
        "Period": "Period",
        "Worktime": "Worktime",
        "Approval": "Approval",
        "Select period": "Select period ",
        "Week": "Week",
        "Error_date": "Please select date range",
        "Send to": "Send to",
        "Pdf name": "Pdf name",
        "Email title": "Email title",
        "Message": "Message",
        "Send": "Send",
        "Work time": "Work time",
        "Hour": "Hour",
        "Customer": "Customer",
        "Select range": "Select range",
        "ReportDescription": "Report row description",
        "FromTasks": "From task names",
        "FromDescription": "From worklog description",
        "Select worksite": "Select worksite",
        "No worksite": "No worksite",
        "Select period": "Select period",
        "Tasks": "Tasks",
        "ByBillableTime": "By billable time",
        "ByExactTime": "By exact time",
        "Unreported task": "Unreported task",

        //TODO: TRanslate!!

        "Report advice some hours not submitted": "Huom! Osaa jakson töistä ei ole toimitettu. He eivät sisälly raporttiin",
        "Report advice hours submitted": "Huomaathan että raportoitavien töiden on oltava toimitettu tilassa",
        "Report advice hours confirmed": "Huomaathan että raportoitavien töiden on oltava Hyväksytty -tilassa",

        "Billing advice hours confirmed": "Huomaathan että laskutettavien töiden on oltava Hyväksytty -tilassa",
        "Billing advice some hours not confirmed": "Huom! Osaa jakson töistä ei ole vahvistettu. He eivät sisälly laskutukseen",


        // BillReports
        "Period bill report": "Bill report",
        "Annual ktv bill report": "Household tax deductions",
        "Bill amount": "Total",
        "Bill vat": "VAT",
        "Bill amount without vat": "Amount",
        "Bill work charge amount": "Labor costs",
        "KTV material fees amount": "Materials",
        "KTV billing fees amount": "Billing fees",
        "KTV report period": "Household tax deductions period",

        "KTV Total": "Total amount:",
        "KTV totalBillingFees": "Billing and cargo fees:",
        "KTV totalMaterialFees": "Materials:",
        "KTV totalLaborFees": "Deductable labor costs in total:",

        //Period bill
        "Bill Recipient": "Recipient",
        "Bill status": "Status",
        "Bill Total": "Bill Total:",

        "Bill vat total": "Total VAT:",
        "Bill total amount without vat": "Total without VAT:",

        "Send report": "Send",
        "Send eSign": "Send eSign",
        "Preview report": "Preview",
        "Report actions": "Actions",
        "Report mass actions": "Mass-actions",
        "Change recipients": "Change recipients",
        "Selected recipients": "Selected recipients",

        "Reports SendToDefault": "You are sending all selected reports to default addess",
        "Reports SendTo": "You are sending all selected reports",

        // report types

        "eSigned": "Electronically signed",

        "Open in new tab": "Open in new tab",
        "Open in new tab tooltip": "Reposrt will also be saved and listed in reports listing",


        "DEFAULT_REPORT_EMAIL_SUBJECT": "Report",
        "DEFAULT_REPORT_EMAIL_MESSAGE": "Dear recipient, you can fing your  report as an attachment.",

        "HOUR_WAGE": "Hour report",
        "HOUR_WAGE_emailSubject": "Hour wage report",
        "HOUR_WAGE_emailMessage": "Dear recipient, you can find your report as an attachment.",

        "PERIOD_FIXED": "Period report",
        "PERIOD_FIXED_emailSubject": "Period report",
        "PERIOD_FIXED_emailMessage": "Dear recipient, you can find your period report as an attachment",

        "WORKTIME_PERIOD": "Worktime report",
        "WORKTIME_PERIOD_emailSubject": "Worktime report",
        "WORKTIME_PERIOD_emailMessage": "Dear recipient, you can find your worktime report as an attachment",


        "CUSTOMER_TIME": "Customer time report",
        "CUSTOMER_TIME_emailSubject": "Customer time report",
        "CUSTOMER_TIME_emailMessage": "Dear recipient, you can find your Customer time report as an attachment",

        "APPROVAL": "Approval report",
        "APPROVAL_emailSubject": "Approval report",
        "APPROVAL_emailMessage": "Dear recipient, you can find your Approval report as an attachment",

        "KTV": "Householding bill report",
        "KTV_emailSubject": "Householding bill report",
        "KTV_emailMessage": "Dear recipient, you can find your annual bill-report as an attachment",

        "WORKLIST": "Workshiftlist",
        "WORKLIST_emailSubject": "Workshifts",
        "WORKLIST_emailMessage": "Dear recipient, you can find your workshifts report as an attachment",

        "Report page header title": "Page:",
        "Report page Company_id title": "Comapny ID: ",
       

        //Workshifts
        "Workshifts": "Työvuorot",
        "Workshift_weeks": "Workshift weeks:",
        "Workshift report created": "Created: ",
        "Workshift_report_partner_in_short": "w/",
        "Workdays count":"Number of workdays",

        //JobModels
        "Model name": "Model name",
        "Notes": "Notes",
        "Add row": "Add row",
        "Delete model": "Delete model",
        "Product number": "Product number",
        "Invoice text": "Invoice text",
        "Description": "Description",
        "Edit tasks": "Edit tasks",
        "Task_hint": "HINT: add tasks by pressing Enter",
        "Add task": "Add task",
        "Require material confirmation": "Require material confirmation",
        "Require": "Require",
        "DefaultAmount": "Default amount",
        // JobTemplates
        "Copy from model": "Copy from model",
        "Select customers": "Select customers",
        "Template name": "Template name",
        "Delete template": "Delete template",
        "Work number": "Work number",
        //Products
        "Billable": "Billable",
        "Generate bill": "Include in hour bill generation",
        "Services": "Services",
        "Add product": "Add product",
        "Edit product": "Edit product",
        "Buy in price": "Buy in price",
        "Sell price": "Sell price",
        "VAT": "VAT",
        "TODO list": "TODO list",
        "Service": "Service",
        "Material": "Material",
        "Type": "Type",
        "Checkpoint": "Checkpoint",
        "Generate": "Generate",
        "Print QR code": "Print QR code",
        "Title_opt": "Title (Optional)",
        "Msg_opt": "Text (Optional)",
        "Travel": "Travel",
        "ProductNumber_short": "Product#",
        "ProductActive": "Active",
        // KEys
        "Number": "Number",
        "Holder": "Holder",
        "Received": "Received",
        "Returned": "Returned",
        "Info": "Info",
        "Locker": "Locker",
        // Employees / users
        "Subcontractors": "Subcontractors",
        "Subcontractor": "Subcontractor",
        "Personal information": "Personal information",
        "First name": "First name",
        "Family name": "Family name",
        "Contact information": "Contact information",
        "Home phone": "Phone (home)",
        "Work phone": "Phone (work)",
        "Company employee": "Company employee",
        "Other": "Other",
        "Company ID": "Company ID",
        // Settings and what not
        "Settings work periods": "Work time periods",
        "Settings period advice text": "Työn jaksoraportoinnissa käytettävä työajan tasoittumis jakso jonka puitteissa työaika ja ylityöt lasketaan. Jakson pituus voi olla ",
        "Settings period advice text cont": "Valitse haluamasi pituinen aloitusjatko kalenterista. Järjestelmä toistaa valitun pituista jaksoa johon raportointi perustuu.",
        "Start wk": "Start wk",
        "End wk": "End wk",
        "Language": "Language",
        "Finnish": "Finnish",
        "English": "English",
        "setting_week": "Week",
        "setting_weeks": "Weeks",
        "Work_deadline": "Work confirmation deadline",
        "Management start": "Management start",
        "Default_emp": "Default max employees per job",
        "Billable time rounding": "Billable time rounding",
        "starting_15": "Starting 15min",
        "starting_30": "Starting 30min",
        "starting_1": "Starting 1h",
        "Bank name": "Bank name",
        "Account number": "Account number",
        "Bank Identification": "Bank Identification Number",
        "Vehicles": "Vehicles",
        "plate number": "Plate number",
        "make": "Make",
        "model": "Model",
        //Calendar
        "Today": "Today",
        "Next_c": "Next",
        "Prev_c": "Prev",
        "Show only this": "Show only this",
        "Color": "Color",
        "all-day": "all-day",
        //CancelDialog
        "Cancel job": "Cancel job",
        "Reason": "Reason",
        "Billing amount": "Billing amount",
        "Own notes": "Own notes",
        "No billing": "No billing",
        "Every": "Every",
        "day": "day",
        "days": "days",
        "week": "week",
        "weeks": "weeks",
        "month": "month",
        "months": "months",
        "Does not repeat": "Does not repeat",
        "Worksite": "Worksite",
        "Edit event": "Edit event",
        "Delete event": "Delete event",
        "Edit recurring event": "Edit recurring event",
        "Delete recurring event": "Delete recurring event",
        "One time": "One time",
        "This and onward": "This and onward (keep future changes)",
        "This and onward override": "This and onward (override future changes)",

        "This and onward": "This and onward ",
        "This and onward preserve incoming": "This and onward (preserve future changes)",
        "This and onward override": "This and onward (override future changes)",
        "Notice_disabled_future_pre": "You have changed this job on ",
        "Notice_disabled_future_post": " and onwards. Changes to this to this job occurrence will be only valid until then.",
        "Notice_changes_in_future": "You have changed this job in the future. Choose how to proceed with onwards changes.",
        "Range": "Range",
        "Delete all events": "Delete all events",
        "Error_dialog_msg": "Please select rule how job will be affected",
        // Fullscreen dialog
        "Edit job": "Edit job",
        "Add job": "Add job",
        "Repeats": "Repeats",
        "Daily": "Daily",
        "Weekly on": "Weekly on",
        "Every two weeks": "Every two weeks",
        "Custom": "Custom",
        "Job model": "Job model",
        "Create_template": "Create template for customer",
        "No_customer": "No customer selection",
        "Tasks": "Tasks",
        "Extra work": "Requested work",
        "Extra work tip": "Add requested tasks by pressing enter",
        "Title": "Title",
        "Additional info": "Additional info",
        "Repeats every": "Repeats every",
        "Repeat on": "Repeat on",
        "Recurrence_Mo": "M",
        "Recurrence_Tu": "T",
        "Recurrence_We": "W",
        "Recurrence_To": "T",
        "Recurrence_Fr": "F",
        "Recurrence_Sa": "S",
        "Recurrence_Su": "S",
        "Recurrence_End": "End",
        "Recurrecne_Never": "Never",
        "Until": "Until",
        "Repeat_Occ": "After",
        "occurrences": "occurrences",
        "max_employees": "Optimal number of employees",
        "On time start": "Start time agreed",
        "End ontime": "End time agreed",
        "Once impact": "Job changed this time",
        "Onwards impact": "Job changed this time onwards",
        "Range impact": "Job changed during time range",
        "RecurringRuleForwardChange": "Occurrence changed this time onwards",
        "ModifiedBy": "Modifier: ",
        "CreatedBy": "Creator: ",
        //PDFS
        "Generate_PDF": "Generate Report",
        "Monday": "Monday",
        "Tuesday": "Tuesday",
        "Wednesday": "Wednesday",
        "Thursday": "Thursday",
        "Friday": "Friday",
        "Saturday": "Saturday",
        "Sunday": "Sunday",
        "Summary": "Summary",
        "Evening": "Evening",
        "Overtime": "Overtime",
        "Total": "Total",
        "Period summary text": "Period summary",
        "Period evening": "Period evening work",
        "Period overtime": "Period overtime",
        "Period normaltime": "Period normal work",
        "Period total": "Period total time",
        "Hour_PH": "Front page, add summary here",
        "Day_start": "Start",
        "Day_end": "End",
        "Day_duration": "Day duration",
        "Day_pauses": "Pauses/transits",
        "Day_overtime": "Overtime",
        "Day_evening": "Eveningtime",
        "Day_total": "Total worktime",
        "WEEKS": "WEEKS",
        "No_billable_task": "No billable tasks in worklog",
        "Signature": "Signature",
        "Day": "Päivä",
        "Done job": "Done job",
        "Sum": "Sum",
        "Mo": "Mo",
        "Tu": "Tu",
        "We": "We",
        "Th": "Th",
        "Fr": "Fr",
        "Sa": "Sa",
        "Su": "Su",
        // Bills
        "Created": "Created",
        "Customer name": "Customer name",
        "Delivery": "Delivery",
        "Bill date": "Bill date",
        "Sent": "Sent",
        "InQue": "Queued",
        "Bill number": "Bill#",
        "Bill information": "Bill information",
        "Credit_bill": "Credit",
        "Time to pay": "Time to pay",
        "Delivery terms": "Delivery terms",
        "Delivery type": "Delivery type",
        "Your order number": "Your order number",
        "Reference number": "Reference number",
        "Bill date2": "Bill date",
        "Due date": "Due date",
        "Delivery date": "Delivery date",
        "Our reference": "Our reference",
        "Your reference": "Your reference",
        "Currency": "Currency",
        "VAT prices": "VAT prices",
        "Address+AI": "Addresses and additional information",
        "Billing address": "Billing address",
        "Postal code": "Postal code",
        "Address": "Address",
        "Delivery address": "Delivery address",
        "Same+Delivery": "Same as billing address",
        "Bill text": "Bill text",
        "Notice": "Notice",
        "Bill+Product": "Product number",
        "Ordered": "Ordered",
        "Price": "Price",
        "Discount": "Discount",
        "Margin": "Margin",
        "Account": "Account",
        "Vat": "VAT",
        "Added_material": "Added material",
        "Summary_attachments": "Summary and attachments",
        "Freight_charge": "Freight charge",
        "Billing_charge": "Billing charge",
        "Billing_charges": "Billing charges",
        "Billing_charge_depends_on_delivery_method": "Billing charge depends on delivery method",
        "Billing_charge_einvoice": "eInvoice or eBill",
        "Billing_charge_email": "Email invoice",
        "Billing_charge_confirmed_email": "confirmed email invoice",
        "Billing_charge_paper_bill": "Paper invoice",
        "Billing_charge_netposti_bill": "Netpost",
        "Billing_charge_unit": "€ (+VAT)",
        "Billing_charge_info": "Add the default invoicing fee or shipping costs. You may also select invoicing fees independently correspondinf delivery method",
        "Billing_charge_depends_on_delivery_method_info": "Whe sending bills to consumers with electronic invoicing, your customer may select between regular paper bill, eBill and Netposti. This information will be updated automatically after sending bills and will be valid on the following bill. You can also change this fee manually in customer details. Note that it is necessary to send one paper bill in order to activate eBilling.",

        "Bill_discount": "Bill discount (%)",
        "Upload_attachment": "Upload attachment",
        "Show_attachment": "Show attachment",
        "Total_we_vat": "Total without VAT",
        "Payable": "Due",
        "CreateCompensation": "Create compensation",
        "Paid": "Paid",
        "created": "Created",
        "sent": "Sent",
        "paid": "paid",
        "archived": "Archived",
        "Selection": "Selection",
        "SendAttachment": "Send Attachment",
        "SendAttachmentSeparateEmail": "Send Attachment by email",
        "Email / APIX": "Email / APIX",
        "email / apix": "Email / APIX",
        "Manual": "Manual",
        "manual": "manual",
        // Settings
        "Category": "Category",
        "Common": "Common",
        "Job": "Job",
        "Company": "Company",
        "Modules": "Modules",
        "User": "User",
        "Serial numbers": "Serial numbers",
        "Sales invoice": "Sales invoice",
        "Days": "Days",
        "Sending": "Sending",
        "Send method": "Send method (default)",
        "Talenom credentials": "Talenom credentials",
        "Username": "Username",
        "Password": "Password",
        "Texts": "Texts",
        "Email document": "Email document",
        "Sender's name": "Sender's name",
        "Sender": "Sender",
        "Subject": "Subject",
        "Line of business": "Line of business",
        "Main user": "Main user",
        "Management_settings": "Management",
        "Administration": "Administration",
        "MainUser_CB": "Main user",
        "Management_admin": "Management admin",
        "Time_admin": "Time admin",
        "Reports_admin": "ReportComponents admin",
        "Billing_admin": "Billing admin",
        "Keys_admin": "Keys admin",
        "Registry_admin": "Registry admin",
        "Crm_admin": "CRM admin",
        "SpotOn_admin": "Vehicle admin",
        "Mobile application": "Mobile application",
        "Automatic_tracking": "Automatic job logging",
        "Log for partner": "Log time for partner",
        "Add new job": "Add new job",
        "Add_materials": "Add materials",
        "Add_customer": "Add customers",
        "Web application": "Web application",
        "Check own reports": "Check own reports",
        "Edit_worklogs": "Edit worklogs",
        "Manage_bills": "Manage bills",
        "Key module": "Key module",
        "SpotOn module": "Vehicle module",
        "Add_key_system": "Add key to system",
        "Return_key_customer": "Return key to customer",
        "Manage_keys": "Manage keys",
        "Mobile_devices": "Mobile devices",
        "Print": "Print",
        "Payment tracking": "Payment tracking",
        "Payment_tracking_manual": "Manual",
        "Lahirahoitus": "Lahirahoitus",
        "Thirdparty": "Third party",
        "Apix credentials": "Apix credentials",
        "Apix_activation_text": "In order to use electronic channel for sending bills, you need to activate account in APIX and fill the key values below",
        "Apix_activation_text_cont": "Help in activation: info@mobilityworkcloud.com",
        "ApixTransferID": "Apix TransferID",
        "ApixTransferKey": "Apix TransferKey",
        "Accounting": "Accounting",
        "Accountant": "Accountant",
        "Accounting email": "Accounting emailsendMethod",
        "Accounting_info_text": "Add accounting email below in order to send copy of bills to accounting",
        "None": "None",
        "talenom": "Talenom API",
        "netvisor": "Netvisor API",
        "netvisor": "Netvisor API",
        "Talenom": "Talenom API",
        "lahirahoitus": "Lahirahoitus",
        "apix": "eBilling channel (APIX)",
        "apix invoicing": "Electronic invoicing (APIX)",
        "no apix invoicing": "Activate electronic invoicing for more options",
        "print": "Print",
        "email": "Email",
        "Workday start": "Workday start",
        "At the office": "At the office",
        "At first customer": "At first customer",
        "Transfers": "Transfers",
        "Are paid": "Are paid",
        "Are not paid": "Are not paid",
        "View jobs": "View jobs",
        "Create_edit": "Create and edit jobs",
        "Accountant email": "Accountant email",
        "Billing_fees": "Billing fees",
        "Report texts": "Report texts",
        "FreeText": "Free description",
        "Bill_logo": "Bill logo",
        "Bill_logo_instruction": "Browse or drag bill logo (116x116px) here",
        // Tutorials
        // JobModels
        "modelTitle1": "Title 1",
        "modelMsg1": "Text 1",
        "modelTitle2": "Title 2",
        "modelMsg2": "Text 2",
        "modelTitle3": "Title 3",
        "modelMsg3": "Text 3",
        "modelTitle4": "Title 4",
        "modelMsg4": "Text 4",
        "modelTitle5": "Title 5",
        "modelMsg5": "Text 5",
        "modelTitle6": "Done",
        // Tooltips
        "Tooltipl_eInvoice": "E-invoice includes printing and mailing services, e-invoice and netpost",
        //Auditlog
        "Started_work": "started work",
        "Finished_work": "finished work",
        "No_worksite": "No worksite",
        "Delete_work": "deleted worklog",
        "Added_materials": "added material",
        "Changed_task": "changed task",
        //CRM email
        "Title_required": "Title is required",
        "Message_required": "Message field is required",
        "Sender_required": "Sender email address is required",
        "Invalid_email": "Please enter valid email address",
        "Attachment_required": "Attachment name is required",
        "SelectedCustomers_required": "Select atleast one customer",
        "Emails_sent": "Emails sent",
        //ListItem
        "Back": "Back",
        "status": "Status",
        "sendMethod": "Default sending method",
        "Attention": "Attention",
        "ChangeTo": "You are about to change all selected",
        "SendTo": "You are about to send all selected bills",
        "Bills": "Bills",
        "active": "Active",
        "type": "Type",
        "true": "Material",
        "false": "Service",
        "checkpoint": "Checkpoint",
        "travel": "Travel",
        "ProductsOwn": "Products",
        "BillsOwn": "Bills",
        "Addresses": "Addresses",
        "customers": "customers",
        "Complete": "Complete",
        "send": "Send",
        "sendReports": "Send",
        "sendReportsTo": "Send to address...",
        "sendReport...": "Lähetä",
        // Validation
        "CID": "Error while saving",
        "CN_required": "Required field",
        "CN_duplicate": "Duplicate entity",
        "NameRequired": "Required field",
        "InvalidPhone": "Invalid phone number",
        "InvalidEmail": "Invalid email address",
        "WS_required": "Name required",
        "AddressRequired": "Address required",
        "JM_NameRequired": "Model name required",
        "JM_TaskNameRequired": "Taske names required",
        "JM_PnumRequired": "Product numbers required",
        "JM_unitRequired": "Units required",
        "JM_TasksRequired": "Tasks required",
        "JM_Required": "Required field",
        //GlobalDialog
        "ActionConfirmation": "Are you sure you want to",
        "DeleteAction": "delete",
        "CreateAction": "create",
        "DiscardAction": "discard",
        "DeleteActionEmployee": "user",
        "DeleteActionCustomer": "customer",
        "DeleteActionJobModel": "job model",
        "DeleteActionJobTemplate": "job template",
        "DeleteActionKey": "key",
        "DeleteActionProduct": "product",
        "DeleteActionWorklog": "worklog",
        "DiscradActionJob": "job",
        "DeleteActionBill": "bill",
        "CreateActionCompensation": "compensation bill",
        //
        "ActionCannotRollback": "Action cannot be cancelled",
        //errors
        "errProductNotFound": "Product not found",
        "errProductNumber": "Product number: "
    }
}
