import React, { Component } from 'react';

import {
    Button,
    TextField,
    MenuItem,
    IconButton,
    CircularProgress,
    Checkbox,
    Grid,
    Paper,
} from "@material-ui/core";

import {
    Delete
} from "@material-ui/icons";


//import { changeBillableToString, changeBillableToInt } from "./WorklogHelpers";
//import { setModels } from "../actions/modelActions";
//import { setCustomers } from "../actions/customerActions";
import { setEmployees } from "../actions/employeeActions";

import WorklogAC from "../WorkLogComponents/WorklogAC";
import TimeButtonComponent from "../WorkLogComponents/TimeButtonComponent";
import DateDialog from '../JobCalendarComponents/DateDialog';
import TimeDialog from '../JobCalendarComponents/TimePicker'
//import TaskDialog from "./TaskDialog";
import ImageDialog from '../ImageDialog';
import GlobalConfirmationDialog from "../CommonDialogs/GlobalConfirmationDialog";

import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";

//import { default as firebase, firestore } from "firebase";
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';

import moment from "moment-timezone";
import "moment/locale/fi";
import "moment/locale/en-gb";
import MapComponent from '../util/GoogleMap';
import "./WorkdayEditor.css";
import { calculateWorkTime, durationToHHmm, roundWorkTimeToNearestFull } from '../util/TimeUtils';
import { flexibleCompare } from 'fullcalendar';
import { getWorkdayWorklogs, getDayStartAndEndTimeFromWorklogs, findPausesFromWorklogsAndAddToWorkday, calculateEveningTime, calculateNightTime, calculateEveningPauses, calculateNightPauses, calculateDaytimePauses, createEmptyDaysForPeriod } from "../util/WorkDayFunctions";


class WorkdayEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            workday: {},
            datePicker: false,
            timePicker: false,
            taskDialog: false,
            DTValue: "",
            pickField: "",
            statusArray: ["Ongoing", "Finished", "Submitted", "Confirmed", "Archived"],
            searchEmployee: "",
            confirmationDialog: false,
            sortDirection: "asc",
            saving: false,
            unsavedChanges: false,
            job: {},
            dialogAction: "",
        }
    }

    componentDidMount() {
        const { match: { params } } = this.props;
        const { user } = this.props.user;
        //const { customers } = this.props.customers;
        //const { worksites } = this.props.worksites;
        const { employees } = this.props.employees;
        //const { models } = this.props.models;

        console.log("WorkdayEditor params: ", params);

        /* just in case this does not come from props */
        //if (!customers.length > 0) firebase.firestore().collection("customers").where("companyID", "array-contains", user.companyID).get().then(querySnap => this.onQuerySnap(querySnap, "customers"));
        if (!employees.length > 0) firebase.firestore().collection("employees").where("companyID", "==", user.companyID).get().then(querySnap => this.onQuerySnap(querySnap, "employees"));
        //if (!worksites.length > 0) firebase.firestore().collection("company").doc(user.companyID).collection("worksites").get().then(querySnap => this.onQuerySnap(querySnap, "worksites"));
        //if (!models.length > 0) firebase.firestore().collection("company").doc(user.companyID).collection("jobModels").get().then(querySnap => this.onQuerySnap(querySnap, "models"));


        if (params.wdayID && params.emplID) {

            this.unsubscribeWorkDayListener = firebase.firestore().collection('company').doc(user.companyID).collection("workdays").doc(params.emplID)
                .collection("worktimes").doc(params.wdayID).onSnapshot(docSnap => {
                    if (docSnap.exists) {
                        let workday = docSnap.data();
                        workday.id = docSnap.id;
                        workday.employee = params.emplID;

                        console.log("DAY ", workday);
                        if (!workday.status) { //little hack with status if not set by mobile app
                            if (workday.startDT && workday.endDT) {
                                workday.status = "Finished"
                            }
                            if (workday.startDT && !workday.endDT) {
                                workday.status = "Ongoing"
                            }
                        }

                        this.setState({ loading: false, workday });
                    } else {
                        console.log("DAY NOT FOUND")
                    }
                });
        }

        /*
        this.unsubWorklog = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").doc(params.wlID).onSnapshot(docSnap => {
            if (docSnap.exists) {
                let worklog = docSnap.data();
                worklog.id = docSnap.id;

                worklog = changeBillableToString(worklog);
                console.log("WL", worklog);

                const customerWorksites = this.findCustomerWorksites(worklog.customer);

                this.setState({loading: false, worklog, customerWorksites});

                this.getJobByID(worklog.jobID);

            }
        })*/
    }



    /*
    if (params.jobID && !params.wdayID) {
        //this is unreported occurrence, passed job id from url
        // need to calc occurrence again to get proper dates, not just start of recurrence
        firebase.firestore().collection("company").doc(user.companyID).collection("jobs").doc(params.jobID).get()
            .then(doc => {
                if (doc.exists) {
                    let job = doc.data();
                    job.id = doc.id;

                    const worklog = this.generateNewFromJob(job, params.startDT, params.endDT);
                    const customerWorksites = this.findCustomerWorksites(job.customer);

                    this.setState({loading: false, worklog, customerWorksites});
                } else { this.setState({loading: false} ) }
            })
    }*/


    /*
    getJobByID = id => {
        const {user} = this.props.user;
    
        if (id) {
            this.setState({loading: true});
    
            firebase.firestore().collection("company").doc(user.companyID).collection("jobs").doc(id).get()
                .then(doc => {
                    if (doc.exists) {
                        let job = doc.data();
                        job.id = doc.id;
    
                        this.setState({loading: false, job});
                    }
                    else { this.setState({loading: false }) }
                })
        }
    }*/

    /*
    generateNewFromJob = (job, startDT, endDT) => {
        const {user} = this.props.user;
    
        const newLog = {};
    
        newLog.startDT = startDT ? moment(startDT).format('YYYYMMDDTHHmmss') : moment(job.startDT).format('YYYYMMDDTHHmmss');
        newLog.endDT = endDT ? moment(endDT).format('YYYYMMDDTHHmmss') : moment(job.endDT).format('YYYYMMDDTHHmmss');
        newLog.jobStartDT = startDT ? moment(startDT).format('YYYYMMDDTHHmmss') : moment(job.startDT).format('YYYYMMDDTHHmmss');
        newLog.duration = moment(job.endDT) - moment(job.startDT);
        newLog.jobID = job.id;
        newLog.customer = job.customer;
        newLog.TZ = job.TZ;
        newLog.status = "Finished";
    
        if (job.templateID) newLog.templateID = job.templateID;
    
        newLog.billing = {
            billableHours: (newLog.duration / 3600000).toFixed(2)
        };
    
        const empArr = [];
        job.employees && job.employees.forEach(emp => {
            empArr.push(emp.id)
        });
    
        newLog.employee = empArr;
        newLog.generated = true;
        newLog.generatedBy = user.name;
        newLog.generatedDT = moment().format('YYYYMMDDTHHmmss');
        newLog.OS = "web";
    
        console.log("generateNewFromJob: ", newLog);
    
        return newLog;
    };
    */

    componentWillUnmount() {
        this.unsubscribeWorkDayListener && this.unsubscribeWorkDayListener();
    }

    onQuerySnap = (querySnap, dataName) => {
        const dataArr = [];

        querySnap.forEach(doc => {
            const data = doc.data();
            data.id = doc.id;
            if (dataName === "employees") data.name = `${data.firstName} ${data.familyName}`;
            dataArr.push(data);
        });

        if (dataName === "customers") this.props.setCustomers(dataArr);
        if (dataName === "employees") this.props.setEmployees(dataArr);
        if (dataName === "models") this.props.setModels(dataArr);
        //if (dataName === "worksites") this.props.setWorksites(dataArr);
    };

    findCustomerWorksites = (customer) => {
        const { worksites } = this.props.worksites;

        let customerWorksites = [];

        if (customer && worksites) {
            customerWorksites = worksites.filter(site => site.customer === customer);
            console.log("found customerWorksites: ", customerWorksites)
        }
        return customerWorksites;
    }
    x
    getEmployeeName = id => {
        const { employees } = this.props.employees;

        const employee = employees.find(employee => employee.id === id);
        if (employee) return employee.name;
        else return " - ";
    };

    onWorkdayUpdate = workday => {
        this.setState({ workday, taskDialog: false, unsavedChanges: true })
    };

    onDateChange = (event, field) => {
        const { workday } = this.state;

        const time = moment(workday[field]).format("HH:mm:ss");

        const date = moment(event.selection.startDate).format("YYYY-MM-DD");

        workday[field] = moment(`${date}T${time}`).format("YYYYMMDDTHHmmss");

        if (field === "startDT") {
            // if editing DAY start DATE, so match end date to same day at default
            const endTime = moment(workday.endDT).format("HH:mm:ss");
            workday.endDT = moment(`${date}T${endTime}`).format("YYYYMMDDTHHmmss");
        }

        this.setState({ workday, datePicker: false, unsavedChanges: true });
    };

    onTimeChange = (event, field) => {
        const { workday } = { ...this.state.workday };
        let duration = 0;

        if (workday.endDT)
            duration = moment(workday.endDT).diff(workday.startDT, "milliseconds");

        const date = moment(workday[field]).format("YYYY-MM-DD");

        const time = moment(event).format("HH:mm:ss");

        workday[field] = moment(`${date}T${time}`).format("YYYYMMDDTHHmmss");

        if (field === "startDT" && workday.endDT) {
            const newEndTime = moment(event) + moment(duration);
            const endTime = moment(newEndTime).format("HH:mm:ss");
            workday.endDT = moment(`${date}T${endTime}`).format("YYYYMMDDTHHmmss");
        }

        workday.duration = duration;

        //calculate new work time
        const workTime = calculateWorkTime(workday);
        //TODO IF - tsekkaa settingssit että laskteaanko edes ja miten työristyy
        const payableTime = roundWorkTimeToNearestFull(workTime, "15min");
        workday.payableTime = payableTime;

        this.setState({ workday, timePicker: false, unsavedChanges: true })
    };

    onStatusChange = (event) => {
        const { workday } = this.state;
        workday.status = event.target.value;
        this.setState({ workday, unsavedChanges: true });
    };

    /*
    onEmployeeSelect = event => {
        const { worklog } = this.state;
        const { employees } = this.props.employees;

        if (!worklog.employee.includes(event.id)) worklog.employee.push(event.id);
        else {
            const removeIndex = worklog.employee.indexOf(event.id);
            worklog.employee.splice(removeIndex, 1);
        }

        this.setState({ worklog, searchEmployee: "", unsavedChanges: true })
    };*/


    onPayableTimeChange = (value, field, index) => {
        const { workday } = this.state;

        const regex = /^[0-9]{0,3}[.0-9]{0,3}$/;
        const noSpace = value.replace(/\s+/g, '');


        if (field === "payableTime") {
            if (regex.test(noSpace) || value.length === 0) workday.payableTime = noSpace;
        }


        this.setState({ workday, unsavedChanges: true });
    };


    /*
    onTaskDelete = index => {
        const { worklog } = this.state;
        worklog.billing.billableHoursByTask.splice(index, 1);
        this.setState({ worklog, unsavedChanges: true });
    };
    */

    onPauseTimeChange = (value, index) => {
        const { workday } = this.state;

        const regex = /^[0-9]{0,3}[.0-9]{0,3}$/;
        const noSpace = value.replace(/\s+/g, '');

        if (regex.test(noSpace) || value.length === 0) workday.pauses[index].duration = noSpace;

        this.setState({ workday, unsavedChanges: true });
    }

    onPauseDelete = index => {
        const { workday } = this.state;
        workday.pauses.splice(index, 1);
        this.setState({ workday, unsavedChanges: true });
    };


    onAdditionalDescriptionChange = (event) => {
        const { workday } = this.state;
        workday.additionalDescription = event.target.value;
        this.setState({ workday, unsavedChanges: true });
    };

    onMaterialAmountChange = (event, index) => {
        const { workday } = this.state;

        workday.materials[index].amount = event.target.value;

        this.setState({ workday, unsavedChanges: true });
    };

    onMaterialDelete = index => {
        const { workday } = this.state;

        workday.materials.splice(index, 1);

        this.setState({ workday, unsavedChanges: true });
    };

    onWorkdayEnd = () => {
        const { workday } = this.state;
        workday.endDT = moment(moment()).format("YYYYMMDDTHHmmss");
        workday.status = "finished";
        this.setState({ workday, unsavedChanges: true });
    }

    onWorkdayDelete = () => {
        const { workday } = this.state;
        const { user } = this.props.user;

        if (workday.id && workday.employee) {
            firebase.firestore().collection("company").doc(user.companyID).collection("workdays").doc(workday.employee).collection("workdays").doc(workday.id).delete();
            //TODO: then? .catch?
        }

        this.props.history.goBack();
    };

    onWorkdaySave = () => {
        //const worklog = changeBillableToInt(this.state.worklog);
        const { workday } = this.state;
        const { user } = this.props.user;

        this.setState({ saving: true })
        if (workday.id && workday.employee) firebase.firestore().collection("company").doc(user.companyID).collection("workdays").doc(workday.employee).collection("worktimes").doc(workday.id).set(workday)
            .then(() => {
                this.setState({ saving: false, unsavedChanges: false }, this.props.history.goBack())
            });  //TODO: catch errors
        else if (workday.employee) firebase.firestore().collection("company").doc(user.companyID).collection("workdays").doc(workday.employee).collection("worktimes").add(workday)
            .then(() => {
                this.setState({ saving: false, unsavedChanges: false, }, this.props.history.goBack())
            });
        else {
            console.log("TODO ERROR: no employee available")
        }

    };

    deleteImage = index => {
        //const worklog = changeBillableToInt(this.state.worklog);
        const { workday } = this.state;
        const { user } = this.props.user;
        const { worksites } = this.props.worksites;

        const imageRef = firebase.storage().refFromURL(workday.images[index].image_url);

        //let worksite = {};
        //let imageIndex = null;

        /*
        worksites.forEach(site => {
            if (site.customer === worklog.customer && site.images) {
                const image = site.images.find(image => image.date === worklog.images[index].date);
                if (image) {
                    worksite = site;
                    imageIndex = worksite.images.indexOf(image);
                }
            }
        });
        */

        imageRef.delete()
            .then(() => {
                workday.images.splice(index, 1);
                //worklog.startDT = moment(worklog.startDT).format("YYYYMMDDTHHmmss");
                //worklog.endDT = moment(worklog.endDT).format("YYYYMMDDTHHmmss");
                console.log(workday)
                //firebase.firestore().collection('company').doc(user.companyID).collection('worklogs').doc(worklog.id).set(worklog)
                firebase.firestore().collection("company").doc(user.companyID).collection("workdays").doc(workday.employee).collection("worktimes").doc(workday.id).set(workday)
                    .then(() => {
                        let backToStr = JSON.parse(JSON.stringify(workday));
                        //backToStr = changeBillableToString(backToStr);

                        this.setState({
                            workday: backToStr
                        });
                    });

                // Delete reference from worksites aswell
                /*if (worksite && worksite.images) {
                    worksite.images.splice(imageIndex, 1);
                    firebase.firestore().collection('company').doc(user.companyID).collection('worksites').doc(worksite.id).set(worksite);
                }*/
            });

    };

    /*
    worksiteHandler = event => {
        const { worklog } = this.state;

        //console.log("worksiteHandler ", event.target.value);

        //check needed?
        //const {customerWorksites} = this.state;
        //const worksite = customerWorksites.find(site => site.id === event.target.value)
        //if (worksite)  worklog.worksite = event.target.value;

        worklog.worksite = event.target.value;

        this.setState({ worklog, unsavedChanges: true });
    };*/

    checkboxHandler = (value, field, index) => {
        const { workday } = this.state;

        console.log("workday before checkboxHandler ", workday)
        console.log("checkboxHandler ", value, field, index)


        if (field === "pauses") {
            workday[field][index].isPayable = value;

        }

        console.log("worklog after checkboxHandler ", workday)
        this.setState({
            workday: workday,
            unsavedChanges: true
        });

    };

    todoHandler = (value, field) => {
        const { workday } = this.state;

        console.log("workday before todoHandler ", workday)
        console.log("todoHandler ", value, field)

        if (field === "normalTodo") {
            let ind = workday.normalTodo.findIndex(todo => todo === value);
            if (ind != -1) {
                workday.normalTodo.splice(ind, 1);
            } else {
                workday.normalTodo.push(value)
            }
        }

        if (field === "extraTodo") {
            let ind = workday.extraTodo.findIndex(todo => todo === value);
            if (ind != -1) {
                workday.extraTodo.splice(ind, 1);
            } else {
                workday.extraTodo.push(value)
            }
        }


        console.log("workday after todoHandler ", workday)
        this.setState({
            workday: workday,
            unsavedChanges: true
        });

    };


    render() {
        const { loading, workday, saving, unsavedChanges, dialogAction } = this.state;
        const { employees } = this.props.employees;
        //const { customers } = this.props.customers;
        //let { worksites } = this.props.worksites;
        const { t } = this.props;

        //const { customerWorksites } = this.state;

        /*
        if (customerWorksites && customerWorksites.length > 0) {
            worksites = customerWorksites;
        } else {
            const cws = this.findCustomerWorksites(worklog);
            if (cws && cws.length > 0) {
                worksites = customerWorksites;
            }
        }*/

        //const customer = customers.find(customer => customer.id === worklog.customer);
        //const worksite = worksites.find(worksite => worksite.id === worklog.worksite);
        const employee = employees.find(employee => employee.id === workday.employee);

        console.log("workday found ", workday);


        if (loading) return <div></div>
        else return (
            <div className="we-container">
                <div className="we-titlebar">
                    <h3>Työpäivän tiedot</h3>
                </div>

                <div className="we-row-container we-h3">
                    <div className="we-column-container we-3 we-brd">
                        <div className="we-center-item">
                            <div className="we-center-left">{t("Employee_name")}</div>
                            <div className="we-center-right">{employee ? employee.name : ""}</div>
                        </div>


                        <div className="we-center-item">
                            <div className="we-center-left">{t("Employee_email")}</div>
                            <div className="we-center-right">{employee ? employee.email : ""}</div>
                        </div>
                        {/* 
                        <div className="we-center-item">
                            <div className="we-center-left">{t("Employee_number")}</div>
                            <div className="we-center-right"></div>
                        </div>

                        <div className="we-center-item">
                            <div className="we-center-left">{t("Employee_address")}</div>
                            <div className="we-center-right">{employee.address}</div>
                        </div>
                        */
                        }


                    </div>

                    <div className="we-column-container we-3 we-brd">

                        <div className="we-center-item">
                            <div className="we-center-left">
                                {this.props.t("Status")}</div>
                        </div>
                        <div className="we-center-item">

                            <div className="we-center-left">
                                <TextField
                                    variant="outlined"
                                    value={workday.status || ""}
                                    InputProps={{ style: { height: 40 } }}
                                    label={this.props.t("Status")}
                                    onChange={this.onStatusChange}
                                    style={{ width: '50%' }}
                                    //error={workday.status === "" || workday.status === "Select" || !this.state.statusArray.find(workday.status)}
                                    select
                                >
                                    {this.state.statusArray.map(item => (
                                        <MenuItem key={item} value={item}>{this.props.t(item)}</MenuItem>
                                    ))}

                                </TextField>
                            </div>
                        </div>

                        <div className="we-center-item">
                            <div className="we-center-left" >{t("Workday createType")}</div>
                            <div className="we-center-right">{workday.createType ? t(workday.createType) : ''}</div>
                        </div>

                    </div>

                    <div className="we-column-container we-3">

                        <div className="we-additional-info">
                            <p style={{ width: '30%', textAlign: 'left' }}>Last modification time</p>
                            <p style={{
                                width: '30%',
                                textAlign: 'left'
                            }}>{workday.modified && workday.modified.time ? moment(workday.modified.time).locale("fi").format('lll') : '-'}</p>
                        </div>

                        <div className="we-additional-info">
                            <p style={{ width: '30%', textAlign: 'left' }}>Modified by</p>
                            <p style={{
                                width: '30%',
                                textAlign: 'left'
                            }}>{workday.modified && workday.modified.user ? this.getEmployeeName(workday.modified.user) : '-'}</p>
                        </div>

                        <div className="we-additional-info">
                            <p style={{ width: '30%', textAlign: 'left' }}>OS</p>
                            <p style={{ width: '30%', textAlign: 'left' }}>{workday.OS}</p>
                        </div>

                        <div className="we-additional-info">
                            <p style={{ width: '30%', textAlign: 'left' }}>Version</p>
                            <p style={{ width: '30%', textAlign: 'left' }}>{workday.AppVersion}</p>
                        </div>


                    </div>
                </div>

                <div className="we-titlebar">
                    <h3>Päivän tapahtumat</h3>
                </div>

                <div className="we-row-container we-h4">

                    <div className="we-column-container we-2 we-brd">

                        <div style={{
                            width: '80%',
                            display: 'flex',
                            borderBottomColor: '#1770B2',
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            marginBottom: 10
                        }}>
                            <p style={{ width: '100%' }}>{t("Work day")}</p>
                        </div>


                        <div className="wd-workday-info-card">
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                borderBottomColor: '#1770B2',
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid",
                                marginBottom: 10,
                                marginTop: 20,

                            }}>
                                <p style={{ width: '100%', textAlign: 'left' }}>{t("Start Time")}</p>
                                {workday.endDT && <p style={{ width: '100%', textAlign: 'left' }}>{t("End time")}</p>}
                                {workday.duration && <p style={{ width: '100%', textAlign: 'left' }}>{t("Duration")}</p>}

                            </div>
                            <div className="we-row-container">
                                <div className="we-item-buttons">
                                    <div style={{ width: 250 }}>
                                        <Button onClick={() => this.setState({
                                            DTValue: moment(workday.startDT).toDate(),
                                            pickField: "startDT",
                                            datePicker: true
                                        })}>
                                            {moment(workday.startDT).locale("fi").format('L')}
                                        </Button>
                                        <Button onClick={() => this.setState({
                                            DTValue: moment(workday.startDT).toDate(),
                                            pickField: "startDT",
                                            timePicker: true
                                        })}>
                                            {moment(workday.startDT).format('LT')}
                                        </Button>
                                    </div>

                                    {<div style={{ width: 40 }}> - </div>}

                                    {workday.endDT &&
                                        <div style={{ width: 200, }}>
                                            <Button onClick={() => this.setState({
                                                DTValue: moment(workday.endDT).toDate(),
                                                pickField: "endDT",
                                                datePicker: true
                                            })}>
                                                {moment(workday.endDT).locale("fi").format('L')}
                                            </Button>

                                            <Button onClick={() => this.setState({
                                                DTValue: moment(workday.endDT).toDate(),
                                                pickField: "endDT",
                                                timePicker: true
                                            })}>
                                                {moment(workday.endDT).format('LT')}
                                            </Button>
                                        </div>
                                    }

                                    {!workday.endDT &&
                                        <div style={{ width: 200 }}>
                                            <Button
                                                variant="contained"
                                                style={{ backgroundColor: '#b22717', color: '#FFF', marginRight: 10, }}
                                                onClick={() => this.setState({ confirmationDialog: true, dialogAction: "end", alignSelf: "flex-end" })}
                                            >
                                                {this.props.t("End")}
                                            </Button>
                                        </div>
                                    }
                                    {workday.duration &&
                                        <div style={{ width: 200, }}>
                                            <div>
                                                {durationToHHmm(workday.duration)}
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>




                            {workday.pauses &&
                                <div className="we-column-container">
                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        borderBottomColor: '#1770B2',
                                        borderBottomWidth: 1,
                                        borderBottomStyle: "solid",
                                        marginBottom: 10,
                                        marginTop: 20,

                                    }}>
                                        <p style={{ width: '20%', textAlign: 'left' }}>{t("Pauses")}</p>
                                        <p style={{ width: '20%', textAlign: 'left' }}>{t("Duration")}</p>
                                        <p style={{ width: '30%', textAlign: 'center' }}>{t("Reason")}</p>
                                        <p style={{ width: '15%', textAlign: 'center' }}>{t("Payable")}</p>
                                        <p style={{ width: '15%', textAlign: 'right' }}>{t("Delete")}</p>
                                    </div>
                                    {workday.pauses.map((pause, index) => (
                                        <div key={index} className="wd-payable-hours">
                                            <div className="payable-start">
                                                <div >{durationToHHmm(pause.duration)}</div>

                                            </div>
                                            <div className="payable-middle">
                                                <div style={{ fontSize: 9 }}>{pause.reason ? pause.reason : (pause.type ? pause.type : "-")}</div>
                                            </div>
                                            <div className="payable-end">
                                                <Checkbox
                                                    checked={pause.isPayable}
                                                    onChange={() => this.checkboxHandler(!pause.isPayable, "pauses", index)}
                                                    style={{
                                                        color: '#1770B2',
                                                    }}
                                                />
                                                <IconButton style={{ height: 15, width: 15, padding: 5, margin: 0 }}
                                                    disableRipple={true} onClick={() => this.onPauseDelete(index)}>
                                                    <Delete />
                                                </IconButton>
                                            </div>
                                        </div>

                                    ))}
                                </div>
                            }


                            <div style={{
                                width: '100%',
                                display: 'flex',
                                borderBottomColor: '#1770B2',
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid",
                                marginBottom: 20,
                                marginTop: 10,

                            }}>
                                <p style={{ width: '100%', textAlign: 'left' }}>{t("Total work time")}</p>
                            </div>

                            <div className="we-row-container">
                                <div className="we-center-left">{durationToHHmm(workday.duration)}
                                    {workday.startDT && !workday.endDT &&
                                        <div className="we-center-right">{durationToHHmm((moment(moment()) - moment(workday.startDT)))}</div>
                                    }
                                    {workday.status &&
                                        <div className="we-center-right">{t(workday.status)}</div>
                                    }
                                </div>
                                {workday.eveningDuration &&
                                    <div className="we-center-left">{durationToHHmm(workday.eveningDuration)} </div>
                                }

                                {workday.nightDuration &&
                                    <div className="we-center-left">{durationToHHmm(workday.nightDuration)} </div>
                                }

                            </div>

                            <div style={{
                                width: '100%',
                                display: 'flex',
                                borderBottomColor: '#1770B2',
                                borderBottomWidth: 1,
                                borderBottomStyle: "solid",
                                marginBottom: 20,
                                marginTop: 30,

                            }}>
                                <p style={{ width: '100%', textAlign: 'left' }}>{t("Payable hours")}</p>
                            </div>

                            <div className="wd-payable-hours">

                                <div className="billable-start">
                                    {/* TODO this should be the payable time.. should we have this calculated from times - pauses and what point? */}

                                    {
                                        <TimeButtonComponent
                                            value={workday.payableTime ? workday.payableTime : 0}
                                            onChange={this.onPayableTimeChange}
                                            field="payableTime"
                                        />}
                                </div>
                                <div className="billable-end">
                                    <p>{""}</p>

                                </div>
                            </div>


                            {workday &&
                                <div className="we-column-container">
                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        borderBottomColor: '#1770B2',
                                        borderBottomWidth: 1,
                                        borderBottomStyle: "solid",
                                        marginBottom: 20,
                                        marginTop: 20,

                                    }} >
                                        <p style={{ width: '100%', textAlign: 'left' }}>{t("Additional Description")}</p>
                                    </div>

                                    <div className="wd-payable-hours">
                                        <TextField
                                            variant="outlined"
                                            value={workday.additionalDescription || ""}
                                            InputProps={{ style: { height: 60 } }}
                                            label={t("Additional Description")}
                                            onChange={this.onAdditionalDescriptionChange}
                                            style={{ width: 400 }}

                                        />
                                    </div>
                                </div>
                            }
                        </div>

                    </div>


                    <div className="we-column-container we-2">

                        <div style={{
                            width: '80%',
                            display: 'flex',
                            borderBottomColor: '#1770B2',
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                            marginBottom: 10
                        }}>
                            <p style={{ width: '30%' }}>{t("Job")}</p>
                            <p style={{ width: '30%', textAlign: 'center' }}>{t("Start time")}</p>
                            <p style={{ width: '30%', textAlign: 'center' }}>{t("End time")}</p>
                            <p style={{ width: '10%', textAlign: 'center' }}>{t("Duration")}</p>
                        </div>

                        {/*<WorklogAC
                            searchValues={["name"]}
                            listShowField={"name"}
                            searchData={employees}
                            listMaxLength={5}
                            onSelect={this.onEmployeeSelect}
                            onChange={event => this.setState({ searchEmployee: event })}
                            value={this.state.searchEmployee || ""}
                            fieldName={"searchEmployee"}
                            placeholder={this.props.t("Employee")}
                            style={{ width: 325 }}
                        />*/}
                        {workday.employee &&
                            <div className="we-item-delete" key={workday.employee}>

                                {/*<IconButton onClick={() => this.onEmployeeSelect({ id: employee })}>
                                    <Delete />
                                </IconButton>
                                */}
                            </div>
                        }
                    </div>

                </div>


                <div className="we-button-row">
                    {workday.id &&
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#b22717', color: '#FFF', marginRight: 10 }}
                            onClick={() => this.setState({ confirmationDialog: true, dialogAction: "delete" })}
                        >
                            {this.props.t("Delete")}
                        </Button>
                    }

                    <Button
                        variant="contained"
                        style={{ backgroundColor: '#1770B2', color: '#FFF', marginRight: 10 }}
                        onClick={() => this.props.history.goBack()}
                    >
                        {this.props.t("Cancel")}
                    </Button>

                    {unsavedChanges &&
                        <Button
                            variant="contained"
                            style={{ backgroundColor: '#1770B2', color: '#FFF' }}
                            onClick={this.onWorkdaySave}
                        >
                            {saving &&
                                <div style={{
                                    alignSelf: 'right',
                                    width: 'auto',
                                    height: 'auto',
                                }}>
                                    <CircularProgress />
                                </div>
                            }
                            {this.props.t("Save")}
                        </Button>
                    }
                </div>


                {
                    this.state.datePicker &&
                    <DateDialog
                        open={this.state.datePicker}
                        selectType={"single"}
                        value={this.state.DTValue}
                        onClose={() => this.setState({ datePicker: false })}
                        onChange={event => this.onDateChange(event, this.state.pickField)}
                    />
                }

                {
                    this.state.timePicker &&
                    <TimeDialog
                        onChange={event => this.onTimeChange(event, this.state.pickField)}
                        onClose={() => this.setState({ timePicker: false })}
                        value={this.state.DTValue}
                    />
                }
                {/*this.state.taskDialog &&
                    <TaskDialog
                        open={this.state.taskDialog}
                        onClose={() => this.setState({ taskDialog: false })}
                        selectedLog={worklog}
                        models={this.props.models.models}
                        customers={customers}
                        addTasks={this.onWorklogUpdate}
                    />
                */}

                {
                    this.state.imageDialog &&
                    <ImageDialog
                        open={this.state.imageDialog}
                        onClose={() => this.setState({ imageDialog: false })}
                        image={this.state.dummyUrl}
                    />
                }

                {
                    this.state.confirmationDialog &&
                    <GlobalConfirmationDialog
                        open={this.state.confirmationDialog}
                        mode={dialogAction}
                        onClose={() => this.setState({ confirmationDialog: false, dialogAction: "" })}
                        onConfirm={() => this.setState({ confirmationDialog: false, dialogAction: "" }, () => workday.id && workday.employee && dialogAction === "delete" ? this.onWorkdayDelete() : this.onWorkdayEnd())}
                        title={this.props.t("Attention")}
                        message={dialogAction === "delete" ? `${t("ActionConfirmation")} ${t("DeleteAction")} ${t("ActionWorkday")}` : `${t("ActionConfirmation")} ${t("EndAction")} ${t("ActionWorkday")}?`}
                    />
                }
            </div >
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    //customers: state.customers,
    employees: state.employees,
    //worksites: state.worksites,
    //models: state.models,
});

export default connect(mapStateToProps, { setEmployees })(withTranslation()(WorkdayEditor));
