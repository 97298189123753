import {findValueInObject} from "../validations/isEmpty";
import {billSummaryPrices} from "../BillingComponents/BillDetailsComponents/InvoiceObjectForming";

export const sortList = (field, parse, sortOrder, data) => {

    let direction = "";

    if (sortOrder.field === field) direction = sortOrder.dir === "asc" ? "desc" : "asc";
    else direction = "desc";


    const results = data.sort((a,b) => {

        const fieldValueA = field !== "billSum" ? findValueInObject([a], field) : billSummaryPrices(a.billObj.rows, a.billObj.billingFees, a.billObj.invoice).fullPrice;
        const fieldValueB = field !== "billSum" ? findValueInObject([b], field) : billSummaryPrices(b.billObj.rows, b.billObj.billingFees, b.billObj.invoice).fullPrice;

        console.log(fieldValueA);

        const aField = parse ? parseFloat(fieldValueA) : fieldValueA;
        const bField = parse ? parseFloat(fieldValueB) : fieldValueB;

        if (direction === "asc" ? aField > bField : aField < bField) return -1;
        if (direction === "asc" ? aField < bField : aField > bField) return 1;
    });

    sortOrder.dir = direction;
    sortOrder.field = field;

    return {
        newSortOrder: sortOrder,
        results,
    }
};
