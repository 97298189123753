module.exports = {
    fConfig : {
        apiKey:"AIzaSyDKjZxULmdO3DPzhEaVx6ux5WFxLNvTcmk",
        authDomain:"mobility-be85b.firebaseapp.com",
        databaseURL:"https://mobility-be85b.firebaseio.com",
        projectId:"mobility-be85b",
        storageBucket:"mobility-be85b.appspot.com",
        messagingSenderId:"452374993262",
        timestampsInSnapshots:true
    },
    fProduction: {
        apiKey: "AIzaSyBBQU8-DBk0I-OBCu9dPUGlrspWdwD6cks",
        authDomain: "mobility-prod.firebaseapp.com",
        databaseURL: "https://mobility-prod.firebaseio.com",
        projectId: "mobility-prod",
        storageBucket: "mobility-prod.appspot.com",
        messagingSenderId: "383402516361",
        timestampsInSnapshots: true
    },
    secret: "=9P%S/a7#|+gP!E&:DM((:p(C4ooOJ"
};