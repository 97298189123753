import React, { Component } from "react";

import { Button } from "@material-ui/core";

import TextFieldWithLabel from "../TextComponents/TextFieldWithLabel";
import MenuOption from "../TextComponents/MenuOption";

import GlobalConfirmationDialog from "../CommonDialogs/GlobalConfirmationDialog";
import { setValueToObject } from "../util/ObjectPath";
import { validateCustomerEditor } from "../validations/customerEditor";
import firebase from "firebase/app";
import "firebase/firestore";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import "./CustomerEditor.css";

class CustomerEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      customer: {},
      customerCopy: {},
      customers: [],
      errors: {},
      confirmationDialog: false,
    };
  }

  componentDidMount() {
    const { user } = this.props.user;
    const {
      match: { params },
    } = this.props;

    if (params.customerId) {
      this.unsubscribeCustomerListener = firebase
        .firestore()
        .collection("customers")
        .doc(params.customerId)
        .onSnapshot((docSnap) => {
          if (docSnap && docSnap.id) {
            const customer = { 
                ...docSnap.data(),
                 id: docSnap.id
                }
    
            if (!customer.address2) customer.address2 = {};
            if (!customer.billingSettings) customer.billingSettings = {};
            const customerCopy = JSON.parse(JSON.stringify(customer));

            this.setState({ customer, customerCopy, loading: false });
          } else {
            this.setState({ customer: {}, customerCopy: {}, loading: false });
          }
        });
    }

    this.unsubscribeHighestCustomernumber = firebase
      .firestore()
      .collection("customers")
      .where("companyID", "array-contains", user.companyID)
      .onSnapshot((querySnap) => {
        const customers = [];
        let highestNumber = 0;

        querySnap.forEach((doc) => {
          const customer = doc.data();
          customer.id = doc.id;

          if (parseInt(customer.customerNumber) > highestNumber)
            highestNumber = parseInt(customer.customerNumber);
          customers.push(customer);
        });

        this.setState({
          customers,
        });

        if (!params.customerId) {
          const customer = {
            active: true,
            type: "private",
            address2: {},
            billingSettings: {},
            customerNumber: highestNumber + 1,
          };
          this.setState({ loading: false, customer });
        }
      });
  }

  componentWillUnmount() {
    this.unsubscribeCustomerListener && this.unsubscribeCustomerListener();
    this.unsubscribeHighestCustomernumber &&
      this.unsubscribeHighestCustomernumber();
  }

  customerFields = (event, ...path) => {
    let { customer, errors } = this.state;

    let value;
    if (typeof event === "object") value = event.target.value;
    else value = event;

    console.log("customerFields event ", event)
    setValueToObject(customer, path, value);

    errors[path[0]] = "";

    this.setState({ customer });
  };

  onWorksiteSave = (customer, id) => {
    const { user } = this.props.user;

    const worksite = {
      wNumber: "",
      customer: id,
      city: customer.city ? customer.city : "",
      instructions: "",
      postCode: customer.postCode ? customer.postCode : "",
      streetAddress: customer.streetAddress ? customer.streetAddress : "",
      name: customer.streetAddress ? customer.streetAddress : "",
    };

    console.log(worksite);
    firebase
      .firestore()
      .collection("company")
      .doc(user.companyID)
      .collection("worksites")
      .add(worksite)
      .then(() => this.props.history.goBack());
  };

  onCustomerSave = () => {
    const { customer, customerCopy, customers } = this.state;
    const { user } = this.props.user;
    customer.companyID = [user.companyID];

    console.log("onCustomerSave user ", user);
    const { errors, isValid } = validateCustomerEditor(
      customer,
      customerCopy,
      customers
    );

    if (!isValid) return this.setState({ errors });

    if (customer.id) {
      customer.modifiedAt = Date();
      customer.modifiedBy = user.id;
      firebase
        .firestore()
        .collection("customers")
        .doc(customer.id)
        .set(customer)
        .then(() => this.props.history.goBack());
    } else {
      customer.createdAt = Date();
      customer.createdBy = user.id;
      firebase
        .firestore()
        .collection("customers")
        .add(customer)
        .then((resData) => this.onWorksiteSave(customer, resData.id));
    }
  };

  onCustomerDelete = () => {
    const { customer } = this.state;
    const { user } = this.props.user;

    const customerCopy = { ...customer };

    //if this customer belongs to many company, only remove company ID - (not sure if connecting same customer to many companies is yet even possible)

    const idx = customerCopy?.companyID?.findIndex(
      (companyID) => companyID === user.companyID
    );

    if (idx > -1) {
      customerCopy.companyID.splice(idx, 1);
    }

    //if still belongs to soem company, keep the customer, just store with removed companyID
    if (customerCopy.companyID && customerCopy.companyID.length > 0) {
      firebase
        .firestore()
        .collection("customers")
        .doc(customerCopy.id)
        .set(customerCopy)
        .then(() => this.props.history.replace("/registry/customers/"));
    } else {
      //if no companies exist in array, then actually delete
      firebase
        .firestore()
        .collection("customers")
        .doc(customerCopy.id)
        .delete()
        .then(() => this.props.history.replace("/registry/customers/"));
    }
  };

  render() {
    const { loading, customer, errors } = this.state;
    const { t } = this.props;
    const { company } = this.props.company;

    const companyBillingAPI = company.settings?.billing?.sending?.default || null;

    console.log("companyBillingAPI ", companyBillingAPI)
    if (loading) return <div></div>;
    else
      return (
        <div className="customer-edit-container">
          <div className="customer-edit-title-bg">
            <h3>{t("Common")}</h3>
          </div>

          <div className="customer-edit-container wrapper row1">
            <div className="customer-edit-column">
              <TextFieldWithLabel
                label={t("Customer number")}
                value={customer.customerNumber || ""}
                onChange={(event) =>
                  this.customerFields(event, "customerNumber")
                }
                error={!!errors.customerNumber}
                errorText={t(errors.customerNumber)}
              />

              <TextFieldWithLabel
                label={t("Name")}
                value={customer.name || ""}
                onChange={(event) => this.customerFields(event, "name")}
                error={!!errors.name}
                errorText={t(errors.name)}
              />

              <TextFieldWithLabel
                label={t("Active_sub")}
                value={customer.active}
                onChange={(event) => this.customerFields(event, "active")}
                select
              >
                <MenuOption value={true}>{t("Yes")}</MenuOption>
                <MenuOption value={false}>{t("No")}</MenuOption>
              </TextFieldWithLabel>
            </div>

            <div className="customer-edit-column middle-brd">
              <TextFieldWithLabel
                label={t("Home phone")}
                value={customer.phone || ""}
                onChange={(event) => this.customerFields(event, "phone")}
                error={!!errors.phone}
                errorText={t(errors.phone)}
              />

              <TextFieldWithLabel
                label={t("Work phone")}
                value={customer.phoneWork}
                onChange={(event) => this.customerFields(event, "phoneWork")}
                error={!!errors.phoneWork}
                errorText={t(errors.phoneWork)}
              />

              <TextFieldWithLabel
                label={t("Email")}
                value={customer.email || ""}
                onChange={(event) => this.customerFields(event, "email")}
                error={!!errors.email}
                errorText={t(errors.email)}
              />
            </div>

            <div className="customer-edit-column">
              <TextFieldWithLabel
                label={t("Type")}
                value={customer.type}
                onChange={(event) => this.customerFields(event, "type")}
                select
              >
                <MenuOption value={"private"}>
                  {t("PrivateCustomer")}
                </MenuOption>
                <MenuOption value={"company"}>
                  {t("CompanyCustomer")}
                </MenuOption>
              </TextFieldWithLabel>

              {customer.type === "company" && (
                <TextFieldWithLabel
                  label={t("CompanyName")}
                  value={customer.companyName || ""}
                  onChange={(event) =>
                    this.customerFields(event, "companyName")
                  }
                />
              )}

              {customer.type === "company" && (
                <TextFieldWithLabel
                  label={t("Company ID")}
                  value={customer.yCode || ""}
                  onChange={(event) => this.customerFields(event, "yCode")}
                />
              )}
            </div>
          </div>

          <div className="customer-edit-title-bg">
            <h3>{t("Addresses")}</h3>
          </div>

          <div className="customer-edit-container wrapper row2">
            <div className="customer-edit-column">
              <h4>{`${t("Worksite address")} 1`}</h4>
              <TextFieldWithLabel
                label={t("Country")}
                value={customer.country || ""}
                onChange={(event) => this.customerFields(event, "country")}
              />

              <TextFieldWithLabel
                label={t("City")}
                value={customer.city || ""}
                onChange={(event) => this.customerFields(event, "city")}
              />

              <TextFieldWithLabel
                label={t("Street address")}
                value={customer.streetAddress || ""}
                onChange={(event) =>
                  this.customerFields(event, "streetAddress")
                }
              />

              <TextFieldWithLabel
                label={t("Street address ext")}
                value={customer.streetAddressExt || ""}
                onChange={(event) =>
                  this.customerFields(event, "streetAddressExt")
                }
              />

              <TextFieldWithLabel
                label={t("Post code")}
                value={customer.postCode || ""}
                onChange={(event) => this.customerFields(event, "postCode")}
              />
            </div>
            <div className="customer-edit-column middle-brd">
              <h4>{`${t("Worksite address")} 2`}</h4>
              <TextFieldWithLabel
                label={t("Country")}
                value={customer.address2.country || ""}
                onChange={(event) =>
                  this.customerFields(event, "address2", "country")
                }
              />

              <TextFieldWithLabel
                label={t("City")}
                value={customer.address2.city || ""}
                onChange={(event) =>
                  this.customerFields(event, "address2", "city")
                }
              />

              <TextFieldWithLabel
                label={t("Street address")}
                value={customer.address2.streetAddress || ""}
                onChange={(event) =>
                  this.customerFields(event, "address2", "streetAddress")
                }
              />

              <TextFieldWithLabel
                label={t("Post code") || ""}
                value={customer.address2.postCode || ""}
                onChange={(event) =>
                  this.customerFields(event, "address2", "postCode")
                }
              />
            </div>
            <div className="customer-edit-column"></div>
          </div>

          <div className="customer-edit-title-bg">
            <h3>{t("Billing")}</h3>
          </div>

          
          <div className="customer-edit-container wrapper row3">
            <div className="customer-edit-column">
              <h4>Lähetys</h4>
              <TextFieldWithLabel
                label={t("TimeToPay")}
                value={customer.billingSettings?.timeToPay || ""}
                onChange={(event) =>
                  this.customerFields(event, "billingSettings", "timeToPay")
                }
              />

              {/* no idea why i cannot render menuoptions conditionally */ }
              {/* this for APIX*/}
              {companyBillingAPI === "apix" &&
              <TextFieldWithLabel
                label={t("BillingMethod")}
                value={customer.billingSettings?.sendingMethod ? customer.billingSettings?.sendingMethod : companyBillingAPI}
                onChange={(event) =>
                  this.customerFields(event, "billingSettings", "sendingMethod")
                }
                select
              >
                {/*<MenuOption value="print">{t("Print")}</MenuOption>*/}
                <MenuOption value="email / apix">{t("email / apix")}</MenuOption>
                <MenuOption value="apix">{t("apix")}</MenuOption>
              </TextFieldWithLabel>
              }

              {companyBillingAPI != "apix" &&
              <TextFieldWithLabel
                label={t("BillingMethod")}
                value={t(companyBillingAPI)}
                onChange={(event) =>
                  this.customerFields(event, "billingSettings", "sendingMethod")
                }
                disabled
              >
              </TextFieldWithLabel>
              }

              {/* sending based opting for billing fee */}
              {(customer.billingSettings?.sendingMethod === "apix" || customer.billingSettings?.sendingMethod === "netvisor" || customer.billingSettings?.sendingMethod === "talenom" ) && (
                <TextFieldWithLabel
                  label={t("Customer_bill_delivery_method")}
                  value={customer.billingSettings?.deliveryMethod || ""}
                  onChange={(event) =>
                    this.customerFields(
                      event,
                      "billingSettings",
                      "deliveryMethod"
                    )
                  }
                  select
                >
                  <MenuOption value="eInvoice">
                    {t("Customer_bill_delivery_method_einvoice")}
                  </MenuOption>
                  <MenuOption value="paperLetterInvoice">
                    {t("Customer_bill_delivery_method_paper_bill")}
                  </MenuOption>
                  <MenuOption value="netpostiInvoice">
                    {t("Customer_bill_delivery_method_netposti")}
                  </MenuOption>
                </TextFieldWithLabel>
              )}

              {customer.billingSettings?.sendingMethod === "email / apix" && (
                <TextFieldWithLabel
                  label={t("Customer_bill_delivery_method")}
                  value={customer.billingSettings?.deliveryMethod || ""}
                  onChange={(event) =>
                    this.customerFields(
                      event,
                      "billingSettings",
                      "deliveryMethod"
                    )
                  }
                  select
                >
                  <MenuOption value="eInvoice">
                    {t("Customer_bill_delivery_method_einvoice")}
                  </MenuOption>
                  <MenuOption value="emailInvoice">
                    {t("Customer_bill_delivery_method_email")}
                  </MenuOption>
                  <MenuOption value="confirmedEmailInvoice">
                    {t("Customer_bill_delivery_method_confirmed_email")}
                  </MenuOption>
                </TextFieldWithLabel>
              )}

              { companyBillingAPI === "apix" &&
              <TextFieldWithLabel
                label={t("Payment tracking")}
                value={customer.billingSettings.paymentTracking || ""}
                onChange={(event) =>
                  this.customerFields(
                    event,
                    "billingSettings",
                    "paymentTracking"
                  )
                }
                select
              >
                 <MenuOption value="accountant">{t("Accountant")}</MenuOption>
                 <MenuOption value="thirdparty">{t("Thirdparty")}</MenuOption>
                 <MenuOption value="manual">{t("Payment_tracking_manual")}</MenuOption>

              </TextFieldWithLabel>
              }

              {companyBillingAPI !== "apix" &&
              <TextFieldWithLabel
                label={t("Payment tracking")}
                value={customer.billingSettings?.paymentTracking || ""}
                onChange={(event) =>
                  this.customerFields(
                    event,
                    "billingSettings",
                    "paymentTracking"
                  )
                }
                select
              >
                <MenuOption value={companyBillingAPI}>{t(companyBillingAPI)}</MenuOption>
                <MenuOption value="thirdparty">{t("Thirdparty")}</MenuOption>
                <MenuOption value="manual">{t("Payment_tracking_manual")}</MenuOption>
              </TextFieldWithLabel>
              }

            </div>
            <div className="customer-edit-column middle-brd">
              <h4>Viitteet</h4>
              <TextFieldWithLabel
                label={t("References")}
                value={customer.billingSettings.ourReference || ""}
                onChange={(event) =>
                  this.customerFields(event, "billingSettings", "ourReference")
                }
              />

              <TextFieldWithLabel
                label={t("CustomerReference")}
                value={customer.billingSettings.customerReference}
                onChange={(event) =>
                  this.customerFields(
                    event,
                    "billingSettings",
                    "customerReference"
                  )
                }
              />
            </div>
            <div className="customer-edit-column">
              {customer.type === "company" && <h4>Verkkolaskutus</h4>}
              {customer.type === "company" && (
                <TextFieldWithLabel
                  label={t("OperatorIdentifier")}
                  value={customer.billingSettings.operatorIdentifier || ""}
                  onChange={(event) =>
                    this.customerFields(
                      event,
                      "billingSettings",
                      "operatorIdentifier"
                    )
                  }
                />
              )}

              {customer.type === "company" && (
                <TextFieldWithLabel
                  label={t("E-InvoiceAddress")}
                  value={customer.billingSettings.eInvoiceAddress || ""}
                  onChange={(event) =>
                    this.customerFields(
                      event,
                      "billingSettings",
                      "eInvoiceAddress"
                    )
                  }
                />
              )}
            </div>
          </div>

          <div className="customer-edit-buttons">
            <Button
              variant="contained"
              style={{
                color: "#FFF",
                backgroundColor: "#b22717",
                marginRight: 10,
              }}
              onClick={() => this.setState({ confirmationDialog: true })}
            >
              {t("Delete")}
            </Button>

            <Button
              variant="contained"
              style={{
                color: "#FFF",
                backgroundColor: "#1770B2",
                marginRight: 10,
              }}
              onClick={() => this.props.history.goBack()}
            >
              {t("Cancel")}
            </Button>

            <Button
              variant="contained"
              style={{ color: "#FFF", backgroundColor: "#1770B2" }}
              onClick={this.onCustomerSave}
            >
              {t("Save")}
            </Button>
          </div>

          <GlobalConfirmationDialog
            open={this.state.confirmationDialog}
            onClose={() => this.setState({ confirmationDialog: false })}
            onConfirm={this.onCustomerDelete}
            title={t("Attention")}
            message={`${t("ActionConfirmation")} ${t("DeleteAction")} ${t(
              "DeleteActionCustomer"
            )}? ${t("ActionCannotRollback")}.`}
          />
        </div>
      );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  company: state.company
});

export default connect(mapStateToProps, {})(withTranslation()(CustomerEditor));
