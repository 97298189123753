import moment from 'moment-timezone';


export const checkRRULE = freq => {
    let returnVal = "";

    if (freq === "DAILY") {
        returnVal = "days"
    }

    if (freq === "WEEKLY") {
        returnVal = "weeks"
    }

    if (freq === "MONTHLY") {
        returnVal = "months"
    }

    return returnVal
};


export const checkEmployees = (rootJob, selectedJob) => {

    let employees = null;

    if (selectedJob.employees && rootJob.employees && rootJob.employees.length !== selectedJob.employees.length) {
        employees = selectedJob.employees;
    }
    if (selectedJob.employees && rootJob.employees && rootJob.employees.length === selectedJob.employees.length) {
        let counter = 0;
        rootJob.employees.forEach(rootEmployee => {
            selectedJob.employees.forEach(selectedEmployee => {
                if (rootEmployee.id !== selectedEmployee.id) {
                    counter++
                }
            });
        });

        if (rootJob.employees.length === 1) {
            if (counter >= rootJob.employees.length) {
                employees = selectedJob.employees
            }
        }

        if (rootJob.employees.length > 1) {
            if (counter > rootJob.employees.length) {
                employees = selectedJob.employees
            }
        }

    }

    // Returns null if no changes made

    return employees;
};

export const checkTask = (rootJob, selectedJob) => {
    let tasks = null;

    console.log("checkTask rootJob ", rootJob);
    console.log("checkTask selectedJob ", selectedJob);

    // If rootjob doesn't have tasks.
    if (selectedJob.tasks && !rootJob.tasks) {
        tasks = selectedJob.tasks;
    }

    // If tasks exists in both but their length doesn't match.
    if (selectedJob.tasks && rootJob.tasks && rootJob.tasks.length !== selectedJob.tasks.length) {
        tasks = selectedJob.tasks;
    }

    console.log("checkTask", tasks);

    // if tasks exists in both and their length match
    if (selectedJob.tasks && rootJob.tasks && rootJob.tasks.length === selectedJob.tasks.length) {
        let counter = 0;
        // Douple loop to check if tasks name's doesn't match
        rootJob.tasks.forEach(rootTask => {
            selectedJob.tasks.forEach(selectTask => {
                if (rootTask.name !== selectTask.name) {
                    counter++;
                }
            })
        });

        if (rootJob.tasks.length === 1) {
            if (counter >= rootJob.tasks.length) {
                tasks = selectedJob.tasks
            }
        }

        if (rootJob.tasks.length > 1) {
            if (counter > rootJob.tasks.length) {
                tasks = selectedJob.tasks
            }
        }
    }
    console.log("checkTasks", tasks);
    return tasks
};

const checkExtraTodo = (rootjob, selectedJob) => {
    let extraTodo = null;

    if (!rootjob.extraTodo && selectedJob.extraTodo) {
        extraTodo = selectedJob.extraTodo;
    }

    if (rootjob.extraTodo && selectedJob.extraTodo && (rootjob.extraTodo.length !== selectedJob.extraTodo.length)) {
        extraTodo = selectedJob.extraTodo;
    }

    if (rootjob.extraTodo && selectedJob.extraTodo && (rootjob.extraTodo.length === selectedJob.extraTodo.length)) {

        rootjob.extraTodo.forEach(item => {

            if (!selectedJob.extraTodo.includes(item)) {
                extraTodo = selectedJob.extraTodo;
            }
        });
    }

    return extraTodo;

};

const checkIsOntime = (rootJob, selectedJob) => {
    let isOntime = null;

    if (!rootJob.isOntime && selectedJob.isOntime) isOntime = selectedJob.isOntime;
    if (rootJob.isOntime && rootJob.isOntime != selectedJob.isOntime) isOntime = selectedJob.isOntime;

    return isOntime;
};

const checkEndOntime = (rootJob, selectedJob) => {
    let endOntime = null;

    if (!rootJob.endOntime && selectedJob.endOntime) endOntime = selectedJob.endOntime;
    if (rootJob.endOntime && rootJob.endOntime != selectedJob.endOntime) endOntime = selectedJob.endOntime;

    return endOntime;
};

const checkTitleChanged = (rootJob, selectedJob) => {
    let title = null;

    if (!rootJob.title && selectedJob.title) title = selectedJob.title;
    if (rootJob.title && rootJob.title != selectedJob.title) title = selectedJob.title;

    return title;
};

const checkMaxEmpChanged = (rootJob, selectedJob) => {
    let maxEmp = null;

    if (!rootJob.maxEmp && selectedJob.maxEmp) maxEmp = selectedJob.maxEmp;
    if (rootJob.maxEmp && rootJob.maxEmp != selectedJob.maxEmp) maxEmp = selectedJob.maxEmp;

    return maxEmp;
};

/*
 * deprecated?
 */
export const formIncomingChanges = (rootjob, selectedJob, recurringRule, rrStart, rrEnd) => {

    const date = moment(rootjob.startDT).format("YYYY-MM-DD");
    const time = moment(selectedJob.startDT).format("HH:mm:ss");
    const replaceDT = moment(date + 'T' + time);

    const newChange = {
        replaceDT: replaceDT,
        startDT: selectedJob.startDT,
        endDT: selectedJob.endDT
    };

    if (recurringRule === "once") newChange.impact = "once";

    if (recurringRule === "range") {
        console.log("OldRange")
        delete newChange.replaceDT;
        newChange.replaceStart = rrStart;
        newChange.nextNormal = moment(rrEnd).add(rootjob.rrule.INTERVAL, checkRRULE(rootjob.rrule.FREQ)).toDate();
        newChange.replaceEnd = moment(rrEnd).add(rootjob.rrule.INTERVAL, checkRRULE(rootjob.rrule.FREQ)).toDate();
        newChange.impact = "range";
    }

    let employees = checkEmployees(rootjob, selectedJob);
    let tasks = checkTask(rootjob, selectedJob);
    let extraTodo = checkExtraTodo(rootjob, selectedJob);


    employees !== null ? newChange.employees = employees : employees = null;
    tasks !== null ? newChange.tasks = tasks : tasks = null;
    extraTodo !== null ? newChange.extraTodo = extraTodo : extraTodo = null;

    if (rootjob.inComingChanges) {
        const change = rootjob.inComingChanges.find(change => change.replaceDT === newChange.replaceDT || change.replaceStart === newChange.replaceStart);

        if (change) {
            change.employees ? newChange.employees = change.employees : delete change.employees;
            change.tasks ? newChange.tasks = change.tasks : delete change.tasks;
            change.extraTodo ? newChange.extraTodo = change.extraTodo : delete change.extraTodo;

            const removeIndex = rootjob.inComingChanges.indexOf(change);
            rootjob.inComingChanges.splice(removeIndex, 1, newChange);
        }
        else {
            rootjob.inComingChanges.push(newChange);
        }
    }
    else {
        rootjob.inComingChanges = [newChange];
    }

    return formatJobDates(rootjob);

};

/*
 * form changes that are made for recurring event
 */
export const formIncomingChanges2 = (rootjob, selectedJob, recurringRule, rrStart, rrEnd, deleteMode, user) => {

    const date = moment(selectedJob.startDT).format("YYYY-MM-DD");
    const time = moment(rootjob.startDT).format("HH:mm:ss");
    const replaceDT = moment(date + 'T' + time);

    console.log("DEBUG: formIncomingChanges2 called ", selectedJob, rootjob);

    let newChange = {};

    if (recurringRule === "once") {

        newChange = {
            replaceDT: replaceDT, // take start time from rootJob - date from selectedJob
            startDT: selectedJob.startDT, // Defines when new event should start
            endDT: selectedJob.endDT, // Defines when new event should end
            type: "content",
            impact: "once",
            modifiedBy: user.id,
        };
    }

    if (recurringRule === "range") {

        newChange = {
            replaceDT: replaceDT,
            rangeStart: rrStart,
            startDuringRange: selectedJob.startDT,
            endDuringRange: selectedJob.endDT,
            rangeEnd: rrEnd,
            impact: "range",
            type: "content",
            modifiedBy: user.id,
        }
    }

    if (deleteMode) newChange.type = "delete";

    let employees = checkEmployees(rootjob, selectedJob);
    let tasks = checkTask(rootjob, selectedJob);
    let extraTodo = checkExtraTodo(rootjob, selectedJob);
    let isOntime = checkIsOntime(rootjob, selectedJob);
    let endOntime = checkEndOntime(rootjob, selectedJob);
    let title = checkTitleChanged(rootjob, selectedJob);
    let maxEmp = checkMaxEmpChanged(rootjob, selectedJob);

    employees !== null ? newChange.employees = employees : employees = null;
    tasks !== null ? newChange.tasks = tasks : tasks = null;
    extraTodo !== null ? newChange.extraTodo = extraTodo : extraTodo = null;
    isOntime !== null ? newChange.isOntime = isOntime : isOntime = null;
    endOntime !== null ? newChange.endOntime = endOntime : endOntime = null;
    title !== null ? newChange.title = title : title = null;
    maxEmp !== null ? newChange.maxEmp = maxEmp : maxEmp = null;

    if (!rootjob.inComingChanges) {
        rootjob.inComingChanges = [newChange]
    }
    else {
        const existingChange = rootjob.inComingChanges.find(change => moment(change.replaceDT).isSame(newChange.replaceDT));
        console.log("DEBUG: formIncomingChanges2 existingChange ", existingChange, newChange.replaceDT);

        if (existingChange) {
            // Copy other changes so they don't get lost
            if (existingChange.employees && !newChange.employees) newChange.employees = existingChange.employees;
            if (existingChange.tasks && !newChange.tasks) newChange.tasks = existingChange.tasks;
            if (existingChange.extraTodo && !newChange.extraTodo) newChange.extraTodo = existingChange.extraTodo;
            if (existingChange.isOntime && !newChange.isOntime) newChange.isOntime = existingChange.isOntime;
            if (existingChange.endOntime && !newChange.endOntime) newChange.endOntime = existingChange.endOntime;
            if (existingChange.title && !newChange.title) newChange.title = existingChange.title;
            if (existingChange.maxEmp && !newChange.maxEmp) newChange.maxEmp = existingChange.maxEmp;
            //TODO - title & maxEmp

            const removeIndex = rootjob.inComingChanges.indexOf(existingChange);
            rootjob.inComingChanges.splice(removeIndex, 1, newChange);
        }
        else rootjob.inComingChanges.push(newChange);
    }

    return formatJobDates(rootjob);
};

export const addIncomingChanges = (rootJob, selectedJob, selectedJobDT, events, recurringRule, extraTodo, rrStart, rrEnd, user) => {
    let returnVal = [];

    console.log("DEBUG: addIncomingChanges called");

    let inComingChanges = {};
    // Form newChange object
    // Create one time change
    if (recurringRule === "once") {
        inComingChanges = {
            replaceDT: selectedJobDT.startDT,
            startDT: selectedJob.startDT,
            endDT: selectedJob.endDT,
            impact: "once",
            modifiedBy: user.id,
        };
    }

    // Create onward change
    if (recurringRule === "onward" || recurringRule === "onward_override") {
        inComingChanges = {
            startDT: selectedJob.startDT,
            replaceDT: selectedJobDT.startDT,
            endDT: selectedJob.endDT,
            until: moment(selectedJobDT.endDT).subtract(rootJob.rrule.INTERVAL, checkRRULE(rootJob.rrule.FREQ)).toDate(),
            impact: "onward",
            modifiedBy: user.id,
        };
    }

    //Create range change
    if (recurringRule === "range") {
        inComingChanges = {
            //replaceStart: moment(rrStart).subtract(rootJob.rrule.INTERVAL, checkRRULE(rootJob.rrule.FREQ)).toDate(),
            replaceStart: rrStart,
            nextNormal: moment(rrEnd).add(rootJob.rrule.INTERVAL, checkRRULE(rootJob.rrule.FREQ)).toDate(),
            //replaceEnd: rrEnd,
            replaceEnd: moment(rrEnd).add(rootJob.rrule.INTERVAL, checkRRULE(rootJob.rrule.FREQ)).toDate(),
            startDT: selectedJob.startDT,
            endDT: selectedJob.endDT,
            impact: "range",
            modifiedBy: user.id,
        };
    }
    // Check possible changes to employees, tasks and extraTodo
    let employees = checkEmployees(rootJob, selectedJob);
    let tasks = checkTask(rootJob, selectedJob);
    employees !== null ? inComingChanges.employees = employees : employees = null;
    tasks !== null ? inComingChanges.tasks = tasks : tasks = null;
    extraTodo && extraTodo.length >= 1 ? inComingChanges.extraTodo = extraTodo : delete inComingChanges.extraTodo;
    //TODO: RETHINK - are these used at all??

    // Check if job already has inComingChanges array
    if (rootJob.inComingChanges) {

        //Counter && hit for checking if we need to push new one to array
        let counter = 0;
        let hit = false;

        rootJob.inComingChanges.map(change => {
            change.replaceDT = moment.tz(change.replaceDT, rootJob.TZ);
            if (change.impact === "once" && inComingChanges.impact === "once") {
                if (moment(change.replaceDT).isSame(inComingChanges.replaceDT)) {
                    // Check employees, tasks, extraTo do here as well, if not, they will get overwritten
                    change.employees ? inComingChanges.employees = change.employees : delete change.employees;
                    change.tasks ? inComingChanges.tasks = change.tasks : delete change.tasks;
                    change.extraTodo ? inComingChanges.extraTodo = change.extraTodo : delete change.extraTodo;
                    //change.isOntime ? inComingChanges.isOntime = change.isOntime : delete change.isOntime;
                    //change.title ? inComingChanges.title = change.title : delete change.title;
                    //change.maxEmp ? inComingChanges.maxEmp = change.maxEmp : delete change.maxEmp;

                    let index = rootJob.inComingChanges.indexOf(change);
                    if (index > -1) {
                        rootJob.inComingChanges.splice(index, 1, inComingChanges)
                    }
                    hit = true;
                }
            }

            if (change.impact === "onward" && inComingChanges.impact === "onward" && moment(change.replaceDT).isSame(inComingChanges.replaceDT)) {
                // Check employees, tasks, extraTo do here as well, if not, they will get overwritten
                change.employees ? inComingChanges.employees = change.employees : delete change.employees;
                change.tasks ? inComingChanges.tasks = change.tasks : delete change.tasks;
                change.extraTodo ? inComingChanges.extraTodo = change.extraTodo : delete change.extraTodo;


                let index = rootJob.inComingChanges.indexOf(change);
                if (index > -1) {
                    rootJob.inComingChanges.splice(index, 1, inComingChanges)
                }
                hit = true
            }

            if (change.impact === "range" && inComingChanges.impact === "range" && moment(change.replaceStart).isSame(inComingChanges.replaceStart)) {
                // Check employees, tasks, extraTo do here as well, if not, they will get overwritten
                change.employees ? inComingChanges.employees = change.employees : delete change.employees;
                change.tasks ? inComingChanges.tasks = change.tasks : delete change.tasks;
                change.extraTodo ? inComingChanges.extraTodo = change.extraTodo : delete change.extraTodo;


                let index = rootJob.inComingChanges.indexOf(change);
                if (index > -1) {
                    rootJob.inComingChanges.splice(index, 1, inComingChanges)
                }
                hit = true
            }
            counter++;
        });

        if (counter === rootJob.inComingChanges.length && !hit) {
            rootJob.inComingChanges.push(inComingChanges)
        }
        returnVal = rootJob.inComingChanges;
    }
    else {
        returnVal.push(inComingChanges);
    }

    return returnVal;
};

export const checkEmployeeTasks = selectedJob => {
    let returnVal = {};

    if (selectedJob.inComingChanges) {
        selectedJob.inComingChanges.forEach(change => {
            if (change.impact === "once" && moment(change.replaceDT).isSame(selectedJob.startDT)) {
                //console.log(" checkEmployeeTasks ONCE change: ", change)
                change.employees ? selectedJob.employees = change.employees : delete change.employees;
                change.tasks ? selectedJob.tasks = change.tasks : delete change.tasks;
                change.extraTodo ? selectedJob.extraTodo = change.extraTodo : delete change.tasks;

                returnVal = selectedJob;
            }
            if (change.impact === "range" && moment(selectedJob.startDT).isBetween(change.until, change.endDT)) {
                console.log(" checkEmployeeTasks RANGE change: ", change)
                change.employees ? selectedJob.employees = change.employees : delete change.employees;
                change.tasks ? selectedJob.tasks = change.tasks : delete change.tasks;
                change.extraTodo ? selectedJob.extraTodo = change.extraTodo : delete change.extraTodo;

                returnVal = selectedJob;
            }
            else {
                returnVal = selectedJob;
            }
        });
    } else {
        returnVal = selectedJob;
    }

    return returnVal;
};

/*
 * checking occurrence of incoming changes when rendering calendar events for fullcalendar
 * check if this event has some changed properties in extendeddprops and add it to "selectedJob"
 */
export const checkIncomingChanges = (_selectedJob, extProps) => {
    let returnVal = {};
    let selectedJob = {..._selectedJob}

    //console.log(" checkIncomingChanges selectedJob.inComingChanges: ", selectedJob.inComingChanges)
    if (selectedJob.inComingChanges) {
        selectedJob.inComingChanges.forEach(change => {

            /*console.log("checkIncomingChanges change: ", change.impact, change.replaceDT, selectedJob.startDT, extProps,
                change.impact === "once" && moment(change.replaceDT).isSame(selectedJob.startDT),
                change.impact === "onward" && moment(selectedJob.startDT).isBetween(change.until, change.endDT))
            */

            //if once change has been made twice need to compare with lastly modified time, e.g. the following is not enough
            //if (change.impact === "once" && moment(change.replaceDT).isSame(selectedJob.startDT)) {

            //date from change and time from job occurrence
            const date = moment(change.replaceDT).format("YYYYMMDD");
            const time = moment(selectedJob.startDT).format("HHmmss");
            const replaceDT = moment(date + 'T' + time);

            
            console.log(" checkIncomingChanges change: comparing dates ", change.replaceDT, " and ", '' +
                replaceDT.format("YYYYMMDDTHHmmss"), " with ", selectedJob.startDT,
                selectedJob);
            

            if (change.impact === "once" && (moment(change.replaceDT).isSame(selectedJob.startDT) || replaceDT.isSame(selectedJob.startDT))) {
                console.log(" checkIncomingChanges ONCE change: ", change, extProps)
                extProps.employees ? selectedJob.employees = extProps.employees : delete change.employees;
                extProps.isOntime ? selectedJob.isOntime = extProps.isOntime : delete change.isOntime;
                extProps.endOntime ? selectedJob.endOntime = extProps.endOntime : delete change.endOntime;
                extProps.alternateMaxEmp ? selectedJob.maxEmp = extProps.alternateMaxEmp : delete change.maxEmp;
                extProps.alternateTitle ? selectedJob.title = extProps.alternateTitle : delete change.title;

                selectedJob.changeImpact = change.impact;
                selectedJob.modifiedBy = change.modifiedBy;
                returnVal = selectedJob;
            }
            //TODO: IS THIS SUPPOSED TO BE RANGE CHANGE, NO ONWARD? - was onward!!!
            //else if (change.impact === "onward" && moment(selectedJob.startDT).isBetween(change.until, change.endDT)) {
            else if (change.impact === "range" && moment(selectedJob.startDT).isBetween(change.rangeStart, moment(change.rangeEnd).endOf("day"))) {
                console.log(" checkIncomingChanges RANGE change: ", selectedJob, change, extProps)
                extProps.employees ? selectedJob.employees = extProps.employees : delete change.employees;
                extProps.isOntime ? selectedJob.isOntime = extProps.isOntime : delete change.isOntime;
                extProps.endOntime ? selectedJob.endOntime = extProps.endOntime : delete change.endOntime;
                extProps.alternateMaxEmp ? selectedJob.maxEmp = extProps.alternateMaxEmp : delete change.maxEmp;
                extProps.alternateTitle ? selectedJob.title = extProps.alternateTitle : delete change.title;

                if (change.rangeStart ) {
                    selectedJob.rangeInfo = {rangeStart: change.rangeStart, rangeEnd: change.rangeEnd}
                }

                selectedJob.changeImpact = change.impact;
                selectedJob.modifiedBy = change.modifiedBy;

                returnVal = selectedJob;
            }
            else {
                console.log(" checkIncomingChanges ELSE change: ", selectedJob, change, extProps)
                returnVal = selectedJob;
            }
        });
    } else {
        //check for RecurringRuleForwardChange is done this day - then set the modifier
        if (selectedJob.createInfo && moment(selectedJob.createInfo.startDT).isSame(selectedJob.startDT)) {
            console.log(" checkIncomingChanges NEW ONWARD CHANGE: ", selectedJob, extProps)
            if (selectedJob.createInfo.reason === "onward") {
                selectedJob.changeImpact = selectedJob.createInfo.reason;
                selectedJob.modifiedBy = selectedJob.createInfo.by;

            } else { 
                //console.log(" checkIncomingChanges - NO: BUT createInfo found ELSE ONWARD CHANGE: ", selectedJob, extProps)
            } 
        } else {//just a new job
           // console.log(" checkIncomingChanges NO:  ELSE CHANGE: ", selectedJob, extProps)
        }

        returnVal = selectedJob;
    }

    return returnVal;
};

export const formatJobDates = (job) => {

    console.log("formatJobDates called for job ", job);

    if (job.inComingChanges) {
        job.inComingChanges.forEach(change => {
            if (change.impact === "once") {
                change.startDT = moment(change.startDT).format('YYYYMMDDTHHmmss');
                change.endDT = moment(change.endDT).format('YYYYMMDDTHHmmss');
                change.replaceDT = moment(change.replaceDT).format('YYYYMMDDTHHmmss');
                //RETHINK: if once type change occurs many times this does not keep the original startDT as replaceDT ->
            }
            if (change.impact === "range") {
                //change.replaceStart = moment(change.replaceStart).format('YYYYMMDDTHHmmss');
                //change.replaceEnd = moment(change.replaceEnd).format('YYYYMMDDTHHmmss');
                //change.startDT = moment(change.startDT).format('YYYYMMDDTHHmmss');
                //change.endDT = moment(change.endDT).format('YYYYMMDDTHHmmss');
                //change.nextNormal = moment(change.nextNormal).format('YYYYMMDDTHHmmss');
                change.replaceDT = moment(change.replaceDT).format("YYYYMMDDTHHmmss");
                change.rangeStart = moment(change.rangeStart).format("YYYYMMDDTHHmmss");
                change.startDuringRange = moment(change.startDuringRange).format("YYYYMMDDTHHmmss");
                change.endDuringRange = moment(change.endDuringRange).format("YYYYMMDDTHHmmss");
                change.rangeEnd = moment(change.rangeEnd).format("YYYYMMDDTHHmmss");

            }
        });
        job.startDT = moment(job.startDT).format('YYYYMMDDTHHmmss');
        job.endDT = moment(job.endDT).format('YYYYMMDDTHHmmss');
    }
    else {
        job.startDT = moment(job.startDT).format('YYYYMMDDTHHmmss');
        job.endDT = moment(job.endDT).format('YYYYMMDDTHHmmss');
    }

    console.log("job after formatJobDates ", job)
    return job;
};