import React, {Component} from 'react';

import firebase from 'firebase/app';
import "firebase/functions";
import 'firebase/firestore';

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import {
    authenticateSender,
    formInvoiceAndUrl,
    sendWithCustomOrWithout,
    sendWithGeneratedAttachment
} from './BillDetailsComponents/sendToServer';

import {
    Button,
    CircularProgress
} from '@material-ui/core';

import GlobalConfirmationDialog from "../CommonDialogs/GlobalConfirmationDialog";
import BillDetailsInformation from './BillDetailsComponents/BillDetailsInformation';
import BillDetailsAdditional from './BillDetailsComponents/BillDetailsAdditional';
import BillDetailsProductRows from './BillDetailsComponents/BillDetailsProductRows';
import BillDetailsSummary from './BillDetailsComponents/BillDetailsSummary';
import CustomerSelectDialog from "./BillDetailsComponents/CustomerSelectDialog";
import {createBillAttachment} from "./BillDetailsComponents/PDFAttachment";
import { formEmptyBill, customerObject, refNumberCounter, billSummaryPrices} from "./BillDetailsComponents/InvoiceObjectForming";
import {
    changeIntToString,
    changeStingToInt,
    changeToDot
} from "./BillDetailsComponents/BillNumberHelpers";
import { checkNested, isEmpty } from "../validations/isEmpty";
import moment from 'moment';

import "./BillDetails.css";
import {solveBillingErrorMessage} from "../util/BillServerErrorHelper";

import { Toast, ToastWrapper } from "show-toast-box"

import {validateCrmEmail} from "../validations/crmEmail";


class BillDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            customerTemplates: [],
            employees: [],
            models: [],
            bill: {},
            company: {},
            loading: true,
            saving: false,
            sending: false,
            deleting: false,
            creatingAttachment: false,
            anchorEl: null,
            anchorEs: null,
            selectTarget: [],
            dateField: "",
            value: "",
            priceWithVat: false,
            deliveryData: {},
            deliveryIsSame: true,
            billWorklogs: [],
            gotCompany: false,
            attachmentUrl: "",
            uploadedAttachmentName: "",
            uploadedAttachment: "",
            sendAttachment: true,
            sendAttachmentAsEmail: true,
            customerDialog: false,
            oldBill: {},
            confirmationDialog: false,
            confirmationMode: '', 
        };

        this.wrapper = React.createRef();

    }

    componentDidMount() {
        const {match: {params}} = this.props;
        const {user} = this.props.user;
        const {company} = this.props.company;

        const employees = [];
        const models = [];

        //why have all snapshots here? what is gonna change? making changes in another window - is this necessary?

        this.unsubscribeModelListener = firebase.firestore().collection("company").doc(user.companyID).collection("jobModels").onSnapshot(qyerySnap => {

            qyerySnap.forEach(doc => {
                const model = doc.data();
                model.id = doc.id;
                models.push(model);
            });

            this.setState({
                models
            });
        });

        this.unsubscribeEmployeeListener = firebase.firestore().collection("employees").where("companyID", "==", user.companyID).onSnapshot(querySnap => {

            querySnap.forEach(doc => {
                const employee = doc.data();
                employee.id = doc.id;
                employee.name = `${employee.firstName} ${employee.familyName}`;
                employees.push(employee);
            });

            this.setState({
                employees
            });
        });

        this.unsubscribeProductListener = firebase.firestore().collection('company').doc(user.companyID).collection('products').onSnapshot(this.onProductSnapshot);

        this.unsubscribeCustomerListener = firebase.firestore().collection("customers")
            .where("companyID", "array-contains", user.companyID).onSnapshot(querySnap => {
                const customers = [];

                querySnap.forEach(doc => {
                    const customer = doc.data();
                    customer.id = doc.id;
                    customers.push(customer);
                });

                this.setState({
                    customers
                });
            });

        if (params.billId) {
            this.unsubscribeBillListener = firebase.firestore().collection('company').doc(user.companyID).collection('bills').doc(params.billId).onSnapshot(docSnapshot => {
                if(docSnapshot && docSnapshot.exists) {
                    let bill = docSnapshot.data();
                    bill.id = docSnapshot.id;
                    const asAttachment = company.settings?.billing?.sending?.sendTimeReportAttachment;
                    const attAsEmail = company.settings?.billing?.sending?.sendTimeReportAsEmailAttachment;

                    let sendAttachment = (asAttachment === "false" || asAttachment === false )? false : true;
                    let sendAttachmentAsEmail = (attAsEmail === "true" || attAsEmail === true ) ? true : false;
                    
                    if (bill.type && bill.type === "compensation") {
                        sendAttachment = false;
                        sendAttachmentAsEmail = false;
                    }
                    this.getWorklogs(bill, employees, models);

                    bill = changeIntToString(bill, user);

                    this.setState({
                        sendAttachment,
                        sendAttachmentAsEmail,
                        bill: bill,
                        loading: false
                    });
                } else {
                    //bill not found
                    this.setState({
                        loading: false
                    });
                }
            });
        }

        this.unsubscribeCompanyListener = firebase.firestore().collection("company").doc(user.companyID).onSnapshot(docSnap => {
            const company = docSnap.data();
            company.id = docSnap.id;

            this.setState({
                company
            });
 
            if (!params.billId) {
                this.setState({
                    bill: formEmptyBill(company, user),
                    loading: false,
                    sendAttachment: false,
                    sendAttachmentAsEmail: false,
                });
            }
        });
    }

    toastaa = (type, title, description, duration=5000) => {

        if (type === "success") {
            this.wrapper.current.add(
                <Toast 
                    title={title} 
                    description={description}
                    type="success" 
                    animation="slide-up" 
                    autoRemove={true} 
                    removeWhenClick={true} 
                    delay={1} 
                    duration={duration} 
                    animationDuration={2000} 
            />
            )
        } else if (type === "error") {
            this.wrapper.current.add(
                <Toast 
                    title={title} 
                    description={description}
                    type="error" 
                    animation="slide-up" 
                    removeWhenClick={true} 
                    delay={1} 
                    animationDuration={2000} 
            />
            )
        } else if (type === "warning") {
            this.wrapper.current.add(
                <Toast
                    title={title} 
                    description={description}
                    type="warning" 
                    animation="slide-up" 
                    autoRemove={true} 
                    removeWhenClick={true} 
                    delay={1} 
                    duration={duration} 
                    animationDuration={2000} 
                />
            )
        }
    }

    onProductSnapshot = querySnapshot => {
        const products = [];

        querySnapshot.forEach(doc => {
            const product = doc.data();
            product.id = doc.id;
            products.push(product);
        });
        this.setState({
            products: products
        });
    };

    getWorklogs = (bill, employees, models) => {
        const {t} = this.props;
        const {user} = this.props.user;
        const {company} = this.props.company;
        const {products} = this.props.products;
        const wIds = bill.worklogs;
        const customer = bill.billObj.customer;


        this.setState({ creatingAttachment: true });
        // If there is worklogs get them + create pdf attachment
        if (bill.worklogs) {
            this.unsubscribeWorklogListener = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").onSnapshot(querySnap => {

                const billWorklogs = [];

                querySnap.forEach(doc => {
                    const worklog = doc.data();
                    worklog.id = doc.id;

                    if (wIds.includes(worklog.id)) billWorklogs.push(worklog)
                });

             

                createBillAttachment(t, billWorklogs, company, bill.billObj.customer, employees, products, models, user, bill.billObj.rows)
                    .then(response => {
                        this.setState({ 
                            attachmentUrl: `data:application/pdf;base64,${response}`, 
                            creatingAttachment: false})
                        });

                this.setState({ billWorklogs });
            });
        }
        // If no worklogs create attachment based on rows
        else {
            createBillAttachment(t, [], company, bill.billObj.customer, employees, products, models, user, bill.billObj.rows)
                .then(response => this.setState({ attachmentUrl: `data:application/pdf;base64,${response}`, creatingAttachment: false}));
        }

    };

    setAttachmentToState = dataUrl => {
        this.setState({
            attachmentUrl: `data:application/pdf;base64,${dataUrl}`
        });
    };

    componentWillUnmount() {
        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
        this.unsubscribeModelListener && this.unsubscribeModelListener();
        this.unsubscribeBillListener && this.unsubscribeBillListener();
        this.unsubscribeProductListener && this.unsubscribeProductListener();
        this.unsubscribeWorklogListener && this.unsubscribeWorklogListener();
        this.unsubscribeCompanyListener && this.unsubscribeCompanyListener();
    }

    handleChange = (event, field) => {
        const {bill} = this.state;
        bill.type = event.target.value;
        this.setState({
            bill
        });
    };

    onCustomerSelect = customer => {
        const {bill} = this.state;

        bill.customerID = customer.id;
        bill.billObj.customer = customerObject(customer);
        
        console.log("bill.sendingMethod 1", bill.sendingMethod);

        console.log("customer ", bill.billObj.customer, customer);

        if(bill.billObj.customer.defaultSendingMethod)  {
            bill.sendingMethod.sending = bill.billObj.customer.defaultSendingMethod;
        }   
        console.log("bill.sendingMethod 2", bill.sendingMethod);
        
        // Find customer templates -> This is for selecting products when creating new bill
        const customerTemplates = [];
        this.state.models && this.state.models.forEach(model => {
            if (model.customer === customer.id) customerTemplates.push(model);
        });

        this.setState({
            bill,
            customerTemplates,
            customerDialog: false
        });
    };

    changeInvoiceFields = (event, field) => {
        const {bill} = this.state;

        if (field === "timeToPay") {
            bill.billObj.invoice[field] = event;
            bill.billObj.invoice.dates.dueFinish = moment(bill.billDate).add(event, "days").format('YYYYMMDDTHHmmss');
        }
        else if (field === "sendingMethod") {
            bill.sendingMethod.sending = event.toLowerCase();
            //TODO: fix the billing-fee accordingly
        }
        else if (field === "paymentTracking") {
            bill.sendingMethod.paymentTracking = event.toLowerCase();
        }
        else if (field === "currency") {
            bill.billObj.invoice[field] = event;
        }
        else {
            bill.billObj.invoice[field] = event.target.value;
        }

        this.setState({
            bill: bill,
            anchorEs: null
        });
    };

    handleDateChange = (event, field) => {
        const {bill} = this.state;

        if (field === 'billDate' || field === 'deliveryDate') {
            bill.billDate = moment(event.selection.startDate).format('YYYYMMDDTHHmmss');

            bill.billObj.invoice.dates.dueFinish = moment(bill.billDate).add(bill.billObj.invoice.timeToPay, "days").format("YYYYMMDDTHHmmss");
        }
        else {
            bill.billObj.invoice.dates.dueFinish = moment(event.selection.startDate).format('YYYYMMDDTHHmmss');

            // Calculate new timeToPay value
            const daysBetween = (moment(bill.billObj.invoice.dates.dueFinish).startOf("day") - moment(bill.billDate).startOf("day")) / (1000*60*60*24);
            bill.billObj.invoice.timeToPay = daysBetween;
        }

        this.setState({
            anchorEl: null,
            bill: bill
        });
    };

    changeCustomerFields = (event, middle, field) => {
        const {bill} = this.state;

        bill.billObj.customer[middle][field] = event.target.value;

        this.setState({
            bill: bill
        });
    };

    changeDeliveryFields = (event, field) => {
        const {deliveryData} = this.state;

        deliveryData[field] = event.target.value;

        this.setState({
            deliveryData: deliveryData
        });
    };

    handleMenu = (event, field) => {
        const {bill} = this.state;

        const value = field === "billDate" || field === "deliveryDate" ? moment(bill.billDate).toDate() : moment(bill.billObj.invoice.dates.dueFinish);

        this.setState({
            value: value,
            anchorEl: event.currentTarget,
            dateField: field
        });
    };


    handleSelect = (event, field) => {

        this.setState({
            anchorEs: event.currentTarget,
            selectTarget: field
        });
    };

    // Adds product to bill rows when creating new invoice
    addProductRow = (addedProduct, index) => {
        const {bill, products, employees, models, billWorklogs} = this.state;
        const {company} = this.props.company;
        const {user} = this.props.user;
        const {t} = this.props;

        const product = products.find(product => product.pNumber === addedProduct.pNumber);
        const replaceValue = user && user.lang === "fi" ? "," : ".";
        console.log("addProductRow ", product)

        bill.billObj.rows[index] = {
            pNumber: addedProduct.pNumber ? addedProduct.pNumber : "",
            name: addedProduct.name ? addedProduct.name : "",
            item: addedProduct.name ? addedProduct.name : "",
            invoiceText: addedProduct.invoiceText ? addedProduct.invoiceText : (product && product.invoiceText ? product.invoiceText : ""),
            unit: addedProduct.unit ? addedProduct.unit : "",
            quantity: user && user.lang === "fi" ? "1,00" : "1.00",
            discount: 0,
            price: product ? (parseFloat(product.sellPrice)).toFixed(2).replace(".", replaceValue) : (0).toFixed(2).replace(".", replaceValue),
            alv: product.vat > 0 ? parseFloat(product.vat) / 100 : 0,  // Ensure product.vat is parsed as a float
            manuallyAdded: true
        };


        createBillAttachment(t, billWorklogs, company, bill.billObj.customer, employees, products, models, user, bill.billObj.rows)
            .then(response => {
                this.setState({ attachmentUrl: `data:application/pdf;base64,${response}`})
            });


        this.setState({
            bill
        });
    };

    changeRowFields = (event, index, field) => {
        const {bill} = this.state;

        let value = event.target.value;

        const regEx = /^-?[0-9]{0,15}([,.]?[0-9]{0,2})$/;

        if (!regEx.test(value)) return;

        if (field === "discount") {
            if (typeof value === "string" && value.length === 0) bill.billObj.rows[index][field] = value;
            else bill.billObj.rows[index][field] = parseFloat(value) / 100;
        }
        else {
            bill.billObj.rows[index][field] = value;
        }

        createBillAttachment(this.props.t, this.state.billWorklogs, this.state.company, bill.billObj.customer, this.state.employees, this.state.products, this.state.models, this.props.user.user, bill.billObj.rows)
            .then(response => this.setState({ attachmentUrl: `data:application/pdf;base64,${response}`}));

        this.setState({
            bill: bill
        });
    };

    deleteRow = index => {
        const {bill} = this.state;

        bill.billObj.rows.splice(index, 1);

        this.setState({
            bill
        });
    };

    changeFees = (event, field) => {
        const {bill} = this.state;

        let value = event.target.value;

        const regEx = /^-?[0-9]{0,15}([,.]?[0-9]{0,2})$/;

        if (!regEx.test(value)) return;


        bill.billObj.billingFees[field] = value;
        if (field === "discount") {
            if (typeof value === "string" && value.length === 0) bill.billObj.invoice.discount = value;
            else bill.billObj.invoice.discount = (parseFloat(value) / 100)
        }


        this.setState({
            bill
        });
    };

    onBillSend = async () => {
        const {t} = this.props;
        let {bill, deliveryIsSame, deliveryData, sendAttachment, sendAttachmentAsEmail} = this.state;
        const {user} = this.props.user;
        const {company} = this.props.company;

        this.setState({sending: true});

        // Check if user has selected delivery address is the same as billing address checkbox
        if (deliveryIsSame) {
            bill.billObj.customer.deliveryIsSame = deliveryIsSame;
        }
        else {
            bill.billObj.customer.deliveryIsSame = deliveryIsSame;
            bill.billObj.customer.delivery = deliveryData;
        }

        // If user didn't make changes to invoice text, get default from settings
        bill.billObj.invoice.invoiceText = company.settings.billing.texts.defaultBill;

        let authToken = "";

        // Authenticate user
        const token = await authenticateSender();
        if (token) authToken = `Bearer ${token}`;
        else {
            this.setState({sending: false});
            console.log("Error while authenticating");
            throw "Error while authenticating";
        }

        const attachmentName = this.state.uploadedAttachmentName ? `${this.state.uploadedAttachmentName}` : `${moment().format("MM-YYYY")}-${bill.billObj.customer.contact.name.replace(/\s+/g, '').replace(/\//g, '_').replace(/&/g, '_').replace(/-/g, '')}_hours`;
        //const attachmentName = this.state.uploadedAttachmentName ? `${this.state.uploadedAttachmentName}` : `${moment().format("MM-YYYY")}-${bill.billObj.customer.contact.name.replace(/\s+/g, '').replace(/\//g, '_').replace(/&/g, '_')}_hours`;
        
        const {invoice, serverUrl} = await formInvoiceAndUrl(bill, user, company, attachmentName, sendAttachment);

        
        let res = "";
        // Send bill
        if (!this.state.uploadedAttachmentName && sendAttachment) {
            res = await sendWithGeneratedAttachment(this.state.attachmentUrl, attachmentName, invoice, serverUrl, authToken)
                .catch(error => { 
                    console.log("RETURNED ERROR 1", error, bill.sendingMethod.sending)
                    //this.toastaa("error", t("Sending failed"))
                    this.toastaa("error", t("Sending failed"), solveBillingErrorMessage(bill.sendingMethod?.sending, error, t));
                    //this.toastaa("error", t("errUploadAttachment"), `${t("errBillNumber")}: ${bill.billNumber} ${t("errBillNumberError")}: ${error}`);
                    this.setState({sending: false});
                })   
        }
        else {
            console.log("sendWithCustomOrWithout ", authToken)
            res = await sendWithCustomOrWithout(this.state.uploadedAttachmentName, invoice, serverUrl, authToken)
                .catch(error => {
                    console.log("RETURNED ERROR 2", error)
                    this.toastaa("error", t("Sending failed"), solveBillingErrorMessage(bill.sendingMethod.sending, error, t));
                    this.setState({sending: false});
                });
        }

        // If loading to server was succesful
        if (res) {
            console.log("Sending succesful");
            this.toastaa("success", t("Sending succesful"), "", 1000);
            
            // Firebase save functions
            bill.status = "Sent";
            bill = changeStingToInt(bill);
            if (bill.id) firebase.firestore().collection('company').doc(user.companyID).collection('bills').doc(bill.id).set(bill)
                .then(async () => {
                    if(sendAttachmentAsEmail) {
                        await this.sendBillAttachmentAsEmail();
                    }
                    this.props.history.goBack();
                });

            else firebase.firestore().collection('company').doc(user.companyID).collection('bills').add(bill)
                .then(() => {
                    this.incrementCompanySettings();
                });
        }
        // If problems while sending, save bill to FB with status "Created"
        else {
            this.toastaa("error", t("Sending failed"), solveBillingErrorMessage(bill.sendingMethod.sending, null, t));
            if (!bill.id) {
                bill.status = "Created";
                firebase.firestore().collection('company').doc(user.companyID).collection('bills').add(bill)
                    .then(() => {
                        this.incrementCompanySettings();
                    });
            }
        }
        
        this.setState({sending: false});
    };

    onBillSave = () => {
        this.setState({saving: true});

        let {bill} = this.state;
        const {user} = this.props.user;
        const { invoice, rows } = bill.billObj;
        const replaceValue = user && user.lang === "fi" ? "," : ".";

        console.log("onBillSave ", bill);

        //update also invoice prices info after manually adding rows (onsave)
        //this is needed for bill reports
        //TODO applu also on send+save 
        const { withoutVat, totalVat, fullPrice } = billSummaryPrices(rows, bill.billObj.billingFees, invoice, user);
        
        let billing, freight = 0;

        if (checkNested(bill, "billObj", "billingFees", "freight")) freight = changeToDot(bill.billObj.billingFees.freight);
        if (checkNested(bill, "billObj", "billingFees", "billing")) billing = changeToDot(bill.billObj.billingFees.billing);

        // Assume billingFeesVatRate is determined elsewhere based on context or user input
        const billingFeesVatRate = 0.255;  // Set to 25.5% for new VAT rate

      /*   const billingFeesWithoutVAT = billing && freight ? (parseFloat(billing) + parseFloat(freight))  : 0;
        const billingFeesVAT = billingFeesWithoutVAT ? parseFloat(billingFeesWithoutVAT) * 0.24 : 0;
        const billingFeesWithVAT = billingFeesWithoutVAT ? parseFloat(billingFeesWithoutVAT) * 1.24 : 0 */
        // Calculate billing fees without and with VAT based on the determined rate
        const billingFeesWithoutVAT = billing + freight;
        const billingFeesVAT = billingFeesWithoutVAT * billingFeesVatRate;
        const billingFeesWithVAT = billingFeesWithoutVAT * (1 + billingFeesVatRate);

        console.log("onBillSave billingWithoutVAT etc.. ", fullPrice, billingFeesWithoutVAT, totalVat, billingFeesVAT, billingFeesWithVAT)
        
        bill.billObj.invoice.due = parseFloat(changeToDot(fullPrice));
        bill.billObj.invoice.price = parseFloat(changeToDot(withoutVat)) - billingFeesWithoutVAT;
        bill.billObj.invoice.vat = parseFloat(changeToDot(totalVat)) - billingFeesVAT;
        bill.billObj.invoice.fullPrice = parseFloat(changeToDot(fullPrice));
       // console.log("onBillSave updated invoice obj", bill.billObj.invoice);

        if (!bill.id) bill.status = "Created";
        bill = changeStingToInt(bill);

        if (bill.id) firebase.firestore().collection('company').doc(user.companyID).collection('bills').doc(bill.id).set(bill)
            .then(() => this.props.history.goBack());
        else {
            // Filter out incomplete rows
            bill.billObj.rows = bill.billObj.rows.filter(row => (row.pNumber && row.name) || row.compensationInfo);

            firebase.firestore().collection('company').doc(user.companyID).collection('bills').add(bill)
                .then(() => {
                    this.incrementCompanySettings()
                })
        }
        this.setState({saving: false});
    };

    incrementCompanySettings = () => {
        const {user} = this.props.user;

        firebase.firestore().collection("company").doc(user.companyID).set({
            settings: {
                billing: {
                    serialNumbers: {
                        salesInvoice: firebase.firestore.FieldValue.increment(1)
                    }
                }
            }
        }, {merge: true});
        this.props.history.goBack()
    };

    createCompensationBill = () => {
        const {company} = this.state;
        const {user} = this.props.user;
        const compensationBill = JSON.parse(JSON.stringify(this.state.bill));

        // Delete original bill.id
        delete compensationBill.id;

        compensationBill.type = "compensation";
        compensationBill.status = "Created";

        compensationBill.billNumber = company.settings.billing.serialNumbers.salesInvoice + 1;
        compensationBill.billObj.invoice.referenceNumber = refNumberCounter(compensationBill.billNumber);

        const replaceValue = user && user.lang === "fi" ? "," : ".";

        compensationBill.billObj.rows && compensationBill.billObj.rows.forEach(row => {
            if (row.price) row.price = (parseFloat(changeToDot(row.price)) * -1).toFixed(2).replace(".", replaceValue);
        });

        if (checkNested(compensationBill, "billObj", "billingFees", "freight")) compensationBill.billObj.billingFees.freight = (parseFloat(changeToDot(compensationBill.billObj.billingFees.freight))  * -1).toFixed(2).replace(".", replaceValue);
        if (checkNested(compensationBill, "billObj", "billingFees", "billing")) compensationBill.billObj.billingFees.billing = (parseFloat(changeToDot(compensationBill.billObj.billingFees.billing)) * -1).toFixed(2).replace(".", replaceValue);

        const priceAndQuantity = user && user.lang === "fi" ? "0,00" : "0.00";

        const compensationRow = {pNumber: 0, name: `Hyvitys laskuun ${this.state.bill.billNumber}`, invoiceText: "", unit: "", quantity: priceAndQuantity, discount : 0, price: priceAndQuantity, alv: 0, compensationInfo: true };
        compensationRow.item = compensationRow.name;
        compensationBill.billObj.rows.push(compensationRow);

        compensationBill.billDate = moment().format("YYYYMMDDTHHmmss");
        compensationBill.billObj.invoice.dates.deliveryDate = moment().format("YYYYMMDDTHHmmss");
        compensationBill.billObj.invoice.dates.dueFinish = moment().add(company.settings.billing.paymentCondition.default, "days").format("YYYYMMDDTHHmmss")

        this.setState({ bill: compensationBill, sendAttachment: false, sendAttachmentAsEmail: false, });
    };

    onBillDelete = () => {
        this.setState({deleting: true});
        const {id} = this.state.bill;
        const {user} = this.props.user;


        const billRef = firebase.firestore().collection("company").doc(user.companyID).collection("bills").doc(id);

        const batch = firebase.firestore().batch();
        batch.delete(billRef);

        this.state.billWorklogs.forEach(worklog => {
            const status = worklog.previousStatus ? worklog.previousStatus : "Confirmed";
            const logRef = firebase.firestore().collection("company").doc(user.companyID).collection("worklogs").doc(worklog.id);
            batch.set(logRef, {status}, {merge: true})
        });

        batch.commit().then(() => console.log("Success"));

        this.setState({deleting: false});

        this.props.history.goBack();
    };

    sendBillAttachmentAsEmail = async () => {
        const {company} = this.props.company;
        const {attachmentUrl, customers, bill} = this.state;
        const {t} = this.props;

        //TEST ONLY
        const testCustomer = customers.find(customer => customer.id === "SNEooLHjA6S6EhKsAii4")

        const customer = customers.find(customer => customer.id === bill.customerID)

        console.log("only sending to testCustomer & customer & company & bill ", testCustomer, customer, company, bill)

        const email = {
            emailAddress: `${company.email}`,
            pdfName: `${t("TimeReportName")}`,
            useAttachment: true,
            attachmentString: attachmentUrl.replace("data:application/pdf;base64,", ""),
            selectedCustomers: [testCustomer],   
            title: `${company.name} - laskunne numero ${bill.billNumber} aikaraportti`,
            message: `Hyvä asiakkaamme, \n\nOhessa laskunne numero ${bill.billNumber} aikaraportti. \n\nAikaraportista näette tuntilaskun työaikaerittelyn\n\nTerveisin,\n\n${company.name}`,
        }
        // Validate email
        const {errors, isValid} = validateCrmEmail(email);

        if (!isValid) {
            console.log("emailSending  ERR: ", errors)
            this.toastaa("error", t("Email sending failed"), `${t("Email sending format error")} ${errors.message}`, 5000);
            return;
        }

        this.setState({sendingEmail: true})

        //this.setState({sending: true})

        firebase.functions().httpsCallable("crmEmail")(email)
            .then(() => {
                //this.setState({emailSent: true, sending: false});
                console.log("emailSending for customers: ", email.selectedCustomers)
                this.toastaa("success", t("Sending timereport by email succesful"), "", 1000);
                this.setState({sendingEmail: false});
            })
            .catch(err => {
                console.log("ERROR SENDING EMAIL ATTHMNT", err);
                this.toastaa("error", t("Email sending failed"), `${t("Email sending failed desc")} ${err}`, 5000);
                this.setState({sendingEmail: false});
                //this.setState({emailSent: false, sending: false});
            })
    }

    render() {
        const {loading, sending, saving, deleting, bill, sendingEmail, creatingAttachment} = this.state;
        const {company} = this.props.company;
        const {t} = this.props;

        console.log("BILLOBJ ", bill)

        return (
            <div className="details-content">
                {!loading && bill && bill.customerID && company && company.settings &&
                    <div className="details-container">
                         
                        <BillDetailsInformation
                            bill={this.state.bill}
                            company={company}
                            anchorEl={this.state.anchorEl}
                            anchorEs={this.state.anchorEs}
                            dateField={this.state.dateField}
                            value={this.state.value}
                            selectTarget={this.state.selectTarget}
                            priceWithVat={this.state.priceWithVat}


                            changePriceType={() => this.setState({priceWithVat: !this.state.priceWithVat})}
                            onChange={this.handleChange}
                            onDateChange={this.handleDateChange}
                            onInvoiceChange={this.changeInvoiceFields}


                            handleMenu={this.handleMenu}
                            handleSelect={this.handleSelect}
                            closeMenu={() => this.setState({anchorEl: null, anchorEs: null})}
                            openCustomerDialog={() => this.setState({customerDialog: true})}
                        />

                        <BillDetailsAdditional
                            bill={this.state.bill}
                            deliveryData={this.state.deliveryData}
                            changeContact={this.changeCustomerFields}
                            changeDelivery={this.changeDeliveryFields}
                            changeInvoice={this.changeInvoiceFields}
                            selectDelivery={() => this.setState({deliveryIsSame: !this.state.deliveryIsSame})}
                            deliveryIsSame={this.state.deliveryIsSame}
                        />

                        <BillDetailsProductRows
                            bill={this.state.bill}
                            products={this.state.products}
                            priceWithVat={this.state.priceWithVat}
                            changeRows={(event, index, field) => this.changeRowFields(event, index, field)}
                            customerTemplates={this.state.customerTemplates}
                            addProductRow={this.addProductRow}
                            deleteRow={this.deleteRow}
                        />

                        <BillDetailsSummary
                            bill={this.state.bill}
                            attachment={this.state.attachmentUrl}
                            billingFees={this.state.bill.billObj.billingFees}
                            onChange={this.changeFees}
                            onUpload={(name, string) => this.setState({uploadedAttachmentName: name, uploadedAttachment: string})}
                            uploadedAttachmentName={this.state.uploadedAttachmentName}
                            uploadedAttachment={this.state.uploadedAttachment}
                            sendAttachment={this.state.sendAttachment}
                            sendAttachmentAsEmail={this.state.sendAttachmentAsEmail}
                            setSendAttachment={val => this.setState({ sendAttachment: val })}
                            setSendAttachmentAsEmail={val => this.setState({ sendAttachmentAsEmail: val })}
                            onSendBillAttachmentAsEmail={() => this.sendBillAttachmentAsEmail()}
                        />

                        <div className="details-button-container">
                            <Button
                                variant="contained"
                                style={{marginRight: 20, backgroundColor: '#b22717', color: '#FFF'}}
                                onClick={() => this.setState({confirmationMode: 'delete', confirmationDialog: true})}
                            >
                                {t("Delete")}
                            </Button>

                            <Button
                                variant="contained"
                                style={{marginRight: 20, backgroundColor: '#1770B2', color: '#FFF'}}
                                onClick={() => this.props.history.goBack()}
                            >
                                {t("Cancel")}
                            </Button>

                            {(!bill.type || bill.type !== "compensation") &&
                                <Button
                                    variant="outlined"
                                    style={{marginRight: 20, backgroundColor: '#1770B2', color: '#FFF'}}
                                    onClick={() => this.setState({confirmationMode: 'create', confirmationDialog: true})}
                                >
                                    {t("CreateCompensation")}
                                </Button>
                            }

                            <Button
                                variant="outlined"
                                color="primary"
                                style={{marginRight: 20, backgroundColor: '#1770B2', color: '#FFF'}}
                                onClick={() => this.onBillSave()}
                            >
                                {t("Save")}
                            </Button>

                            <Button
                                variant="outlined"
                                style={{backgroundColor: '#1770B2', color: '#FFF'}}
                                onClick={() => this.onBillSend()}
                                disabled={sending || loading || saving || creatingAttachment}
                            >
                                {sending && <CircularProgress/>}
                                {sendingEmail && <CircularProgress/>}
                                {t("Send")}
                            </Button>

                                <br/>
                        </div>
                    </div>
                }

                {loading &&
                <div style={{
                    alignSelf: 'center',
                    width: '50vw',
                    height: 'auto',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginTop: '3%'
                }}>
                    <CircularProgress/>
                </div>
                }
                
                {this.state.customerDialog &&
                    <CustomerSelectDialog
                        open={this.state.customerDialog}
                        onClose={() => this.setState({customerDialog: false})}
                        customers={this.state.customers}
                        onCustomerSelect={this.onCustomerSelect}
                    />
                }

                <GlobalConfirmationDialog
                    open={this.state.confirmationDialog}
                    onClose={() => this.setState({ confirmationDialog: false})}
                    onConfirm={() => this.setState({ confirmationDialog: false}, () => this.state.confirmationMode === "delete" ? this.onBillDelete() : this.createCompensationBill())}
                    title={t("Attention")}
                    message={this.state.confirmationMode === "delete" ? `${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DeleteActionBill")}?` : `${t("ActionConfirmation")} ${t("CreateAction")} ${t("CreateActionCompensation")}`}
                    mode={this.state.confirmationMode}
                />

                {/* Needed so we can get base64 url barcode*/}
                <canvas id="barcode" style={{opacity: 0}}>

                </canvas>


                <ToastWrapper ref={this.wrapper} />

            </div>            
        );
    }
}

const mapStateToProps = state => ({
    user: state.user,
    company: state.company,
    products: state.products
});

export default connect(mapStateToProps, {})(withTranslation()(BillDetails));
