import {GET_EMPLOYEES} from "../actions/types";

const initialState = {
    loading: true,
    employees: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEES:
            return {
                loading: false,
                employees: action.payload
            };
        default:
            return state;
    }
}
