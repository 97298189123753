import React, { Component } from "react";

import { Paper, CircularProgress } from '@material-ui/core'
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types';

import "./UtilityBar.css";
class UtilityBar extends Component {

    render() {
        const { title, searchBar, searchTerm, t, loading } = this.props;
        return (
            <div className="utilityBar-container">
                {title && <h1>{title}</h1>}


                {/*  Search Bar  */}
                {searchBar &&
                    <Paper className="utilityBar-searchBar">
                        {!loading &&
                            <div className="utilityBar-icon">
                                <Search style={{ height: 30, width: 30 }} />
                            </div>
                        }
                        {loading && <CircularProgress />}
                        
                        <input type="text" value={searchTerm} placeholder={t("Enter search text")}
                            onChange={this.props.searchOnChange}
                            onKeyPress={(event) => {
                                //console.log("onKeyPress ", event.key, event.which, event.ctrlKey, event)
                                if (event.key === "Enter" /* || event.which === 13*/) {
                                    console.log("enter painettu ", event.key)
                                    this.props.triggerSearch && this.props.triggerSearch();
                                }
                            }}
                        />
                    </Paper>

                }

                <div className="utilityBar-children">
                    {this.props.children}
                </div>
            </div>
        );
    }
}

UtilityBar.propTypes = {
    searchBar: PropTypes.bool,
    searchTerm: PropTypes.any,
    searchOnChange: PropTypes.func
};

export default UtilityBar;
