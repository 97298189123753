import firebase from 'firebase/app';
import 'firebase/firestore';

export default class FirebaseHelper {

    static async getCustomersWithEmail(emails) {
        console.log('emails', emails)
        const customers = [];
        await firebase.firestore().collection('customers').where('email', 'in', emails).get().then(customer => {
            customers.push(customer);
        });
        console.log(customers);
        return customers;
    };

    static async addReportToCustomer(report, customers, company) {
        console.log('customers', customers)
        console.log('firebaseHelper report', report);
        customers.forEach(customer => {
            customer.docs.forEach(doc => {
                firebase.firestore().collection('approveReports').add({ storagePath: report.storagePath, approved: false, company, companyReportId: report.id, customerId: doc.data().id, authorizationCode: report.authorizationCode })
            })
        })


    }

    static async getApproveReportsForCompany(company) {
        return firebase.firestore().collection('approveReports').where('company', '==', company).get().then(querySnapshot => {
            if (querySnapshot.size > 0) {
                const approveReports = [];
                querySnapshot.docs.forEach(doc => {
                    console.log('approvereports doc', doc);
                    if (doc.exists) {
                        approveReports.push(doc.data())
                    }
                });
                return approveReports;
            } else {
                return null;
            }
        });
    }
};