import React from 'react';

import {Button, Paper, TextField} from "@material-ui/core";
import {withTranslation} from "react-i18next";

const ReportSettings = props => {
    const {t, company} = props;

    if (!company.settings.reports) company.settings.reports = {};

    const onFieldChange = (event, field) => {

        company.settings.reports[field] = event.target.value;

        props.updateCompany(company);
    };

    return (
        <div style={{marginLeft: 10}}>
            <h4>{t("Report texts")}</h4>

            <TextField
                variant="outlined"
                label={t("FreeText")}
                value={company.settings.reports.approvalFreeText || ""}
                style={{
                    width: 250
                }}
                onChange={event => onFieldChange(event, "approvalFreeText")}
            />



            <Paper className="settings-footer">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => props.onSave(props.company)}
                >
                    {t("Save")}
                </Button>

            </Paper>
        </div>
    )

};

export default withTranslation()(ReportSettings);