
export const GET_USER =  "GET_USER";
export const USER_LOADING = "USER_LOADING";
export const RESET_USER = "RESET_USER";
export const COLLAPSE_MENU = "COLLAPSE_MENU";

export const SAVE_SEND = "SAVE_SEND";
export const RESET_SEND = "RESET_SEND";

// Products
export const GET_PRODUCTS = "GET_PRODUCTS";

// Worksites
export const GET_WORKSITES = "GET_WORKSITES";

// Company
export const GET_COMPANY  = "GET_COMPANY";

// Worklogs
export const GET_WORKLOGS = "GET_WORKLOGS";

// Customers
export const GET_CUSTOMERS = "GET_CUSTOMERS";

// Employees
export const GET_EMPLOYEES = "GET_EMPLOYEES";

// Models
export const GET_MODELS = "GET_MODELS";
