

import CalendarAdapter from "scheduler/lib/adapters/CalendarAdapter";
import RecurrenceExpander from "scheduler/lib/RecurrenceExpander";

export default class MobilityBookingCalendarAdapter extends CalendarAdapter {

    _start = null;
    _duration = null;
    _recurrenceRule = {};

    constructor(start, duration, recurrenceRule) {
        super();

        this._start = start;
        this._duration = duration;
        this._recurrenceRule = recurrenceRule;
    }

    getExpandedRangesBetween(from, to) {
        return RecurrenceExpander.expandEvent(this._start, this._duration, this._recurrenceRule,
            from.clone().subtract(this._duration), to)
    }
}