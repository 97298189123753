import React, {Component} from 'react';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import MapComponent from './util/GoogleMap';
import EmployeeListGenerate from './EmployeeListGenerate';
import HomeCalendar from './JobCalendarComponents/HomeCalendar'
import AuditLogComponent from "./AuditLogComponent";
//import * as firebase from 'firebase';
import firebase from 'firebase/app';
import 'firebase/firestore';
import CircularProgress from '@material-ui/core/CircularProgress';

import "moment/locale/fi";

import CalendarComponent from './CalendarComponents/CalendarComponent';

import {withTranslation} from 'react-i18next';
import {withRouter} from "react-router-dom";

/* Redux */
import {connect} from 'react-redux';
import AuditLogView from "./AuditLogView";


class HomeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            employeeCoords: [],
            loading: true,

        };
    }

    componentWillMount() {
        const {user} = this.props.user;
        console.log(user);
        this.unsubscribeEmployeeListener = firebase.firestore().collection('employees')
            .where("companyID", "==", user.companyID)
            .onSnapshot(querySnapshot => {
                let employeeList = [];
                querySnapshot.forEach(doc => {
                    let employee = doc.data();
                    employee.id = doc.id;

                    if (!employee.isDeleted && employee.lat && employee.lng) {
                        employeeList.push({
                            id: employee.id,
                            lat: employee.lat,
                            lng: employee.lng,
                            label: employee.firstName + ' ' + employee.familyName
                        });
                    }

                    if (employeeList.length > 1) {
                        this.setState({
                            employeeCoords: employeeList,
                            loading: false
                        });
                    }


                });


            });
    }


    componentDidUpdate() {
        if (this.state.loading) {
            this.componentWillMount();
        }

    }

    componentWillUnmount() {
        if (this.unsubscribeEmployeeListener) {
            this.unsubscribeEmployeeListener();
        }
    }

    handleChange = (event, value) => {
        this.setState({value});
    };

    generate = () => {
        const {loading} = this.props.user;
        const {t} = this.props;
        if (!loading) {
            return (
                <div style={{width: '100%', height: '100%', display: "flex", justifyContent: 'space-between'}}>

                    <Paper style={{height: '88vh', width: '65%'}}>
                        <HomeCalendar
                        />
                    </Paper>


                    <div style={{width: "34%", display: "flex", flexDirection: "column"}}>
                        <Paper style={{marginTop: 0, height: '88vh', overflow: 'auto'}}>
                            <div style={{
                                backgroundColor: '#2B81C1',
                                height: 50,
                                width: '100%',
                                paddingTop: 20,
                                paddingLeft: 20
                            }}>
                                <p style={{color: '#FFF', padding: 0, margin: 0, fontSize: 20}}>{t("Audit")}</p>
                            </div>
                            <AuditLogComponent/>
                        </Paper>

                        {/*
                        <Paper style={{height: 290, marginTop: 24}}>
                            <MapComponent
                                employees={this.state.employeeCoords}
                                loadingElement={<div style={{height: `100%`}}/>}
                                containerElement={<div style={{height: '100%'}}/>}
                                mapElement={<div style={{height: '100%'}}/>}
                            />
                        </Paper>
                        */}
                    </div>
                </div>
            );
        }
    };

    render() {
        const {loading} = this.props.user;

        return (
            <div style={{width: '100%', height: '100%', padding: 20}}>
                {loading && <CircularProgress/>}
                {this.generate()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

export default withRouter(connect(mapStateToProps, {})(withTranslation()(HomeDetails)));
