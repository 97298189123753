import React, { useState, useEffect } from 'react';

import {
    TextField,
    MenuItem, Button,
    ClickAwayListener,
    RadioGroup,
    Radio,
    FormControlLabel,
    CircularProgress,
} from '@material-ui/core';

import AutocompleteReports from '../AutocompleteReports';
import { withTranslation } from 'react-i18next';
import { DateRange } from 'react-date-range';
import { fi, enGB } from 'date-fns/locale'

import moment from 'moment';
import { connect } from 'react-redux';
import { isEmpty } from "../../validations/isEmpty";

const WorkshiftComponent = props => {
    const { t, i18n } = props;
    const { user } = props.user;

    const [menu, setMenu] = useState(false);
    const [anchor, setAnchor] = useState(null);
    const [searchDisabled, setSearchDisabled] = useState(false)
    const [startWeekNumber, setStartWeekNumber] = useState("")
    const [endWeekNumber, setEndWeekNumber] = useState("")

    const handleMenu = event => {
        setMenu(true);
        setAnchor(event.getBoundingClientRect());
    };

    const closeMenu = () => {
        setMenu(false);
        setAnchor(null)
    };

    const handleDate = event => {
        console.log("handleDate ", event)
        props.onChange(event, 'date');

        closeMenu();
    };

    useEffect(() => {
        console.log("useEffect is search allowed? err ", props.errors)
        if (isEmpty(props.employees)) {
            console.log(" search 1")
            setSearchDisabled(true);
        } else {
            console.log(" search 2")
            setSearchDisabled(false);
        }
    }, [props.employees]);


    useEffect(() => {
        if (props.dateRange.selection.startDate) {
            console.log("useEffect props.dateRange ", moment(props.dateRange.selection.startDate).week());
            setStartWeekNumber(moment(props.dateRange.selection.startDate).week());
            if (moment(props.dateRange.selection.startDate).week() < moment(props.dateRange.selection.endDate).week()) {
                setEndWeekNumber(moment(props.dateRange.selection.endDate).week());
            }   setEndWeekNumber("");

        }
    }, [props.dateRange]);


    console.log("WorkshiftComponent props.dateRange ", props.dateRange);

    return (
        <div>
            <TextField
                variant="outlined"
                label={t("Report type")}
                style={{
                    width: '100%'
                }}
                value={props.reportType}
                onChange={event => props.onChange(event, 'reportType')}
                select
            >
                <MenuItem key="select" value="select">{t("Select type")}</MenuItem>
                <MenuItem key="tvl" value="tvl">{t("Workshifts")}</MenuItem>
            </TextField>


            <AutocompleteReports
                searchArray={props.employees}
                field="Employee"
                label={t("Search employees")}
                onSelect={event => props.onChange(event, 'employee')}
            />

            <TextField
                variant="outlined"
                label={t("Select range")}
                style={{
                    width: '100%',
                    marginTop: 10
                }}
                value={`${moment(props.dateRange.selection.startDate).locale(user.lang ? user.lang : "fi").format('L')} - ${moment(props.dateRange.selection.endDate).locale(user.lang ? user.lang : "fi").format('L')} `}
                onClick={event => handleMenu(event.currentTarget)}
            />

            {<p>{`${t("Week")}: ${startWeekNumber} ${(endWeekNumber ? "-" : "")} ${endWeekNumber}`}</p>}

            {/* props.errors.date.length > 0 && <p style={{color: '#B00', marginLeft: 60}}>{props.errors.date}</p>*/}


            {menu &&
                <ClickAwayListener onClickAway={closeMenu}>
                    <div
                        style={{
                            position: 'absolute',
                            top: anchor.y + anchor.height,
                            left: anchor.x,
                            zIndex: 10,
                            backgroundColor: '#FFF',
                            width: anchor.width - 2,
                            borderStyle: 'solid',
                            borderColor: '#ccc',
                            borderWidth: 1,
                            borderRadius: 4,
                        }}
                    >
                        <div style={{ marginLeft: 30, marginTop: 10 }}>
                            <DateRange
                                moveRangeOnFirstSelection={false}
                                ranges={[props.dateRange.selection]}
                                style={{
                                    width: '100%',
                                }}
                                //locale={i18n.language === "fi" ? fi : enGB}
                                locale={user.lang && user.lang === "en-gb" ? enGB : fi}
                                onChange={event => handleDate(event)}
                            />

                        </div>


                    </div>
                </ClickAwayListener>
            }


            <br />

            <Button
                variant="outlined"
                style={{
                    marginTop: 20,
                    marginLeft: 50
                }}
                onClick={() => props.onReset()}
            >
                {t("Reset")}
            </Button>

            <Button
                disabled={searchDisabled}
                variant="outlined"
                color={searchDisabled ? "secondary" : "primary"}
                style={{
                    marginLeft: 50,
                    marginTop: 20
                }}
                onClick={() => props.onSearch()}
            >
                {t("Search")}
                {props.searchingJobs &&
                    <CircularProgress />}
            </Button>
        </div>
    );
};

const mapsStateToProps = state => ({
    user: state.user
});

export default connect(mapsStateToProps, {})(withTranslation()(WorkshiftComponent));
