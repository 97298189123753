import React, {Component} from 'react';

import {connect} from 'react-redux';

import firebase from 'firebase/app';
import 'firebase/firestore';

import moment from 'moment-timezone';
import "moment/locale/en-gb";
import "moment/locale/fi";

import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

//import enGb from 'react-date-range/dist/locale/en-GB';
import { fi, enGB} from 'date-fns/locale'

import CalendarComponent from './CalendarComponent';
import ColorDialog from '../ColorDialog';

import {
    Grid,
    IconButton,
    Checkbox,
} from '@material-ui/core';

class CalendarComp extends Component {

    constructor(props) {

        super(props);

        this.state = {
            dateRange: {
                selection: {
                    startDate: moment(),
                    endDate: moment(),
                    key: 'selection',
                }
            },
            activeView: "agendaWeek",
            activeDays: 7,
            employees: [],
            employeeArr: [],
            employeeFilter: [],
            colorDialog: false,
            loading: true,
            defaultDate: null,
            employeeMode: false,
            employeeAction: null,
            employeeID: "",
            colorChanged: false,
            employeeColor: {},
            key: ""
        };
    }

    componentWillMount() {

        this.unsubscribeEmployeeListener =
            firebase.firestore().collection('employees').onSnapshot(this.onEmployeesSnapshot);
    }

    onEmployeesSnapshot = querySnapshot => {

        const {user} = this.props.user;

        let employees = [];
        let employeeArr = [];

        querySnapshot.forEach(doc => {

            let employee = doc.data();
            employee.id = doc.id;

            if (employee.companyID === user.companyID) {
                employees.push(employee);
                employeeArr.push(employee.id);
            }
        });

        this.setState({
            employees: employees,
            employeeArr: employeeArr
        });
    };

    componentWillUnmount() {

        this.unsubscribeEmployeeListener && this.unsubscribeEmployeeListener();
    }

    getEmployee = (id, field) => {

        const {employees} = this.state;

        const employee = employees && employees.find(employee => employee.id === id);

        if (field === "color") {
            return employee.color ? employee.color : "#cccccc";
        }

        if (field === "name") {
            return employee ? `${employee.firstName} ${employee.familyName}` : "";
        }

        return "";
    };

    handleRangeChange = (event) => {
        let dur = (event.selection.endDate - event.selection.startDate) / 86400000;

        const dateRange = {
            selection: {
                key: 'selection',
                startDate: event.selection.startDate,
                endDate: moment(event.selection.endDate).endOf("day").toDate()
            }
        };

        this.setState({
            dateRange: dateRange,
            defaultDate: event.selection.startDate,
            activeDays: dur + 1,
            activeView: 'selectedView',
        });
    };

    saveColor = (newColor) => {

        const {selectedEmployee} = this.state;

        let id = selectedEmployee.id;

        delete selectedEmployee.id;

        selectedEmployee.color = newColor.hex;


        this.setState({
            colorDialog: false,
        });

        return firebase.firestore().collection('employees').doc(id).set(selectedEmployee);
    };

    filterByEmployee = (event) => {

        let {employeeArr, employeeFilter, key} = this.state;

        let id = event.target.value;

        let empArr = [];

        if (employeeArr) {

            if (employeeArr.includes(id)) {
                empArr = employeeArr.filter(emp => emp !== id);

                this.setState({
                    employeeMode: true,
                    employeeArr: empArr,
                    employeeID: id,
                    employeeAction: 'remove'
                });
            }
            else {
                employeeArr.push(id);
                this.setState({
                    employeeMode: true,
                    employeeArr: employeeArr,
                    employeeID: id,
                    employeeAction: 'add'
                });
            }
        }

        if (!employeeFilter.includes(id)) {
            employeeFilter.push(id)
        }

        else {
            let arr = employeeFilter.filter(item => item !== id);
            employeeFilter = arr;
        }

        if (key === id) {
            key = "akjfiöskjeöfjlskdj";
        }
        else {
            key = id
        }

        this.setState({
            key: key,
            employeeFilter: employeeFilter
        });


    };

    resetDate = () => {
        this.setState({
            defaultDate: null
        });
    };

    render() {
        const {user} = this.props.user;

        return (
            <div style={{margin: 20, width: '100%', height: '100vh'}}>
                {!this.state.addDialog &&
                    <div style={{width: '100%', display: 'flex'}}>
                        {/* Div for date selector && employee selector*/}
                        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'left', width: 300}}>

                            <div style={{width: '100%', height: 350,}}>
                                <DateRange
                                    onChange={event => this.handleRangeChange(event)}
                                    moveRangeOnFirstSelection={false}
                                    ranges={[this.state.dateRange.selection]}
                                    style={{
                                        width: '100%'
                                    }}
                                    locale={user.lang && user.lang === "en-gb" ? enGB : fi}
                                />
                            </div>
                            <br/>
                            <div style={{width: '100%', height: '100%', overflowY: 'auto'}}>
                                {this.state.employees && this.state.employees.map(employee => (
                                    <Grid container direction="row" key={employee.id}>
                                        <Grid item xs={12} md={2}>
                                            <Checkbox
                                                value={employee.id}
                                                onChange={event => this.filterByEmployee(event)}
                                                checked={this.state.employeeArr.includes(employee.id)}
                                                style={{
                                                    color: this.getEmployee(employee.id, 'color')
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={8} style={{overflowX: 'hidden'}}>
                                            <p>{employee.firstName + ' ' + employee.familyName}</p>
                                        </Grid>
                                        <Grid item xs={12} md={1}>
                                            <IconButton style={{margin: 5}} onClick={() => this.setState({
                                                colorDialog: !this.state.colorDialog,
                                                selectedColor: employee.color,
                                                selectedEmployee: employee
                                            })}>
                                                <div style={{
                                                    width: 20,
                                                    height: 20,
                                                    borderRadius: 10,
                                                    backgroundColor: this.getEmployee(employee.id, 'color')
                                                }}>

                                                </div>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                ))}
                            </div>
                        </div>

                        {/* Div for calendar*/}
                        <div style={{flexGrow: 1}}>
                            <div style={{display: 'flex', justifyContent: 'flex-end', height: '80vh', marginLeft: 20, overflowY: 'auto'}}>
                                <CalendarComponent
                                    filterBy="Calendar"
                                    defaultView={this.state.activeView}
                                    defaultDate={this.state.defaultDate}
                                    activeDays={this.state.activeDays}
                                    employeeArr={this.state.employeeFilter}
                                    resetDate={this.resetDate}
                                    key={this.state.key}
                                />

                            </div>
                        </div>
                    </div>}


                <ColorDialog
                    open={this.state.colorDialog}
                    defaultColor={this.state.selectedColor}
                    onCancel={() => this.setState({colorDialog: false})}
                    onSave={this.saveColor}
                />

            </div>
        );
    }


}

const mapStateToProps = (state) => ({
    user: state.user
});


export default connect(mapStateToProps, {})(CalendarComp);