import React, {useState, useRef} from 'react';

import {
    Grid,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    MenuItem,
    MenuList
} from '@material-ui/core';

import { ArrowDropDown } from '@material-ui/icons';

import {withTranslation} from "react-i18next";
const SelectButton = props => {
    const [open, setOpen] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const anchorRef = useRef(null);

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const handleClick = index => {
        props.onChange(props.options[index]);
        setSelectedIndex(index);
        setOpen(false);
    };

    return (
        <Grid container>
            <Grid item xs={12} align="right">
                <ButtonGroup
                    variant="contained"
                    style={{backgroundColor: '#1770B2'}}
                    ref={anchorRef}
                    aria-label="split button"
                >
                    <Button
                        style={{backgroundColor: '#1770B2', color: '#FFF'}}
                        onClick={() => handleClick(selectedIndex)}
                    >
                        {props.t(props.options[selectedIndex])}
                    </Button>

                    <Button
                        size="small"
                        style={{backgroundColor: '#1770B2', color: '#FFF'}}
                        aria-owns={open ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <ArrowDropDown/>
                    </Button>
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} transition disablePortal style={{backgroundColor: '#FFF', zIndex: 100}}>
                    {({ TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === "bottom" ? "center-top" : "center bottom"}}
                        >
                            <Paper id="menu-list-grow">
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList>
                                        {props.options.map((option, index) => (
                                            <MenuItem
                                                key={option}
                                                selected={index === selectedIndex}
                                                onClick={() => handleClick(index)}
                                            >
                                                {props.t(option)}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
};

export default withTranslation()(SelectButton);
