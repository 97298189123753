import React, {useState} from 'react';

import {
    ClickAwayListener,
    TextField,
    InputAdornment,
} from "@material-ui/core";

import SearchIcon from '@material-ui/icons/Search';

import "./Autocomplete.css";

const Autocomplete = props => {
    const [searchTerm, setSearchTerm] = useState("");
    const [searchList, setSearchList] = useState([]);
    const [anchor, setAnchor] = useState(null);


    const searchData = event => {
        const searchTerm = event.target.value;

        let searchList = [];

        props.searchArray && props.searchArray.forEach(item => {

            let combineValues = [];

            props.searchValues && props.searchValues.forEach(value => {
                combineValues.push(item[value])
            });

            const searchTarget = combineValues.join("");

            if (searchTarget.toLowerCase().search(searchTerm.toLowerCase()) > -1) searchList.push(item);
        });


        if (props.listMaxLength) searchList = searchList.splice(0, props.listMaxLength);

        setSearchTerm(searchTerm);
        setSearchList(searchList);
    };

    return (
        <div>
            <TextField
                variant={props.variant ? props.variant : "standard"}
                style={{...props.style}}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    disableUnderline: true
                }}
                value={searchTerm}
                onChange={event => searchData(event)}
                onClick={event => setAnchor(event.currentTarget.getBoundingClientRect())}
            />

            {Boolean(anchor) && searchList.length > 0 &&
            <ClickAwayListener onClickAway={() => setAnchor(null)}>
                <div
                    className="ac-list"
                    style={{
                        width: anchor.width,
                        top: anchor.top + anchor.height,
                        left: anchor.left,
                    }}
                >
                    {searchList && searchList.map((item, ind) => (
                        <div
                            className="ac-list-item"
                            key={item.id}
                            onClick={() => props.onSelect(item)}
                        >
                            {props.searchValues && props.searchValues.map((value, index) => (
                                <p style={{padding: 0, margin: 0, marginLeft: 10}}
                                   key={item[value] + index}>{item[value] + (index !== props.searchValues.length - 1 ? ", " : "")}</p>
                            ))}
                        </div>

                    ))}
                </div>
            </ClickAwayListener>
            }
        </div>
    );
};

export default Autocomplete;