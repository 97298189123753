import React from 'react';
import { useParams } from 'react-router-dom';
import firebase from "firebase/app";
import 'firebase/firestore';
import "firebase/auth";
import * as jsonwebtoken from 'jsonwebtoken';

export default function RegisterVehicle(props) {
    const { jwt } = useParams();
    const registerVehicle = jwt => {
        const vehicle = jsonwebtoken.decode(jwt)
        vehicle.user = firebase.auth().currentUser.uid;
        firebase
            .firestore()
            .collection('spoton_vehicles')
            .add(vehicle)
            .then(() => {
                window.ReactNativeWebView.postMessage(firebase.auth().currentUser.uid);
            });
    }
    registerVehicle(jwt);

    return (
        <div>
            <div>
                RegisterVehicle: {jwt}
            </div>
        </div>
    )
}