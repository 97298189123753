import React, {useState} from 'react';

import {
    CheckBoxOutlineBlank
} from '@material-ui/icons';

import classNames from 'classnames';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckSquare} from "@fortawesome/free-solid-svg-icons";
import {ArrowDropDown} from "@material-ui/icons";

import {withTranslation} from "react-i18next";



import "./CheckboxWithSelect.css";
const CheckboxWithSelect = props => {


    const checkBox = classNames({
        "cws-checkbox": !props.checked,
        "cws-checkbox-checked": props.checked
    });

    const btn = classNames({
        "checkbox-btn": true,
        "checkbox-btn-checked": props.checked
    });

    return (
        <div className="cws-container">
            <div className={checkBox} onClick={props.onChange}>
                {props.checked && <FontAwesomeIcon icon={faCheckSquare} style={{width: 30, height: 28, color: '#1770B2', margin: 0, padding: 0}}/>}
            </div>
            <button className={btn} onClick={props.onClick}>
                <ArrowDropDown style={{margin: 0, padding: 0}} />
            </button>
        </div>


    );
};

export default withTranslation()(CheckboxWithSelect);
