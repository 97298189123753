import React, {Component} from 'react';

import {
    Collapse
} from "@material-ui/core";

import {
    KeyboardArrowDown,
    KeyboardArrowUp
} from '@material-ui/icons';

import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";

import {withTranslation} from "react-i18next";
import {connect} from 'react-redux';

class HourListComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchObj: {},
            searchKeys: [],
            showMore: null,
            showMoreWeek: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prev = Object.keys(prevProps.searchObj);
        const next = Object.keys(this.props.searchObj);

        if (prev.length !== next.length) {
            this.setState({
                searchObj: this.props.searchObj,
                searchKeys: next
            });
        }
    }

    roundTimes = d => {
        return (Math.ceil(d * 2) / 2).toFixed(2);
    };


    handleCollapseMenu = key => {
        let {showMore} = this.state;

        if (key === showMore) {
            showMore = null;
        }
        else {
            showMore = key
        }

        this.setState({
            showMore
        })
    };

    handleCollapseWeeklyMenu = week => {
        const {showMoreWeek} = this.state;

        if (!showMoreWeek.includes(week)) showMoreWeek.push(week);
        else {
            const removeIndex = showMoreWeek.indexOf(week);
            showMoreWeek.splice(removeIndex, 1);
        }
        this.setState({ showMoreWeek });
    };

    render() {
        const {user} = this.props.user;
        const {searchObj} = this.props;
        const searchKeys = Object.keys(searchObj);
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomColor: '#ccc', borderBottomStyle: 'solid'}}>
                    <p style={{fontSize: 14, color: '#7d888d', width: '15%', marginLeft: 20}}>Name</p>
                    <p style={{fontSize: 14, color: '#7d888d', width: '10%'}}>Normal</p>
                    <p style={{fontSize: 14, color: '#7d888d', width: '10%'}}>Evening</p>
                    <p style={{fontSize: 14, color: '#7d888d', width: '10%'}}>Holiday</p>

                </div>
                {searchKeys && searchKeys.map((key, index) => (
                    <div key={index} style={{borderBottom: "0.5px solid #ccc"}}>
                        <div style={{display: 'flex', justifyContent: 'flex-start'}} >
                            <p style={{fontSize: 14, width: '15%', marginLeft: 20}} onClick={() => this.handleCollapseMenu(key)}>{this.props.getEmployee(key)}</p>
                            <p style={{fontSize: 14, width: '10%'}}>{(searchObj[key].normal).toFixed(2)}</p>
                            <p style={{fontSize: 14, width: '10%'}}>{(searchObj[key].evenings).toFixed(2)}</p>
                            <p style={{fontSize: 14, width: '10%'}}>{(searchObj[key].holiday).toFixed(2)}</p>
                            {this.state.showMore === key && <KeyboardArrowDown style={{paddingTop: 10}}/>}
                            {this.state.showMore !== key && <KeyboardArrowUp />}
                        </div>

                        <Collapse in={this.state.showMore === key}>
                            <div style={{width: '100%', marginLeft: 40, marginTop: 10}}>
                                {Object.keys(searchObj[key]["normalByWeek"]).map(weekNumber => (
                                    <div style={{borderBottom: "0.5px solid #1770B2", marginTop: 10}} key={weekNumber} onClick={() => this.handleCollapseWeeklyMenu(weekNumber)}>
                                        <div style={{display: "flex", }}>
                                            <p style={{margin: 0, padding: 0}}>{this.props.t("Week") + " " + weekNumber}: </p>
                                            <p style={{margin:0, padding:0, marginLeft: 10, marginRight: 20}}>{(searchObj[key]["normalByWeek"][weekNumber].normal).toFixed(2) + "h"}</p>
                                            {this.state.showMoreWeek.includes(weekNumber) && <KeyboardArrowDown />}
                                            {!this.state.showMoreWeek.includes(weekNumber) && <KeyboardArrowUp />}
                                        </div>
                                        <br/>

                                        <Collapse in={this.state.showMoreWeek.includes(weekNumber)}>
                                            <div style={{ marginLeft: 80, fontSize: 14}}>
                                                {searchObj[key]["normalByWeek"][weekNumber].worklogs.map((worklog,index) => (
                                                        <div style={{display: "flex", marginBottom: 10}} key={worklog.customer + index}>
                                                        <p style={{margin: 0, padding: 0}}>{moment(worklog.startDT).locale(user.lang ? user.lang : "fi").format("L")}</p>
                                                        <p style={{margin:0, padding: 0, marginLeft: 10}}>{" - " + this.props.getCustomer(worklog.customer) }</p>
                                                        <p style={{margin:0, padding: 0, marginLeft: 10}}>{" - " + this.props.getWorksite(worklog.worksite) + " - "}</p>
                                                        <p style={{margin: 0, padding: 0, marginLeft: 10}}>{(worklog.duration).toFixed(2) + "h"}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </Collapse>
                                    </div>
                                ))}

                            </div>
                        </Collapse>
                    </div>



                ))}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(HourListComponent));