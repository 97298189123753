import React, {Component} from 'react';

import {Dialog, DialogContent} from "@material-ui/core";

class ImageDialog extends Component{
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                style={{padding: 0, width: '100%', height: '100%'}}
            >
                <DialogContent style={{padding: 0, height: '100%', width: '100%'}}>
                    <img src={this.props.image} style={{width: '100%', height: '100%'}}/>
                </DialogContent>

            </Dialog>
        );
    }

}

export default ImageDialog;