import ReportTypes from "./ReportTypes";

const ReportSettings = [
    {
        name: 'UNDEFINED_REPORT',
        emailSubject: 'UNDEFINED_REPORT_emailSubject',
        emailMessage: 'UNDEFINED_REPORT_emailMessage'
    },
    {name: ReportTypes.HOUR_WAGE, emailSubject: 'HOUR_WAGE_emailSubject', emailMessage: 'HOUR_WAGE_emailMessage'},
    {name: ReportTypes.PERIOD_FIXED, emailSubject: 'PERIOD_FIXED_emailSubject', emailMessage: 'PERIOD_FIXED_emailMessage'},
    {name: ReportTypes.WORKTIME_PERIOD, emailSubject: 'WORKTIME_PERIOD_emailSubject', emailMessage: 'WORKTIME_PERIOD_emailMessage'},
    {name: ReportTypes.APPROVAL, emailSubject: 'APPROVAL_emailSubject', emailMessage: 'APPROVAL_emailMessage'},
    {name: ReportTypes.CUSTOMER_TIME, emailSubject: 'CUSTOMER_TIME_emailSubject', emailMessage: 'CUSTOMER_TIME_emailMessage'},
    {name: ReportTypes.CUSTOMER_BILLS, emailSubject: 'CUSTOMER_BILLS_emailSubject', emailMessage: 'CUSTOMER_BILLS_emailMessage'},
    {name: ReportTypes.BILLS, emailSubject: 'BILLS_emailSubject', emailMessage: 'BILLS_emailMessage'},
    {name: ReportTypes.KTV, emailSubject: 'KTV_emailSubject', emailMessage: 'KTV_emailMessage'},
    {name: ReportTypes.WORKLIST, emailSubject: 'WORKLIST_emailSubject', emailMessage: 'WORKLIST_emailMessage'},
];
export default ReportSettings;