import React, { Component } from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    IconButton,
    Button,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';

import {
    Close
} from '@material-ui/icons';

import { withTranslation } from "react-i18next";

import keycode from 'keycode';
import en from "../locales/en";

class TodoDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: true,
            todoText: "",
            product: {},
            selectedTask: {}
        };
    }

    componentDidMount() {
        const product = this.props.products.find(product => this.props.selectedTask.pNumber === product.pNumber);

        this.setState({
            product: product,
            selectedTask: { ...this.props.selectedTask }
        })
    }

    onKeyDown = event => {
        const { selectedTask, todoText } = this.state;
        if (keycode(event) === "enter") {
            selectedTask.todo ? selectedTask.todo.push(todoText) : selectedTask.todo = [todoText];

            this.setState({
                todoText: "",
                selectedTask: selectedTask
            });
        }

    };

    isRequired = () => {
        const { selectedTask } = this.state;

        selectedTask.requireConfirmation ? selectedTask.requireConfirmation = !selectedTask.requireConfirmation : selectedTask.requireConfirmation = "true";

        this.setState({
            selectedTask: selectedTask
        });
    };

    onDefaultAmount = event => {
        const { selectedTask } = this.state;
        selectedTask.amount = event.target.value;
        this.setState({ selectedTask });
    };

    render() {
        const { t } = this.props;
        const { selectedTask, product } = this.state;
        return (
            <Dialog
                open={this.state.dialogOpen}
                onClose={() => this.props.onClose()}
                maxWidth="sm"
                fullWidth
            >
                {(product.isMaterial === "true" || product.isMaterial === "travel") &&
                    <div>
                        <DialogTitle>{t("Require material confirmation")}</DialogTitle>
                        <DialogContent>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={selectedTask.requireConfirmation === "true"}
                                        onChange={() => this.isRequired()}
                                        style={{
                                            color: '#1770B2'
                                        }}
                                    />
                                }
                                label={t("Require")}
                            />
                            <br />
                            {selectedTask.requireConfirmation === "true" &&
                                <FormControlLabel
                                    control={
                                        <TextField
                                            variant="outlined"
                                            style={{ width: 60, marginLeft: 15, marginRight: 15 }}
                                            InputProps={{
                                                style: { height: 25 }
                                            }}
                                            value={selectedTask.amount || ""}
                                            onChange={event => this.onDefaultAmount(event)}
                                        />
                                    }
                                    label={t("DefaultAmount")}
                                />
                            }


                        </DialogContent>
                    </div>
                }



                <DialogTitle>{t("Edit tasks")}</DialogTitle>
                <DialogContent>
                    <p>{t("Task_hint")}</p>
                    <TextField
                        placeholder={t("Add task")}
                        variant="outlined"
                        value={this.state.todoText}
                        onChange={event => this.setState({ todoText: event.target.value })}
                        InputProps={{
                            onKeyDown: this.onKeyDown
                        }}
                        style={{
                            width: '100%'
                        }}
                    />
                    <div style={{ height: 300, marginTop: 10, marginLeft: 10 }}>
                        {selectedTask.todo && selectedTask.todo.map(item => (
                            <div key={item} style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    {item}
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1 }}>
                                    <IconButton>
                                        <Close />
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={() => this.props.onClose()}
                    >
                        {t("Close")}
                    </Button>

                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.props.onSave(this.state.selectedTask)}
                        style={{
                            marginRight: 20
                        }}
                    >
                        {t("Save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation()(TodoDialog);