import React from 'react';

import {
    TextField,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';

import BillDetailsListItem from './BillDetailsListItem';

import {withTranslation} from "react-i18next";

import "./BillDetailsAdditional.css";

const BillDetailsAdditional = props => {
    const {customer} = props.bill.billObj;
    const {t} = props;
    return (
        <div>
            <div className="additional-header">
                <p className="additional-header-text">{t("Address+AI")}</p>
            </div>

            <div className="additional-container">

                {/* !-------- Left Component ------------!  */}


                <div className="additional-left">
                    <p className="additional-title">{t("Billing address")}</p>

                    <BillDetailsListItem
                        left={<p style={{marginTop: 10}}>{t("Name")}: </p>}
                        right={
                            <p style={{marginTop: 10}}>{customer.contact.name}</p>
                        }
                    />

                    <BillDetailsListItem
                        left={<p style={{marginTop: 10}}>{t("Address")}: </p>}
                        right={
                            <TextField
                                variant="outlined"
                                style={{
                                    marginTop: 9
                                }}
                                InputProps={{
                                    style:{
                                        height: 25,
                                        fontSize: 14
                                    }
                                }}
                                value={customer.address.address || ""}
                                onChange={event => props.changeContact(event, 'address', 'address')}
                            />
                        }
                    />

                    <BillDetailsListItem
                        left={<p style={{marginTop: 10}}>{t("Postal code")}: </p>}
                        right={
                            <TextField
                                variant="outlined"
                                style={{
                                    marginTop: 9
                                }}
                                InputProps={{
                                    style:{
                                        height: 25,
                                        fontSize: 14
                                    }
                                }}
                                value={customer.address.postalCode || ""}
                                onChange={event => props.changeContact(event, 'address', 'postalCode')}
                            />
                        }
                    />

                    <BillDetailsListItem
                        left={<p style={{marginTop: 10}}>{t("City")}: </p>}
                        right={
                            <TextField
                                variant="outlined"
                                style={{
                                    marginTop: 9
                                }}
                                InputProps={{
                                    style:{
                                        height: 25,
                                        fontSize: 14
                                    }
                                }}
                                value={customer.address.city || ""}
                                onChange={event => props.changeContact(event, 'address', 'city')}
                            />
                        }
                    />

                    <BillDetailsListItem
                        left={<p style={{marginTop: 10}}>{t("Country")}: </p>}
                        right={
                            <TextField
                                variant="outlined"
                                style={{
                                    marginTop: 9
                                }}
                                InputProps={{
                                    style:{
                                        height: 25,
                                        fontSize: 14
                                    }
                                }}
                                value={customer.address.country || ""}
                                onChange={event => props.changeContact(event, 'address', 'country')}
                            />
                        }
                    />

                    <BillDetailsListItem
                        left={<p style={{marginTop: 10}}>{t("Phone")}: </p>}
                        right={
                            <TextField
                                variant="outlined"
                                style={{
                                    marginTop: 9
                                }}
                                InputProps={{
                                    style:{
                                        height: 25,
                                        fontSize: 14
                                    }
                                }}
                                value={customer.contact.phone || ""}
                                onChange={event => props.changeContact(event, 'contact', 'phone')}
                            />
                        }
                    />

                    <BillDetailsListItem
                        left={<p style={{marginTop: 10}}>{t("Email")}: </p>}
                        right={
                            <TextField
                                variant="outlined"
                                style={{
                                    marginTop: 9
                                }}
                                InputProps={{
                                    style:{
                                        height: 25,
                                        fontSize: 14
                                    }
                                }}
                                value={customer.contact.email || ""}
                                onChange={event => props.changeContact(event, 'contact', 'email')}
                            />
                        }
                    />
                </div>



                {/* !-------- Center Component ------------!  */}



                <div className="additional-center">
                    <p className="additional-title">{t("Delivery address")}</p>

                   <div className="additional-checkbox">
                       <FormControlLabel
                           control={
                               <Checkbox
                                   style={{
                                       color: "#1770B2"
                                   }}
                                   checked={props.deliveryIsSame || ""}
                                   onChange={() => props.selectDelivery()}
                               />
                           }
                           label={t("Same+Delivery")}
                       />
                   </div>

                    {!props.deliveryIsSame && <div>
                        <BillDetailsListItem
                            left={<p style={{marginTop: 10}}>{t("Name")}: </p>}
                            right={
                                <TextField
                                    variant="outlined"
                                    style={{
                                        marginTop: 9
                                    }}
                                    InputProps={{
                                        style:{
                                            height: 25,
                                            fontSize: 14
                                        }
                                    }}
                                    value={props.deliveryData.name || ""}
                                    onChange={event => props.changeDelivery(event, "name")}
                                />
                            }
                        />

                        <BillDetailsListItem
                            left={<p style={{marginTop: 10}}>{t("Address")}: </p>}
                            right={
                                <TextField
                                    variant="outlined"
                                    style={{
                                        marginTop: 9
                                    }}
                                    InputProps={{
                                        style:{
                                            height: 25,
                                            fontSize: 14
                                        }
                                    }}
                                    value={props.deliveryData.address || ""}
                                    onChange={event => props.changeDelivery(event, "address")}
                                />
                            }
                        />

                        <BillDetailsListItem
                            left={<p style={{marginTop: 10}}>{t("Postal code")}: </p>}
                            right={
                                <TextField
                                    variant="outlined"
                                    style={{
                                        marginTop: 9
                                    }}
                                    InputProps={{
                                        style:{
                                            height: 25,
                                            fontSize: 14
                                        }
                                    }}
                                    value={props.deliveryData.postalCode || ""}
                                    onChange={event => props.changeDelivery(event, "postalCode")}
                                />
                            }
                        />

                        <BillDetailsListItem
                            left={<p style={{marginTop: 10}}>{t("City")}: </p>}
                            right={
                                <TextField
                                    variant="outlined"
                                    style={{
                                        marginTop: 9
                                    }}
                                    InputProps={{
                                        style:{
                                            height: 25,
                                            fontSize: 14
                                        }
                                    }}
                                    value={props.deliveryData.city || ""}
                                    onChange={event => props.changeDelivery(event, "city")}
                                />
                            }
                        />

                        <BillDetailsListItem
                            left={<p style={{marginTop: 10}}>{t("Country")}: </p>}
                            right={
                                <TextField
                                    variant="outlined"
                                    style={{
                                        marginTop: 9
                                    }}
                                    InputProps={{
                                        style:{
                                            height: 25,
                                            fontSize: 14
                                        }
                                    }}
                                    value={props.deliveryData.country || ""}
                                    onChange={event => props.changeDelivery(event, "country")}
                                />
                            }
                        />

                        <BillDetailsListItem
                            left={<p style={{marginTop: 10}}>{t("Phone")}: </p>}
                            right={
                                <TextField
                                    variant="outlined"
                                    style={{
                                        marginTop: 9
                                    }}
                                    InputProps={{
                                        style:{
                                            height: 25,
                                            fontSize: 14
                                        }
                                    }}
                                    value={props.deliveryData.phone || ""}
                                    onChange={event => props.changeDelivery(event, "phone")}
                                />
                            }
                        />

                        <BillDetailsListItem
                            left={<p style={{marginTop: 10}}>{t("Email")}: </p>}
                            right={
                                <TextField
                                    variant="outlined"
                                    style={{
                                        marginTop: 9
                                    }}
                                    InputProps={{
                                        style:{
                                            height: 25,
                                            fontSize: 14
                                        }
                                    }}
                                    value={props.deliveryData.email || ""}
                                    onChange={event => props.changeDelivery(event, "email")}
                                />
                            }
                        />
                    </div>}

                </div>

                {/* !-------- Right Component ------------!  */}


                <div className="additional-right">
                    <TextField
                        variant="outlined"
                        label={t("Bill text")}
                        style={{
                            width: '80%',
                            marginLeft: '10%',
                            marginTop: 30
                        }}
                        multiline
                        minRows={5}
                        maxRows={5}
                        value={props.bill.billObj.invoice.invoiceText || ""}
                        onChange={event => props.changeInvoice(event, "invoiceText")}
                    />

                    <TextField
                        variant="outlined"
                        label={t("Notice")}
                        style={{
                            width: '80%',
                            marginLeft: '10%',
                            marginTop: 30
                        }}
                        multiline
                        minRows={5}
                        maxRows={5}
                        value={props.bill.billObj.invoice.notification || ""}
                        onChange={event => props.changeInvoice(event, "notification")}
                    />
                </div>

            </div>
        </div>
    )
};

export default withTranslation()(BillDetailsAdditional);
