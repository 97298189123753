import moment from 'moment';
import "moment/locale/fi";
import "moment/locale/en-gb";
import { isEmpty } from '../validations/isEmpty';

export const employeesToString = (logEmployees, employees) => {
    const employeeArr = [];

    if (!logEmployees || !employees) return "";

    logEmployees.forEach(id => {
        const employee = employees.find(employee => employee.id === id);
        if (employee) employeeArr.push(employee.name)
    });

    return employeeArr.join(", ");
};

export const jobEmployeesToString = (jobEmployees, employees) => {
    const employeeArr = [];

    if (!jobEmployees || !employees) return "";

    jobEmployees.forEach(empl => {
        const employee = employees.find(employee => employee.id === empl.id);
        if (employee) employeeArr.push(employee.name)
    });

    return employeeArr.join(", ");
};

export const dateTime = (field, method, user) => {
    if (isEmpty(field)) return "";
    if (method === "T") return moment(field).locale(user.lang ? user.lang : "fi").format("LT");
    else return moment(field).locale(user.lang ? user.lang : "fi").format("L");

};

export const tasksToString = worklog => {
    const taskNameArr = [];

    if (worklog.status === "Cancelled" || worklog.previousStatus === "Cancelled") {
        return "cancelled";
    }

    const billing = worklog.billing;
    if (!billing || (billing && !billing.billableHoursByTask)) return "No_task_selected"
    else {
        billing.billableHoursByTask.forEach(task => {
            taskNameArr.push(task.name);
        });
    }
    return taskNameArr.join(", ");
};

export const billableTime = (billing, user) => {
    if (!billing) return "0,0";
    else return (billing.billableHours / 3600000).toFixed(2).replace(".", user.lang === "fi" ? "," : ".");
};
