import React, { Component } from "react";

import firebase from "firebase/app";
import "firebase/firestore";

import { Button, TextField } from "@material-ui/core";
import { ChevronLeft, ChevronRight, ControlCameraOutlined } from "@material-ui/icons";
import FullCalendar from "sardius-fullcalendar-wrapper";
import "sardius-fullcalendar-wrapper/dist/fullcalendar.min.css";

import moment from "moment-timezone";
import {
  checkEmployeeTasks,
  checkIncomingChanges,
  formatJobDates,
  formIncomingChanges2,
} from "../util/JobHandleHelpers";

import ClickDialog from "./ClickDialog";
import EditDialog from "./EditDialog";
import FullscreenDialog from "./FullscreenDialog";
import CancelDialog from "./CancelDialog";
import ErrorDialog from "./ErrorDialog";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import uuid from "uuid";
import { root } from "@uirouter/react";

class FullCalendarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedJob: {},
      selectedEvent: {},
      selectedModel: {},
      rootjob: {},
      addDialog: false,
      editDialog: false,
      cancelDialog: false,
      editMode: false,
      deleteMode: false,
      recurringRule: "",
      rrStart: null,
      rrEnd: null,
      createTemplate: true,
      errorDialog: false,
      aspectRatio: this.props.fullCalendar.aspectRatio,
    };

    this.fullCalendar = React.createRef();
  }

  selectHandler = (event) => {
    this.setState({
      selectedJob: {
        startDT: event.start,
        endDT: event.end,
        rrule: {
          FREQ: "WEEKLY",
          INTERVAL: 0,
        },
        maxEmp: this.props.company?.settings?.job?.defaultEmp
          ? this.props.company.settings.job.defaultEmp
          : 0,
      },
      addDialog: true,
      editMode: false,
    });
  };

  editHandler = (event, dialog) => {
    const { jobs } = this.props;
    let { createTemplate } = this.state;

    const job = jobs.find((job) => job.id === event.event.extendedProps.jobID);

    if (job) {
      // Make deep copy so we dont screw up original data
      let selectedJob = JSON.parse(JSON.stringify(job));
      const rootjob = JSON.parse(JSON.stringify(job));
      console.log("edithandler job ", job, selectedJob);

      selectedJob.startDT = event.event.start;
      selectedJob.endDT = event.event.end;

      const rrStart = event.event.start;
      const rrEnd = event.event.end;

      const selectedEvent = event;

      /* used in draggable changes */
      if (dialog === "editDialog") {
        console.log("editDialog ");
        createTemplate = false;

        selectedJob = checkIncomingChanges(
          selectedJob,
          event.event.extendedProps
        );
      }

      if (dialog === "clickDialog" /*&& selectedJob.inComingChanges */) {
        //filter incomingchagnes in the functions, because we want to retect onward changes too
        console.log(
          "edithandler  clickDialog extendedProps ",
          event.event.extendedProps
        );

        selectedJob = checkIncomingChanges(
          selectedJob,
          event.event.extendedProps
        );

        selectedJob = checkEmployeeTasks(selectedJob);
      }

      console.log("edithandler selectedJob", dialog, selectedJob);

      if (dialog !== "clickDialog" && job.rrule && job.rrule.INTERVAL === 0) {
        this.setState({ selectedJob }, () => this.straightSaveHandler());
      } else {
        this.setState({
          rootjob,
          selectedJob,
          selectedEvent,
          rrStart,
          rrEnd,
          [dialog]: true,
          createTemplate,
        });
      }
    }
  };

  onDialogClose = () => {
    this.setState({
      clickDialog: false,
      cancelDialog: false,
      editDialog: false,
      addDialog: false,
      rrEnd: null,
      rrStart: null,
      recurringRule: "",
      createTemplate: true,
      selectedJob: {},
      selectedEvent: {},
      rootjob: {},
      editMode: false,
      deleteMode: false,
    });
  };

  jobSettingsHandler = (event, field) => {
    if (field !== "recurringRange") {
      this.setState({
        [field]: event,
      });
    } else {
      this.setState({
        rrStart: event.selection.startDate,
        rrEnd: event.selection.endDate,
      });
    }
  };

  saveRouter = (route) => {
    if (route === "add") {
      this.addHandler();
    } else if (route === "straight") {
      this.straightSaveHandler();
    } else {
      // TODO check that this works
      // Check if changes are made for same day as rootJob, if true straightSave, this way we avoid un-necessary inComingChanges
      /*if (moment(this.state.selectedJob.startDT).isSame(this.state.rootjob.startDT,"day") && !this.state.selectedJob.inComingChanges) {
        this.straightSaveHandler();
      } else {*/
      /* PJ 4.9.2023. the above does not work if one time change in mother event */
      this.recurringSaveHandler();
      /* } */
    }
  };

  createTemplate = () => {
    const { user } = this.props.user;

    const newTemplate = { ...this.state.selectedModel };
    newTemplate.name =
      this.state.selectedJob.customerLabel + " - " + "template";
    newTemplate.notes = "";
    newTemplate.type = "template";

    newTemplate.customer = this.state.selectedJob.customer;

    const docRef = firebase
      .firestore()
      .collection("company")
      .doc(user.companyID)
      .collection("jobModels")
      .doc();

    firebase
      .firestore()
      .collection("company")
      .doc(user.companyID)
      .collection("jobModels")
      .doc(docRef.id)
      .set(newTemplate);

    return docRef.id;
  };

  addHandler = () => {
    const { user } = this.props.user;
    const { selectedJob, selectedModel } = this.state;

    selectedJob.active = true;
    selectedJob.TZ = moment.tz.guess();

    selectedJob.startDT = moment(selectedJob.startDT).format("YYYYMMDDTHHmmss");
    selectedJob.endDT = moment(selectedJob.endDT).format("YYYYMMDDTHHmmss");

    selectedJob.createdBy = user.id;
    selectedJob.modifiedBy = user.id;

    if (selectedModel.type === "template")
      selectedJob.templateID = selectedModel.id;

    // If job recurringRule was not changed, set until so it wont start repeating weekly
    if (selectedJob.rrule.INTERVAL === 0)
      selectedJob.rrule.UNTIL = moment(selectedJob.endDT)
        .add(1, "hour")
        .format("YYYYMMDDTHHmmss");

    if (this.state.createTemplate) {
      selectedJob.templateID = this.createTemplate();
    }

    firebase
      .firestore()
      .collection("company")
      .doc(user.companyID)
      .collection("jobs")
      .add(selectedJob);

    this.onDialogClose();
  };

  deleteHandler = () => {
    const { user } = this.props.user;
    let { selectedJob, recurringRule, rootjob, rrStart, rrEnd, deleteMode } =
      this.state;

    if (recurringRule === "once" || recurringRule === "range") {
      // || recurringRule === "range" doesn't work for range

      // TODO add logic for delete here
      rootjob = formIncomingChanges2(
        rootjob,
        selectedJob,
        recurringRule,
        rrStart,
        rrEnd,
        deleteMode,
        user
      );

      firebase
        .firestore()
        .collection("company")
        .doc(user.companyID)
        .collection("jobs")
        .doc(rootjob.id)
        .set(rootjob);
    }
    if (recurringRule === "onward") {
      rootjob.disableDT = moment(selectedJob.startDT)
        .subtract(1, "day")
        .format("YYYYMMDDTHHmmss");

      rootjob.inComingChanges &&
        rootjob.inComingChanges.forEach((change) => {
          if (moment(change.replaceDT).isAfter(rootjob.disableDT)) {
            const removeIndex = rootjob.inComingChanges.indexOf(change);
            rootjob.inComingChanges.splice(removeIndex, 1);
          }
        });

      console.log("DeleteOnward", rootjob);
      firebase
        .firestore()
        .collection("company")
        .doc(user.companyID)
        .collection("jobs")
        .doc(rootjob.id)
        .set(rootjob);
    }
    if (recurringRule === "all") {
      firebase
        .firestore()
        .collection("company")
        .doc(user.companyID)
        .collection("jobs")
        .doc(rootjob.id)
        .delete();
    }

    this.onDialogClose();
  };

  straightSaveHandler = () => {
    const { user } = this.props.user;
    const { selectedJob } = this.state;

    console.log("straightSaveHandler selectedJob ", selectedJob);

    selectedJob.startDT = moment(selectedJob.startDT).format("YYYYMMDDTHHmmss");
    selectedJob.endDT = moment(selectedJob.endDT).format("YYYYMMDDTHHmmss");

    if (selectedJob.rrule && selectedJob.rrule.UNTIL)
      selectedJob.rrule.UNTIL = selectedJob.endDT;

    firebase
      .firestore()
      .collection("company")
      .doc(user.companyID)
      .collection("jobs")
      .doc(selectedJob.id)
      .set(selectedJob);

    this.onDialogClose();
  };

  recurringSaveHandler = () => {
    const { user } = this.props.user;
    let { rootjob, selectedJob, recurringRule, rrStart, rrEnd, deleteMode } =
      this.state;

    console.log(
      "recurringSaveHandler recurringRule = ",
      recurringRule,
      user.email, 
      selectedJob
    );

    if (recurringRule.length === 0) return this.setState({ errorDialog: true });

    if (this.state.createTemplate)
      selectedJob.templateID = this.createTemplate();

    // Do comparison of other fields
    //if (rootjob.title !== selectedJob.title) rootjob.title = selectedJob.title;
    if (rootjob.templateID !== selectedJob.templateID)
      rootjob.templateID = selectedJob.templateID;

    if (recurringRule === "onward" || recurringRule === "onward_override") {
      //onward change creates new rootJob based on selectedJob but clear from incominchanges

      // Set uuid so we can link jobs together if needed
      if (!rootjob.rrule.uuid) {
        const id = uuid();
        rootjob.rrule.uuid = id;
        selectedJob.rrule.uuid = id;
      }

      selectedJob = formatJobDates(selectedJob);
      rootjob = formatJobDates(rootjob);
      console.log("recurringSaveHandler recurringRule  ", recurringRule );

      //set disable time for the root job minus one day, startOf day
      rootjob.disableDT = moment(selectedJob.startDT).subtract(1, "day").format("YYYYMMDDTHHmmss");
      rootjob.disabledInfo = { reason: recurringRule, by: user.id };
      rootjob.modifiedBy = user.id;

      //cleanup rootjob's future recurrences and copu only them to new job
      const rootJobIncomingChangesUntilDisabled = [];
      const rootJobIncomingChangesAfterDisabled = [];

      rootjob?.inComingChanges &&
        rootjob.inComingChanges &&
        rootjob.inComingChanges.forEach((change) => {
          if (moment(change.replaceDT).isAfter(rootjob.disableDT)) {
            //also adjust change's replaceDT to new root job's startDT 
            //RETHINK 
            // so we need to get new repolaceable time from selectedJob, but date from "change"
            const newReplaceDT = moment(change.replaceDT).format("YYYYMMDD") + "T" + moment(selectedJob.startDT).format("HHmmss");
            console.log("new replceDT ", newReplaceDT)
            const newChange = {...change, replaceDT: newReplaceDT }
            rootJobIncomingChangesAfterDisabled.push(newChange);
          } else {
            const newChange = {...change, replaceDT: rootjob.startDT }  //
            rootJobIncomingChangesUntilDisabled.push(newChange);
          }
        });

      console.log("recurringSaveHandler before changed selectedJob.inComingChanges: ", rootJobIncomingChangesAfterDisabled, rootJobIncomingChangesUntilDisabled);

      //copy rootjobs incoming changes only until disabled
      if(rootJobIncomingChangesUntilDisabled?.length>0) {
        rootjob.inComingChanges = rootJobIncomingChangesUntilDisabled;
      } else {
        delete rootjob.inComingChanges;
      }

      //copy rootjobs incoming&FUTURE changes onward
      if( rootJobIncomingChangesAfterDisabled?.length>0 && recurringRule !== "onward_override") {
        //delete selectedJob.inComingChanges;
        selectedJob.inComingChanges = rootJobIncomingChangesAfterDisabled;
      } else {
        delete selectedJob.inComingChanges;
      }

      delete selectedJob.changeImpact; //delete this so that if we copy one time change into onward change root job, it will display correctly

      console.log(
        "recurringSaveHandler after delete selectedJob.inComingChanges: ",
        selectedJob, rootjob
      );

      if (rootjob.title !== selectedJob.title)
        rootjob.title = selectedJob.title;

      //set reason to new rootJob why created, so we can show who has done
      selectedJob.createInfo = {
        reason: recurringRule,
        by: user.id,
        startDT: selectedJob.startDT,
      };
      selectedJob.modifiedBy = user.id;

      console.log("recurringSaveHandler save rootjob after onward change: ", rootjob);

      firebase
        .firestore()
        .collection("company")
        .doc(user.companyID)
        .collection("jobs")
        .doc(rootjob.id)
        .set(rootjob);

      firebase
        .firestore()
        .collection("company")
        .doc(user.companyID)
        .collection("jobs")
        .add(selectedJob);
    } else {
      console.log("recurringSaveHandler ", selectedJob, recurringRule);
      //rootjob = formIncomingChanges(rootjob, selectedJob, recurringRule, rrStart, rrEnd);
      rootjob = formIncomingChanges2(
        rootjob,
        selectedJob,
        recurringRule,
        rrStart,
        rrEnd,
        deleteMode,
        user
      );
      console.log("recurringSaveHandler rootjob ", rootjob);
      firebase
        .firestore()
        .collection("company")
        .doc(user.companyID)
        .collection("jobs")
        .doc(rootjob.id)
        .set(rootjob);
    }

    this.onDialogClose();
  };

  onNext = () => {
    this.fullCalendar.current.calendar.next();
  };

  onPrev = () => {
    this.fullCalendar.current.calendar.prev();
  };

  onToday = () => {
    this.fullCalendar.current.calendar.today();
  };

  onResize = (event) => {
    const aspectRatio = event.el.clientWidth / (window.innerHeight * 0.74);

    this.setState({
      aspectRatio: aspectRatio,
    });
  };

  render() {
    const { user } = this.props.user;

    return (
      <div>
        <div style={{ padding: 10 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => this.onPrev()}
          >
            <ChevronLeft />
          </Button>

          <Button
            variant="outlined"
            color="primary"
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
            onClick={() => this.onNext()}
          >
            <ChevronRight />
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => this.onToday()}
          >
            {this.props.t("Today")}
          </Button>

          {this.props.showSearchBar && (
            <TextField
              variant="outlined"
              value={this.props.searchTerm}
              onChange={this.props.onSearch}
              style={{
                marginLeft: 10,
              }}
              InputProps={{
                style: { height: 35 },
              }}
              placeholder={this.props.t("Search")}
            />
          )}
        </div>
        <FullCalendar
          ref={this.fullCalendar}
          defaultView={this.props.fullCalendar.defaultView}
          defaultDate={moment(this.props.fullCalendar.defaultDate).toDate()}
          height={"100%"}
          aspectRatio={this.state.aspectRatio}
          events={this.props.events}
          timeZone="local"
          locale={user.lang ? user.lang : "fi"}
          firstDay={1}
          allDayText={this.props.t("all-day")}
          scrollTime={"06:00:00"}
          views={{
            selectedView: {
              type: "agenda",
              duration: { days: this.props.fullCalendar.activeDays },
            },
          }}
          header={{
            left: "",
            right: "",
          }}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
            hour12: false,
          }}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
            hour12: false,
          }}
          customButtons={{
            myToday: {
              text: this.props.t("Today"),
              click: this.onToday,
            },
          }}
          editable
          selectable
          selectHelper
          windowResize={(event) => this.onResize(event)}
          datesRender={(event) => this.props.getEvents(event)}
          select={this.selectHandler}
          eventClick={(event) => this.editHandler(event, "clickDialog")}
          eventResize={(event) => this.editHandler(event, "editDialog")}
          eventDrop={(event) => this.editHandler(event, "editDialog")}
        />

        {this.state.clickDialog && (
          <ClickDialog
            selectedJob={this.state.selectedJob}
            worksites={this.props.worksites}
            open={this.state.clickDialog}
            onDelete={() =>
              this.setState({
                deleteMode: true,
                clickDialog: false,
                editDialog: true,
              })
            }
            onClose={this.onDialogClose}
            onCancel={() =>
              this.setState({ clickDialog: false, cancelDialog: true })
            }
            onEdit={() =>
              this.setState({
                addDialog: true,
                editMode: true,
                clickDialog: false,
              })
            }
            employees={this.props.employees}
          />
        )}

        {this.state.editDialog && (
          <EditDialog
            open={this.state.editDialog}
            onClose={this.onDialogClose}
            onChange={(event) => this.setState({ recurringRule: event })}
            deleteMode={this.state.deleteMode}
            selectedJob={this.state.selectedJob}
            recurringRule={this.state.recurringRule}
            rrStart={this.state.rrStart}
            rrEnd={this.state.rrEnd}
            onDateChange={this.jobSettingsHandler}
            onSave={() => this.recurringSaveHandler()}
            onDelete={() => this.deleteHandler()}
          />
        )}

        {this.state.addDialog && (
          <FullscreenDialog
            open={this.state.addDialog}
            selectedJob={this.state.selectedJob}
            selectedModel={this.state.selectedModel}
            company={this.props.company}
            customers={this.props.customers}
            models={this.props.models}
            employees={this.props.employees}
            worksites={this.props.worksites}
            editMode={this.state.editMode}
            rrStart={this.state.rrStart}
            rrEnd={this.state.rrEnd}
            recurringRule={this.state.recurringRule}
            onChange={this.jobSettingsHandler}
            createTemplate={this.state.createTemplate}
            onClose={() => this.onDialogClose()}
            onSave={(event) => this.saveRouter(event)}
          />
        )}

        {this.state.cancelDialog && (
          <CancelDialog
            selectedJob={this.state.selectedJob}
            onClose={this.onDialogClose}
          />
        )}

        {this.state.errorDialog && (
          <ErrorDialog onClose={() => this.setState({ errorDialog: false })} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  {}
)(withTranslation()(FullCalendarComponent));
