import React, { Component } from 'react';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";

import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import moment from "moment";
import "moment/locale/fi";
import "moment/locale/en-gb";

import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';

import { createReport, uploadToFirebaseStorage } from "../reportStoreFunctions";
import TextField from "../../TextComponents/TextField";
import ReportTypes from "../ReportTypes";

class PDFHourWage2 extends Component {

    createPdf = (key) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { t } = this.props;
        const { docRef } = this.state;
        const { user } = this.props.user;

        console.log("createPdf this.props ", this.props);

        //TODO: FINISH THIS : ONE REPORT PER EMPLOYEE!! - NO LOOP HERE

        const { searchObj } = this.props;
        const searchKeys = Object.keys(searchObj);

        const worklogRows = [];

        //searchKeys && searchKeys.forEach(key => {
        const employee = this.props.getEmployee(key);
        console.log("createPdf for emplName ", employee.name);

        //const newMainLine = {text: `${name} - Yht: ${(searchObj[key].normal).toFixed(2)}h`, style: "main"};
        const newMainLine = [
            {
                text: employee.name,
                style: "main",
                fontSize: 14, bold: true
            }, { text: "" }, { text: "" }, { text: "" }, { text: (searchObj[key].normal).toFixed(2) }, { text: "h" }
        ];
        worklogRows.push(newMainLine);
        worklogRows.push([{ text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }]);
        worklogRows.push([t('Date'), t('Customer'), t('Worksite'), t('Done job'), t('Amount'), t('Unit')]);

        let worklogs = [];

        Object.keys(searchObj[key]["normalByWeek"]).forEach(weekNumber => {
            searchObj[key]["normalByWeek"][weekNumber].worklogs.forEach(worklog => worklogs.push(worklog));
        });

        worklogs = worklogs.sort((a, b) => {
            if (moment(a.startDT).isBefore(b.startDT)) return -1;
            if (moment(a.startDT).isAfter(b.startDT)) return 1;
        });

        worklogs.forEach(worklog => {
            //console.log(" createPdf worklog ", worklog.durationPerTask ? worklog.durationPerTask : " NOOOOOOU ")

            const newRow = [
                {
                    text: moment(worklog.startDT).locale(user.lang ? user.lang : "fi").format('ddd')  //weekday
                        + " " + moment(worklog.startDT).locale(user.lang ? user.lang : "fi").format("L")
                }, //time
                { text: this.props.getCustomer(worklog.customer) },
                { text: this.props.getWorksite(worklog.worksite) },
                { text: worklog.durationPerTask && !Array.isArray(worklog.durationPerTask) && worklog.durationPerTask.name ? worklog.durationPerTask.name : "" },
                { text: worklog.duration.toFixed(2) },
                { text: "h" },
            ];
            worklogRows.push(newRow);

            if (worklog.durationPerTask && Array.isArray(worklog.durationPerTask)) {
                worklog.durationPerTask.map(task => {
                    const newRow = [
                        { text: "" },
                        { text: "" },
                        { text: "" },
                        { text: task.name },
                        { text: task.duration },
                        { text: "h" },
                    ];
                    worklogRows.push(newRow)
                })
            }
        });

        //empty line between employees
        //const emptyLine = [ {text: ""}, {text: ""}, {text: ""}, {text: ""}, {text: ""}, {text: ""}];
        worklogRows.push([{ text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }, { text: "" }]);
        const endOfEmployeeLine = [
            {
                text: employee.name,
                style: "main",
            }, { text: "" }, { text: "" }, { text: "Yht: " }, { text: (searchObj[key].normal).toFixed(2) }, {
                text: "h",
                pageBreak: 'after'
            }
        ];
        worklogRows.push(endOfEmployeeLine);

        //})


        const workLogTable = {
            fontSize: 10,
            margin: [10, 0, 10, 0],
            table: {
                headerRows: 0,
                widths: [80, 80, 150, 100, 45, 45],
                body: worklogRows,
            },
            layout: "noBorders",
            //layout: 'lightHorizontalLines',
        };

        docRef.content.push(workLogTable);
        console.log("createPdf docRef for key", key);
        console.log("createPdf docRef ", docRef);

        //const summary = this.genereateSummary(reportSummary);
        //docRef.content.push(summary);

        //TODO: checkbox: "open report in browser tab"
        if (this.props.openInNewTab) pdfMake.createPdf(docRef).open();

        pdfMake.createPdf(docRef).getBase64((dataUrl) => {

            const startWeek = Object.keys(searchObj[key]["normalByWeek"])[0];
            const endWeek = Object.keys(searchObj[key]["normalByWeek"])[Object.keys(searchObj[key]["normalByWeek"]).length - 1];

            const recipients = [employee.email];
            //TODO, other default address
            const fileName = t(ReportTypes.HOUR_WAGE) + '_' + (employee.name ? employee.name : '') + "_" + t('Weeks') + ' ' + startWeek + '-' + endWeek;

            uploadToFirebaseStorage(user, dataUrl, ReportTypes.HOUR_WAGE).then(filestorageSuccess => {
                console.log('succesfully writed pdf to storage', filestorageSuccess)


                createReport(user, ReportTypes.HOUR_WAGE, employee.name, recipients, "email", fileName, dataUrl, filestorageSuccess)
                    .then((report) => {

                        console.log("storeReport success: ", report);
                        this.props.onReportsCreated(true);
                    })
                    .catch((err) => {
                        console.log("storeReport ERROR: ", err);
                        this.props.onReportsCreated(false);
                    })
            });
        });
    };

    generateHeader = (key) => new Promise((resolve, reject) => {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        const { company, t } = this.props;
        const { searchObj } = this.props;
        const searchKeys = Object.keys(searchObj);

        //const key = searchKeys[0];
        const startWeek = Object.keys(searchObj[key]["normalByWeek"])[0];
        const endWeek = Object.keys(searchObj[key]["normalByWeek"])[Object.keys(searchObj[key]["normalByWeek"]).length - 1];

        const emplName = this.props.getEmployee(key).name;
        console.log("generate header for ", emplName)

        const docRef = {
            content: [
                {
                    margin: [0, 10, 10, 10],
                    table: {
                        headerRows: 1,
                        widths: ['*', '*'],
                        body: [
                            [
                                { text: company.name, alignment: 'left' },
                                { text: "", alignment: 'right', style: "subheader" },

                            ],
                            [
                                { text: company.streetAddress, alignment: 'left' },
                                { text: "", alignment: 'right', style: "subheader" },
                            ],
                            [
                                { text: company.city + ' ' + company.postCode, alignment: 'left' },
                                { text: "", alignment: 'right', style: "subheader" },
                            ]
                        ]
                    },
                    layout: "noBorders",
                },
                [""],
                { text: t("Employee report period") },
                {/* text: moment(dateRange.selection.startDate).format('L') + ' - ' + moment(dateRange.selection.endDate).format('L') , alignment: 'left' */ },
                { text: t("Weeks") + " " + startWeek + " - " + endWeek, alignment: 'left' },
                { text: t("Employee") + " " + emplName, alignment: 'left' },
                { text: "" },

            ],
            pageMargins: [20, 30, 20, 10],
            styles: {
                subheader: {
                    fontSize: 15,
                    bold: true
                }
            }
        };

        this.setState({
            docRef: docRef
        }, () => resolve());


        /*
        setTimeout(() => {
            this.createPdf()
        }, 200);
        */
    });

    generateReports = () => {
        //pdfMake.vfs = pdfFonts.pdfMake.vfs;
        //const {t} = this.props;
        //const {docRef} = this.state;
        //const ref = {};
        //const {user} = this.props.user;
        //const body = [];


        console.log("createPdf this.props ", this.props);

        const { searchObj } = this.props;
        const searchKeys = Object.keys(searchObj);

        this.props.onReportCreate()

        searchKeys && searchKeys.forEach(key => {

            this.sleep(100).then(() => {

                this.generateHeader(key).then(() => {
                    this.createPdf(key); // one employee report
                })
            });
        })
    }


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }


    render() {
        return (
            <div style={{
                alignItems: 'center',
            }}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.generateReports()}
                    style={{
                        width: 180,
                        marginLeft: 75,
                        marginTop: 10
                    }}
                >
                    {this.props.t("Create")}
                </Button>
                <FormControlLabel
                    style={{
                        marginLeft: 75,
                        marginTop: 10,
                    }}
                    control={
                        <Checkbox
                            checked={this.props.openInNewTab}
                            onChange={(event) => this.props.onChangeOpenInNewTab(event.target.checked)}
                            value={"checkOpenOInNewTab"}
                            color="primary"
                        />
                    }
                    label={this.props.t("Open in new tab")}
                />
                <div style={{
                    marginLeft: 75,
                    marginTop: 10,
                }}>{this.props.t("Open in new tab tooltip")}</div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(PDFHourWage2));