import React, { useState, useEffect } from 'react';
import * as firebase from 'firebase';
import "firebase/storage";

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    Button,
    CircularProgress,
    TextField,
} from '@material-ui/core';

import CircularLoader from "../util/CircularLoader";

import { withTranslation } from "react-i18next";
import "./PreviewDialog.css";
import moment from "moment/moment";
import PDFComponent from '../PDFComponent';
import { getReportPdf} from '../ReportComponents/reportStoreFunctions';

const PreviewDialog = props => {
    const { sending, report, t, user } = props;

    const [pdf, setPdf] = useState(null);
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getReportPdf(report).then((pdf) => {
            setPdf(pdf);
            setLoading(false);
        }).catch((err) => {
            setLoading(false);
        })
    }, [report]);


    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth={true}
            maxWidth={loading ? 'xs' : 'lg'}
            scroll={'paper'}
        >
            {/*!loading && <DialogTitle style={{ width: '100%', textAlign: 'center' }}>{t("Report preview")}</DialogTitle>*/}

            {!loading && report &&
                <DialogContentText>
                    <div className={"dialog-information-container"}>

                        <div className={"dialog-information-field-centering-left"}>
                            <div>{t("Report type")}</div>
                            <div>{t("Created at")}</div>
                            <div>{t("Created by")}</div>
                            <div>{t("Recipients")}</div>
                            <div>{t("Status")}</div>
                        </div>

                        <div className={"dialog-information-field-centering-center"}>
                            <div>{t(report.type)}</div>
                            <div>{report.createdAt}</div>
                            <div>{report.createdBy}</div>
                            <div>{report.recipients}</div>
                            <div>{report.status}</div>
                        </div>

                        <div className={"dialog-information-field-centering-right"}>
                            <div>{report.log && t("Log")}:</div>
                            <div>{report.log && report.log.map((logi, index) =>
                                <p key={{ index }} >
                                    {t(logi.action)} {logi.to} {moment(logi.at).locale(user.lang ? user.lang : "fi").format('lll')}
                                </p>)}
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            }
            <DialogContent>
                {loading && <div style={{justifyContent: 'center', alignContent: 'center' }}>
                    <CircularLoader loading={loading} /></div>
                }

                {!loading && pdf &&
                    <div style={{ width: '100%', height: 600, textAlign: 'center' }}>
                        <PDFComponent pdf={pdf} height='100%' />
                        {/*<object data={`data:application/pdf;base64,${pdf}`} width="100%" height={"800"}
                            style={{ marginTop: 80 }} />
                */}

                    </div>
                }


            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between", marginTop: 15 }}>
                {!loading && <Button
                    onClick={props.onClose}
                    variant="contained"
                    style={{ width: '32%' }}
                >
                    {t("Cancel")}
                </Button>}

                {!loading && <Button
                    variant="contained"
                    onClick={() => props.onChangeRecipients()}
                    style={{ backgroundColor: '#1770B2', color: '#FFF', width: '32%' }}
                >
                    {t("Change recipients")}
                </Button>}

                {!loading && <Button
                    variant="contained"
                    onClick={() => props.onSend(report)}
                    style={{ backgroundColor: '#1770B2', color: '#FFF', width: '32%' }}
                >
                    {t("Send")}
                </Button>}

                {loading &&
                    <Button
                        variant="contained"
                        style={{ width: '50%' }}
                        onClick={props.onClose}
                    >
                        {t("Close")}
                    </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default withTranslation()(PreviewDialog);
