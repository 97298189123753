import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/firestore';
//import "firebase/functions";

import moment from 'moment-timezone';

import {
    Stepper,
    Step,
    StepLabel,
    Button
} from '@material-ui/core';

import CheckCode from "./CheckCode";
import CreateCompany from "./CreateCompany";
import CreateUser from "./CreateUser";
import keys from "../util/keys";
import CompleteDialog from "./CompleteDialog";
import { createMainUser } from "../SettingsComponents/CreateSettings";
import CreateRegistry from "./CreateRegistry";
import { validateJobModel, validateJobModelProductArray } from "../validations/jobmodel";
import { isEmpty } from "../validations/isEmpty";
import { withTranslation } from 'react-i18next';

let registerUser = "";

/* if (process.env.NODE_ENV === "production") { */
console.log('process.env.REACT_ENV', process.env.REACT_APP_ENV)
if (process.env.REACT_APP_ENV === "development") {
    registerUser = firebase.initializeApp(keys.fConfig, "Register");
} else {
    registerUser = firebase.initializeApp(keys.fProduction, "Register");
}

// FOR NOW AS ALWAYS RUNNING LOCALLY TO PRODUCTION - ALWAYS USE PRODUCTION ENV SETTINGS
// registerUser = firebase.initializeApp(keys.fProduction, "Register");

class RegisterLanding extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            newCompany: {
                name: "",
                streetAddress: "",
                city: "",
                business: "",
                email: "",
            },
            newUser: {
                firstName: "",
                familyName: "",
                streetAddress: "",
                city: "",
                email: "",
                password: "",
                repeatPassword: "",
                role: "admin",
                isSubcontractor: "false"
            },
            newSampleProduct: {
                pNumber: 1000,
                name: "Esimerkkituote",
                invoiceText: "Tuotekohtainen laskun teksti",
                description: "Tuotekohtainen kuvaus",
                isActive: "true",
                isBillable: "true",
                isMaterial: "false",
                sellPrice: "40.00",
                vat: 24,
                unit: "h",
            },
            // newSampleCustomer: {}, // cannot do this, customers are "global"
            newSampleJobModel: {
                type: "model",
                name: "Työmalli",
                notes: "Esimerkki työmalli - malliksi tai muokattavaksi omaan käyttöön",
                tasks: [],
            },

            codeValidated: false,
            dummyCode: "helppotyo21",
            errors: {
                code: "",
                newCompany: {},
                newUser: {},
                system: "",
            },
            completeDialog: false,
            users: [],
            sampleProducts: [],
            sampleJobModels: [],
            useGeneratedSampleRegistry: false,
        };
    }

    componentDidMount() {
        this.unsubscribeUserListener = firebase.firestore().collection('users').onSnapshot(querySnapshot => {
            const users = [];

            querySnapshot.forEach(doc => {
                const user = doc.data();
                user.id = doc.id;
                if (user.email) {
                    users.push(user)
                }
            });
            this.setState({
                users: users
            });
        });
    }

    componentWillUnmount() {
        this.unsubscribeUserListener();
    }

    validateCode = code => {
        const { errors } = this.state;
        if (code === this.state.dummyCode) {
            errors.code = "";
            this.setState({
                codeValidated: true,
                errors: errors
            });
        }
        else {
            errors.code = "Invalid code";
            this.setState({
                errors: errors
            })
        }
    };


    validateCompany = () => {
        const { newCompany } = this.state;
        const fields = ["name", "streetAddress", "city", "postCode", "email", "phone", "business", "yCode"];
        const stringRegex = /^([a-zA-Z0-9åäöÅÄÖ' _-]{1,30})$/;
        const phoneRegex = /^[0-9 +]+( [0-9]+)*$/;
        const emailRegex = /^([a-zA-Z0-9_.@-]{2,50})$/;
        const yCodeRegex = /^[0-9]{7}[-]{1}[0-9]{1}/;
        const postRegex = /^[0-9]{5}$/;

        let errorFree = 0;

        for (let i = 0; i < fields.length; i++) {
            if (fields[i] === "name" || fields[i] === "streetAddress" || fields[i] === "city" || fields[i] === "business") {
                stringRegex.test(newCompany[fields[i]]) ? errorFree++ : this.createError("company", fields[i], newCompany[fields[i]]);
            }
            if (fields[i] === "postCode") {
                postRegex.test(newCompany[fields[i]]) ? errorFree++ : this.createError("company", fields[i], newCompany[fields[i]]);
            }
            if (fields[i] === "phone") {
                phoneRegex.test(newCompany[fields[i]]) ? errorFree++ : this.createError("company", fields[i], newCompany[fields[i]]);
            }
            if (fields[i] === "email") {
                emailRegex.test(newCompany[fields[i]]) ? errorFree++ : this.createError("company", fields[i], newCompany[fields[i]]);
            }
            if (fields[i] === "yCode") {
                yCodeRegex.test(newCompany[fields[i]]) ? errorFree++ : this.createError("company", fields[i], newCompany[fields[i]]);
            }
        }

        if (errorFree === fields.length) {
            this.setState({
                activeStep: this.state.activeStep + 1
            });
        }

    };

    validateUser = () => {
        const { newUser, errors, users } = this.state;
        const fields = ["firstName", "familyName", "streetAddress", "city", "postCode", "email", "phone", "password", "repeatPassword"];
        const stringRegex = /^([a-zA-Z0-9åäöÅÄÖ' _-]{1,50})$/;
        const phoneRegex = /^[0-9 +]+( [0-9]+)*$/;
        const emailRegex = /^([a-zA-Z0-9_.@-]{2,50})$/;
        const passwordRegex = /^([A-Za-z0-9_@./#&+-]{5,30})$/;
        const postRegex = /^[0-9]{5}$/;

        let errorFree = 0;

        for (let i = 0; i < fields.length; i++) {
            if (fields[i] === "firstName" || fields[i] === "streetAddress" || fields[i] === "city" || fields[i] === "familyName") {
                stringRegex.test(newUser[fields[i]]) ? errorFree++ : this.createError("user", fields[i], newUser[fields[i]]);
            }
            if (fields[i] === "postCode") {
                postRegex.test(newUser[fields[i]]) ? errorFree++ : this.createError("user", fields[i], newUser[fields[i]]);
            }
            if (fields[i] === "phone") {
                phoneRegex.test(newUser[fields[i]]) ? errorFree++ : this.createError("user", fields[i], newUser[fields[i]]);
            }
            if (fields[i] === "email") {
                if (users.some(user => user.email === newUser.email)) {
                    errors.newUser.email = "Email already exists"
                }
                else {
                    emailRegex.test(newUser[fields[i]]) ? errorFree++ : this.createError("user", fields[i], newUser[fields[i]]);
                }
            }
            if (fields[i] === "password") {
                if (newUser.password !== newUser.repeatPassword) {
                    errors.newUser.password = "Password not match";
                    errors.newUser.repeatPassword = "Password not match";
                    this.setState({
                        errors: errors
                    });
                }
                else {
                    passwordRegex.test(newUser.password) ? errorFree++ : this.createError("user", "password", newUser.password);
                    passwordRegex.test(newUser.repeatPassword) ? errorFree++ : this.createError("user", "repeatPassword", newUser.repeatPassword);
                }
            }
        }

        if (errorFree === fields.length) {
            //this.handleSave();

            this.setState({
                activeStep: this.state.activeStep + 1
            });
        }

    };

    validateModels = () => {
        const { sampleProducts, sampleJobModels } = this.state;
        let errorFree = 0;

        if (sampleJobModels && sampleJobModels.length > 0) {
            sampleJobModels.forEach(model => {
                const { errors, isValid } = validateJobModel(model, sampleProducts);
                console.log("validateModels errors ", errors)
                if (!isEmpty(errors)) {
                    errorFree += errors.length;
                    this.setState({
                        errors: errors
                    });
                }
            })
        }

        console.log("validateModels errorFree ", errorFree);

        if (errorFree === 0) {
            this.handleSave();
            //this.updateSampleProductsAndSignout();
        }
    }

    createError = (target, field, value) => {
        const { errors } = this.state;
        if (target === "company") {
            errors.newCompany[field] = value && value.length > 0 ? "Invalid value" : "Field is required";
        }

        if (target === "user") {
            if (field === "password" || field === "repeatPassword") {
                errors.newUser.password = value && value.length < 6 ? "Password must be at least 5 characters long" : "Field is required";
                errors.newUser.repeatPassword = value && value.length < 6 ? "Password must be at least 5 characters long" : "Field is required";
            }
            else {
                errors.newUser[field] = value && value.length > 0 ? "Invalid value" : "Field is required";
            }
        }

        this.setState({
            errors: errors
        });
    };

    onChangeCompany = (event, field) => {
        const { newCompany, errors } = this.state;

        newCompany[field] = event.target.value;

        errors.newCompany[field] = "";

        this.setState({
            newCompany: newCompany,
            errors: errors
        });

    };

    onChangeUser = (event, field) => {
        const { newUser, errors } = this.state;

        newUser[field] = event.target.value;

        errors.newUser[field] = "";

        this.setState({
            newUser: newUser
        });
    };

    onChangeUserDirectValue = (value, field) => {
        const { newUser, errors } = this.state;

        newUser[field] = value;

        errors.newUser[field] = "";

        this.setState({
            newUser: newUser
        });
    };


    onAddSampleProduct = () => {
        const { useGeneratedSampleRegistry, newSampleProduct, sampleProducts, errors } = this.state;

        let sampleProduct = { ...newSampleProduct };

        sampleProduct.name = sampleProduct.name + "-" + sampleProducts.length + 1;
        sampleProduct.pNumber = sampleProduct.pNumber + 1;

        sampleProducts.push(sampleProduct);

        this.setState({
            useGeneratedSampleRegistry: true,
            sampleProducts: sampleProducts,
        })

    };

    onAddSampleModel = () => {
        const { useGeneratedSampleRegistry, newSampleJobModel, sampleProducts, sampleJobModels, errors } = this.state;

        let sampleModel = { ...newSampleJobModel };

        sampleModel.name = sampleModel.name + "-" + sampleJobModels.length + 1;

        const tasks = [];
        if (sampleProducts.length > 0) {
            sampleProducts.forEach((product, index) => {
                if (product.pNumber) {
                    let task = { ...product };
                    task.name = "Toiminto " + index;
                    task.description = product.description + " (Toiminnon nimi ja kuvaus muokattavissa työmallissa ja työpohjassa)";
                    task.invoiceText = product.invoiceText + " (Laskun teksti muokattavissa työmallissa ja työpohjassa)";
                    tasks.push(task);

                    task.name = "Toiminto " + index + "_" + index;
                    task.description = product.description + "(Samalla tuotteella voi olla useita toimintoja työmallissa ja työpohjassa)";
                    task.invoiceText = product.invoiceText + "Voi myös käyttää tuotteeseen kirjattua laskuntekstiä"

                    tasks.push(task);
                }
            });
        }
        sampleModel.tasks = tasks;

        sampleJobModels.push(sampleModel);

        this.setState({
            sampleJobModels: sampleJobModels,
        })

    };

    onRemoveSampleData = () => {

        this.setState({
            useGeneratedSampleRegistry: false,
            sampleProducts: [],
            sampleJobModels: [],
        })
    }

    /*

    updateSampleProductsAndSignout = () => {

        const {newUser, newCompany, useGeneratedSampleRegistry, sampleProducts, sampleJobModels, errors} = this.state;
        let storeCount = 0;

        console.log("updateSampleProductsAndSignout ", newCompany, useGeneratedSampleRegistry, sampleJobModels, sampleProducts)

        if (useGeneratedSampleRegistry) {
            storeCount =  sampleProducts.length + sampleJobModels.length;

            if (sampleProducts && sampleProducts.length > 0) {
                sampleProducts.forEach(product => {
                    firebase.firestore().collection('company').doc(newCompany.id).collection('products').add(product)
                        .then(res => {
                            console.log("sample product added ", res)
                            storeCount--;
                        })
                        .catch(error => {
                            console.log("sample product add failed  ", error)
                        });
                })
            }


            if (sampleJobModels && sampleJobModels.length > 0) {
                sampleJobModels.forEach(model => {
                    firebase.firestore().collection('company').doc(newCompany.id).collection('jobModels').add(model)
                        .then(res => {
                            console.log("sample jobModel added ", res)
                            storeCount--;
                        })
                        .catch(error => {
                            console.log("sample jobModel add failed  ", error)

                        });

                })
            }

            setTimeout(() => {
                console.log(" updateSampleProductsAndSignout wait for signout  ");
                if (storeCount === 0) {
                    console.log(" updateSampleProductsAndSignout signout!!  ");
                    this.signout();
                }
            }, 4000);


        } else {
            this.signout();
        }
    }
    */

    signout = () => {

        registerUser.auth().signOut();

        /*this.setState({
            completeDialog: true
        });*/

    }

    handleSave = () => {
        const { newUser, newCompany, useGeneratedSampleRegistry, sampleProducts, sampleJobModels, errors } = this.state;

        // Add mainUser permissions
        newUser.settings = {};
        newUser.settings.permissions = createMainUser();
        newUser.created = moment().format("YYYYMMDDTHHmmss");

        // Set creation day
        newCompany.created = moment().format("YYYYMMDDTHHmmss");

        const companyRef = firebase.firestore().collection('company').doc();
        newCompany.id = companyRef.id;
        this.setState(newCompany)

        firebase.firestore().collection('company').doc(companyRef.id).set(newCompany).then(function (ref) {

            console.log("company stored to FB: ", ref, companyRef, useGeneratedSampleRegistry, sampleProducts, sampleJobModels);


            if (sampleProducts && sampleProducts.length > 0) {
                sampleProducts.forEach(product => {
                    firebase.firestore().collection('company').doc(companyRef.id).collection('products').add(product)
                        .then(res => {
                            console.log("sample product added ", res)
                        })
                        .catch(error => {
                            console.log("sample product add failed  ", error)
                        });
                })
            }

            if (sampleJobModels && sampleJobModels.length > 0) {
                sampleJobModels.forEach(model => {
                    firebase.firestore().collection('company').doc(newCompany.id).collection('jobModels').add(model)
                        .then(res => {
                            console.log("sample jobModel added ", res)
                        })
                        .catch(error => {
                            console.log("sample jobModel add failed  ", error)

                        });

                })
            }

            registerUser.auth().createUserWithEmailAndPassword(newUser.email, newUser.password).then(function (firebaseUser) {

                // Delete password fields so they dont end up in FB
                delete newUser.password;
                delete newUser.repeatPassword;

                // Add companyID field to newUser
                newUser.companyID = companyRef.id;

                firebase.firestore().collection('employees').doc(firebaseUser.user.uid).set(newUser);

                registerUser.auth().signOut();  //Do this later
            });

        }).catch(error => {
            errors.system = 'Error creating company, code ' + error.code + " message: " + error.message;
            console.log("handleSave errors.system ", errors.system)
        })

        this.setState({
            completeDialog: true
        });

    };

    render() {
        const { activeStep } = this.state;
        const { t } = this.props;

        console.log("sampleProducts ", this.state.sampleProducts);
        console.log("sampleJobModels ", this.state.sampleJobModels);

        return (
            <div style={{ width: '50vw', margin: 'auto', marginTop: '5%' }}>
                <Stepper>
                    <Step active={activeStep === 0} completed={activeStep > 0}>
                        <StepLabel>{t("Enter code")}</StepLabel>
                    </Step>
                    <Step active={activeStep === 1} completed={activeStep > 1}>
                        <StepLabel>{t("Create company")}</StepLabel>
                    </Step>
                    <Step active={activeStep === 2} completed={activeStep > 2}>
                        <StepLabel>{t("Create user")}</StepLabel>
                    </Step>
                    <Step active={activeStep === 3}>
                        <StepLabel>{t("Create sample data")}</StepLabel>
                    </Step>
                </Stepper>

                {activeStep === 0 &&
                    <CheckCode
                        t={t}
                        onValidate={this.validateCode}
                    />
                }

                {activeStep === 1 &&
                    <CreateCompany
                        t={t}
                        newCompany={this.state.newCompany}
                        errors={this.state.errors}
                        onChange={this.onChangeCompany}
                    />
                }

                {activeStep === 2 &&
                    <CreateUser
                        t={t}
                        newUser={this.state.newUser}
                        newCompany={this.state.newCompany}
                        errors={this.state.errors}
                        onChange={this.onChangeUser}
                        onDirectChange={this.onChangeUserDirectValue}
                    />
                }

                {activeStep === 3 &&
                    <CreateRegistry
                        t={t}
                        sampleProducts={this.state.sampleProducts}
                        sampleJobModels={this.state.sampleJobModels}
                        useGeneratedSampleRegistry={this.state.useGeneratedSampleRegistry}
                        errors={this.state.errors}
                        onAddProduct={this.onAddSampleProduct}
                        onAddModel={this.onAddSampleModel}
                        onRemoveSampleData={this.onRemoveSampleData}
                    />
                }


                {this.state.errors.code.length > 0 &&
                    <p style={{ color: '#f00', marginTop: 10, marginBottom: 10 }}>{this.state.errors.code}</p>}
                <div style={{ marginTop: 20 }}>
                    {activeStep > 0 &&
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.setState({ activeStep: activeStep - 1 })}
                        >
                            {t("Back")}
                        </Button>}


                    {activeStep === 0 && <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.setState({ activeStep: activeStep + 1 })}
                        disabled={!this.state.codeValidated}
                    >
                        {t("Next")}
                    </Button>}

                    {activeStep === 1 &&
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.validateCompany()}
                        //onClick={() => this.setState({activeStep: activeStep + 1})}
                        >
                            {t("Next")}
                        </Button>}

                    {activeStep === 2 &&
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.validateUser()}
                        >
                            {t("Next")}
                        </Button>}

                    {activeStep === 3 &&
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.validateModels()}
                        >
                            {t("Save")}
                        </Button>
                    }
                </div>

                {this.state.errors.system.length > 0 &&
                    <p style={{ color: '#f00', marginTop: 10, marginBottom: 10 }}>{this.state.errors.system}</p>
                }


                {this.state.completeDialog &&
                    <CompleteDialog
                        t={t}
                        onClose={() => this.props.onRegister()}
                        dialogOpen={this.state.completeDialog}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(RegisterLanding));