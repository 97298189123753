import React from 'react';

import {Checkbox, FormControlLabel} from "@material-ui/core";
import moment from "moment/moment";


export const LogMenuContents = props => {
    const {t, user, logInfo} = props;
    console.log("logInfo ", logInfo)
    return (
        <div style={{marginLeft: 5, marginTop: 0}}>
            {logInfo && logInfo.map((info) => {
                return <div style={{fontSize:12}}>{t(info.action)}: {info.to}, {moment(info.at).locale(user.lang ? user.lang : "fi").format('lll')}</div>

            })
            }
        </div>
    );
};

