import React from 'react';

import "./MenuOption.css";
const MenuOption = props => {

    return (
        <div className="menuItem">
            <p>{props.children}</p>
        </div>
    )
};

export default MenuOption;
