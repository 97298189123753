import React, {Component} from 'react';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {
    CircularProgress,
    TextField,
    Button,
    MenuItem,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText as DialogText,
    DialogTitle,
    Paper, Divider, ClickAwayListener
} from "@material-ui/core";




import moment from 'moment';
import "moment/locale/fi";

import GlobalConfirmationDialog from "../CommonDialogs/GlobalConfirmationDialog";
import DateDialog from '../CalendarComponents/DateDialog';

import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";

import "./KeyListEditor.css";
class KeyListEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            key: {},
            keylist: [],
            onlyKeys: [],
            customerList: [],
            employeeList: [],
            worksites: [],
            selectedEmployee: 1,
            confirmDialog: false,
            keyNumber: null,
            errors: {
                keynumber: ""
            },
            dateDialog: false,
            value: null,
            dateField: "",
            anchorEl: null,
            searchTerm: "",
            searchList: [],
            customerWorksites: []
        };
    }

    componentDidMount() {
        const {user} = this.props.user;
        const {match: {params}} = this.props;
        if (params.keyId) {
            let id = params.keyId;
            this.unsubscribeKeyListener = firebase.firestore().collection('company').doc(user.companyID).collection('keys').doc(id).onSnapshot(docSnapshot => {
                let key = docSnapshot.data();
                key.id = docSnapshot.id;
                if (key.received) {
                    key.received = moment(new Date(key.received.seconds * 1000)).toDate();
                } else if (key.returned) {
                    key.returned = moment(new Date(key.returned.seconds * 1000)).toDate();
                }
                this.setState({
                    key: key,
                    keyNumber: key.number,
                    selectedEmployee: key.holder,
                });
            })
        }

        this.unsubscribeKeylistListener = firebase.firestore().collection('company').doc(user.companyID).collection('keys').onSnapshot(querySnapshot => {
            let keylist = [];
            let onlyKeys = [];
            querySnapshot.forEach(doc => {
                let key = doc.data();
                key.id = doc.id;
                keylist.push(key);
                onlyKeys.push(key.belongsTo);
                if (key.received) {
                    key.received = moment(new Date(key.received.seconds * 1000)).toDate();
                } else if (key.returned) {
                    key.returned = moment(new Date(key.returned.seconds * 1000)).toDate();
                }
            });
            this.setState({
                keylist: keylist,
                onlyKeys: onlyKeys
            });
        });

        this.unsubscribeWorksiteListener = firebase.firestore().collection("company").doc(user.companyID).collection("worksites").onSnapshot(querySnap => {
            const worksites = [];

            querySnap.forEach(doc => {
                const worksite = doc.data();
                worksite.id = doc.id;
                worksites.push(worksite);
            });

            this.setState({ worksites });
        })

        this.unsubscibeCustomerListener = firebase.firestore().collection('customers')
            .where('companyID', 'array-contains', user.companyID)
            .onSnapshot(querySnapshot => {
            let customerList = [];
            let searchTerm = "";
            let customerWorksites = [];
            querySnapshot.forEach(doc => {
                let customer = doc.data();
                customer.id = doc.id;
                customerList.push(customer);

                if (customer.id === this.state.key.belongsTo) {
                    searchTerm = customer.name;
                    customerWorksites = this.state.worksites.filter(site => site.customer === customer.id);
                }
            });
            this.setState({customerList, searchTerm, customerWorksites});
        });
        this.unsubscribeEmployeeListener = firebase.firestore().collection('employees')
            .where("companyID", "==", user.companyID)
            .onSnapshot(querySnapshot => {
            let employeeList = [];
            querySnapshot.forEach(doc => {
                let employee = doc.data();
                employee.id = doc.id;
                employee.name = `${employee.firstName} ${employee.familyName}`;
                employeeList.push(employee);
            });
            this.setState({
                employeeList: employeeList,
                loading: false
            });
        });


    }

    componentWillUnmount() {
        if (this.unsubscribeKeyListener) {
            this.unsubscribeKeyListener();
        }
        if (this.unsubscibeCustomerListener) {
            this.unsubscibeCustomerListener();
        }
        if (this.unsubscribeEmployeeListener) {
            this.unsubscribeEmployeeListener();
        }

        if (this.unsubscribeKeylistListener) {
            this.unsubscribeKeylistListener();
        }
    }


    checkKeyNumber = (event) => {
        const { keylist, errors } = this.state;

        const keyNumber = event.target.value;

        if (keylist.some(key => key.number === keyNumber && key.number !== this.state.key.number)) {
            errors.keynumber = "Key number already in use";
            this.setState({
                errors: errors,
                keyNumber: keyNumber
            });
        }
        else {
            errors.keynumber = "";
            this.setState({
                keyNumber: keyNumber
            });
        }
    };


    handleChange(event, field) {
        const {key} = this.state;

        key[field] = event.target.value;

        this.setState({
            key: key
        })
    }

    handleDateChange = (event) => {
        const {key, dateField} = this.state;

        key[dateField] = event.selection.startDate;

        this.setState({
            key: key,
            dateField: "",
            dateDialog: false,
            value: null
        })
    };


    onSelectChange = (event, field) => {
        const {key} = this.state;

        key[field] = event.target.value;

        this.setState({ key });
    };

    handleMenu = currentTarget => {
        console.log(currentTarget.getBoundingClientRect());

        this.setState({
            anchorEl: currentTarget.getBoundingClientRect()
        });
    };

    onCustomerSelect = customer => {
        let {key, worksites} = this.state;

        key.belongsTo = customer.id;

        const customerWorksites = worksites.filter(site => site.customer === customer.id);

        this.setState({ key, searchTerm: customer.name, anchorEl: null, customerWorksites})
    };

    searchCustomer = event => {
        const {customerList} = this.state;
        const searchTerm = event.target.value;

        const searchList = [];

        customerList.forEach(customer => {
            if (customer.name.toLowerCase().search(searchTerm.toLowerCase()) > -1) searchList.push(customer);
        });

        let shortenedList = searchList;
        if (searchList.length > 5) shortenedList = searchList.slice(0, 4);

        this.setState({
            searchTerm,
            searchList: shortenedList
        });
    };

    saveHandler() {
        const {user} = this.props.user;
        const {key, onlyKeys, errors} = this.state;
        key.number = this.state.keyNumber;

        //check if key number is duplicate
        if (!errors.keynumber) {
            console.log("noError")
            //update if we have id
            if (key.id) {
                console.log("shootSave")
                firebase.firestore().collection('company').doc(user.companyID).collection('keys').doc(key.id).set(key)
                    .then(() => {
                        this.props.history.goBack();
                    });
            }
            // else create new one
            else {
                //check if customer exists already
                //if it does, open confirm dialog
                if (onlyKeys.includes(key.belongsTo)) {
                    this.setState({
                        confirmDialog: !this.state.confirmDialog
                    })
                } else {
                    firebase.firestore().collection('company').doc(user.companyID).collection('keys').add(key)
                        .then(() => {
                            this.props.history.goBack();
                        });
                }

            }
        }
    }

    confirmSave = () => {
        const {user} = this.props.user;
        const {key} = this.state;

        this.setState({
            confirmDialog: !this.state.confirmDialog
        });

        firebase.firestore().collection('company').doc(user.companyID).collection('keys').add(key);
        this.props.history.goBack();
    };

    deleteKey = () => {
        const {key} = this.state;
        const {user} = this.props.user;
        firebase.firestore().collection('company').doc(user.companyID).collection('keys').doc(key.id).delete();

        this.props.history.goBack();
    };


    //If customer already exists, render confirm dialog
    renderConfirmDialog = () => {
        return (
            <div>
                <Dialog
                    open={this.state.confirmDialog}
                    onClose={() => this.setState({confirmDialog: !this.state.confirmDialog})}
                    maxWidth="xs"
                    fullWidth
                >
                    <DialogTitle>Add new key</DialogTitle>
                    <DialogContent>
                        <DialogText>Selected customer already has a key registered. Do you still wan't to
                            proceed?</DialogText>
                    </DialogContent>
                    <DialogActions>
                        <Button size="medium" color="secondary"
                                onClick={() => this.setState({confirmDialog: !this.state.confirmDialog})}>
                            Cancel
                        </Button>
                        <Button size={"medium"} color="primary" onClick={() => this.confirmSave()}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    renderItem = item => {

        return (
            <div className="key-list-item" key={item.id}>
                <div style={{padding: 15}} onClick={() => this.onCustomerSelect(item)}>
                     <p style={{fontSize: 16, margin: 0, padding: 0}}>{item.name}</p>
                </div>
                <Divider style={{width: '100%', backgroundColor: '#ccc'}}/>
            </div>

        )
    };

    render() {
        const { employeeList, key, anchorEl, searchList, searchTerm} = this.state;
        const {t} = this.props;
        const open = Boolean(anchorEl);

        return (
            <div style={{width: '100%', height: '100%'}}>
                {
                    this.state.loading &&
                    <CircularProgress/>
                }
                <div style={{width: '50vw', marginLeft: 'auto', marginRight: 'auto', marginTop: 10}}>
                    <Paper style={{width: '100%'}} ref={ref => this.paperContainer = ref}>
                        <div style={{width: '100%', height: '8%', backgroundColor:'#2B81C1'}}>
                            {!this.state.id && <h1 style={{color: '#FFF', paddingTop: 20, paddingLeft: 40}}>{t("Add key")}</h1>}
                            {this.state.id && <h1 style={{color: '#FFF', paddingTop: 20, paddingLeft: 40}}>{t("Edit key")}</h1>}
                        </div>

                        <div style={{padding: 20}}>

                            <TextField
                                variant="outlined"
                                label={t("Number")}
                                value={this.state.keyNumber || ""}
                                onChange={event => this.checkKeyNumber(event)}
                                error={this.state.errors.keynumber.length > 0}
                                helperText={this.state.errors.keynumber}
                            />

                            <TextField
                                variant="outlined"
                                placeholder="URI"
                                value={key.uri || ""}
                                onChange={event => this.handleChange(event)}
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                            />

                            <TextField
                                variant="outlined"
                                style={{
                                    width: '100%',
                                    marginTop: 10
                                }}
                                label={t("Customer")}
                                value={this.state.searchTerm || ""}
                                onChange={event => this.searchCustomer(event)}
                                onClick={event => this.handleMenu(event.currentTarget)}
                            />

                            {open && searchTerm.length > 0 &&
                                <ClickAwayListener onClickAway={() => this.setState({anchorEl: null})}>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: anchorEl.y + anchorEl.height,
                                            left: anchorEl.x,
                                            zIndex: 10,
                                            backgroundColor: '#FFF',
                                            width: anchorEl.width - 2,
                                            borderStyle: 'solid',
                                            borderColor: '#ccc',
                                            borderWidth: 1,
                                            borderRadius: 4
                                        }}
                                    >
                                        {searchList && searchList.map(this.renderItem)}
                                    </div>
                                </ClickAwayListener>

                            }


                            <TextField
                                variant="outlined"
                                label={t("Worksite")}
                                style={{
                                    width: '100%',
                                    marginTop: 10
                                }}
                                value={key.worksite || ""}
                                onChange={event => this.onSelectChange(event, "worksite")}
                                disabled={searchTerm.length === 0}
                                select
                            >
                                {this.state.customerWorksites && this.state.customerWorksites.map(worksite => (
                                    <MenuItem key={worksite.id} value={worksite.id}>{worksite.name}</MenuItem>
                                ))}

                            </TextField>


                            <TextField
                                variant="outlined"
                                label={t("Employee")}
                                style={{
                                    width: '100%',
                                    marginTop: 10
                                }}
                                value={key.holder || ""}
                                onChange={event => this.onSelectChange(event, 'holder')}
                                select
                            >
                                {employeeList && employeeList.map(employee => (
                                    <MenuItem key={employee.id} value={employee.id}>{employee.name}</MenuItem>
                                ))}
                            </TextField>


                            <div style={{width: '100%', marginTop: 10}}>
                                <div style={{width: "30%", display: 'flex'}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 10, paddingLeft: 10}}>
                                        {t("Received")}
                                    </div>
                                    <div style={{flexGrow: 1}}>
                                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <Button
                                                style={{width: 150}}
                                                onClick={() => this.setState({value: moment(this.state.key.received).toDate(), dateField: "received", dateDialog: true})}
                                            >
                                                {this.state.key.received && moment(this.state.key.received).locale("fi").format('L')}
                                                {!this.state.key.received && " - "}
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <br/>

                                <div style={{width: "30%", display: 'flex', marginTop: 10}}>
                                    <div style={{display: 'flex', justifyContent: 'flex-start', marginTop: 10, paddingLeft: 10}}>
                                        {t("Returned")}
                                    </div>
                                    <div style={{flexGrow: 1}}>
                                        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <Button
                                                style={{
                                                    width: 150
                                                }}
                                                onClick={() => this.setState({value: moment(this.state.key.returned).toDate(), dateField: "returned", dateDialog: true})}
                                            >
                                                {this.state.key.returned && moment(this.state.key.returned).locale("fi").format('L')}
                                                {!this.state.key.returned && " - "}
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <TextField
                                variant="outlined"
                                value={this.state.key.info || ""}
                                onChange={event => this.handleChange(event, 'info')}
                                label={t("Info")}
                                style={{
                                    marginTop: 10,
                                    width: '100%'
                                }}
                            />

                            <div style={{width: "100%%", display: 'flex', marginTop: 20, justifyContent: 'flex-end'}}>

                                    <Button
                                        variant="contained"
                                        onClick={() => this.setState({confirmationDialog: true})}
                                        style={{
                                            backgroundColor: '#b22717',
                                            color: '#FFF',
                                            marginRight: 10
                                        }}
                                    >
                                        {t("Delete")}
                                    </Button>

                                    <Button
                                        variant="contained"
                                        onClick={() => this.props.history.goBack()}
                                        style={{
                                            backgroundColor: '#1770B2',
                                            color: '#FFF',
                                            marginRight: 10
                                        }}
                                    >
                                        {t("Cancel")}
                                    </Button>

                                    <Button
                                        variant="contained"
                                        style={{
                                            backgroundColor: '#1779B2',
                                            color: '#FFF'
                                        }}
                                        onClick={() => this.saveHandler()}
                                    >
                                        {t("Save")}
                                    </Button>


                            </div>

                        </div>
                    </Paper>
                </div>

                {this.renderConfirmDialog()}

                {this.state.dateDialog &&
                    <DateDialog
                        onChange={event => this.handleDateChange(event)}
                        onClose={() => this.setState({dateDialog: false})}
                        value={this.state.value}
                        selectType="date"
                    />
                }

                <GlobalConfirmationDialog
                    open={this.state.confirmationDialog}
                    onClose={() => this.setState({ confirmationDialog: false })}
                    onConfirm={() => this.setState({ confirmationDialog: false}, () => this.deleteKey())}
                    title={t("Attention")}
                    message={`${t("ActionConfirmation")} ${t("DeleteAction")} ${t("DeleteActionKey")}?`}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps, {})(withTranslation()(KeyListEditor));
