import React, {useState, useEffect} from 'react';

import {
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    Button,
    CircularProgress,
    Chip,
    TextField,
    Tabs,
    Tab,
} from '@material-ui/core';

import Avatar from '@material-ui/core/Avatar';
import FaceIcon from '@material-ui/icons/Face';
import DoneIcon from '@material-ui/icons/Done';

import CircularLoader from "../util/CircularLoader";

import {withTranslation} from "react-i18next";
import ReportTypes from "../ReportComponents/ReportTypes";
import ReportSettings from "../ReportComponents/ReportSettings";

import {isEmpty} from "../validations/isEmpty";
import Validator from "validator";


const RecipientsDialog = props => {
    const {loading, sending, selectedReportIDs, selectedReport, reports, t} = props;
    const [errors, setErrors] = useState({});
    const [email, setEmail] = useState('');
    const [recipients, setRecipients] = useState([]);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [changed, setChanged] = useState(false);

    const chipDeleteHandler = item => {
        let list = recipients.filter(rcpt => rcpt !== item);
        setRecipients(list);
        if (!changed) setChanged(true);
    };

    const onEmailChange = (email) => {

        if (!Validator.isEmail(email)) {
            errors.emailAddress = "InvalidEmail";
            setErrors(errors);
        } else {
            delete errors.emailAddress;
            setErrors(errors);
        }

        setEmail(email);

        if (!changed) setChanged(true);
    }

    const onChangeSubject = (subject) => {
        setSubject(subject);
        if (!changed) setChanged(true);
    }

    const onChangeMessage = (message) => {
        setMessage(message);
        if (!changed) setChanged(true);
    }

    const onSave = (recipients, subject, message) => {
        setChanged(false)
        props.onSave(recipients, subject, message)
    }

    const onSend = () => {
        const {selectedReport, selectedReportIDs} = props;
        console.log("onSend selectedReport ", selectedReport, selectedReportIDs);

        if (!isEmpty(selectedReport)) props.onSend(selectedReport)
        else if (!isEmpty(recipients)) props.onMassSend(recipients, subject, message)
        else console.log("CANNOT SEND REPORT!!")
    }

    /* cannot change addresses if multiple mails selected*/

    //useEffect(() => {
    const reportType = selectedReport && selectedReport.type ? ReportSettings.find(type => type.name === selectedReport.type) : ReportSettings[0].name;

    if (!isEmpty(selectedReport) && isEmpty(recipients)) {

        if (!isEmpty(selectedReport.recipients))
            setRecipients(selectedReport.recipients);

        //Fill subject and message at first time either with message from report.email or default
        if (isEmpty(subject)) {
            if (selectedReport.email && selectedReport.email.subject) {
                setSubject(selectedReport.email.subject);
            } else {
                setSubject(t(reportType ? t(reportType.emailSubject) : "DEFAULT_REPORT_EMAIL_SUBJECT"))
            }
        }

        if (isEmpty(message)) {
            if (selectedReport.email && selectedReport.email.message) {
                setMessage(selectedReport.email.message);
            } else {
                setMessage(reportType ? t(reportType.emailMessage) : t("DEFAULT_REPORT_EMAIL_MESSAGE"))
            }
        }
    }

    //}, [selectedReport])


    const disableSaveButton = !changed || isEmpty(selectedReport);
    const disableSendButton = changed && !isEmpty(selectedReport) || isEmpty(recipients);


    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullWidth={true}
            //fullScreen={true}
        >
            {!loading && <DialogTitle style={{textAlign: 'center'}}>{t("Change recipients")}</DialogTitle>}
            <DialogContent style={{height: 400}}>
                {!loading &&
                <div style={{width: '100%', textAlign: 'center'}}>

                    <div style={{flex: 1, flexDirection: 'row', alignItems: 'flex-end', height: 55}}>

                        <TextField
                            variant="outlined"
                            value={email || ""}
                            style={{
                                width: '70%',
                                //marginTop: 10
                                height: 40,
                            }}
                            label="Vastaanottajan sähköposti"
                            onChange={event => onEmailChange(event.target.value)}
                            onKeyPress={(ev) => console.log("key ", ev.target)}
                            error={!!errors.emailAddress}
                            helperText={errors.emailAddress ? t(errors.emailAddress) : ""}
                        />

                        <Button
                            variant="contained"
                            disabled={isEmpty(email) || errors.emailAddress}
                            onClick={() => {
                                setRecipients([...recipients, email]);
                                setEmail('');
                            }}
                            style={{
                                backgroundColor: ((isEmpty(email) || errors.emailAddress) ? '#A9ACAD' : '#1770B2'),
                                color: '#FFF',
                                width: '20%',
                                height: 50,
                                marginLeft: 10
                            }}
                        >
                            {t("Add")}
                        </Button>
                    </div>

                    <div style={{flex: 1, width: '100%', flexDirection: 'row', alignItems: 'flex-start'}}>
                        <div>{t("Selected recipients")}:</div>
                        <div key={recipients}
                             style={{flex: 1, flexDirection: 'row', height: 'auto', overflow: 'auto', marginLeft: 15}}>
                            {recipients && recipients.map(recipient => (
                                <Chip
                                    key={recipient}
                                    style={{margin: 5}}
                                    label={recipient}
                                    onDelete={() => chipDeleteHandler(recipient)}
                                    variant="outlined"
                                    size="small"
                                    icon={<FaceIcon/>}
                                    color="primary"
                                    //deleteIcon={<DoneIcon />}
                                />
                            ))
                            }
                        </div>

                    </div>

                    {
                        <div style={{flex: 1, width: '100%', alignItems: 'flex-end'}}>

                            <TextField
                                variant="outlined"
                                label="Sähköpostin otsikko"
                                style={{
                                    marginTop: 10,
                                    height: 40,
                                    width: '100%'
                                }}
                                value={subject || ""}
                                onChange={event => onChangeSubject(event.target.value)}
                                error={!!errors.subject}
                                helperText={errors.subject ? t(errors.subject) : ""}
                            />


                            <TextField
                                variant="outlined"
                                label="Sähköpostin viesti"
                                style={{
                                    marginTop: 35,
                                    height: '100%',
                                    width: '100%',
                                }}
                                rows={5}
                                multiline
                                value={message.replace('<br>', '\n') || ""}
                                onChange={event => onChangeMessage(event.target.value.replace('\n', '<br>'))}
                                helperText={errors.message ? t(errors.message) : ""}
                            />

                        </div>
                    }

                </div>
                }

                {loading && <div style={{width: '100%', justifyContent: 'center', alignContent: 'center'}}>
                    <CircularLoader loading={loading}/></div>
                }

            </DialogContent>

            <DialogActions style={{justifyContent: "space-between", marginTop: 15}}>
                {!loading && <Button
                    onClick={props.onClose}
                    variant="contained"
                    style={{width: '49%'}}
                >
                    {t("Cancel")}
                </Button>}

                {!loading && <Button
                    variant="contained"
                    disabled={disableSaveButton}
                    onClick={() => onSave(recipients, subject, message)}
                    style={{
                        backgroundColor: disableSaveButton ? '#A9ACAD' : '#1770B2', color: '#FFF', width: '49%'
                    }}
                >
                    {t("Save")}
                </Button>
                }

                {!loading && <Button
                    disabled={disableSendButton}
                    variant="contained"
                    onClick={() => onSend()}
                    style={{backgroundColor: disableSendButton ? '#A9ACAD' : '#1770B2', color: '#FFF', width: '49%'}}
                >
                    {t("Send")}
                </Button>}

                {loading &&
                <Button
                    variant="contained"
                    style={{width: '100%'}}
                    onClick={props.onClose}
                >
                    {t("Close")}
                </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default withTranslation()(RecipientsDialog);
