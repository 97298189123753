import React, {Component} from 'react';

import Autocomplete from 'react-autocomplete';
import {Divider, MenuItem, TextField, IconButton} from "@material-ui/core";
import {Navigation} from '@material-ui/icons';


class AutocompleteReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: ""
        };
    }

    componentDidMount() {
        if (this.props.searchCustomer) this.setState({searchTerm: this.props.searchCustomer})
    }

    renderSearch = (props) => {
        const {InputProps, ref, ...other} = props;
        return <TextField
            placeholder={this.props.placeholder ? this.props.placeholder : ""}
            variant="outlined"
            label={this.props.label ? this.props.label : ""}
            InputProps={{
                inputRef: ref,
                ...InputProps}}
            {...other}
            style={{
                width: 330,
                marginTop: 10
            }}
        />
    };

    returnValue = event => {
        this.setState({
            searchTerm: event
        });

        this.props.onSelect(event);
    };

    changeHandler = event => {
        const searchTerm = event.target.value;

        if (searchTerm.length === 0) {
            this.props.onSelect(searchTerm)
        }

        this.setState({
            searchTerm: searchTerm
        });
    };

    render() {
        return (
            <Autocomplete
                items={this.props.searchArray}
                getItemValue={(item) => item.name}
                shouldItemRender={(item, value) => item.name.toLowerCase().search(value.toLowerCase()) > -1}
                renderMenu={children => (
                    <div style={{backgroundColor: '#FFF', position: 'absolute', zIndex: 10, width: 330, borderWidth: 0.25, borderStyle: 'solid', borderColor: '#ccc'}}>
                        {children}
                    </div>
                )}
                renderItem={(item) =>
                    <div key={item.id}>
                        <MenuItem>{item.name}</MenuItem>
                        <Divider style={{backgroundColor: '#ccc', width: 330}}/>
                    </div>
                }
                renderInput={this.renderSearch}
                value={this.state.searchTerm}
                onChange={event => this.changeHandler(event)}
                onSelect={event => this.returnValue(event)}
            />
        );
    }
}


export default AutocompleteReports;
