import {GET_COMPANY} from "./types";

import firebase from 'firebase/app';
import 'firebase/firestore';

export const getCompany = companyID => dispatch => {
    firebase.firestore().collection("company").doc(companyID).onSnapshot(docSnapshot => {
        if (docSnapshot.exists) {
            const company = docSnapshot.data();
            company.id = docSnapshot.id;

            dispatch({
                type: GET_COMPANY,
                payload: company
            })
        }
    })
};