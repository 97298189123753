import React from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCloud} from "@fortawesome/free-solid-svg-icons";

import classNames from 'classnames';

import "./CircularLoader.css";
const CircularLoader = props => {
    const {size, loading} = props;

    const loaderContainer = classNames({
        "cl-container": true,
        "medium": !!(loading && size && size === "medium"),
        "large": !!(loading && size && size === "large"),
        "hide": !loading,
    });

    return (
        <div className={loaderContainer}>
            <div className="loader">
                <div className="cloud">
                    <FontAwesomeIcon icon={faCloud} style={{color: '#1770B2'}}/>
                </div>
                <div className="cloud">
                    <FontAwesomeIcon icon={faCloud} style={{color: '#1770B2'}}/>
                </div>

            </div>
        </div>

    );
};

export default CircularLoader;
