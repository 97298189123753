import {GET_COMPANY} from "../actions/types";

const initialState = {
    company: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY:
            return {
                company: action.payload
            };
        default:
            return state;
    }
}